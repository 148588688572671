import React from 'react';
import { AuthStore, CarePatientStore } from '../../../stores';

import Message from '../../../utils/Message';

class PatientConfigSection extends React.Component<
  {
    patientID: string;
    zipCode: string;
    enableMemoryBox: boolean;
    enableBlankMemoryBox: boolean;
    enableBiometrics: boolean;

    showAccountCallButton: boolean;
    showCallButton: boolean;
    showAccountCallButton2: boolean;
    showCallButton2: boolean;
    showAccountCheckInButton: boolean;
    showCheckInButton: boolean;
    showAccountEmergencyButton: boolean;
    showEmergencyButton: boolean;
    showWeather: boolean;
    showAccountCallButtonsBar: boolean;
    showCallButtonsBar: boolean;
    showCallPhoneFromDevice: boolean;
    enableBluetoothButtons: boolean;
    enableAutoAnswer: boolean;
    enableAppIncomingCall: boolean;
    enableImpairedRingtone: boolean;
    enableExternalApps: boolean;
    PhotosCount: number;
    enableAccountMemoryBox: boolean;
    enableAccountBiometrics: boolean;
    enableAccountBluetoothButtons: boolean;
    enableAccountAutoAnswer: boolean;
    enableAccountAppIncomingCall: boolean;
    accountExternalApps: boolean;
  },
  {
    selectedPatientLocale?: string;
    accountID: string;
    patientID: string;
    deviceTab: string;
    biometricsTab: string;
    buttonsTab: string;
    deviceTabBody: string;
    biometricsTabBody: string;
    buttonsTabBody: string;
    patientZipCode: string;
    btnUpdateZipCodeStyle: string;
    memoryBoxEnabled: boolean;
    blankMemoryBoxEnabled: boolean;
    accountAudioCallsOnly: boolean;
    allowVideoCallForAccountAudioCallsOnly: boolean;
    patientLocale: string;
    availableLocalesArray: any[];
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      accountID: AuthStore.getUserAccountID(),
      patientID: this.props.patientID,

      deviceTab: 'staffSubTab',
      biometricsTab: ' ',
      buttonsTab: '',

      deviceTabBody: ' ',
      biometricsTabBody: ' hide',
      buttonsTabBody: ' hide',

      patientZipCode: this.props.zipCode,
      btnUpdateZipCodeStyle: '',

      memoryBoxEnabled: this.props.enableMemoryBox,
      blankMemoryBoxEnabled: this.props.enableBlankMemoryBox,

      accountAudioCallsOnly: false,
      allowVideoCallForAccountAudioCallsOnly: false,

      patientLocale: '',
      availableLocalesArray: [],
    };
    //console.log("Config");
    //console.log(this.props);
    this.handleRowClicked = this.handleRowClicked.bind(this);

    this.onGetPatientAccountLocalesDataAction = this.onGetPatientAccountLocalesDataAction.bind(this);
    this.handleUpdatePatientLocale = this.handleUpdatePatientLocale.bind(this);
    this.onPatientLocaleUpdatedAction = this.onPatientLocaleUpdatedAction.bind(this);

    this.handleAllowVideoCalls = this.handleAllowVideoCalls.bind(this);
    this.onAllowVideoCallUpdatedAction = this.onAllowVideoCallUpdatedAction.bind(this);

    this.handleShowCallButton = this.handleShowCallButton.bind(this);
    this.onShowCallButtonUpdatedAction = this.onShowCallButtonUpdatedAction.bind(this);

    this.handleShowCallButton2 = this.handleShowCallButton2.bind(this);
    this.onShowCallButton2UpdatedAction = this.onShowCallButton2UpdatedAction.bind(this);

    this.handleShowCheckInButton = this.handleShowCheckInButton.bind(this);
    this.onShowCheckInButtonUpdatedAction = this.onShowCheckInButtonUpdatedAction.bind(this);

    this.handleShowEmergencyButton = this.handleShowEmergencyButton.bind(this);
    this.onShowEmergencyButtonUpdatedAction = this.onShowEmergencyButtonUpdatedAction.bind(this);

    this.handleShowWeather = this.handleShowWeather.bind(this);
    this.onShowWeatherUpdatedAction = this.onShowWeatherUpdatedAction.bind(this);

    this.handleShowCallControls = this.handleShowCallControls.bind(this);
    this.onShowCallControlsUpdatedAction = this.onShowCallControlsUpdatedAction.bind(this);

    this.handlePhoneCallFromDevice = this.handlePhoneCallFromDevice.bind(this);
    this.onCallPhoneFromDeviceUpdatedAction = this.onCallPhoneFromDeviceUpdatedAction.bind(this);

    this.handleMemoryBox = this.handleMemoryBox.bind(this);
    this.onEnableMemoryBoxUpdatedAction = this.onEnableMemoryBoxUpdatedAction.bind(this);

    this.handleBlankMemoryBox = this.handleBlankMemoryBox.bind(this);
    this.onEnableBlankMemoryBoxUpdatedAction = this.onEnableBlankMemoryBoxUpdatedAction.bind(this);

    this.handleUpdateZipCode = this.handleUpdateZipCode.bind(this);
    this.onZipCodeUpdatedAction = this.onZipCodeUpdatedAction.bind(this);

    this.handleEnableBiometrics = this.handleEnableBiometrics.bind(this);
    this.onEnableBiometricsUpdatedAction = this.onEnableBiometricsUpdatedAction.bind(this);

    this.handleEnableBluetoothButtons = this.handleEnableBluetoothButtons.bind(this);
    this.onEnableBluetoothButtonsUpdatedAction = this.onEnableBluetoothButtonsUpdatedAction.bind(this);

    this.handleEnableAutoAnswer = this.handleEnableAutoAnswer.bind(this);
    this.onEnableAutoAnswerUpdatedAction = this.onEnableAutoAnswerUpdatedAction.bind(this);

    this.handleEnableAppIncomingCall = this.handleEnableAppIncomingCall.bind(this);
    this.onEnableAppIncomingCallUpdatedAction = this.onEnableAppIncomingCallUpdatedAction.bind(this);

    this.handleEnableImpairedRingtone = this.handleEnableImpairedRingtone.bind(this);
    this.onEnableImpairedRingtoneUpdatedAction = this.onEnableImpairedRingtoneUpdatedAction.bind(this);

    this.handleDeviceTab = this.handleDeviceTab.bind(this);
    this.handleBiometricsTab = this.handleBiometricsTab.bind(this);
    this.handleButtonsTab = this.handleButtonsTab.bind(this);

    this.onOpenDevicesSubTabAction = this.onOpenDevicesSubTabAction.bind(this);
    this.onOpenBiometricsAlertsSubTabAction = this.onOpenBiometricsAlertsSubTabAction.bind(this);
    this.onOpenButtonsSubTabAction = this.onOpenButtonsSubTabAction.bind(this);

    this.onPatientGetDataAction = this.onPatientGetDataAction.bind(this);
  }

  componentDidMount() {
    CarePatientStore.getPatientAccountLocalesData(AuthStore.getUserAccountID());

    CarePatientStore.on('onGetPatientAccountLocalesData', this.onGetPatientAccountLocalesDataAction);

    CarePatientStore.on('onPatientLocaleUpdated', this.onPatientLocaleUpdatedAction);

    CarePatientStore.on('onAllowVideoCallUpdated', this.onAllowVideoCallUpdatedAction);

    CarePatientStore.on('onShowCallButtonUpdated', this.onShowCallButtonUpdatedAction);

    CarePatientStore.on('onShowCallButton2Updated', this.onShowCallButton2UpdatedAction);

    CarePatientStore.on('onShowCheckInButtonUpdated', this.onShowCheckInButtonUpdatedAction);
    CarePatientStore.on('onShowEmergencyButtonUpdated', this.onShowEmergencyButtonUpdatedAction);
    CarePatientStore.on('onShowWeatherUpdated', this.onShowWeatherUpdatedAction);
    CarePatientStore.on('onShowCallControlsUpdated', this.onShowCallControlsUpdatedAction);
    CarePatientStore.on('onCallPhoneFromDeviceUpdated', this.onCallPhoneFromDeviceUpdatedAction);
    CarePatientStore.on('onEnableMemoryBoxUpdated', this.onEnableMemoryBoxUpdatedAction);
    CarePatientStore.on('onEnableBlankMemoryBoxUpdated', this.onEnableBlankMemoryBoxUpdatedAction);

    CarePatientStore.on('onZipCodeUpdated', this.onZipCodeUpdatedAction);

    CarePatientStore.on('onEnableBiometricsUpdated', this.onEnableBiometricsUpdatedAction);
    CarePatientStore.on('onEnableBluetoothButtonsUpdated', this.onEnableBluetoothButtonsUpdatedAction);
    CarePatientStore.on('onEnableAutoAnswerUpdated', this.onEnableAutoAnswerUpdatedAction);
    CarePatientStore.on('onEnableAppIncomingCallUpdated', this.onEnableAppIncomingCallUpdatedAction);

    CarePatientStore.on('onEnableImpairedRingtoneUpdated', this.onEnableImpairedRingtoneUpdatedAction);

    //CarePatientStore.on("onGetPatientButtons", this.onGetPatientButtonsAction);
    //CarePatientStore.on("onGetUnAssignedButtons", this.onGetUnAssignedButtonsAction);
    //CarePatientStore.on("onAssignButtonToPatient", this.onAssignButtonToPatientAction);

    //CarePatientStore.on("onOpenButtonModal", this.onOpenButtonModalAction);
    //CarePatientStore.on("onButtonUpdated", this.onButtonUpdatedAction);
    //CarePatientStore.on("onButtonUnassigned", this.onButtonUnassignedAction);

    CarePatientStore.on('onOpenDevicesSubTab', this.onOpenDevicesSubTabAction);
    CarePatientStore.on('onOpenBiometricsAlertsSubTab', this.onOpenBiometricsAlertsSubTabAction);
    CarePatientStore.on('onOpenButtonsSubTab', this.onOpenButtonsSubTabAction);

    CarePatientStore.on('onPatientGetData', this.onPatientGetDataAction);
    CarePatientStore.on('onPatientPhotosDeleted', this.onPatientPhotosDeleted);

    this.setState({
      patientID: this.props.patientID,
      memoryBoxEnabled: this.props.enableMemoryBox,
      blankMemoryBoxEnabled: this.props.enableBlankMemoryBox,
    });

    // DEPRECATED - Fri Dec 6th 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6239
    // Message.show("Deprecated BLEButtons 1");
    // this.buttonsInfoLoaded = -1;
    // CarePatientStore.getPatientButtons({
    //     PatientID : this.props.patientID
    // });

    // DEPRECATED - Fri Dec 6th 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6239
    // Message.show("Deprecated BLEButtons 2");
    // CarePatientStore.getUnAssignedButtonsForAccount({
    //     AccountID : this.state.accountID
    // });
  }

  onPatientPhotosDeleted = () => {
    //alert("Photos Deleted")
    Message.show('Photos Deleted');
  };

  componentWillUnmount() {
    CarePatientStore.removeListener('onGetPatientAccountLocalesData', this.onGetPatientAccountLocalesDataAction);
    CarePatientStore.removeListener('onPatientLocaleUpdated', this.onPatientLocaleUpdatedAction);
    CarePatientStore.removeListener('onAllowVideoCallUpdated', this.onAllowVideoCallUpdatedAction);
    CarePatientStore.removeListener('onShowCallButtonUpdated', this.onShowCallButtonUpdatedAction);
    CarePatientStore.removeListener('onPatientPhotosDeleted', this.onPatientPhotosDeleted);
    CarePatientStore.removeListener('onShowCallButton2Updated', this.onShowCallButton2UpdatedAction);

    CarePatientStore.removeListener('onShowCheckInButtonUpdated', this.onShowCheckInButtonUpdatedAction);
    CarePatientStore.removeListener('onShowEmergencyButtonUpdated', this.onShowEmergencyButtonUpdatedAction);
    CarePatientStore.removeListener('onShowWeatherUpdated', this.onShowWeatherUpdatedAction);
    CarePatientStore.removeListener('onShowCallControlsUpdated', this.onShowCallControlsUpdatedAction);
    CarePatientStore.removeListener('onCallPhoneFromDeviceUpdated', this.onCallPhoneFromDeviceUpdatedAction);
    CarePatientStore.removeListener('onEnableMemoryBoxUpdated', this.onEnableMemoryBoxUpdatedAction);
    CarePatientStore.removeListener('onEnableBlankMemoryBoxUpdated', this.onEnableBlankMemoryBoxUpdatedAction);

    CarePatientStore.removeListener('onZipCodeUpdated', this.onZipCodeUpdatedAction);

    CarePatientStore.removeListener('onEnableBiometricsUpdated', this.onEnableBiometricsUpdatedAction);
    CarePatientStore.removeListener('onEnableBluetoothButtonsUpdated', this.onEnableBluetoothButtonsUpdatedAction);
    CarePatientStore.removeListener('onEnableAutoAnswerUpdated', this.onEnableAutoAnswerUpdatedAction);
    CarePatientStore.removeListener('onEnableAppIncomingCallUpdated', this.onEnableAppIncomingCallUpdatedAction);
    CarePatientStore.removeListener('onEnableImpairedRingtoneUpdated', this.onEnableImpairedRingtoneUpdatedAction);

    //CarePatientStore.removeListener("onGetPatientButtons", this.onGetPatientButtonsAction);
    //CarePatientStore.removeListener("onGetUnAssignedButtons", this.onGetUnAssignedButtonsAction);
    //CarePatientStore.removeListener("onAssignButtonToPatient", this.onAssignButtonToPatientAction);

    //CarePatientStore.removeListener("onOpenButtonModal", this.onOpenButtonModalAction);
    //CarePatientStore.removeListener("onButtonUpdated", this.onButtonUpdatedAction);
    //CarePatientStore.removeListener("onButtonUnassigned", this.onButtonUnassignedAction);

    CarePatientStore.removeListener('onOpenDevicesSubTab', this.onOpenDevicesSubTabAction);
    CarePatientStore.removeListener('onOpenBiometricsAlertsSubTab', this.onOpenBiometricsAlertsSubTabAction);
    CarePatientStore.removeListener('onOpenButtonsSubTab', this.onOpenButtonsSubTabAction);

    CarePatientStore.removeListener('onPatientGetData', this.onPatientGetDataAction);

    //$('.tooltipped').tooltip('remove');
  }

  componentWillReceiveProps() {}

  componentWillUpdate() {}

  handleRowClicked() {
    // GeneralStore.setClientTab(1);
    // GeneralStore.setPageBeforePatient("patients");
    // window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
  }

  onPatientGetDataAction(response) {
    console.log('onPatientGetDataAction Config', response);

    this.setState({
      patientZipCode: response.ZipCode,
      patientLocale: response.LocaleTag,
      accountAudioCallsOnly: response.AccountAudioCallsOnly,
      allowVideoCallForAccountAudioCallsOnly: response.AllowVideoForAccountAudioCallsOnly,
      selectedPatientLocale: response.LocaleTag,
    });
  }

  onGetPatientAccountLocalesDataAction(response) {
    console.log('onGetPatientAccountLocalesDataAction', response);
    if (response && response.ok) {
      this.setState({
        availableLocalesArray: response.accountLanguages,
        selectedPatientLocale: response.locale,
      });
    } else {
      Message.show('Sorry, There was a problem getting the locale information. Please try again.');
    }
  }

  handleUpdatePatientLocale(e) {
    console.log('handleUpdatePatientLocale', e.target);
    console.log('New Locale', this.state.selectedPatientLocale);
    this.setState(
      {
        selectedPatientLocale: e.target.value,
      },
      () => {
        CarePatientStore.updatePatientLocale({
          PatientID: this.props.patientID,
          LocaleTag: this.state.selectedPatientLocale,
        });
      },
    );
  }
  onPatientLocaleUpdatedAction(response) {
    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' language updated'); // to " + response.locale);
    } else {
      Message.show('Error updating ' + AuthStore.getPatientLabel() + ' language.');
    }
  }

  handleAllowVideoCalls(event) {
    console.log('handleAllowVideoCalls', event.target.checked);

    CarePatientStore.updatePatientAllowVideoCall({
      PatientID: this.props.patientID,
      Enabled: event.target.checked,
    });
  }

  onAllowVideoCallUpdatedAction(response) {
    console.log('onAllowVideoCallUpdatedAction', response);

    const responseLabel = response.enabled ? ' enabled' : ' disabled';
    if (response.ok) {
      Message.show('Allow Video Call for Audio Call only ' + responseLabel);
    } else {
      Message.show('Error updating Allow Video Call for Audio Call only');
    }
  }

  handleShowCallButton(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateShowCallButton({
      PatientID: this.props.patientID,
      ShowCallButton: event.target.checked,
    });
    // onShowCallButtonUpdated
  }
  onShowCallButtonUpdatedAction(response) {
    console.log('onShowCallButtonUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show Call button set to: ' + response.show);
    } else {
      Message.show('Error updating Show Call Button state');
    }
  }

  handleShowCallButton2(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateShowCallButton2({
      PatientID: this.props.patientID,
      ShowCallButton2: event.target.checked,
    });
    // onShowCallButton2Updated
  }
  onShowCallButton2UpdatedAction(response) {
    console.log('onShowCallButton2UpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show Call Button 2 set to: ' + response.show);
    } else {
      Message.show('Error updating Show Call Button 2 state');
    }
  }

  handleShowCheckInButton(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateShowCheckInButton({
      PatientID: this.props.patientID,
      ShowCheckInButton: event.target.checked,
    });
    // onShowCheckInButtonUpdated
  }
  onShowCheckInButtonUpdatedAction(response) {
    console.log('onShowCheckInButtonUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show CheckIn Button set to: ' + response.show);
      CarePatientStore.getPatientData({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error updating Show CheckIn Button state');
    }
  }

  handleShowEmergencyButton(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateShowEmergencyButton({
      PatientID: this.props.patientID,
      ShowEmergencyButton: event.target.checked,
    });
    // onShowEmergencyButtonUpdated
  }
  onShowEmergencyButtonUpdatedAction(response) {
    console.log('onShowEmergencyButtonUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show Emergency Button set to: ' + response.show);
    } else {
      Message.show('Error updating Show Emergency Button state');
    }
  }

  handleShowWeather(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateShowWeather({
      PatientID: this.props.patientID,
      ShowWeather: event.target.checked,
    });
    // onShowWeatherUpdated
  }
  onShowWeatherUpdatedAction(response) {
    console.log('onShowWeatherUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show Weather set to: ' + response.show);
    } else {
      Message.show('Error updating Show Weather state');
    }
  }

  handleShowCallControls(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateShowCallControls({
      PatientID: this.props.patientID,
      ShowCallButtonsBar: event.target.checked,
    });
    // onShowCallControlsUpdated
  }
  onShowCallControlsUpdatedAction(response) {
    console.log('onShowCallControlsUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Show Call Buttons Bar set to: ' + response.show);
    } else {
      Message.show('Error updating Show Call Buttons Bar state');
    }
  }

  handlePhoneCallFromDevice(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateCallPhoneFromDevice({
      PatientID: this.props.patientID,
      CallPhoneFromDevice: event.target.checked,
    });
    // onCallPhoneFromDeviceUpdated
  }
  onCallPhoneFromDeviceUpdatedAction(response) {
    console.log('onCallPhoneFromDeviceUpdatedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Call Phone from Device set to: ' + response.enable);
    } else {
      Message.show('Error updating Call Phone from Device state');
    }
  }

  handleMemoryBox(event) {
    console.log('this has changed ', event.target.checked);

    // console.log(this.refs.checkEnableBlankMemoryBox.checked);
    // if (event.target.checked && this.refs.checkEnableBlankMemoryBox.checked){
    //   let tempObj = {
    //     target :{
    //       checked :false
    //     }
    //   };
    //   setTimeout(() => {
    //     this.handleBlankMemoryBox(tempObj);
    //     this.refs.checkEnableBlankMemoryBox.checked = false;
    //   }, 500);
    // }

    CarePatientStore.updateEnableMemoryBox({
      PatientID: this.props.patientID,
      EnableMemoryBox: event.target.checked,
    });
    // onEnableMemoryBoxUpdated
  }
  onEnableMemoryBoxUpdatedAction(response) {
    console.log('onEnableMemoryBoxUpdatedAction');
    console.log(response);

    if (response.ok) {
      const responseResult = response.EnableMemoryBox ? ' Enabled.' : ' Disabled.';
      Message.show('Memory box ' + responseResult);
    } else {
      Message.show('Error updating Memory Box.');
    }
  }

  handleBlankMemoryBox(event) {
    console.log('this has changed ', event.target.checked);

    // console.log(this.refs.checkEnableMemoryBox.checked);
    // if (event.target.checked && this.refs.checkEnableMemoryBox.checked){
    //   let tempObj = {
    //     target :{
    //       checked :false
    //     }
    //   };
    //   setTimeout(() => {
    //     this.handleMemoryBox(tempObj);
    //     this.refs.checkEnableMemoryBox.checked = false;
    //   }, 500);
    // }

    CarePatientStore.updateEnableBlankMemoryBox({
      PatientID: this.props.patientID,
      EnableBlankMemoryBox: event.target.checked,
    });
    // onEnableBlankMemoryBoxUpdated
  }

  onEnableBlankMemoryBoxUpdatedAction(response) {
    console.log('onEnableBlankMemoryBoxUpdatedAction');
    console.log(response);

    if (response.ok) {
      const responseResult = response.EnableBlankMemoryBox ? ' Enabled.' : ' Disabled.';
      Message.show('Blank Screen Saver ' + responseResult);
    } else {
      Message.show('Error updating Blank Screen Saver.');
    }
  }

  handleEnableExternalApps(event) {
    CarePatientStore.updateEnableExternalApps({
      PatientID: this.props.patientID,
      EnableExternalApps: event.target.checked,
      callback: response => {
        console.log('updateEnableExternalApps callback');
        console.log(response);
        if (response.ok) {
          const responseResult = response.EnableExternalApps ? ' enabled.' : ' disabled.';
          Message.show('Apps ' + responseResult);
        } else {
          Message.show('Error updating Apps.');
        }
      },
    });
  }
  handleUpdateZipCode() {
    // if (this.refs.txtZipCode.value == ''){
    //     Message.show("Insert a valid Zip Code");
    //     this.refs.txtZipCode.focus();
    // }

    CarePatientStore.updateZipCode({
      PatientID: this.props.patientID,
      //@ts-ignore
      ZipCode: this.refs.txtZipCode.value,
    });
    // Listen to "onZipCodeUpdated"
  }
  onZipCodeUpdatedAction(response) {
    console.log('onZipCodeUpdatedAction ', response);

    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' Zip Code updated!');
      CarePatientStore.getPatientData({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error updating ' + AuthStore.getPatientLabel() + ' Zip Code');
    }
  }

  // BIOMETRICS CONFIGURATION

  handleEnableBiometrics(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateEnableBiometrics({
      PatientID: this.props.patientID,
      EnableBiometrics: event.target.checked,
    });
    // onEnableBiometricsUpdated
  }

  onEnableBiometricsUpdatedAction(response) {
    console.log('onEnableBiometricsUpdatedAction');
    console.log(response);

    if (response.ok) {
      const responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Biometrics ' + responseResult);
      CarePatientStore.getPatientData({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error updating Biometrics.');
    }
  }

  // Bluetooth Buttons
  handleEnableBluetoothButtons(event) {
    console.log('this has changed ', event.target.checked);

    CarePatientStore.updateEnableBluetoothButtons({
      PatientID: this.props.patientID,
      EnableBluetoothButtons: event.target.checked,
    });
    // onEnableBluetoothButtonsUpdated
  }

  onEnableBluetoothButtonsUpdatedAction(response) {
    console.log('onEnableBluetoothButtonsUpdatedAction', response);

    if (response.ok) {
      const responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Bluetooth Buttons ' + responseResult);
      CarePatientStore.getPatientData({
        PatientID: this.props.patientID,
      });
    } else {
      Message.show('Error updating Bluetooth Buttons.');
    }
  }

  handleEnableAutoAnswer(event) {
    console.log('handleEnableAutoAnswer', event.target.checked);

    CarePatientStore.updateEnableAutoAnswer({
      PatientID: this.props.patientID,
      EnableAutoAnswer: event.target.checked,
    });
  }

  onEnableAutoAnswerUpdatedAction(response) {
    console.log('onEnableAutoAnswerUpdatedAction', response);

    if (response.ok) {
      const responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Auto Answer ' + responseResult);
    } else {
      Message.show('Error updating Auto Answer.');
    }
  }

  handleEnableAppIncomingCall(event) {
    console.log('handleEnableAppIncomingCall', event.target.checked);

    CarePatientStore.updateEnableAppIncomingCall({
      PatientID: this.props.patientID,
      EnableAppIncomingCall: event.target.checked,
    });
  }

  onEnableAppIncomingCallUpdatedAction(response) {
    console.log('onEnableAppIncomingCallUpdatedAction', response);

    if (response.ok) {
      const responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('App Incoming Call ' + responseResult);
    } else {
      Message.show('Error updating App Incoming Call.');
    }
  }

  handleEnableImpairedRingtone(event) {
    console.log('handleEnableImpairedRingtone', event.target.checked);

    CarePatientStore.updateEnableImpairedRingtone({
      PatientID: this.props.patientID,
      Enable: event.target.checked,
    });
  }

  onEnableImpairedRingtoneUpdatedAction(response) {
    console.log('onEnableImpairedRingtoneUpdatedAction', response);

    if (response.ok) {
      const responseResult = response.enable ? ' Enabled.' : ' Disabled.';
      Message.show('Hearing Impaired Ringtone ' + responseResult);
    } else {
      Message.show('Error updating Hearing Impaired Ringtone.');
    }
  }

  onOpenDevicesSubTabAction() {
    this.handleDeviceTab();
  }

  onOpenBiometricsAlertsSubTabAction() {
    this.handleBiometricsTab();
  }

  onOpenButtonsSubTabAction() {
    this.handleButtonsTab();
  }

  handleDeviceTab() {
    this.setState({
      deviceTab: 'staffSubTab',
      biometricsTab: ' ',
      buttonsTab: '',

      deviceTabBody: ' ',
      biometricsTabBody: ' hide',
      buttonsTabBody: ' hide',
    });

    CarePatientStore.getPatientData({
      PatientID: this.props.patientID,
    });
    //Message.show("Device Configuration updated");
  }

  handleBiometricsTab() {
    this.setState({
      deviceTab: '',
      biometricsTab: ' staffSubTab ',
      buttonsTab: '',

      deviceTabBody: ' hide',
      biometricsTabBody: ' ',
      buttonsTabBody: ' hide',
    });

    CarePatientStore.getPatientData({
      PatientID: this.props.patientID,
    });
    //Message.show("Biometrics Information updated");
  }

  handleButtonsTab() {
    this.setState({
      deviceTab: '',
      biometricsTab: ' ',
      buttonsTab: ' staffSubTab',

      deviceTabBody: ' hide',
      biometricsTabBody: ' hide',
      buttonsTabBody: ' ',
    });
  }

  deleteAllPhotos = () => {
    if (window.confirm('Are you sure you want to Delete all family photos this action cannot be undone?')) {
      CarePatientStore.deleteFamilyPhotos({
        PatientID: this.props.patientID,
      });
    }
  };

  render() {
    const videoCallForAudioCallsOnlyDefault = this.state.allowVideoCallForAccountAudioCallsOnly;

    const showCallBtnMode = this.props.showAccountCallButton ? '' : 'disabled';
    const showCallBtnValue = this.props.showCallButton ? 'checked' : '';

    const showCallBtnMode2 = this.props.showAccountCallButton2 ? '' : 'disabled';
    const showCallBtnValue2 = this.props.showCallButton2 ? 'checked' : '';

    const showCheckInBtnMode = this.props.showAccountCheckInButton ? '' : 'disabled';
    const showCheckInBtnValue = this.props.showCheckInButton ? 'checked' : '';

    const showEmergencyBtnMode = this.props.showAccountEmergencyButton ? '' : 'disabled';
    const showEmergencyBtnValue = this.props.showEmergencyButton ? 'checked' : '';
    const showWeatherValue = this.props.showWeather ? 'checked' : '';

    const showCallControlsMode = this.props.showAccountCallButtonsBar ? '' : 'disabled';
    const showCallControlsValue = this.props.showCallButtonsBar ? 'checked' : '';

    const enableMemoryBoxMode = this.props.enableAccountMemoryBox ? '' : 'disabled';
    const accountExternalAppsMode = this.props.accountExternalApps ? '' : 'disabled';

    const enableMemoryBoxValue = this.props.enableMemoryBox ? 'checked' : '';
    const enableBlankMemoryBoxValue = this.props.enableBlankMemoryBox ? 'checked' : '';

    const enableBiometricsValue = this.props.enableBiometrics ? 'checked' : '';
    //let enableBluetoothButtonsValue = this.props.enableBluetoothButtons ? "checked" : "";

    const enableAccountBiometricsMode = this.props.enableAccountBiometrics ? '' : 'disabled';

    const enableAutoAnswerValue = this.props.enableAutoAnswer ? 'checked' : '';
    const enableAccountAutoAnswerMode = this.props.enableAccountAutoAnswer ? '' : 'disabled';

    const enableAppIncomingCallValue = this.props.enableAppIncomingCall ? 'checked' : '';
    const enableAccountAppIncomingCallMode = this.props.enableAccountAppIncomingCall ? '' : 'disabled';
    // --
    const enableImpairedRingtone = this.props.enableImpairedRingtone ? 'checked' : '';

    const enableExternalAppsValue = this.props.enableExternalApps ? 'checked' : '';

    const availablePatientLocales = [];
    if (this.state.availableLocalesArray != null && this.state.availableLocalesArray.length) {
      this.state.availableLocalesArray.map(locale => {
        availablePatientLocales.push(
          <option key={locale.LocaleTag} value={locale.LocaleTag}>
            {locale.Description}
          </option>,
        );
      });
    }

    // DEPRECATED - Fri Dec 6th 2019
    // https://nucleusintercom.atlassian.net/browse/CARE-6239
    /*
        let patientButtonsRows = [];
        
        if (this.state.buttonsArray != null && this.state.buttonsArray != []){
            this.state.buttonsArray.map((button, key) => {

                patientButtonsRows.push(
                    <CarePatientButtonItemRow
                      key={key}
                      Mac={ button.Mac }
                      Type={ button.Type }
                      Location={ button.Location }
                      Online={ button.Online}
                      LastCheckIn={ button.LastCheckIn}
                    />
                );
            });
        }
        

        let availableButtonsOptions = [];
        if (this.state.availableButtonsArray != null && this.state.availableButtonsArray.length){
            this.state.availableButtonsArray.map((button, key) => {
                availableButtonsOptions.push(<option key={button.Mac} value={button.Mac} >{button.Mac}</option>);
            });
        }
        let selectButtonCaption = (availableButtonsOptions.length > 0) ? 'Choose one of the available buttons' : 'No available buttons';
        */

    return (
      <div className="row biometricsChartContainer">
        {/* Deprecated - New Design Feb 15-2018
                <div className="bottom-border-menu ">
                    <div className="tabSmallContainer">
                        <a className={"staffTabsSmall2 nucleus-link " + this.state.deviceTab }  onClick={this.handleDeviceTab}>&nbsp;&nbsp;Care Device&nbsp;&nbsp;</a>
                    </div>
                    <div className={"tabSmallContainer " + biometricAlertsSection }>
                        <a className={"staffTabsSmall2 nucleus-link " + this.state.biometricsTab } onClick={this.handleBiometricsTab}>&nbsp;&nbsp;Biometrics&nbsp;&nbsp;</a>
                    </div>
                    <div className="tabSmallContainer">
                        <a className={"staffTabsSmall2 nucleus-link " + this.state.buttonsTab } onClick={this.handleButtonsTab}>&nbsp;&nbsp;Buttons&nbsp;&nbsp;</a>
                    </div>
                </div>
                */}

        <div className={'row padding-15 ' + this.state.deviceTabBody}>
          <div className="col s5 no-margin ">
            {/*
              <span className="nucleus-labels-bold "> Care Device</span>
              <br/><br/>
            */}
            <div className="row vbottom-align no-margin ">
              <div className="col s4 no-margin">
                <span className="nucleus-labels"> Language</span>
              </div>
              <div className="col s6 no-margin control-panels-container">
                <select value={this.state.selectedPatientLocale} className="browser-default patientConfigSelect" onChange={this.handleUpdatePatientLocale}>
                  <option value="" disabled selected>
                    {' Select a Language '}
                  </option>
                  {availablePatientLocales}
                </select>
              </div>
              <div className="col s2 no-margin "></div>
            </div>

            {this.state.accountAudioCallsOnly && (
              <div className="row vbottom-align no-margin ">
                <div className="col s9 no-margin">
                  <span className="nucleus-labels"> Allow Video Calls</span>
                </div>
                <div className="col s3 no-margin">
                  <span>
                    <input
                      ref="checkAllowVideoForAudioCallsOnly"
                      type="checkbox"
                      className="filled-in"
                      id={'checkAllowVideoForAudioCallsOnly'}
                      defaultChecked={videoCallForAudioCallsOnlyDefault}
                      onChange={this.handleAllowVideoCalls}
                    />
                    <label id={'checkAllowVideoForAudioCallsOnly_label'} htmlFor={'checkAllowVideoForAudioCallsOnly'}>
                      &nbsp;
                    </label>
                  </span>
                </div>
              </div>
            )}
            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Show Call Button</span>
              </div>
              <div className="col s3 no-margin">
                <span key={showCallBtnValue}>
                  <input
                    ref="checkShowCallButton"
                    type="checkbox"
                    className="filled-in"
                    id={'checkShowCallButton' + this.state.accountID}
                    defaultChecked={!!showCallBtnValue}
                    onChange={this.handleShowCallButton}
                    disabled={!!showCallBtnMode}
                  />
                  <label id={'checkShowCallButton-label'} htmlFor={'checkShowCallButton' + this.state.accountID}>
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Show 2nd Call Button</span>
              </div>
              <div className="col s3 no-margin">
                <span key={showCallBtnValue2}>
                  <input
                    ref="checkShowCallButton2"
                    type="checkbox"
                    className="filled-in"
                    id="checkShowCallButton2"
                    defaultChecked={!!showCallBtnValue2}
                    onChange={this.handleShowCallButton2}
                    disabled={!!showCallBtnMode2}
                  />
                  <label id="checkShowCallButton2-label" htmlFor="checkShowCallButton2">
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Show Check In Button</span>
              </div>
              <div className="col s3 no-margin">
                <span key={showCheckInBtnValue}>
                  <input
                    ref="checkShowCheckBtn"
                    type="checkbox"
                    className="filled-in"
                    id={'checkShowCheckBtn' + this.state.accountID}
                    defaultChecked={!!showCheckInBtnValue}
                    onChange={this.handleShowCheckInButton}
                    disabled={!!showCheckInBtnMode}
                  />
                  <label id={'checkShowCheckBtn-label'} htmlFor={'checkShowCheckBtn' + this.state.accountID}>
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels">Show Emergency Button</span>
              </div>
              <div className="col s3 no-margin">
                <span key={showEmergencyBtnValue}>
                  <input
                    ref="checkShowEmergencyBtn"
                    type="checkbox"
                    className="filled-in"
                    id={'checkShowEmergencyBtn' + this.state.accountID}
                    defaultChecked={!!showEmergencyBtnValue}
                    onChange={this.handleShowEmergencyButton}
                    disabled={!!showEmergencyBtnMode}
                  />
                  <label id={'checkShowEmergencyBtn-label'} htmlFor={'checkShowEmergencyBtn' + this.state.accountID}>
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels">Show Weather</span>
              </div>
              <div className="col s3 no-margin">
                <span key={showWeatherValue}>
                  <input
                    ref="checkShowWeather"
                    type="checkbox"
                    className="filled-in"
                    id={'checkShowWeather' + this.state.accountID}
                    defaultChecked={!!showWeatherValue}
                    onChange={this.handleShowWeather}
                  />
                  <label id={'checkShowWeather-label'} htmlFor={'checkShowWeather' + this.state.accountID}>
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            {/* Deprecated - New Design March 26-2019
              <div className="row vbottom-align no-margin ">
                  <div className="col s9 no-margin">
                      <span className="nucleus-labels"> Call Phone From Device</span> 
                  </div>
                  <div className="col s3 no-margin">
                      <span key={enableCallPhoneFromDeviceValue}>
                          <input ref="checkPhoneCallFromDevice" type="checkbox" className="filled-in" id={"checkPhoneCallFromDevice" + this.state.accountID } defaultChecked={enableCallPhoneFromDeviceValue} onChange={ this.handlePhoneCallFromDevice}/>
                          <label htmlFor={"checkPhoneCallFromDevice" + this.state.accountID }>&nbsp;</label>
                      </span>
                  </div>
              </div>*/}

            <div className="row vbottom-align no-margin">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Enable Memory Box *</span>
              </div>
              <div className="col s3 no-margin">
                <span key={enableMemoryBoxValue}>
                  <input
                    ref="checkEnableMemoryBox"
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableMemoryBox"
                    defaultChecked={!!enableMemoryBoxValue}
                    onChange={this.handleMemoryBox}
                    disabled={!!enableMemoryBoxMode}
                  />
                  <label id="checkEnableMemoryBox-label" htmlFor="checkEnableMemoryBox">
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Enable Blank Screen Saver *</span>
              </div>
              <div className="col s3 no-margin">
                <span key={enableBlankMemoryBoxValue}>
                  <input
                    ref="checkEnableBlankMemoryBox"
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableBlankMemoryBox"
                    defaultChecked={!!enableBlankMemoryBoxValue}
                    onChange={this.handleBlankMemoryBox}
                    disabled={!!enableMemoryBoxMode}
                  />
                  <label id="checkEnableBlankMemoryBox-label" htmlFor="checkEnableBlankMemoryBox">
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Enable Apps</span>
              </div>
              <div className="col s3 no-margin">
                <span key={enableExternalAppsValue}>
                  <input
                    ref="checkEnableExternalApps"
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableExternalApps"
                    defaultChecked={!!enableExternalAppsValue}
                    onChange={this.handleEnableExternalApps.bind(this)}
                    disabled={!!accountExternalAppsMode}
                  />
                  <label id="checkEnableExternalApps-label" htmlFor="checkEnableExternalApps">
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin">
              <div className="col s3 no-margin">
                <span className="nucleus-labels-small "> ZIP Code: </span>
              </div>
              <div className="col s6 no-margin" key={this.state.patientZipCode}>
                <input ref="txtZipCode" type="text" className="validate nucleus-input-form-small accountTextFull short-input" defaultValue={this.state.patientZipCode} />
              </div>
              <div className="col s3 no-margin ">
                <a className="btn-floating btn waves-effect waves-light light-blue darken-4 nucleus-floating-btn a-bit-left" onClick={this.handleUpdateZipCode}>
                  <i className="material-icons nucleus-floating-btn-icon">save</i>
                </a>
              </div>
            </div>
          </div>

          <div className="col s4 no-margin ">
            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Show Call Buttons Bar</span>
              </div>
              <div className="col s3 no-margin">
                <span key={showCallControlsValue}>
                  <input
                    ref="checkShowCallControls"
                    type="checkbox"
                    className="filled-in"
                    id={'checkShowCallControls' + this.state.accountID}
                    defaultChecked={!!showCallControlsValue}
                    onChange={this.handleShowCallControls}
                    disabled={!!showCallControlsMode}
                  />
                  <label id={'checkShowCallControls-label'} htmlFor={'checkShowCallControls' + this.state.accountID}>
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>
            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Biometrics </span>
              </div>
              <div className="col s3 no-margin">
                <span key={enableBiometricsValue}>
                  <input
                    ref="checkEnableBiometrics"
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableBiometrics"
                    defaultChecked={!!enableBiometricsValue}
                    onChange={this.handleEnableBiometrics}
                    disabled={!!enableAccountBiometricsMode}
                  />
                  <label htmlFor="checkEnableBiometrics">&nbsp;</label>
                </span>
              </div>
            </div>

            {/*
              // DEPRECATED - Fri Dec 6th 2019
              // https://nucleusintercom.atlassian.net/browse/CARE-6239  
              <div className="row vbottom-align no-margin ">
                  <div className="col s9 no-margin">
                      <span className="nucleus-labels"> Bluetooth Buttons </span> 
                  </div>
                  <div className="col s3 no-margin">
                      <span key={enableBluetoothButtonsValue}>
                          <input ref="checkEnableBluetoothButtons" type="checkbox" className="filled-in" id="checkEnableBluetoothButtons" defaultChecked={enableBluetoothButtonsValue} onChange={ this.handleEnableBluetoothButtons} disabled={enableAccountBluetoothButtonsMode}/>
                          <label htmlFor="checkEnableBluetoothButtons">&nbsp;</label>
                      </span>
                  </div>
              </div>
              */}

            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Enable Auto Answer </span>
              </div>
              <div className="col s3 no-margin">
                <span key={enableAutoAnswerValue}>
                  <input
                    ref="checkEnableAutoAnswer"
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableAutoAnswer"
                    defaultChecked={!!enableAutoAnswerValue}
                    onChange={this.handleEnableAutoAnswer}
                    disabled={!!enableAccountAutoAnswerMode}
                  />
                  <label id="checkEnableAutoAnswer-label" htmlFor="checkEnableAutoAnswer">
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Enable App Incoming Call </span>
              </div>
              <div className="col s3 no-margin">
                <span key={enableAppIncomingCallValue}>
                  <input
                    ref="checkEnableAppIncomingCall"
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableAppIncomingCall"
                    defaultChecked={!!enableAppIncomingCallValue}
                    onChange={this.handleEnableAppIncomingCall}
                    disabled={!!enableAccountAppIncomingCallMode}
                  />
                  <label htmlFor="checkEnableAppIncomingCall">&nbsp;</label>
                </span>
              </div>
            </div>

            <div className="row vbottom-align no-margin ">
              <div className="col s9 no-margin">
                <span className="nucleus-labels"> Hearing Impaired Ringtone </span>
              </div>
              <div className="col s3 no-margin">
                <span key={enableImpairedRingtone}>
                  <input
                    ref="checkEnableImpairedRingtone"
                    type="checkbox"
                    className="filled-in"
                    id="checkEnableImpairedRingtone"
                    defaultChecked={!!enableImpairedRingtone}
                    onChange={this.handleEnableImpairedRingtone}
                  />
                  <label id="checkEnableImpairedRingtone-label" htmlFor="checkEnableImpairedRingtone">
                    &nbsp;
                  </label>
                </span>
              </div>
            </div>

            {this.props.PhotosCount > 0 && (
              <div className="row vbottom-align no-margin ">
                <div className="col s9 no-margin">
                  <span className="nucleus-labels"> Delete All Family Photos </span>
                </div>
                <div className="col s3 no-margin">
                  <button
                    onClick={this.deleteAllPhotos}
                    style={{
                      backgroundColor: 'red',
                    }}
                    className="btn"
                  >
                    DELETE
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="col s3 no-margin "></div>
        </div>

        <div className={'row padding-15 border1 ' + this.state.biometricsTabBody}></div>
      </div>
    );
  }
}

export default PatientConfigSection;
