import React from 'react';

interface CarePatientMessageItemRowProps {
  Message: string;
  DeliveryDate: string;
  Timezone: string;
  Status: string;
  SignatureUrl: string;
}

const CarePatientMessageItemRow: React.FC<CarePatientMessageItemRowProps> = ({ Message, DeliveryDate, Timezone, Status, SignatureUrl }) => {
  return (
    <tr>
      <td className="">{DeliveryDate + ' ' + Timezone}</td>
      <td className="">{Message}</td>
      <td className="">{Status}</td>
      <td className="center-align">{SignatureUrl && <img src={SignatureUrl} style={{ width: 120 }}></img>}</td>
    </tr>
  );
};

export default CarePatientMessageItemRow;
