import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useState } from 'react';
import { GroupCategory } from './types';

const LIMIT = 20;

const useCategories = () => {
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [categories, setCategories] = useState<GroupCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const getCategories = () => {
    if (isLoading || !hasMore) return false;
    setIsLoading(true);
    httpApi
      .get(`/groupCategories?limit=${LIMIT}&page=${page}`)
      .then(({ data }) => {
        if (data) {
          setTotal(data.totalGroups);
          setCategories(prevCategories => [...prevCategories, ...data.groupCategories]);
          setPage(prevPage => prevPage + 1);
          setIsLoading(false);
          if (data?.groupCategories?.length === 0) {
            setHasMore(false);
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const loadMore = () => {
    console.log('ylog load more page', page);
    console.log('load more hasMore', hasMore);
    console.log('load more isLoading', isLoading);
    if (hasMore && !isLoading) {
      console.log('load more yes');
      getCategories();
    }
  };

  return {
    categories,
    getCategories,
    isLoading,
    loadMore,
    hasMore,
    total,
  };
};

export default useCategories;
