import { FC } from 'react';
import styled from 'styled-components';
import { UIButton } from '../../../components/UI/Buttons';

const Container = styled.div`
  border-radius: 5px;
  background: #ebf2fe;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  ${(props: { style?: React.CSSProperties }) => props.style}
`;

const Title = styled.p`
  color: #0a313f;
  font-style: italic;
  font-size: 20px;
  padding: 0 20px;
`;

const Bottom = styled.div`
  padding: 0 20px;
  margin-top: auto;
`;

const Content = styled.div`
  padding-bottom: 30px;
`;

export type GroupContainerProps = {
  children?: React.ReactNode;
  title?: string;
  buttonLabel?: string;
  buttonOnClick?: () => void;
  containerStyle?: React.CSSProperties;
};

const GroupContainer: FC<GroupContainerProps> = ({ children, title, buttonLabel, buttonOnClick, containerStyle }) => {
  return (
    <Container style={containerStyle} className="ui-group-container">
      <Content>
        {title && <Title>{title}</Title>}
        {children}
      </Content>

      {buttonLabel && (
        <Bottom>
          <UIButton text={buttonLabel} onClick={buttonOnClick} variant="secondary" />
        </Bottom>
      )}
    </Container>
  );
};

export default GroupContainer;
