import styled from 'styled-components';

const PaginationMainContainer = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const PaginationContainer = styled.div`
  height: 70px;
  width: auto; /* Adjust width as needed */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #bec4cc;
  border-radius: 100px;
  margin-bottom: 5%;
  margin-top: 5%;
`;

const NumberContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${props => (props.isActive ? '#0092FF' : 'transparent')};
  border-radius: 50%; /* For a circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  width: 50px;
  height: 50px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Pagination = ({ pages, setCurrentPageCallBack, currentPage }) => {
  console.log('number of pages: ', pages);
  const validateBackPage = currentPage - 1 <= 0;
  const validateNextPage = currentPage + 1 > pages.length;
  const backPage = (currentPage: number) => {
    setCurrentPageCallBack(currentPage - 1);
  };
  const nextPage = (currentPage: number) => {
    setCurrentPageCallBack(currentPage + 1);
  };
  const handlePageOnChange = (pageNumber: number) => {
    setCurrentPageCallBack(pageNumber);
  };
  return (
    <PaginationMainContainer>
      <PaginationContainer>
        <ButtonContainer onClick={() => (validateBackPage ? null : backPage(currentPage))} disabled={currentPage === 1}>
          <p style={{ margin: 0 }}>{'<'}</p>
        </ButtonContainer>
        {pages.map(number => (
          <NumberContainer key={number} isActive={currentPage === number} onClick={() => handlePageOnChange(number)}>
            <p style={{ textAlign: 'center', margin: 0, color: currentPage === number ? 'white' : 'black' }}>{number}</p>
          </NumberContainer>
        ))}
        <ButtonContainer onClick={() => (validateNextPage ? null : nextPage(currentPage))} disabled={currentPage === pages.length}>
          <p style={{ margin: 0 }}>{'>'}</p>
        </ButtonContainer>
      </PaginationContainer>
    </PaginationMainContainer>
  );
};

export default Pagination;
