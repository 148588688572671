import { NucleusTabProps } from 'components/UI';

export const ScheduleTabs: NucleusTabProps[] = [
  {
    route: '/provider/schedule',
    title: 'Schedule',
    slug: 'todays-schedule',
    disabled: false,
    fields: ['field1', 'field2', 'field3'],
  },
  {
    route: '/provider/schedule/availability',
    title: 'Schedule Availability',
    slug: 'schedule-availability',
    disabled: false,
    fields: ['field1', 'field2', 'field3'],
  },
];
