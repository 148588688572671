import { FC } from 'react';
import styled from 'styled-components';
import { UIButton } from '../UI/Buttons';

const Container = styled.div`
  border-radius: 5px;
  background: #ebf2fe;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  ${(props: { style?: React.CSSProperties }) => props.style}
`;

const Title = styled.p`
  color: #0a313f;
  font-style: italic;
  font-size: 20px;
  padding: 0 20px;
`;

const Bottom = styled.div`
  padding: 0 20px;
  margin-top: auto;
`;

const Content = styled.div`
  padding-bottom: 30px;
`;
const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export type GroupContainerProps = {
  children?: React.ReactNode;
  title?: string;
  buttonLabel?: string;
  buttonOnClick?: () => void;
  containerStyle?: React.CSSProperties;
  editButtonLabel?: string;
  editButtonOnClick?: () => void;
  showEditTitle?: boolean;
};

const GroupContainer: FC<GroupContainerProps> = ({ children, title, buttonLabel, buttonOnClick, containerStyle, editButtonLabel, editButtonOnClick, showEditTitle = false }) => {
  return (
    <Container style={containerStyle} className="ui-group-container">
      <Content>
        <TopBarContainer>
          {title && <Title>{title}</Title>}
          {showEditTitle && (
            <button
              onClick={editButtonOnClick}
              style={{
                backgroundColor: 'transparent',
                color: '#0092FF',
                border: 'none',
                padding: '10px 20px',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              {editButtonLabel || 'Edit'}
            </button>
          )}
        </TopBarContainer>
        {children}
      </Content>

      {buttonLabel && (
        <Bottom>
          <UIButton text={buttonLabel} onClick={buttonOnClick} variant="secondary" />
        </Bottom>
      )}
    </Container>
  );
};

export default GroupContainer;
