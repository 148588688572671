//@ts-nocheck
import React from 'react';
import { MessagesStore } from '../../stores';

class CarePatientScheduledMessageItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editStyle: ' ',
    };
    //console.log(this.state.patientID);
    this.handleEditMessage = this.handleEditMessage.bind(this);
    this.handleRemoveMessage = this.handleRemoveMessage.bind(this);

    this.editable = true;
  }

  componentDidMount() {
    ////console.log("MESSAGE ID ", this.props.MessageID);
    /*
        let patientInfo = MessagesStore.getPatientInfo(this.props.PatientID);
        console.log("PatientInfo:", patientInfo);
        if (patientInfo){
            this.setState({
                assignmentPatientName : patientInfo.Name
            });
        }
        */
    //let utcNowString = CurrentMomentUTC.toLocaleDateString() + " " + CurrentMomentUTC.toLocaleTimeString();

    //console.log("CurrentMomentUTC", this.props.NowUTC);
    const deliveryDateTimeUTC = new Date(this.props.DeliveryDateTimeUTC);
    //console.log("deliveryDateTimeUTC", deliveryDateTimeUTC);

    const diff = Math.abs(deliveryDateTimeUTC - this.props.NowUTC);
    const minsBeforeDelivery = Math.floor(diff / 1000 / 60);

    if (deliveryDateTimeUTC > this.props.NowUTC && minsBeforeDelivery > 5) {
      this.editable = true;
      this.setState({
        editStyle: ' ',
      });
      //console.log("El Delivery es en el Futuro aun. Mins: ", minsBeforeDelivery);
    } else {
      this.editable = false;
      this.setState({
        editStyle: ' icon-disabled',
      });
      //console.log("El Delivery ya paso. Mins: ", minsBeforeDelivery);
    }
    return;
  }

  componentWillUnmount() {}

  handleEditMessage() {
    if (this.editable) {
      MessagesStore.getPatientMessageDetails({
        PatientMessageID: this.props.PatientMessageID,
      });
    } else {
      ////MessagesStore.openNotEditableMessage();
    }

    // GeneralStore.requestOpenEditMessageModal({
    //     PatientMessageID : this.props.PatientMessageID,
    //     MessageID : this.props.MessageID,
    //     Message: this.props.Message,
    //     CreatedDateTime : this.props.CreatedDate,
    //     DeliveryDateTimeTime : this.props.DeliveryDateTime,
    //     Status : this.props.Status,
    //     Editable : this.props.CanEdit
    // });
  }

  handleRemoveMessage() {
    if (this.editable) {
      MessagesStore.handleDeleteMessage({
        PatientMessageID: this.props.PatientMessageID,
      });
    } else {
      MessagesStore.openNotDeleteMessage();
    }
  }

  render() {
    // console.log("Name: ", this.state.assignmentPatientName);
    // console.log("Message: ", this.state.assignmentMessage);
    // console.log("Date: ", this.state.assignmentDate);
    // console.log("Delivered: ", this.state.assignmentSmsDelivered);
    // console.log("Read: ", this.state.assignmentMessageRead);
    //let noteDate = new Date(this.props.CreatedDate).toLocaleDateString() + " " + new Date(this.props.CreatedDate).toLocaleTimeString();

    ////console.log("DATE: ", new Date(this.props.CreatedDate).toLocaleDateString()+ " " + new Date(this.props.CreatedDate).toLocaleTimeString());

    // let shortMessage = this.props.Message;
    // if (shortMessage != null && shortMessage.length > 55){
    //     shortMessage = shortMessage.substring(0, 55) + " ...";
    // }

    return (
      <tr>
        <td className="">{this.props.DeliveryDateTime + ' ' + this.props.Timezone}</td>
        <td className="">{this.props.RecurringText}</td>
        <td className="">{this.props.Message}</td>
        {/* <td className="" >{ this.props.Category }</td> */}
        <td className="center-align">
          <span class={'nucleus-table-icon nucleus-link ' + this.state.editStyle + ' '} onClick={this.handleEditMessage}>
            <i className="Tiny material-icons">edit</i>
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span className="nucleus-table-icon nucleus-link" onClick={this.handleRemoveMessage}>
            <i className="Tiny material-icons">cancel</i>
          </span>
        </td>
      </tr>
    );
  }
}

export default CarePatientScheduledMessageItemRow;
