//@ts-nocheck
import { RootState, getAccountAppsThunk, resetAccountAppThunk, updateAccountAppsConfigThunk } from '@nucleus-care/nucleuscare-backend-client';
import React from 'react';
import { useState, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
  //DefaultRootState
} from 'react-redux';

import { SpinnerCircular } from 'spinners-react';

import AuthStore from '../../stores/AuthStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';

import AppTableRow from './AppTableRow';

const ExternalAppsPage = () => {
  const dispatch = useDispatch();

  const accountAppsStore = useSelector((state: RootState) => {
    //console.log("ExternalAppsPage useSelector", state.accountExternalApps.accountApps);
    return state.accountExternalApps.accountApps;
  });

  const [stateLoading, setStateLoading] = useState(true);
  const [stateSearchText, setStateSearchText] = useState('');

  const [stateSortingStyles, setStateSortingStyles] = useState({
    startNameAsc: ' nucleus-icon-inactive ',
    startNameDesc: ' nucleus-icon-inactive hide ',
    // accountAsc : " nucleus-icon-inactive ",
    // accountDesc : " nucleus-icon-inactive hide "
  });

  const [stateSortingMode, setStateSortingMode] = useState('startNameAsc');

  const handleSortingMode = (tag, tag2) => {
    console.log('handleSortingMode', tag, tag2);
    const object = {
      startNameAsc: ' nucleus-icon-inactive ',
      startNameDesc: ' nucleus-icon-inactive hide ',
      // accountAsc : " nucleus-icon-inactive ",
      // accountDesc : " nucleus-icon-inactive hide "
    };
    object[tag] = ' nucleus-icon-active ';
    object[tag2] = ' nucleus-icon-inactive hide ';
    setStateSortingStyles(object);
    setStateSortingMode(tag);
  };

  const [stateSaveButtonStyle, setStateSaveButtonStyle] = useState('nucleus-submit-btn');

  const [stateResetAppData, setStateResetAppData] = useState({
    accountId: AuthStore.getUserAccountID().toLowerCase(),
    externalAppId: null,
    appEnabled: false,
    appName: '',
    overwriteConfig: false,
  });

  const [stateUpdateMode, setStateUpdateMode] = useState(1);
  useEffect(() => {
    //console.log("ExternalAppsPage useEffect");
    validateAccessGranted('/externalApps');

    refreshReport();
    $('#modalConfirmConfig').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    $('#modalConfirmReset').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    $('#modalEnableAllApps').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    $('#modalDisableAllApps').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }, []);

  useEffect(() => {
    //console.log("ExternalAppsPage useEffect accountAppsStore", accountAppsStore);
    if (!accountAppsStore.getLoading) {
      if (accountAppsStore.getComplete) {
        //Message.show("Report updated");
        setStateLoading(false);
      }
      // dispatch(
      // 	reportsSliceClean()
      // );
    } else if (accountAppsStore.error) {
      Message.show('Error getting Apps');
    }
  }, [accountAppsStore.getLoading, accountAppsStore.getComplete]);

  useEffect(() => {
    //console.log("ExternalAppsPage useEffect accountAppsStore", accountAppsStore);
    if (!accountAppsStore.resetLoading) {
      if (accountAppsStore.resetComplete) {
        if (stateUpdateMode == 1) {
          //Message.show("App updated");
          setStateLoading(false);
          $('#modalConfirmConfig').modal('close');
        } else {
          setStateSaveButtonStyle('nucleus-submit-btn');
          Message.show('All Apps config updated');
          $('#modalEnableAllApps').modal('close');
          $('#modalDisableAllApps').modal('close');
        }
      }
      refreshReport();
    } else if (accountAppsStore.error) {
      Message.show('Error getting Apps');
    }
  }, [accountAppsStore.resetLoading, accountAppsStore.resetComplete]);

  const onSearchTextChange = textInput => {
    console.log('onSearchTextChange', textInput.target.value);
    setStateSearchText(textInput.target.value);

    // if (textInput.target.value != ""){
    //     setStateExportFileName(stateExportFileName + "_" + textInput.target.value);
    // }
    // else{
    //     setStateExportFileName(stateExportFileName);
    // }
  };

  const refreshReport = () => {
    console.log('refreshReport');
    setStateLoading(true);
    dispatch(getAccountAppsThunk(AuthStore.getUserAccountID()));
  };

  const accountAppConfigChange = appData => {
    console.log('accountAppConfigChange', appData);
    console.log('accountAppConfigChange stateResetAppData', stateResetAppData);
    const tempObject = Object.assign({}, stateResetAppData);
    tempObject.externalAppId = appData.ExternalAppID;
    (tempObject.appEnabled = appData.enabled), (tempObject.appName = appData.Name), (tempObject.overwriteConfig = false);
    setStateResetAppData(tempObject);

    if (appData.enabled) {
      $('#modalConfirmConfig').modal('open');
      setStateSaveButtonStyle('nucleus-submit-btn');
    } else {
      $('#modalConfirmReset').modal('open');
      setStateSaveButtonStyle('nucleus-submit-btn');
    }
  };

  const saveAccountConfig = overwriteConfig => {
    console.log('saveAccountConfig', overwriteConfig);
    setStateSaveButtonStyle('nucleus-submit-btn-disabled');
    setStateUpdateMode(1);
    if (overwriteConfig) {
      dispatch(
        resetAccountAppThunk({
          accountId: stateResetAppData.accountId,
          appEnabled: stateResetAppData.appEnabled,
          externalAppId: stateResetAppData.externalAppId,
          overwriteConfig: true,
        }),
      );
    } else {
      dispatch(
        resetAccountAppThunk({
          accountId: stateResetAppData.accountId,
          appEnabled: stateResetAppData.appEnabled,
          externalAppId: stateResetAppData.externalAppId,
          overwriteConfig: false,
        }),
      );
      $('#modalConfirmConfig').modal('close');
    }
  };

  const saveResetConfig = save => {
    console.log('saveResetConfig', save);
    $('#modalConfirmReset').modal('close');
    setStateSaveButtonStyle('nucleus-submit-btn-disabled');
    setStateUpdateMode(1);
    if (save) {
      // Disabled for the Account
      setStateLoading(true);
      dispatch(
        resetAccountAppThunk({
          accountId: stateResetAppData.accountId,
          externalAppId: stateResetAppData.externalAppId,
          appEnabled: false,
          overwriteConfig: false,
        }),
      );
    }
  };

  const handleEnableAllApps = () => {
    console.log('handleEnableAllApps');
    setStateSaveButtonStyle('nucleus-submit-btn');
    $('#modalEnableAllApps').modal('open');
  };

  const onEnableAllApps = overwriteConfig => {
    console.log('onEnableAllApps overwriteConfig', overwriteConfig);
    setStateSaveButtonStyle('nucleus-submit-btn-disabled');
    setStateUpdateMode(2);

    dispatch(
      updateAccountAppsConfigThunk({
        accountId: AuthStore.getUserAccountID().toLowerCase(),
        appsEnabled: true,
        overwriteConfig: overwriteConfig,
      }),
    );
  };

  const handleDisableAllApps = () => {
    console.log('handleDisableAllApps');
    setStateSaveButtonStyle('nucleus-submit-btn');
    $('#modalDisableAllApps').modal('open');
  };

  const onDisableAllApps = save => {
    console.log('onDisableAllApps', save);
    setStateSaveButtonStyle('nucleus-submit-btn-disabled');
    setStateUpdateMode(2);

    if (save) {
      dispatch(
        updateAccountAppsConfigThunk({
          accountId: AuthStore.getUserAccountID().toLowerCase(),
          appsEnabled: false,
          overwriteConfig: false,
        }),
      );
    } else {
      $('#modalDisableAllApps').modal('close');
    }
  };

  const getTableRows = () => {
    console.log('render getTableRows>', accountAppsStore);
    const appsRowArray = [];
    if (accountAppsStore && accountAppsStore.apps && accountAppsStore.apps.length > 0) {
      let array = [].concat(accountAppsStore.apps);

      if (stateSortingMode == 'startNameDesc') {
        array = array.sort((a, b) => {
          const textA = a.Name.toLowerCase();
          const textB = b.Name.toLowerCase();
          if (textB < textA)
            //sort string ascending
            return -1;
          if (textB > textA) return 1;
          return 0;
        });
      } else if (stateSortingMode == 'startNameAsc') {
        array = array.sort((a, b) => {
          const textA = a.Name.toLowerCase();
          const textB = b.Name.toLowerCase();
          if (textA < textB)
            //sort string ascending
            return -1;
          if (textA > textB) return 1;
          return 0;
        });
      }

      if (stateSearchText.length == 0) {
        array.map((appData, key) => {
          appsRowArray.push(<AppTableRow key={key} data={appData} mdmAccountApps={accountAppsStore.mdmAccountApps} onToggle={accountAppConfigChange}></AppTableRow>);
        });
      } else {
        const text = stateSearchText.toLocaleLowerCase();
        array.map((appData, key) => {
          if (
            appData.Name.toLowerCase().indexOf(text) >= 0
            //(identifier.toLowerCase().indexOf(text) >= 0) ||
          ) {
            appsRowArray.push(<AppTableRow key={key} data={appData} mdmAccountApps={accountAppsStore.mdmAccountApps} onToggle={accountAppConfigChange}></AppTableRow>);
          }
        });
      }
      //console.log("Generated array", outputArray);
      if (appsRowArray && appsRowArray.length > 0) {
        return <tbody>{appsRowArray}</tbody>;
      } else {
        return (
          <tbody className="center-align ">
            <tr style={{ borderBottomStyle: 'none' }}>
              <td colSpan="7" className="center-align ">
                <br />
                <br />
                <br />
                <span className="nucleus-table-header-medium text-gray"> {'There are no apps with this name'} </span>
                <br />
                <br />
                <br />
              </td>
            </tr>
          </tbody>
        );
      }
    }
    return (
      <tbody className="center-align ">
        <tr style={{ borderBottomStyle: 'none' }}>
          <td colSpan="7" className="center-align ">
            <br />
            <br />
            <br />
            <span className="nucleus-table-header-medium text-gray"> {'There are no apps in the system'} </span>
            <br />
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <div className="nucleus-hard-container">
      <div className="nucleus-all-scrollable-page-medium no-margin">
        <div>
          <p className="nucleus-tools-page-title">
            <b>Apps</b>
          </p>
        </div>
        <div style={{ marginTop: 0, marginBottom: 0, height: 56 }}>
          <div className="row left-align">
            <div className="col s9 m7 no-margin">
              <span className="nucleus-tools-page-text-title a-bit-lower">Search &nbsp;&nbsp;&nbsp;</span>
              <input type="text" className="nucleus-input-form-small-left" style={{ width: 500 }} onChange={onSearchTextChange} placeholder={'Search for an App'} />
              &nbsp;&nbsp;&nbsp;
              <a
                className="txt-white nucleus-font-small nucleus-submit-btn-small"
                onClick={() => {
                  refreshReport();
                }}
              >
                {' '}
                Refresh
              </a>
            </div>

            <div className="col s3 m5 no-margin right-align">
              &nbsp;&nbsp;
              {!accountAppsStore.mdmAccountApps && (
                <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={handleEnableAllApps}>
                  {' '}
                  Enable All Apps
                </a>
              )}
              &nbsp;&nbsp;
              {!accountAppsStore.mdmAccountApps && (
                <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={handleDisableAllApps}>
                  {' '}
                  Disable All Apps
                </a>
              )}
              <div style={{ margin: 0, padding: 0, marginTop: 9, marginBottom: 0 }}>
                {stateLoading && <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness="100" />}
                {!stateLoading && (
                  <div>
                    <p></p>
                    <br />
                    <br />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="users-table-pending altoImportantContainer">
          {accountAppsStore.mdmAccountApps && (
            <div className="row left-align">
              <span className="nucleus-table-header-medium text-gray"> {`Apps set by the Nucleus MDM`} </span>
            </div>
          )}
          <table className="bordered highlight nucleus-table">
            <thead>
              <tr>
                <th className="table-col-30">
                  <span className="nucleus-table-header-medium ">{'System Apps'}</span>
                  <a class={'nucleus-font-small ' + stateSortingStyles.startNameDesc} onClick={() => handleSortingMode('startNameAsc', 'startNameDesc')}>
                    {' '}
                    &nbsp;▼
                  </a>
                  <a class={'nucleus-font-small ' + stateSortingStyles.startNameAsc} onClick={() => handleSortingMode('startNameDesc', 'startNameAsc')}>
                    &nbsp;▲
                  </a>
                </th>
                <th className="table-col-20 left-align">{!accountAppsStore.mdmAccountApps && <span className="nucleus-table-header-medium">{'Make Available'}</span>}</th>
                <th className="table-col-50"></th>
              </tr>
            </thead>
            {getTableRows()}
          </table>
        </div>
        <div id="modalConfirmConfig" className="modal modalScrollableMedium">
          <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
            <img
              className="responsive-img img-close-modal"
              src="/img/close_modal.png"
              onClick={() => {
                $('#modalConfirmConfig').modal('close');
              }}
            />
            <h3 className="nucleus-page-subtitle">{'Make App Available'}</h3>
            <br />
            <br />
            <div className="row">
              <span className="nucleus-labels nucleus-font nucleus-font-14">
                {' '}
                {"You are allowing the app '" + stateResetAppData.appName + "' to be in the config section for every " + AuthStore.getPatientLabel().toLowerCase()}.{' '}
              </span>
              <br />
              <span className="nucleus-labels nucleus-font nucleus-font-14">
                {' '}
                Do you want to make the app available now for all {AuthStore.getPatientLabelPlural().toLowerCase()}?{' '}
              </span>
            </div>
            <div className="input-field col s12 modalCallLogContent2">
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      saveAccountConfig(false);
                    }}
                  >
                    No
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      saveAccountConfig(true);
                    }}
                  >
                    Yes
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalConfirmReset" className="modal modalScrollableMedium">
          <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
            <img
              className="responsive-img img-close-modal"
              src="/img/close_modal.png"
              onClick={() => {
                $('#modalConfirmReset').modal('close');
              }}
            />
            <h3 className="nucleus-page-subtitle">{'Disable App'}</h3>
            <br />
            <br />
            <div className="row">
              <span className="nucleus-labels nucleus-font nucleus-font-14">
                This will remove the app for all {AuthStore.getPatientLabelPlural().toLowerCase()}.
                <br />
                Are you sure you want to continue?
              </span>
            </div>
            <div className="input-field col s12 modalCallLogContent2">
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      saveResetConfig(false);
                    }}
                  >
                    No
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      saveResetConfig(true);
                    }}
                  >
                    Yes
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalEnableAllApps" className="modal modalScrollableMedium">
          <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
            <img
              className="responsive-img img-close-modal"
              src="/img/close_modal.png"
              onClick={() => {
                $('#modalEnableAllApps').modal('close');
              }}
            />
            <h3 className="nucleus-page-subtitle">{'Enable All Apps'}</h3>
            <br />
            <br />
            <div className="row">
              <span className="nucleus-labels nucleus-font nucleus-font-14">{'You are enabling all apps for this account.'}.</span>
              <br />
              <span className="nucleus-labels nucleus-font nucleus-font-14">
                {'Do you want to enable all the apps for every ' + AuthStore.getPatientLabel().toLowerCase() + ' as well? '}
              </span>
            </div>
            <div className="input-field col s12 modalCallLogContent2">
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      onEnableAllApps(false);
                    }}
                  >
                    No
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      onEnableAllApps(true);
                    }}
                  >
                    Yes
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalDisableAllApps" className="modal modalScrollableMedium">
          <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
            <img
              className="responsive-img img-close-modal"
              src="/img/close_modal.png"
              onClick={() => {
                $('#modalDisableAllApps').modal('close');
              }}
            />
            <h3 className="nucleus-page-subtitle">{'Disable All Apps'}</h3>
            <br />
            <br />
            <div className="row">
              <span className="nucleus-labels nucleus-font nucleus-font-14">
                This will disable all the apps for every {AuthStore.getPatientLabel().toLowerCase()} of the Account.
                <br />
                Are you sure you want to continue?
              </span>
            </div>
            <div className="input-field col s12 modalCallLogContent2">
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      onDisableAllApps(false);
                    }}
                  >
                    No
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a
                    class={'txt-white nucleus-font ' + stateSaveButtonStyle}
                    onClick={() => {
                      onDisableAllApps(true);
                    }}
                  >
                    Yes
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExternalAppsPage;
