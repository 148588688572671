import { getUserLoginProviderDebounced, resetUserLoginProvider } from '@nucleus-care/react-native-nucleuscare-connect-security-client';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link, Navigate, useLocation } from 'react-router-dom';
import packageJson from '../../package.json';

import Config from '../Config';
import { useUserMeSelector } from '../selectors/user';
import AuthStore from '../stores/AuthStore';
import Loader from '../utils/Loader';

const RedirectIfAuthenticated = () => {
  const location = useLocation();
  const user = useUserMeSelector();
  const dispatch = useDispatch();
  useEffect(() => {
    if (AuthStore.isAuthenticated()) {
      AuthStore.getMe(dispatch).catch(() => {
        console.log('Invalid token not redirecting to dashboard');
      });
    }
  }, []);

  if (user) {
    if (user.Created) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />;
    } else {
      return <Navigate to="/completeProfile" state={{ from: location }} />;
    }
  }
  return null;
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      doneFetchingMe: false,
      loginFailedStyle: ' hide',
      btnStyle: ' nucleus-submit-btn-disabled',
      loginFailedText: '',
    };

    this.onAuthStoreLogin = this.onAuthStoreLogin.bind(this);
    this.onGetMe = this.onGetMe.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
    this.validateButton = this.validateButton.bind(this);
    this.handleProviderLoginSuccess = this.handleProviderLoginSuccess.bind(this);
    this.handleLoginChange = this.handleLoginChange.bind(this);

    window.NUCLEUS_VER = Config.nucleusVersion;
  }

  componentDidMount() {
    //Register Listener
    AuthStore.on('onAuthStoreLogin', this.onAuthStoreLogin);
    AuthStore.on('onGetMe', this.onGetMe);
    // this.setState({
    //   loginFailedStyle: ' hide',
    // });
    AuthStore.requestCurrentIP();
    //console.log("LOGIN",this.refs.nucleusUsername.value);
  }

  componentWillUnmount() {
    //Remove Listener
    AuthStore.removeListener('onAuthStoreLogin', this.onAuthStoreLogin);
  }

  async doLogin() {
    const platformInformation = {
      ClientIPAddress: AuthStore.getCurrentIP(),
      ClientUserAgent: window.navigator.userAgent,
      WebControlVersion: window.NUCLEUS_VER,
    };

    this.setState({
      loginFailedStyle: ' hide',
      loading: true,
    });

    await AuthStore.login({
      Email: this.refs.nucleusUsername.value,
      Password: this.refs.nucleusPassword.value,
      PlatformInformation: JSON.stringify(platformInformation),
      dispatch: this.props.dispatch,
    });

    this.setState({
      loading: false,
    });
  }

  handleLoginChange({ currentTarget }) {
    const { dispatch } = this.props;
    getUserLoginProviderDebounced(currentTarget.value, dispatch);
    this.validateButton();
    if (currentTarget.value.length === 0) {
      dispatch(resetUserLoginProvider());
    }
  }

  validateFields() {
    const emailRegex = /^\w+([.+ -]?[\w]+)*@\w+([.-]?[\w]+)*(\.\w{2,15})+$/;

    if (!emailRegex.test(this.refs.nucleusUsername.value)) {
      //Materialize.toast('Ups! The email is not valid', 3000, 'rounded');
      this.setState({
        loginFailedStyle: ' ',
        loginFailedText: 'Email invalid.',
      });
      return;
    }
    if (this.refs.nucleusPassword.value === '') {
      Materialize.toast('Hey, you forgot the password!', 3000, 'rounded');
      return;
    }

    this.doLogin();
  }

  validateFieldsFromKeyboard(e) {
    if (e.charCode == 13 || e.keyCode == 13 || e.key == 13) {
      this.validateFields();
    }
  }

  validateButton() {
    //console.log("validateButton");

    this.setState({
      loginFailedStyle: ' hide',
    });

    if (this.refs.nucleusUsername.value.length > 0 && this.refs.nucleusPassword.value.length > 0) {
      this.setState({
        btnStyle: ' nucleus-submit-btn',
      });
    } else {
      this.setState({
        btnStyle: ' nucleus-submit-btn-disabled',
      });
    }
  }

  onAuthStoreLogin(loginResponse) {
    const { location } = this.props;

    console.log('onAuthStoreLogin', loginResponse);
    if (loginResponse.ok) {
      if (loginResponse.profileCreated) {
        if (loginResponse.twoFactorRequired) {
          this.props.history.push({
            pathname: '/login2Factor',
            state: {
              email: loginResponse.email,
              telephone: loginResponse.telephone,
            },
          });
        } else {
          const { from } = location.state || { from: { pathname: '/dashboard' } };
          this.props.history.replace(from);
        }
      } else {
        if (loginResponse.twoFactorRequired) {
          this.props.history.push({
            pathname: '/login2Factor',
            state: {
              email: loginResponse.email,
              telephone: loginResponse.telephone,
            },
          });
        } else {
          this.props.history.push('/completeProfile');
        }
      }
    } else {
      //Message.show("Invalid login");

      let errorMessage = loginResponse.loginError ? loginResponse.loginError : 'Email address and password do not match.';

      if (loginResponse.internetError) {
        errorMessage = loginResponse.internetError;
      }

      this.setState({
        loginFailedStyle: ' ',
        loginFailedText: errorMessage,
        loading: false,
      });
    }
  }

  handleProviderLoginSuccess(rsp) {
    const { dispatch } = this.props;
    AuthStore.loginProvider(dispatch, rsp);
  }

  onGetMe() {
    this.setState({
      doneFetchingMe: true,
    });
  }

  getVersion = () => {
    let version = 'v 2';
    try {
      version = 'v ' + packageJson.version;
    } catch (error) {
      console.warn('Error getVersion cannot read packageJson');
    }
    return version;
  };

  render() {
    const { canUserLoginWithSalesforce } = this.props;
    const style = {
      height: window.innerHeight,
      background: '#008FFF',
    };

    return (
      <div className="nucleus-login " style={style}>
        <RedirectIfAuthenticated />
        <div className="alto valign-wrapper">
          <div className="row ancho">
            <div className="col s2 m3 l3 border"></div>
            <div className="col s8 m6 l6 border center-align">
              <img className="responsive-img" src="/img/nucleus_2024_logo.png" />
              <br />
              <br />
              <br />
              <p id={'welcome-text'} className="txt-white nucleus-page-subtitle">
                Welcome !
              </p>

              <p className="login-info login-error nucleus-font ">
                <span className={' ' + this.state.loginFailedStyle}> {this.state.loginFailedText} </span> &nbsp;{' '}
              </p>

              <div className="row center-align no-margin">
                <div className="input-field col s1 m3 l3"></div>
                <div className="input-field col s10 m6 l6">
                  <input
                    id={'email-input'}
                    ref="nucleusUsername"
                    type="email"
                    name="nucleus-user"
                    className="validate nucleus-input-login nucleus-font"
                    placeholder="Email"
                    onKeyPress={this.validateFieldsFromKeyboard}
                    onChange={this.handleLoginChange}
                  />
                </div>
                <div className="input-field col s1 m3 l3"></div>
              </div>

              {!canUserLoginWithSalesforce ? (
                <div className="row center-align no-margin">
                  <div className="input-field col s1 m3 l3"></div>
                  <div className="input-field col s10 m6 l6">
                    <input
                      id={'password-input'}
                      ref="nucleusPassword"
                      type="password"
                      name="nucleus-password"
                      className="validate nucleus-input-login nucleus-font"
                      placeholder="Password"
                      onKeyPress={this.validateFieldsFromKeyboard}
                      onChange={this.validateButton}
                    />
                    <Link id={'forgot-password'} to="/forgot" className="forgot-style">
                      <span className="txt-white nucleus-font nucleus-font-small">Forgot your password?</span>
                    </Link>
                  </div>
                  <div className="input-field col s1 m3 l3"></div>
                </div>
              ) : null}
              <br />
              <br />

              <div className="row center-align">
                <div className="col s12">
                  {!canUserLoginWithSalesforce ? (
                    <a id="submit-login" className={'txt-white nucleus-font-small nucleus-font ' + this.state.btnStyle} onClick={this.validateFields}>
                      {this.state.loading ? (
                        <Loader
                          style={{
                            top: 5,
                          }}
                        />
                      ) : (
                        'Submit'
                      )}
                    </a>
                  ) : null}
                  {/* <LoginWithSalesforceButton onUserSuccess={this.handleProviderLoginSuccess} />  */}
                </div>
              </div>
              <br />
              <div className="row center-align no-margin">
                <p className="nucleus-font-book no-margin" style={{ color: '#FFFFFF80', fontSize: 12 }}>
                  <span>{this.getVersion()}</span>
                </p>
              </div>
            </div>
            <div className="col s2 m3 l3 border"></div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

function mapStateToProps({ userLoginProvider }) {
  return {
    canUserLoginWithSalesforce: userLoginProvider.userLoginProvider.provider.salesforce,
  };
}

const LoginFC = props => {
  return useReactRouterProps(Login, props);
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginFC);
