import React from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  flex: 1;
  padding: 9px;
  background-color: white;
`;

interface NucleusColumnContentProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const UINucleusColumnContent: React.FC<NucleusColumnContentProps> = ({ children, style }) => {
  return <ContentContainer style={style}>{children}</ContentContainer>;
};

export default UINucleusColumnContent;
