import React from 'react';

interface VideoCallSvgProps {
  width?: number;
  height?: number;
  strokeColor?: string;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const VideoCallSvg: React.FC<VideoCallSvgProps> = ({
  onClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
  },
  width = 26,
  height = 24,
  strokeColor = '#0A313F',
}) => {
  return (
    <svg style={{ cursor: 'pointer' }} onClick={onClick} width={width} height={height} viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="4" width="16.8" height="16" rx="2.5" stroke={strokeColor} strokeWidth="2" />
      <path
        d="M17.8003 10.3515L23.7366 6.12948C24.2663 5.75278 25.0003 6.13146 25.0003 6.78142V17.2308C25.0003 17.8784 24.271 18.2576 23.7409 17.8858L17.8003 13.7186V10.3515Z"
        stroke={strokeColor}
        strokeWidth="2"
      />
    </svg>
  );
};

export default VideoCallSvg;
