import { startCall } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import { CallingMethods } from 'components/WebRTCCallView';
import React from 'react';
import { connect } from 'react-redux';
import { AuthStore, CareQueueStore } from 'stores';
import Message from '../../utils/Message';

class QueueItemRow extends React.Component<
  {
    RequestID: string;
    PatientID: string;
    PatientName: string;
    patientFirstName: string;
    patientLastName: string;
    DeviceName: string;
    ConnectDevice: string;
    PatientTelephone: string;
    DeviceID: string;
    RequestTime: string;
    TimezoneString: string;
    Num: number;
    Notes: string;
    DeviceOffset: number;
    IsEmergency: boolean;
    Escalated: boolean;
    TextColorClass: string;
    dispatch: any;
  },
  {
    RequestID: string;
    patientID: string;
    patientName: string;
    patientTelephone: string;
    patientAddress: string;
    cancelIconVisibility: string;
  }
> {
  private datetimeFormat: string;
  private timeFormat: string;
  private dateOptions: Intl.DateTimeFormatOptions;
  private timeOptions: Intl.DateTimeFormatOptions;

  constructor(props) {
    super(props);
    this.state = {
      RequestID: this.props.RequestID,
      patientID: this.props.PatientID,
      patientName: '',
      patientTelephone: '',
      patientAddress: '',
      cancelIconVisibility: ' nucleus-link',
      //patientName:this.props.FirstName + ' ' + this.props.LastName
    };

    this.handleRowClicked = this.handleRowClicked.bind(this);
    this.handleQueueItem = this.handleQueueItem.bind(this);

    this.dateOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    };
    //this.dateOptions.timeZone = 'UTC';
    this.timeOptions = {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    };
    //this.timeOptions.timeZone = 'UTC';

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.datetimeFormat = 'm/d/Y h:i A';
      this.timeFormat = 'hh:mm A';
      this.timeOptions = {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
      };
    } else {
      this.datetimeFormat = 'm/d/Y H:i';
      this.timeFormat = 'HH:mm A';
      this.timeOptions = {
        hourCycle: 'h23',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
      };
    }
  }

  handleRowClicked() {
    //const callData = CareCommunicationStore.buildCallData(this.props as any, this.props.RequestID);
    const patientName = this.props.patientFirstName + ' ' + this.props.patientLastName;
    const deviceId = this.props.DeviceID.toLowerCase();
    const patientId = this.props.PatientID.toLowerCase();
    const participants: FullCallEntity[] = [
      {
        devicesIds: [deviceId],
        name: patientName,
        entityId: patientId,
        host: false,
        status: 'connected',
        type: CallType.Video,
      },
    ];

    const callData: WebRTCCall = {
      requestId: this.props.RequestID,
      patientId: patientId,
      callingMethod: CallingMethods.Call,
      participants,
      multiCall: false,
      patientFirstName: this.props.patientFirstName,
      patientLastName: this.props.patientLastName,
      patientName,
      deviceName: this.props.DeviceName,
      patientThumb: '',
      type: AuthStore.nucleusAutomaticCallRequestBargeIn ? CallType.BargeIn : CallType.Video,
    };

    this.props.dispatch(startCall(callData));
  }

  handleQueueItem() {
    console.log('handleQueueItem');

    CareQueueStore.requestOpenMarkAsHandledModal({
      RequestID: this.state.RequestID,
      UserID: AuthStore.getUserID(),
      PatientName: this.props.PatientName,
      StartTime: this.props.RequestTime,
      Emergency: this.props.IsEmergency,
      Escalated: this.props.Escalated,
      Requests: this.props.Num,
      Notes: this.props.Notes,
      DeviceOffset: this.props.DeviceOffset,
      TimezoneString: this.props.TimezoneString,
    });

    // GeneralStore.requestOpenDeleteModal({
    //     RequestID : this.state.RequestID,
    //     UserID : AuthStore.getUserID(),
    //     PatientName : this.props.PatientName
    // });

    // this.setState({
    //     cancelIconVisibility : ' nucleus-link-disabled'
    // });
  }

  onReportCallHandledAction(response) {
    console.log('onReportCallHandledAction');
    console.log(response);

    if (response.requestID == this.state.RequestID) {
      console.log('onReportCallHandledAction The choosen one');
      if (response.ok) {
        Message.show('Call request cancelled!');
      } else {
        Message.show('Error canceling the call request, please try again.');
      }
      this.setState({
        cancelIconVisibility: ' nucleus-link',
      });
    }
  }

  render() {
    let dateStrings = ''; // (new Date(this.props.RequestTime)).toString();

    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const requestDate = new Date(this.props.RequestTime); //).toUTCString();
    const requestDateClientOffset = new Date(requestDate);
    requestDateClientOffset.setHours(requestDateClientOffset.getHours() + this.props.DeviceOffset);
    const requestDateString = requestDateClientOffset.toLocaleDateString('en-US', this.dateOptions) + ' - ' + requestDateClientOffset.toLocaleTimeString('en-US', this.timeOptions);

    const today = new Date();
    const Days = today.getDate() - requestDate.getDate();
    const Months = today.getMonth() - requestDate.getMonth();
    const weeekDays = today.getDay() - requestDate.getDay();

    if (Months == 0) {
      if (Days < 1) {
        const timeStart = requestDate.getTime();
        const timeEnd = today.getTime();
        const hourDiff = timeEnd - timeStart; //in ms

        const hDiff = hourDiff / 3600 / 1000; //in hours
        const minDiff = hourDiff / 60 / 1000; //in minutes
        const secDiff = hourDiff / 1000; //in s

        const hours = Math.floor(hDiff);
        const minutes = Math.floor(minDiff - 60 * hours);
        const segundos = Math.floor(secDiff - 60 * minutes);

        // console.log("H: " + hDiff + ", M: " + minDiff + ", S: " + secDiff);
        // console.log("H: " + hours + ", M: " + minutes + ", S: " + segundos);

        if (hours >= 2) {
          dateStrings = hours + ' hrs ago';
        } else if (hours > 0) {
          dateStrings = hours + ' hr, ' + minutes + ' min ago';
        } else if (minutes >= 2) {
          dateStrings = minutes + ' min ago';
        } else if (minutes > 0) {
          dateStrings = minutes + ' min, ' + segundos + ' seconds ago';
        } else {
          dateStrings = segundos + ' seconds ago';
        }
      } else if (Days < 2) {
        //console.log("1");
        dateStrings =
          'Yesterday at ' +
          new Date(this.props.RequestTime).toLocaleTimeString('en-US', {
            hour: 'numeric',
            hourCycle: AuthStore.getTime12Hrs().toString() == 'true' ? 'h11' : 'h23',
            minute: 'numeric',
          });
      } else if (Days < 7 && weeekDays > 0) {
        //console.log("2");
        dateStrings =
          dayNames[requestDate.getDay()] +
          ' at ' +
          new Date(this.props.RequestTime).toLocaleTimeString('en-US', {
            hour: 'numeric',
            hourCycle: AuthStore.getTime12Hrs().toString() == 'true' ? 'h11' : 'h23',
            minute: 'numeric',
          });
      } else {
        //console.log("3");
        dateStrings =
          monthNames[requestDate.getMonth()] +
          ' ' +
          requestDate.getDate() +
          ', ' +
          requestDate.getFullYear() +
          ' at ' +
          new Date(this.props.RequestTime).toLocaleTimeString('en-US', {
            hour: 'numeric',
            hourCycle: AuthStore.getTime12Hrs().toString() == 'true' ? 'h11' : 'h23',
            minute: 'numeric',
          });
      }
    } else if ((Days < 7 || Days > -7) && weeekDays > 0) {
      //console.log("4");
      dateStrings =
        dayNames[requestDate.getDay()] +
        ' at ' +
        new Date(this.props.RequestTime).toLocaleTimeString('en-US', {
          hour: 'numeric',
          hourCycle: AuthStore.getTime12Hrs().toString() == 'true' ? 'h11' : 'h23',
          minute: 'numeric',
        });
    } else {
      //console.log("5");
      dateStrings =
        monthNames[requestDate.getMonth()] +
        ' ' +
        requestDate.getDate() +
        ', ' +
        requestDate.getFullYear() +
        ' at ' +
        new Date(this.props.RequestTime).toLocaleTimeString('en-US', {
          hour: 'numeric',
          hourCycle: AuthStore.getTime12Hrs().toString() == 'true' ? 'h11' : 'h23',
          minute: 'numeric',
        });
    }

    let patientName = this.props.PatientName;
    if (patientName != null && patientName.length > 29) {
      patientName = patientName.substring(0, 29) + ' ...';
    }

    let deviceName = this.props.DeviceName;
    if (deviceName != null && deviceName.length > 29) {
      deviceName = deviceName.substring(0, 29) + ' ...';
    }

    let patientPhone = this.props.PatientTelephone;
    if (patientPhone != null && patientPhone.length > 18) {
      patientPhone = patientPhone.substring(0, 18) + ' ...';
    }

    // Deprecated: Oct 19th 2017. CARE 1258
    // let patientAddress = this.state.patientAddress;
    // if (patientAddress != null && patientAddress.length > 35){
    //     patientAddress = patientAddress.substring(0, 35) + " ...";
    // }

    console.log('AuthStore.nucleusAutomaticCallRequestBargeIn', AuthStore.nucleusAutomaticCallRequestBargeIn);

    return (
      <tr className={' ' + this.props.TextColorClass}>
        <td className="nucleus-row no-padding" onClick={this.handleRowClicked}>
          {patientName}
        </td>
        <td className="nucleus-row" onClick={this.handleRowClicked}>
          {deviceName}
        </td>
        {/*
                 <td className="nucleus-row" onClick={this.handleRowClicked}>{ patientPhone }</td>
                 <td className="nucleus-row" onClick={this.handleRowClicked}>{ patientAddress }</td> 
                */}
        <td className="nucleus-row left-align" onClick={this.handleRowClicked}>
          {requestDateString + ' - ' + this.props.TimezoneString}
        </td>
        <td className="nucleus-row center-align" onClick={this.handleRowClicked}>
          {dateStrings}
        </td>

        <td className="nucleus-row center-align" onClick={this.handleRowClicked}>
          {this.props.Num}
        </td>
        <td className="nucleus-row right-align a-much-left">
          &nbsp;&nbsp;&nbsp;
          <span className={'nucleus-table-icon ' + this.state.cancelIconVisibility}>
            {/*<i className="Tiny material-icons" onClick={this.handleQueueItem}>mode_edit</i>*/}
            <img src="/img/mark_as_handled.png" className="nucleus-link" onClick={this.handleQueueItem} />
          </span>
          {/*<span className="nucleus-table-icon" ><i className="Tiny material-icons">mode_edit</i></span>
                    
                    
                    &nbsp;&nbsp;&nbsp;
                    <span className="nucleus-font-small">
                      <input type="checkbox" className="filled-in nucleus-font-small" id={"checkItem"+ this.props.RequestID }/>
                      <label className="nucleus-font-small" for={"checkItem"+ this.props.RequestID }>&nbsp;</label>
                    </span>
                */}
        </td>
      </tr>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(undefined, mapDispatchToProps)(QueueItemRow);
