//@ts-nocheck
import React from 'react';

type appProps = {
  data: Object;
  onToggle: Function;
  mdmAccountApps: boolean;
};

const AppTableRow = (props: appProps) => {
  const onClicked = e => {
    console.log('onClicked', e.target.checked);
    const params = Object.assign({}, props.data);
    params.enabled = e.target.checked;
    props.onToggle(params);
    //setStateChecked(e.target.checked);
  };

  //const [stateChecked, setStateChecked] = useState(props.data.Active != null);
  const url = props.data.IconUrl;

  return (
    <tr>
      <td className="" style={{ display: 'flex' }}>
        {props.data.IconUrl && (
          <img
            src={
              props.data.IconUrl
              //? props.data.IconUrl
              //: "/img/user_placeholder.png"
            }
            // ref={(img) => {
            //     this.imgRef = img;
            // }}
            // onError={() => {
            //     this.imgRef.src = (props.data.IconUrl).replace("https", "http");
            //     console.error("Image source error", (props.data.IconUrl).replace("https", "http"));
            // }}
            style={{
              width: 50,
              height: 50,
              marginRight: 20,
              marginLeft: 0,
              borderRadius: 5,
              objectFit: 'cover',
            }}
          />
        )}
        <span style={{ alignSelf: 'center' }}>{props.data.Name}</span>
      </td>
      <td className="center-align" style={{ textAlign: 'start' }}>
        {!props.mdmAccountApps && (
          <div className="switch" style={{ marginLeft: 34 }}>
            <label>
              <input type="checkbox" checked={props.data.Active} onChange={onClicked} />
              <span className="lever no-margin"></span>
            </label>
          </div>
        )}
      </td>
      <td className="center-align"></td>
    </tr>
  );
};

export default AppTableRow;
