export const ChevronRightSVG = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.1957 17.2436L10.9543 31.4849C10.2675 32.1717 9.15392 32.1717 8.46715 31.4849L6.80613 29.8238C6.12046 29.1382 6.11914 28.0269 6.8032 27.3396L18.0897 16L6.8032 4.66039C6.11914 3.9731 6.12046 2.86183 6.80613 2.17615L8.46715 0.515135C9.154 -0.171711 10.2675 -0.171711 10.9543 0.515135L25.1957 14.7563C25.8824 15.4432 25.8824 16.5567 25.1957 17.2436Z"
        fill="#0092FF"
      />
    </svg>
  );
};
