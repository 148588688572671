import classNames from 'classnames';
import React from 'react';
import { JQuery } from 'types/jquery.types';
import AuthStore from '../../stores/AuthStore';
import CareUserStore from '../../stores/CareUserStore';
import GeneralStore from '../../stores/GeneralStore';
import Message from '../../utils/Message';
import UserGroupsPermissions from '../Groups/GroupsPermissions';
import UserAssignedPatients from './UserAssignedPatients';
import UserPatientAvailableItemRow from './UserPatientAvailableItemRow';
//import _ from "underscore";

const TABS = {
  Clients: 'Clients',
  Categories: 'Categories',
};

class UserProfileInfo extends React.Component<
  {
    userId: string;
    userFullName: string;
    userIsOnDuty: boolean;
    userOnlyUrgent: boolean;
    userProfileCompleted: boolean;
    userCreated: boolean;
    adminAccess: boolean;
    userIsAdmin: boolean;
    userCanReceiveEscalation: boolean;
    currentMode: string;
  },
  {
    userID: string;
    userFullName: string;
    userOnDuty: boolean;
    userOnlyUrgent: boolean;
    patientsArray: any[];
    userAdmin: boolean;
    selectedAssignedPatientID: string;
    selectedAssignedPatientName: string;
    btnResendVisibility: string;
    resendEmailBtnStyle: string;
    userAllPatientsAccess: boolean;
    userPatientsListMode: string;
    btnStyle: string;
    userAvailablePatients: any[];
    defaultSelected: boolean;

    userEscalationStyle: string;

    searchText: string;
    activeTab: string;
    userIsOnDuty?: boolean;
  }
> {
  patientToAssign: number;
  patientAssigned: number;

  constructor(props) {
    super(props);

    this.state = {
      userID: null,
      userFullName: '',
      userOnDuty: false,
      userOnlyUrgent: false,
      patientsArray: [],
      userAdmin: AuthStore.getUserRol() == 'admin',
      selectedAssignedPatientID: '',
      selectedAssignedPatientName: '',
      btnResendVisibility: ' hide',
      resendEmailBtnStyle: 'nucleus-submit-btn-small',
      userAllPatientsAccess: CareUserStore.getUserAllPatientsAccess(),
      userPatientsListMode: CareUserStore.getUserAllPatientsAccess() ? 'disabled' : '',
      btnStyle: ' disabled',
      userAvailablePatients: [],
      defaultSelected: false,
      userEscalationStyle: '',

      searchText: '',
      activeTab: TABS.Categories,
    };

    this.onUserGetDataAction = this.onUserGetDataAction.bind(this);
    this.handleOnDutyProfile = this.handleOnDutyProfile.bind(this);
    this.onSetUserOnDutyAction = this.onSetUserOnDutyAction.bind(this);
    this.handleOnlyUrgent = this.handleOnlyUrgent.bind(this);
    this.onSetUserOnlyUrgentAction = this.onSetUserOnlyUrgentAction.bind(this);
    this.handleResendInvite = this.handleResendInvite.bind(this);
    this.onResendEmailAction = this.onResendEmailAction.bind(this);

    // Assign Patients
    console.log('Getting: ', CareUserStore.getUserAllPatientsAccess(), ' but... : ' + (CareUserStore.getUserAllPatientsAccess().toString() == 'true'));
    this.onGetPossiblePatientsAction = this.onGetPossiblePatientsAction.bind(this);
    this.onPatientAssignListHasChangedAction = this.onPatientAssignListHasChangedAction.bind(this);
    this.onAssignPatientAction = this.onAssignPatientAction.bind(this);
    this.onUnassignPatientAction = this.onUnassignPatientAction.bind(this);

    this.patientAssigned = 0;

    this.handleSearchText = this.handleSearchText.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.renderCategoriesTab = this.renderCategoriesTab.bind(this);
    this.renderClientsTab = this.renderClientsTab.bind(this);
    this.renderActiveTab = this.renderActiveTab.bind(this);
  }

  componentDidMount() {
    //Register Listener
    CareUserStore.on('onUserGetData', this.onUserGetDataAction);
    // CareUserStore.on("onSetUserOnDuty", this.onSetUserOnDutyAction);
    // CareUserStore.on("onSetUserOnlyUrgent", this.onSetUserOnlyUrgentAction)
    CareUserStore.on('onResendEmail', this.onResendEmailAction);

    CareUserStore.on('onGetPossiblePatients', this.onGetPossiblePatientsAction);
    GeneralStore.on('onPatientAssignListHasChanged', this.onPatientAssignListHasChangedAction);
    CareUserStore.on('onAssignPatient', this.onAssignPatientAction);
    CareUserStore.on('onUnassignPatient', this.onUnassignPatientAction);

    this.setState({
      userID: this.props.userId,
      userFullName: this.props.userFullName,
      userOnDuty: this.props.userIsOnDuty,
    });
  }

  componentWillUnmount() {
    //Remove Listener
    CareUserStore.removeListener('onUserGetData', this.onUserGetDataAction);
    // CareUserStore.removeListener("onSetUserOnDuty", this.onSetUserOnDutyAction);
    // CareUserStore.removeListener("onSetUserOnlyUrgent", this.onSetUserOnlyUrgentAction);
    CareUserStore.removeListener('onResendEmail', this.onResendEmailAction);
    CareUserStore.removeListener('onGetPossiblePatients', this.onGetPossiblePatientsAction);
    GeneralStore.removeListener('onPatientAssignListHasChanged', this.onPatientAssignListHasChangedAction);
    CareUserStore.removeListener('onAssignPatient', this.onAssignPatientAction);
    CareUserStore.removeListener('onUnassignPatient', this.onUnassignPatientAction);
  }

  onUserGetDataAction(response) {
    if (response) {
      CareUserStore.setUserAllPatientsAccess(response.SeeAllPatients);
      CareUserStore.setCurrentUserName(response.FirstName + ' ' + response.LastName);

      const accountEscalationEmail = response.AccountEscalationEmail;

      this.setState({
        userIsOnDuty: response.IsOnDuty,
        userAllPatientsAccess: response.SeeAllPatients,
        userPatientsListMode: response.SeeAllPatients ? 'disabled' : '',
        userEscalationStyle: accountEscalationEmail ? ' icon-disabled' : '',
      });
    }
  }

  handleOnlyUrgent(e) {
    console.log('handleOnlyUrgent');
    console.log(e.target.checked);
    CareUserStore.setUserOnlyUrgent({
      UserID: this.props.userId,
      OnlyUrgent: e.target.checked,
    });
  }

  onSetUserOnlyUrgentAction(result) {
    console.log('onSetUserOnlyUrgentAction');
    console.log(result);
    if (result.userId == this.props.userId) {
      if (result.ok) {
        this.setState({
          userOnlyUrgent: result.onlyUrgent,
        });
        if (result.onlyUrgent) {
          Message.show('Urgent calls only enabled');
        } else {
          Message.show('Urgent calls only disabled');
        }

        if (result.userId == AuthStore.getUserID()) {
          AuthStore.setOnlyUrgent(result.onlyUrgent);
        }
      } else {
        Message.show('Error updating Urgent Calls Only, please try again.');
      }
    }
  }

  handleOnDutyProfile(e) {
    console.log('Checkbox');
    console.log(e);
    const onDuty = e.target.checked;
    console.log(onDuty);

    //Get the User Data from WS
    CareUserStore.setUserOnDuty({
      UserID: this.props.userId,
      IsOnDuty: onDuty,
    });
  }

  onSetUserOnDutyAction(result) {
    console.log('onSetUserOnDutyAction');
    console.log(result);
    console.log(this.state.userID, this.props.userId);

    if (result.userId == this.props.userId) {
      if (result.ok) {
        this.setState({
          userOnDuty: result.onDuty,
        });
        if (result.onDuty) {
          Message.show(this.props.userFullName + ' is On Duty now!');
        } else {
          Message.show(this.props.userFullName + ' is Off now!');
        }

        if (result.userId == AuthStore.getUserID()) {
          AuthStore.setOnDuty(result.onDuty);
        }
      } else {
        Message.show('Error updating ' + this.props.userFullName + ' OnDuty, please try again.');
      }
    }
  }

  handleResendInvite() {
    CareUserStore.reSendEmail({
      UserID: this.props.userId,
    });
    this.setState({
      resendEmailBtnStyle: 'nucleus-submit-btn-small-disabled',
    });
  }

  onResendEmailAction(response) {
    this.setState({
      resendEmailBtnStyle: 'nucleus-submit-btn-small',
    });
    if (response.ok) {
      Message.show("The 'Welcome email' has been resent");
    } else {
      Message.show('There was an error resending the email, please try again');
    }
  }

  onGetPossiblePatientsAction(response) {
    console.log('onGetPossiblePatientsAction', response);
    if (response.ok) {
      this.setState({
        userAvailablePatients: response.Patients,
      });
    } else {
      Message.show('Error getting available ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' information');
    }
  }

  onPatientAssignListHasChangedAction(patientToAssign) {
    console.log('onPatientAssignListHasChangedAction', patientToAssign);
    this.patientToAssign = patientToAssign;
    if (patientToAssign > 0) {
      this.setState({
        btnStyle: '',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }
  }

  onAssignPatientAction(response) {
    console.log('onAssignPatientAction', response);
    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' Assigned');
    } else {
      Message.show('Error assigning ' + AuthStore.getPatientLabel().toLowerCase() + ' to this user.');
    }
  }

  onUnassignPatientAction(response) {
    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' Unassigned');
    } else {
      Message.show(
        'Sorry,  there was an error when unassign the ' + AuthStore.getPatientLabel().toLowerCase() + ' from the ' + AuthStore.getUserLabel().toLowerCase() + ', please try again',
      );
    }
  }


  handleSearchText(e) {
    this.setState({
      searchText: e.target.value,
    });
  }

  changeActiveTab(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  renderCategoriesTab() {
    this.setState({
      activeTab: TABS.Categories,
    });
  }

  renderClientsTab() {
    this.setState({
      activeTab: TABS.Clients,
    });
  }

  renderActiveTab() {
    if (!this.props.adminAccess){
      return null;
    }
    if (this.state.activeTab == TABS.Categories) {
      return <UserGroupsPermissions />;
    }
    if (this.state.activeTab == TABS.Clients) {
      return <UserAssignedPatients />;
    }
  }

  render() {
    const currentSection = classNames({
      hide: this.props.currentMode != 'Info',
    });

    const patientsList = [];
    const textToSearch = this.state.searchText.toLowerCase();
    if (this.state.userAvailablePatients != null) {
      this.state.userAvailablePatients.map((patient, key) => {
        const name = (patient.FirstName + ' ' + patient.LastName).toLowerCase();
        //console.log(name.includes(textToSearch));
        if (textToSearch.length == 0 || name.indexOf(textToSearch) != -1) {
          patientsList.push(
            <UserPatientAvailableItemRow
              key={key}
              UserID={this.props.userId}
              PatientID={patient.PatientID}
              Name={patient.FirstName + ' ' + patient.LastName}
              Telephone={patient.Telephone}
              DefaultSelected={this.state.defaultSelected}
              Assigned={patient.Assigned}
              PatientsListMode={!!this.state.userPatientsListMode}
            />,
          );
        }
      });
    }

    const groupsSubtabStyle = this.state.activeTab == TABS.Categories ? 'new-nucleus-page-subtitle-selected' : 'new-nucleus-page-subtitle';
    const clientsSubtabStyle = this.state.activeTab == TABS.Clients ? 'new-nucleus-page-subtitle-selected' : 'new-nucleus-page-subtitle';

    return (
      <div className={'nucleus-user-pending ' + currentSection} >
        {
          (this.props.adminAccess) &&
          <>
            <h3 className={groupsSubtabStyle} onClick={this.renderCategoriesTab}>
              <span> Assigned Groups</span>
            </h3>
            <h3 className={clientsSubtabStyle} onClick={this.renderClientsTab}>
              <span> Assigned {AuthStore.getPatientLabelPlural()} List</span>
            </h3>
          </>
        }
        {
          this.renderActiveTab()
        }
        
      </div>
    );
  }
}

export default UserProfileInfo;
