//@ts-nocheck
import { Document, Page, Text, Image, View, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';
import { useEffect } from 'react';

Font.register({
  family: 'GothamNarrow-Medium',
  src: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Medium.otf',
});
Font.register({
  family: 'GothamNarrow-Light',
  src: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Light.otf',
});
Font.register({
  family: 'GothamNarrow-Thin',
  src: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Thin.otf',
});

const GeneratePdfDocument = props => {
  console.log('Doc Props 2', props);

  const generateReportLines = () => {
    console.log('generateReportLines', props);
    const reportRows = [];
    props.data.patientMessages.forEach((delivery, index) => {
      const row = (
        <View key={index} style={pdfStyles.bodyTableRow}>
          <View style={pdfStyles.bodyTableRowCell}>
            <Text>{delivery.DeliveryTime}</Text>
          </View>
          <View style={pdfStyles.bodyTableRowCell}>
            <Text>{delivery.Patient}</Text>
          </View>
          <View style={pdfStyles.bodyTableRowCell}>
            <Text>{''}</Text>
          </View>
          <View style={pdfStyles.bodyTableRowCell}>
            <Text>{delivery.Response}</Text>
          </View>
          {props.data.signatureReport && (
            <View style={pdfStyles.bodyTableRowCell}>
              {delivery.MessageSignature != null && (
                <Image src={{ uri: delivery.MessageSignature, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }} style={{ width: 120 }}></Image>
              )}
            </View>
          )}
        </View>
      );
      reportRows.push(row);
    });
    return reportRows;
  };

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={pdfStyles.page}>
        <Image
          src={{
            uri: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/logos/nucleuscare_logo_2022.png',
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
            body: '',
          }}
          style={{ width: 172 }}
        ></Image>
        <View style={pdfStyles.header}>
          <View style={pdfStyles.title}>
            <Text>{'Message Report'}</Text>
          </View>
          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{'Message:'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.data.body}</Text>
            </View>
          </View>
          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{'Sender:'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.data.sender}</Text>
            </View>
          </View>
          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{'Recurring:'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.data.recurring}</Text>
            </View>
          </View>
          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{'Report from:'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.data.fromTime}</Text>
            </View>
          </View>
          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{'Report to:'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.data.toTime}</Text>
            </View>
          </View>
        </View>

        <View style={pdfStyles.body}>
          <View style={pdfStyles.bodyTable}>
            <View style={pdfStyles.bodyTableHeader}>
              <View style={pdfStyles.bodyTableHeaderCell}>
                <Text>{'Sent'}</Text>
              </View>
              <View style={pdfStyles.bodyTableHeaderCell}>
                <Text>{props.data.patientLabel + ' Name'}</Text>
              </View>
              <View style={pdfStyles.bodyTableHeaderCell}>
                <Text>{props.data.patientLabel + ' Groups'}</Text>
              </View>
              <View style={pdfStyles.bodyTableHeaderCell}>
                <Text>{'Response'}</Text>
              </View>
              {props.data.signatureReport && (
                <View style={pdfStyles.bodyTableHeaderCell}>
                  <Text>{'Signature'}</Text>
                </View>
              )}
            </View>
            <View style={pdfStyles.bodyTableContent}>{generateReportLines()}</View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 25,
    fontWeight: 'light',
    fontFamily: 'GothamNarrow-Medium',
    height: '100%',
  },
  title: {
    marginBottom: 15,
    color: '#0A313F',
  },
  textTitle: {},
  header: {
    flexDirection: 'column',
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: 'white',
    padding: 15,
    paddingTop: 5,
    paddingLeft: 5,
    borderRadius: 3,
  },
  headerRow: {
    flexDirection: 'row',
    fontFamily: 'GothamNarrow-Medium',
    fontSize: 14,
  },
  headerColumnLeft: {
    flex: 1,
  },
  headerColumnRight: {
    flex: 3,
    fontSize: 14,
    fontFamily: 'GothamNarrow-Light',
  },
  textHeader: {},
  body: {
    flex: 1,
    backgroundColor: 'white',
  },
  bodyTableHeader: {
    flexDirection: 'row',
    fontSize: 13,
    justifyContent: 'center',
  },
  bodyTableHeaderCell: {
    flex: 1,
    backgroundColor: '#BCC8D0',
    padding: 5,
    alignSelf: 'center',
  },
  bodyTableContent: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontSize: '12',
  },
  bodyTableRow: {
    flexDirection: 'row',
    fontFamily: 'GothamNarrow-Light',
    borderColor: '#E4E4E4',
    borderWidth: 1,
    borderStyle: 'solid',
    justifyContent: 'center',
  },
  bodyTableRowCell: {
    flex: 1,
    padding: 3,
    paddingTop: 5,
    alignSelf: 'center',
  },

  textBody: {},
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

const preloadResources = async () => {
  const fonts = [
    'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Medium.otf',
    'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Light.otf',
    'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Thin.otf',
  ];

  const images = ['https://nucleuscare-data.s3.us-west-2.amazonaws.com/logos/nucleuscare_logo_2022.png'];

  const fetchPromises = [...fonts, ...images].map(url =>
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch ${url}`);
        }
        return response;
      })
      .catch(error => {
        console.error(`Error fetching resource ${url}:`, error);
        return null; // Return null in case of an error
      }),
  );

  const responses = await Promise.all(fetchPromises);
  console.log('preloadResources responses', responses);

  return responses;
};

const MessagesReportPdf = props => {
  console.log('MessagesReportPdf PropsData', props);

  useEffect(() => {
    preloadResources().then(() => {
      console.log('Resources preloaded');
    });
  }, []);

  return (
    <div style={{ display: 'inline' }}>
      {props.messageData && props.messageData.ID && (
        <PDFDownloadLink document={<GeneratePdfDocument data={props.messageData} />} fileName="NucleusMessageReport.pdf">
          {({ loading, error }) => {
            if (error) {
              console.error('PDF Download Link error', error);
              return 'Error generating document';
            }
            return loading ? (
              'Loading document...'
            ) : (
              <a
                className={'btn-floating txt-white nucleus-font-small nucleus-submit-btn-small tooltipped '}
                style={{ width: 98, height: 35, textAlign: 'center', justifyContent: 'center', padding: 0 }}
                data-position="top"
                data-delay="50"
                data-tooltip="Download PDF"
              >
                <i className="material-icons" style={{ width: 32, marginTop: -3, marginRight: 3 }}>
                  save_alt
                </i>
                PDF
              </a>
            );
          }}
        </PDFDownloadLink>
      )}
    </div>
  );
};

export default MessagesReportPdf;
