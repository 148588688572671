import { startCall } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import classnames from 'classnames';
import ClientList from 'components/ClientList';
import { HandleCallPayload } from 'components/Communication/communication.types';
import { CallingMethods } from 'components/WebRTCCallView';
import moment from 'moment';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { JQuery } from 'types/jquery.types';
import AuthStore from '../../stores/AuthStore';
import CarePatientStore from '../../stores/CarePatientStore';
import GeneralStore from '../../stores/GeneralStore';
import SalesforceStore from '../../stores/SalesforceStore';

import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
import css from '../Users/components/SalesforceIntegration.module.css';

import { PatientData } from './patient.types';
import PatientItemRow from './PatientItemRow';

import VideoCallToggle from './VideoCallToggle';

class Patients extends React.Component<
  {
    dispatch: any;
  },
  {
    patientsArray: PatientData[];
    currentPatientID: string;
    sortDesc: boolean;
    sortBy: string;
    btnStyle: string;
    searchText: string;
    userBargeInEnabled: boolean;
    salesforceIntegrationEnabled: boolean;
    salesforceIntegrationStatus: string;
    salesforceDataLoading: boolean;
    availablepatientsIdsHash: any[];
    availablePatientsArray: any[];
    canImportPatients: boolean;
    audioCallsOnly: boolean;
    allowVideoCalls: boolean;
    accountAutoAnswerAllowed: boolean;
    allowMessageButton: boolean;
    patientPendingRequest?: string;
    patientPendingRequestIcon?: string;
    loading?: boolean;
  }
> {
  private salesforceCount: number;
  constructor(props) {
    super(props);

    validateAccessGranted('/patients');

    this.state = {
      patientsArray: [],
      currentPatientID: '',
      sortDesc: true,
      sortBy: 'name',
      btnStyle: ' nucleus-submit-btn-disabled',
      searchText: '',
      accountAutoAnswerAllowed: false,
      userBargeInEnabled: false,

      salesforceIntegrationEnabled: false,
      salesforceIntegrationStatus: 'Loading Salesforce Data...',
      salesforceDataLoading: false,
      availablepatientsIdsHash: [],
      availablePatientsArray: [],

      canImportPatients: false,
      audioCallsOnly: undefined,
      allowVideoCalls: undefined,
      allowMessageButton: false,
    };

    this.onGetAllPatientsDataAction = this.onGetAllPatientsDataAction.bind(this);
    this.openDeletePatientModalAction = this.openDeletePatientModalAction.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDeletePatient = this.handleDeletePatient.bind(this);
    this.onDeletePatientAction = this.onDeletePatientAction.bind(this);

    this.handleSendMessageAllPatients = this.handleSendMessageAllPatients.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.checkBroadcastMessage = this.checkBroadcastMessage.bind(this);
    this.handleSendBroadcastMessage = this.handleSendBroadcastMessage.bind(this);
    this.onBroadcastMessageSentAction = this.onBroadcastMessageSentAction.bind(this);
    this.handleSearchText = this.handleSearchText.bind(this);

    this.handleSalesforceIntegration = this.handleSalesforceIntegration.bind(this);
    this.handleCloseIntegrationModal = this.handleCloseIntegrationModal.bind(this);
    this.handleSelectPatientId = this.handleSelectPatientId.bind(this);
    this.handleSubmitSalesforceIntegration = this.handleSubmitSalesforceIntegration.bind(this);
    this.getSalesforceDataRows = this.getSalesforceDataRows.bind(this);

    this.onSalesforcePatientsIntegrating = this.onSalesforcePatientsIntegrating.bind(this);
    this.onSalesforcePatientsIntegrated = this.onSalesforcePatientsIntegrated.bind(this);
    this.salesforceCount = 0;

    this.onImportedPatientsDone = this.onImportedPatientsDone.bind(this);
  }

  componentDidMount() {
    console.warn('Patients componentDidMount===============');
    CarePatientStore.getAllPatientsData({
      UserID: AuthStore.getUserID(),
    });
    //Register Listener
    CarePatientStore.on('onGetAllPatientsData', this.onGetAllPatientsDataAction);
    GeneralStore.on('openDeletePatientModalAction', this.openDeletePatientModalAction);

    CarePatientStore.on('onDeletePatientAction', this.onDeletePatientAction);
    CarePatientStore.on('onBroadcastMessageSent', this.onBroadcastMessageSentAction);

    SalesforceStore.on('onSyncSalesforceStarted', this.onSyncSalesforceStartedAction);
    SalesforceStore.on('onSyncSalesforceProcess', this.onSyncSalesforceProcessAction);
    SalesforceStore.on('onSyncSalesforceEnded', this.onSyncSalesforceEndedAction);

    SalesforceStore.on('onSalesforcePatientsIntegrating', this.onSalesforcePatientsIntegrating); // *...Action

    SalesforceStore.on('onSalesforcePatientsIntegrated', this.onSalesforcePatientsIntegrated);

    ($('.modalIntegratePatients') as unknown as JQuery).modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });

    ($('.tooltipped') as unknown as JQuery).tooltip({ delay: 50 });
    ($('.modalPatients') as unknown as JQuery).modal({
      dismissible: true,
      complete: function () {}, // Callback for Modal close
    });
    this.setState({
      allowMessageButton: AuthStore.getMessageToAllPatientsAllowed(),
    });
  }

  componentWillUnmount() {
    console.warn('Patients componentWillUnmount===============');
    //Remove Listener
    CarePatientStore.removeListener('onGetAllPatientsData', this.onGetAllPatientsDataAction);
    GeneralStore.removeListener('openDeletePatientModalAction', this.openDeletePatientModalAction);
    CarePatientStore.removeListener('onDeletePatientAction', this.onDeletePatientAction);
    CarePatientStore.removeListener('onBroadcastMessageSent', this.onBroadcastMessageSentAction);

    SalesforceStore.removeListener('onSyncSalesforceStarted', this.onSyncSalesforceStartedAction);
    SalesforceStore.removeListener('onSyncSalesforceProcess', this.onSyncSalesforceProcessAction);
    SalesforceStore.removeListener('onSyncSalesforceEnded', this.onSyncSalesforceEndedAction);

    SalesforceStore.removeListener('onSalesforcePatientsIntegrating', this.onSalesforcePatientsIntegrating);
    SalesforceStore.removeListener('onSalesforcePatientsIntegrated', this.onSalesforcePatientsIntegrated);

    ($('.tooltipped') as unknown as JQuery).tooltip('remove');
  }

  onGetAllPatientsDataAction({ success, integrationEnabled, bargeInEnabled, canImportPatients, audioCallsOnly, allowVideoCalls }) {
    console.log('foobar', success, integrationEnabled, bargeInEnabled, canImportPatients, audioCallsOnly, allowVideoCalls);
    if (success) {
      this.setState({
        salesforceIntegrationEnabled: integrationEnabled,
        userBargeInEnabled: bargeInEnabled,
        canImportPatients: canImportPatients,
        audioCallsOnly: audioCallsOnly,
        allowVideoCalls: allowVideoCalls,
        accountAutoAnswerAllowed: AuthStore.getAutoAnswerAllowed().toString() == 'true',
      });
    } else {
      Message.show('Sorry, There was a problem getting the ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' information. Please try again.');
    }
  }

  openDeletePatientModalAction(PatientID) {
    this.setState({
      currentPatientID: PatientID,
    });
    ($('#modalDeletePatient') as unknown as JQuery).modal('open');
  }

  handleCancelDelete() {
    ($('#modalDeletePatient') as unknown as JQuery).modal('close');
  }

  handleDeletePatient() {
    CarePatientStore.deletePatient({
      PatientID: this.state.currentPatientID,
    });
  }

  onDeletePatientAction(response) {
    if (response.ok) {
      ($('#modalDeletePatient') as unknown as JQuery).modal('close');
      CarePatientStore.getAllPatientsData({
        UserID: AuthStore.getUserID(),
      });
      setTimeout(function () {
        //@ts-ignore
        Message.show(AuthStore.getPatientLabel().capitalize() + ' deleted successfully.');
      }, 700);
    } else {
      ($('#modalDeletePatient') as unknown as JQuery).modal('close');
      Message.show('Sorry, There was a problem deleting the ' + AuthStore.getPatientLabel().toLowerCase() + '. Please try again.');
    }
  }

  handleSendMessageAllPatients() {
    ($('#modalBroadcastMessage') as unknown as JQuery).modal('open');
    //@ts-ignore
    this.refs.textMessageContent.value = '';
    this.setState({
      btnStyle: ' nucleus-submit-btn-disabled',
    });
    const currentTime = new Date();
    //@ts-ignore
    this.refs.messageDateTime.value = currentTime.toLocaleDateString() + ' ' + currentTime.toLocaleTimeString();
  }

  handleCloseModal() {
    ($('#modalBroadcastMessage') as unknown as JQuery).modal('close');
  }

  checkBroadcastMessage() {
    //@ts-ignore
    if (this.refs.textMessageContent.value.length > 0 && this.refs.messageDateTime.value.length > 0) {
      this.setState({
        btnStyle: ' nucleus-submit-btn',
      });
    } else {
      this.setState({
        btnStyle: ' nucleus-submit-btn-disabled',
      });
    }
  }

  handleSendBroadcastMessage() {
    this.setState({
      btnStyle: ' nucleus-submit-btn-disabled',
    });

    Message.show('Error, web service deprecated');
    console.log('Error, web service deprecated');
  }

  onBroadcastMessageSentAction(response) {
    console.log('onBroadcastMessageSentAction');
    console.log(response);
    this.setState({
      btnStyle: ' nucleus-submit-btn',
    });

    if (response.ok) {
      Message.show('Message sent to all ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' successfully');
    } else {
      Message.show('Error sending message to all ' + AuthStore.getPatientLabelPlural().toLowerCase());
    }
    ($('#modalBroadcastMessage') as unknown as JQuery).modal('close');
  }

  handleSearchText(e) {
    this.setState({
      searchText: e.target.value,
    });
  }

  sortBy = sortBy => {
    console.log('sortBy', sortBy);
    this.setState({
      sortBy,
      sortDesc: !this.state.sortDesc,
    });
  };

  prepareCallData = (deviceInfo: HandleCallPayload, callType: CallType) => {
    const patient = this.state.patientsArray.find(patient => patient.PatientID.toLowerCase() === deviceInfo.PatientID.toLowerCase());
    const participants: FullCallEntity[] = [
      {
        devicesIds: deviceInfo.Devices.map(device => device.id.toLowerCase()),
        name: patient?.FirstName + ' ' + patient?.LastName,
        entityId: deviceInfo.PatientID.toLowerCase(),
        host: false,
        type: callType,
        status: 'connected',
      },
    ];

    const patientName = patient?.FirstName + ' ' + patient?.LastName;
    const patientThumb = patient.ThumbnailUrl;
    const callData: WebRTCCall = {
      requestId: '',
      patientId: patient.PatientID.toLowerCase(),
      callingMethod: CallingMethods.Call,
      callerName: AuthStore.getUser()?.FullName,
      participants,
      multiCall: false,
      entityId: deviceInfo.PatientID.toLowerCase(),
      patientFirstName: patient?.FirstName,
      patientLastName: patient?.LastName,
      calleeFirstName: patient?.FirstName,
      calleeLastName: patient?.LastName,
      patientName,
      patientThumb: patientThumb,
      type: callType,
    };

    return callData;
  };

  onCallPatient = (patient: PatientData) => {
    console.log('onCallPatient', patient);

    const participants: FullCallEntity[] = [
      {
        devicesIds: patient.PatientDevices.map(device => device.id.toLowerCase()),
        name: patient.FirstName + ' ' + patient.LastName,
        entityId: patient.PatientID.toLowerCase(),
        host: false,
        type: CallType.Video,
        status: 'connected',
      },
    ];

    const patientName = patient.FirstName + ' ' + patient.LastName;
    const patientThumb = patient.ThumbnailUrl;
    const callData: WebRTCCall = {
      requestId: '',
      patientId: patient.PatientID.toLowerCase(),
      callingMethod: CallingMethods.Call,
      callerName: AuthStore.getUser()?.FullName,
      participants,
      multiCall: false,
      entityId: patient.PatientID.toLowerCase(),
      patientFirstName: patient.FirstName,
      patientLastName: patient.LastName,
      calleeFirstName: patient.FirstName,
      calleeLastName: patient.LastName,
      patientName,
      patientThumb: patientThumb,
      type: CallType.Video,
    };

    console.log('callData Patients', callData);
    this.props.dispatch(startCall(callData));
  };

  // SALESFORCE SECTION
  handleSalesforceIntegration = () => {
    console.log('handleSalesforceIntegration', this.state);
    console.log(AuthStore.getUserAccountID());

    SalesforceStore.getSalesforceData({
      AccountID: AuthStore.getUserAccountID(),
    });
    ($('#modalIntegratePatients') as unknown as JQuery).modal('open');
    this.setState({
      salesforceDataLoading: true,
      salesforceIntegrationStatus: 'Retrieving Salesforce Data...',
    });
  };

  onSyncSalesforceProcessAction = data => {
    console.log('onSyncSalesforceProcessAction', data);
    this.setState({
      salesforceIntegrationStatus: data.info,
    });
  };

  onSyncSalesforceStartedAction = data => {
    console.log('onSyncSalesforceStartedAction', data);
    if (data.ok) {
      this.setState({
        salesforceIntegrationStatus: 'Loading Salesforce Data...',
      });
    } else {
      Message.show('Error on Sync Salesforce Data: ' + data.info);
    }
  };

  handleCloseIntegrationModal = () => {
    ($('#modalIntegratePatients') as unknown as JQuery).modal('close');
  };

  onSyncSalesforceEndedAction = data => {
    console.log('onSyncSalesforceEndedAction', data);

    if (data.ok) {
      this.setState({
        salesforceDataLoading: false,
        availablepatientsIdsHash: [].concat(data.patientsIdsHash),
        availablePatientsArray: data.availablePatientsArray,
        salesforceIntegrationStatus: '',
      });

      if (data.requireRefresh) {
        CarePatientStore.getAllPatientsData({
          UserID: AuthStore.getUserID(),
        });
      }
    } else {
      this.setState({
        salesforceDataLoading: false,
      });
      Message.show(data.info);
    }
  };

  handleSelectPatientId = event => {
    const patientIdIndex = parseInt(event.target.id.replace('checkPatientName', ''));

    if (event.target.checked) {
      this.salesforceCount++;
    } else {
      this.salesforceCount--;
    }

    this.setState({
      btnStyle: this.salesforceCount > 0 ? ' nucleus-submit-btn' : ' nucleus-submit-btn-disabled',
    });

    const hashData = this.state.availablepatientsIdsHash;
    console.log('Current availablepatientsIdsHash', this.state.availablepatientsIdsHash);
    hashData[event.target.name] = { checked: event.target.checked, index: patientIdIndex };
    this.setState({
      availablepatientsIdsHash: hashData,
    });
  };

  handleSubmitSalesforceIntegration = () => {
    console.log('handleSubmitSalesforceIntegration', this.state.availablepatientsIdsHash);
    const patientIds = [];
    for (const [key] of Object.entries(this.state.availablepatientsIdsHash)) {
      //console.log("key",key);
      const obj = this.state.availablepatientsIdsHash[key];
      //console.log("obj",obj);
      if (obj.checked) {
        patientIds.push(obj.index);
      }
    }
    console.log('patientIds', patientIds);

    this.setState({
      btnStyle: ' nucleus-submit-btn-disabled',
      salesforceDataLoading: true,
    });

    SalesforceStore.integrateSalesforcePatients({
      AccountID: AuthStore.getUserAccountID(),
      UserID: AuthStore.getUserID(),
      PatientIds: patientIds,
    });
  };

  onSalesforcePatientsIntegrating(data) {
    if (data.ok) {
      this.setState({
        salesforceIntegrationStatus: data.info,
      });
    }
  }

  onSalesforcePatientsIntegrated(data) {
    console.log('onSalesforcePatientsIntegrated', data);
    if (data.ok) {
      Message.show(AuthStore.getPatientLabelPlural() + ' integrated succesfully');
      ($('#modalIntegratePatients') as unknown as JQuery).modal('close');
      CarePatientStore.getAllPatientsData({
        UserID: AuthStore.getUserID(),
      });
    } else {
      Message.show('Error Integrating ' + AuthStore.getPatientLabelPlural());
      ($('#modalIntegratePatients') as unknown as JQuery).modal('close');
      CarePatientStore.getAllPatientsData({
        UserID: AuthStore.getUserID(),
      });
    }
  }

  getSalesforceDataRows() {
    const itemRows = [];
    let lastModifiedDate = null;

    for (let index = 0; index < this.state.availablePatientsArray.length; index++) {
      const element = this.state.availablePatientsArray[index];
      //console.log("Generating", element);
      lastModifiedDate = '';
      if (element.LastModifiedDate) {
        try {
          lastModifiedDate = moment(element.LastModifiedDate).format('MM/DD/YYY HH:mm');
        } catch (error) {
          lastModifiedDate = element.LastModifiedDate + '.';
        }
      }

      itemRows.push(
        <tr key={index}>
          <td className="">
            <span>
              <input type="checkbox" className="filled-in" id={'checkPatientName' + index} name={element.Id} onChange={this.handleSelectPatientId} />
              <label htmlFor={'checkPatientName' + index}>&nbsp;</label>
            </span>
          </td>
          <td>{element.Name}</td>
          <td className={css.columnCenter}>
            {element.BillingStreet +
              ' ' +
              element.BillingCity +
              ' ' +
              element.BillingPostalCode +
              ', ' +
              element.BillingState +
              ', ' +
              element.BillingState +
              ', ' +
              element.BillingCountry}
          </td>
          <td className={css.columnCenter}>{element.Phone}</td>
          <td className={css.columnCenter}>{lastModifiedDate}</td>
        </tr>,
      );
    }
    return itemRows;
  }

  onImportedPatientsDone() {
    CarePatientStore.getAllPatientsData({
      UserID: AuthStore.getUserID(),
    });
  }

  render() {
    const messageButtonVisibility = ' hide';
    const patientsList = [];
    const textToSearch = this.state.searchText.toLowerCase();

    const shortcuts =
      AuthStore.nucleusVideoCallShortcut || AuthStore.nucleusAudioCallShortcut || AuthStore.nucleusBargeInCallShortcut || AuthStore.nucleusSilentBargeInCallShortcut;

    if (this.state.patientsArray != null) {
      const sortedArray = this.state.patientsArray.sort((a, b) => {
        //DESC
        const aFullName = `${a.FirstName} ${a.LastName}`;
        const bFullName = `${b.FirstName} ${b.LastName}`;
        if (this.state.sortDesc) {
          return aFullName.localeCompare(bFullName, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        } else {
          return bFullName.localeCompare(aFullName, undefined, {
            numeric: true,
            sensitivity: 'base',
          });
        }
      });

      sortedArray.map((patient, key) => {
        if (patient.PatientID) {
          const name = (patient.FirstName + ' ' + patient.LastName).toLowerCase();
          if (textToSearch.length == 0 || name.indexOf(textToSearch) != -1) {
            patientsList.push(
              <PatientItemRow
                id={`patient-${key}`}
                key={patient.PatientID}
                PatientID={patient.PatientID}
                FirstName={patient.FirstName}
                LastName={patient.LastName}
                Address={patient.Address}
                Email={patient.Telephone}
                Telephone={patient.Telephone}
                ImageThumb={patient.ThumbnailUrl}
                PatientDevices={patient.PatientDevices}
                shortcuts={shortcuts}
                AccountEnableAutoAnswer={this.state.accountAutoAnswerAllowed}
                BargeInEnabled={this.state.userBargeInEnabled}
                DefaultDeviceID={patient.DefaultDeviceID}
                DefaultDeviceName={patient.DefaultDeviceName}
                DefaultDeviceInfo={patient.DefaultDeviceInfo}
                PatientTotalDevices={patient.TotalDevices}
                PatientOnlineDevices={patient.OnlineDevices}
                //DevicesInfo={patient.Devices}

                FamilyMembers={patient.FamilyMembers}
                FamilyMembersActive={patient.FamilyMembersActive}
                MemoryBoxItems={patient.MemoryBoxItems}
              />,
            );
          }
        }
      });
    }

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">{AuthStore.getPatientLabelPlural()} </p>

          <div className="clients-table-pending altoImportantContainer">
            <div className="fixed-action-btn btn-add-new">
              &nbsp;
              <a
                className={'btn-floating btn waves-effect waves-light green tooltipped btnbroadcast' + messageButtonVisibility}
                data-position="left"
                data-delay="50"
                data-tooltip="New Message"
                onClick={this.handleSendMessageAllPatients}
              >
                <i className="material-icons">message</i>
              </a>
              <br />
              <Link id={'btn-new-patient'} to="/newPatient">
                <span
                  className="btn-floating btn-large waves-effect waves-light orange tooltipped "
                  data-position="left"
                  data-delay="50"
                  data-tooltip={'New ' + AuthStore.getPatientLabel()}
                >
                  <i className="material-icons">add</i>
                </span>
              </Link>
              &nbsp;
            </div>

            {/* - - - - - - - - Allow Video Call Toggle  - - - - - - - - */}
            {
              // only display if audio calls only is set to false for the account
              // also wait for the allowVideoCalls value to be set
              !this.state.audioCallsOnly && this.state.allowVideoCalls != undefined && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: 25,
                  }}
                >
                  <span style={{ marginLeft: 5, marginRight: 10, fontSize: 18 }}>Allow Video</span>
                  <VideoCallToggle
                    initialValue={this.state.allowVideoCalls}
                    onToggle={isEnabled => {
                      const responseCallback = rsp => {
                        if (!rsp.ok) {
                          Message.show('Error setting video call permissions for this account. Please refresh and try again');
                        }
                      };
                      CarePatientStore.setAllowVideoCallsForAccount(AuthStore.getUserAccountID(), isEnabled, responseCallback);
                    }}
                  />
                </div>
              )
            }
            <ClientList
              canImportPatients={this.state.canImportPatients}
              handleSalesforceIntegration={this.handleSalesforceIntegration}
              onImportedPatientsDone={this.onImportedPatientsDone}
              salesforceIntegrationEnabled={this.state.salesforceIntegrationEnabled}
              userID={AuthStore.getUserID()}
              onCallPatient={this.onCallPatient}
              setPatientsShowing={patients =>
                this.setState({
                  patientsArray: patients,
                })
              }
            />
          </div>

          <div id="modalBroadcastMessage" className="modal modalPatients modalBroadcastMessage ">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseModal} />
              <h3 className="nucleus-page-subtitle"> Send Message to All {AuthStore.getPatientLabelPlural()}</h3>
              <div className="row"></div>
              <span className="nucleus-font-medium">Date/Time:</span>
              <input id="messageDateTime" ref="messageDateTime" type="text" className="validate nucleus-date-picker-medium" />
              <br />
              <br />
              <div className="input-field col s12 modalCallLogContent2 no-margin">
                <textarea ref="textMessageContent" className="nucleus-textarea-big " onChange={this.checkBroadcastMessage}></textarea>
                <br />
                <div className="row no-margin a-bit-lower">
                  <div className="col s12 center-align no-margin">
                    <a className={'txt-white nucleus-font-modal-btn  ' + this.state.btnStyle} onClick={this.handleSendBroadcastMessage}>
                      Send
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalIntegratePatients" className="modal modalIntegratePatients ">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 className={css.modalTitle} style={{ margin: 0 }}>
                  {' '}
                  Salesforce {AuthStore.getPatientLabelPlural()}
                </h3>
                <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseIntegrationModal} />
              </div>
              <div className="row"></div>
              <span className="nucleus-font-medium">{this.state.salesforceIntegrationStatus}</span>
              {this.state.salesforceDataLoading && (
                <div className={'row center-align '}>
                  <br />
                  <br />
                  <br />
                  <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loading} style={{ display: 'inline-flex' }} />
                  <br />
                  <br />
                  <br />
                </div>
              )}
              {!this.state.salesforceDataLoading && (
                <div className={'row '}>
                  <div>
                    <table className="bordered highlight nucleus-report-table bg-white">
                      <thead>
                        <tr className="header-biotable-row">
                          <th className={classnames('table-col-5', css.columnHeader, css.columnSelect)}></th>
                          <th className={classnames('table-col-20', css.columnHeader)}>
                            <span className={css.columnHeaderTitle}>Name</span>
                          </th>
                          <th className={classnames('table-col-30', css.columnHeader, css.columnCenter)}>
                            <span className={css.columnHeaderTitle}>Address</span>
                          </th>
                          <th className={classnames('table-col-15', css.columnHeader, css.columnCenter)}>
                            <span className={css.columnHeaderTitle}>Phone</span>
                          </th>
                          <th className={classnames('table-col-20', css.columnHeader, css.columnCenter)}>
                            <span className={css.columnHeaderTitle}>Last Modified</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody style={{ borderColor: 'green', borderWidth: 1, height: 500, maxHeight: 600, overflowY: 'scroll' }}>{this.getSalesforceDataRows()}</tbody>
                    </table>
                  </div>
                </div>
              )}
              <br />

              <div className="input-field col s12 modalCallLogContent2 no-margin">
                <br />
                <div className="row no-margin a-bit-lower">
                  <div className="col s12 center-align no-margin">
                    <a className={'txt-white nucleus-font-modal-btn  ' + this.state.btnStyle} onClick={this.handleSubmitSalesforceIntegration}>
                      Submit
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

export default connect(undefined, mapDispatchToProps)(Patients);
