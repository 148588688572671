import { useSelector } from 'react-redux';

export function useLoudestPeerSelector(deviceId) {
  return useSelector(({ call }) => {
    const loudestPeer = call && call.loudestPeer;
    return loudestPeer === deviceId;
  });
}

export function useIsMultiCallSelector() {
  return useSelector(({ call }) => {
    const innerCall = call && call.call;
    if (!innerCall) return false;
    return innerCall.multiCall;
  });
}
