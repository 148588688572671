//@ts-nocheck
import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';

import { AuthStore, CareAlertsStore, GeneralStore } from '../../stores';

import Message from '../../utils/Message';

import AlertHandledItemRow from './AlertHandledItemRow';

class AlertsReport extends React.Component {
  constructor(props) {
    super();

    GeneralStore.getDSTDates();

    this.state = {
      alertsArray: [],
      loading: false,

      datesErrorVisible: ' hide',
      datesErrorText: 'The From Date has to be before the To Date.',

      filterTimezonesArray: GeneralStore.getIsDST() ? GeneralStore.GetDaylightTimezones() : GeneralStore.GetStandardTimezones(),

      arrayOccurredDesc: [],
      arrayOccurredAsc: [],

      arrayPatientDesc: [],
      arrayPatientAsc: [],

      arrayHandledDesc: [],
      arrayHandledAsc: [],

      arrayUserDesc: [],
      arrayUserAsc: [],

      sortOccurredDesc: ' nucleus-icon-active ',
      sortOccurredAsc: ' nucleus-icon-active hide ',

      sortPatientDesc: ' nucleus-icon-inactive ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-inactive ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-inactive ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      selectedAlertID: null,
      selectedAlertPatientID: null,
      selectedAlertPatientName: null,
      selectedAlertOccurredOn: null,
      selectedAlertDescription: null,
      selectedAlertActionsTaken: null,

      fromDateTimeReports: null,
      toDateTimeReports: null,
    };

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.onRefreshAlertsReportAction = this.onRefreshAlertsReportAction.bind(this);
    this.submitFilterAlerts = this.submitFilterAlerts.bind(this);
    this.onGetHandledAlertsUpdatedAction = this.onGetHandledAlertsUpdatedAction.bind(this);

    this.handleSortOccurredDesc = this.handleSortOccurredDesc.bind(this);
    this.handleSortOccurredAsc = this.handleSortOccurredAsc.bind(this);

    this.handleSortPatientDesc = this.handleSortPatientDesc.bind(this);
    this.handleSortPatientAsc = this.handleSortPatientAsc.bind(this);

    this.handleSortHandledDesc = this.handleSortHandledDesc.bind(this);
    this.handleSortHandledAsc = this.handleSortHandledAsc.bind(this);

    this.handleSortUserDesc = this.handleSortUserDesc.bind(this);
    this.handleSortUserAsc = this.handleSortUserAsc.bind(this);

    this.onOpenHandledAlertDetailsAction = this.onOpenHandledAlertDetailsAction.bind(this);
    this.handleCloseHandledAlertModal = this.handleCloseHandledAlertModal.bind(this);

    this.handledAlertsList = [];
    this.alertsRendered = false;

    this.sorteredArray1 = [];
    this.sorteredArray2 = [];
    this.sorteredArray3 = [];
    this.sorteredArray4 = [];
    this.sorteredArray5 = [];
    this.sorteredArray6 = [];
    this.sorteredArray7 = [];
    this.sorteredArray8 = [];

    this.messageFilterTimezonesArray = [];

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.datetimeFormat = 'MM/DD/YYYY hh:mm A';
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      this.datetimeFormat = 'MM/DD/YYYY HH:mm';
      //this.timeFormat = "HH:mm A";
      this.timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }

    this.fromDateTimeReportsRef = React.createRef();
    this.toDateTimeReportsRef = React.createRef();
  }

  componentDidMount() {
    //Register Listener
    CareAlertsStore.on('onRefreshAlertsReport', this.onRefreshAlertsReportAction);
    CareAlertsStore.on('onGetHandledAlertsUpdated', this.onGetHandledAlertsUpdatedAction);
    CareAlertsStore.on('onOpenHandledAlertDetails', this.onOpenHandledAlertDetailsAction);

    const currentDate = new Date();
    const beginDate = new Date();
    beginDate.setHours(currentDate.getHours() - 24);

    console.log('DATE:', currentDate, beginDate);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('TIMEZONE:', currentTimezone);

    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    let formatedTimezone = '' + currentTimezone;

    if (currentTimezone > 0) {
      formatedTimezone = '+' + currentTimezone;
    }
    console.log('FORMATED TIMEZONE:', formatedTimezone);

    this.setState({
      fromDateTimeReports: beginDate,
      toDateTimeReports: currentDate,
    });

    this.refs.selectTimeZone.value = formatedTimezone;

    if (this.refs.selectTimeZone.value == '') {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please select one of the available time zones.',
      });
      return;
    }

    // CareAlertsStore.getHandledAlertsForAccountByDateTime({
    //     AccountID : AuthStore.getUserAccountID(),
    //     FromDateTime : this.refs.fromDateTimeReports.value,
    //     ToDateTime : this.refs.toDateTimeReports.value,
    //     TimeZoneOffset : this.refs.selectTimeZone.value
    // });
    // Listen: "onGetHandledAlertsUpdated"
  }

  componentWillUnmount() {
    //Remove Listener
    CareAlertsStore.removeListener('onRefreshAlertsReport', this.onRefreshAlertsReportAction);
    CareAlertsStore.removeListener('onGetHandledAlertsUpdated', this.onGetHandledAlertsUpdatedAction);
    CareAlertsStore.removeListener('onOpenHandledAlertDetails', this.onOpenHandledAlertDetailsAction);
  }

  onFromDateReportsChange = (date, value) => {
    console.log('onFromDateReportsChange', date, value);
    this.setState({
      fromDateTimeReports: value,
    });
  };

  onToDateReportsChange = (date, value) => {
    console.log('onToDateReportsChange', date, value);
    this.setState({
      toDateTimeReports: value,
    });
  };

  onRefreshAlertsReportAction() {
    console.log('onRefreshAlertsReportAction');

    const currentDate = new Date();
    const beginDate = new Date();
    beginDate.setHours(currentDate.getHours() - 24);

    console.log('DATE:', currentDate, beginDate);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('TIMEZONE:', currentTimezone);

    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    let formatedTimezone = '' + currentTimezone;

    if (currentTimezone > 0) {
      formatedTimezone = '+' + currentTimezone;
    }

    console.log('FORMATED TIMEZONE:', formatedTimezone);

    this.state.fromDateTimeReports = beginDate; // beginDate.toLocaleDateString('en-US', this.dateOptions) + " " + beginDate.toLocaleTimeString('en-US', this.timeOptions);
    this.state.toDateTimeReports = currentDate; //currentDate.toLocaleDateString('en-US', this.dateOptions) + " " + currentDate.toLocaleTimeString('en-US', this.timeOptions);

    this.refs.selectTimeZone.value = formatedTimezone;

    if (this.refs.selectTimeZone.value == '') {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please select one of the available time zones.',
      });
      return;
    }

    this.setState({
      loading: true,
    });

    CareAlertsStore.getHandledAlertsForAccountByDateTime({
      AccountID: AuthStore.getUserAccountID(),
      FromDateTime:
        this.state.fromDateTimeReports.toLocaleDateString('en-US', this.dateOptions) + ' ' + this.state.fromDateTimeReports.toLocaleTimeString('en-US', this.timeOptions),
      ToDateTime: this.state.toDateTimeReports.toLocaleDateString('en-US', this.dateOptions) + ' ' + this.state.toDateTimeReports.toLocaleTimeString('en-US', this.timeOptions),
      TimeZoneOffset: this.refs.selectTimeZone.value,
    });
    // Listen: "onGetHandledAlertsUpdated"
  }

  submitFilterAlerts() {
    this.setState({
      datesErrorVisible: ' hide',
    });

    if (!(this.state.fromDateTimeReports && this.state.fromDateTimeReports.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please enter the From Date',
      });
      return;
    }

    if (!(this.state.toDateTimeReports && this.state.toDateTimeReports.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please enter the To Date',
      });
      return;
    }

    const fromDate = new Date(this.state.fromDateTimeReports);
    const toDate = new Date(this.state.toDateTimeReports);

    if (toDate < fromDate) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' The From Date has to be before the To Date.',
      });
      return;
    } else {
    }

    if (this.refs.selectTimeZone.value == '') {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please select one of the available time zones.',
      });
      return;
    }

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', this.timeOptions);
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', this.timeOptions);

    console.log('PARAM 1', fromDateParam);
    console.log('PARAM 2', toDateParam);
    console.log('TIMEZONE', this.refs.selectTimeZone.value);
    console.log('ACCOUNTID', AuthStore.getUserAccountID());

    this.setState({
      loading: true,
    });

    CareAlertsStore.getHandledAlertsForAccountByDateTime({
      AccountID: AuthStore.getUserAccountID(),
      FromDateTime: fromDateParam,
      ToDateTime: toDateParam,
      TimeZoneOffset: this.refs.selectTimeZone.value,
    });
    // Listen: "onGetHandledAlertsUpdated"
  }

  onGetHandledAlertsUpdatedAction(response) {
    console.log('onGetHandledAlertsUpdatedAction', response);

    if (response.ok) {
      this.setState({
        alertsArray: [],
        loading: false,
      });

      // if (response.alertsArray.length == 0){
      //     Message.show("There are no Handled Alerts records for the selected period of time.");
      // }
      this.sorteredArray1 = [].concat(response.alertsArray);
      this.sorteredArray1 = this.sorteredArray1.sort((a, b) => new Date(b.AlertOccurredOnMs) - new Date(a.AlertOccurredOnMs));

      this.alertsRendered = false;

      this.setState({
        alertsArray: this.sorteredArray1,

        sortOccurredDesc: ' nucleus-icon-inactive hide ',
        sortOccurredAsc: ' nucleus-icon-active ',

        sortPatientDesc: ' nucleus-icon-inactive ',
        sortPatientAsc: ' nucleus-icon-inactive hide ',

        sortHandledDesc: ' nucleus-icon-inactive ',
        sortHandledAsc: ' nucleus-icon-inactive hide ',

        sortUserDesc: ' nucleus-icon-inactive ',
        sortUserAsc: ' nucleus-icon-inactive hide ',
        /*
                arrayOccurredDesc : sorteredArray1,
                arrayOccurredAsc : sorteredArray2,

                arrayPatientDesc : sorteredArray3,
                arrayPatientAsc : sorteredArray4,

                arrayHandledDesc : sorteredArray5,
                arrayHandledAsc : sorteredArray6,

                arrayUserDesc : sorteredArray7,
                arrayUserAsc : sorteredArray8,
                */
      });

      this.sorteredArray2 = [].concat(response.alertsArray);

      this.sorteredArray3 = [].concat(response.alertsArray);
      this.sorteredArray4 = [].concat(response.alertsArray);

      this.sorteredArray5 = [].concat(response.alertsArray);
      this.sorteredArray6 = [].concat(response.alertsArray);

      this.sorteredArray7 = [].concat(response.alertsArray);
      this.sorteredArray8 = [].concat(response.alertsArray);

      this.sorteredArray2 = this.sorteredArray2.sort((a, b) => new Date(a.AlertOccurredOnMs) - new Date(b.AlertOccurredOnMs));

      this.sorteredArray3 = this.sorteredArray3.sort((a, b) => {
        const textA = a.PatientName.toLowerCase();
        const textB = b.PatientName.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });
      this.sorteredArray4 = this.sorteredArray4.sort((a, b) => {
        const textA = a.PatientName.toLowerCase();
        const textB = b.PatientName.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArray5 = this.sorteredArray1.sort((a, b) => new Date(b.AlertHandledOnMs) - new Date(a.AlertHandledOnMs));
      this.sorteredArray6 = this.sorteredArray2.sort((a, b) => new Date(a.AlertHandledOnMs) - new Date(b.AlertHandledOnMs));

      this.sorteredArray7 = this.sorteredArray7.sort((a, b) => {
        const textA = a.AlertHandledByName.toLowerCase();
        const textB = b.AlertHandledByName.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });
      this.sorteredArray7 = this.sorteredArray8.sort((a, b) => {
        const textA = a.AlertHandledByName.toLowerCase();
        const textB = b.AlertHandledByName.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      /*
            this.setState({
                alertsArray : response.alertsArray,

                sortOccurredDesc : ' nucleus-icon-inactive hide ',
                sortOccurredAsc : ' nucleus-icon-active ',

                sortPatientDesc : ' nucleus-icon-inactive ',
                sortPatientAsc : ' nucleus-icon-inactive hide ',

                sortHandledDesc: ' nucleus-icon-inactive ',
                sortHandledAsc: ' nucleus-icon-inactive hide ',

                sortUserDesc: ' nucleus-icon-inactive ',
                sortUserAsc: ' nucleus-icon-inactive hide ',
                
                arrayOccurredDesc : this.sorteredArray1,
                arrayOccurredAsc : this.sorteredArray2,

                arrayPatientDesc : this.sorteredArray3,
                arrayPatientAsc : this.sorteredArray4,

                arrayHandledDesc : this.sorteredArray5,
                arrayHandledAsc : this.sorteredArray6,

                arrayUserDesc : this.sorteredArray7,
                arrayUserAsc : this.sorteredArray8,
            });
            */
    } else {
      Message.show('Sorry, There was a problem getting the alerts information. Please try again.');
      this.setState({
        loading: false,
      });
    }
  }

  handleSortOccurredDesc() {
    console.log('handleSortOccurredDesc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-inactive hide ',
      sortOccurredAsc: ' nucleus-icon-active ',

      sortPatientDesc: ' nucleus-icon-inactive ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-inactive ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-inactive ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      alertsArray: this.sorteredArray1,
    });
    this.alertsRendered = false;
  }

  handleSortOccurredAsc() {
    console.log('handleSortOccurredAsc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-active ',
      sortOccurredAsc: ' nucleus-icon-inactive hide ',

      sortPatientDesc: ' nucleus-icon-inactive ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-inactive ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-inactive ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      alertsArray: this.sorteredArray2,
    });
    this.alertsRendered = false;
  }

  handleSortPatientDesc() {
    console.log('handleSortPatientDesc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-inactive ',
      sortOccurredAsc: ' nucleus-icon-inactive hide',

      sortPatientDesc: ' nucleus-icon-inactive hide ',
      sortPatientAsc: ' nucleus-icon-active ',

      sortHandledDesc: ' nucleus-icon-inactive ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-inactive ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      alertsArray: this.sorteredArray3,
    });
    this.alertsRendered = false;
  }

  handleSortPatientAsc() {
    console.log('handleSortPatientAsc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-inactive ',
      sortOccurredAsc: ' nucleus-icon-inactive hide',

      sortPatientDesc: ' nucleus-icon-active ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-inactive ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-inactive ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      alertsArray: this.sorteredArray4,
    });
    this.alertsRendered = false;
  }

  handleSortHandledDesc() {
    console.log('handleSortHandledDesc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-inactive ',
      sortOccurredAsc: ' nucleus-icon-inactive hide',

      sortPatientDesc: ' nucleus-icon-inactive ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-inactive hide ',
      sortHandledAsc: ' nucleus-icon-active ',

      sortUserDesc: ' nucleus-icon-inactive ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      alertsArray: this.sorteredArray5,
    });
    this.alertsRendered = false;
  }

  handleSortHandledAsc() {
    console.log('handleSortHandledAsc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-inactive ',
      sortOccurredAsc: ' nucleus-icon-inactive hide',

      sortPatientDesc: ' nucleus-icon-inactive ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-active ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-inactive ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      alertsArray: this.sorteredArray6,
    });
    this.alertsRendered = false;
  }

  handleSortUserDesc() {
    console.log('handleSortUserDesc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-inactive ',
      sortOccurredAsc: ' nucleus-icon-inactive hide',

      sortPatientDesc: ' nucleus-icon-inactive ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-inactive ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-inactive hide',
      sortUserAsc: ' nucleus-icon-active ',

      alertsArray: this.sorteredArray7,
    });
    this.alertsRendered = false;
  }

  handleSortUserAsc() {
    console.log('handleSortUserAsc');
    this.alertsRendered = false;
    this.state.alertsArray = [];
    this.setState({
      sortOccurredDesc: ' nucleus-icon-inactive ',
      sortOccurredAsc: ' nucleus-icon-inactive hide',

      sortPatientDesc: ' nucleus-icon-inactive ',
      sortPatientAsc: ' nucleus-icon-inactive hide ',

      sortHandledDesc: ' nucleus-icon-inactive ',
      sortHandledAsc: ' nucleus-icon-inactive hide ',

      sortUserDesc: ' nucleus-icon-active ',
      sortUserAsc: ' nucleus-icon-inactive hide ',

      alertsArray: this.sorteredArray8,
    });
    this.alertsRendered = false;
  }

  onOpenHandledAlertDetailsAction(handledAlertData) {
    console.log('onOpenHandledAlertDetailsAction', handledAlertData);

    this.setState({
      selectedAlertID: handledAlertData.AlertID,
      selectedAlertPatientID: handledAlertData.PatientID,
      selectedAlertPatientName: handledAlertData.PatientName,
      selectedAlertOccurredOn: handledAlertData.AlertOccurredOn,
      selectedAlertDescription: handledAlertData.AlertDescription,
      selectedAlertActionsTaken: handledAlertData.AlertActionsTaken,
    });

    $('#modalHandledAlert').modal('open');
  }

  handleCloseHandledAlertModal() {
    $('#modalHandledAlert').modal('close');
  }

  render() {
    console.log('ALERTS RENDER:');

    let tableResultsView = ' hide';
    let tableNoResultsView = ' hide';
    let tableLoadingView = '';
    if (this.state.loading) {
      tableResultsView = ' hide';
      tableNoResultsView = ' hide';
      tableLoadingView = '';
    } else {
      if (this.state.alertsArray != null && this.state.alertsArray.length > 0) {
        tableResultsView = ' ';
        tableNoResultsView = ' hide';
      } else {
        tableResultsView = ' hide';
        tableNoResultsView = ' ';
      }
    }

    if (this.state.alertsArray != null && this.state.alertsArray.length && !this.alertsRendered) {
      if (this.state.alertsArray.length != 0) {
        this.alertsRendered = true;
      }
      this.handledAlertsList = [];

      this.state.alertsArray.map((alert, key) => {
        this.handledAlertsList.push(
          <AlertHandledItemRow
            key={key}
            AlertID={alert.ID}
            PatientID={alert.PatientID}
            PatientName={alert.PatientName}
            AlertOccurredOn={alert.AlertOccurredOn}
            AlertPatientTimeZone={alert.PatientTimeZone}
            AlertDescription={alert.AlertDescription}
            AlertHandledOn={alert.AlertHandledOn}
            AlertHandledByID={alert.AlertHandledByID}
            AlertHandledByName={alert.AlertHandledByName}
            AlertHandledActionsTaken={alert.AlertHandledActionsTaken}
          />,
        );
      });
    }

    if (this.state.filterTimezonesArray != null && this.state.filterTimezonesArray.length && this.messageFilterTimezonesArray.length == 0) {
      this.state.filterTimezonesArray.map((timezone, key) => {
        this.messageFilterTimezonesArray.push(
          <option key={key} value={timezone.value}>
            {timezone.name}
          </option>,
        );
      });
    }

    return (
      <div className="row no-margin">
        <div className="row">
          <span className="nucleus-labels">
            <b> &nbsp;From</b>
          </span>{' '}
          &nbsp;
          {/* <input 
                        id="fromDateTimeReports" 
                        ref="fromDateTimeReports" 
                        type="text" 
                        className="validate nucleus-date-picker-short" /> */}
          <div style={{ width: 210, display: 'inline-flex' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                ref={this.fromDateTimeReportsRef}
                variant="dialog"
                className="browser-default"
                value={this.state.fromDateTimeReports}
                onChange={this.onFromDateReportsChange}
                onError={console.log}
                onClose={() => {}}
                //minDate={new Date("2018-01-01T00:00")}
                format={this.datetimeFormat}
              />
            </MuiPickersUtilsProvider>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span className="nucleus-labels">
            <b>To</b>
          </span>{' '}
          &nbsp;
          {/* <input 
                        id="toDateTimeReports" 
                        ref="toDateTimeReports" 
                        type="text" 
                        className="validate nucleus-date-picker-short" /> */}
          <div style={{ width: 210, display: 'inline-flex' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDateTimePicker
                ref={this.toDateTimeReportsRef}
                variant="dialog"
                className="browser-default"
                value={this.state.toDateTimeReports}
                onChange={this.onToDateReportsChange}
                onError={console.log}
                onClose={() => {}}
                //minDate={new Date("2018-01-01T00:00")}
                format={this.datetimeFormat}
              />
            </MuiPickersUtilsProvider>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span className="nucleus-labels">
            <b>From/To TimeZone</b>
          </span>{' '}
          &nbsp;
          <select
            ref="selectTimeZone"
            className="browser-default nucleus-select select-large"
            style={{ backgroundColor: 'transparent', borderBottomColor: '#e6e6e6', borderBottomWidth: 1 }}
          >
            {this.messageFilterTimezonesArray}
          </select>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={this.submitFilterAlerts}>
            Submit
          </a>
          &nbsp;
          <br />
          <span class={'nucleus-form-error center-align ' + this.state.datesErrorVisible}>{this.state.datesErrorText}</span>
          <br />
        </div>
        <br />
        <div class={'row ' + tableResultsView}>
          <table className="bordered highlight nucleus-table table-fake-header">
            <thead>
              <tr>
                <th className="table-col-20">
                  <span className="nucleus-table-header-small">Alert Date & Time</span>
                  <a class={'nucleus-font-medium ' + this.state.sortOccurredDesc} onClick={this.handleSortOccurredDesc}>
                    {' '}
                    &nbsp;▲
                  </a>
                  <a class={'nucleus-font-medium ' + this.state.sortOccurredAsc} onClick={this.handleSortOccurredAsc}>
                    &nbsp;▼
                  </a>
                </th>
                <th className="table-col-15">
                  <span className="nucleus-table-header-small">Patient Name</span>
                  <a class={'nucleus-font-medium ' + this.state.sortPatientDesc} onClick={this.handleSortPatientDesc}>
                    {' '}
                    &nbsp;▲
                  </a>
                  <a class={'nucleus-font-medium ' + this.state.sortPatientAsc} onClick={this.handleSortPatientAsc}>
                    &nbsp;▼
                  </a>
                </th>
                <th className="table-col-20">
                  <span className="nucleus-table-header-small">Time Handled</span>
                  <a class={'nucleus-font-medium ' + this.state.sortHandledDesc} onClick={this.handleSortHandledDesc}>
                    {' '}
                    &nbsp;▲
                  </a>
                  <a class={'nucleus-font-medium ' + this.state.sortHandledAsc} onClick={this.handleSortHandledAsc}>
                    &nbsp;▼
                  </a>
                </th>
                <th className="table-col-15">
                  <span className="nucleus-table-header-small">Handled By</span>
                  <a class={'nucleus-font-medium ' + this.state.sortUserDesc} onClick={this.handleSortUserDesc}>
                    {' '}
                    &nbsp;▲
                  </a>
                  <a class={'nucleus-font-medium ' + this.state.sortUserAsc} onClick={this.handleSortUserAsc}>
                    &nbsp;▼
                  </a>
                </th>
                <th className="table-col-30">
                  <span className="nucleus-table-header-small">Alert</span>
                </th>
              </tr>
            </thead>
            <tbody>{this.handledAlertsList}</tbody>
          </table>
        </div>

        <div class={'row center-align ' + tableNoResultsView}>
          <br />
          <br />
          <br />
          <br />
          <span className="nucleus-table-header-medium text-gray">There are no handled alerts for this date range </span>
          <br />
          <br />
          <br />
          <br />
        </div>
        <div class={'row center-align ' + tableLoadingView}>
          <br />
          <br />
          <br />
          <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loading} style={{ display: 'inline-flex' }} />
          <br />
          <br />
          <br />
        </div>

        <div id="modalHandledAlert" className="modal modalAlert modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseHandledAlertModal} />
            <h3 className="nucleus-page-subtitle">Alert Details</h3>
            <br />
            <br />
            <div className="row ">
              <span className="nucleus-labels">
                <b>Alert Date & Time:</b> {this.state.selectedAlertOccurredOn}
              </span>
            </div>
            <div className="row ">
              <span className="nucleus-labels">
                <b>Patient:</b> {this.state.selectedAlertPatientName}
              </span>
            </div>
            <div className="row ">
              <span className="nucleus-labels">
                <b>Alert Description:</b>
              </span>{' '}
              <br />
              <span className="nucleus-labels">{this.state.selectedAlertDescription}</span>
            </div>
            <div className="row ">
              <span className="nucleus-labels">
                <b>Actions taken to handle this alert:</b>
              </span>{' '}
              <br />
              <span className="nucleus-labels">{this.state.selectedAlertActionsTaken}</span>
            </div>
            <div className="row ">
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AlertsReport;
