//@ts-nocheck
import React from 'react';
import AuthStore from '../../stores/AuthStore';

import DashboardContent from './DashboardContent';

class Dashboard extends React.Component {
  constructor() {
    super();

    this.state = {
      userFullName: AuthStore.getUserFullName(),
      isNewSession: AuthStore.getIsNewSession(),
      companyName: AuthStore.getCompanyName(),
      companyLogo: AuthStore.getCompanyLogo(),
    };
  }

  componentDidMount() {
    if (AuthStore.getIsNewSession() != 'yes') {
      Materialize.toast('Welcome ' + this.state.userFullName + '!', 3000, 'rounded');
      AuthStore.clearIsNewSession();
    }
  }

  componentWillUnmount() {}

  render() {
    const style = {
      height: window.innerHeight,
    };

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <div className="row ">
            <div className="col s8 no-margin">
              <p className="nucleus-page-title">
                {' '}
                {this.state.companyName} Welcome, {this.state.userFullName}!!
              </p>
            </div>
            <div className="col s4 right-align ">
              <img className="responsive-img img-company-logo" src={this.state.companyLogo} />
            </div>
          </div>

          <DashboardContent />
        </div>
      </div>
    );
  }
}

export default Dashboard;
