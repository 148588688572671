import React, { useState } from 'react';
import styled from 'styled-components';
import { IState } from './useStateSelector';

const Input = styled.input`
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  border-color: transparent;
`;

const StateTag = styled.span`
  padding: 5px 10px;
  margin: 5px;
  background-color: #dadada;
  color: #0a313f;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-right: 5px;
`;

const Content = styled.div`
  background-color: #fff;
  padding: 18px 10px;
  position: relative;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #0a313f;
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

const ResultsPreview = styled.div`
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  bottom: 100%;
  max-height: 220px;
  overflow-y: scroll;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  left: 0;
`;

const SearchItem = styled.p`
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
`;

type StateSelectInputProps = {
  states: IState[];
  toggleStatesSelection: (stateId: string) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  selectedStates: IState[];
  statesPerGroup: IState[];
};

const StateSelector: React.FC<StateSelectInputProps> = ({ searchTerm, setSearchTerm, states, toggleStatesSelection, selectedStates, statesPerGroup }) => {
  const [isFocused, setIsFocused] = useState(false);

  const highlightText = (name, highlight) => {
    const parts = name?.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) => (part.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{part}</mark> : part));
  };

  const handleValueChange = e => {
    setSearchTerm(e.target.value);
  };

  const renderSelectedStates = () => {
    if (!Array.isArray(selectedStates)) {
      return [];
    }
    return selectedStates?.map(state => (
      <StateTag key={state.ID}>
        {state.StateName}
        <CloseButton onClick={() => toggleStatesSelection(state.ID)}>x</CloseButton>
      </StateTag>
    ));
  };

  const renderSearchResults = () => {
    if (!isFocused) {
      return null;
    }
    const filteredStates = states.filter(state => !selectedStates.some(selectedState => selectedState.ID === state.ID));
    const items = filteredStates.map(state => (
      <SearchItem
        key={state.ID}
        onClick={() => {
          toggleStatesSelection(state.ID);
          setSearchTerm('');
        }}
      >
        {highlightText(state.StateName, searchTerm)}
      </SearchItem>
    ));

    if (!items.length) {
      return null;
    }

    return <ResultsPreview>{items}</ResultsPreview>;
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  return (
    <div>
      <Label>Start typing and a list of names to choose from will appear</Label>
      <Content>
        <Input type="text" className="ui-nucleus" value={searchTerm} onChange={handleValueChange} placeholder="Type name" onFocus={() => setIsFocused(true)} onBlur={handleBlur} />
        {renderSearchResults()}
        {renderSelectedStates()}
      </Content>
    </div>
  );
};

export default StateSelector;
