import moment from 'moment-timezone';
import styled from 'styled-components';
import { TrashSVG } from './assets/TrashSVG';
import { VideoIcon } from './assets/VideoIcon';
import { ProviderSchedule, ProviderScheduleStatus } from './providerSchedule.types';

const ProviderQueueRowContainer = styled.div`
  border-radius: 5px;
  display: flex;
`;

const LeftComponent = styled.div<{ background: string }>(
  props => `
  display: flex;
  flex-direction: row;
  background: ${props.background};
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 10px;
  flex: 1;
  border-radius: 5px;
`,
);

const Column = styled.div(
  ({ color }: { color?: string }) => `
  display: flex;
  flex-direction: column;
  color:${color || ' #0A313F'};
  font-size: 10px;
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
  gap: 5px;
`,
);

const ColumnWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
  text-align: center;
  flex: 0;
  padding-left: 5px;
  padding-right: 5px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const Title = styled.div(
  ({ color }: { color?: string }) => `
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${color || '#0092FF'};
`,
);

const ColumnBody = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 10px;
  justify-content: center;
`;

const ScheduleStatusLabel = styled.div<{ background: string }>(
  props => `
  color: ${props.background || '#FE3824'};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 60px;
`,
);

const ColumnIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 15px;
  margin-right: 5px;
  cursor: pointer;
`;

const ClickableLeftOffset = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -20px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
`;

export const ProviderScheduleRow = ({
  providerSchedule,
  canDelete,
  onClick,
  onDelete,
}: {
  providerSchedule: ProviderSchedule;
  onClick: () => void;
  onDelete: () => void;
  canDelete: boolean;
}) => {
  let callRowStatusBackground = '#EBF2FE';
  let callRowStatusLabelColor = '#000';
  let callRowTitleColor = '#0092FF';
  let callRowContentColor = '#0A313F';

  let formattedStatus: string = providerSchedule.Status;

  switch (providerSchedule.Status) {
    case ProviderScheduleStatus.Canceled:
    case ProviderScheduleStatus.Declined:
    case ProviderScheduleStatus.Failed:
    case ProviderScheduleStatus.NotAnswered:
    case ProviderScheduleStatus.Disconnected:
      callRowStatusBackground = '#FFEAA6';
      callRowStatusLabelColor = '#FE3824';
      callRowTitleColor = '#806510';
      callRowContentColor = '#806510';
      break;
    case ProviderScheduleStatus.Done:
      callRowStatusBackground = '#D9F8AB';
      callRowTitleColor = '#114306';
      callRowContentColor = '#114306';
      break;
  }

  //Normalize the status into human readable format
  switch (providerSchedule.Status) {
    case ProviderScheduleStatus.Canceled:
      formattedStatus = 'Canceled';
      break;
    case ProviderScheduleStatus.Declined:
      formattedStatus = 'Declined';
      break;
    case ProviderScheduleStatus.Failed:
      formattedStatus = 'Failed';
      break;
    case ProviderScheduleStatus.NotAnswered:
      formattedStatus = 'Not Answered';
      break;
    case ProviderScheduleStatus.Done:
      formattedStatus = 'Completed';
      break;
  }

  const isCompleted = providerSchedule.Status === ProviderScheduleStatus.Done;
  const noEvent = () => {};

  const callEndedSecondsAgo = providerSchedule.CallEndedAt ? moment(providerSchedule.CallEndedAt).diff(moment(providerSchedule.CallStartedAt), 'seconds') : 0;
  const normalizedTime = callEndedSecondsAgo > 60 ? `${Math.floor(callEndedSecondsAgo / 60)} minute(s)` : `${callEndedSecondsAgo} second(s)`;
  const callEndedTime = callEndedSecondsAgo === 0 ? '' : normalizedTime;

  return (
    <ProviderQueueRowContainer>
      {canDelete && !isCompleted && (
        <ClickableLeftOffset onClick={onDelete}>
          <TrashSVG />
        </ClickableLeftOffset>
      )}
      <LeftComponent background={callRowStatusBackground}>
        <Row>
          <Column color={callRowContentColor}>
            <Title color={callRowTitleColor}>Account Name:</Title>
            <ColumnBody>{providerSchedule.AccountName}</ColumnBody>
          </Column>
          <Column color={callRowContentColor}>
            <Title color={callRowTitleColor}>Patient Name:</Title>
            <ColumnBody>{providerSchedule.PatientName}</ColumnBody>
          </Column>
          <Column color={callRowContentColor}>
            <Title color={callRowTitleColor}>Doctor:</Title>
            <ColumnBody>{providerSchedule.StatusByUser}</ColumnBody>
          </Column>
          <Column color={callRowContentColor}>
            <Title color={callRowTitleColor}>Schedule Time:</Title>
            {moment(providerSchedule.StartTime).format('hh:mm A zz')}
          </Column>
          <Column color={callRowContentColor}>
            <Title color={callRowTitleColor}>Time Started:</Title>
            {providerSchedule.CallStartedAt ? moment(providerSchedule.CallStartedAt).format('hh:mm A zz') : ''}
          </Column>
          <Column color={callRowContentColor}>
            <Title color={callRowTitleColor}>Length of call:</Title>
            {callEndedTime}
          </Column>
          <ColumnWrap>
            <ScheduleStatusLabel background={callRowStatusLabelColor}>{formattedStatus}</ScheduleStatusLabel>
          </ColumnWrap>
          <ColumnIcon
            style={{
              opacity: isCompleted ? 0 : 1,
              cursor: isCompleted ? 'auto' : 'pointer',
            }}
            onClick={isCompleted ? noEvent : onClick}
          >
            <VideoIcon />
          </ColumnIcon>
        </Row>
      </LeftComponent>
    </ProviderQueueRowContainer>
  );
};
