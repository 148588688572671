//@ts-nocheck
import { EventEmitter } from 'events';
import BulletinApi from '../apis/BulletinApi';

class CareBulletinStore extends EventEmitter {
  constructor() {
    super();
    this.BulletinApi = new BulletinApi(this);

    try {
      this.BulletinButtons = localStorage.BulletinButtons ? JSON.parse(localStorage.BulletinButtons) : [];
    } catch (e) {
      console.error('Error parsing BulletinButtons from localStorage', e);
    }

    this.ButtonsCount = localStorage.ButtonsCount || 0;
    this.retrievingData = false;
  }

  getBulletinButtons() {
    return this.BulletinButtons;
  }

  // getBulletinButtonsSize(){
  // 	return this.BulletinButtonsSize;
  // }

  getButtonsCount() {
    return this.ButtonsCount;
  }

  getBulletinButtonsData({ AccountID }) {
    console.log('getBulletinButtonsData ', AccountID);

    if (!AccountID) {
      console.warn('getBulletinButtonsData AccountID is null');
      return;
    }
    this.retrievingData = true;
    this.BulletinApi.GetAccountBulletinButtons({ AccountID }, rsp => {
      console.log('GetAccountBulletinButtons Response', rsp);
      this.retrievingData = false;

      if (rsp.ok) {
        //sortedArray = sortedArray.sort((a, b) => (new Date(b.AlertOccurredOnMs)) - (new Date(a.AlertOccurredOnMs)));
        this.BulletinButtons = rsp.BulletinButtons;
        //this.BulletinButtonsSize = rsp.media.length;
        //this.saveToLocalStorage();
        this.ButtonsCount = rsp.AccountTemplateBulletinButtons;
        this.saveToLocalStorage();
      }

      this.emit('onGetBulletinButtonsDataComplete', rsp.ok);
    });
  }

  updateButtonContent({ ButtonID, Content }) {
    //console.log("updateButtonContent ", ButtonID, Content  );

    this.BulletinApi.UpdateButtonContent({ ButtonID, Content }, rsp => {
      this.emit('onUpdateButtonContentComplete', rsp);
    });
  }

  UploadBulletinImage(AccountID, FileName, File, ResponseCallback) {
    //console.log("UploadBulletinImage ", UserID, Caption, MediaType, ForAllPatient, PatientIDs + ".", File  );

    const data = new FormData();
    data.append('AccountID', AccountID);
    data.append('ImageFileName', FileName);
    data.append('File', File);
    //console.log("data", data);

    this.BulletinApi.UploadImage(data, rsp => {
      console.log('CareBulletinStore rsp=>', rsp);
      //this.emit("onUploadMediaComplete", rsp);
      ResponseCallback(rsp);
    });
  }

  UploadBulletinFile(AccountID, FileName, File, ResponseCallback) {
    //console.log("UploadBulletinImage ", UserID, Caption, MediaType, ForAllPatient, PatientIDs + ".", File  );

    const data = new FormData();
    data.append('AccountID', AccountID);
    data.append('PdfFileName', FileName);
    data.append('File', File);
    //console.log("data", data);

    this.BulletinApi.UploadPdf(data, rsp => {
      console.log('CareBulletinStore rsp=>', rsp);
      //this.emit("onUploadMediaComplete", rsp);
      ResponseCallback(rsp);
    });
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    //localStorage.QueueList = this.QueueList;
    localStorage.BulletinButtons = JSON.stringify(this.BulletinButtons);
    localStorage.ButtonsCount = JSON.stringify(this.ButtonsCount);

    //localStorage.QueueEmergencyList = this.QueueEmergencyList;
    //localStorage.QueueRequestList = this.QueueRequestList;
  }
}

const careBulletinStore = new CareBulletinStore();

export default careBulletinStore;
