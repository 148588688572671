import AuthStore from '../stores/AuthStore';

export function validateAccessGranted(currentPath, redirect = true) {
  let accessGranted = false;
  if (currentPath.includes('/users') || currentPath.includes('/userProfile') || currentPath.includes('/newUser') || currentPath.includes('/newAssignedPatient')) {
    accessGranted = AuthStore.canAccessStaff();
  } else if (currentPath.includes('/patients') || currentPath.includes('/patientProfile') || currentPath.includes('/newPatient') || currentPath.includes('/newFamilyMember')) {
    accessGranted = AuthStore.canAccessClients();
  } else if (currentPath.includes('/caregivers') || currentPath.includes('/caregiverProfile') || currentPath.includes('/newCaregiver')) {
    //this.selectCaregiverMenu();
    accessGranted = false;
  } else if (currentPath.includes('/settings')) {
    accessGranted = true;
  } else if (currentPath.includes('/messages')) {
    accessGranted = AuthStore.canAccessMessages();
  } else if (currentPath.includes('/queue')) {
    accessGranted = AuthStore.canAccessQueue();
  } else if (currentPath.includes('/alerts')) {
    accessGranted = AuthStore.canAccessAlerts();
  } else if (currentPath.includes('/pictures')) {
    accessGranted = AuthStore.canAccessPictures();
  } else if (currentPath.includes('/bulletin')) {
    accessGranted = AuthStore.canAccessBulletin();
  } else if (currentPath.includes('/classes')) {
    accessGranted = AuthStore.canAccessVideoSession();
  } else if (currentPath.includes('/devices')) {
    accessGranted = AuthStore.canAccessDevices();
  } else if (currentPath.includes('/videoSession')) {
    accessGranted = AuthStore.canAccessVideoSession();
  } else if (
    currentPath.includes('/reports') //|| currentPath.includes("/reportCalls")
  ) {
    accessGranted = AuthStore.getReportsStoreAccess();
  } else if (currentPath.includes('/groups')) {
    accessGranted = AuthStore.getGroupsStoreAccess();
  } else if (currentPath.includes('/externalApps')) {
    accessGranted = AuthStore.canAccessExternalApps();
  }
  console.warn('Access granted for', currentPath, ' -> ', accessGranted);

  if (!accessGranted && redirect) {
    window.location.assign('/dashboard');
  }
  return accessGranted;
}
