import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { useEffect, useState, FC } from 'react';
import styled from 'styled-components';

import { UISmallButton } from '../../../components/UI';
import Message from '../../../utils/Message';

interface Props {
  onSubmit: (from: string, to: string) => void;
  initialFromOffsetDays?: number;
  initialToOffsetDays?: number;
}

const DateFromToSelector: FC<Props> = ({ onSubmit, initialFromOffsetDays = -7, initialToOffsetDays = +1 }) => {
  const [fromDateValue, setFromDateValue] = useState<Date | null>(new Date());
  const [toDateValue, setToDateValue] = useState<Date | null>(new Date());
  const [datesErrorText, setDatesErrorText] = useState('');

  useEffect(() => {
    const passedDate = new Date();
    const currentDate = new Date();
    passedDate.setDate(passedDate.getDate() + initialFromOffsetDays);
    currentDate.setDate(currentDate.getDate() + initialToOffsetDays);

    setFromDateValue(passedDate);
    setToDateValue(currentDate);
    const fromParamString = moment(passedDate).startOf('day').toDate().toISOString();
    const toParamString = moment(currentDate).endOf('day').toDate().toISOString();
    setDatesErrorText('');
    onSubmit(fromParamString, toParamString);
  }, []);

  const onSubmitAction = () => {
    console.log('onSubmitAction');
    if (!fromDateValue || !toDateValue) {
      setDatesErrorText('Please select From and To date.');
      return;
    }
    const fromDateTimeLogs = new Date(fromDateValue);
    const toDateTimeLogs = new Date(toDateValue);
    if (toDateTimeLogs < fromDateTimeLogs) {
      setDatesErrorText('The From Date has to be before the To Date.');
      return;
    }

    if (fromDateTimeLogs == null || fromDateTimeLogs.toString().includes('Invalid Date') || toDateTimeLogs == null || toDateTimeLogs.toString().includes('Invalid Date')) {
      Message.show('Please select a valid date');
      return;
    }

    const fromParamString = moment(fromDateTimeLogs).startOf('day').toDate().toISOString();
    const toParamString = moment(toDateTimeLogs).endOf('day').toDate().toISOString();

    setDatesErrorText('');
    onSubmit(fromParamString, toParamString);
  };

  return (
    <Container>
      <Row>
        <DateSelectorContainer>
          <Label>From</Label>
          <DateSelectorWrapper>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                variant="dialog"
                className="browser-default"
                value={fromDateValue}
                onChange={date => {
                  //@ts-ignore
                  setFromDateValue(date);
                  setDatesErrorText('');
                }}
                autoOk={true}
                onError={console.log}
                format={'MM/DD/YYYY'}
              />
            </MuiPickersUtilsProvider>
          </DateSelectorWrapper>
        </DateSelectorContainer>
        <DateSelectorSeparator> - </DateSelectorSeparator>
        <DateSelectorContainer>
          <Label>To</Label>
          <DateSelectorWrapper>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                variant="dialog"
                className="browser-default"
                value={toDateValue}
                onChange={date => {
                  //@ts-ignore
                  setToDateValue(date);
                  setDatesErrorText('');
                }}
                autoOk={true}
                onError={console.log}
                format={'MM/DD/YYYY'}
              />
            </MuiPickersUtilsProvider>
          </DateSelectorWrapper>
        </DateSelectorContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: 2,
            marginLeft: 12,
          }}
        >
          <UISmallButton onClick={onSubmitAction} text={'  Filter  '} variant={'black'} style={{ width: 212 }} />
        </div>
      </Row>
      <div style={{ display: 'flex', paddingLeft: 15 }}>
        <ErrorLabel>{datesErrorText}</ErrorLabel>
      </div>
    </Container>
  );
};

export default DateFromToSelector;

const Container = styled.div`
  margin-top: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateSelectorContainer = styled.div`
  width: 168px;
  padding: 9px 12px;
  height: 68px;
  margin-right: 12px;
  border-radius: 6px;
`;
const DateSelectorSeparator = styled.div`
  margin: 0px;
  padding: 0px;
  padding-top: 24px;
  font-size: 32px;
`;

const DateSelectorWrapper = styled.div`
  width: 140px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  padding: 5px 5px 0px;
  border-radius: 5px;
  margin-top: 0px;
  width: 158px;
`;

const Label = styled.label`
  color: #0a313f;
  font-size: 15px;
`;
const ErrorLabel = styled.label`
  color: #ff4b30;
  font-size: 15px;
`;
