//@ts-nocheck
import { getAccountUsersProvider } from '@nucleus-care/react-native-nucleuscare-connect-security-client';
import classnames from 'classnames';
import { useState, useCallback } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import { useDispatch } from 'react-redux';
import { JQuery } from 'types/jquery.types';
import { useHasSalesforceIntegration } from '../../../selectors/accountConfig';
import AuthStore from '../../../stores/AuthStore';
import CareUserStore from '../../../stores/CareUserStore';
import { getInstance } from '../../../utils/auth-client';
import Message from '../../../utils/Message';
import { useIsLoadingAccountUsersProvider, useAccountUsersProvider } from '../selectors/accountUsersProviders';
import { useConfigAccessSelector } from '../selectors/loggedInUserConfig';
import css from './SalesforceIntegration.module.css';

const PROVIDER_NAME = 'salesforce';

function ProviderUsersModal({ onClose, show, accountId }) {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [usersToBeImportedMap, setUsersToBeImportedMap] = useState(new Map());
  const loading = useIsLoadingAccountUsersProvider();
  const users = useAccountUsersProvider();
  const handleClose = () => {
    setError('');
    onClose();
  };
  const modalRef = useCallback(
    node => {
      if (node !== null) {
        const modal = ($(node) as unknown as JQuery).modal({
          dismissible: false,
          complete: function () {}, // Callback for Modal close
        });
        if (show === true) {
          modal.modal('open');
        }
        if (show === false) {
          setUsersToBeImportedMap(new Map());
          modal.modal('close');
        }
      }
    },
    [show],
  );
  const handleSelectPatientId = (e, user) => {
    const { currentTarget } = e;
    if (currentTarget.checked) {
      usersToBeImportedMap.set(user.ID, user);
    } else {
      usersToBeImportedMap.set(user.ID, null);
    }
    setUsersToBeImportedMap(new Map(usersToBeImportedMap));
  };
  //@ts-ignore
  const usersSelected = [...usersToBeImportedMap.values()].filter(user => user);
  const haveUsersSelected = usersSelected.length > 0;
  const submitClassName = classnames('txt-white nucleus-font-modal-btn', {
    'nucleus-submit-btn': haveUsersSelected,
    'nucleus-submit-btn-disabled': !haveUsersSelected,
  });
  const handleSubmit = () => {
    const authClient = getInstance();
    authClient
      .importProviderUsers({
        users: usersSelected,
        accountId,
        providerName: PROVIDER_NAME,
      })
      .then(() => {
        Message.show('Salesforce users imported succesfully');
        dispatch(
          getAccountUsersProvider({
            accountId,
            providerName: PROVIDER_NAME,
          }),
        );
        CareUserStore.getAllUsersData({
          UserID: AuthStore.getUserID(),
        });
        onClose();
      })
      .catch(e => {
        let errorMsg = 'Unknown error, please reach support';
        const { response } = e;
        if (response) {
          const { data, code } = response.data;
          if (code === 10) {
            errorMsg = 'Cannot import users that are already registered in the system.';
            if (data && data.length) {
              const emails = data.map(user => user.Email);
              errorMsg += ` Following email addresses already exist: ${emails.join(', ')}`;
            }
          }
        }
        setError(errorMsg);
      });
  };
  const rows =
    users &&
    users.map(user => {
      return (
        <tr key={user.ID}>
          <td>
            <span>
              <input type="checkbox" className="filled-in" id={'checkPatientName' + user.ID} name={user.ID} onChange={e => handleSelectPatientId(e, user)} />
              <label htmlFor={'checkPatientName' + user.ID}>&nbsp;</label>
            </span>
          </td>
          <td>{user.ID}</td>
          <td className={css.columnCenter}>{user.Email}</td>
          <td className={css.columnCenter}>{user.FirstName}</td>
          <td className={css.columnCenter}>{user.LastName}</td>
          <td className={css.columnCenter}>{user.Telephone}</td>
        </tr>
      );
    });
  return (
    <div id="providerUsersModal" ref={modalRef} className="modal modalIntegratePatients">
      <div className="modal-content nucleus-modal-content txt-special-color">
        <div className={css.topBar}>
          <h3 className={css.modalTitle}> Salesforce {AuthStore.getUserLabel()}</h3>
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleClose} />
        </div>
        <div className="row"></div>
        {loading && (
          <div className={'row center-align '}>
            <br />
            <br />
            <br />
            <Spinner color="#2096F3" size={34} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />
            <br />
            <br />
            <br />
          </div>
        )}
        {!loading && rows && rows.length && (
          <div className={'row '}>
            <div>
              <table className="bordered highlight nucleus-report-table bg-white">
                <thead>
                  <tr className="header-biotable-row">
                    <th className={classnames('table-col-5', css.columnHeader, css.columnSelect)}></th>
                    <th className={classnames('table-col-15', css.columnHeader)}>
                      <span className={css.columnHeaderTitle}>ID</span>
                    </th>
                    <th className={classnames('table-col-28', css.columnHeader, css.columnCenter)}>
                      <span className={css.columnHeaderTitle}>Email</span>
                    </th>
                    <th className={classnames('table-col-20', css.columnHeader, css.columnCenter)}>
                      <span className={css.columnHeaderTitle}>First Name</span>
                    </th>
                    <th className={classnames('table-col-20', css.columnHeader, css.columnCenter)}>
                      <span className={css.columnHeaderTitle}>Last Name</span>
                    </th>
                    <th className={classnames('table-col-20', css.columnHeader, css.columnCenter)}>
                      <span className={css.columnHeaderTitle}>Phone</span>
                    </th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    height: 500,
                    maxHeight: 600,
                    overflowY: 'scroll',
                    borderColor: 'green',
                    borderWidth: 1,
                  }}
                >
                  {rows}
                </tbody>
              </table>
            </div>
          </div>
        )}
        <br />

        <div className="input-field col s12 modalCallLogContent2 no-margin">
          {error ? <div className={css.error}>{error}</div> : null}
          <br />
          <div className="row no-margin a-bit-lower">
            <div className="col s12 center-align no-margin">
              <a className={submitClassName} onClick={handleSubmit}>
                Submit
              </a>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function SalesforceSyncButton({ accountId, onClick = () => {} }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(null);
  const hasSalesforceIntegration = useHasSalesforceIntegration();
  const { allow } = useConfigAccessSelector('allowSalesforceImport');
  const handleButtonClick = () => {
    if (onClick) return onClick();
    // fetch users from backend client
    setShowModal(true);
    dispatch(
      getAccountUsersProvider({
        accountId,
        providerName: PROVIDER_NAME,
      }),
    );
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  if (!hasSalesforceIntegration || !allow) return null;
  return (
    <div>
      <a className="nucleus-font-family-medium nucleus-link profile-header-btn3" style={{ marginLeft: 36 }} onClick={handleButtonClick}>
        Sync with Salesforce
      </a>
      <ProviderUsersModal accountId={accountId} show={showModal} onClose={handleModalClose} />
    </div>
  );
}
