import { UIModal } from 'components/UI';
import { FC, useState, useEffect } from 'react';
import { SpinnerCircular } from 'spinners-react';
import authStore from 'stores/AuthStore';
import styled from 'styled-components';
import Message from 'utils/Message';

type AccountUserResetPasswordParams = {
  UserID: string | undefined;
  UserName: string | undefined;
  OnDismiss: () => void;
  resetPassword: boolean;
  resetModalActive: boolean;
  closeResetModal: () => void;
};

const AccountUserResetPassword: FC<AccountUserResetPasswordParams> = props => {
  const ResetState = {
    CLEAN: 1,
    INITIATED: 2,
    COMPLETE: 3,
    FAILED: 4,
  };

  const ButtonStyle = {
    ENABLED: 'nucleus-submit-btn',
    DISABLED: 'nucleus-submit-btn-disabled',
  };

  const [stateLoading, setStateLoading] = useState(false);
  const [stateSelectedUserID, setStateSelectedUserID] = useState(null);
  const [stateSelectedUserName, setStateSelectedUserName] = useState('');
  const [stateSelectedUserNewCode, setStateSelectedUserNewCode] = useState('');
  const [stateSaveButton2Style] = useState(ButtonStyle.ENABLED);
  const [stateResetState, setStateResetState] = useState(ResetState.CLEAN);

  useEffect(() => {
    console.log('AccountUserResetPasswordModal Initial useEffect', props);

    //@ts-ignore
    $('#modalResetUserPassword').modal({
      //@ts-ignore
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    return () => {
      console.log('AccountUserResetPasswordModal Initial useEffect Bye!!!!!');
      props.OnDismiss && props.OnDismiss();
    };
    //refreshReport();
  }, []);

  useEffect(() => {
    console.log('AccountUserResetPasswordModal Initial useEffect props.UserID', props.UserID);

    setStateSelectedUserID(props.UserID);
    setStateSelectedUserName(props.UserName);

    if (props.UserID && props.UserName && props.resetPassword) {
      setStateResetState(ResetState.CLEAN);
      setStateLoading(false);
    }
  }, [props.UserID]);

  const handleCloseModal = () => {
    props.OnDismiss && props.OnDismiss();
    props.closeResetModal();
  };

  const confirmReset = () => {
    console.log('AccountUserResetPasswordModal confirmReset');
    setStateResetState(ResetState.INITIATED);
    setStateLoading(true);

    authStore
      .resetTempPassword(stateSelectedUserID)
      .then(response => {
        console.log('AuthStore.resetTempPassword for ', stateSelectedUserID, response);
        setStateLoading(false);
        if (response.tempPassword) {
          setStateResetState(ResetState.COMPLETE);
          setStateSelectedUserNewCode(response.tempPassword);
        }
      })
      .catch(error => {
        console.log('Error reseting password: ', error);
        setStateResetState(ResetState.FAILED);
      });
  };

  const copySelectedUserNewCode = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(stateSelectedUserNewCode);
      Message.show('Copied!');
    } else {
      document.execCommand('copy', true, stateSelectedUserNewCode);
      Message.show('Copied!');
    }
  };

  return (
    <Row>
      <UIModal
        actions={
          stateResetState != ResetState.COMPLETE
            ? [
                {
                  label: 'Cancel',
                  onClick: props.closeResetModal,
                  buttonVariant: 'blue',
                },
                {
                  label: 'Confirm',
                  onClick: confirmReset,
                  buttonVariant: 'blue',
                },
              ]
            : []
        }
        close={props.closeResetModal}
        isOpen={props.resetModalActive}
        title={''}
        size="large"
      >
        <ModalContent>
          <CenterRow>
            <Subtitle>Generate Temporal Password</Subtitle>
          </CenterRow>

          {stateResetState != ResetState.COMPLETE && (
            <Row>
              <CenterRow>
                <Labels>
                  Are you sure you want to generate a temporal password code for <LabelsBold>{stateSelectedUserName}</LabelsBold> ?
                </Labels>
              </CenterRow>
            </Row>
          )}

          <Row>
            {stateLoading && (
              <CenterRow>
                <Spinner color="#2096F3" />
              </CenterRow>
            )}

            {stateResetState == ResetState.COMPLETE && (
              <ResettedRow>
                <Col>
                  <Labels>
                    The temporal password code for <LabelsBold>{stateSelectedUserName}</LabelsBold> is:
                  </Labels>
                </Col>
                <Col>
                  <LabelsLargeBold>{stateSelectedUserNewCode}</LabelsLargeBold>
                </Col>
                <Col>
                  <Link className={'txt-white nucleus-font-medium ' + stateSaveButton2Style} onClick={copySelectedUserNewCode}>
                    Copy
                  </Link>
                </Col>
                <Col>
                  <Link className={'txt-white nucleus-font-medium ' + stateSaveButton2Style} onClick={handleCloseModal}>
                    Close
                  </Link>
                </Col>
              </ResettedRow>
            )}

            {stateResetState == ResetState.FAILED && (
              <CenterRow>
                <Col>
                  <Labels>There was an error, please try again.</Labels>
                </Col>
                <Col>
                  <Labels>{stateSelectedUserNewCode}</Labels>
                </Col>
              </CenterRow>
            )}
          </Row>
        </ModalContent>
      </UIModal>
    </Row>
  );
};

export default AccountUserResetPassword;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalContent = styled.div`
  .modal-content {
    /* Your modal content styles here */
  }
`;

const CenterRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ResettedRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Subtitle = styled.p`
  font-weight: bold;
  font-size: 16px;
`;

const Labels = styled.span`
  font-size: 16px;
  margin-top: 4%;
  margin-bottom: 3%;
`;

const LabelsBold = styled.span`
  font-weight: bold;
`;

const LabelsLargeBold = styled.span`
  font-weight: bold;
  font-size: large;
`;

const Spinner = styled(SpinnerCircular)`
  /* Spinner styles here */
`;

const Col = styled.div`
  margin-top: 5%;
`;

const Link = styled.a`
  /* Link styles here */
`;
