import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { UIModal } from '../../../../components/UI';
import { ModalAction } from '../../../../components/UI/Modals/Base/ActionButtons';
import authStore from '../../../../stores/AuthStore';
import Message from '../../../../utils/Message';

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;

const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

const Groups = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;

interface Props {
  closeDeleteModal: () => void;
  isOpen: boolean;
  receiver: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  group: {
    id: string;
    name: string;
  } | null;
  titles: {
    ProviderID: string;
    UserRoleName: string;
    UserRoleNamePlural: string;
    Tier1: string;
    Tier1Plural: string;
    Tier2: string;
    Tier2Plural: string;
    Tier3: string;
    Tier3Plural: string;
    LocaleTag: string;
  };
  tier: string;
}

const RemoveProviderGroupEmail: FC<Props> = ({ closeDeleteModal, isOpen, receiver, group, titles, tier }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteRecipient = () => {
    const providerId = authStore.getProviderID();
    httpApi
      .delete(`/providers/${providerId}/groups/${group?.id}/receivers/${receiver?.id}`)
      .then(() => {
        Message.show('Recipient deleted');
        closeDeleteModal();
      })
      .catch(() => {
        Message.show('Error deleting Recipient');
        setIsLoading(false);
      });
  };

  const submit = () => {
    deleteRecipient();
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeDeleteModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Proceed',
        onClick: submit,
        disabled: isLoading,
      },
    ];

    return data;
  };

  return (
    <UIModal size="medium" isOpen={isOpen} close={closeDeleteModal} actions={getActions()}>
      <Title>WARNING!</Title>
      <Note>You are about to remove</Note>
      <Name>{receiver?.email}</Name>
      <Note>from</Note>
      <Name>{`${titles?.[tier]}: ${group?.name}`}</Name>
      <Note>This email will no longer receive</Note>
      <Note>automatic reports</Note>
    </UIModal>
  );
};

export default RemoveProviderGroupEmail;
