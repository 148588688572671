//@ts-nocheck
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import AuthStore from '../../stores/AuthStore';
import CarePatientStore from '../../stores/CarePatientStore';
import GeneralStore from '../../stores/GeneralStore';

//import { IndexLink, Link } from "react-router-dom";
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
import AccountDeviceItemRow from './AccountDeviceItemRow';

class Devices extends React.Component {
  constructor() {
    super();

    validateAccessGranted('/devices');

    GeneralStore.getDSTDates();

    this.state = {
      accountDevicesArray: [],
      loading: false,
      deviceSearchText: '',

      sortPatientDesc: ' nucleus-icon-inactive hide ',
      sortPatientAsc: ' nucleus-icon-inactive ',

      sortDeviceNameDesc: ' nucleus-icon-inactive hide ',
      sortDeviceNameAsc: ' nucleus-icon-inactive ',

      sortConnectedDesc: ' nucleus-icon-inactive hide ',
      sortConnectedAsc: ' nucleus-icon-inactive ',

      sortMacDesc: ' nucleus-icon-inactive hide ',
      sortMacAsc: ' nucleus-icon-inactive ',

      sortPreNameDesc: ' nucleus-icon-inactive hide ',
      sortPreNameAsc: ' nucleus-icon-inactive ',

      sortVersionDesc: ' nucleus-icon-inactive hide ',
      sortVersionAsc: ' nucleus-icon-inactive ',

      sortBatteryDesc: ' nucleus-icon-inactive hide ',
      sortBatteryAsc: ' nucleus-icon-inactive ',

      sortStatusDesc: ' nucleus-icon-inactive hide ',
      sortStatusAsc: ' nucleus-icon-inactive ',

      sortTimeZoneDesc: ' nucleus-icon-inactive hide ',
      sortTimeZoneAsc: ' nucleus-icon-inactive ',
      // udpateDate: new Date(),
      // patientsDataComplete : false  // Deprecated April 12th, 2018
    };

    this.devicesRendered = false;

    this.onGetAccountDevicesAction = this.onGetAccountDevicesAction.bind(this);
    this.handleNoClick = this.handleNoClick.bind(this);
    this.handleDeviceSearchText = this.handleDeviceSearchText.bind(this);

    this.sorteredArray1 = [];
    this.sorteredArray2 = [];
    this.sorteredArray3 = [];
    this.sorteredArray4 = [];
    this.sorteredArray5 = [];
    this.sorteredArray6 = [];

    this.sorteredArrayMac1 = [];
    this.sorteredArrayMac2 = [];

    this.sorteredArrayPreName1 = [];
    this.sorteredArrayPreName2 = [];

    this.sorteredArrayVersion1 = [];
    this.sorteredArrayVersion2 = [];

    this.sorteredArrayBattery1 = [];
    this.sorteredArrayBattery2 = [];

    this.sorteredArrayStatus1 = [];
    this.sorteredArrayStatus2 = [];

    this.sorteredArrayTimeZone1 = [];
    this.sorteredArrayTimeZone2 = [];

    this.handleSortPatientDesc = this.handleSortPatientDesc.bind(this);
    this.handleSortPatientAsc = this.handleSortPatientAsc.bind(this);

    this.handleSortDeviceNameDesc = this.handleSortDeviceNameDesc.bind(this);
    this.handleSortDeviceNameAsc = this.handleSortDeviceNameAsc.bind(this);

    this.handleSortConnectedDesc = this.handleSortConnectedDesc.bind(this);
    this.handleSortConnectedAsc = this.handleSortConnectedAsc.bind(this);

    this.handleSortMacDesc = this.handleSortMacDesc.bind(this);
    this.handleSortMacAsc = this.handleSortMacAsc.bind(this);

    this.handleSortPreNameDesc = this.handleSortPreNameDesc.bind(this);
    this.handleSortPreNameAsc = this.handleSortPreNameAsc.bind(this);

    this.handleSortVersionDesc = this.handleSortVersionDesc.bind(this);
    this.handleSortVersionAsc = this.handleSortVersionAsc.bind(this);

    this.handleSortBatteryDesc = this.handleSortBatteryDesc.bind(this);
    this.handleSortBatteryAsc = this.handleSortBatteryAsc.bind(this);

    this.handleSortStatusDesc = this.handleSortStatusDesc.bind(this);
    this.handleSortStatusAsc = this.handleSortStatusAsc.bind(this);

    this.handleSortTimeZoneDesc = this.handleSortTimeZoneDesc.bind(this);
    this.handleSortTimeZoneAsc = this.handleSortTimeZoneAsc.bind(this);

    this.resetSortIconsState = this.resetSortIconsState.bind(this);
    this.onRebootNewDeviceRequestedAction = this.onRebootNewDeviceRequestedAction.bind(this);

    this.restartDeviceAccess = AuthStore.canRestartDevices();

    this.refreshDevices = this.refreshDevices.bind(this);
  }

  componentDidMount() {
    // //Register Listener

    CarePatientStore.on('onGetAccountDevices', this.onGetAccountDevicesAction);
    CarePatientStore.on('onRebootNewDeviceRequested', this.onRebootNewDeviceRequestedAction);
    this.setState({
      loading: true,
    });

    CarePatientStore.getAccountDevices({
      //UserID: AuthStore.getUserID(),
      //Token: AuthStore.getUserID(),  // The TCareUser has no token, so this is handled on backend
      AccountID: AuthStore.getUserAccountID(),
    });
  }

  componentWillUnmount() {
    // //Remove Listener
    CarePatientStore.removeListener('onGetAccountDevices', this.onGetAccountDevicesAction);
    CarePatientStore.removeListener('onRebootNewDeviceRequested', this.onRebootNewDeviceRequestedAction);
  }

  onGetAccountDevicesAction(response) {
    console.log('onGetAccountDevicesAction', response); //, CarePatientStore.getDevicesArray());

    this.setState({
      loading: false,
    });

    if (response.ok) {
      this.devicesRendered = false;

      this.setState({
        accountDevicesArray: [],
      });

      this.sorteredArray1 = [].concat(response.deviceList);
      this.sorteredArray1 = this.sorteredArray1.sort((a, b) => {
        const textA = (a.PatientFirstName + ' ' + a.PatientLastName).toLowerCase();
        const textB = (b.PatientFirstName + ' ' + b.PatientLastName).toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.devicesRendered = false;

      this.setState({
        accountDevicesArray: response.deviceList,

        sortPatientAsc: ' nucleus-icon-active ',
        sortPatientDesc: ' nucleus-icon-inactive hide ',
      });

      this.sorteredArray2 = [].concat(response.deviceList);
      this.sorteredArray2 = this.sorteredArray2.sort((a, b) => {
        const textA = (a.PatientFirstName + ' ' + a.PatientLastName).toLowerCase();
        const textB = (b.PatientFirstName + ' ' + b.PatientLastName).toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArray3 = [].concat(response.deviceList);
      this.sorteredArray4 = [].concat(response.deviceList);

      this.sorteredArray3 = this.sorteredArray3.sort((a, b) => {
        const textA = a.DeviceName.toLowerCase();
        const textB = b.DeviceName.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sorteredArray4 = this.sorteredArray4.sort((a, b) => {
        const textA = a.DeviceName.toLowerCase();
        const textB = b.DeviceName.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArray5 = [].concat(response.deviceList);
      this.sorteredArray6 = [].concat(response.deviceList);

      this.sorteredArray5 = this.sorteredArray1.sort((a, b) => new Date(b.DeviceInfoLastCheckinTime) - new Date(a.DeviceInfoLastCheckinTime));
      this.sorteredArray6 = this.sorteredArray2.sort((a, b) => new Date(a.DeviceInfoLastCheckinTime) - new Date(b.DeviceInfoLastCheckinTime));

      this.sorteredArrayMac1 = [].concat(response.deviceList);
      this.sorteredArrayMac2 = [].concat(response.deviceList);

      this.sorteredArrayMac1 = this.sorteredArrayMac1.sort((a, b) => {
        let textA = a.Mac ? a.Mac.toLowerCase() : '';
        if (a.HexnodeID != null) {
          textA = a.HexnodeID;
        }
        if (a.MdmDeviceID) {
          textA = a.MdmDeviceID;
        }
        const textB = b.Mac ? b.Mac.toLowerCase() : '';
        if (b.HexnodeID != null) {
          textA = b.HexnodeID;
        }
        if (b.MdmDeviceID != null) {
          textA = b.MdmDeviceID;
        }
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sorteredArrayMac2 = this.sorteredArrayMac2.sort((a, b) => {
        let textA = a.Mac ? a.Mac.toLowerCase() : '';
        if (a.HexnodeID != null) {
          textA = a.HexnodeID;
        }
        if (a.MdmDeviceID != null) {
          textA = a.MdmDeviceID;
        }
        const textB = b.Mac ? b.Mac.toLowerCase() : '';
        if (b.HexnodeID != null) {
          textA = b.HexnodeID;
        }
        if (b.MdmDeviceID != null) {
          textA = b.MdmDeviceID;
        }
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArrayPreName1 = [].concat(response.deviceList);
      this.sorteredArrayPreName2 = [].concat(response.deviceList);

      this.sorteredArrayPreName1 = this.sorteredArrayPreName1.sort((a, b) => {
        const textA = a.PreAssignedName.toLowerCase();
        const textB = b.PreAssignedName.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sorteredArrayPreName2 = this.sorteredArrayPreName2.sort((a, b) => {
        const textA = a.PreAssignedName.toLowerCase();
        const textB = b.PreAssignedName.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArrayVersion1 = [].concat(response.deviceList);
      this.sorteredArrayVersion2 = [].concat(response.deviceList);

      this.sorteredArrayVersion1 = this.sorteredArrayVersion1.sort((a, b) => {
        let textA = '';
        if (a.DeviceInfoAppVersion) {
          textA = a.DeviceInfoAppVersion;
        }
        let textB = '';
        if (b.DeviceInfoAppVersion) {
          textB = b.DeviceInfoAppVersion;
        }
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sorteredArrayVersion2 = this.sorteredArrayVersion2.sort((a, b) => {
        let textA = '';
        if (a.DeviceInfoAppVersion) {
          textA = a.DeviceInfoAppVersion;
        }
        let textB = '';
        if (b.DeviceInfoAppVersion) {
          textB = b.DeviceInfoAppVersion;
        }
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArrayBattery1 = [].concat(response.deviceList);
      this.sorteredArrayBattery2 = [].concat(response.deviceList);

      this.sorteredArrayBattery1 = this.sorteredArrayBattery1.sort((a, b) => {
        let textA = 0;
        if (a.Battery) {
          textA = a.Battery;
        }
        let textB = 0;
        if (b.Battery) {
          textB = b.Battery;
        }
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sorteredArrayBattery2 = this.sorteredArrayBattery2.sort((a, b) => {
        let textA = 0;
        if (a.Battery) {
          textA = a.Battery;
        }
        let textB = 0;
        if (b.Battery) {
          textB = b.Battery;
        }
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArrayStatus1 = [].concat(response.deviceList);
      this.sorteredArrayStatus2 = [].concat(response.deviceList);

      this.sorteredArrayStatus1 = this.sorteredArrayStatus1.sort((a, b) => {
        let textA = 0;
        if (a.DeviceInfoOnline) {
          textA = 1;
        }
        let textB = 0;
        if (b.DeviceInfoOnline) {
          textB = 1;
        }
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sorteredArrayStatus2 = this.sorteredArrayStatus2.sort((a, b) => {
        let textA = 0;
        if (a.DeviceInfoOnline) {
          textA = 1;
        }
        let textB = 0;
        if (b.DeviceInfoOnline) {
          textB = 1;
        }
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.sorteredArrayTimeZone1 = [].concat(response.deviceList);
      this.sorteredArrayTimeZone2 = [].concat(response.deviceList);

      this.sorteredArrayTimeZone1 = this.sorteredArrayTimeZone1.sort((a, b) => {
        const textA = a.DeviceInfoTimezone ? a.DeviceInfoTimezone.toLowerCase() : '';
        const textB = b.DeviceInfoTimezone ? b.DeviceInfoTimezone.toLowerCase() : '';
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });

      this.sorteredArrayTimeZone2 = this.sorteredArrayTimeZone2.sort((a, b) => {
        const textA = a.DeviceInfoTimezone ? a.DeviceInfoTimezone.toLowerCase() : '';
        const textB = b.DeviceInfoTimezone ? b.DeviceInfoTimezone.toLowerCase() : '';
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });
    } else {
      Message.show('Sorry, There was a problem getting Devices information. Please try again.');
    }
  }

  handleDeviceSearchText(e) {
    this.setState({
      deviceSearchText: e.target.value,
    });
  }

  handleNoClick() {
    console.log('Click');
  }

  handleSortPatientDesc() {
    console.log('handleSortPatientDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];

    this.resetSortIconsState();

    this.setState({
      sortPatientAsc: ' nucleus-icon-inactive hide ',
      sortPatientDesc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArray1,
    });
    this.devicesRendered = false;
  }

  handleSortPatientAsc() {
    console.log('handleSortPatientAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortPatientAsc: ' nucleus-icon-active ',
      sortPatientDesc: ' nucleus-icon-inactive hide ',
      accountDevicesArray: this.sorteredArray2,
    });
    this.devicesRendered = false;
  }

  handleSortDeviceNameDesc() {
    console.log('handleSortDeviceNameDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortDeviceNameAsc: ' nucleus-icon-inactive hide ',
      sortDeviceNameDesc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArray3,
    });

    this.devicesRendered = false;
  }

  handleSortDeviceNameAsc() {
    console.log('handleSortDeviceNameAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortDeviceNameAsc: ' nucleus-icon-active ',
      sortDeviceNameDesc: ' nucleus-icon-inactive hide ',

      accountDevicesArray: this.sorteredArray4,
    });
    this.devicesRendered = false;
  }

  handleSortConnectedDesc() {
    console.log('handleSortConnectedDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortConnectedAsc: ' nucleus-icon-inactive hide ',
      sortConnectedDesc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArray5,
    });
    this.devicesRendered = false;
  }

  handleSortConnectedAsc() {
    console.log('handleSortConnectedAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();

    this.setState({
      sortConnectedAsc: ' nucleus-icon-active ',
      sortConnectedDesc: ' nucleus-icon-inactive hide ',
      accountDevicesArray: this.sorteredArray6,
    });
    this.devicesRendered = false;
  }

  handleSortMacAsc() {
    console.log('handleSortMacAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortMacDesc: ' nucleus-icon-active hide ',
      sortMacAsc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArrayMac1,
    });
    this.devicesRendered = false;
  }

  handleSortMacDesc() {
    console.log('handleSortMacDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortMacDesc: ' nucleus-icon-active ',
      sortMacAsc: ' nucleus-icon-active hide ',
      accountDevicesArray: this.sorteredArrayMac2,
    });
    this.devicesRendered = false;
  }

  handleSortPreNameAsc() {
    console.log('handleSortPreNameAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortPreNameDesc: ' nucleus-icon-active hide ',
      sortPreNameAsc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArrayPreName2,
    });
    this.devicesRendered = false;
  }

  handleSortPreNameDesc() {
    console.log('handleSortPreNameDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortPreNameDesc: ' nucleus-icon-active ',
      sortPreNameAsc: ' nucleus-icon-active hide ',
      accountDevicesArray: this.sorteredArrayPreName1,
    });
    this.devicesRendered = false;
  }

  handleSortVersionAsc() {
    console.log('handleSortVersionAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortVersionDesc: ' nucleus-icon-active hide ',
      sortVersionAsc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArrayVersion2,
    });
    this.devicesRendered = false;
  }

  handleSortVersionDesc() {
    console.log('handleSortVersionDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortVersionDesc: ' nucleus-icon-active ',
      sortVersionAsc: ' nucleus-icon-active hide ',
      accountDevicesArray: this.sorteredArrayVersion1,
    });
    this.devicesRendered = false;
  }

  handleSortBatteryAsc() {
    console.log('handleSortBatteryAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortBatteryDesc: ' nucleus-icon-active hide ',
      sortBatteryAsc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArrayBattery2,
    });
    this.devicesRendered = false;
  }

  handleSortBatteryDesc() {
    console.log('handleSortBatteryDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortBatteryDesc: ' nucleus-icon-active ',
      sortBatteryAsc: ' nucleus-icon-active hide ',
      accountDevicesArray: this.sorteredArrayBattery1,
    });
    this.devicesRendered = false;
  }

  handleSortStatusAsc() {
    console.log('handleSortStatusAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortStatusDesc: ' nucleus-icon-active hide ',
      sortStatusAsc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArrayStatus2,
    });
    this.devicesRendered = false;
  }

  handleSortStatusDesc() {
    console.log('handleSortStatusDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortStatusDesc: ' nucleus-icon-active ',
      sortStatusAsc: ' nucleus-icon-active hide ',
      accountDevicesArray: this.sorteredArrayStatus1,
    });
    this.devicesRendered = false;
  }

  handleSortTimeZoneAsc() {
    console.log('handleSortTimeZoneAsc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortTimeZoneDesc: ' nucleus-icon-active hide ',
      sortTimeZoneAsc: ' nucleus-icon-active ',
      accountDevicesArray: this.sorteredArrayTimeZone2,
    });
    this.devicesRendered = false;
  }

  handleSortTimeZoneDesc() {
    console.log('handleSortTimeZoneDesc');
    this.devicesRendered = false;
    this.state.accountDevicesArray = [];
    this.resetSortIconsState();
    this.setState({
      sortTimeZoneDesc: ' nucleus-icon-active ',
      sortTimeZoneAsc: ' nucleus-icon-active hide ',
      accountDevicesArray: this.sorteredArrayTimeZone1,
    });
    this.devicesRendered = false;
  }

  resetSortIconsState() {
    console.log('resetSortIconsState');

    this.setState({
      sortPatientAsc: ' nucleus-icon-inactive ',
      sortPatientDesc: ' nucleus-icon-inactive hide ',

      sortDeviceNameAsc: ' nucleus-icon-inactive ',
      sortDeviceNameDesc: ' nucleus-icon-inactive hide ',

      sortConnectedAsc: ' nucleus-icon-inactive ',
      sortConnectedDesc: ' nucleus-icon-inactive hide ',

      sortMacAsc: ' nucleus-icon-inactive ',
      sortMacDesc: ' nucleus-icon-inactive hide ',

      sortPreNameAsc: ' nucleus-icon-inactive ',
      sortPreNameDesc: ' nucleus-icon-inactive hide ',

      sortVersionAsc: ' nucleus-icon-inactive ',
      sortVersionDesc: ' nucleus-icon-inactive hide ',

      sortBatteryAsc: ' nucleus-icon-inactive ',
      sortBatteryDesc: ' nucleus-icon-inactive hide ',

      sortStatusAsc: ' nucleus-icon-inactive ',
      sortStatusDesc: ' nucleus-icon-inactive hide ',

      sortTimeZoneAsc: ' nucleus-icon-inactive ',
      sortTimeZoneDesc: ' nucleus-icon-inactive hide ',
    });
  }

  onRebootNewDeviceRequestedAction(response) {
    console.log('onRebootNewDeviceRequestedAction');
    console.log(response);
    if (response.ok) {
      Message.show('Reboot device requested, the device will restart soon.');

      // setTimeout(function(){
      //     this.handleRefresh();
      // }, 30000);
    } else {
      Message.show('There was a error requesting the device reboot, please refresh.');
    }
  }

  refreshDevices = () => {
    this.setState({
      loading: true,
    });

    CarePatientStore.getAccountDevices({
      AccountID: AuthStore.getUserAccountID(),
    });
  };

  render() {
    //console.log("Devices RENDER this.devicesRendered:", this.devicesRendered);
    //console.log("Devices RENDER this.state.loading:", this.state.loading);

    let tableResultsView = ' hide';
    let tableLoadingView = '';
    if (this.state.loading) {
      tableResultsView = ' hide';
      tableLoadingView = '';
    } else {
      tableResultsView = '';
      tableLoadingView = 'hide';
    }

    this.devicesRendered = true;

    const accountDevicesList = [];
    const deviceTextToSearch = this.state.deviceSearchText.toLowerCase();
    let devicesCount = 0;
    if (this.state.accountDevicesArray != null) {
      let deviceMac = '';
      this.state.accountDevicesArray.map((device, key) => {
        deviceMac = device.Mac ? device.Mac.toLowerCase() : '';
        device.PreAssignedName || (device.PreAssignedName = '');
        device.Serial || (device.Serial = '');

        //console.log("device", device.PreAssignedName)
        //console.log("deviceSearchText", device.PreAssignedName.indexOf(deviceTextToSearch));
        const patientName = (device.PatientFirstName + ' ' + device.PatientLastName).toLowerCase();
        const version = device.DeviceInfoAppVersion || '';
        if (deviceTextToSearch.length == 0) {
          accountDevicesList.push(
            <AccountDeviceItemRow
              key={key}
              ID={device.ID}
              Mac={device.Mac}
              DeviceUID={device.DeviceUID || ''}
              Serial={device.Serial || ''}
              HexnodeID={device.HexnodeID}
              MdmDeviceID={device.MdmDeviceID}
              MdmID={device.MdmID}
              Battery={device.Battery || ''}
              PatientName={device.PatientFirstName + ' ' + device.PatientLastName}
              PreAssignedName={device.PreAssignedName}
              DeviceName={device.DeviceName}
              DeviceInfoOnline={device.DeviceInfoOnline}
              DeviceInfoLastCheckinTime={device.DeviceInfoLastCheckinTime}
              DeviceInfoTimezone={device.DeviceInfoTimezone}
              DeviceInfoTimezoneOffset={device.DeviceInfoTimezoneOffset}
              DeviceInfoAppVersion={device.DeviceInfoAppVersion}
              DeviceInfo2={device.DeviceInfo2}
              RemoteLogs={device.RemoteLogs}
              //DeviceOS={device.OS}
              RestartDeviceAccess={this.restartDeviceAccess}
            />,
          );
          devicesCount++;
        } else if (
          patientName.indexOf(deviceTextToSearch) >= 0 ||
          // DEPRECATED 6/20/2023 (device.Mac && device.Mac.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
          (device.HexnodeID && device.HexnodeID.toString().indexOf(deviceTextToSearch) >= 0) ||
          (device.MdmDeviceID && device.MdmDeviceID.toString().indexOf(deviceTextToSearch) >= 0) ||
          (device.DeviceName && device.DeviceName.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
          (device.PreAssignedName && device.PreAssignedName.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
          (version && version.toLowerCase().indexOf(deviceTextToSearch) >= 0) ||
          (deviceTextToSearch == 'online' && device.DeviceInfoOnline) ||
          (deviceTextToSearch == 'offline' && !device.DeviceInfoOnline)
        ) {
          devicesCount++;
          accountDevicesList.push(
            <AccountDeviceItemRow
              key={key}
              ID={device.ID}
              Mac={device.Mac}
              DeviceUID={device.DeviceUID || ''}
              Serial={device.Serial || ''}
              HexnodeID={device.HexnodeID}
              MdmDeviceID={device.MdmDeviceID}
              MdmID={device.MdmID}
              Battery={device.Battery || ''}
              PatientName={device.PatientFirstName + ' ' + device.PatientLastName}
              PreAssignedName={device.PreAssignedName}
              DeviceName={device.DeviceName}
              DeviceInfoOnline={device.DeviceInfoOnline}
              DeviceInfoLastCheckinTime={device.DeviceInfoLastCheckinTime}
              DeviceInfoTimezone={device.DeviceInfoTimezone}
              DeviceInfoTimezoneOffset={device.DeviceInfoTimezoneOffset}
              DeviceInfoAppVersion={device.DeviceInfoAppVersion}
              DeviceInfo2={device.DeviceInfo2}
              RemoteLogs={device.RemoteLogs}
              //DeviceOS={device.OS}
              RestartDeviceAccess={this.restartDeviceAccess}
            />,
          );
        }
      });
    }

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">
            Devices
            {/* <span className="nucleus-labels-height">
                        &nbsp; {"(" + devicesCount + ")"}&nbsp;&nbsp;
						</span> */}
          </p>
          <div className="users-table-pending altoImportantContainer">
            {/* <div className="nucleus-table-container"> */}

            <div class={'row ' + tableResultsView}>
              <div class={'no-margin '}>
                <div className="row no-margin a-bit-lower">
                  <div className="col s10 m10 no-margin left-align ">
                    <img style={{ marginRight: -3, marginBottom: -4, height: 19 }} src="/img/search_icon_2.png" />
                    <span className="nucleus-labels-height a-bit-lower"> &nbsp; Search&nbsp;&nbsp; </span>
                    <input
                      ref="txtDeviceSearch"
                      type="text"
                      className="nucleus-input-form-big-left width640"
                      onChange={this.handleDeviceSearchText}
                      placeholder="Patient Name / ID / Device Name / Pre A Name / Version / 'offline' / 'online'"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <a className="txt-white nucleus-font-small nucleus-submit-btn-small" style={{ display: 'inline-block' }} onClick={this.refreshDevices}>
                      {' '}
                      Refresh{' '}
                    </a>
                  </div>
                  <div className="col s2 m2 no-margin left-align "></div>
                </div>
                <div>
                  <p style={{ fontWeight: 'bold', fontSize: 16, color: '#0A313F' }}>
                    {`(${accountDevicesList.length}) Results ${this.state.deviceSearchText != '' && !!(this.state.deviceSearchText.toString().length >= 1) ? 'for ' + '"' + this.state.deviceSearchText + '"' : ''}`}
                  </p>
                </div>
                <br />
                <div className="row nucleus-fixed-container nucleus-table-container-s">
                  <style>
                    {`
                                    .nucleus-table-container-s{
                                        overflow: scroll;
                                        overflow-y: scroll;
                                        height: 70vh !important;
                                    }
                                    `}
                  </style>
                  <table className="b-table b-table-hover b-table-active" style={{}}>
                    <thead style={{ zIndex: 999 }} className="sticky-thead">
                      <tr>
                        {/* 
                                        <th className="table-col-10 left-align">
											<span className="nucleus-table-header">Mac / Hexnode ID</span>
										</th> 
                                        */}
                        <th className="b-col-1 left-align">
                          <span className="nucleus-table-header"> ID </span>
                          <a class={'nucleus-font-medium ' + this.state.sortMacAsc} onClick={this.handleSortMacDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortMacDesc} onClick={this.handleSortMacAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col-2 left-align">
                          <span className="nucleus-table-header">Patient Name</span>
                          <a class={'nucleus-font-medium ' + this.state.sortPatientAsc} onClick={this.handleSortPatientDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortPatientDesc} onClick={this.handleSortPatientAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col-2 left-align">
                          <span className="nucleus-table-header">Device Name</span>
                          <a class={'nucleus-font-medium ' + this.state.sortDeviceNameAsc} onClick={this.handleSortDeviceNameDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortDeviceNameDesc} onClick={this.handleSortDeviceNameAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col-2 left-align">
                          <span className="nucleus-table-header">Pre A. Name</span>
                          <a class={'nucleus-font-medium ' + this.state.sortPreNameAsc} onClick={this.handleSortPreNameDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortPreNameDesc} onClick={this.handleSortPreNameAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col-2 left-align">
                          <span className="nucleus-table-header">Version</span>
                          <a class={'nucleus-font-medium ' + this.state.sortVersionAsc} onClick={this.handleSortVersionDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortVersionDesc} onClick={this.handleSortVersionAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col center-align">
                          <span className="nucleus-table-header">Batt.</span>
                          <a class={'nucleus-font-medium ' + this.state.sortBatteryAsc} onClick={this.handleSortBatteryDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortBatteryDesc} onClick={this.handleSortBatteryAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col center-align">
                          <span className="nucleus-table-header">Status</span>
                          <a class={'nucleus-font-medium ' + this.state.sortStatusAsc} onClick={this.handleSortStatusDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortStatusDesc} onClick={this.handleSortStatusAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col-3 left-align">
                          <span className="nucleus-table-header">Connected on</span>
                          <a class={'nucleus-font-medium ' + this.state.sortConnectedAsc} onClick={this.handleSortConnectedDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortConnectedDesc} onClick={this.handleSortConnectedAsc}>
                            &nbsp;▼
                          </a>
                        </th>
                        <th className="b-col-3 center-align">
                          <span className="nucleus-table-header">Timezone</span>
                          <a class={'nucleus-font-medium ' + this.state.sortTimeZoneAsc} onClick={this.handleSortTimeZoneDesc}>
                            {' '}
                            &nbsp;▲
                          </a>
                          <a class={'nucleus-font-medium ' + this.state.sortTimeZoneDesc} onClick={this.handleSortTimeZoneAsc}>
                            &nbsp;▼
                          </a>
                        </th>

                        {/* <th className="b-col-2 enter-align">
											<span className="nucleus-table-header">Log</span>
										</th> */}
                        <th className="b-col-2 center-align">
                          <span className="nucleus-table-header">
                            {/* <span
													className="nucleus-table-icon nucleus-link tooltipped"
													data-delay="50"
													data-position="top"
													data-tooltip="Remove"
												>
													<i className="Tiny material-icons">not_interested</i>
												</span> */}
                            {/* <a class={ "txt-white nucleus-font-small nucleus-submit-btn-small" + deleteDeviceVisibility } onClick={this.handleDeleteDevice}>Delete</a> */}
                            {this.restartDeviceAccess && (
                              <img
                                src="/img/icon_device_restart.png"
                                className="nucleus-link tooltipped"
                                data-delay="50"
                                data-position="top"
                                data-tooltip="Remote restart"
                                width="28"
                              />
                            )}
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{accountDevicesList}</tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class={'row center-align ' + tableLoadingView}>
              <br />
              <br />
              <br />
              <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loading} style={{ display: 'inline-flex' }} />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Devices;
