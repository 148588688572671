//@ts-nocheck
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import { CSVLink } from 'react-csv';

import MessageItemRow2 from '../../components/Messages/MessageItemRow2';
import MessagesReportPdf from '../../components/Messages/MessagesReportPdf';
import NucleusMessage from '../../components/Messages/NucleusMessage';
import { AuthStore, GeneralStore, MessagesStore } from '../../stores';

import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';

class Messages extends React.Component {
  constructor() {
    super();

    validateAccessGranted('/messages');
    GeneralStore.getDSTDates();

    this.state = {
      messagesArray: [],

      fromDateTime: null,
      toDateTime: null,
      //messagesArrayDesc : MessagesStore.getMessages(),
      //messagesArrayAsc : [],
      filterTimezonesArray: GeneralStore.getIsDST() ? GeneralStore.GetDaylightTimezones() : GeneralStore.GetStandardTimezones(),

      loading: false,
      noResultsLabel: 'There are no messages for this date range',

      arrayRecurringDesc: [],
      arrayRecurringAsc: [],

      arrayLastSentDesc: [],
      arrayLastSentAsc: [],

      arrayNextDeliveryDesc: [],
      arrayNextDeliveryAsc: [],

      arraySenderDesc: [],
      arraySenderAsc: [],

      arrayRecipientDesc: [],
      arrayRecipientAsc: [],

      sortRecurringDesc: ' nucleus-icon-active ',
      sortRecurringAsc: ' nucleus-icon-active hide ',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      datesErrorVisible: ' hide',
      datesErrorText: 'The From Date has to be before the To Date.',

      messageReportID: null,
      messageReport: [],
      messageReportBody: '',
      messageReportSender: '',
      messageReportRecurring: '',

      messageReportDeliveryDesc: [],
      messageReportDeliveryAsc: [],

      messageReportPatientDesc: [],
      messageReportPatientAsc: [],

      sortReportDeliveryDesc: ' nucleus-icon-active ',
      sortReportDeliveryAsc: ' nucleus-icon-active hide ',

      sortReportPatientDesc: ' nucleus-icon-inactive ',
      sortReportPatientAsc: ' nucleus-icon-inactive hide ',

      reportLoading: false,

      messageModalMode: 'New',
      messageSaveNewBtn: ' ',
      messageUpdateBtn: ' hide',

      messageEditable: '',

      messageModalModeShow: ' hide',
      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',
      messageModalNotEditable: ' hide',

      deleteMessageBtnStyle: 'nucleus-submit-btn',
      editMessageBtnStyle: ' nucleus-submit-btn ',

      newMessageSingleChoice: false,
      newMessageMultipleSelection: false,
      newMessageCategory: null,

      messageCategories: MessagesStore.getMessageCategories(),

      messageTypeError: ' hide',
      messageTopicError: ' hide',
      messageDeliveryDateError: ' ',
      messageEndDateError: ' ',
      messageBroadcast: true,

      messageDeliverTo: ' ',
      messageDeliveryAt: ' ',

      canSendAllPatients: '',

      messagePatientsList: {
        //'T' : 'https://st2.depositphotos.com/3101765/8372/v/450/depositphotos_83729944-stock-illustration-border-collie-animal-dog-watercolor.jpg' : '13'
      },
      messageAutocompleteDataList: {},
      selectedMessagePatientsIDs: null,
      selectPatientsVisibility: ' hide',

      singleChoiceVisibility: ' hide',
      multipleOptionVisibility: ' hide',
      messageOptionsError: ' hide',

      repeatConfigVisibility: ' hide',
      repeatWeekVisibility: ' hide',

      messageRepeat: false,
      messageRepeatStyle: '',
      messageRepeatEveryError: ' hide',
      stopRecurringMessageVisibility: ' hide',
      stopRecurringMessageStyle: ' nucleus-submit-btn-small ',

      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',

      messageBodyError: ' hide',
      messageBodyLengthError: ' hide',

      sortLastDescending: ' ',
      sortLastAscending: ' hide',

      selectedMessageID: null,

      messageReportFromDateTime: null,
      messageReportToDateTime: null,

      messageReportIncludesSignature: false,

      btnDeleteQueueItem: 'nucleus-submit-btn-small',

      udpateDate: new Date(),
    };

    this.fromDateTimeRef = React.createRef();
    this.toDateTimeRef = React.createRef();
    this.messageReportFromDateTimeRef = React.createRef();
    this.messageReportToDateTimeRef = React.createRef();

    this.allOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' };
    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.messagesRendered = false;

    this.onGetMessagesForAccountUpdatedAction = this.onGetMessagesForAccountUpdatedAction.bind(this);

    this.submitFilterMessages = this.submitFilterMessages.bind(this);

    this.onRefreshMessageCategoriesAction = this.onRefreshMessageCategoriesAction.bind(this);

    this.onGetAutocompletePatientsListAction = this.onGetAutocompletePatientsListAction.bind(this);

    this.handleNewMessageModal = this.handleNewMessageModal.bind(this);
    this.handleCloseNewMessageModal = this.handleCloseNewMessageModal.bind(this);
    this.handleMessageModalMode = this.handleMessageModalMode.bind(this);

    this.handleSelectMessageType = this.handleSelectMessageType.bind(this);
    this.handleResetOptionsValidationError = this.handleResetOptionsValidationError.bind(this);
    this.handleSelectMessageCategory = this.handleSelectMessageCategory.bind(this);
    this.handleMessageBroadcast = this.handleMessageBroadcast.bind(this);
    this.handleMessageRepeat = this.handleMessageRepeat.bind(this);
    this.handleStopRecurringMessageModal = this.handleStopRecurringMessageModal.bind(this);
    this.handleCancelStopRecurringMessageModal = this.handleCancelStopRecurringMessageModal.bind(this);
    this.handleStopRecurringMessage = this.handleStopRecurringMessage.bind(this);

    this.handleTxtRepeatEvery = this.handleTxtRepeatEvery.bind(this);
    this.handleSelectMessageRecurring = this.handleSelectMessageRecurring.bind(this);
    this.handleMessageEnds = this.handleMessageEnds.bind(this);

    this.handleMessageText = this.handleMessageText.bind(this);
    this.handleSaveMessage = this.handleSaveMessage.bind(this);

    this.handleCancelResumeMessageModal = this.handleCancelResumeMessageModal.bind(this);
    this.handleResumeSaveMessage = this.handleResumeSaveMessage.bind(this);

    this.handleUpdateMessage = this.handleUpdateMessage.bind(this);

    this.onSaveMessageByUserAction = this.onSaveMessageByUserAction.bind(this);

    this.onGetMessageReportStarted = this.onGetMessageReportStarted.bind(this);
    this.onGetMessageReportAction = this.onGetMessageReportAction.bind(this);
    this.handleCloseMessageReportsModal = this.handleCloseMessageReportsModal.bind(this);
    this.handleUpdateMessageReport = this.handleUpdateMessageReport.bind(this);
    this.onGetMessageReportUpdatedAction = this.onGetMessageReportUpdatedAction.bind(this);

    this.handleSortReportDeliveryDesc = this.handleSortReportDeliveryDesc.bind(this);
    this.handleSortReportDeliveryAsc = this.handleSortReportDeliveryAsc.bind(this);
    this.handleSortReportPatientDesc = this.handleSortReportPatientDesc.bind(this);
    this.handleSortReportPatientAsc = this.handleSortReportPatientAsc.bind(this);

    this.onStopRecurringMessageAction = this.onStopRecurringMessageAction.bind(this);
    this.handleSubmitDeleteMessage = this.handleSubmitDeleteMessage.bind(this);

    //this.handleSortInit = this.handleSortInit.bind(this);

    //this.handleSortByDateDesc = this.handleSortByDateDesc.bind(this);
    //this.handleSortByDateAsc = this.handleSortByDateAsc.bind(this);

    this.handleSortRecurringDesc = this.handleSortRecurringDesc.bind(this);
    this.handleSortRecurringAsc = this.handleSortRecurringAsc.bind(this);

    this.handleSortLastSentDesc = this.handleSortLastSentDesc.bind(this);
    this.handleSortLastSentAsc = this.handleSortLastSentAsc.bind(this);

    this.handleSortNextDeliveryDesc = this.handleSortNextDeliveryDesc.bind(this);
    this.handleSortNextDeliveryAsc = this.handleSortNextDeliveryAsc.bind(this);

    this.handleSortSenderDesc = this.handleSortSenderDesc.bind(this);
    this.handleSortSenderAsc = this.handleSortSenderAsc.bind(this);

    this.handleSortRecipientDesc = this.handleSortRecipientDesc.bind(this);
    this.handleSortRecipientAsc = this.handleSortRecipientAsc.bind(this);

    //this.handleSortInit();
    this.onRefreshMessagesCenterAction = this.onRefreshMessagesCenterAction.bind(this);
    this.handleUpdateTimeZone = this.handleUpdateTimeZone.bind(this);

    this.createSortedMessagesArray = this.createSortedMessagesArray.bind(this);

    this.generatePdfReport = this.generatePdfReport.bind(this);

    this.controlTimezone = 0;

    this.sorteredArray1 = [];
    this.sorteredArray2 = [];
    this.sorteredArray3 = [];
    this.sorteredArray4 = [];
    this.sorteredArray5 = [];
    this.sorteredArray6 = [];
    this.sorteredArray7 = [];
    this.sorteredArray8 = [];
    this.sorteredArray9 = [];
    this.sorteredArray10 = [];

    this.messageFilterTimezonesArray = [];

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.datetimeFormat = 'MM/DD/YYYY hh:mm A';
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      this.datetimeFormat = 'MM/DD/YYYY HH:mm';
      //this.timeFormat = "HH:mm A";
      this.timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }
  }

  componentDidMount() {
    //Register Listener

    MessagesStore.on('onGetMessagesForAccountUpdated', this.onGetMessagesForAccountUpdatedAction);
    MessagesStore.on('onRefreshMessageCategories', this.onRefreshMessageCategoriesAction);
    MessagesStore.on('onGetAutocompletePatientsList', this.onGetAutocompletePatientsListAction);
    MessagesStore.on('onSaveMessageByUser', this.onSaveMessageByUserAction);

    MessagesStore.on('onGetMessageReport', this.onGetMessageReportStarted);
    MessagesStore.on('onGotMessageReport', this.onGetMessageReportAction);
    MessagesStore.on('onGetMessageReportUpdated', this.onGetMessageReportUpdatedAction);

    MessagesStore.on('onStopRecurringMessage', this.onStopRecurringMessageAction);

    MessagesStore.on('onRefreshMessagesCenter', this.onRefreshMessagesCenterAction);

    // Deprecated: Jan 30th 2019; CARE-4076
    // MessagesStore.getMessagesForUserUpdated({
    //    UserID : AuthStore.getUserID()
    // });

    MessagesStore.getAutocompletePatientsGroupsList({
      UserID: AuthStore.getUserID(),
    });

    if (!(this.state.messageCategories.length > 0)) {
      MessagesStore.refreshMessageCategries();
    }

    $('.tooltipped').tooltip({ delay: 50 });

    // console.log("PATIENTS HASH: ", CarePatientStore.getPatientDataComplete());
    // if (CarePatientStore.getPatientDataComplete() > 0){
    //     this.setState({
    //         udpateDate: new Date(),
    //         patientsDataComplete : true
    //     });
    // }

    $('.modalMessages').modal({
      dismissible: true,
      complete: function () {}, // Callback for Modal close
    });

    /*
		// DEPRECATED to use Component "NucleusMessage"
		$('#messageDateTime').datetimepicker({
						//minDate: '-1d',
						format:'m/d/Y h:i A',
						dateFormat: 'm/d/Y',
						timeFormat: 'hh:mm A',
						timepicker : true,
						hours12 : false,
						//onSelectDate : this.validateDate
						//defaultDate : firstDay
						validateOnBlur : true,
						minDate: '-1d'
				}).on("change", (e) => {
						console.log("Date changed: assignmentDateTime ", e.target.value);
						this.setState({
				messageDeliveryDateError : ' hide'
			});
				});

		$('#messageEndDateTime').datetimepicker({
						//minDate: '-1d',
						format:'m/d/Y h:i A',
						// dateFormat: 'm/d/Y',
						// timeFormat: 'hh:mm A',
						timepicker : true,
						//onSelectDate : this.validateDate
						//defaultDate : firstDay
						validateOnBlur : true,
						minDate: '-1d'
				}).on("change", (e) => {
						console.log("Date changed: assignmentDateTime ", e.target.value);
						this.setState({
							messageEndDateError : ' hide'
						});
				});
				*/

    const currentDate = new Date();
    const fromDate = new Date();
    const toDate = new Date();

    fromDate.setDate(fromDate.getDate() - 7);
    toDate.setDate(toDate.getDate() + 7);

    console.log('DATE INITIAL:', currentDate);
    console.log('DATE INITIAL:', fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', this.timeOptions));
    console.log('DATE INITIAL:', toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', this.timeOptions));

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('TIMEZONE:', currentTimezone);
    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    this.controlTimezone = currentTimezone;
    console.log('CONTROL TIMEZONE:', this.controlTimezone);
    let formatedTimezone = '' + currentTimezone;

    if (currentTimezone > 0) {
      formatedTimezone = '+' + currentTimezone;
    }
    console.log('FORMATED TIMEZONE:', formatedTimezone);

    // $('#fromDateTime').datetimepicker({
    //     //minDate: '-1d',
    //     format:this.datetimeFormat,
    //     dateFormat: 'm/d/Y',
    //     timeFormat: 'hh:mm A',
    //     timepicker : true,
    //     //onSelectDate : this.validateDate
    //     //defaultDate : fromDate
    //     validateOnBlur : true,
    //     closeOnDateSelect:true
    // });
    const initFromDate = new Date(fromDate);
    const initToDate = new Date(toDate);
    this.setState({
      fromDateTime: initFromDate,
      toDateTime: initToDate,
    });
    //this.refs.fromDateTime.value  = fromDate.toLocaleDateString('en-US', this.dateOptions) + " " + fromDate.toLocaleTimeString('en-US', this.timeOptions);

    // $('#toDateTime').datetimepicker({
    //     //minDate: new Date(this.refs.fromDateTime.value),
    //     format:this.datetimeFormat,
    //     dateFormat: 'm/d/Y',
    //     timeFormat: 'hh:mm A',
    //     timepicker : true,
    //     //onSelectDate : this.validateDate,
    //     //defaultDate : toDate
    //     validateOnBlur : true,
    //     closeOnDateSelect:true
    // });

    // this.refs.toDateTime.value = toDate.toLocaleDateString('en-US', this.dateOptions) + " " + toDate.toLocaleTimeString('en-US', this.timeOptions);

    if (this.refs.selectTimeZone.value == '') {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please select one of the available time zones.',
      });
      return;
    }

    try {
      this.refs.selectTimeZone.value = formatedTimezone;
    } catch (err) {
      console.log('>>>>>> Catch Timezone');
    }

    const messagesSize = MessagesStore.getMessagesSize();
    console.log('MENSAGES -->', messagesSize);
    if (messagesSize < 1 || true) {
      console.log('Request');
      this.refs.selectTimeZone.value = formatedTimezone;

      fromDate.setHours(fromDate.getHours() + this.controlTimezone * -1);
      toDate.setHours(toDate.getHours() + this.controlTimezone * -1);
      const fromParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
      const toParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

      //console.log("Requesting --> ", fromParam, " a ", toParam);

      MessagesStore.getMessagesForAccountUpdated({
        AccountID: AuthStore.getUserAccountID(),
        FromDateTime: fromParam,
        ToDateTime: toParam,
      });
      this.setState({
        loading: true,
        noResultsLabel: 'There are no messages for this date range',
      });
    } else {
      // This was cached data: Deprecated!
      this.setState({
        loading: true,
      });
      this.setState({
        fromDateTime: MessagesStore.getMessageFilterFrom(),
      });
      //this.refs.fromDateTime.value =
      this.refs.toDateTime.value = MessagesStore.getMessageFilterTo();
      this.refs.selectTimeZone.value = MessagesStore.getMessagesFilterTimezone();

      setTimeout(() => {
        this.alertsRendered = false;

        // this.setState({
        // 	messagesArray : MessagesStore.getMessages()
        // });
        this.createSortedMessagesArray(MessagesStore.getMessages());

        this.setState({
          loading: false,
        });
      }, 900);
    }
  }

  componentWillUnmount() {
    //Remove Listener

    MessagesStore.removeListener('onGetMessagesForAccountUpdated', this.onGetMessagesForAccountUpdatedAction);
    MessagesStore.removeListener('onRefreshMessageCategories', this.onRefreshMessageCategoriesAction);
    MessagesStore.removeListener('onGetAutocompletePatientsList', this.onGetAutocompletePatientsListAction);
    MessagesStore.removeListener('onSaveMessageByUser', this.onSaveMessageByUserAction);

    MessagesStore.removeListener('onGetMessageReport', this.onGetMessageReportStarted);
    MessagesStore.removeListener('onGotMessageReport', this.onGetMessageReportAction);
    MessagesStore.removeListener('onGetMessageReportUpdated', this.onGetMessageReportUpdatedAction);

    MessagesStore.removeListener('onStopRecurringMessage', this.onStopRecurringMessageAction);

    MessagesStore.removeListener('onRefreshMessagesCenter', this.onRefreshMessagesCenterAction);
  }

  onFromDateChange = (date, value) => {
    console.log('onFromDateChange', date, value);
    this.setState({
      fromDateTime: value,
    });
  };

  onToDateChange = (date, value) => {
    console.log('onToDateChange', date, value);
    this.setState({
      toDateTime: value,
    });
  };
  onGetMessagesForAccountUpdatedAction(response) {
    console.log('onGetMessagesForAccountUpdatedAction', response);

    if (response.ok) {
      this.setState({
        messagesArray: [],
        loading: false,
      });

      if (response.Messages.length == 0) {
        //Message.show("There are no Messages for the selected period of time.");
        this.setState({
          noResultsLabel: 'There are no messages for this date range',
        });
      }

      this.createSortedMessagesArray(response.Messages);

      console.log('storing setMessageFilterFrom', this.state.fromDateTime);
      console.log('storing setMessageFilterTo', this.state.toDateTime);

      MessagesStore.setMessageFilterFrom(this.state.fromDateTime);
      MessagesStore.setMessageFilterTo(this.state.toDateTime);
      MessagesStore.setMessagesFilterTimezone(this.refs.selectTimeZone.value);
      //Message.show("Messages updated");
    } else {
      // console.log("Obteniendo");
      // this.alertsRendered = false;
      // this.setState({
      // 	messagesArray : MessagesStore.getMessages()
      // });
      this.setState({
        loading: false,
        noResultsLabel: 'We could not retrieve the results. Please try again.',
      });
    }
  }

  createSortedMessagesArray(messagesArray) {
    this.sorteredArray6 = [].concat(messagesArray);
    this.sorteredArray6 = this.sorteredArray6.sort((a, b) => new Date(a.NextDelivery) - new Date(b.NextDelivery));

    this.setState({
      messagesArray: this.sorteredArray6,

      sortRecurringDesc: ' nucleus-icon-inactive hide ',
      sortRecurringAsc: ' nucleus-icon-inactive ',

      sortLastSentDesc: ' nucleus-icon-inactive hide',
      sortLastSentAsc: ' nucleus-icon-inactive ',

      sortNextDeliveryDesc: ' nucleus-icon-active ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      // arrayRecurringDesc : sorteredArray1,
      // arrayRecurringAsc : sorteredArray2,

      // arrayLastSentDesc : sorteredArray3,
      // arrayLastSentAsc : sorteredArray4,

      // arrayNextDeliveryDesc : sorteredArray5,
      // arrayNextDeliveryAsc : sorteredArray6,

      // arraySenderDesc : sorteredArray7,
      // arraySenderAsc : sorteredArray8,

      // arrayRecipientDesc : sorteredArray9,
      // arrayRecipientAsc : sorteredArray10,

      udpateDate: new Date(),
    });

    this.sorteredArray1 = [].concat(messagesArray);
    this.sorteredArray2 = [].concat(messagesArray);

    this.sorteredArray3 = [].concat(messagesArray);
    this.sorteredArray4 = [].concat(messagesArray);

    this.sorteredArray5 = [].concat(messagesArray);

    this.sorteredArray7 = [].concat(messagesArray);
    this.sorteredArray8 = [].concat(messagesArray);

    this.sorteredArray9 = [].concat(messagesArray);
    this.sorteredArray10 = [].concat(messagesArray);

    this.sorteredArray1 = this.sorteredArray1.sort((a, b) => {
      const textA = a.Reccuring.toLowerCase();
      const textB = b.Reccuring.toLowerCase();
      if (textB < textA)
        //sort string ascending
        return -1;
      if (textB > textA) return 1;
      return 0;
    });
    this.sorteredArray2 = this.sorteredArray2.sort((a, b) => {
      const textA = a.Reccuring.toLowerCase();
      const textB = b.Reccuring.toLowerCase();
      if (textA < textB)
        //sort string ascending
        return -1;
      if (textA > textB) return 1;
      return 0;
    });

    this.sorteredArray3 = this.sorteredArray3.sort((a, b) => new Date(b.LastSent) - new Date(a.LastSent)); //|| (new Date(b.NextDelivery)) - (new Date(a.NextDelivery)) );
    this.sorteredArray4 = this.sorteredArray4.sort((a, b) => new Date(a.LastSent) - new Date(b.LastSent)); //|| (new Date(b.NextDelivery)) - (new Date(a.NextDelivery)) );

    this.sorteredArray5 = this.sorteredArray5.sort((a, b) => new Date(b.NextDelivery) - new Date(a.NextDelivery));

    this.sorteredArray7 = this.sorteredArray7.sort((a, b) => {
      const textA = a.Sender.toLowerCase();
      const textB = b.Sender.toLowerCase();
      if (textB < textA)
        //sort string ascending
        return -1;
      if (textB > textA) return 1;
      return 0;
    });
    this.sorteredArray8 = this.sorteredArray8.sort((a, b) => {
      const textA = a.Sender.toLowerCase();
      const textB = b.Sender.toLowerCase();
      if (textA < textB)
        //sort string ascending
        return -1;
      if (textA > textB) return 1;
      return 0;
    });

    this.sorteredArray9 = this.sorteredArray9.sort((a, b) => {
      const textA = a.Recipients.toLowerCase();
      const textB = b.Recipients.toLowerCase();
      if (textB < textA)
        //sort string ascending
        return -1;
      if (textB > textA) return 1;
      return 0;
    });
    this.sorteredArray10 = this.sorteredArray10.sort((a, b) => {
      const textA = a.Recipients.toLowerCase();
      const textB = b.Recipients.toLowerCase();
      if (textA < textB)
        //sort string ascending
        return -1;
      if (textA > textB) return 1;
      return 0;
    });
    //let unsortedArray = messagesArray;
    //let sorteredArrayDesc = messagesArray;

    //ASC
    //sorteredArrayAsc = messagesArray.slice(0).sort((a, b) => (new Date(a.LastSent)) - (new Date(b.LastSent)));

    //DESC
    //sorteredArrayDesc= unsortedArray.sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate));
  }

  submitFilterMessages() {
    this.setState({
      datesErrorVisible: ' hide',
    });
    if (!(this.state.fromDateTime && this.state.fromDateTime.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please enter the From Date',
      });
      return;
    }

    if (!(this.state.toDateTime && this.state.toDateTime.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please enter the To Date',
      });
      return;
    }

    const fromDate = new Date(this.state.fromDateTime);
    const toDate = new Date(this.state.toDateTime);

    fromDate.setHours(fromDate.getHours() + this.controlTimezone * -1);
    toDate.setHours(toDate.getHours() + this.controlTimezone * -1);

    if (toDate < fromDate) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' The From Date has to be before the To Date.',
      });
      return;
    } else {
    }

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', this.timeOptions);
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', this.timeOptions);

    console.log('PARAM 1', fromDateParam);
    console.log('PARAM 2', toDateParam);
    console.log('ACCOUNTID', AuthStore.getUserAccountID());

    this.setState({
      loading: true,
    });

    MessagesStore.getMessagesForAccountUpdated({
      AccountID: AuthStore.getUserAccountID(),
      FromDateTime: fromDateParam,
      ToDateTime: toDateParam,
    });
  }

  onRefreshMessagesCenterAction() {
    //this.submitFilterMessages();

    // let fromDate = new Date();
    // let toDate = new Date();

    // fromDate.setDate(fromDate.getDate() - 7);
    // toDate.setDate(toDate.getDate() + 7);

    // fromDate.setHours(fromDate.getHours() + (this.controlTimezone * -1));
    // toDate.setHours(toDate.getHours() + (this.controlTimezone * -1));

    const fromDate = new Date(this.state.fromDateTime);
    const toDate = new Date(this.state.toDateTime);

    fromDate.setHours(fromDate.getHours() + this.controlTimezone * -1);
    toDate.setHours(toDate.getHours() + this.controlTimezone * -1);

    if (toDate < fromDate) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' The From Date has to be before the To Date.',
      });
      return;
    } else {
    }

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', this.timeOptions);
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', this.timeOptions);

    this.setState({
      loading: true,
    });

    console.log('PARAM 1', fromDateParam);
    console.log('PARAM 2', toDateParam);

    MessagesStore.getMessagesForAccountUpdated({
      AccountID: AuthStore.getUserAccountID(),
      FromDateTime: fromDateParam,
      ToDateTime: toDateParam,
    });
  }

  handleUpdateTimeZone() {
    const newTimezone = Number(this.refs.selectTimeZone.value);
    console.log('handleUpdateTimeZone', newTimezone);
    this.controlTimezone = newTimezone;
  }

  onRefreshMessageCategoriesAction(response) {
    console.log('onRefreshMessageCategoriesAction', response);
    if (response.ok) {
      this.setState({
        messageCategories: response.Categories,
      });
    }
  }

  // DEPRECATED
  onGetAutocompletePatientsListAction(response) {
    console.log('onGetAutocompletePatientsListAction 1 DEPRECATED!', response);
  }

  // Reset Modal NEW Message
  handleNewMessageModal() {
    console.log('Hello!!');

    $('#modalNewMessage').modal('open');

    console.log('Message categories are: ', this.state.messageCategories);

    this.refs.selectMessageType.value = '';
    this.refs.selectMessageCategory.value = '';
    this.refs.checkMessageAllPatients.checked = true;

    this.refs.checkRepeat.checked = false;

    this.refs.txtRepeatNumber.value = '1';
    this.refs.selectMessageRecurring.value = 'Day';

    this.setState({
      messageModalMode: 'New',
      messageSaveNewBtn: ' ',
      messageUpdateBtn: ' hide',

      messageEditable: '',

      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',

      editMessageBtnStyle: ' nucleus-submit-btn ',

      messageModalModeShow: ' hide',
      messageModalNotEditable: ' hide',

      newMessageSingleChoice: false,
      newMessageMultipleSelection: false,
      singleChoiceVisibility: ' hide',
      multipleOptionVisibility: ' hide',

      messageOptionsError: ' hide',

      messageTypeError: ' hide',
      messageBroadcast: true,

      messageDeliverTo: ' All ' + AuthStore.getPatientLabelPlural(),
      messageDeliveryAt: ' ',

      canSendAllPatients: '',

      newMessageCategory: null,
      messageTopicError: ' hide',

      messageDeliveryDateError: ' hide',
      messageEndDateError: ' hide',

      selectedMessagePatientsIDs: null,
      selectPatientsVisibility: ' hide',

      singleChoiceVisibility: ' hide',
      multipleOptionVisibility: ' hide',

      repeatConfigVisibility: ' hide',
      repeatWeekVisibility: ' hide',

      messageRepeat: false,
      messageRepeatStyle: '',
      messageRepeatEveryError: ' hide',

      stopRecurringMessageVisibility: ' hide',
      stopRecurringMessageStyle: ' nucleus-submit-btn-small ',

      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',
    });

    $('.chips-initial').material_chip({
      data: [],
      placeholder: 'Type a name',
      secondaryPlaceholder: 'Type another name',
      autocompleteOptions: {
        data: this.state.messageAutocompleteDataList,
        limit: Infinity,
      },
    });

    this.refs.checkSunday.checked = false;
    this.refs.checkMonday.checked = false;
    this.refs.checkTuesday.checked = false;
    this.refs.checkWednesday.checked = false;
    this.refs.checkThursday.checked = false;
    this.refs.checkFriday.checked = false;
    this.refs.checkSaturday.checked = false;

    this.refs.checkEnds.checked = false;

    this.refs.txtSingleOption1.value = '';
    this.refs.txtSingleOption2.value = '';
    this.refs.txtSingleOption3.value = '';
    this.refs.txtSingleOption4.value = '';
    this.refs.txtSingleOption5.value = '';
    this.refs.txtSingleOption6.value = '';

    this.refs.txtMultiOption1.value = '';
    this.refs.txtMultiOption2.value = '';
    this.refs.txtMultiOption3.value = '';
    this.refs.txtMultiOption4.value = '';
    this.refs.txtMultiOption5.value = '';
    this.refs.txtMultiOption6.value = '';

    this.refs.textMessage.value = '';

    let currentTime = new Date();
    currentTime = currentTime.getTime() + 5 * 60000;
    currentTime = new Date(currentTime);

    // console.log("1>> ", currentTime.toLocaleDateString('en-US', this.allOptions));
    // console.log("2>> ", currentTime.toLocaleDateString('en-US', this.dateOptions));
    // console.log("3>> ", currentTime.toLocaleTimeString('en-US', this.timeOptions));

    this.refs.messageDateTime.value = currentTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentTime.toLocaleTimeString('en-US', this.timeOptions);
    this.refs.messageEndDateTime.value = currentTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentTime.toLocaleTimeString('en-US', this.timeOptions);
  }

  // Reset Modal UPDATE Message
  // DEPRECATED
  // onGetMessageDetailsAction(messageData){
  // }

  handleCloseNewMessageModal() {
    $('#modalNewMessage').modal('close');
  }

  handleMessageModalMode() {
    console.log('handleMessageModalMode');

    if (this.state.messageModalOptionLabel == 'Delete') {
      this.setState({
        messageModalOptionLabel: 'Cancel',
        messageModeEdit: 'hide',
        messageModeDelete: '',
        messageModalOptionColor: '',
      });
    } else {
      this.setState({
        messageModalOptionLabel: 'Delete',
        messageModeEdit: '',
        messageModeDelete: 'hide',
        messageModalOptionColor: 'txt-red',
      });
    }
  }

  handleSelectMessageType() {
    if (this.refs.selectMessageType.value == '1') {
      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' hide',
        messageOptionsError: ' hide',

        messageRepeatStyle: '',

        messageTypeError: ' hide',

        canSendAllPatients: '',
      });
    } else if (this.refs.selectMessageType.value == '2') {
      this.setState({
        newMessageSingleChoice: true,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' ',
        multipleOptionVisibility: ' hide',
        messageOptionsError: ' hide',

        messageRepeatStyle: 'disabled',
        messageTypeError: ' hide',

        canSendAllPatients: 'disabled',
        messageBroadcast: true,

        selectedMessagePatientsIDs: null,
        selectPatientsVisibility: ' hide',
      });

      this.refs.txtSingleOption1.value = '';
      this.refs.txtSingleOption2.value = '';
      this.refs.txtSingleOption3.value = '';
      this.refs.txtSingleOption4.value = '';
      this.refs.txtSingleOption5.value = '';
      this.refs.txtSingleOption6.value = '';

      this.refs.checkMessageAllPatients.checked = true;
    } else if (this.refs.selectMessageType.value == '3') {
      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: true,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' ',
        messageOptionsError: ' hide',

        messageRepeatStyle: 'disabled',
        messageTypeError: ' hide',
        canSendAllPatients: 'disabled',
        messageBroadcast: true,

        selectedMessagePatientsIDs: null,
        selectPatientsVisibility: ' hide',
      });
      this.refs.txtMultiOption1.value = '';
      this.refs.txtMultiOption2.value = '';
      this.refs.txtMultiOption3.value = '';
      this.refs.txtMultiOption4.value = '';
      this.refs.txtMultiOption5.value = '';
      this.refs.txtMultiOption6.value = '';

      this.refs.checkMessageAllPatients.checked = true;
    }
  }

  handleResetOptionsValidationError() {
    if (this.state.messageOptionsError == ' ') {
      this.setState({
        messageOptionsError: ' hide',
      });
    }
  }

  handleSelectMessageCategory() {
    this.setState({
      newMessageCategory: this.refs.selectMessageCategory.value,
      messageTopicError: ' hide',
    });
  }

  handleMessageBroadcast(e) {
    const setToAllPatients = e.target.checked;

    this.setState({
      messageBroadcast: setToAllPatients,
    });

    if (setToAllPatients) {
      this.setState({
        selectedMessagePatientsIDs: null,
        selectPatientsVisibility: ' hide',
      });
    } else {
      this.setState({
        selectedMessagePatientsIDs: [],
        selectPatientsVisibility: ' ',
      });
      $('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: this.state.messageAutocompleteDataList,
          limit: Infinity,
        },
      });
    }
  }

  handleMessageRepeat(e) {
    const repeat = e.target.checked;

    this.setState({
      messageRepeat: repeat,
      repeatConfigVisibility: repeat ? '' : ' hide',
    });
  }

  handleStopRecurringMessageModal() {
    $('#modalStopRecurringMessage').modal('open');
  }

  handleCancelStopRecurringMessageModal() {
    $('#modalStopRecurringMessage').modal('close');
  }

  handleStopRecurringMessage() {
    MessagesStore.stopRecurringMessage({
      MessageID: this.state.selectedMessageID,
      stop: true,
    });
    $('#modalStopRecurringMessage').modal('close');
  }

  handleTxtRepeatEvery() {
    this.setState({
      messageRepeatEveryError: ' hide',
    });
  }

  handleSelectMessageRecurring() {
    if (this.refs.selectMessageRecurring.value == 'Week') {
      this.setState({
        repeatWeekVisibility: '',
      });
    } else {
      this.setState({
        repeatWeekVisibility: ' hide',
      });
    }
  }

  handleMessageEnds(e) {
    const repeatEnds = e.target.checked;

    this.setState({
      messageRepeatEnds: repeatEnds,
      messageRepeatEndsDateSection: repeatEnds ? '' : ' hide',
    });
  }

  handleMessageText() {
    this.setState({
      messageBodyError: ' hide',
      messageBodyLengthError: ' hide',
    });
  }

  // SAVE NEW MESSAGE
  handleCancelResumeMessageModal() {
    $('#modalResumeSaveMessage').modal('close');
  }

  handleResumeSaveMessage() {
    if (this.refs.selectMessageType.value == '') {
      this.setState({
        messageTypeError: ' ',
      });
      return;
    }

    if (this.state.newMessageCategory == null) {
      this.setState({
        messageTopicError: ' ',
      });
      return;
    }

    if (new Date(this.refs.messageDateTime.value) < new Date()) {
      this.setState({
        messageDeliveryDateError: ' ',
      });
      return;
    }

    if (!(this.refs.textMessage.value.length > 0)) {
      this.setState({
        messageBodyError: ' ',
      });
      return;
    }

    if (this.refs.textMessage.value.length > 140) {
      this.setState({
        messageBodyLengthError: ' ',
      });
      return;
    }

    if (this.refs.selectMessageType.value == '2') {
      let optionsIncluded = false;
      if (this.refs.txtSingleOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        return;
      }
    }
    if (this.refs.selectMessageType.value == '3') {
      let optionsIncluded = false;
      if (this.refs.txtMultiOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        return;
      }
    }

    let namesArray = ' All ' + AuthStore.getPatientLabelPlural();
    if (this.state.selectedMessagePatientsIDs != null) {
      const chipsObjectValue = $('.chips-initial').material_chip('data');

      namesArray = '';
      chipsObjectValue.forEach((value, key) => {
        //console.log("Seleccionado", value);
        //console.log("SeleccionadoDE", this.state.messagePatientsList);

        if (namesArray.length > 0) {
          namesArray = namesArray + ', ';
        }
        namesArray = namesArray + value.tag;
        //console.log("The PatientID es: ", patientID);
      });
    }
    this.setState({
      messageDeliverTo: namesArray,
      messageDeliveryAt: this.refs.messageDateTime.value,
    });

    $('#modalResumeSaveMessage').modal('open');
  }

  handleSaveMessage() {
    alert('Continue');

    if (this.refs.selectMessageType.value == '') {
      this.setState({
        messageTypeError: ' ',
      });
      return;
    }

    if (this.state.newMessageCategory == null) {
      this.setState({
        messageTopicError: ' ',
      });
      return;
    }

    if (new Date(this.refs.messageDateTime.value) < new Date()) {
      this.setState({
        messageDeliveryDateError: ' ',
      });
      return;
    }

    if (!(this.refs.textMessage.value.length > 0)) {
      this.setState({
        messageBodyError: ' ',
      });
      return;
    }

    if (this.refs.textMessage.value.length > 140) {
      this.setState({
        messageBodyLengthError: ' ',
      });
      return;
    }

    let IDsArray = null;
    if (this.state.selectedMessagePatientsIDs != null) {
      const chipsObjectValue = $('.chips-initial').material_chip('data');

      console.log(chipsObjectValue);

      IDsArray = '';
      chipsObjectValue.forEach((value, key) => {
        //console.log("Seleccionado", value);
        //console.log("SeleccionadoDE", this.state.messagePatientsList);

        let patientID = null;
        patientID = this.state.messagePatientsList[value.tag];
        //IDsArray.push(patientID);
        if (IDsArray.length > 0) {
          IDsArray = IDsArray + ',';
        }
        IDsArray = IDsArray + patientID;
        //console.log("The PatientID es: ", patientID);
      });
      console.log('Los Patients IDS son', IDsArray);
      this.setState({
        selectedMessagePatientsIDs: IDsArray,
      });
    }

    let messageOptionsArray = null;
    if (this.refs.selectMessageType.value == '2') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption1.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption2.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption3.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption4.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption5.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption6.value;
    } else if (this.refs.selectMessageType.value == '3') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption1.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption2.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption3.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption4.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption5.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption6.value;
    }
    console.log('Les Options son', messageOptionsArray);

    let repeatMessage = this.state.messageRepeat;
    if (this.state.messageRepeatStyle == 'disabled') {
      repeatMessage = false;
    }

    console.log('Message Repeat: ', repeatMessage);
    console.log('Repeat Every: ', this.refs.txtRepeatNumber.value, ' ', this.refs.selectMessageRecurring.value);

    if (repeatMessage && !(this.refs.txtRepeatNumber.value.length > 0)) {
      this.setState({
        messageRepeatEveryError: ' ',
      });
      $('#modalResumeSaveMessage').modal('close');
      return;
    }

    console.log(this.refs.checkSunday.checked);
    console.log(this.refs.checkMonday.checked);
    console.log(this.refs.checkTuesday.checked);
    console.log(this.refs.checkWednesday.checked);
    console.log(this.refs.checkThursday.checked);
    console.log(this.refs.checkFriday.checked);
    console.log(this.refs.checkSaturday.checked);

    console.log(this.state.messageRepeatEnds);

    let endRepeatDate = '';
    if (this.state.messageRepeatEnds) {
      endRepeatDate = this.refs.messageEndDateTime.value;

      if (new Date(this.refs.messageEndDateTime.value) < new Date(this.refs.messageDateTime.value)) {
        this.setState({
          messageEndDateError: ' ',
        });
        $('#modalResumeSaveMessage').modal('close');
        return;
      }
    }

    console.log('Repeat Ends :', this.state.messageRepeatEnds);
    console.log('Repeat Ends ON:', endRepeatDate);

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn-disabled ',
    });

    $('#modalResumeSaveMessage').modal('close');

    MessagesStore.saveMessageByUser({
      AccountID: AuthStore.getUserAccountID(),
      UserID: AuthStore.getUserID(),
      Message: this.refs.textMessage.value,
      SendToAll: this.state.messageBroadcast,
      DeliveryTime: this.refs.messageDateTime.value,
      MessageCategoryID: this.state.newMessageCategory,
      isRecurring: repeatMessage,
      RepeatEvery: this.refs.txtRepeatNumber.value,
      RepeatType: this.refs.selectMessageRecurring.value,
      RepeatOnSunday: this.refs.checkSunday.checked,
      RepeatOnMonday: this.refs.checkMonday.checked,
      RepeatOnTuesday: this.refs.checkTuesday.checked,
      RepeatOnWednesday: this.refs.checkWednesday.checked,
      RepeatOnThursday: this.refs.checkThursday.checked,
      RepeatOnFriday: this.refs.checkFriday.checked,
      RepeatOnSaturday: this.refs.checkSaturday.checked,
      EndDate: endRepeatDate,
      IsSingleOptionMessage: this.state.newMessageSingleChoice,
      IsMultipleOptionMessage: this.state.newMessageMultipleSelection,
      MessageOptionsTexts: messageOptionsArray,
      PatientsIDs: IDsArray,
      MessageOptionsTemplateID: '',
    });
  }

  handleUpdateMessage() {
    if (this.refs.selectMessageType.value == '') {
      this.setState({
        messageTypeError: ' ',
      });
      return;
    }

    if (this.state.newMessageCategory == null) {
      this.setState({
        messageTopicError: ' ',
      });
      return;
    }

    if (new Date(this.refs.messageDateTime.value) < new Date()) {
      this.setState({
        messageDeliveryDateError: ' ',
      });
      return;
    }

    if (!(this.refs.textMessage.value.length > 0)) {
      this.setState({
        messageBodyError: ' ',
      });
      return;
    }

    if (this.refs.textMessage.value.length > 140) {
      this.setState({
        messageBodyLengthError: ' ',
      });
      return;
    }

    if (this.refs.selectMessageType.value == '2') {
      let optionsIncluded = false;
      if (this.refs.txtSingleOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtSingleOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        return;
      }
    }
    if (this.refs.selectMessageType.value == '3') {
      let optionsIncluded = false;
      if (this.refs.txtMultiOption1.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption2.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption3.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption4.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption5.value.length > 0) {
        optionsIncluded = true;
      } else if (this.refs.txtMultiOption6.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        return;
      }
    }

    let IDsArray = null;
    if (this.state.selectedMessagePatientsIDs != null) {
      const chipsObjectValue = $('.chips-initial').material_chip('data');

      console.log(chipsObjectValue);

      IDsArray = '';
      chipsObjectValue.forEach((value, key) => {
        //console.log("Seleccionado", value);
        //console.log("SeleccionadoDE", this.state.messagePatientsList);

        let patientID = null;
        patientID = this.state.messagePatientsList[value.tag];
        //IDsArray.push(patientID);
        if (IDsArray.length > 0) {
          IDsArray = IDsArray + ',';
        }
        IDsArray = IDsArray + patientID;
        //console.log("The PatientID es: ", patientID);
      });
      console.log('Los Patients IDS son', IDsArray);
      this.setState({
        selectedMessagePatientsIDs: IDsArray,
      });
    } else {
      console.log('All patients', IDsArray);
    }

    let messageOptionsArray = null;
    if (this.refs.selectMessageType.value == '2') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption1.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption2.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption3.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption4.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption5.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtSingleOption6.value;
    } else if (this.refs.selectMessageType.value == '3') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption1.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption2.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption3.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption4.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption5.value + ',';
      messageOptionsArray = messageOptionsArray + this.refs.txtMultiOption6.value;
    }
    console.log('Las Options son', messageOptionsArray);

    let repeatMessage = this.state.messageRepeat;
    if (this.state.messageRepeatStyle == 'disabled') {
      repeatMessage = false;
    }

    console.log('Message Repeat: ', repeatMessage);
    console.log('Repeat Every: ', this.refs.txtRepeatNumber.value, ' ', this.refs.selectMessageRecurring.value);

    if (repeatMessage && !(this.refs.txtRepeatNumber.value.length > 0)) {
      this.setState({
        messageRepeatEveryError: ' ',
      });
      return;
    }

    console.log(this.refs.checkSunday.checked);
    console.log(this.refs.checkMonday.checked);
    console.log(this.refs.checkTuesday.checked);
    console.log(this.refs.checkWednesday.checked);
    console.log(this.refs.checkThursday.checked);
    console.log(this.refs.checkFriday.checked);
    console.log(this.refs.checkSaturday.checked);

    console.log(this.state.messageRepeatEnds);

    let endRepeatDate = '';
    if (this.state.messageRepeatEnds) {
      endRepeatDate = this.refs.messageEndDateTime.value;

      if (new Date(this.refs.messageEndDateTime.value) < new Date(this.refs.messageDateTime.value)) {
        this.setState({
          messageEndDateError: ' ',
        });
        return;
      }
    }

    console.log('Repeat Ends :', this.state.messageRepeatEnds);
    console.log('Repeat Ends ON:', endRepeatDate);

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn-disabled ',
    });

    MessagesStore.updateMessageByUser({
      MessageID: this.state.selectedMessageID,
      Message: this.refs.textMessage.value,
      SendToAll: this.state.messageBroadcast,
      DeliveryTime: this.refs.messageDateTime.value,
      MessageCategoryID: this.state.newMessageCategory,
      isRecurring: repeatMessage,
      RepeatEvery: this.refs.txtRepeatNumber.value,
      RepeatType: this.refs.selectMessageRecurring.value,
      RepeatOnSunday: this.refs.checkSunday.checked,
      RepeatOnMonday: this.refs.checkMonday.checked,
      RepeatOnTuesday: this.refs.checkTuesday.checked,
      RepeatOnWednesday: this.refs.checkWednesday.checked,
      RepeatOnThursday: this.refs.checkThursday.checked,
      RepeatOnFriday: this.refs.checkFriday.checked,
      RepeatOnSaturday: this.refs.checkSaturday.checked,
      EndDate: endRepeatDate,
      IsSingleOptionMessage: this.state.newMessageSingleChoice,
      IsMultipleOptionMessage: this.state.newMessageMultipleSelection,
      MessageOptionsTexts: messageOptionsArray,
      PatientsIDs: IDsArray,
      MessageOptionsTemplateID: '',
    });
  }

  onSaveMessageByUserAction(response) {
    console.log('onSaveMessageByUserAction', response);

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn ',
    });

    if (response.ok) {
      Message.show('Message saved');
      $('#modalNewMessage').modal('close');

      this.setState({
        loading: true,
      });
      //MessagesStore.getMessagesForAccountUpdated({
      //    AccountID : AuthStore.getUserAccountID(),
      //    FromDateTime : this.refs.fromDateTime.value,
      //    ToDateTime : this.refs.toDateTime.value
      //});
      this.onRefreshMessagesCenterAction();
    } else {
      Message.show('Error saving message');
    }
  }

  onStopRecurringMessageAction(response) {
    console.log('onStopRecurringMessageAction', response);

    if (response.ok) {
      Message.show('Recurring message stopped');
      $('#modalNewMessage').modal('close');
    } else {
      Message.show('Error stopping recurring message');
    }
  }

  handleSubmitDeleteMessage() {
    this.setState({
      deleteMessageBtnStyle: ' nucleus-submit-btn-disabled',
    });

    MessagesStore.deleteMessage({
      MessageID: this.state.selectedMessageID,
    });
  }

  onGetMessageReportStarted() {
    $('#modalMessageLoadingResults').modal('open');
  }

  onGetMessageReportAction(response) {
    console.log('onGetMessageReportAction', response);
    this.setState({
      messageReportBody: '',
      reportLoading: false,
    });

    if (response.ok) {
      //let messageReportSorted = response.dataProvider.slice(0);
      //messageReportSorted.sort((a, b) => (Number(b.Count)) - (Number(a.Count)));

      //console.log("El MERO ARRAY SORTED ", messageReportSorted);

      // if (messageReportSorted.length > 0) {
      // 	let opt1Txt = messageReportSorted[0].OptionText;
      // 	let opt1Val = messageReportSorted[0].Count;
      // 	let opt2Txt = messageReportSorted[1].OptionText;
      // 	let opt2Val = messageReportSorted[1].Count;
      // 	let opt3Txt = messageReportSorted[2].OptionText;
      // 	let opt3Val = messageReportSorted[2].Count;
      // 	let opt4Txt = messageReportSorted[3].OptionText;
      // 	let opt4Val = messageReportSorted[3].Count;
      // 	let opt5Txt = messageReportSorted[4].OptionText;
      // 	let opt5Val = messageReportSorted[4].Count;
      // 	let opt6Txt = messageReportSorted[5].OptionText;
      // 	let opt6Val = messageReportSorted[5].Count;
      //  DEPRECATED: Feb 5th 2019, CARE-4077
      // 	this.createMessageReportChart(opt1Txt, opt1Val, opt2Txt, opt2Val, opt3Txt, opt3Val, opt4Txt, opt4Val, opt5Txt, opt5Val, opt6Txt, opt6Val);

      // }

      let sorteredArray1 = [].concat(response.messageDeliveries);
      let sorteredArray2 = [].concat(response.messageDeliveries);

      let sorteredArray3 = [].concat(response.messageDeliveries);
      let sorteredArray4 = [].concat(response.messageDeliveries);

      sorteredArray1 = sorteredArray1.sort((a, b) => new Date(b.DeliveryTime) - new Date(a.DeliveryTime));
      sorteredArray2 = sorteredArray2.sort((a, b) => new Date(a.DeliveryTime) - new Date(b.DeliveryTime));

      sorteredArray3 = sorteredArray3.sort((a, b) => {
        const textA = a.Patient.toLowerCase();
        const textB = b.Patient.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });
      sorteredArray4 = sorteredArray4.sort((a, b) => {
        const textA = a.Patient.toLowerCase();
        const textB = b.Patient.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      const fromDate = new Date(response.messageFrom);
      const toDate = new Date(response.messageTo);

      let reportIncludesSignature = false;
      if (sorteredArray1 && sorteredArray1.length) {
        sorteredArray1.forEach(delivery => {
          if (delivery.MessageSignature) {
            reportIncludesSignature = true;
            return;
          }
        });
      }

      this.setState({
        messageReportID: response.MessageID,
        messageReport: sorteredArray1,

        messageReportIncludesSignature: reportIncludesSignature,

        messageReportBody: response.messageBody,
        messageReportSender: response.messageSender,
        messageReportRecurring: response.messageRecurring,

        messageReportDeliveryDesc: sorteredArray1,
        messageReportDeliveryAsc: sorteredArray2,
        messageReportPatientDesc: sorteredArray3,
        messageReportPatientDesc: sorteredArray4,

        sortReportDeliveryDesc: ' nucleus-icon-active hide',
        sortReportDeliveryAsc: ' nucleus-icon-active ',

        sortReportPatientDesc: ' nucleus-icon-inactive ',
        sortReportPatientAsc: ' nucleus-icon-inactive hide ',

        messageReportFromDateTime: new Date(response.messageFrom),
        messageReportToDateTime: new Date(response.messageTo),

        reportFromTime: response.messageFrom, //.replace(/:\d{2}\s/,' '),
        reportToTime: response.messageTo, //.replace(/:\d{2}\s/,' '),
      });

      // fromDate.setHours(0);
      // fromDate.setMinutes(0);
      // fromDate.setSeconds(0);

      // toDate.setHours(23);
      // toDate.setMinutes(59);
      // toDate.setSeconds(0);

      //fromDate.setHours(fromDate.getHours() + this.controlTimezone);
      //toDate.setHours(toDate.getHours() + this.controlTimezone);

      console.log('Messages.js', 'fromDate ' + fromDate);
      console.log('Messages.js', 'toDate ' + toDate);
      // this.refs.messageReportFromDateTime.value  = fromDate.toLocaleDateString('en-US', this.dateOptions); // + " " + fromDate.toLocaleTimeString('en-US', this.timeOptions);
      // this.refs.messageReportToDateTime.value  = toDate.toLocaleDateString('en-US', this.dateOptions); // + " " + toDate.toLocaleTimeString('en-US', this.timeOptions);
      $('#modalMessageLoadingResults').modal('close');
      $('#modalMessageResults').modal('open');
    } else {
      Message.show('Error getting Message Report');
    }
  }

  /*
	createMessageReportChart(opt1Txt, opt1Val, opt2Txt, opt2Val, opt3Txt, opt3Val, opt4Txt, opt4Val, opt5Txt, opt5Val, opt6Txt, opt6Val){
		console.log("createMessageReportChart", opt1Txt, opt1Val, opt2Txt, opt2Val, opt3Txt, opt3Val, opt4Txt, opt4Val, opt5Txt, opt5Val, opt6Txt, opt6Val);
		var chart = AmCharts.makeChart( "chartMessageResults", {
			"type": "pie",
			"theme": "light",
			"dataProvider": [ {
				"option": opt1Txt,
				"count": opt1Val
			}, {
				"option": opt2Txt,
				"count": opt2Val
			}, {
				"option": opt3Txt,
				"count": opt3Val
			}, {
				"option": opt4Txt,
				"count": opt4Val
			}, {
				"option": opt5Txt,
				"count": opt5Val
			}, {
				"option": opt6Txt,
				"count": opt6Val
			}],
			"valueField": "count",
			"titleField": "option",
			 "balloon":{
			 "fixedPosition":true
			},
			"export": {
				"enabled": true
			}
	} );
	}
	*/

  handleCloseMessageReportsModal() {
    $('#modalMessageResults').modal('close');
  }

  handleUpdateMessageReport() {
    const fromDate = new Date(this.state.messageReportFromDateTime);
    const toDate = new Date(this.state.messageReportToDateTime);

    fromDate.setHours(0);
    fromDate.setMinutes(0);
    fromDate.setSeconds(0);

    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate.setSeconds(0);

    //fromDate.setHours(fromDate.getHours() + (this.controlTimezone * -1));
    //toDate.setHours(toDate.getHours() + (this.controlTimezone * -1));

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

    console.log('Offset', this.controlTimezone * -1);
    console.log('PARAM 1', fromDateParam);
    console.log('PARAM 2', toDateParam);
    console.log('MessageID', this.state.messageReportID);

    MessagesStore.getMessageReport({
      MessageID: this.state.messageReportID,
      FromDateTime: fromDateParam,
      ToDateTime: toDateParam,
    });

    this.setState({
      reportLoading: true,
      reportFromTime: fromDateParam,
      reportToTime: toDateParam,
    });
  }

  onGetMessageReportUpdatedAction(response) {
    console.log('onGetMessageReportUpdatedAction', response);

    this.setState({
      reportLoading: false,
    });

    if (response.ok) {
      let sorteredArray1 = [].concat(response.messageDeliveries);
      let sorteredArray2 = [].concat(response.messageDeliveries);

      let sorteredArray3 = [].concat(response.messageDeliveries);
      let sorteredArray4 = [].concat(response.messageDeliveries);

      sorteredArray1 = sorteredArray1.sort((a, b) => new Date(b.DeliveryTime) - new Date(a.DeliveryTime));
      sorteredArray2 = sorteredArray2.sort((a, b) => new Date(a.DeliveryTime) - new Date(b.DeliveryTime));

      sorteredArray3 = sorteredArray3.sort((a, b) => {
        const textA = a.Patient.toLowerCase();
        const textB = b.Patient.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });
      sorteredArray4 = sorteredArray4.sort((a, b) => {
        const textA = a.Patient.toLowerCase();
        const textB = b.Patient.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      let reportIncludesSignature = false;
      if (sorteredArray1 && sorteredArray1.length) {
        sorteredArray1.forEach(delivery => {
          if (delivery.MessageSignature) {
            reportIncludesSignature = true;
            return;
          }
        });
      }

      this.setState({
        messageReportID: response.MessageID,
        messageReport: sorteredArray1,
        messageReportIncludesSignature: reportIncludesSignature,

        messageReportBody: response.message,
        messageReportSender: response.messageSender,
        messageReportRecurring: response.messageRecurring,

        messageReportDeliveryDesc: sorteredArray1,
        messageReportDeliveryAsc: sorteredArray2,
        messageReportPatientDesc: sorteredArray3,
        messageReportPatientAsc: sorteredArray4,

        sortReportDeliveryDesc: ' nucleus-icon-active hide',
        sortReportDeliveryAsc: ' nucleus-icon-active ',

        sortReportPatientDesc: ' nucleus-icon-inactive ',
        sortReportPatientAsc: ' nucleus-icon-inactive hide ',
      });
    } else {
      Message.show('Error getting Message Report');
    }
  }

  handleSortReportDeliveryDesc() {
    console.log('handleSortReportDeliveryDesc');
    this.state.messageReport = [];
    this.setState({
      sortReportDeliveryDesc: ' nucleus-icon-active hide',
      sortReportDeliveryAsc: ' nucleus-icon-active ',

      sortReportPatientDesc: ' nucleus-icon-inactive ',
      sortReportPatientAsc: ' nucleus-icon-inactive hide ',

      messageReport: this.state.messageReportDeliveryDesc,
    });
  }

  handleSortReportDeliveryAsc() {
    console.log('handleSortReportDeliveryAsc');
    this.state.messageReport = [];
    this.setState({
      sortReportDeliveryDesc: ' nucleus-icon-active ',
      sortReportDeliveryAsc: ' nucleus-icon-active hide',

      sortReportPatientDesc: ' nucleus-icon-inactive ',
      sortReportPatientAsc: ' nucleus-icon-inactive hide ',

      messageReport: this.state.messageReportDeliveryAsc,
    });
  }

  handleSortReportPatientDesc() {
    console.log('handleSortReportPatientDesc');
    this.state.messageReport = [];
    this.setState({
      sortReportDeliveryDesc: ' nucleus-icon-inactive hide',
      sortReportDeliveryAsc: ' nucleus-icon-inactive  ',

      sortReportPatientDesc: ' nucleus-icon-active hide',
      sortReportPatientAsc: ' nucleus-icon-active ',

      messageReport: this.state.messageReportPatientDesc,
    });
  }

  handleSortReportPatientAsc() {
    console.log('handleSortReportPatientAsc');
    this.state.messageReport = [];
    this.setState({
      sortReportDeliveryDesc: ' nucleus-icon-inactive hide',
      sortReportDeliveryAsc: ' nucleus-icon-inactive  ',

      sortReportPatientDesc: ' nucleus-icon-active ',
      sortReportPatientAsc: ' nucleus-icon-active hide',

      messageReport: this.state.messageReportPatientAsc,
    });
  }
  /*
	handleSortInit(){
		console.log("handleSortInit");
		
		let unsortedArray = MessagesStore.getMessages();
		let sorteredArrayAsc = [];
		//console.log("Ordenando", unsortedArray);

		//ASC
		sorteredArrayAsc= unsortedArray.slice(0).sort((a, b) => (new Date(a.CreateDate)) - (new Date(b.CreateDate)));

		//DESC 
		//sorteredArrayDesc= unsortedArray.sort((a, b) => new Date(b.CreateDate) - new Date(a.CreateDate));
		
		//console.log("OrdenaDO", sorteredArray);
		this.setState({
			messagesArrayAsc : sorteredArrayAsc
		});
	}
  

	handleSortByDateDesc(){

		this.setState({
			messagesArray : this.state.messagesArrayAsc,
			sortLastDescending : ' hide',
				sortLastAscending : ' ',
			udpateDate: new Date()
		});
	}

	handleSortByDateAsc(){

		this.setState({
			messagesArray : this.state.messagesArrayDesc,
			sortLastDescending : ' ',
				sortLastAscending : ' hide',
			udpateDate: new Date()
		});
	}
	*/

  handleSortRecurringDesc() {
    console.log('handleSortRecurringDesc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive hide ',
      sortRecurringAsc: ' nucleus-icon-active ',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray1,
    });
  }

  handleSortRecurringAsc() {
    console.log('handleSortRecurringAsc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-active ',
      sortRecurringAsc: ' nucleus-icon-inactive hide ',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray2,
    });
  }

  handleSortLastSentDesc() {
    console.log('handleSortLastSentDesc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-inactive hide ',
      sortLastSentAsc: ' nucleus-icon-active ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray3,
    });
  }

  handleSortLastSentAsc() {
    console.log('handleSortLastSentAsc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-active ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray4,
    });
  }

  handleSortNextDeliveryDesc() {
    console.log('handleSortNextDeliveryDesc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive hide ',
      sortNextDeliveryAsc: ' nucleus-icon-active ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray5,
    });
  }

  handleSortNextDeliveryAsc() {
    console.log('handleSortNextDeliveryAsc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-active ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray6,
    });
  }

  handleSortSenderDesc() {
    console.log('handleSortSenderDesc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive hide',
      sortSenderAsc: ' nucleus-icon-active ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray7,
    });
  }

  handleSortSenderAsc() {
    console.log('handleSortSenderAsc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-active ',
      sortSenderAsc: ' nucleus-icon-inactive hide ',

      sortRecipientDesc: ' nucleus-icon-inactive ',
      sortRecipientAsc: ' nucleus-icon-inactive hide ',

      messagesArray: this.sorteredArray8,
    });
  }

  handleSortRecipientDesc() {
    console.log('handleSortRecipientDesc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive hide',
      sortSenderAsc: ' nucleus-icon-inactive ',

      sortRecipientDesc: ' nucleus-icon-inactive hide',
      sortRecipientAsc: ' nucleus-icon-active ',

      messagesArray: this.sorteredArray9,
    });
  }

  handleSortRecipientAsc() {
    console.log('handleSortRecipientAsc');
    this.state.messagesArray = [];
    this.setState({
      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide',

      sortLastSentDesc: ' nucleus-icon-inactive ',
      sortLastSentAsc: ' nucleus-icon-inactive hide ',

      sortNextDeliveryDesc: ' nucleus-icon-inactive ',
      sortNextDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortSenderDesc: ' nucleus-icon-inactive ',
      sortSenderAsc: ' nucleus-icon-inactive hide',

      sortRecipientDesc: ' nucleus-icon-active ',
      sortRecipientAsc: ' nucleus-icon-inactive hide',

      messagesArray: this.sorteredArray10,
    });
  }

  generatePdfReport() {
    console.log('generatePdfReport');
  }

  render() {
    let tableResultsView = ' hide';
    let tableNoResultsView = ' hide';
    let tableLoadingView = '';

    let filtersBoxesMode = '';
    let filterBtnMode = 'nucleus-submit-btn-small';
    if (this.state.loading) {
      tableResultsView = ' hide';
      tableNoResultsView = ' hide';
      tableLoadingView = '';

      filtersBoxesMode = 'disabled';
      filterBtnMode = 'nucleus-submit-btn-small-disabled';
    } else {
      if (this.state.messagesArray.length > 0) {
        tableResultsView = ' ';
        tableNoResultsView = ' hide';
      } else {
        tableResultsView = ' hide';
        tableNoResultsView = ' ';
      }
      filtersBoxesMode = '';
      filterBtnMode = 'nucleus-submit-btn-small';
    }

    const messagesList = [];

    if (this.state.messagesArray != null) {
      this.state.messagesArray.map((message, key) => {
        console.log('message', message);

        messagesList.push(
          <MessageItemRow2
            key={key}
            messageID={message.MessageID}
            messageReccuring={message.Reccuring}
            messageLastSent={message.LastSent}
            messageNextDelivery={message.NextDelivery}
            messageSender={message.Sender}
            messageRecipients={message.Recipients}
            messageText={message.Message}
            messageLastSentString={message.LastSentString}
            messageNextDeliveryString={message.NextDeliveryString}
            /*
					userOffset={this.controlTimezone}

					messageLSTimezone={message.LastSentTimezoneString}
					messageNDTimezone={message.NextDeliveryTimezoneString}
					*/
          />,
        );
      });
    }

    const messageReportRows = [];
    let messageReportContent = ' hide';
    let messageReportNoContent = ' ';
    let messageReportLoadingContent = ' hide';

    let filterReportBtnMode = 'nucleus-submit-btn-small';
    let dateReportFilters = '';

    if (this.state.reportLoading) {
      messageReportContent = ' hide';
      messageReportNoContent = ' hide';
      messageReportLoadingContent = ' ';

      filterReportBtnMode = 'nucleus-submit-btn-small-disabled';
      dateReportFilters = 'disabled';
    } else {
      messageReportLoadingContent = ' hide';
      filterReportBtnMode = 'nucleus-submit-btn-small';

      if (this.state.messageReport != null) {
        this.state.messageReport.map((messageOption, key) => {
          messageReportContent = ' ';
          messageReportNoContent = ' hide';

          messageReportRows.push(
            <tr key={key}>
              <td className="">{messageOption.DeliveryTime /* .replace(/:\d{2}\s/,' ')  */}</td>
              <td className="">{messageOption.Patient}</td>
              <td className="">{''}</td>
              <td className="">{messageOption.Response}</td>
              {this.state.messageReportIncludesSignature && (
                <td className="">{messageOption.MessageSignature && <img src={messageOption.MessageSignature} style={{ width: 120 }}></img>}</td>
              )}
            </tr>,
          );
        });
      }
    }

    const disabledFont = '';
    //disabledFont = 'placeholder-gray';

    const level0 = '';
    //level0 = 'disabled';

    const messageCategoriesList = [];
    if (this.state.messageCategories != null && this.state.messageCategories.length) {
      this.state.messageCategories.map((category, key) => {
        if (category.Category != 'Biometrics') {
          messageCategoriesList.push(
            <option key={category.ID} value={category.ID}>
              {category.Category}
            </option>,
          );
        }
      });
    }

    if (this.state.filterTimezonesArray != null && this.state.filterTimezonesArray.length && this.messageFilterTimezonesArray.length == 0) {
      this.state.filterTimezonesArray.map((timezone, key) => {
        this.messageFilterTimezonesArray.push(
          <option key={key} value={timezone.value}>
            {timezone.name}
          </option>,
        );
      });
    }

    console.log('MESSAGES RENDER');
    //console.log("MESSAGES RENDER E_BIO", AuthStore.getAccountBiometricsEnabled());

    return (
      <div className="nucleus-hard-container border2">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">Messages</p>

          <div className="row filters-row">
            <span className="nucleus-labels">
              <b> &nbsp;From</b>
            </span>{' '}
            &nbsp;
            {/* <input 
						id="fromDateTime" 
						ref="fromDateTime" 
						type="text" 
						className="validate nucleus-date-picker-short" 
						autoComplete="off" 
						disabled={filtersBoxesMode}/> */}
            <div style={{ width: 210, display: 'inline-flex' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  ref={this.fromDateTimeRef}
                  variant="dialog"
                  className="browser-default"
                  value={this.state.fromDateTime}
                  onChange={this.onFromDateChange}
                  onError={console.log}
                  onClose={() => {}}
                  //minDate={new Date("2018-01-01T00:00")}
                  format={this.datetimeFormat}
                  disabled={filtersBoxesMode}
                />
              </MuiPickersUtilsProvider>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="nucleus-labels">
              <b>To</b>
            </span>{' '}
            &nbsp;
            {/* <input 
						id="toDateTime" 
						ref="toDateTime" 
						type="text" 
						className="validate nucleus-date-picker-short" 
						autoComplete="off" 
						disabled={filtersBoxesMode}/> */}
            <div style={{ width: 210, display: 'inline-flex' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDateTimePicker
                  ref={this.toDateTimeRef}
                  variant="dialog"
                  className="browser-default"
                  value={this.state.toDateTime}
                  onChange={this.onToDateChange}
                  onError={console.log}
                  onClose={() => {}}
                  //minDate={new Date("2018-01-01T00:00")}
                  format={this.datetimeFormat}
                  disabled={filtersBoxesMode}
                />
              </MuiPickersUtilsProvider>
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <span className="nucleus-labels">
              <b>From/To TimeZone</b>
            </span>{' '}
            &nbsp;
            <select
              ref="selectTimeZone"
              className="browser-default nucleus-select select-large"
              style={{ backgroundColor: 'transparent', borderBottomColor: '#e6e6e6', borderBottomWidth: 1 }}
              onChange={this.handleUpdateTimeZone}
              disabled={filtersBoxesMode}
            >
              {this.messageFilterTimezonesArray}
            </select>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <a class={'txt-white nucleus-font-small ' + filterBtnMode} onClick={this.submitFilterMessages}>
              Filter Results
            </a>
            &nbsp;
            <br />
            <span class={'nucleus-form-error center-align ' + this.state.datesErrorVisible}>{this.state.datesErrorText}</span>
            <br />
          </div>

          <div className="users-table-pending altoImportantContainer">
            {/* <div className="nucleus-table-container"> */}

            <div className="row">
              <table className="bordered highlight  nucleus-table">
                <thead>
                  <tr width="100%">
                    <th className="table-col-9">
                      <span className="nucleus-table-header-medium ">Repeat </span>
                      <a class={'nucleus-font-small ' + this.state.sortRecurringDesc} onClick={this.handleSortRecurringDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a class={'nucleus-font-small ' + this.state.sortRecurringAsc} onClick={this.handleSortRecurringAsc}>
                        &nbsp;▼
                      </a>
                    </th>
                    <th className="table-col-17">
                      <span className="nucleus-table-header-medium">Last Sent Date </span>
                      <a class={'nucleus-font-small ' + this.state.sortLastSentDesc} onClick={this.handleSortLastSentDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a class={'nucleus-font-small ' + this.state.sortLastSentAsc} onClick={this.handleSortLastSentAsc}>
                        &nbsp;▼
                      </a>
                      {/*
									<a class={ "nucleus-font-small nucleus-link " + this.state.sortLastDescending } onClick={this.handleSortByDateDesc} > &nbsp;▲</a>
									<a class={ "nucleus-font-small nucleus-link " + this.state.sortLastAscending } onClick={this.handleSortByDateAsc} >&nbsp;▼</a>
									*/}
                    </th>
                    <th className="table-col-17">
                      <span className="nucleus-table-header-medium">Next Delivery </span>
                      <a class={'nucleus-font-small ' + this.state.sortNextDeliveryDesc} onClick={this.handleSortNextDeliveryDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a class={'nucleus-font-small ' + this.state.sortNextDeliveryAsc} onClick={this.handleSortNextDeliveryAsc}>
                        &nbsp;▼
                      </a>
                    </th>
                    <th className="table-col-11">
                      <span className="nucleus-table-header-medium">Sender</span>
                      <a class={'nucleus-font-small ' + this.state.sortSenderDesc} onClick={this.handleSortSenderDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a class={'nucleus-font-small ' + this.state.sortSenderAsc} onClick={this.handleSortSenderAsc}>
                        &nbsp;▼
                      </a>
                    </th>
                    <th className="table-col-11 center-align">
                      <span className="nucleus-table-header-medium">Recipients</span>
                      <a class={'nucleus-font-small ' + this.state.sortRecipientDesc} onClick={this.handleSortRecipientDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a class={'nucleus-font-small ' + this.state.sortRecipientAsc} onClick={this.handleSortRecipientAsc}>
                        &nbsp;▼
                      </a>
                    </th>

                    <th className="table-col-24">
                      <span className="nucleus-table-header-medium">Message</span>
                    </th>
                    <th className="table-col-10 right-align">
                      <span className="nucleus-table-header-small"></span>
                    </th>
                  </tr>
                </thead>

                <tbody class={tableResultsView}>{messagesList}</tbody>
              </table>
              <div class={'row center-align ' + tableNoResultsView}>
                <br />
                <br />
                <br />
                <br />
                <span className="nucleus-table-header-medium text-gray"> {this.state.noResultsLabel} </span>
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>

            <div class={'row center-align ' + tableLoadingView}>
              <br />
              <br />
              <br />
              <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loading} style={{ display: 'inline-flex' }} />
              <br />
              <br />
              <br />
            </div>

            {/* //DEPRECATED!: Not used
					<div className="fixed-action-btn">
                      	<a 	className="btn-floating btn-large waves-effect waves-light green tooltipped " 
							data-position="left" 
							data-delay="50" 
							data-tooltip="Create New Message" 
							onClick={this.handleNewMessageModal}>
                        	<i className="material-icons">message</i>
                      	</a>
                  	</div> 
					*/}

            <NucleusMessage patientID={null} enableBiometrics={AuthStore.getAccountBiometricsEnabled()} />

            <div id="modalStopRecurringMessage" className="modal modalMessages modalStopRecurringMessage">
              <div className="modal-content modal-content-delete">
                <h3 className="nucleus-page-subtitle"> Stop Recurring Message</h3>
                <p></p>
                <div className="row">
                  <div className="col s12 left-align nucleus-font-medium">
                    Are you sure you want to stop this message from repeating?
                    <br />
                  </div>
                </div>
                <div className="row ">
                  <div className="col s12 right-align ">
                    <p></p>
                    <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelStopRecurringMessageModal}>
                      Cancel
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleStopRecurringMessage}>
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div id="modalResumeSaveMessage" className="modal modalMessages modalResumeSaveMessage">
              <div className="modal-content modal-content-delete">
                <h3 className="nucleus-page-subtitle"> Message Confirmation !</h3>
                <p></p>
                <div className="row">
                  <div className="col s12 left-align nucleus-font-medium">
                    You are about to send a message
                    <br />
                    To be delivered to: {this.state.messageDeliverTo}
                    <br />
                    To be delivered at: {this.state.messageDeliveryAt}
                  </div>
                </div>
                <div className="row ">
                  <div className="col s12 right-align ">
                    <p></p>
                    <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelResumeMessageModal}>
                      Cancel
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleSaveMessage}>
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div id="modalMessageLoadingResults" className="modal modalMessages modalMessageLoadingResults">
              <div className="modal-content modal-content-delete">
                <h3 className="nucleus-page-subtitle"> Message Report</h3>
                <p />
                <p />
                <div className="row no-margin">
                  <div className="col s12 left-align nucleus-font">
                    <span className="nucleus-labels">
                      <b>Getting message responses report...</b>
                    </span>
                  </div>
                </div>
                <div className="row ">
                  <div className="col s12 center-align ">
                    <br />
                    <Spinner color="#2096F3" size={34} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />
                    <br />
                    <br />
                    <p />
                  </div>
                </div>
              </div>
            </div>

            <div id="modalMessageResults" className="modal modalMessages modalScrollable modalTall modalMessageResults">
              <div className="modal-content nucleus-modal-content txt-special-color">
                <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseMessageReportsModal} />
                <h3 className="nucleus-page-subtitle">Message Report</h3>
                <br />
                <br />
                <div className="row no-margin">
                  <div className="col s3 no-margin">
                    <span className="nucleus-labels">
                      <b>Message: </b>
                    </span>
                  </div>
                  <div className="col s9 ">
                    <span className="nucleus-font nucleus-font-medium"> {this.state.messageReportBody}</span>
                  </div>
                </div>
                <div className="row no-margin">
                  <div className="col s3 no-margin">
                    <span className="nucleus-labels">
                      <b>Sender: </b>
                    </span>
                  </div>
                  <div className="col s9 ">
                    <span className="nucleus-font nucleus-font-medium"> {this.state.messageReportSender}</span>
                  </div>
                </div>
                <div className="row no-margin">
                  <div className="col s3 no-margin">
                    <span className="nucleus-labels">
                      <b>Recurring: </b>
                    </span>
                  </div>
                  <div className="col s9 ">
                    <span className="nucleus-font nucleus-font-medium"> {this.state.messageReportRecurring}</span>
                  </div>
                </div>
                <br />
                <div className="row no-margin">
                  <span className="nucleus-labels">
                    <b> From</b>
                  </span>
                  &nbsp;
                  {/* <input 
									id="messageReportFromDateTime" 
									ref="messageReportFromDateTime" 
									type="text" 
									className="validate nucleus-date-picker-short h23" 
									autoComplete="off" 
									disabled={dateReportFilters}/> */}
                  <div style={{ width: 180, display: 'inline-flex' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        ref={this.messageReportFromDateTimeRef}
                        variant="dialog"
                        className="browser-default"
                        value={this.state.messageReportFromDateTime}
                        onChange={date => {
                          this.setState({
                            messageReportFromDateTime: date,
                          });
                          setTimeout(() => {
                            this.handleUpdateMessageReport();
                          }, 300);
                        }}
                        autoOk={true}
                        onError={console.log}
                        //minDate={new Date("2018-01-01T00:00")}
                        format={'MM/DD/YYYY'}
                        disabled={dateReportFilters}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <span className="nucleus-labels">
                    <b>To</b>
                  </span>{' '}
                  &nbsp;
                  {/* <input 
									id="messageReportToDateTime" 
									ref="messageReportToDateTime" 
									type="text" 
									className="validate nucleus-date-picker-short h23" 
									autoComplete="off" 
									disabled={dateReportFilters}/> */}
                  <div style={{ width: 180, display: 'inline-flex' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        ref={this.messageReportToDateTimeRef}
                        variant="dialog"
                        className="browser-default"
                        value={this.state.messageReportToDateTime}
                        onChange={date => {
                          this.setState({
                            messageReportToDateTime: date,
                          });
                          setTimeout(() => {
                            this.handleUpdateMessageReport();
                          }, 300);
                        }}
                        autoOk={true}
                        onError={console.log}
                        //minDate={new Date("2018-01-01T00:00")}
                        format={'MM/DD/YYYY'}
                        disabled={dateReportFilters}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <a class={'txt-white nucleus-font-small ' + filterReportBtnMode} onClick={this.handleUpdateMessageReport}>
                    Filter Results
                  </a>
                  &nbsp;
                </div>
                <br />

                <div class={'row no-margin center-align '}>
                  <div className="nucleus-report center-align message-report">
                    {/*<div id="chartMessageResults" className="nucleus-chart-body"></div>*/}

                    <div class={'row nucleus-msg-table-container no-margin  ' + messageReportContent}>
                      <table className="bordered highlight nucleus-report-table bg-white ">
                        <thead>
                          <tr className="header-biotable-row">
                            <th className="table-col-25 table-radius-top-left">
                              <span className="nucleus-table-header-small">Sent</span>
                              <a class={'nucleus-font-small ' + this.state.sortReportDeliveryDesc} onClick={this.handleSortReportDeliveryDesc}>
                                {' '}
                                &nbsp;▲
                              </a>
                              <a class={'nucleus-font-small ' + this.state.sortReportDeliveryAsc} onClick={this.handleSortReportDeliveryAsc}>
                                &nbsp;▼
                              </a>
                            </th>
                            <th className="table-col-25">
                              <span className="nucleus-table-header-small">{AuthStore.getPatientLabel()} Name</span>
                              <a class={'nucleus-font-small ' + this.state.sortReportPatientDesc} onClick={this.handleSortReportPatientDesc}>
                                {' '}
                                &nbsp;▲
                              </a>
                              <a class={'nucleus-font-small ' + this.state.sortReportPatientAsc} onClick={this.handleSortReportPatientAsc}>
                                &nbsp;▼
                              </a>
                            </th>
                            <th className="table-col-25">
                              <span className="nucleus-table-header-small">{AuthStore.getPatientLabel()} Groups</span>
                              <a class={'nucleus-font-small ' + this.state.sortReportPatientDesc} onClick={this.handleSortReportPatientDesc}>
                                {' '}
                                &nbsp;▲
                              </a>
                              <a class={'nucleus-font-small ' + this.state.sortReportPatientAsc} onClick={this.handleSortReportPatientAsc}>
                                &nbsp;▼
                              </a>
                            </th>
                            <th className="table-col-25 table-radius-top-right">
                              <span className="nucleus-table-header-small">Response</span>
                            </th>
                            {this.state.messageReportIncludesSignature && (
                              <th className="table-col-25 table-radius-top-right">
                                <span className="nucleus-table-header-small">Signature</span>
                              </th>
                            )}
                          </tr>
                        </thead>

                        <tbody>{messageReportRows}</tbody>
                      </table>
                      <div className="fixed-action-btn">
                        <MessagesReportPdf
                          messageData={{
                            ID: this.state.messageReportID,
                            patientMessages: this.state.messageReport,
                            signatureReport: this.state.messageReportIncludesSignature,
                            body: this.state.messageReportBody,
                            sender: this.state.messageReportSender,
                            recurring: this.state.messageReportRecurring,
                            fromTime: this.state.reportFromTime,
                            toTime: this.state.reportToTime,
                            patientLabel: AuthStore.getPatientLabel(),
                          }}
                        ></MessagesReportPdf>
                        &nbsp;&nbsp;
                        <CSVLink data={this.state.messageReport} filename={'NucleusMessageReport.csv'} target="_blank">
                          <a
                            class={'btn-floating txt-white nucleus-font-small nucleus-submit-btn-small tooltipped'}
                            style={{ width: 98, height: 35, textAlign: 'center', justifyContent: 'center', padding: 0 }}
                            data-position="top"
                            data-delay="50"
                            data-tooltip="Download CSV"
                          >
                            <i className="material-icons" style={{ width: 32, marginTop: -3, marginRight: 3 }}>
                              save_alt
                            </i>
                            CSV
                          </a>
                        </CSVLink>
                      </div>
                    </div>

                    <div class={'row no-margin h400 ' + messageReportNoContent}>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <span className="nucleus-labels"> There are no result to show for this message. </span>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>

                    <div class={'row no-margin h400 center-align ' + messageReportLoadingContent}>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.reportLoading} style={{ display: 'inline-flex' }} />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Messages;
