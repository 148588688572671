import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { Account } from 'components/Account/useAccountSelector';
import AccountsList from 'components/ProviderGroups/AccountsList';
import AddAccountToGroup from 'components/ProviderGroups/AddAccountToGroup';
import AddEditProviderGroupCompany from 'components/ProviderGroups/AddEditProviderGroupCompany';
import AddEditRegion from 'components/ProviderGroups/AddEditRegion';
import EditLevelTitle from 'components/ProviderGroups/EditLevelTitle';
import GroupList from 'components/ProviderGroups/GroupList';
import { ProviderGroup, GroupCategory, providerGroupLevelKeyIndex, TierTitles, ReportFrequency } from 'components/ProviderGroups/types';
import useProvidersGroups from 'components/ProviderGroups/useProvidersGroups';
import useRegions from 'components/ProviderGroups/useRegions';
import { useModal } from 'components/UI';
import { useEffect, useState } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import authStore from 'stores/AuthStore';
import styled from 'styled-components';
import Message from 'utils/Message';
import { AddButtonSVG } from '../Schedule/assets/AddButton';
import ArrowIcon from './assets/arrowRight.svg';
import { AddEditProviderGroupEmail } from './components/AddEditProviderGroupEmail';
import RemoveProviderGroupEmail from './components/RemoveProviderGroupEmail';
import ReportsRecipients from './components/ReportsRecipients';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 25px 40px;
  overflow-y: auto;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Title = styled.p`
  color: #0a313f;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 35px;
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  padding-bottom: 15px;
  align-items: flex-start;
  & > div {
    flex: 1;
  }
  .ui-group-container {
    max-width: 30%;
  }
`;

const ArrowRight = styled.img`
  margin-top: 50px;
`;

const FloatingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
`;
type Receiver = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

const ProviderGrouping = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedReceiver, setSelectedReceiver] = useState<Receiver | null>(null);
  const [selectedReceiverForDeletion, setSelectedReceiveForDeletion] = useState<Receiver | null>(null);
  const [reRenderFlag, setReRenderFlag] = useState(false);
  const [tierTitles, setTierTitles] = useState<TierTitles | null>();
  const [selectedTier, setSelectedTier] = useState<string>('');
  const [selectedAccount, setSelectedAccount] = useState<Account>();
  const [frequencies, setFrequencies] = useState<ReportFrequency[]>();
  const [selectedGroupForTable, setSelectedGroupForTable] = useState<ProviderGroup>();
  const fetchTierTitles = async () => {
    const providerId = authStore.getProviderID();
    httpApi
      .get(`/providers/${providerId}/strings`)
      .then(response => {
        const responseTierTitles = response?.data;
        setTierTitles(responseTierTitles);
      })
      .catch(error => {
        console.error('Error getting tier titles', error);
        Message.show('Error getting tier titles');
      });
  };
  const fetchProviderGroupsFrequencies = async () => {
    try {
      const response = await httpApi.get(`/providers/${authStore.getProviderID()}/frequency`);
      const frequencies = response.data;
      console.log('Frequencies:', frequencies);
      setFrequencies(frequencies);
    } catch (error) {
      setFrequencies([]);
      Message.show('Error getting frequencies');
    }
  };

  const fetchTitlesAndFrequencies = () => {
    fetchTierTitles();
    fetchProviderGroupsFrequencies();
  };

  useEffect(() => {
    fetchTitlesAndFrequencies();
  }, []);

  const {
    groups: regionGroups,
    getGroupsByCategoryId: getRegions,
    accountsPerRegion,
    isLoading: isGroupsLoading,
    removeGroupMember,
    getAccountsByRegionId,
    loadMoreGroups,
    hasMoreGroups,
  } = useRegions();

  const { companies, getCompanies, isLoading: isLoadingCategories, loadMore: loadMoreCategories, hasMore: hasMoreCategories, total: totalCompanies } = useProvidersGroups();

  const { isOpen: isOpenGroupCategory, openModal: openGroupCategoryModal, closeModal: closeGroupCategoryModal } = useModal();

  useEffect(() => {
    getCompanies();
  }, []);

  const { isOpen: isOpenGroup, openModal: openGroupModal, closeModal: closeGroupModal } = useModal();

  const { isOpen: isOpenEditLevelTitle, openModal: openLevelTitleModal, closeModal: closeLevelTitleModal } = useModal();

  const { isOpen: isOpenAddAccountToGroup, openModal: openAddAccountGroupModal, closeModal: closeAddAccountToGroupModal } = useModal();

  const [selectedProviderGroup, setSelectedProviderGroup] = useState<GroupCategory | null>();
  // selectedGroup is the region
  const [selectedGroup, setSelectedGroup] = useState<ProviderGroup | null>();

  // Edit
  const [activeGroupCategory, setActiveGroupCategory] = useState<GroupCategory | null>(null);
  const [activeGroup, setActiveGroup] = useState<ProviderGroup | null>(null);

  // Edit Level Title
  const [selectedGroupTitleTier, setActiveTierToEdit] = useState<keyof typeof providerGroupLevelKeyIndex | null>(null);

  useEffect(() => {
    getGroups();
  }, [selectedProviderGroup]);

  useEffect(() => {
    if (selectedGroup?.id) {
      getAccountsByRegionId(selectedGroup?.id);
    }
  }, [selectedGroup]);

  // Group Category
  const openAddGroupCategory = () => {
    setActiveGroupCategory(null);
    openGroupCategoryModal();
  };

  const openEditGroupCategory = (category: GroupCategory) => {
    setActiveGroupCategory(category);
    openGroupCategoryModal();
  };

  const deleteProviderGroupRegionCallback = () => {
    setSelectedGroup(null);
  };
  const deleteProviderGroupCallback = () => {
    setSelectedProviderGroup(null);
  };
  const onCloseGroupCategory = (fetchAgain = true) => {
    if (fetchAgain) getCompanies();
    closeGroupCategoryModal();
  };

  // Group
  const openAddGroup = () => {
    setActiveGroup(null);
    openGroupModal();
  };

  const openEditGroup = (category: ProviderGroup) => {
    setActiveGroup(category);
    openGroupModal();
  };

  const openEditLelvelTitle = (tier: keyof typeof providerGroupLevelKeyIndex) => {
    setActiveTierToEdit(tier);
    openLevelTitleModal();
  };

  const onCloseGroup = () => {
    getGroups();
    closeGroupModal();
  };

  const onCloseEditLevelTitle = () => {
    setActiveTierToEdit(null);
    closeLevelTitleModal();
    fetchTierTitles();
  };

  const getGroups = () => {
    if (selectedProviderGroup) {
      getRegions(selectedProviderGroup.id);
    } else {
      setSelectedGroup(null);
    }
  };

  const handleSelectionOfGroupCategory = (item: GroupCategory) => {
    setSelectedProviderGroup(item);
    setSelectedGroup(null);
    setSelectedTier('Tier1');
  };

  const handleCloseAddAccountToGroupModal = () => {
    if (selectedGroup) {
      getAccountsByRegionId(selectedGroup?.id);
    }

    closeAddAccountToGroupModal();
  };

  const handleRemoveAccountFromRegionGroup = (groupId: string) => {
    if (selectedGroup) {
      removeGroupMember(groupId)
        .then(() => {
          Message.show('Account removed from group successfully.');
          getAccountsByRegionId(selectedGroup?.id);
        })
        .catch(() => {
          Message.show('Sorry, There was a problem removing account.');
        });
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReceiver(null);
    setReRenderFlag(prev => !prev);
  };
  const closeDeleteModal = () => {
    setSelectedReceiveForDeletion(null);
    setIsDeleteModalOpen(false);
    setReRenderFlag(prev => !prev);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };
  const openDeleteModalHandler = receiver => {
    setSelectedReceiveForDeletion(receiver);
    setIsDeleteModalOpen(true);
  };
  const openAddEditModalHandler = receiver => {
    setSelectedReceiver(receiver);
    setIsModalOpen(true);
  };
  const handleSelectedReceiver = (receiver, type: string) => {
    type === 'remove' ? openDeleteModalHandler(receiver) : openAddEditModalHandler(receiver);
  };
  console.log('hasMoreCategories', hasMoreCategories);

  const renderGroupCategories = () => {
    return (
      <Items>
        <GroupList<GroupCategory>
          titleEditable
          data={companies}
          onItemClick={item => handleSelectionOfGroupCategory(item)}
          selectedGroup={selectedProviderGroup}
          buttonOnClick={openAddGroupCategory}
          onActionClick={item => openEditGroupCategory(item)}
          buttonLabel={`+   Create a New ${tierTitles?.Tier1}`}
          title={(totalCompanies > 1 ? tierTitles?.Tier1Plural : tierTitles?.Tier1) ?? 'Company'}
          isLoading={isLoadingCategories}
          loadMore={loadMoreCategories}
          hasMore={hasMoreCategories}
          total={totalCompanies}
          openEditLevelTitle={() => openEditLelvelTitle('tier1')}
        />
        {selectedProviderGroup && (
          <>
            <ArrowRight src={ArrowIcon} alt="Region" />
            <GroupList
              titleEditable
              data={regionGroups}
              openEditLevelTitle={() => openEditLelvelTitle('tier2')}
              isLoading={isGroupsLoading}
              onItemClick={item => {
                setSelectedGroup(item);
                setSelectedTier('Tier2');
                setSelectedGroupForTable(item);
              }}
              selectedGroup={selectedGroup}
              buttonOnClick={openAddGroup}
              onActionClick={item => openEditGroup(item)}
              buttonLabel={`+   Create a New ${tierTitles?.Tier2}`}
              title={(regionGroups.length > 1 ? tierTitles?.Tier2Plural : tierTitles?.Tier2) ?? 'Region'}
              loadMore={() => loadMoreGroups(selectedProviderGroup.id)}
              total={regionGroups.length}
              hasMore={hasMoreGroups}
              tier={selectedTier}
            />
          </>
        )}

        {selectedGroup && (
          <>
            <ArrowRight src={ArrowIcon} alt="Category" />
            <AccountsList
              openEditLevelTitle={() => openEditLelvelTitle('tier3')}
              group={selectedGroup}
              selectedAccount={selectedAccount}
              onItemClick={item => {
                setSelectedAccount(item);
                setSelectedGroupForTable(item as ProviderGroup);
                setSelectedTier('Tier3');
              }}
              buttonOnClick={openAddAccountGroupModal}
              buttonLabel={`+   Add a New ${tierTitles?.Tier3}`}
              accounts={accountsPerRegion}
              removeMemberFromGroup={handleRemoveAccountFromRegionGroup}
              title={(accountsPerRegion.length > 1 ? tierTitles?.Tier3Plural : tierTitles?.Tier3) ?? 'Accounts'}
            />
          </>
        )}
      </Items>
    );
  };

  const renderContent = () => {
    if (!companies) {
      return (
        <LoaderContainer>
          <Spinner color="#2096F3" size={34} speed={0.6} animating={true} />
        </LoaderContainer>
      );
    }

    return renderGroupCategories();
  };

  return (
    <Container>
      <Title>Provider Grouping</Title>
      {renderContent()}
      <ReportsRecipients
        reRenderFlag={reRenderFlag}
        group={selectedGroupForTable}
        selectReceiverCallBack={handleSelectedReceiver}
        tierTitles={tierTitles as TierTitles}
        tier={selectedTier}
      />
      <AddEditProviderGroupCompany
        tierTitles={tierTitles as TierTitles}
        closeModal={onCloseGroupCategory}
        deletedSuccessCallback={deleteProviderGroupCallback}
        isOpen={isOpenGroupCategory}
        category={activeGroupCategory}
      />
      <AddEditRegion
        tierTitles={tierTitles as TierTitles}
        deletedSuccessCallback={deleteProviderGroupRegionCallback}
        closeModal={onCloseGroup}
        isOpen={isOpenGroup}
        category={selectedProviderGroup}
        group={activeGroup}
      />

      <EditLevelTitle
        closeModal={onCloseEditLevelTitle}
        frequencies={frequencies || []}
        requestSuccessCallback={fetchTitlesAndFrequencies}
        isOpen={isOpenEditLevelTitle}
        tierTitles={tierTitles as TierTitles}
        tierSelected={selectedGroupTitleTier as keyof typeof providerGroupLevelKeyIndex}
      />

      {/* TODO */}
      <AddAccountToGroup closeModal={handleCloseAddAccountToGroupModal} isOpen={isOpenAddAccountToGroup} group={selectedGroup} />

      <AddEditProviderGroupEmail
        isOpen={isModalOpen}
        closeModal={closeModal}
        receiver={selectedReceiver ?? null}
        group={selectedGroupForTable as ProviderGroup}
        titles={tierTitles as TierTitles}
        tier={selectedTier}
      />
      <RemoveProviderGroupEmail
        isOpen={isDeleteModalOpen}
        group={selectedGroupForTable as ProviderGroup}
        receiver={selectedReceiverForDeletion ?? null}
        closeDeleteModal={closeDeleteModal}
        titles={tierTitles as TierTitles}
        tier={selectedTier}
      />
      <FloatingButton
        onClick={() => {
          selectedGroupForTable?.id ? openModal() : Message.show('Select a destination, before adding a new Recipient');
        }}
      >
        <AddButtonSVG />
      </FloatingButton>
    </Container>
  );
};

export default ProviderGrouping;
