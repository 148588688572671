import React from 'react';
import styled from 'styled-components';
const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
const Count = styled.span`
  font-family: 'Fira Sans';
  font-weight: 300;
`;

const OnlineContactsCell = ({ cell: { row } }) => {
  const rowData = row.original;
  return (
    <CellContainer>
      <Count>
        {rowData.FamilyMembersActive ?? 0}/{rowData.FamilyMembers ?? 0}
      </Count>
    </CellContainer>
  );
};

export default OnlineContactsCell;
