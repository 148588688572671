import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import NucleusSearchInput from '../../../components/NucleusSearchInput';
import authStore from '../../../stores/AuthStore';
import Message from '../../../utils/Message';
import { AddButtonSVG } from '../Schedule/assets/AddButton';
import AccountUserResetPassword from './components/AccountUserResetPassword';
import AddEditProviderUsers from './components/AddEditProviderUsers';
import DeleteProviderUser from './components/DeleteProviderUser';
import Pagination from './components/Pagination';
import ProviderUsers from './components/ProviderUsers';
import { ProviderUser } from './types';
const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 25px 40px;
  overflow-y: auto;
`;
const ManageUsersTitle = styled.label`
  font-weight: 600;
  font-size: 24px;
  color: #0a313f;
  margin-bottom: 2%;
`;
const FloatingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
`;
type Users = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  name: string;
  isAdmin: boolean;
  status: string;
  address: string;
}[];
const ManageUsers = () => {
  const [users, setUsers] = useState<Users>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [resetPassword, setResetPassword] = useState<boolean>(false);
  const [resetModalActive, setResetModalActive] = useState<boolean>(false);
  const [isProviderUserModalOpen, setIsProviderUserModalOpen] = useState<boolean>(false);
  const [selectedProviderUser, setSelectedProviderUser] = useState<ProviderUser | null>(null);
  const [deleteModalActive, setDeleteModalActive] = useState<boolean>(false);
  const getUsers = () => {
    const providerId = authStore.getProviderID();
    httpApi
      .get(`/providers/${providerId}/users?limit=10&page=${currentPage}`)
      .then(response => {
        setUsers(response.data?.users);
        setTotalUsers(response.data?.totalUsers);
      })
      .catch(error => {
        Message.show('Error getting provider users: ', error);
      });
  };
  const handleCurrentPage = (page: number) => {
    console.log('changing page: ', page);
    setCurrentPage(page);
  };
  const onCloseProviderUserModal = () => {
    getUsers();
    setIsProviderUserModalOpen(false);
  };
  const openAddModal = () => {
    setSelectedProviderUser(null);
    setIsProviderUserModalOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalActive(false);
    setSelectedProviderUser(null);
    getUsers();
  };
  const closeResetModal = () => {
    setSelectedProviderUser(null);
    setResetModalActive(false);
  };
  const onUserResetPasswordClear = () => {
    setSelectedProviderUser(null);
    setResetPassword(false);
  };
  useEffect(() => {
    getUsers();
  }, [currentPage]);
  const totalNumberOfPages = Math.ceil(totalUsers / 10);
  const pageNumbers = [];
  for (let i = 1; i <= totalNumberOfPages; i++) {
    pageNumbers.push(i);
  }
  const filteredUsers = users.filter(
    data =>
      searchText == '' ||
      data.name.toLowerCase().includes(searchText.toLowerCase()) ||
      data.email.toLowerCase().includes(searchText.toLowerCase()) ||
      data.telephone?.toLowerCase().includes(searchText.toLowerCase()),
  );
  const selectProviderUser = (user: ProviderUser, type: string) => {
    setSelectedProviderUser(user);
    if (type === 'edit') {
      setIsProviderUserModalOpen(true);
    } else if (type === 'delete') {
      setDeleteModalActive(true);
    } else if (type === 'reset') {
      setResetPassword(true);
      setResetModalActive(true);
    }
  };
  return (
    <Container>
      <ManageUsersTitle>Manage Users</ManageUsersTitle>
      <NucleusSearchInput
        value={searchText}
        onChange={e => {
          const value = e.target.value;
          setSearchText(value);
        }}
        inputMinWidth={300}
        placeholder={`Filter by Name / Email / Telephone`}
      />
      <ProviderUsers users={filteredUsers} selectUser={selectProviderUser} />
      {totalUsers >= 10 && <Pagination pages={pageNumbers} setCurrentPageCallBack={handleCurrentPage} currentPage={currentPage} />}
      <AddEditProviderUsers isOpen={isProviderUserModalOpen} closeModal={onCloseProviderUserModal} providerUser={selectedProviderUser} />
      <DeleteProviderUser providerUser={selectedProviderUser} deleteModalActive={deleteModalActive} closeDeleteModal={closeDeleteModal} />
      <AccountUserResetPassword
        UserID={selectedProviderUser?.id}
        UserName={selectedProviderUser?.name}
        OnDismiss={onUserResetPasswordClear}
        resetPassword={resetPassword}
        resetModalActive={resetModalActive}
        closeResetModal={closeResetModal}
      />
      <FloatingButton onClick={openAddModal}>
        <AddButtonSVG />
      </FloatingButton>
    </Container>
  );
};

export default ManageUsers;
