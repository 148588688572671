const domain = process.env.REACT_APP_DOT_NET_URL || 'https://care.nucleuslife.io';
const authUrl = process.env.REACT_APP_AUTH_URL || 'https://auth.nucleuscare.io';

export default {
  // "domain" : "http://127.0.0.1:3000/",
  // "domain" : "https://care-dev.nucleuslife.io/ws/",
  rootDomain: domain,
  domain: domain + '/ws/',
  colors: {
    nucleusBlue: '#008FFF',
  },
  authUrl,
};
