import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 99%;
  display: flex;
`;
interface NucleusColumnsContainerProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export const UINucleusColumnsContainer: React.FC<NucleusColumnsContainerProps> = ({ children, style }) => {
  return <Container style={style}>{children}</Container>;
};

export default UINucleusColumnsContainer;
