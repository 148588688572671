import { PatientData as Patient } from 'pages/Patients/patient.types';
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StatusIcon = styled.img`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-right: 10px;
`;

const IsOnlineCell = ({ cell: { row } }) => {
  let deviceStatusIcon = '/img/icon_no_device.png';
  let deviceStatus = 'No devices assigned to the patient';
  const rowData: Patient = row.original;

  if (rowData.TotalDevices > 0) {
    if (rowData.OnlineDevices === rowData.TotalDevices) {
      deviceStatusIcon = '/img/icon_device_active.png';
      deviceStatus = 'All devices are online';
    } else if (rowData.OnlineDevices === 0) {
      deviceStatusIcon = '/img/icon_device_inactive.png';
      deviceStatus = 'All devices are offline';
    } else {
      deviceStatusIcon = '/img/icon_device_semi_active.png';
      deviceStatus = 'Some devices are offline';
    }
  }

  return (
    <CellContainer data-tooltip-id={`tooltip-${rowData.PatientID}`} data-tooltip-content={deviceStatus}>
      <StatusIcon src={deviceStatusIcon} />
      <ReactTooltip id={`tooltip-${rowData.PatientID}`} place="top" variant="dark" />
    </CellContainer>
  );
};

export default IsOnlineCell;
