//@ts-nocheck
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import GeneralStore from '../../stores/GeneralStore';
import UserStore from '../../stores/UserStore';
import Message from '../../utils/Message';

//import _ from "underscore";

class UserItemRow extends React.Component<
  {
    index: any;
    UserID: any;
    FirstName: any;
    LastName: any;
    Email: any;
    Telephone: any;
    Status: any;
    IsAdmin: any;
    IsOnDuty: any;
    UserImageThumb: any;
  },
  {
    userID: any;
    userName: any;
    onDuty: any;
  }
> {
  img: any;

  constructor(props) {
    super(props);
    this.state = {
      userID: null,
      userName: '',
      onDuty: false,
    };
    this.handleOnDuty = this.handleOnDuty.bind(this);
    this.onSetUserOnDutyAction = this.onSetUserOnDutyAction.bind(this);
    this.handleRowClicked = this.handleRowClicked.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
  }

  componentDidMount() {
    //Register Listener
    UserStore.on('onSetUserOnDuty', this.onSetUserOnDutyAction);

    this.setState({
      userID: this.props.UserID,
      userName: this.props.FirstName + ' ' + this.props.LastName,
      onDuty: this.props.IsOnDuty,
    });
  }

  componentWillUnmount() {
    //Remove Listener
    UserStore.removeListener('onSetUserOnDuty', this.onSetUserOnDutyAction);
  }

  handleOnDuty(e) {
    console.log('Checkbox');
    console.log(e);
    const onDuty = e.target.checked;
    console.log(onDuty);

    //Get the User Data from WS
    UserStore.setUserOnDuty({
      UserID: this.state.userID,
      IsOnDuty: onDuty,
    });
  }

  onSetUserOnDutyAction(result) {
    console.log('onSetUserOnDutyAction');
    console.log(result);

    if (result.userId == this.state.userID) {
      if (result.ok) {
        if (result.onDuty) {
          Message.show(this.state.userName + ' is On Duty now!');
        } else {
          Message.show(this.state.userName + ' is Off now!');
        }
      } else {
        Message.show('Error updating ' + this.state.userName + ' OnDuty, please try again.');
      }
    }
  }

  handleRowClicked() {
    this.props.navigate('/userProfile/' + this.props.UserID);
    //window.location.assign('/userProfile/' + this.props.UserID);
  }

  handleDeleteUser() {
    GeneralStore.openDeleteUserModal({
      UserID: this.state.userID,
    });
  }

  render() {
    //let onDutyValue = this.props.IsOnDuty ? "checked" : "";
    const status = this.props.Status ? 'Active' : 'Pending';
    const statusStyle = this.props.Status ? '' : 'nucleus-bold';
    const userOnDutyIndicator = this.props.IsOnDuty ? '/img/staff_active_icon.png' : '/img/staff_inactive_icon.png';
    let coordinatorName = this.props.FirstName + ' ' + this.props.LastName;
    if (coordinatorName != null && coordinatorName.length > 29) {
      coordinatorName = coordinatorName.substring(0, 29) + ' ...';
    }

    let coordinatorPhone = this.props.Telephone;
    if (coordinatorPhone != null && coordinatorPhone.length > 18) {
      coordinatorPhone = coordinatorPhone.substring(0, 18) + ' ...';
    }

    const userAdminIndicator = this.props.IsAdmin ? 'Y' : '';

    return (
      <tr id={`user-${this.props.index}`} onClick={this.handleRowClicked}>
        <td className="nucleus-row">
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {/* UserImageRow */}

            <img //src={this.props.UserImageThumb}
              src={this.props.UserImageThumb ? this.props.UserImageThumb : '/img/user_placeholder.png'}
              ref={img => (this.img = img)}
              onError={() => (this.img.src = '/img/user_placeholder.png')}
              style={{ width: 50, height: 50, marginRight: 10, borderRadius: 25, objectFit: 'cover' }}
            />
            {coordinatorName}
          </div>
        </td>
        <td className="nucleus-row">{this.props.Email}</td>
        <td className="nucleus-row">{coordinatorPhone}</td>
        <td className={'nucleus-row ' + statusStyle}>{status}</td>
        <td className="nucleus-row center-align">{userAdminIndicator}</td>
        <td className="center-align">
          <img src={userOnDutyIndicator} />
        </td>
      </tr>
    );
  }
}

export default props => useReactRouterProps(UserItemRow, props);
