import { FC } from 'react';
import styled from 'styled-components';

type Size = 'small' | 'x-small' | 'medium' | 'large';

export type AvatarProps = {
  alt?: string;
  title?: string;
  style?: React.CSSProperties;
  size?: Size;
  src: string;
};

const getSize = (size: Size): string => {
  switch (size) {
    case 'x-small':
      return '28px';
    case 'small':
      return '50px';
    case 'medium':
      return '80px';
    case 'large':
      return '128px';
    default:
      return '80px';
  }
};

export const UIAvatar: FC<AvatarProps> = ({ alt, style, src = '/img/user_placeholder.png', size = 'medium' }) => {
  const selectedSize = style?.width ? style?.width : getSize(size);

  return (
    <AvatarContainer onClick={e => e.stopPropagation()} style={{ ...style, width: selectedSize, height: selectedSize }}>
      <AvatarImage src={src || '/img/user_placeholder.png'} alt={alt} />
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div<{ style?: React.CSSProperties }>`
  border-radius: 50%;
  background: #f3f4f5;
  position: relative;
  width: ${props => props.style?.width};
  height: ${props => props.style?.height};
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;
