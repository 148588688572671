import {
  UICheckboxLine,
  UINucleusColumnsContainer,
  UINucleusColumnContent,
} from 'components/UI';
import React, { useEffect, useState } from 'react';
import authStore from '../../stores/AuthStore';

export interface UserPermissions {
  IsAdmin: boolean;
  CanSeeStaffTab: boolean;
  CanSeeClientsTab: boolean;
  CanAccessGroups: boolean;
  CanSeeQueueTab: boolean;
  CanSeeMessageCenterTab: boolean;
  CanSeeAlertsTab: boolean;
  CanSeeMemoryBox: boolean;
  CanSeeBulletinBoard: boolean;
  CanSeeDevicesTab: boolean;
  CanAccessReports: boolean;
  CanDeletePatients: boolean;
  CanDeleteDevices: boolean;
  BargeInCall: boolean;
  CanImportClients: boolean;
  CanRestartDevices: boolean;
}

interface Props {
  onDataChanged: (permissions: UserPermissions) => void;
}

export const defaultUserPermissions: UserPermissions = {
  IsAdmin: false,
  CanSeeStaffTab: true,
  CanSeeClientsTab: true,
  CanAccessGroups: false,
  CanSeeQueueTab: true,
  CanSeeMessageCenterTab: true,
  CanSeeAlertsTab: true,
  CanSeeMemoryBox: true,
  CanSeeBulletinBoard: true,
  CanSeeDevicesTab: false,
  CanAccessReports: false,
  CanDeletePatients: false,
  CanDeleteDevices: false,
  BargeInCall: true,
  CanRestartDevices: true,
  CanImportClients: false,
};

const NewUserPermissions: React.FC<Props> = (props) => {
  const [userPermissions, setUserPermissions] = useState<UserPermissions>(defaultUserPermissions);

  useEffect(() => {
    console.log("userPermissions>useEffect");
    props.onDataChanged && props.onDataChanged(userPermissions);
    return () => {};
  }, [userPermissions]);

  const togglePermission = (permission: keyof UserPermissions, newValue: boolean) => {
    setUserPermissions((prevState) => ({
      ...prevState,
      [permission]: newValue,
    }));
  };

  return (
    <UINucleusColumnsContainer>
      <UINucleusColumnContent>
        <UICheckboxLine 
          tagId="isAdmin" 
          label="Admin Access" 
          checked={userPermissions.IsAdmin} 
          onClick={(newPermission) => togglePermission('IsAdmin', newPermission)} 
        />
        <p></p>
        <UICheckboxLine
          tagId="checkStaffAccess"
          label="Can access Staff tab"
          checked={userPermissions.CanSeeStaffTab}
          onClick={(newPermission) => togglePermission('CanSeeStaffTab', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkClientsAccess"
          label={'Can access ' + authStore.getPatientLabelPlural() + ' tab'}
          checked={userPermissions.CanSeeClientsTab}
          onClick={(newPermission) => togglePermission('CanSeeClientsTab', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkGroupsAccess"
          label={'Can access Groups tab'}
          checked={userPermissions.CanAccessGroups}
          onClick={(newPermission) => togglePermission('CanAccessGroups', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkQueueAccess"
          label={'Can access Queue tab'}
          checked={userPermissions.CanSeeQueueTab}
          onClick={(newPermission) => togglePermission('CanSeeQueueTab', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkMessagesAccess"
          label={'Can access Message Center tab'}
          checked={userPermissions.CanSeeMessageCenterTab}
          onClick={(newPermission) => togglePermission('CanSeeMessageCenterTab', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkAlertsAccess"
          label={'Can access Alerts tab'}
          checked={userPermissions.CanSeeAlertsTab}
          onClick={(newPermission) => togglePermission('CanSeeAlertsTab', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkPicturesAccess"
          label={'Can access ' + authStore.getPicturesLabel() + ' tab'}
          checked={userPermissions.CanSeeMemoryBox}
          onClick={(newPermission) => togglePermission('CanSeeMemoryBox', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkBulletinBoardAccess"
          label={'Can access Bulletin Board tab'}
          checked={userPermissions.CanSeeBulletinBoard}
          onClick={(newPermission) => togglePermission('CanSeeBulletinBoard', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkDevicesAccess"
          label={'Can access Devices tab'}
          checked={userPermissions.CanSeeDevicesTab}
          onClick={(newPermission) => togglePermission('CanSeeDevicesTab', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkReportsAccess"
          label={'Can access Reports tab'}
          checked={userPermissions.CanAccessReports}
          onClick={(newPermission) => togglePermission('CanAccessReports', newPermission)}
        />
      </UINucleusColumnContent>
      <UINucleusColumnContent>
        <div style={{ height: 41 }}></div>
        <UICheckboxLine
          tagId="checkDeletePatients"
          label={'Can delete ' + authStore.getPatientLabelPlural()}
          checked={userPermissions.CanDeletePatients}
          onClick={(newPermission) => togglePermission('CanDeletePatients', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkDeleteDevices"
          label={'Can edit/delete Devices'}
          checked={userPermissions.CanDeleteDevices}
          onClick={(newPermission) => togglePermission('CanDeleteDevices', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkBargeInCalls"
          label={'Can place Barge In calls'}
          checked={userPermissions.BargeInCall}
          onClick={(newPermission) => togglePermission('BargeInCall', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkImportClients"
          label={'Can import ' + authStore.getPatientLabelPlural() + ' from CSV'}
          checked={userPermissions.CanImportClients}
          onClick={(newPermission) => togglePermission('CanImportClients', newPermission)}
        />
        <p></p>
        <UICheckboxLine
          tagId="checkRestartDevices"
          label={'Can restart Devices'}
          checked={userPermissions.CanRestartDevices}
          onClick={(newPermission) => togglePermission('CanRestartDevices', newPermission)}
        />
      </UINucleusColumnContent>
    </UINucleusColumnsContainer>
  );
};

export default NewUserPermissions;
