import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { IProvider, ProviderAvailability, ProviderStringsResponse, ProviderUser } from '../provider.types';

export const getProviderUserDetails = (providerId: string, userId: string) => {
  return httpApi.get<ProviderUser>(`/providers/${providerId}/users/${userId}`);
};
export const getProviderById = (providerId: string) => {
  return httpApi.get<IProvider>(`/providers/${providerId}`);
};
export const getProviderStrings = async (providerId: string): Promise<ProviderStringsResponse> => {
  const { data } = await httpApi.get(`/providers/${providerId}/strings`);
  return data as ProviderStringsResponse;
};

export const createProviderAvailability = async (providerId: string, data: ProviderAvailability) => {
  const response = await httpApi.post(`/providers/${providerId}/schedule-availabilities`, data);
  console.log('provider availability response', response.data);
  return response.data as { id: string };
};
export const updateProviderAvailability = (providerId: string, scheduleAvailabilityId: string, data: ProviderAvailability) => {
  return httpApi.put(`/providers/${providerId}/schedule-availabilities/${scheduleAvailabilityId}`, data);
};
export const deleteProviderAvailability = (providerId: string, scheduleAvailabilityId: string) => {
  return httpApi.delete(`/providers/${providerId}/schedule-availabilities/${scheduleAvailabilityId}`);
};
