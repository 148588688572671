import moment from 'moment-timezone';
import { FC, useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';
import { UIModal } from '../../../../components/UI';
import { ModalAction } from '../../../../components/UI/Modals/Base/ActionButtons';
import { UINucleusContentContainer } from '../../../../components/UI/NucleusContainer/Content';
import AuthStore from '../../../../stores/AuthStore';
import message from '../../../../utils/Message';
import { ProviderAvailabilityTableRow } from '../../provider.types';
import { deleteProviderAvailability as apiDeleteProviderAvailability } from '../../utils/providerApi';

const Title = styled.p`
  font-family: Barlow;
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const Note = styled.p`
  font-family: Barlow;
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;

const Name = styled.p`
  font-family: Barlow;
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

const Bold = styled.p`
  font-family: Barlow;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  color: #0a313f;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  fetchAvailability: () => void;
  availabilityToDeleteData?: ProviderAvailabilityTableRow;
}

const DeleteProviderAvailability: FC<Props> = ({ closeModal, isOpen, fetchAvailability, availabilityToDeleteData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasScheduledPatients, setHasScheduledPatients] = useState(false);
  const providerId = AuthStore.getProviderID()!;
  const deleteAvailability = async () => {
    setIsLoading(true);
    if (!availabilityToDeleteData?.id) return;
    apiDeleteProviderAvailability(providerId, availabilityToDeleteData?.id)
      .then(deleteResponse => {
        if (deleteResponse?.data?.code == 'SCHEDULE_AVAILABILITY_NOT_FOUND') {
          setHasScheduledPatients(true);
          setIsLoading(false);
          return;
        }
        if (deleteResponse.status >= 200 && deleteResponse.status < 300) {
          message.show('Availability deleted successfully');
          onClose();
          fetchAvailability();
        } else {
          message.show('Failed to delete availability');
        }
        setIsLoading(false);
        return;
      })
      .catch(error => {
        if (error?.response?.data?.code == 'EXISTING_PATIENT_SCHEDULES') {
          setHasScheduledPatients(true);
          setIsLoading(false);
          return;
        }
        setIsLoading(false);
      });
  };

  const submit = () => {
    deleteAvailability();
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: hasScheduledPatients ? 'Close' : 'Cancel',
        onClick: onClose,
        buttonVariant: 'secondary',
      },
      {
        label: 'Proceed',
        onClick: submit,
        disabled: isLoading,
      },
    ];
    if (hasScheduledPatients) {
      data.pop();
    }
    return data;
  };
  const onClose = () => {
    setHasScheduledPatients(false);
    setIsLoading(false);
    closeModal();
  };
  useEffect(() => {
    return () => {
      setHasScheduledPatients(false);
      setIsLoading(false);
    };
  }, []);
  return (
    <UIModal size="mediumLarge" isOpen={isOpen} close={onClose} actions={getActions()}>
      <Title>{hasScheduledPatients ? 'ERROR' : 'WARNING'}!</Title>
      {hasScheduledPatients ? (
        <>
          <Note>You tried to delete the Provider Availability scheduled for:</Note>
          <Note>{moment(availabilityToDeleteData?.startTime).format('MMMM D, YYYY')}</Note>
          <Note>{`${moment(availabilityToDeleteData?.startTime).format('h:mm A')} - ${moment(availabilityToDeleteData?.endTime).format('h:mm A')}`}</Note>
          <Bold>There is at least one appointment scheduled for this availability.</Bold>
          <Note>The delete function is not allowed in this instance.</Note>
        </>
      ) : (
        <>
          <Note>You are about to delete the Provider Availability scheduled for:</Note>
          <Name>{moment(availabilityToDeleteData?.startTime).format('MMMM D, YYYY')}</Name>
          <Bold>{`${moment(availabilityToDeleteData?.startTime).format('h:mm A')} - ${moment(availabilityToDeleteData?.endTime).format('h:mm A')}`}</Bold>
          <Note>This action is permanent.</Note>
          <Note>Would you like to proceed?</Note>
        </>
      )}
      <UINucleusContentContainer centerContent>
        {isLoading && <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />}
      </UINucleusContentContainer>
    </UIModal>
  );
};

export default DeleteProviderAvailability;
