import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useNucleusProviders } from '../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { getProviderByID } from '../../../utils/providerApi';

const ProviderDetailsWrapper = ({ children }) => {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const { dispatch, state } = useNucleusProviders();
  const {
    provider: {
      data: providerData,
      state: { error, loading, success },
    },
  } = state;
  useEffect(() => {
    //const providerId = route.params.providerId
    if (providerData?.ID === providerId) return;
    console.log('route.params.providerId: ' + providerId);
    if (!providerId && !loading && (error || !success)) {
      navigate('/providers', { replace: true });
      return;
    }
    async function getProvider(providerId) {
      dispatch({
        type: 'set_provider',
        payload: {
          error: false,
          loading: true,
          success: false,
          provider: null,
        },
      });
      const provider = await getProviderByID(providerId);
      console.log('provider.id: ' + provider);
      dispatch({
        type: 'set_provider',
        payload: {
          error: false,
          loading: false,
          success: true,
          provider: provider,
        },
      });
      if (!provider || !provider.ID) {
        navigate('/providers', { replace: true });
      }
    }
    getProvider(providerId);
  }, [providerId]);
  return <React.Fragment>{children}</React.Fragment>;
};

export default ProviderDetailsWrapper;
