import { useEffect, useState } from 'react';

export const usePolling = (callback: () => Promise<void>, interval: number, start: boolean) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout>();
  const [inProgress, setInProgress] = useState<boolean>(false);
  useEffect(() => {
    const polling = setInterval(async () => {
      //Avoid overlapping requests
      if (inProgress || !start) {
        return;
      }

      setInProgress(true);
      try {
        await callback();
      } catch (e) {
        console.error('Error in polling', e);
        setInProgress(false);
        throw e;
      }
      setInProgress(false);
    }, interval);
    setIntervalId(polling);
    return () => {
      clearInterval(polling);
    };
  }, [start]);

  return intervalId;
};
