import { useEffect, useState } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import styled from 'styled-components';

import { useModal } from '../../components/UI';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import message from '../../utils/Message';
import ArrowIcon from './assets/arrowRight.svg';
import AddEditGroup from './components/AddEditGroup';
import AddEditGroupCategory from './components/AddEditGroupCategory';
import AddPatientsToGroup from './components/AddPatientsToGroup';
import GroupList from './components/GroupList';
import PatientList from './components/PatientList';
import { Group, GroupCategory } from './components/types';
import useCategories from './components/useCategories';
import useGroups from './components/useGroups';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 25px 40px;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Title = styled.p`
  color: #0a313f;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  align-items: flex-start;
  flex-grow: 1;
  overflow: hidden;

  & > div {
    flex: 1;
  }

  .ui-group-container {
    max-width: 30%;
  }
`;

const ArrowRight = styled.img`
  margin-top: 50px;
`;

const Groups = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { groups, getGroupsByCategoryId, patientsPerGroup, isLoading: isGroupsLoading, removePatientFromGroup, getPatientsByGroupId, loadMoreGroups, hasMoreGroups } = useGroups();

  const { categories, getCategories, isLoading: isLoadingCategories, loadMore: loadMoreCategories, hasMore: hasMoreCategories, total: totalCategories } = useCategories();

  useEffect(() => {
    validateAccessGranted('/groups');
  }, []);

  useEffect(() => {
    getCategories();
  }, []);

  const { isOpen: isOpenGroupCategory, openModal: openGroupCategoryModal, closeModal: closeGroupCategoryModal } = useModal();

  const { isOpen: isOpenGroup, openModal: openGroupModal, closeModal: closeGroupModal } = useModal();

  const { isOpen: isOpenAddPatientsToGroup, openModal: openAddPatientsToGroupModal, closeModal: closeAddPatientsToGroupModal } = useModal();

  const [selectedGroupCategory, setSelectedGroupCategory] = useState<GroupCategory | null>();
  const [selectedGroup, setSelectedGroup] = useState<Group | null>();

  // Edit
  const [activeGroupCategory, setActiveGroupCategory] = useState<GroupCategory | null>(null);
  const [activeGroup, setActiveGroup] = useState<Group | null>(null);

  useEffect(() => {
    getGroups();
  }, [selectedGroupCategory]);

  useEffect(() => {
    if (selectedGroup?.id) {
      getPatientsByGroupId(selectedGroup?.id);
    }
  }, [selectedGroup]);

  // Group Category
  const openAddGroupCategory = () => {
    setActiveGroupCategory(null);
    openGroupCategoryModal();
  };

  const openEditGroupCategory = (category: GroupCategory) => {
    setActiveGroupCategory(category);
    openGroupCategoryModal();
  };

  const onCloseGroupCategory = () => {
    getCategories();
    closeGroupCategoryModal();
  };

  // Group
  const openAddGroup = () => {
    setActiveGroup(null);
    openGroupModal();
  };

  const openEditGroup = (category: Group) => {
    setActiveGroup(category);
    openGroupModal();
  };

  const onCloseGroup = () => {
    getGroups();
    closeGroupModal();
  };

  const getGroups = () => {
    if (selectedGroupCategory) {
      getGroupsByCategoryId(selectedGroupCategory.id);
    } else {
      setSelectedGroup(null);
    }
  };

  const handleSelectionOfGroupCategory = (item: GroupCategory) => {
    setSelectedGroupCategory(item);
    setSelectedGroup(null);
  };

  const handleCloseAddPatientsToGroupModal = () => {
    if (selectedGroup) {
      getPatientsByGroupId(selectedGroup?.id);
    }

    closeAddPatientsToGroupModal();
  };

  const handleRemovePatientFromGroup = (patientId: string) => {
    if (selectedGroup) {
      removePatientFromGroup([patientId], selectedGroup.id)
        .then(() => {
          message.show('Client removed from group successfully.');
          getPatientsByGroupId(selectedGroup?.id);
        })
        .catch(() => {
          message.show('Sorry, There was a problem removing client.');
        });
    }
  };

  console.log('hasMoreCategories', hasMoreCategories);

  const renderGroupCategories = () => {
    return (
      <Items>
        <GroupList
          data={categories}
          onItemClick={item => handleSelectionOfGroupCategory(item)}
          selectedGroup={selectedGroupCategory}
          buttonOnClick={openAddGroupCategory}
          onActionClick={item => openEditGroupCategory(item)}
          buttonLabel="+&nbsp;&nbsp;Create a New Category"
          title="Group Categories"
          isLoading={isLoadingCategories}
          loadMore={loadMoreCategories}
          hasMore={hasMoreCategories}
          total={totalCategories}
        />
        {selectedGroupCategory && (
          <>
            <ArrowRight src={ArrowIcon} alt="Category" />
            <GroupList
              data={groups}
              isLoading={isGroupsLoading}
              onItemClick={item => setSelectedGroup(item)}
              selectedGroup={selectedGroup}
              buttonOnClick={openAddGroup}
              onActionClick={item => openEditGroup(item)}
              buttonLabel="+&nbsp;&nbsp;Create a New Group"
              title="Groups"
              loadMore={() => loadMoreGroups(selectedGroupCategory.id)}
              hasMore={hasMoreGroups}
            />
          </>
        )}

        {selectedGroup && (
          <>
            <ArrowRight src={ArrowIcon} alt="Category" />
            <PatientList
              group={selectedGroup}
              buttonOnClick={openAddPatientsToGroupModal}
              patients={patientsPerGroup}
              removePatientFromGroup={handleRemovePatientFromGroup}
              title="Client"
            />
          </>
        )}
      </Items>
    );
  };

  const renderContent = () => {
    if (isLoading && !categories) {
      return (
        <LoaderContainer>
          <Spinner color="#2096F3" size={34} speed={0.6} animating={isLoading} />
        </LoaderContainer>
      );
    }

    return renderGroupCategories();
  };

  return (
    <Container>
      <Title>Groups</Title>
      {renderContent()}
      <AddEditGroupCategory closeModal={onCloseGroupCategory} isOpen={isOpenGroupCategory} category={activeGroupCategory} />
      <AddEditGroup closeModal={onCloseGroup} isOpen={isOpenGroup} category={selectedGroupCategory} group={activeGroup} />
      <AddPatientsToGroup closeModal={handleCloseAddPatientsToGroupModal} isOpen={isOpenAddPatientsToGroup} group={selectedGroup} />
    </Container>
  );
};

export default Groups;
