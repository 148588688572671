import React from 'react';
import { ReactComponent as AlertIcon } from '../../svg/alert.svg';
import css from './Error.module.css';

const ERROR_MESSAGES = {
  MISSING_PARAMETERS: 'Missing parameter devicesIds in querystring',
  UNAUTHORIZED: 'Unauthorized to call device, make sure the user has correct access to call the device provided in the querystring',
  UNEXPECTED: 'Unexpected error',
};

export function CallSessionError({ errorCode = '' }) {
  const errorMsg = ERROR_MESSAGES[errorCode] || '';
  return (
    <div className={css.container}>
      <div className={css.errorContainer}>
        <AlertIcon />
        <p className={css.errorMsg}>{errorMsg}</p>
      </div>
    </div>
  );
}
