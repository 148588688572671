import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import { UserPatientsResponse } from './interfaces/PatientApi';

interface PaginationParams {
  limit: number;
  page: number;
  sort?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  searchText?: string;
}

export const getUserPatients = async (userID: string, { page, limit, searchText, sortBy, sortOrder }: PaginationParams) => {
  try {
    const params = {
      page,
      limit,
    } as PaginationParams;
    if (sortBy && sortOrder) {
      params.sortBy = sortBy;
      params.sortOrder = sortOrder;
    }
    if (searchText) {
      params.searchText = searchText;
    }
    const response = await httpNodeApi.get(`/user/${userID}/patients`, {
      params,
    });
    console.log('getUserPatients.response', response);
    return response.data as UserPatientsResponse;
  } catch (error) {
    console.error('Error fetching user patients:', error);
    throw error;
  }
};
