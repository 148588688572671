//@ts-nocheck
import { Document, Page, Text, Image, View, StyleSheet, Font } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';

Font.register({
  family: 'GothamNarrow-Medium',
  src: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Medium.otf',
});
Font.register({
  family: 'GothamNarrow-Light',
  src: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Light.otf',
});
Font.register({
  family: 'GothamNarrow-Thin',
  src: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/fonts/GothamNarrow-Thin.otf',
});

const GeneratePdfDocument = props => {
  console.log('GeneratePdfDocument', props);

  const generateReportLines = () => {
    //console.log("generateReportLines", props);
    const reportRows = [];
    props.messagesData.forEach((message, index) => {
      const row = (
        <View key={index} style={pdfStyles.bodyTableRow}>
          <View style={[pdfStyles.bodyTableRowCell, { fontSize: 10 }]}>
            <Text>{message.DeliveryTime /* .replace(/:\d{2}\s/,' ') */}</Text>
          </View>
          <View style={[pdfStyles.bodyTableRowCell, { fontSize: 11 }]}>
            <Text>{message.Sender}</Text>
          </View>
          <View style={[pdfStyles.bodyTableRowCell, { fontSize: 11 }]}>
            <Text>{message.Message}</Text>
          </View>
          <View style={[pdfStyles.bodyTableRowCell, { fontSize: 9 }]}>
            <Text>{message.Status}</Text>
          </View>
          <View style={[pdfStyles.bodyTableRowCell, { fontSize: 10 }]}>
            {message.SignatureUrl != null && (
              <Image src={{ uri: message.SignatureUrl, method: 'GET', headers: { 'Cache-Control': 'no-cache' }, body: '' }} style={{ width: 120 }}></Image>
            )}
          </View>
        </View>
      );
      reportRows.push(row);
    });
    return reportRows;
  };

  return (
    <Document>
      <Page size="A4" orientation="portrait" style={pdfStyles.page}>
        <Image
          src={{
            uri: 'https://nucleuscare-data.s3.us-west-2.amazonaws.com/logos/nucleuscare_logo_2022.png',
            method: 'GET',
            headers: { 'Cache-Control': 'no-cache' },
            body: '',
          }}
          style={{ width: 172 }}
        ></Image>
        <View style={pdfStyles.header}>
          <View style={pdfStyles.title}>
            <Text>{props.patientLabel + ' Messages Report'}</Text>
          </View>

          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{props.patientLabel + ':'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.patientFullName}</Text>
            </View>
          </View>

          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{'Messages from:'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.messagesFromTime}</Text>
            </View>
          </View>
          <View style={pdfStyles.headerRow}>
            <View style={pdfStyles.headerColumnLeft}>
              <Text>{'Messages to:'}</Text>
            </View>
            <View style={pdfStyles.headerColumnRight}>
              <Text>{props.messagesToTime}</Text>
            </View>
          </View>
        </View>

        <View style={pdfStyles.body}>
          <View style={pdfStyles.bodyTable}>
            <View style={pdfStyles.bodyTableHeader}>
              <View style={[pdfStyles.bodyTableHeaderCell, { flex: 2 }]}>
                <Text>{'Sent'}</Text>
              </View>
              <View style={[pdfStyles.bodyTableHeaderCell, { flex: 2 }]}>
                <Text>{'Sender'}</Text>
              </View>
              <View style={[pdfStyles.bodyTableHeaderCell, { flex: 2 }]}>
                <Text>{'Message'}</Text>
              </View>
              <View style={[pdfStyles.bodyTableHeaderCell, { flex: 2 }]}>
                <Text>{'Response'}</Text>
              </View>
              <View style={[pdfStyles.bodyTableHeaderCell, { flex: 2 }]}>
                <Text>{'Signature'}</Text>
              </View>
            </View>
            <View style={pdfStyles.bodyTableContent}>{generateReportLines()}</View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const pdfStyles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#E4E4E4',
    padding: 25,
    fontWeight: 'light',
    fontFamily: 'GothamNarrow-Medium',
    height: '100%',
  },
  title: {
    marginBottom: 15,
    color: '#0A313F',
  },
  textTitle: {},
  header: {
    flexDirection: 'column',
    marginTop: 15,
    marginBottom: 15,
    backgroundColor: 'white',
    padding: 15,
    paddingTop: 5,
    paddingLeft: 5,
    borderRadius: 3,
    //borderColor:'gray', borderWidth:1, borderStyle:'solid'
  },
  headerRow: {
    flexDirection: 'row',
    fontFamily: 'GothamNarrow-Medium',
    //borderColor:'gray', borderWidth:1, borderStyle:'solid'
    fontSize: 14,
  },
  headerColumnLeft: {
    flex: 1,
    //borderColor:'gray', borderWidth:1, borderStyle:'solid'
  },
  headerColumnRight: {
    flex: 3,
    fontSize: 14,
    fontFamily: 'GothamNarrow-Light',
    //borderColor:'gray', borderWidth:1, borderStyle:'solid'
  },
  textHeader: {},
  body: {
    flex: 1,
    backgroundColor: 'white',
  },
  bodyTableHeader: {
    flexDirection: 'row',
    fontSize: 13,
    justifyContent: 'center',
  },
  bodyTableHeaderCell: {
    flex: 1,
    backgroundColor: '#BCC8D0',
    padding: 5,
    alignSelf: 'center',
  },
  bodyTableContent: {
    flexDirection: 'column',
    backgroundColor: 'white',
    fontSize: 11,
  },
  bodyTableRow: {
    flexDirection: 'row',
    fontFamily: 'GothamNarrow-Light',
    borderColor: '#E4E4E4',
    borderWidth: 1,
    borderStyle: 'solid',
    justifyContent: 'center',
  },
  bodyTableRowCell: {
    flex: 1,
    padding: 3,
    paddingTop: 5,
    alignSelf: 'center',
  },

  textBody: {},
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

// ===================================================================== \
// PatientMessagesReports	  																							|
// ===================================================================== /
const PatientMessagesReports = props => {
  console.log('PatientMessagesReports PropsData', props);

  const generateFileName = () => {
    let fileName = 'Patient' + 'MessagesReport';
    if (props.patientLabel) {
      fileName = props.patientLabel + ' ' + props.patientFullName.replaceAll(' ', '') + ' MessagesReport ' + props.fromTime + '-' + props.toTime;

      fileName = fileName.replaceAll(' ', '_');
    }
    return fileName;
  };

  const [stateMessagesDataToExport, setStateMessagesDataToExport] = useState([]);
  const reportFileNameRef = useRef('');
  reportFileNameRef.current = generateFileName();

  const csvFileHeadersRef = useRef([]);
  csvFileHeadersRef.current = [
    { label: 'Sent', key: 'DeliveryTime' },
    { label: 'Sender', key: 'Sender' },
    { label: 'Message', key: 'Message' },
    { label: 'Response', key: 'Status' },
    { label: 'Signature', key: 'SignatureUrl' },
  ];

  useEffect(() => {
    //stateMessagesDataToExport.current = [];
    if (props.patientMessages && props.patientMessages.length > 0 && props.patientLabel && props.patientFullName) {
      const outputDataArray = [];
      let reportObject = {};

      props.patientMessages.forEach(message => {
        reportObject = {};
        reportObject.DeliveryTime = message.DeliveryTime + ' ' + message.Timezone;
        reportObject.Sender = message.User;
        reportObject.Message = message.Message;
        reportObject.Status = message.Status;
        reportObject.SignatureUrl = message.SignatureUrl;
        outputDataArray.push(reportObject);
      });
      setStateMessagesDataToExport(outputDataArray);
      //stateMessagesDataToExport.current = outputDataArray;

      reportFileNameRef.current = generateFileName();
      //console.log("PatientMessagesReports File Name >", reportFileNameRef.current);
    }
  }, [props]);

  return (
    <div style={{ display: 'inline' }}>
      {stateMessagesDataToExport && stateMessagesDataToExport.length > 0 && (
        <PDFDownloadLink
          document={
            <GeneratePdfDocument
              messagesData={stateMessagesDataToExport}
              patientLabel={props.patientLabel}
              patientFullName={props.patientFullName}
              messagesFromTime={props.fromTime}
              messagesToTime={props.toTime}
            />
          }
          fileName={reportFileNameRef.current + '.pdf'}
        >
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
          <a
            class={'btn-floating txt-white nucleus-font-small nucleus-submit-btn-small  '} //tooltipped
            style={{ width: 98, height: 35, textAlign: 'center', justifyContent: 'center', padding: 0, marginRight: 15 }}
            data-position="top"
            data-delay="50"
            data-tooltip="Download PDF"
          >
            <i className="material-icons" style={{ width: 32, marginTop: -3, marginRight: 3 }}>
              save_alt
            </i>
            PDF
          </a>
        </PDFDownloadLink>
      )}
      {stateMessagesDataToExport && stateMessagesDataToExport.length > 0 && (
        <CSVLink headers={csvFileHeadersRef.current} data={stateMessagesDataToExport} filename={reportFileNameRef.current + '.csv'} target="_blank">
          <a
            class={'btn-floating txt-white nucleus-font-small nucleus-submit-btn-small '} //tooltipped
            style={{ width: 98, height: 35, textAlign: 'center', justifyContent: 'center', padding: 0, marginRight: 15 }}
            data-position="top"
            data-delay="50"
            data-tooltip="Download CSV"
          >
            <i className="material-icons" style={{ width: 32, marginTop: -3, marginRight: 3 }}>
              save_alt
            </i>
            CSV
          </a>
        </CSVLink>
      )}
    </div>
  );
};

export default PatientMessagesReports;
