import React, { useMemo } from 'react';
import NucleusTable from '../../../../components/NucleusTable';
import Edit from '../assets/editIcon.svg';
import Lock from '../assets/lock.svg';
import Trash from '../assets/trash.svg';
const ProviderUsers = ({ users, selectUser }) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Email',
        accessor: 'email',
        disableSortBy: true,
      },
      {
        Header: 'Telephone',
        accessor: 'telephone',
        disableSortBy: true,
      },
      {
        Header: 'Reset Password',
        Cell: ({ row }) => {
          return <img style={{ marginLeft: '15%' }} src={Lock} onClick={() => selectUser(row.original, 'reset')} />;
        },
      },
      {
        disableSortBy: true,
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', width: '70%', justifyContent: 'space-evenly', gap: 12 }}>
              <img src={Edit} onClick={() => selectUser(row.original, 'edit')} />
              <img src={Trash} onClick={() => selectUser(row.original, 'delete')} />
            </div>
          );
        },
      },
    ],
    [],
  );
  return <NucleusTable columns={columns} data={users} />;
};

export default ProviderUsers;
