import { CSSProperties, FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 100px;
  cursor: pointer;
  padding: 8px 24px;

  &:hover {
    opacity: 0.8;
  }
`;

const Text = styled.p`
  font-size: 15px;
  font-weight: 400;
  margin: 0;
`;

const ChevronContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
`;

const withChevronStyle: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
};
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  containerStyle?: CSSProperties;
  textStyle?: CSSProperties;
  text: string;
  onClick?: () => void;
  showChevron?: boolean;
}
export const BaseSmall: FC<ButtonProps> = ({ containerStyle, textStyle, text, onClick, showChevron = false }) => {
  return (
    <Container onClick={onClick} style={showChevron ? { ...withChevronStyle, ...containerStyle } : containerStyle}>
      <Text style={textStyle}>{text}</Text>
      {!!showChevron && (
        <ChevronContainer>
          <i className="Tiny material-icons" style={{ color: '#0092FF', fontSize: 24, fontWeight: 'bold' }}>
            chevron_right
          </i>
        </ChevronContainer>
      )}
    </Container>
  );
};

export default BaseSmall;
