import React, { ReactElement } from 'react';
import { useLocation, Link } from 'react-router-dom';

export const ProviderSidebarMenuItem = ({
  isVisible,
  onClick,
  menuIcon,
  text,
  id,
  path,
}: {
  isVisible: boolean;
  onClick: () => void;
  menuIcon: ReactElement;
  text: string;
  id: string;
  path: string;
}) => {
  const location = useLocation();
  const active = location.pathname.indexOf(path) != -1;
  const activeClass = active ? 'menu-active' : ' ';
  const visibleClasss = isVisible ? '' : ' hide ';
  const MenuIcon = React.cloneElement(menuIcon, { active: active });

  if (!isVisible) return null;

  return (
    <Link id={id} to={path} className={'txt-white ' + visibleClasss}>
      <div className={'center-align row nucleus-menu ' + activeClass} onClick={onClick}>
        <div className="col m12">{MenuIcon}</div>
        <div className="col m12">{text}</div>
      </div>
    </Link>
  );
};
