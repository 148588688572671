import React from 'react';

type CallParams = {
  data: any;
};

const DeviceRow = (props: CallParams) => {
  const requestTypeIndicator = props.data.isEmergency ? '!' : '';
  // let participantList = "";

  // for (var i = 0; i < this.props.Participants.length; i++){
  //    //console.log("Participante", this.props.Participants[i]);
  //    let callerName = this.props.Participants[i].Description;
  //    if (callerName.includes(this.props.PatientLabel + ":")){
  //     continue;
  //    }
  //    if (this.props.Class.ID){
  //     //callerName = callerName.replace("Concierge:", "");
  //     //callerName = callerName.replace(" ", "");
  //    }
  //    participantList = participantList + callerName + ", ";
  // }
  // participantList = participantList.substring(0, participantList.length - 2);

  // let callType = "Regular";
  // if (this.props.Class.ID){
  //     callType = "Class Session";
  // }
  // else if (this.props.RequestID){
  //     callType = "Requested";
  // }
  const callLogStatusColor = props.data.wasAnswered ? '' : 'txt-red';

  const participantList = props.data.participantsText;

  return (
    <tr>
      <td className="nucleus-row">{props.data.startTime}</td>
      <td className={'nucleus-row left-align '}>{props.data.callType}</td>
      <td className={'nucleus-row left-align ' + callLogStatusColor}>{props.data.status}</td>
      <td className="nucleus-row left-align">{props.data.duration}</td>
      <td className="nucleus-row left-align">{participantList}</td>
      <td className="nucleus-row center-align">
        <span className={'txt-red nucleus-font-family-big'}>
          <b>{requestTypeIndicator}</b>
        </span>
      </td>
    </tr>
  );
};

export default DeviceRow;
