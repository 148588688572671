export const Chevron = () => {
  return (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        id="Vector"
        d="M8.23519 7.00521L2.44964 12.7907C2.17061 13.0698 1.71823 13.0698 1.43923 12.7907L0.764442 12.1159C0.485888 11.8374 0.485351 11.3859 0.763251 11.1067L5.3484 6.49999L0.763251 1.89328C0.485351 1.61407 0.485888 1.16262 0.764442 0.884062L1.43923 0.209273C1.71826 -0.0697575 2.17064 -0.0697575 2.44964 0.209273L8.23519 5.99476C8.51419 6.2738 8.51419 6.72617 8.23519 7.00521Z"
        fill="#0092FF"
      />
    </svg>
  );
};
