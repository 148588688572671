import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UICard, UINucleusContainer } from '../../../components/UI';
import { UINucleusContentContainer } from '../../../components/UI/NucleusContainer/Content';
import { useUserMeSelector } from '../../../selectors/user';
import { ProviderCallRequest } from '../Queue/queue.types';

const InfoLabelContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;
const InfoLabel = styled.span`
  display: flex;
  flex: 2;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
`;
const InfoValue = styled.span`
  display: flex;
  flex: 3;
`;
const InfoField = ({ label = '', value = '' }: { label: string; value: string }) => {
  return (
    <InfoLabelContainer>
      <InfoLabel>{label}:</InfoLabel>
      <InfoValue>{value}</InfoValue>
    </InfoLabelContainer>
  );
};
const InfoSubtitleLine = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  margin-bottom: 5px;
  padding-left: 5px;
`;

const MarkCallAsHandled = ({ markAsHandledData, setHandledCallNotes }: { setHandledCallNotes: (notes: string) => void; markAsHandledData: ProviderCallRequest | null }) => {
  const user = useUserMeSelector();
  const [notes, setNotes] = useState('');
  useEffect(() => {
    if (setHandledCallNotes) {
      setHandledCallNotes(notes);
    }
  }, [notes]);
  return (
    <UINucleusContainer style={{ overflow: 'none', maxHeight: '80vh' }}>
      <UINucleusContentContainer style={{ paddingBottom: 20 }}>
        <UICard
          style={{
            width: '100%',
            backgroundColor: '#FFFFFF',
            marginBottom: 30,
          }}
        >
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              gap: 15,
            }}
          >
            <InfoField label="Patient Name" value={markAsHandledData?.Name || ''} />
            <InfoField label="First Requested" value={markAsHandledData?.RequestTime ? moment(markAsHandledData.RequestTime).format('MM/DD/YYYY hh:mm A zz') : ''} />
            {/* <InfoField
                            label="Urgent Call"
                            value={'N'}
                        /> */}
            <InfoField label="Escalated" value={markAsHandledData?.Escalated ? 'Y' : 'N'} />
            <InfoField label="Requests" value={markAsHandledData?.NumOfRequests?.toString() || ''} />
            <InfoField label="Marked as handled by" value={user.FirstName + ' ' + user.LastName} />
          </div>
        </UICard>
        <InfoSubtitleLine>
          <InfoLabel>{'Notes'}:</InfoLabel>
        </InfoSubtitleLine>
        <UICard
          style={{
            display: 'flex',
            width: '100%',
            backgroundColor: '#FFFFFF',
          }}
        >
          <textarea
            style={{
              fontFamily: 'Barlow',
              width: '100%',
              minHeight: 250,
              resize: 'none',
              border: 'none',
              outline: 'none',
              boxSizing: 'border-box',
            }}
            placeholder="Empty"
            value={notes}
            onChange={e => setNotes(e.target.value)}
          />
        </UICard>
      </UINucleusContentContainer>
    </UINucleusContainer>
  );
};

export default MarkCallAsHandled;
