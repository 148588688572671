import { ReactNode, useEffect, FC, MouseEvent } from 'react';
import styled from 'styled-components';
import CloseIcon from '../assets/closeIcon.svg';
import ActionButtons, { ModalAction } from './ActionButtons';

type Size = 'small' | 'medium' | 'mediumLarge' | 'large';

export type ModalProps = {
  isOpen: boolean;
  close: () => void;
  children: ReactNode;
  title?: string;
  actions?: ModalAction[];
  size?: Size;
  dismissable?: boolean;
};

const SIZES = {
  small: '385px',
  medium: '500px',
  mediumLarge: '620px',
  large: '800px',
};

const Base: FC<ModalProps> = ({ isOpen, close, children, title, actions, size = 'medium', dismissable = true }) => {
  const handleBackgroundClick = (e: MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget && dismissable) {
      close();
    }
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && dismissable) {
        close();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [close]);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalBackdrop onClick={handleBackgroundClick}>
      <ModalContainer
        onClick={e => e.stopPropagation()}
        style={{
          width: SIZES[size],
        }}
      >
        {dismissable && (
          <CloseContainer onClick={close}>
            <img src={CloseIcon} alt="Close" />
          </CloseContainer>
        )}
        {title && <ModalHeader>{title}</ModalHeader>}
        {children}
        <ActionButtons actions={actions} />
      </ModalContainer>
    </ModalBackdrop>
  );
};

export default Base;

const ModalBackdrop = styled.div`
  display: flex;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px 20px;
  border-radius: 10px;
  background: #f3f4f5;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  position: relative;
`;

const ModalHeader = styled.p`
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  font-family: Barlow;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;
