import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const NavigationListener = ({ onNavigationLocationChange }) => {
  const location = useLocation();
  const previousLocation = useRef(location);

  useEffect(() => {
    if (onNavigationLocationChange) {
      onNavigationLocationChange(location, previousLocation.current);
    }
    previousLocation.current = location;
  }, [location, onNavigationLocationChange]);

  return null;
};

export default NavigationListener;
