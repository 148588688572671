import { httpApi } from '@nucleus-care/nucleuscare-backend-client';

type RemoveUserPatient = {
  patientId: string;
  userId: string;
  cb?: ({ success, message }: { success: boolean; message: string }) => void;
};
type EditAssignmentUserPatient = {
  userId: string;
  newAssignments: { Assigned: boolean; PatientID: string }[];
  cb?: ({ success, message }: { success: boolean; message: string }) => void;
};
type FetchPatientsParams = {
  page: number;
  limit: number;
  userId: string;
  cb?: ({ success, message, response }: { success: boolean; message: string; response: unknown }) => void;
};

export const removeUserPatient = ({ patientId, userId, cb }: RemoveUserPatient) => {
  if (!cb) {
    console.warn('Please provide callback function');
    return;
  }
  if (!patientId) {
    cb({
      success: false,
      message: 'Please select at least one client',
    });
    return;
  }
  if (!userId) {
    cb({
      success: false,
      message: 'User not selected',
    });
    return;
  }

  httpApi
    .delete(`/user/${userId}/patientsAssignment`, { data: { patientId } })
    .then(() => {
      cb({
        success: true,
        message: 'Patient removed successfully.',
      });
    })
    .catch(() => {
      cb({
        success: false,
        message: 'Error while removing patient.',
      });
    });
};

export const editUserAssignment = ({ newAssignments, userId, cb }: EditAssignmentUserPatient) => {
  if (!cb) {
    console.warn('Please provide callback function');
    return;
  }
  httpApi
    .patch(`/user/${userId}/patientsAssignment`, newAssignments)
    .then(() => {
      cb({ success: true, message: 'User assignment updated successfully' });
    })
    .catch(() => {
      cb({ success: false, message: 'Error while updating user assignment' });
    });
};

export const fetchAvailablePatients = ({ page = 1, limit = 2000, userId, cb }: FetchPatientsParams) => {
  if (!cb) {
    console.warn('Please provide callback function');
    return;
  }
  httpApi
    .get(`/user/${userId}/availablePatients?page=${page}&limit=${limit}`)
    .then(({ data }) => {
      cb({ success: true, message: 'Available patients fetched successfully', response: data });
    })
    .catch(() => {
      cb({ success: false, message: 'Error while fetching available patients', response: [] });
    });
};
