import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  getUsersWithClassroomAccess,
  getClassSessions,
  createClassSession,
  updateClassSession,
  startClassSession,
  cancelClassSession,
  sessionSliceClean,
  getClassSessionPatient,
  getClassSessionPatientDevice,
  classsSessionPatientSliceClean,
} from '@nucleus-care/nucleuscare-backend-client';
import store from '@nucleus-care/nucleuscare-backend-client';
import { startCall } from '@nucleus-care/nucleuscare-backend-client';
import { WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { RootState } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/store';
import { CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import { CallingMethods } from 'components/WebRTCCallView';
import moment from 'moment';
import { useState, useEffect, useRef } from 'react';

import Spinner from 'react-activity/dist/Spinner';
import {
  useSelector,
  useDispatch,
  //DefaultRootState
} from 'react-redux';

import { JQuery } from 'types/jquery.types';
import AuthStore from '../../stores/AuthStore';
import GeneralStore from '../../stores/GeneralStore';
import MessagesStore from '../../stores/MessagesStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';

import VideoSessionModalInfo from './VideoSessionModalInfo';
import VideoSessionTable from './VideoSessionsTable';

const VideoSession = () => {
  const accountConfig = useSelector((state: RootState) => {
    return state.accountClassSessionConfig.config;
  });

  const [stateLoading, setStateLoading] = useState(false);

  const [stateControlTimezone, setStateControlTimezone] = useState('');
  const [stateFilterTimezone, setStateFilterTimezone] = useState('');

  const timeOptions = useRef({});
  timeOptions.current = { hour12: true, hour: '2-digit', minute: '2-digit' };
  const dateOptions = useRef({});
  dateOptions.current = { day: '2-digit', month: '2-digit', year: 'numeric' };

  const [stateModalTitle, setStateModalTitle] = useState('New');
  const [stateClassInitiated, setStateClassInitiated] = useState(false);
  const [stateClassEditLocked, setStateClassEditLocked] = useState(false);
  const [stateSelectUserWarning, setStateSelectUserWarning] = useState(false);
  const [stateClassNameWarning, setStateClassNameWarning] = useState(false);
  const [stateClassStartTimeWarning, setStateClassStartTimeWarning] = useState(false);
  const [stateClassEndTimeWarning, setStateClassEndTimeWarning] = useState(false);
  const [stateClassTimesWarning, setStateClassTimesWarning] = useState(false);
  const [stateClassParticipantsWarning, setStateParticipantsWarning] = useState(false);

  const [statePatientsList, setStatePatientsList] = useState([]);
  const [stateAutocompletePatientsList, setStateAutocompletePatientsList] = useState([]);

  const [stateClassID, setStateClassID] = useState('');
  const [stateClassUser, setStateClassUser] = useState('');
  const [stateClassUserName, setStateClassUserName] = useState('');
  const [stateClassName, setStateClassName] = useState('');

  const [stateClassStartTime, setStateClassStartTime] = useState<Date>();
  const [stateClassEndTime, setStateClassEndTime] = useState<Date>();

  const [stateClassAutoAnswer, setStateClassAutoAnswer] = useState(false);
  const [stateClassParticipants, setStateClassParticipants] = useState([]);
  const [stateClassParticipantCounter, setStateClassParticipantsCounter] = useState(0);

  const [stateSearchText, setStateSearchText] = useState('');
  const [stateSaveButtonStyle, setStateSaveButtonStyle] = useState('nucleus-submit-btn');

  const [stateClassConflictText, setStateClassConflictText] = useState('');

  const [stateProcessPatients, setStateProcessPatients] = useState(true);

  const dispatch = useDispatch();

  const [stateFromDate, setStateFromDate] = useState(null);
  const [stateToDate, setStateToDate] = useState(null);

  //const startDateTimeRef = useRef();
  //const endDateTimeRef = useRef();

  const txtPatientsInput = useRef(null);

  // First UseEffect
  useEffect(() => {
    console.log('VideoSession useEffect');

    validateAccessGranted('/videoSession');

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      //this.timeFormat = "hh:mm A";
      timeOptions.current = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      //this.timeFormat = "HH:mm A";
      timeOptions.current = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }

    const currentDate = new Date();
    const fromDate = new Date();
    const toDate = new Date();
    const toDateTime = new Date();
    //fromDate.setDate(fromDate.getDate() - 7);
    toDate.setDate(toDate.getDate() + 7);
    toDateTime.setDate(toDateTime.getDate() + 7);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('TIMEZONE:', currentTimezone);
    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);

    let formatedTimezone = '' + currentTimezone;
    if (currentTimezone > 0) {
      formatedTimezone = '+' + currentTimezone;
    }
    setStateControlTimezone(formatedTimezone);
    setStateFilterTimezone(formatedTimezone);
    console.log('FORMATED TIMEZONE:', formatedTimezone);

    ($('#modalNewClass') as unknown as JQuery).modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    ($('#modalCancelClass') as unknown as JQuery).modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    ($('#modalStartClass') as unknown as JQuery).modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    ($('#modalBusy') as unknown as JQuery).modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    setStateFromDate(currentDate);
    setStateToDate(toDateTime);

    // try {
    //     $('#endDateTime').datetimepicker({
    //         format:stateDatetimeFormat,
    //         minDate: '-1d', //(new Date(startDateTimeRef.current.value)).setDate(new Date(startDateTimeRef.current.value).getDate() + 1),
    //         step: 15,
    //         //validateOnBlur : true,
    //         closeOnWithoutClick: true,
    //         closeOnInputClick: true,
    //         closeOnDateSelect :true,
    //         opened: false,
    //         openOnFocus: true,
    //         onChangeDateTime : (value)=>{
    //             let _value = moment(new Date(value)).format("MM/DD/YYYY hh:mm A")
    //             console.log("Date changed: endDateTime ", _value);
    //             setStateClassEndTime(_value);
    //             endDateTimeRef.current.value = _value;
    //             setStateClassEndTimeWarning(false);
    //             setStateClassTimesWarning(false);
    //         }
    //     });
    // } catch (error) {
    //     console.error("Nucleus datetimepicker error 3 ->", error)
    // }
    console.log('AuthStore.getUserAccountID()', AuthStore.getUserAccountID());
    dispatch(getUsersWithClassroomAccess(AuthStore.getUserAccountID()));

    fromDate.setHours(fromDate.getHours() + Number(currentTimezone) * -1);
    toDate.setHours(toDate.getHours() + Number(currentTimezone) * -1);
    const format1 = 'YYYY-MM-DD HH:mm:ss'; // Node Backend Date format Expected
    const fromDateParam = moment(fromDate).format(format1);
    const toDateParam = moment(toDate).format(format1);

    MessagesStore.getAutocompletePatientsList2(
      {
        UserID: AuthStore.getUserID(),
      },
      onAutoCompletePatientsResponse,
    );

    setStateLoading(true);
    dispatch(
      getClassSessions({
        startDate: fromDateParam,
        endDate: toDateParam,
        accountId: AuthStore.getUserAccountID(),
      }),
    );
  }, []);

  const classSessionUsers = useSelector((state: RootState) => {
    return state.classSessionUsers.data;
  });

  const classSessions = useSelector((state: RootState) => {
    return state.classSession.sessions;
  });

  const classSession = useSelector((state: RootState) => {
    return state.classSession.session;
  });

  const startSession = useSelector((state: RootState) => {
    return state.classSession.startSession;
  });

  const cancelSession = useSelector((state: RootState) => {
    return state.classSession.cancelSession;
  });

  const classSessionPatients = useSelector((state: RootState) => {
    return state.classSessionPatient.data;
  });

  useEffect(() => {
    console.log('useEffect classSessions', classSessions);
    if (!classSessions.pending) {
      setStateLoading(false);
      if (classSessions.complete) {
        //Message.show(`${accountConfig.TabNameLabelPlural} loaded.`);
        //setStateReportRendered(false);
      } else if (classSessions.error) {
        Message.show(`Error loading ${accountConfig.TabNameLabelPlural}`);
      }
    }
  }, [classSessions]);

  useEffect(() => {
    console.log('useEffect classSession', classSession);

    if (!classSession.saving) {
      if (classSession.saved) {
        dispatch(sessionSliceClean({}));
        if (stateModalTitle == 'New') Message.show(`${accountConfig.TabNameLabel} saved`);
        if (stateModalTitle == 'Edit') Message.show(`${accountConfig.TabNameLabel} updated`);
        ($('#modalNewClass') as unknown as JQuery).modal('close');
        onSubmitFilters();
      } else if (classSession.error) {
        if (classSession.participantsBusy) {
          setStateSaveButtonStyle('nucleus-submit-btn');

          ($('#modalBusy') as unknown as JQuery).modal('open');

          if (classSession.participantsBusy && classSession.participants && classSession.participants.length > 0) {
            if (classSession.participants.length == 1) {
              setStateClassConflictText('The next participant has a ' + accountConfig.TabNameLabel.toLowerCase() + ' conflict:');
            } else if (classSession.participants.length > 1) {
              setStateClassConflictText('The next participants have ' + accountConfig.TabNameLabel.toLowerCase() + ' conflicts:');
            }
          }
        } else {
          const errorMessage = stateModalTitle == 'New' ? ' creating ' : ' updating ';
          Message.show('Error ' + errorMessage + accountConfig.TabNameLabel);
          ($('#modalNewClass') as unknown as JQuery).modal('close');
        }
        setStateLoading(false);
      }
    }
  }, [classSession]);

  useEffect(() => {
    console.log('useEffect classSessionPatients', classSessionPatients);

    if (classSessionPatients.complete && !classSessionPatients.pending) {
      dispatch(classsSessionPatientSliceClean({}));
      onLoadSessionPatients();
    }
  }, [classSessionPatients]);

  useEffect(() => {
    console.log('useEffect startSession', startSession);

    if (!startSession.saving) {
      if (startSession.saved) {
        ($('#modalStartClass') as unknown as JQuery).modal('close');
        Message.show(`${accountConfig.TabNameLabel} started!`);
        onSubmitFilters();
      } else if (startSession.error) {
        Message.show(`Error starting ${accountConfig.TabNameLabel} : ` + startSession.error);
      }
      dispatch(sessionSliceClean({}));
    }
  }, [startSession]);

  useEffect(() => {
    console.log('useEffect cancelSession', cancelSession);

    if (!cancelSession.saving) {
      if (cancelSession.saved) {
        ($('#modalCancelClass') as unknown as JQuery).modal('close');
        ($('#modalNewClass') as unknown as JQuery).modal('close');
        Message.show(`${accountConfig.TabNameLabel} cancelled`);
        onSubmitFilters();
      } else if (cancelSession.error) {
        Message.show(`Error upddating ${accountConfig.TabNameLabel} : ` + cancelSession.error);
      }
      dispatch(sessionSliceClean({}));
    }
  }, [cancelSession]);

  const onAutoCompletePatientsResponse = response => {
    console.log('onAutoCompletePatientsResponse', response);

    if (response.ok) {
      let accountConfigData = accountConfig;
      try {
        accountConfigData = store.getState().accountClassSessionConfig.config;
      } catch (error) {
        console.log('P.configData', accountConfigData);
      }

      const messageAutocompletePatients: any = {};
      const messageAutocompletePatientIDs: any = {};
      let patientNameKey = '';
      Object.keys(response.autocompleteOptions.data).forEach(function (key) {
        // key: the name of the object key
        // index: the ordinal position of the key within the object
        patientNameKey = key.trim();
        //console.log("Having >"+patientNameKey+"<");
        messageAutocompletePatientIDs[patientNameKey] = response.autocompleteOptions.data[key];
        messageAutocompletePatients[patientNameKey] = null;
      });
      console.log('Array 1', messageAutocompletePatientIDs);
      console.log('Array 2', messageAutocompletePatients);
      setStatePatientsList(messageAutocompletePatientIDs);
      setStateAutocompletePatientsList(messageAutocompletePatients);

      // this.setState({
      //     messagePatientsList : messageAutocompletePatientIDs,
      //     messageAutocompletePatientsList : messageAutocompletePatients
      // });
      ($('.chips') as unknown as JQuery).material_chip();

      ($('.chips') as unknown as JQuery).material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: messageAutocompletePatients, //stateAutocompletePatientsList,
          limit: Infinity,
        },
        selectable: false,
      });
      // $(".chips").click(()=>{
      //     // this.setState({
      //     //     messagePatientsChipsVisible : ' hide'
      //     // });
      // });

      // Event for when a Patient Chip is Selected
      $('.chips').on('chip.add', () => {
        //console.log("Selected >"+chip.tag+"<");
        //console.log("Patients available: ", messageAutocompletePatientIDs);
        //console.log("Available Data: ",messageAutocompletePatients );
        if (accountConfigData.MaxParticipants == undefined) {
          let accountConfigData = accountConfig;
          try {
            accountConfigData = store.getState().accountClassSessionConfig.config;
            console.log('P.configData', accountConfigData);
          } catch (error) {
            console.log('P.configData', accountConfigData);
          }
        }

        const existingPatients = [];
        const existingData = ($('.chips') as unknown as JQuery).material_chip('data');
        let patientExist = false;

        const newAvailableNames = Object.assign({}, messageAutocompletePatients);

        for (let i = 0; i < existingData.length; i++) {
          patientExist = messageAutocompletePatientIDs[existingData[i].tag];
          console.log('ItIs ', patientExist, existingData[i]);
          if (patientExist) {
            existingPatients.push(existingData[i]);
            delete newAvailableNames[existingData[i].tag];
          }
        }

        ($('.chips') as unknown as JQuery).material_chip({
          data: existingPatients,
          placeholder: 'Type a name',
          secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: newAvailableNames, //messageAutocompletePatients, stateAutocompletePatientsList,
            limit: accountConfigData.MaxParticipants,
          },
        });

        //console.log(">>Are ", existingPatients.length, " de ", accountConfigData.MaxParticipants);
        if (existingPatients.length == accountConfigData.MaxParticipants) {
          console.log('Limit!!!!');

          ($('.chips') as unknown as JQuery).material_chip({
            data: existingPatients,
            placeholder: 'Type a name',
            secondaryPlaceholder: 'Type another name',
            autocompleteOptions: {
              data: [], //messageAutocompletePatients, stateAutocompletePatientsList,
              limit: accountConfigData.MaxParticipants,
            },
          });
        } else {
          txtPatientsInput.current.lastChild.autofocus = true;
        }
        setStateClassParticipantsCounter(existingPatients.length);
        setStateParticipantsWarning(false);
      });

      $('.chips').on('chip.delete', (e, chip) => {
        console.log('Deleting', e, chip);

        const existingPatients = [];
        const existingData = ($('.chips') as unknown as JQuery).material_chip('data');
        let patientExist = false;

        const newAvailableNames = Object.assign({}, messageAutocompletePatients);
        for (let i = 0; i < existingData.length; i++) {
          patientExist = messageAutocompletePatientIDs[existingData[i].tag];
          //console.log("There's ", patientExist, existingData[i]);
          if (patientExist) {
            existingPatients.push(existingData[i]);
            delete newAvailableNames[existingData[i].tag];
          }
        }
        if (existingPatients.length != accountConfigData.MaxParticipants) {
          console.log('Working back!!!!');
          ($('.chips') as unknown as JQuery).material_chip({
            data: existingPatients,
            placeholder: 'Type a name',
            secondaryPlaceholder: 'Type another name',
            autocompleteOptions: {
              data: newAvailableNames, //stateAutocompletePatientsList,
              limit: accountConfigData.MaxParticipants,
            },
          });
        } else {
          ($('.chips') as unknown as JQuery).material_chip({
            data: existingPatients,
            placeholder: 'Type a name',
            secondaryPlaceholder: 'Type another name',
            autocompleteOptions: {
              data: newAvailableNames, //stateAutocompletePatientsList,
              limit: accountConfigData.MaxParticipants,
            },
          });
        }
        setStateClassParticipantsCounter(existingPatients.length);
      });

      $('.chips').on('chip.select', () => {
        //console.log("Aqui seleccionando", e, chip);
        return false;
      });
    }
  };

  const [stateDatesErrorVisibility, setStateDatesErrorVisibility] = useState(false);
  const [stateDatesErrorText] = useState('The From Date has to be before the To Date.');

  const onTimeZoneChange = e => {
    //console.log("onTimeZoneChange", e.target.value);
    //setStateControlTimezone(e.target.value);
    setStateFilterTimezone(e.target.value);
  };

  const onFromDateChange = (date, value) => {
    console.log('onFromDateChange', date, value);
    setStateDatesErrorVisibility(false);
    setStateFromDate(value);
    //refreshReport(value, stateToDate);
  };
  const onToDateChange = (date, value) => {
    console.log('onToDateChange', date, value);
    setStateDatesErrorVisibility(false);
    setStateToDate(value);
    //refreshReport(stateFromDate, value);
  };

  const onSubmitFilters = () => {
    console.log('onSubmitFilters');

    const fromDate = new Date(stateFromDate);
    const toDate = new Date(stateToDate);
    fromDate.setHours(fromDate.getHours() + Number(stateFilterTimezone) * -1);
    toDate.setHours(toDate.getHours() + Number(stateFilterTimezone) * -1);
    if (toDate < fromDate) {
      setStateDatesErrorVisibility(true);
    }
    const format1 = 'YYYY-MM-DD HH:mm:ss';
    const fromDateParam = moment(fromDate).format(format1);
    const toDateParam = moment(toDate).format(format1);
    console.log('PARAM 1', fromDateParam);
    console.log('PARAM 2', toDateParam);
    // console.log("ACCOUNTID", AuthStore.getUserAccountID());
    setStateLoading(true);
    dispatch(
      getClassSessions({
        startDate: fromDateParam,
        endDate: toDateParam,
        accountId: AuthStore.getUserAccountID(),
      }),
    );
  };

  const onStartDateChange = (date, value) => {
    console.log('onStartDateChange', date, value);
    setStateClassStartTime(value);
    const endDate = new Date(value);
    endDate.setHours(endDate.getHours() + 1);
    setStateClassEndTime(endDate);
    setStateClassStartTimeWarning(false);
    setStateClassTimesWarning(false);
  };
  const onEndDateChange = (date, value) => {
    console.log('onEndDateChange', date, value);
    setStateClassEndTime(value);
    setStateClassEndTimeWarning(false);
    setStateClassTimesWarning(false);
  };

  const loadNewClassView = () => {
    console.log('loadNewClassView');

    setStateModalTitle('New');
    setStateClassUser('');
    setStateClassName('');

    dispatch(getUsersWithClassroomAccess(AuthStore.getUserAccountID()));

    const startDateTime = new Date();
    const endDateTime = new Date();
    endDateTime.setHours(endDateTime.getHours() + 1);
    setStateClassStartTime(startDateTime);
    setStateClassEndTime(endDateTime);

    //setStateClassStartTime("");
    //setStateClassEndTime("");
    setStateClassAutoAnswer(false);
    setStateClassParticipants([]);
    setStateClassParticipantsCounter(0);

    setStateSaveButtonStyle('nucleus-submit-btn');

    setStateSelectUserWarning(false);
    setStateClassNameWarning(false);
    setStateClassStartTimeWarning(false);
    setStateClassEndTimeWarning(false);
    setStateClassTimesWarning(false);
    setStateParticipantsWarning(false);

    ($('.chips') as unknown as JQuery).material_chip({
      data: [],
      placeholder: 'Type a name',
      secondaryPlaceholder: 'Type another name',
      autocompleteOptions: {
        data: stateAutocompletePatientsList,
        limit: Infinity,
      },
    });
    ($('#modalNewClass') as unknown as JQuery).modal('open');
  };

  const handleCloseModal = () => {
    console.log('handleCloseModal');
    ($('#modalNewClass') as unknown as JQuery).modal('close');
  };

  const submitNewClass = () => {
    console.log('submitNewClass');
    let status = 'ok';
    if (stateClassUser == '') {
      setStateSelectUserWarning(true);
      status = 'no';
    }
    if (stateClassName == '') {
      setStateClassNameWarning(true);
      status = 'no';
    }
    console.log('TODO:');

    if (!stateClassStartTime) {
      setStateClassStartTimeWarning(true);
      status = 'no';
    }
    if (!stateClassEndTime) {
      setStateClassEndTimeWarning(true);
      status = 'no';
    }
    if (status == 'no') {
      return;
    }

    const startUtcTime = new Date(stateClassStartTime);
    const endUtcTime = new Date(stateClassEndTime);

    if (startUtcTime >= endUtcTime) {
      setStateClassTimesWarning(true);
      return;
    }

    if (stateModalTitle == 'New') {
      const chipsObjectValue = ($('.chips') as unknown as JQuery).material_chip('data');
      console.log(chipsObjectValue);

      const IDsArray = [];
      $.each(chipsObjectValue, (key, value) => {
        //console.log("Selected", value);
        //console.log("SelectedDE", this.state.messagePatientsList);
        let patientID = null;
        patientID = statePatientsList[value.tag];
        IDsArray.push(patientID);
        // if (IDsArray.length > 0){
        //     IDsArray = IDsArray + ",";
        // }
        // IDsArray = IDsArray + patientID ;
        //console.log("The PatientID es: ", patientID);
      });
      console.log('Los Patients IDS son', IDsArray);
      if (IDsArray.length == 0) {
        setStateParticipantsWarning(true);
        return;
      }
      // let startUtcTime = new Date(stateClassStartTime);
      // let endUtcTime = new Date(stateClassEndTime);
      startUtcTime.setHours(startUtcTime.getHours() + Number(stateControlTimezone) * -1);
      endUtcTime.setHours(endUtcTime.getHours() + Number(stateControlTimezone) * -1);

      const format1 = 'YYYY-MM-DD HH:mm:ss';
      const startUtcTimeParam = moment(startUtcTime).format(format1);
      const endUtcTimeParam = moment(endUtcTime).format(format1);
      // console.log("PARAM 1", startUtcTimeParam);
      // console.log("PARAM 2", endUtcTimeParam);
      // console.log("ACCOUNTID", AuthStore.getUserAccountID());

      //AuthStore.getUserID();
      setStateSaveButtonStyle('nucleus-submit-btn-disabled');
      setStateLoading(true);
      dispatch(
        createClassSession({
          Name: stateClassName,
          UtcStartTime: startUtcTimeParam,
          UtcEndTime: endUtcTimeParam,
          UserID: stateClassUser,
          AccountID: AuthStore.getUserAccountID(),
          ShouldAutoAnswer: stateClassAutoAnswer,
          Participants: IDsArray,
        }),
      );
    } else if (stateModalTitle == 'Edit') {
      const chipsObjectValue = ($('.chips') as unknown as JQuery).material_chip('data');

      console.log(chipsObjectValue);

      const IDsArray = [];
      $.each(chipsObjectValue, (key, value) => {
        //console.log("Selected", value);
        //console.log("SelectedDE", statePatientsList);
        let patientID = null;
        patientID = statePatientsList[value.tag];
        if (patientID) {
          IDsArray.push(patientID);
        }
        // if (IDsArray.length > 0){
        //     IDsArray = IDsArray + ",";
        // }
        // IDsArray = IDsArray + patientID ;
        //console.log("The PatientID es: ", patientID);
      });

      if (IDsArray.length == 0) {
        setStateParticipantsWarning(true);
        return;
      }
      // let startUtcTime = new Date(stateClassStartTime);
      // let endUtcTime = new Date(stateClassEndTime);
      startUtcTime.setHours(startUtcTime.getHours() + Number(stateControlTimezone) * -1);
      endUtcTime.setHours(endUtcTime.getHours() + Number(stateControlTimezone) * -1);

      const format1 = 'YYYY-MM-DD HH:mm';
      const startUtcTimeParam = moment(startUtcTime).format(format1);
      const endUtcTimeParam = moment(endUtcTime).format(format1);
      // console.log("PARAM 1", startUtcTimeParam);
      // console.log("PARAM 2", endUtcTimeParam);
      // console.log("ACCOUNTID", AuthStore.getUserAccountID());

      //AuthStore.getUserID();
      setStateSaveButtonStyle('nucleus-submit-btn-disabled');
      setStateLoading(true);
      dispatch(
        updateClassSession({
          ID: stateClassID,
          Name: stateClassName,
          UtcStartTime: startUtcTimeParam,
          UtcEndTime: endUtcTimeParam,
          UserID: stateClassUser,
          //AccountID: AuthStore.getUserAccountID(),
          ShouldAutoAnswer: stateClassAutoAnswer,
          Participants: IDsArray,
        }),
      );
    }
  };

  const handleEditClass = classData => {
    console.log('handleEditClass', classData);

    dispatch(getUsersWithClassroomAccess(AuthStore.getUserAccountID()));

    setStateModalTitle('Edit');
    setStateProcessPatients(true);

    setStateClassID(classData.ID);
    setStateClassUser(classData.UserID);
    setStateClassName(classData.Name);
    setStateClassStartTime(moment(classData.UtcStartTime).toDate());
    setStateClassEndTime(moment(classData.UtcEndTime).toDate());
    // startDateTimeRef.current.value = moment(classData.UtcStartTime).format("MM/DD/YYYY hh:mm A");
    // endDateTimeRef.current.value = moment(classData.UtcEndTime).format("MM/DD/YYYY hh:mm A");
    // // $('#startDateTime').datetimepicker({
    // //     minDate: new Date(startDateTimeRef.current.value)
    // // });
    setStateClassAutoAnswer(classData.ShouldAutoAnswer);
    setStateClassParticipants([]);
    setStateClassParticipantsCounter(classData.TotalParticipants);

    setStateSaveButtonStyle('nucleus-submit-btn');

    setStateSelectUserWarning(false);
    setStateClassNameWarning(false);
    setStateClassStartTimeWarning(false);
    setStateClassEndTimeWarning(false);
    setStateClassTimesWarning(false);
    setStateParticipantsWarning(false);

    let lockedEditClass = false;
    try {
      if (accountConfig.MinMinutesToEdit > 0) {
        const now = new Date();
        const minClassDate = new Date(classData.UtcStartTime);
        //minClassDate.setHours(minClassDate.getHours() + classData.GMTOffset);
        minClassDate.setMinutes(minClassDate.getMinutes() + accountConfig.MinMinutesToEdit * -1);
        console.log('minClass', minClassDate);
        if (now >= minClassDate) {
          console.log('Sorry but you cannot Edit this class');
          lockedEditClass = true;
          setStateSaveButtonStyle('nucleus-submit-btn-disabled');
        }
      }
    } catch (error) {
      console.error('Error checking if the class can be edited', error);
    }
    setStateClassEditLocked(lockedEditClass);
    if (classData.UtcActualStartTime) {
      setStateClassInitiated(true);
      setStateSaveButtonStyle('nucleus-submit-btn-disabled');
    } else {
      setStateClassInitiated(false);
    }
    dispatch(getClassSessionPatient(classData.ID));
    ($('#modalNewClass') as unknown as JQuery).modal('open');
  };

  const handleStartClass = classData => {
    console.log('handleStartClass', classData);
    // if (classData.UtcActualStartTime){
    //     Message.show(accountConfig.TabNameLabel + " has already started")
    // }
    dispatch(getClassSessionPatientDevice(classData.ID));
    setStateProcessPatients(false);

    setStateClassID(classData.ID);
    setStateClassUser(classData.UserID);
    setStateClassUserName(classData.UserFirstName + ' ' + classData.UserLastName);
    setStateClassName(classData.Name);
    setStateClassStartTime(moment(classData.UtcStartTime).toDate());
    setStateClassEndTime(moment(classData.UtcEndTime).toDate());

    setStateClassAutoAnswer(classData.ShouldAutoAnswer);

    if (classData.UtcActualStartTime) {
      setStateClassInitiated(true);
    } else {
      setStateClassInitiated(false);
    }

    setStateSaveButtonStyle('nucleus-submit-btn-disabled');
    ($('#modalStartClass') as unknown as JQuery).modal('open');
  };

  const submitStartClass = () => {
    console.log('submitStartClass');

    if (!stateClassInitiated) {
      const currentStartUtcTime = new Date();
      currentStartUtcTime.setHours(currentStartUtcTime.getHours() + Number(stateControlTimezone) * -1);

      const format1 = 'YYYY-MM-DD HH:mm';
      const currentStartUtcTimeParam = moment(currentStartUtcTime).format(format1);

      console.log('PARAM 1', currentStartUtcTimeParam);
      //setStateSaveButtonStyle("nucleus-submit-btn-disabled");
      dispatch(
        startClassSession({
          ID: stateClassID,
          UtcActualStartTime: currentStartUtcTimeParam, //AuthStore.getUserID()
        }),
      );
    }

    console.log('stateClassParticipants>', stateClassParticipants);
    console.log('stateClassAutoAnswer>', stateClassAutoAnswer);
    const multiCallData: WebRTCCall = {
      //PatientID: "",
      //DeviceID: "",
      classParticipants: stateClassParticipants,
      type: stateClassAutoAnswer ? CallType.BargeIn : CallType.Video, //  : "Video" "SilentInstant"
      multiCall: true,
      patientFirstName: 'Starting',
      patientLastName: accountConfig.TabNameLabel,
      deviceName: stateClassName,

      callingMethod: CallingMethods.Call,

      patientThumb: '',
      requestId: '',
      classSessionId: stateClassID,
      timeoutInSeconds: 20,
      patientId: '',
      patientName: '',
      participants: stateClassParticipants,
    };

    dispatch(startCall(multiCallData));

    if (stateClassInitiated) {
      ($('#modalStartClass') as unknown as JQuery).modal('close');
    }
  };

  const submitCancelClass = () => {
    console.log('submitCancelClass');
    setStateSaveButtonStyle('nucleus-submit-btn-disabled');
    dispatch(
      cancelClassSession({
        ID: stateClassID,
        CancelledBy: AuthStore.getUserID(),
      }),
    );
  };

  const onLoadSessionPatients = () => {
    console.log('onLoadSessionPatients', classSessionPatients, stateProcessPatients);

    if (stateProcessPatients) {
      const patientsArray = [];
      Object.entries(classSessionPatients).forEach(([, patient]: [a: unknown, patient: any]) => {
        console.log('Le Patient', patient);

        if (patient.ID) {
          const p = {
            ID: patient.ID,
            Name: patient.FirstName + ' ' + patient.LastName,
          };
          patientsArray.push(p);
        }
        // let userName = session.UserFirstName + " " + session.UserLastName;
        // let searchTextValue = stateSearchText.toLowerCase();
        // if (
        //     searchTextValue.length == 0 ||
        //     (userName.toLowerCase().indexOf(searchTextValue) >= 0) ||
        //     (session.Name  && session.Name.toLowerCase().indexOf(searchTextValue) >= 0)
        // ){
        // availableSessions.push(
        //     <VideoSessionRow key={key} data={session} editClass={handleEditClass}></VideoSessionRow>
        // );
        // }
      });

      //console.log("AvailablePatients", stateAutocompletePatientsList);
      let newAvailableNames: any = { ...stateAutocompletePatientsList };

      const existingPatients = [];

      for (let i = 0; i < patientsArray.length; i++) {
        const patientChip = {};
        patientChip['tag'] = patientsArray[i].Name;
        existingPatients.push(patientChip);
        delete newAvailableNames[patientsArray[i].Name];
      }

      if (stateClassParticipantCounter == accountConfig.MaxParticipants) {
        newAvailableNames = {};
      }
      //console.log("New Available List", newAvailableNames);

      ($('.chips') as unknown as JQuery).material_chip({
        data: existingPatients,
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: newAvailableNames,
          limit: Infinity,
        },
      });
    } else {
      const patientsArray = [];

      if (classSessionPatients.patients) {
        classSessionPatients.patients.map(patient => {
          const p = {
            ID: patient.ID.toLowerCase(),
            Name: patient.FirstName + ' ' + patient.LastName,
            DeviceID: patient.DeviceID.toLowerCase(),
          };
          patientsArray.push(p); // 2 total, 1 participants
          // patientsArray.push(p); // 3 total, 2 participants
          // patientsArray.push(p); // 4 total, 3 participants
          // patientsArray.push(p); // 5 total, 4 participants
          // patientsArray.push(p); // 6 total, 5 participants
          // patientsArray.push(p); // 7 total, 6 participants
          // patientsArray.push(p); // 8 total, 7 participants
          // patientsArray.push(p); // 9 total, 8 participants
          // patientsArray.push(p); // 10 total, 9 participant
          // patientsArray.push(p); // 11 total, 10 participant
          // patientsArray.push(p); // 12 total, 11 participant
          // patientsArray.push(p); // 13 total, 12 participant
        });
      }
      setStateClassParticipants(patientsArray);
      setStateSaveButtonStyle('nucleus-submit-btn');
    }
  };

  const getTimezonesOptions = () => {
    const filterTimezonesArray = GeneralStore.getIsDST() ? GeneralStore.GetDaylightTimezones() : GeneralStore.GetStandardTimezones();
    const availableTimezonesArray = [];
    if (filterTimezonesArray != null && filterTimezonesArray.length && availableTimezonesArray.length == 0) {
      filterTimezonesArray.map((timezone, key) => {
        availableTimezonesArray.push(
          <option key={key} value={timezone.value}>
            {timezone.name}
          </option>,
        );
      });
    }
    return availableTimezonesArray;
  };

  const getUsersOptions = () => {
    console.log('getUsersOptions', classSessionUsers);
    const availableUsers = [];
    if (classSessionUsers != null && classSessionUsers.length) {
      classSessionUsers.map((user, key) => {
        availableUsers.push(
          <option key={key} value={user.ID}>
            {user.FirstName + ' ' + user.LastName}
          </option>,
        );
      });
    }
    return availableUsers;
  };

  const getConflictRows = () => {
    console.log('getConflictRows', classSession);

    const classConflicts = [];
    if (classSession.participantsBusy && classSession.participants && classSession.participants.length > 0) {
      classSession.participants.map((p, key) => {
        classConflicts.push(<VideoSessionModalInfo key={key} accountConfig={accountConfig} classSessionsData={p}></VideoSessionModalInfo>);
      });
    }
    return classConflicts;
  };

  return (
    <div className="nucleus-hard-container">
      <div className="nucleus-all-scrollable-page-medium no-margin">
        <div className="row no-margin">
          <div className="col m10 no-margin">
            <p className="nucleus-page-title">{accountConfig.TabNameLabelPlural}</p>
          </div>
          <div className="col m2 no-margin right-align " style={{ paddingTop: 15 }}>
            {stateLoading && <Spinner color="#2096F3" size={30} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />}
          </div>
        </div>
      </div>

      <div className="row no-margin">
        <div className="col s10 m8 no-margin left-align ">
          &nbsp;
          <img style={{ marginRight: -3, marginBottom: -4, height: 19 }} src="/img/search_icon_2.png" />
          <span className="nucleus-labels-height "> &nbsp; Search&nbsp;&nbsp; </span>
          <input
            id="testID"
            type="text"
            value={stateSearchText}
            className="nucleus-input-form-big-left width500"
            onChange={e => {
              console.log('SearchTextValue', e.target.value);
              setStateSearchText(e.target.value);
            }}
            placeholder={`${accountConfig.HostLabel} / ${accountConfig.TabNameLabel} Name `}
          />
        </div>
        <div className="col s2 m4 no-margin left-align "></div>
      </div>
      <br />
      <div className="row filters-row">
        &nbsp;
        <span className="nucleus-labels">
          <b>{` From `}</b>
        </span>
        &nbsp;
        {/* <input id="fromDateTime" ref={fromDateTimeRef} //value={stateFromDateTimeValue} type="text" className="validate nucleus-date-picker-short" autoComplete="off" //disabled={filtersBoxesMode}/> */}
        <div style={{ width: 240, display: 'inline-flex' }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              //ref={fromDateTimePickerRef}
              variant="dialog"
              className="browser-default"
              value={stateFromDate}
              onChange={onFromDateChange}
              onError={console.log}
              onClose={() => {
                //onSubmitFilters();
              }}
              //minDate={new Date("2018-01-01T00:00")}
              format="MM/DD/YYYY hh:mm A"
            />
          </MuiPickersUtilsProvider>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <span className="nucleus-labels">
          <b>To</b>
        </span>{' '}
        &nbsp;
        {/* <input id="toDateTime" ref={toDateTimeRef} //value={stateToDateTimeValue} type="text" className="validate nucleus-date-picker-short" autoComplete="off" //disabled={filtersBoxesMode} /> */}
        <div style={{ width: 240, display: 'inline-flex' }}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDateTimePicker
              //ref={toDateTimePickerRef}
              variant="dialog"
              className="browser-default"
              value={stateToDate}
              onChange={onToDateChange}
              onError={console.log}
              onClose={() => {
                console.log('onClose!');
                //onSubmitFilters();
              }}
              minDate={new Date(stateFromDate).setDate(new Date(stateFromDate).getDate() + 2)}
              format="MM/DD/YYYY hh:mm A"
            />
          </MuiPickersUtilsProvider>
          &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        <span className="nucleus-labels">
          <b>{` TimeZone `}</b>
        </span>{' '}
        &nbsp;
        <select
          value={stateFilterTimezone}
          className="browser-default nucleus-select select-large"
          onChange={onTimeZoneChange}
          //disabled={filtersBoxesMode}
          style={{ backgroundColor: 'transparent', borderBottomColor: '#e6e6e6', borderBottomWidth: 1 }}
        >
          {getTimezonesOptions()}
        </select>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={onSubmitFilters}>
          Filter Results
        </a>
        &nbsp;
        <br />
        {stateDatesErrorVisibility && <span className="nucleus-form-error center-align ">{stateDatesErrorText}</span>}
        <br />
      </div>

      <div className="users-table-pending altoImportantContainer">
        <VideoSessionTable
          accountConfig={accountConfig}
          classSessions={classSessions}
          searchTextValue={stateSearchText.toLowerCase()}
          handleEditClass={handleEditClass}
          handleStartClass={handleStartClass}
        ></VideoSessionTable>
      </div>

      <div id="modalNewClass" className="modal modalScrollable50 dismissible-modal">
        <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseModal} />
          <h3 className="nucleus-page-subtitle">{stateModalTitle + ' ' + accountConfig.TabNameLabel}</h3>
          {stateModalTitle == 'Edit' && !stateClassInitiated && !stateClassEditLocked && (
            <a
              className={'nucleus-font nucleus-font nucleus-link'}
              onClick={() => {
                console.log('onCancel Pressed');
                ($('#modalCancelClass') as unknown as JQuery).modal('open');
              }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="txt-red"> {'Cancel ' + accountConfig.TabNameLabel}</span>
            </a>
          )}
          <br />
          {stateModalTitle == 'Edit' && stateClassInitiated && (
            <a className={'nucleus-font nucleus-font '}>
              <span className="txt-red"> {`The ${accountConfig.TabNameLabel.toLowerCase()} has already started and cannont be edited or cancelled.`}</span>
            </a>
          )}
          {stateModalTitle == 'Edit' && stateClassEditLocked && !stateClassInitiated && (
            <a className={'nucleus-font nucleus-font '}>
              <span className="txt-red">
                {' '}
                {`The ${accountConfig.TabNameLabel.toLowerCase()} cannot be edited or cancelled ${accountConfig.MinMinutesToEdit} minutes before the start time.`}
              </span>
            </a>
          )}
          <br />
          <br />
          <div className="row marginBottom12">
            <div className="col s5">
              <span className="nucleus-labels">
                <b>{accountConfig.HostLabel}</b>
              </span>
            </div>
            <div className="col s7">
              <select
                className="browser-default h32"
                value={stateClassUser}
                onChange={e => {
                  console.log('Selected ', e.target.value);
                  setStateClassUser(e.target.value);
                  setStateSelectUserWarning(false);
                }}
                // disabled={(stateModalTitle == "Edit")}
              >
                <option value="" disabled>
                  {' '}
                  {'Select ' + accountConfig.HostLabel}{' '}
                </option>
                {getUsersOptions()}
              </select>
              {stateSelectUserWarning && <span className={'nucleus-form-error center-align'}>Please select {accountConfig.HostLabel}.</span>}
            </div>
          </div>
          <div className="row marginBottom12">
            <div className="col s5">
              <span className="nucleus-labels">
                <b>Name</b>
              </span>
            </div>
            <div className="col s7" style={{ paddingRight: 15 }}>
              <input
                type="text"
                value={stateClassName}
                className="validate"
                placeholder="Class Name"
                autoComplete="off"
                style={{ backgroundColor: 'white', paddingLeft: 5, marginBottom: 0 }}
                onChange={e => {
                  console.log('Value', e.target.value);
                  setStateClassName(e.target.value);
                  setStateClassNameWarning(false);
                }}
              />
              {stateClassNameWarning && <span className="nucleus-form-error center-align ">{`Please insert the ${accountConfig.TabNameLabel} name`}</span>}
            </div>
          </div>

          <div className="row marginBottom12">
            <div className="col s5">
              <span className="nucleus-labels">
                <b>Start Time</b>
              </span>
            </div>
            <div className="col s7">
              {/* <input id="startDateTime" 
                                ref={startDateTimeRef} 
                                //value={stateClassStartTime} 
                                type="text" 
                                className="validate nucleus-date-picker placeholder-black" 
                                placeholder="  Pick Start Time"
                                autoComplete="off"/> */}
              <div style={{ width: '100%', display: 'inline-flex', backgroundColor: 'white', paddingLeft: 5 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    //ref={startDateTimePickerRef}
                    variant="dialog"
                    className="browser-default"
                    value={stateClassStartTime}
                    onChange={onStartDateChange}
                    onError={console.log}
                    onClose={() => {}}
                    //minDate={new Date("2018-01-01T00:00")}
                    format="MM/DD/YYYY hh:mm A"
                  />
                </MuiPickersUtilsProvider>
              </div>
              {stateClassStartTimeWarning && <span className="nucleus-form-error center-align ">Please select a date and time.</span>}
            </div>
          </div>
          <div className="row marginBottom12">
            <div className="col s5">
              <span className="nucleus-labels">
                <b>End Time</b>
              </span>
            </div>
            <div className="col s7">
              {/* <input 
                                id="endDateTime" 
                                ref={endDateTimeRef}
                                //value={stateClassEndTime} 
                                type="text" 
                                className="validate nucleus-date-picker placeholder-black" 
                                placeholder="  Pick End Time" 
                                autoComplete="off"/> */}
              <div style={{ width: '100%', display: 'inline-flex', backgroundColor: 'white', paddingLeft: 5 }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDateTimePicker
                    //ref={endDateTimePickerRef}
                    variant="dialog"
                    className="browser-default"
                    value={stateClassEndTime}
                    onChange={onEndDateChange}
                    onError={console.log}
                    onClose={() => {}}
                    minDate={new Date(stateClassStartTime).setHours(new Date(stateClassStartTime).getHours() + 1)}
                    format="MM/DD/YYYY hh:mm A"
                  />
                </MuiPickersUtilsProvider>
              </div>
              {stateClassEndTimeWarning && <span className="nucleus-form-error center-align ">Please select a date and time.</span>}
              {stateClassTimesWarning && <span className="nucleus-form-error center-align ">End Time cannot be before Start Time.</span>}
            </div>
          </div>

          <div className="row marginBottom12">
            <div className="col s5">
              <span className="nucleus-labels">
                <b>Auto Drop-In</b>
              </span>
            </div>
            <div className="col s5 no-margin">
              <div className="switch no-margin ">
                <label className="no-margin">
                  <input
                    type="checkbox"
                    checked={stateClassAutoAnswer}
                    onChange={e => {
                      console.log('Value', e.target.checked);
                      setStateClassAutoAnswer(e.target.checked);
                    }}
                  />
                  <span className="lever nucleus-check"></span>
                </label>
              </div>
            </div>
            <div className="col s2"></div>
          </div>
          <div className="row small-margin-bottom">
            <div className="col s5">
              <span className="nucleus-labels">Participants</span>
              {accountConfig.MaxParticipants && (
                <span className="nucleus-labels nucleus-font-small">{` (${stateClassParticipantCounter} of ${accountConfig.MaxParticipants})`}</span>
              )}
            </div>
          </div>

          <div className="row no-margin ">
            <div className="input-field col s12 no-margin-top">
              <div className="marginBottom12">
                <span className="nucleus-font nucleus-font-14 marginBottom12">
                  Start typing the Participant’s name and we will display all options. Simply select from the list and you will see the Participant's name show in the section
                  below.
                </span>
              </div>
              <div
                id="txtPatients"
                ref={txtPatientsInput}
                className="chips nucleus-chips marginBottom12"
                //disabled={'disabled'}
              ></div>
              {stateClassParticipantsWarning && <span className="nucleus-form-error center-align marginBottom12">Please select at least one Participant.</span>}
              {stateClassParticipantCounter == accountConfig.MaxParticipants && (
                <span className="nucleus-form-error center-align marginBottom12">{`You have reached the limit of ${accountConfig.MaxParticipants} participants per ${accountConfig.TabNameLabel.toLowerCase()}.`}</span>
              )}
            </div>
            <br />
          </div>

          <div className="input-field col s12 modalCallLogContent2">
            <div className="row no-margin">
              <div className="col s12 center-align no-margin">
                <p></p>
                <span className=" modalSeparator">
                  {' '}
                  <label> &nbsp;</label>
                </span>
                <a className={'txt-white nucleus-font-modal-btn ' + stateSaveButtonStyle} onClick={submitNewClass}>
                  Save
                </a>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modalCancelClass" className="modal modalScrollableMedium dismissible-modal">
        <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
          <img
            className="responsive-img img-close-modal"
            src="/img/close_modal.png"
            onClick={() => {
              ($('#modalCancelClass') as unknown as JQuery).modal('close');
            }}
          />
          <h3 className="nucleus-page-subtitle">{'Cancel ' + accountConfig.TabNameLabel}</h3>
          <br />
          <br />
          <div className="row">
            <div className="col s12">
              <span className="nucleus-labels nucleus-font nucleus-font-14">
                {' '}
                Are you sure you want to cancel this {accountConfig.TabNameLabel} '{stateClassName}'?{' '}
              </span>
            </div>
          </div>

          <div className="input-field col s12 modalCallLogContent2">
            <br />
            <div className="row no-margin">
              <div className="col s12 center-align no-margin">
                <p></p>
                <span className=" modalSeparator">
                  {' '}
                  <label> &nbsp;</label>
                </span>
                <a
                  className={'txt-white nucleus-font ' + stateSaveButtonStyle}
                  onClick={() => {
                    ($('#modalCancelClass') as unknown as JQuery).modal('close');
                  }}
                >
                  No
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className={'txt-white nucleus-font ' + stateSaveButtonStyle} onClick={submitCancelClass}>
                  Yes
                </a>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modalStartClass" className="modal modalScrollableSmall dismissible-modal" style={{ width: 450, zIndex: 100 }}>
        <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
          <img
            className="responsive-img img-close-modal"
            src="/img/close_modal.png"
            onClick={() => {
              ($('#modalStartClass') as unknown as JQuery).modal('close');
            }}
          />
          <div className="row">
            <div className="col s12">
              <h3 className="nucleus-page-subtitle">{'Start ' + accountConfig.TabNameLabel}</h3>
            </div>
          </div>
          <div className="row marginBottom12">
            <div className="col s12">
              <span className="nucleus-page-subtitle " style={{ fontSize: 16.7 }}>
                {' '}
                Are you sure you want to start this {accountConfig.TabNameLabel} ?{' '}
              </span>
            </div>
          </div>
          <br />
          <div className="row marginBottom12">
            <div className="col s4">
              <span className="nucleus-labels">
                <b>{'Name'}</b>
              </span>
            </div>
            <div className="col s8">
              <span className="nucleus-labels">{stateClassName}</span>
            </div>
          </div>
          <div className="row marginBottom12">
            <div className="col s4">
              <span className="nucleus-labels">
                <b>{accountConfig.HostLabel}</b>
              </span>
            </div>
            <div className="col s8">
              <span className="nucleus-labels">{stateClassUserName}</span>
            </div>
          </div>

          <div className="row marginBottom12">
            <div className="col s4">
              <span className="nucleus-labels">
                <b>{'Start Time'}</b>
              </span>
            </div>
            <div className="col s8">{stateClassStartTime && <span className="nucleus-labels">{stateClassStartTime.toString()}</span>}</div>
          </div>

          <div className="row marginBottom12">
            <div className="col s4">
              <span className="nucleus-labels">
                <b>{'End Time'}</b>
              </span>
            </div>
            <div className="col s8">{stateClassEndTime && <span className="nucleus-labels">{stateClassEndTime.toString()}</span>}</div>
          </div>

          <div className="input-field col s12 modalCallLogContent2">
            <br />
            <div className="row no-margin">
              <div className="col s12 center-align no-margin">
                <p></p>
                <span className=" modalSeparator">
                  {' '}
                  <label> &nbsp;</label>
                </span>
                <a
                  className={'txt-white nucleus-font nucleus-font-modal-btn ' + stateSaveButtonStyle}
                  onClick={() => {
                    ($('#modalStartClass') as unknown as JQuery).modal('close');
                  }}
                >
                  No
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className={'txt-white nucleus-font nucleus-font-modal-btn ' + stateSaveButtonStyle} onClick={submitStartClass}>
                  Yes
                </a>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modalBusy" className="modal modalScrollableSmall dismissible-modal">
        <div className="modal-content nucleus-modal-wrapper2 nucleus-modal-content txt-special-color">
          <img
            className="responsive-img img-close-modal"
            src="/img/close_modal.png"
            onClick={() => {
              ($('#modalBusy') as unknown as JQuery).modal('close');
            }}
          />
          <h3 className="nucleus-page-subtitle">{accountConfig.TabNameLabel + ' participant(s) conflict.'}</h3>
          <br />
          <br />
          <div className="row">
            <div className="col s12 no-margin">
              <span className="nucleus-labels">{stateClassConflictText}</span>
            </div>
          </div>
          {getConflictRows()}
          <div className="input-field col s12 modalCallLogContent2">
            <br />
            <div className="row no-margin">
              <div className="col s12 center-align no-margin">
                <p></p>
                <span className=" modalSeparator">
                  {' '}
                  <label> &nbsp;</label>
                </span>
                <a
                  className={'txt-white nucleus-font ' + stateSaveButtonStyle}
                  onClick={() => {
                    ($('#modalBusy') as unknown as JQuery).modal('close');
                  }}
                >
                  OK
                </a>
                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a class={"txt-white nucleus-font " + stateSaveButtonStyle } onClick={submitStartClass}>Yes</a> */}
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="fixed-action-btn btn-add-new">
        <a
          className="btn-floating btn-large waves-effect waves-light orange " // tooltipped
          // data-position="left"
          // data-delay="50"
          // data-tooltip={"New " + AuthStore.getPatientLabel()}
          onClick={loadNewClassView}
        >
          <i className="material-icons">add</i>
        </a>
        &nbsp;
      </div>
    </div>
  );
};

export default VideoSession;
