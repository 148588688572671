import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { Link } from 'react-router-dom';
import AuthStore from '../stores/AuthStore';
import Message from '../utils/Message';

class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      errorView1: ' hide',
      errorText1: '',
      btnStyle: ' nucleus-submit-btn-disabled',
    };

    this.onResetRequestedAction = this.onResetRequestedAction.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
    this.validateEmailInput = this.validateEmailInput.bind(this);
  }

  componentDidMount() {
    //Register Listener
    AuthStore.on('onResetRequestedAction', this.onResetRequestedAction);
  }

  componentWillUnmount() {
    //Remove Listener
    AuthStore.removeListener('onResetRequestedAction', this.onResetRequestedAction);
  }

  onResetRequestedAction(response) {
    console.log('onResetRequestedAction');
    console.log(response);

    if (response.ok) {
      Message.show('Reset Password request email has been successfully to this email, please review your inbox.');
    } else {
      if (!response.existingUser) {
        this.setState({
          errorView1: ' ',
          errorText1: 'Email account does not exist.',
        });
      } else {
        Message.show('Error requesting reset password');
      }
    }
    this.setState({
      btnStyle: ' nucleus-submit-btn',
    });
  }

  requestReset() {
    AuthStore.requestResetPassword({
      Email: this.refs.txtUserEmail.value,
    });
  }

  validateFields() {
    if (!/^[\w+.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,15}$/.test(this.refs.txtUserEmail.value)) {
      this.setState({
        errorView1: ' ',
        errorText1: 'Email invalid.',
      });
      return;
    }

    this.requestReset();
    this.setState({
      btnStyle: ' nucleus-submit-btn-disabled',
    });
  }

  validateFieldsFromKeyboard(e) {
    if (e.charCode == 13 || e.keyCode == 13 || e.key == 13) {
      this.validateFields();
    }
  }

  validateEmailInput() {
    this.setState({
      errorView1: ' hide',
    });

    if (this.refs.txtUserEmail.value.length > 0) {
      this.setState({
        btnStyle: ' nucleus-submit-btn',
      });
    } else {
      this.setState({
        btnStyle: ' nucleus-submit-btn-disabled',
      });
    }
  }

  render() {
    const style = {
      height: window.innerHeight,
      background: '#008FFF',
      overflowY: 'auto',
    };

    return (
      <div className="nucleus-login" style={style}>
        <div className="alto valign-wrapper" style={{ height: 760 }}>
          <div className="row ancho">
            <div className="col s2 m3 l3 border"></div>
            <div className="col s8 m6 l6 border center-align no-padding">
              <img className="responsive-img" src="/img/nucleus_2024_logo.png" />
              <br />
              <br />
              <p id={'forgot-title'} className="txt-white nucleus-page-subtitle">
                Forgot Password
              </p>
              <br />
              <br />
              <p className="login-info txt-white nucleus-font "> Enter your account email address and we will send you reset instructions. </p>
              <br />
              <div className="login-info login-error nucleus-font-medium nucleus-font ">
                &nbsp; <span className={this.state.errorView1}> {this.state.errorText1} </span> &nbsp;{' '}
              </div>

              <div className="row center-align no-margin">
                <div className="input-field col s1 m3 l3"></div>
                <div className="input-field col s10 m6 l6">
                  <input
                    id="forgot-email"
                    ref="txtUserEmail"
                    type="email"
                    name="nucleus-user"
                    className="validate nucleus-input-login nucleus-font"
                    placeholder="Email"
                    onKeyPress={this.validateFieldsFromKeyboard}
                    onChange={this.validateEmailInput}
                  />
                </div>
                <div className="input-field col s1 m3 l3"></div>
              </div>
              <br />
              <div className="row center-align">
                <div className="col s12">
                  <a
                    id={'submit-forgot'}
                    className={'txt-white nucleus-font-small nucleus-font-book ' + this.state.btnStyle}
                    style={{ fontSize: 15 }}
                    onClick={this.validateFields}
                  >
                    Submit
                  </a>
                </div>
              </div>

              <div className="row center-align no-margin">
                <div className="input-field col s1 m3 l3"></div>
                <div className="input-field col s10 m6 l6">
                  <Link id={'back-to-login'} to="/login" className="forgot-style">
                    <span className="nucleus-link txt-black nucleus-font-book" style={{ fontSize: 13.9 }}>
                      Back to Login screen
                    </span>
                  </Link>
                </div>
                <div className="input-field col s1 m3 l3"></div>
              </div>
            </div>
            <div className="col s2 m3 l3 border"></div>
          </div>
        </div>
      </div>
    );
  }
}

const ForgotPasswordFC = props => {
  return useReactRouterProps(ForgotPassword, props);
};

export default ForgotPasswordFC;
