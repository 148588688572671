//@ts-nocheck
import React from 'react';

class CustomSlider extends React.Component {
  constructor(props) {
    super(props);
    this.value = this.props.initialValue;
  }

  componentDidMount() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.handleSliderFill(null);
  }

  handleSliderFill(e) {
    const element = $('#' + this.props.id);
    const val = (element.val() - element.attr('min')) / (element.attr('max') - element.attr('min'));

    element.css({
      'background-image':
        '-webkit-gradient(linear, left top, right top, ' + 'color-stop(' + val + ', rgba(233, 242, 255, 1)), ' + 'color-stop(' + val + ', rgba(90, 161, 197, 1))' + ')',
    });
  }

  handleChange(e) {
    const element = $('#' + this.props.id);
    this.value = element.val();
    if (e) {
      this.props.onChange(this.refs[this.props.id]);
    }
  }

  render() {
    return (
      <div className="left-align volume-controls-bar">
        <img className="" src={this.props.minImg} height="15" style={{ marginBottom: -3 }} />
        <input
          defaultValue={this.props.initialValue}
          type="range"
          id={this.props.id}
          className="nucleus-control-range"
          min={this.props.min}
          max={this.props.max}
          step={this.props.step}
          onMouseOut={this.handleChange.bind(this)}
          onMouseUp={this.handleChange.bind(this)}
          onChange={this.handleSliderFill.bind(this)}
        />
        <img className="" src={this.props.maxImg} height="15" style={{ marginBottom: -3 }} />
      </div>
    );
  }
}

export default CustomSlider;
