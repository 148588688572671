//@ts-nocheck
import React from 'react';

import CarePatientStore from '../../../stores/CarePatientStore';

class CarePatientAssignmentItemRow extends React.Component<{
  AssignmentID: number;
  CareGiverID: number;
  CareGiverName: string;
  Message: string;
  Date: string;
  TimeZone: string;
  SmsDelivered: boolean;
  MessageRead: boolean;
  CheckIn: string;
  CheckOut: string;
}> {
  constructor(props) {
    super(props);
    this.state = {
      assignmentID: this.props.AssignmentID,
      assignmentCareGiverID: this.props.CareGiverID,
      assignmentMessage: this.props.Message,
      assignmentDate: this.props.Date + ' - ' + this.props.TimeZone,
      assignmentSmsDelivered: this.props.SmsDelivered,
      assignmentMessageRead: this.props.MessageRead,
      assignmentCheckIn: this.props.CheckIn,
      assignmentCheckOut: this.props.CheckOut,
      assignmentCaregiverName: '',
    };
    //console.log(this.state.patientID);
    this.handleRowClicked = this.handleRowClicked.bind(this);
    this.handleDeleteAssigment = this.handleDeleteAssigment.bind(this);
  }

  componentWillUnmount() {}

  handleRowClicked() {
    //window.location.assign("#/caregiverProfile/" + this.props.CaregiverID);

    console.log('handleRowClicked');
    CarePatientStore.requestOpenPatientAssignmentModal({
      assignmentID: this.state.assignmentID,
      assignmentMessage: this.state.assignmentMessage,
      assignmentDate: this.props.Date,
      assignmentCheckIn: this.state.assignmentCheckIn,
      assignmentCheckOut: this.state.assignmentCheckOut,
      assignmentCaregiverID: this.props.CareGiverID,
      assignmentCaregiverName: this.props.CareGiverName,
    });
  }

  handleDeleteAssigment() {
    //// GeneralStore.openDeletePatientModal({
    ////     PatientID : this.props.PatientID
    //// });
  }

  render() {
    const smsDelivered = this.state.assignmentSmsDelivered ? 'Y' : 'N';

    let shortMessage = this.state.assignmentMessage;
    if (shortMessage != null && shortMessage.length > 35) {
      shortMessage = shortMessage.substring(0, 35) + ' ...';
    }

    let checkInLabel = '';
    if (this.props.CheckIn != '') {
      checkInLabel = this.props.CheckIn + ' - ' + this.props.TimeZone;
    }

    let checkOutLabel = '';
    if (this.props.CheckOut != '') {
      checkOutLabel = this.props.CheckOut + ' - ' + this.props.TimeZone;
    }

    return (
      <tr className="nucleus-row" onClick={this.handleRowClicked}>
        <td className="left-align">{this.props.CareGiverName}</td>
        <td className="left-align">{shortMessage}</td>
        <td className="left-align">{this.state.assignmentDate}</td>
        <td className="center-align">{smsDelivered}</td>
        <td className="left-align">{checkInLabel}</td>
        <td className="left-align">{checkOutLabel}</td>
      </tr>
    );
  }
}

export default CarePatientAssignmentItemRow;
