//@ts-nocheck
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';

const Reports = () => {
  const navigate = useNavigate();
  useEffect(() => {
    validateAccessGranted('/reports');
  }, []);

  return (
    <div className="nucleus-hard-container">
      <div className="nucleus-all-scrollable-page">
        <p className="nucleus-page-title">Reports</p>
        <div>
          <span className="nucleus-tools-page-text-title" style={{ fontSize: 18 }}>
            Reports by
          </span>

          <div style={{ paddingLeft: 72, marginTop: 10 }}>
            <div
              style={{ display: 'inline-flex', cursor: 'pointer' }}
              onClick={() => {
                navigate('/reports/calls');
              }}
            >
              <div>
                <span className="nucleus-tools-page-text-title">Calls</span>
              </div>
              <div>
                <span className="nucleus-table-icon nucleus-link">
                  <i className="Tiny material-icons">chevron_right</i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
