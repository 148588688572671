import { VideoViewData } from '../callLayout.types';

export const AudioView = ({ data }: { data: VideoViewData }) => {
  if (data.type != 'Audio') {
    return null;
  }

  return (
    <div
      style={{
        borderRadius: 10,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        background: 'url("/img/incoming-bg.jpg")',
        backgroundSize: 'cover',
        //zIndex: 9999,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div
        style={{
          height: 150,
          width: 150,
          borderRadius: 75,
          backgroundColor: '#333',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span
          style={{
            color: '#fff',
            fontSize: 50,
          }}
        >
          {data.Initials}
        </span>
      </div>
    </div>
  );
};
