import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import styled from 'styled-components';
import { UINucleusContainer, useModal } from '../../../components/UI';
import { UINucleusContentContainer } from '../../../components/UI/NucleusContainer/Content';
import { useNucleusProviders } from '../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import AuthStore from '../../../stores/AuthStore';
import { CommunicationEvent } from '../../../stores/CareCommunicationStore';
import careCommunicationStore from '../../../stores/CareCommunicationStore';
import PostCallDataModal from '../components/PostCallDataModal';
import { AddButtonSVG } from './assets/AddButton';
import { ArrowLeftSVG } from './assets/ArrowLeft';
import { AddNewScheduleModal } from './components/AddNewScheduleModal';
import { DaySwitcher } from './components/DaySwitcher';
import { ScheduleByHour } from './components/ScheduleByHour';
import ScheduleNavTabs from './components/ScheduleNavTabs';
import { ProviderSchedule as ProviderScheduleType } from './providerSchedule.types';

const PageSubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
`;
const PageCenteredSubtitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 21.6px;
  justify-content: center;
  display: flex;
  flex: 1;
`;

const HeaderRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-left: 9px;
  padding-right: 3px;
`;

const OutlinePill = styled.div`
  display: flex;
  border: 1px solid #0092ff;
  border-radius: 100px;
  color: #0092ff;
  height: 50px;
  padding: 5px 10px;
  margin-right: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`;

const Spacer = styled.div`
  flex: 1;
`;

const FloatingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
`;

const ProviderSchedule = () => {
  const {
    actions,
    state: { schedule: providerSchedule },
  } = useNucleusProviders();

  const [scheduleDate, setScheduleDate] = useState<Date>(new Date());
  const [fetching, setFetching] = useState<boolean>(false);
  const today = moment(new Date());
  const [postCallLogId, setPostCallLogId] = useState<string | null>(null);

  const providerId = AuthStore.getProviderID();

  const { closeModal, isOpen, openModal } = useModal();

  const { isOpen: isCallLogModalOpen, openModal: openCallLogModal, closeModal: closeCallLogModal } = useModal();

  const onOpenCallLogModal = () => {
    openCallLogModal();
  };
  const onCloseCallLogModal = () => {
    setPostCallLogId(null);
    closeCallLogModal();
  };

  const filteredSchedule = providerSchedule.filter((schedule: ProviderScheduleType) => {
    return moment(schedule.StartTime).isSame(scheduleDate, 'day');
  });

  const fetchScheduleForProvider = async (providerId: string) => {
    setFetching(true);
    await actions.fetchScheduleForProvider(providerId);
    setFetching(false);
  };

  useEffect(() => {
    if (providerId) {
      fetchScheduleForProvider(providerId);
    }
  }, [providerId]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (providerId) fetchScheduleForProvider(providerId);
    }, 1000 * 20);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const refreshSchedule = useCallback(() => {
    if (providerId) actions.fetchScheduleForProvider(providerId);
  }, [providerId]);

  const onPostCall = args => {
    console.warn('onPostCall', args);
    console.warn('onPostCall args?.callLogID', args?.callLogID);
    console.warn('onPostCall args?.CallLogID', args?.CallLogID);
    const callLogId = args?.callLogID ?? args?.CallLogID;
    if (args?.ok && callLogId) {
      setPostCallLogId(callLogId);
    }
  };

  useEffect(() => {
    careCommunicationStore.addListener(CommunicationEvent.OnReportCallEnded, refreshSchedule);
    careCommunicationStore.addListener(CommunicationEvent.OnReportCallHandled, refreshSchedule);
    careCommunicationStore.addListener(CommunicationEvent.OnReportCallEnded, onPostCall);
    return () => {
      careCommunicationStore.removeListener(CommunicationEvent.OnReportCallEnded, refreshSchedule);
      careCommunicationStore.removeListener(CommunicationEvent.OnReportCallHandled, refreshSchedule);
      careCommunicationStore.removeListener(CommunicationEvent.OnReportCallEnded, onPostCall);
    };
  }, []);

  const returnToTodaysDate = () => {
    setScheduleDate(new Date());
  };

  return (
    <UINucleusContainer>
      <UINucleusContentContainer>
        <ScheduleNavTabs activeTab="todays-schedule"></ScheduleNavTabs>

        <DaySwitcher
          currentDate={scheduleDate}
          onDateChange={(date: Date) => {
            setScheduleDate(date);
          }}
        />
        {moment(scheduleDate).isSame(today, 'day') ? (
          <HeaderRow>
            <PageCenteredSubtitle>
              <strong>Today</strong>
            </PageCenteredSubtitle>
          </HeaderRow>
        ) : (
          <HeaderRow>
            <Row>
              <PageSubtitle>
                <strong>Today's date:</strong> {moment().format('dddd, MMMM DD, YYYY')}
              </PageSubtitle>
              {fetching && (
                <Spinner
                  style={{
                    marginLeft: 10,
                  }}
                  color="#FFF"
                  size={10}
                  speed={1}
                />
              )}
            </Row>
            <Spacer />
            <OutlinePill onClick={returnToTodaysDate}>
              <ArrowLeftSVG /> Return to today's schedule
            </OutlinePill>
          </HeaderRow>
        )}
        <UINucleusContentContainer style={{ paddingTop: 0, paddingBottom: 100 }}>
          <ScheduleByHour providerSchedule={filteredSchedule} />
          {isOpen && <AddNewScheduleModal isOpen={isOpen} closeModal={closeModal} currentDate={scheduleDate} />}
          <FloatingButton
            onClick={() => {
              openModal();
            }}
          >
            <AddButtonSVG />
          </FloatingButton>
        </UINucleusContentContainer>

        <PostCallDataModal isOpen={isCallLogModalOpen} openModal={onOpenCallLogModal} closeModal={onCloseCallLogModal} callLogId={postCallLogId} />
      </UINucleusContentContainer>
    </UINucleusContainer>
  );
};

export default ProviderSchedule;
