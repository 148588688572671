import React from 'react';
import { ReactComponent as NucleusIcon } from '../../svg/nucleuscare-transparent.svg';
import css from './Done.module.css';

export function CallSessionDone() {
  return (
    <div className={css.container}>
      <NucleusIcon />
    </div>
  );
}
