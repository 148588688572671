import moment from 'moment';
import styled from 'styled-components';
import { ChevronLeftSVG } from '../assets/ChevronLeft';
import { ChevronRightSVG } from '../assets/ChevronRight';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 24px;
  margin-bottom: 18px;
`;

const PageSubtitle = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  font-style: italic;
`;

const Spacer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Clickable = styled.div`
  cursor: pointer;
`;

export const DaySwitcher = ({ currentDate, onDateChange }: { currentDate: Date; onDateChange: (date: Date) => void }) => {
  return (
    <Row>
      <Clickable onClick={() => onDateChange(new Date(currentDate.setDate(currentDate.getDate() - 1)))}>
        <ChevronLeftSVG />
      </Clickable>
      <Spacer>
        <PageSubtitle>{moment(currentDate).format('dddd, MMMM D, YYYY')}</PageSubtitle>
      </Spacer>
      <Clickable onClick={() => onDateChange(new Date(currentDate.setDate(currentDate.getDate() + 1)))}>
        <ChevronRightSVG />
      </Clickable>
    </Row>
  );
};
