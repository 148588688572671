//@ts-nocheck
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React from 'react';
import NucleusBulletinEditor from '../../components/BulletinBoard/NucleusBulletinEditor';
import AuthStore from '../../stores/AuthStore';
import CareBulletinStore from '../../stores/CareBulletinStore';

import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
// import RichTextEditor from 'react-rte';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class BulletinBoard extends React.Component {
  constructor() {
    super();
    validateAccessGranted('/bulletin');
    this.state = {
      accountBulletinButtons: CareBulletinStore.getBulletinButtons(),
      accountButtonsEnabled: CareBulletinStore.getButtonsCount(),

      btn1Tab: ' staffTab',
      btn2Tab: ' ',
      btn3Tab: ' ',
      btn4Tab: ' ',

      btn1Enable: ' ',
      btn2Enable: ' ',
      btn3Enable: ' ',
      btn4Enable: ' ',

      btn1Title: '',
      btn2Title: '',
      btn3Title: '',
      btn4Title: '',

      selectedButton: {},
      selectedButtonIndex: 0,

      saveErrorVisible: '',
      saveErrorText: '',

      loading: false,

      editorState: EditorState.createEmpty(), //RichTextEditor.createEmptyValue(),
    };

    this.allOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' };
    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.onGetBulletinButtonsDataCompleteAction = this.onGetBulletinButtonsDataCompleteAction.bind(this);

    this.handleSelectBtn = this.handleSelectBtn.bind(this);
    this.handleSaveBulletinContent = this.handleSaveBulletinContent.bind(this);
    this.onUpdateButtonContentCompleteAction = this.onUpdateButtonContentCompleteAction.bind(this);

    //this.escapeText = this.escapeText.bind(this);
    //this.handleSearchText = this.handleSearchText.bind(this);

    // this.onChange = (value) => {
    //     this.setState({value});
    // };

    // List of HTML entities for escaping.
    this.htmlEscapes = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#x27;',
      '/': '&#x2F;',
    };
    // Regex containing the keys listed immediately above.
    this.htmlEscaper = /[&<>"'\/]/g;
  }

  componentDidMount() {
    //Register Listener
    CareBulletinStore.on('onGetBulletinButtonsDataComplete', this.onGetBulletinButtonsDataCompleteAction);
    CareBulletinStore.on('onUpdateButtonContentComplete', this.onUpdateButtonContentCompleteAction);

    console.log('Bulletin componentDidMount', this.state.accountButtonsEnabled);
    if (this.state.accountButtonsEnabled > 0) {
      this.init();
    } else {
      CareBulletinStore.getBulletinButtonsData({
        AccountID: AuthStore.getUserAccountID(),
      });
    }
  }

  componentWillUnmount() {
    //Remove Listener
    CareBulletinStore.removeListener('onGetBulletinButtonsDataComplete', this.onGetBulletinButtonsDataCompleteAction);
    CareBulletinStore.removeListener('onUpdateButtonContentComplete', this.onUpdateButtonContentCompleteAction);
  }

  onGetBulletinButtonsDataCompleteAction(success) {
    console.log('onGetBulletinButtonsDataCompleteAction', success);

    if (success) {
      this.setState({
        accountBulletinButtons: CareBulletinStore.getBulletinButtons(),
        accountButtonsEnabled: CareBulletinStore.getButtonsCount(),
      });
      this.init();
    }
  }

  init() {
    console.log('Init', this.state.accountBulletinButtons);

    if (this.state.accountBulletinButtons) {
      let i = 0;
      let editorState;
      let htmlContent = this.state.accountBulletinButtons[i].Content || '';
      if (!htmlContent.includes('<p>')) {
        htmlContent = '<p></p>' + htmlContent;
      }
      const contentBlock = htmlToDraft(htmlContent);
      if (contentBlock) {
        console.log('contentBlock', contentBlock);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        editorState = EditorState.createWithContent(contentState);
      }
      console.log('htmlContent', htmlContent);
      console.log('editorState', editorState);
      this.setState({
        btn1Enable: ' hide',
        btn2Enable: ' hide',
        btn3Enable: ' hide',
        btn4Enable: ' hide',

        selectedButton: this.state.accountBulletinButtons[i],
        selectedButtonIndex: i,
        editorState: editorState, //RichTextEditor.createValueFromString(this.state.accountBulletinButtons[i].Content, 'html')
      });

      while (i < this.state.accountButtonsEnabled) {
        console.log('Init Pos i', i);
        if (i == 0 && this.state.accountBulletinButtons[i].ShouldShow) {
          this.setState({
            btn1Enable: ' ',
            btn1Title: this.state.accountBulletinButtons[i].Name,
          });
        } else if (i == 1 && this.state.accountBulletinButtons[i].ShouldShow) {
          this.setState({
            btn2Enable: ' ',
            btn2Title: this.state.accountBulletinButtons[i].Name,
          });
        } else if (i == 2 && this.state.accountBulletinButtons[i].ShouldShow) {
          this.setState({
            btn3Enable: ' ',
            btn3Title: this.state.accountBulletinButtons[i].Name,
          });
        } else if (i == 3 && this.state.accountBulletinButtons[i].ShouldShow) {
          this.setState({
            btn4Enable: ' ',
            btn4Title: this.state.accountBulletinButtons[i].Name,
          });
        }
        i++;
      }
    }
  }

  handleSelectBtn(e) {
    console.log('handleSelectBtn', e);

    const selectedBulletinButton = this.state.accountBulletinButtons[e];
    console.log('selectedBulletinButton', selectedBulletinButton);

    let editorState = ''; //EditorState.createEmpty()
    let htmlContent = selectedBulletinButton.Content || '';
    if (!htmlContent.includes('<p>')) {
      htmlContent = '<p></p>' + htmlContent;
    }
    const contentBlock = htmlToDraft(htmlContent);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    }

    this.setState({
      btn1Tab: ' ',
      btn2Tab: ' ',
      btn3Tab: ' ',
      btn4Tab: ' ',

      selectedButtonIndex: e,

      selectedButton: selectedBulletinButton,
      editorState: editorState, //RichTextEditor.createValueFromString(selectedBulletinButton.Content, 'html')
    });

    switch (e) {
      case 0:
        this.setState({
          btn1Tab: ' staffTab',
        });
        break;
      case 1:
        this.setState({
          btn2Tab: ' staffTab',
        });
        break;
      case 2:
        this.setState({
          btn3Tab: ' staffTab',
        });
        break;
      case 3:
        this.setState({
          btn4Tab: ' staffTab',
        });
        break;
    }
  }

  handleSaveBulletinContent(currentEditorState) {
    console.log('handleSaveBulletinContent');
    console.log('currentEditorState', currentEditorState);

    const selectedButton = this.state.selectedButton;
    //console.log("Saving... ", selectedButton);
    const text = draftToHtml(convertToRaw(currentEditorState.getCurrentContent()));
    const textHTML = text.toString('html');
    console.log('save text', text);
    console.log('save textHTML', textHTML);
    CareBulletinStore.updateButtonContent({
      ButtonID: selectedButton.ID,
      Content: textHTML,
    });
    this.setState({
      loading: true,
    });
  }

  onUpdateButtonContentCompleteAction(response) {
    console.log('onUpdateButtonContentCompleteAction', response);

    this.setState({
      loading: false,
    });
    if (response.ok) {
      Message.show('Bulletin board content updated');
      const selectedButton = this.state.selectedButton;
      selectedButton.Content = response.Content;
      const allButtons = this.state.accountBulletinButtons;
      allButtons[this.state.selectedButtonIndex] = selectedButton;
      this.setState({
        selectedButton: selectedButton,
        accountBulletinButtons: allButtons,
      });
    } else {
      Message.show('Error updating Bulletin Board Button Content, please try again');
    }
  }

  // escapeText(string){
  //     return ('' + string).replace(this.htmlEscaper, (match) => {
  //         return this.htmlEscapes[match];
  //     });
  // }

  // handleSearchText(e){
  //     //console.log("handleSearchText", e.target.value);
  //     this.setState({
  //         searchText : e.target.value
  //     });
  // }

  render() {
    console.log('Bulletin Render');
    const someButtonEnabled = this.state.btn1Enable == ' ' || this.state.btn2Enable == ' ' || this.state.btn3Enable == ' ' || this.state.btn4Enable == ' ';
    const { editorState } = this.state;

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">Bulletin Board </p>
          <a class={'staffTabs nucleus-link ' + this.state.btn1Tab + this.state.btn1Enable} onClick={this.handleSelectBtn.bind(this, 0)}>
            &nbsp;&nbsp;{this.state.btn1Title}&nbsp;&nbsp;
          </a>

          <a class={'staffTabs2 nucleus-link ' + this.state.btn2Tab + this.state.btn2Enable} onClick={this.handleSelectBtn.bind(this, 1)}>
            &nbsp;&nbsp;{this.state.btn2Title}&nbsp;&nbsp;
          </a>

          <a class={'staffTabs2 nucleus-link ' + this.state.btn3Tab + this.state.btn3Enable} onClick={this.handleSelectBtn.bind(this, 2)}>
            &nbsp;&nbsp;{this.state.btn3Title}&nbsp;&nbsp;
          </a>

          <a class={'staffTabs2 nucleus-link ' + this.state.btn4Tab + this.state.btn4Enable} onClick={this.handleSelectBtn.bind(this, 3)}>
            &nbsp;&nbsp;{this.state.btn4Title}&nbsp;&nbsp;
          </a>
          <br />
          <br />

          <NucleusBulletinEditor
            loading={this.state.loading}
            buttonsEnabled={someButtonEnabled}
            editorState={editorState}
            onSaveBulletin={this.handleSaveBulletinContent}
            emptyEditorMessage={'There are no Bulletin Buttons enabled for this account'}
          ></NucleusBulletinEditor>
        </div>
      </div>
    );
  }
}

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'LINK_BUTTONS', 'HISTORY_BUTTONS'],

  INLINE_STYLE_BUTTONS: [
    { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
    { label: 'Italic', style: 'ITALIC' },
    { label: 'Underline', style: 'UNDERLINE' },
    { label: 'Monospace', style: 'CODE' },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: 'Normal Text', style: 'unstyled' },
    { label: 'Heading 3', style: 'header-three' },
    { label: 'Heading 2', style: 'header-two' },
    { label: 'Heading 1', style: 'header-one' },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: 'UL', style: 'unordered-list-item' },
    { label: 'OL', style: 'ordered-list-item' },
    { label: 'Blockquote', style: 'blockquote' },
  ],
};

export default BulletinBoard;
