import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import PatientMessages from 'components/Messages/PatientMessages';
import PatientMessagesReports from 'components/Messages/PatientMessagesReports';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-activity';
import { Link } from 'react-router-dom';
import AuthStore from 'stores/AuthStore';
import CareCommunicationStore from 'stores/CareCommunicationStore';
import CarePatientStore, { GetScheduledPatientMessagesResponse, PatientMessage } from 'stores/CarePatientStore';
import GeneralStore from 'stores/GeneralStore';
import { JQuery } from 'types/jquery.types';
import Message from 'utils/Message';
import CallLogItemRow from '../components/CallLogItemRow';
import CareFamilyMemberItemRow from '../components/CareFamilyMemberItemRow';
import CarePatientAssignmentItemRow from '../components/CarePatientAssignmentItemRow';
import CarePatientMessageItemRow from '../components/CarePatientMessageItemRow';
import CarePatientNoteItemRow from '../components/CarePatientNoteItemRow';
import ExternalApps from '../components/ExternalApps';
import PatientBiometricsSection from '../components/PatientBiometricsSection';
import PatientBulletin from '../components/PatientBulletin';
import PatientConfigSection from '../components/PatientConfigSection';
import PatientProfileMoveFamilyModal from '../components/PatientProfileMoveFamilyModal';
import PatientRequestSection from '../components/PatientRequestSection';
import ScheduleView from '../components/ScheduleView';

export const PatientSettings = ({
  patientID,
  patientFullName,
  patientZipCode,
  patientDevices,
}: {
  patientID: string;
  patientFullName: string;
  patientZipCode: string;
  patientDevices: any[];
}) => {
  const dateOptions: Intl.DateTimeFormatOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const [state, setReactState] = useState<{
    callLogsContent?: string;
    requestLogsContent?: string;
    careLogsContent?: string;
    careFamilyContent?: string;
    careNotesContent?: string;
    careMessagesContent?: string;
    biometricsContent?: string;
    configurationContent?: string;
    scheduleContent?: string;
    bulletinContent?: string;

    callLogsTab?: string;
    requestLogsTab?: string;
    careLogsTab?: string;
    careFamilyTab?: string;
    careNotesTab?: string;
    careMessagesTab?: string;
    biometricsTab?: string;
    configTab?: string;

    deviceSettingsTab?: string;
    biometricsAlertsTab?: string;
    buttonsTab?: string;
    scheduleTab?: string;
    bulletinTab?: string;
    appsTab?: string;
    saveNewNoteBtnStyle?: string;
    fromDateTimeLogs?: Date;
    toDateTimeLogs?: Date;
    fromDateTimeMsgs?: Date;
    toDateTimeMsgs?: Date;
    fromDateTimeNotes?: Date;
    toDateTimeNotes?: Date;
    notesDatesErrorVisible?: string;
    notesDatesErrorText?: string;
    patientMessagesMode: 'Scheduled' | 'Delivered';
    loading?: boolean;
    loadingLogs?: boolean;
    enableButtonsSchedule?: boolean;
    enableBiometrics?: boolean;
    showCheckInButton?: boolean;
    fromTime?: string;
    toTime?: string;
    logsDatesErrorVisible?: string;
    logsDatesErrorText?: string;
    patientTimezone?: string;
    patientCallLogs?: any[];
    careNotesArray?: any[];
    assignmentsArray?: any[];
    familyMembersArray?: any[];
    PhotosCount?: number;
    enableAccountBiometrics?: boolean;
    enableBiometricsFeature?: boolean;
    enableBluetoothButtons?: boolean;
    enableAccountBluetoothButtons?: boolean;
    enableBluetoothButtonsFeature?: boolean;
    enableAutoAnswer?: boolean;
    enableAccountAutoAnswer?: boolean;
    enableImpairedRingtone?: boolean;
    enableAppIncomingCall?: boolean;
    enableAccountAppIncomingCall?: boolean;
    showAccountCallButton?: boolean;
    showCallButton?: boolean;
    showAccountCallButton2?: boolean;
    showCallButton2?: boolean;
    moveFamilyEnabled?: boolean;
    showAccountCheckInButton?: boolean;
    accountAutoAnswer?: boolean;
    showAccountEmergencyButton?: boolean;
    showEmergencyButton?: boolean;
    showWeather?: boolean;
    showCallPhoneFromDevice?: boolean;
    enableAccountMemoryBox?: boolean;
    enableMemoryBox?: boolean;
    enableBlankMemoryBox?: boolean;
    showCallButtonsBar?: boolean;
    showAccountCallButtonsBar?: boolean;
    enableVoiceMessage?: boolean;
    enableExternalApps?: boolean;
    accountExternalApps?: boolean;
    careDeliveredMessagesArray?: PatientMessage[];
    arrayDeliveryDesc?: PatientMessage[];
    arrayDeliveryAsc?: PatientMessage[];
    arrayStatusDesc?: PatientMessage[];
    arrayStatusAsc?: PatientMessage[];
    sorteredArray3?: PatientMessage[];
    sortDeliveryDesc?: string;
    sortDeliveryAsc?: string;
    sortStatusDesc?: string;
    sortStatusAsc?: string;
    sortCategoryDesc?: string;
    sortCategoryAsc?: string;
    moveFamilyModalOpen?: boolean;
    bloodPressureAlert?: any;
    oxiMeterAlert?: any;
    scaleAlert?: any;
    temperatureAlert?: any;
    bloodSugarAlert?: any;
    sleepHoursAlert?: any;
  }>({
    saveNewNoteBtnStyle: 'nucleus-submit-btn-disabled',
    sortDeliveryDesc: ' nucleus-icon-inactive hide ',
    sortDeliveryAsc: ' nucleus-icon-active  ',

    sortStatusDesc: ' nucleus-icon-inactive ',
    sortStatusAsc: ' nucleus-icon-inactive hide ',

    sortCategoryDesc: ' nucleus-icon-inactive ',
    sortCategoryAsc: ' nucleus-icon-inactive hide ',
    callLogsContent: '',
    requestLogsContent: 'hide',
    careLogsContent: 'hide',
    careFamilyContent: 'hide',
    careNotesContent: 'hide',
    careMessagesContent: 'hide',
    biometricsContent: 'hide',
    configurationContent: 'hide',
    scheduleContent: 'hide',
    bulletinContent: 'hide',

    patientMessagesMode: 'Scheduled',
  });

  const setState = (newState: any) => {
    setReactState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  const textNewPatientNote = useRef<HTMLTextAreaElement>();

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // NOTES SECTION
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleFilterPatientNotes = () => {
    console.log('handleFilterPatientNotes');

    if ((state.fromDateTimeNotes && state.fromDateTimeNotes.toString() == '') || (state.toDateTimeNotes && state.toDateTimeNotes.toString() == '')) {
      return;
    }

    const fromDate = state.fromDateTimeNotes || moment().subtract(7, 'day');
    const toDate = state.toDateTimeNotes || moment();
    const fromDateTimeNotes = moment(fromDate);
    const toDateTimeNotes = moment(toDate);

    if (toDateTimeNotes.isBefore(fromDateTimeNotes, 'minute')) {
      setState({
        notesDatesErrorVisible: ' ',
        notesDatesErrorText: ' The From Date has to be before the To Date.',
      });
      return;
    }

    CarePatientStore.getPatientNotes({
      PatientID: patientID,
      From: fromDateTimeNotes.toISOString(),
      To: toDateTimeNotes.toISOString(),
    });
  };

  const onUpdatePatientNoteAction = () => {
    handleFilterPatientNotes();
  };

  const onAddPatientNoteAction = response => {
    if (response.ok) {
      handleFilterPatientNotes();

      ($('#modalPatientNewNote') as unknown as JQuery).modal('close');
      Message.show(AuthStore.getPatientLabel() + ' Note created');
    } else {
      Message.show('Error creating ' + AuthStore.getPatientLabel() + ' Note, please try again.');
    }
    setState({
      saveNewNoteBtnStyle: 'nucleus-submit-btn',
    });
  };

  const resetTabs = () => {
    setState({
      callLogsContent: 'hide',
      requestLogsContent: 'hide',
      careLogsContent: 'hide',
      careFamilyContent: 'hide',
      careNotesContent: 'hide',
      careMessagesContent: 'hide',
      biometricsContent: 'hide',
      configurationContent: 'hide',
      scheduleContent: 'hide',
      bulletinContent: 'hide',

      callLogsTab: '',
      requestLogsTab: '',
      careLogsTab: '',
      careFamilyTab: '',
      careNotesTab: '',
      careMessagesTab: '',
      biometricsTab: '',
      configTab: '',

      deviceSettingsTab: '',
      biometricsAlertsTab: '',
      buttonsTab: '',
      scheduleTab: '',
      bulletinTab: '',
      appsTab: '',
    });
  };

  const handleRequestLogsTab = () => {
    resetTabs();
    setState({
      requestLogsContent: '',
      requestLogsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(2);

    CarePatientStore.loadPatientRequestLogs();
  };

  const handleCaresTab = () => {
    resetTabs();
    setState({
      careLogsContent: '',
      careLogsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(3);
    CarePatientStore.getPatientAssignments({
      PatientID: patientID,
      ShowAll: true,
    });
    //Message.show("Loading Visit Logs...");
  };

  const handleFamilyTab = () => {
    resetTabs();
    setState({
      careFamilyContent: '',
      careFamilyTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(4);
    CarePatientStore.getFamilyMembers({
      PatientID: patientID,
    });
    //Message.show("Loading Family Members...");
  };

  const handleNotesTab = () => {
    resetTabs();
    setState({
      careNotesContent: '',
      careNotesTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(5);
    handleFilterPatientNotes();
    //Message.show("Loading " + AuthStore.getPatientLabel() + " Notes...");
  };

  const handleMessagesTab = () => {
    resetTabs();
    setState({
      careMessagesContent: '',
      careMessagesTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(6);
  };

  const handleBiometricsTab = () => {
    resetTabs();
    setState({
      biometricsContent: '',
      biometricsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(7);
    GeneralStore.requestRefreshBiometrics();
  };

  const handleDeviceSettingsTab = () => {
    resetTabs();
    setState({
      configurationContent: '',
      deviceSettingsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(8);
    CarePatientStore.openDevicesSubTab();
  };

  const handleBiometricsAlertsTab = () => {
    resetTabs();
    setState({
      configurationContent: '',
      biometricsAlertsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(9);
    CarePatientStore.openBiometricsAlertsSubTab();
  };

  const handleBulletinTab = () => {
    resetTabs();
    setState({
      bulletinContent: '',
      bulletinTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(12);
  };

  const handleAppsTab = () => {
    resetTabs();
    setState({
      appsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(13);
  };
  // DEPRECATED - Fri Dec 6th 2019
  // https://nucleusintercom.atlassian.net/browse/CARE-6239
  const handleButtonsTab = () => {
    resetTabs();
    setState({
      configurationContent: '',
      buttonsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(10);
    CarePatientStore.openButtonsSubTab();
  };

  const handleScheduleTab = () => {
    resetTabs();
    setState({
      scheduleContent: '',
      scheduleTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(11);
    CarePatientStore.getPatientSchedule({
      PatientID: patientID,
    });
  };

  const handleConfigTab = () => {
    resetTabs();
    setState({
      configurationContent: '',
      configTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(8);
    CarePatientStore.getPatientData({
      PatientID: patientID,
    });
    Message.show('Loading Patient Configuration...');
  };

  const handleDefaultTab = () => {
    resetTabs();
    setState({
      callLogsContent: '',
      callLogsTab: 'staffSubTab',
    });
    GeneralStore.setClientTab(1);
    handleFilterPatientCallLogs();
  };

  const handleCallLogsTab = () => {
    handleDefaultTab();
  };

  //Messages
  const handleScheduledMessagesTab = () => {
    setState({
      patientMessagesMode: 'Scheduled',
    });

    CarePatientStore.getScheduledPatientMessages({
      PatientID: patientID,
    });

    CarePatientStore.refreshScheduledMessagesTab();
  };

  const handleDeliveredMessagesTab = () => {
    setState({
      patientMessagesMode: 'Delivered',
    });
    // CarePatientStore.getPatientData({
    //     PatientID : this.props.patientID
    // });
    const fromDate = new Date(state.fromDateTimeMsgs);
    const toDate = new Date(state.toDateTimeMsgs);

    if (toDate < fromDate) {
      Message.show('The From Date has to be before the To Date.');
      // setState({
      //     datesErrorVisible : ' ',
      //     datesErrorText : 'The From Date has to be before the To Date.'
      // });
      return;
    }

    const fromDateParam =
      fromDate.toLocaleDateString('en-US', dateOptions) +
      ' ' +
      fromDate.toLocaleTimeString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      });
    const toDateParam =
      toDate.toLocaleDateString('en-US', dateOptions) +
      ' ' +
      toDate.toLocaleTimeString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      });

    setState({
      loading: true,
      fromTime: fromDateParam,
      toTime: toDateParam,
    });
    CarePatientStore.getDeliveredPatientMessages({
      PatientID: patientID,
      FromDateTime: fromDateParam,
      ToDateTime: toDateParam,
    });
  };

  const handleNewNote = () => {
    ($('#modalPatientNewNote') as unknown as JQuery).modal('open');
    textNewPatientNote.current.value = '';
    setState({
      saveNewNoteBtnStyle: 'nucleus-submit-btn-disabled',
    });
  };

  const handleCloseNewNoteModal = () => {
    ($('#modalPatientNewNote') as unknown as JQuery).modal('close');
  };

  const validateNewNoteText = () => {
    if (textNewPatientNote.current.value.length > 0) {
      setState({
        saveNewNoteBtnStyle: 'nucleus-submit-btn',
      });
    } else {
      setState({
        saveNewNoteBtnStyle: 'nucleus-submit-btn-disabled',
      });
    }
  };

  const handleSaveNewNote = () => {
    CarePatientStore.addPatientNote({
      PatientID: patientID,
      UserID: AuthStore.getUserID(),
      Note: textNewPatientNote.current.value,
    });
    setState({
      saveNewNoteBtnStyle: 'nucleus-submit-btn-disabled',
    });
  };

  const handleFilterPatientMessages = () => {
    if (!(state.fromDateTimeMsgs && state.fromDateTimeMsgs.toString().length > 0)) {
      Message.show('Please enter the From Date');
      return;
    }

    if (!(state.toDateTimeMsgs && state.toDateTimeMsgs.toString().length > 0)) {
      Message.show('Please enter the From Date');
      return;
    }

    const fromDate = new Date(state.fromDateTimeMsgs);
    const toDate = new Date(state.toDateTimeMsgs);

    if (toDate < fromDate) {
      Message.show('The From Date has to be before the To Date.');
      return;
    }

    const fromDateParam =
      fromDate.toLocaleDateString('en-US', dateOptions) +
      ' ' +
      fromDate.toLocaleTimeString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      });
    const toDateParam =
      toDate.toLocaleDateString('en-US', dateOptions) +
      ' ' +
      toDate.toLocaleTimeString('en-US', {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      });

    console.log('From Param ', fromDate);
    console.log('To Param ', toDate);

    setState({
      loading: true,
      fromTime: fromDateParam,
      toTime: toDateParam,
    });
    CarePatientStore.getDeliveredPatientMessages({
      PatientID: patientID,
      FromDateTime: fromDateParam,
      ToDateTime: toDateParam,
    });

    Message.show('Refreshing Messages.');
  };

  const onUpdateCallRequestLogNotesAction = () => {
    handleFilterPatientCallLogs();
  };

  const onCallEndedReportedAction = () => {
    handleFilterPatientCallLogs();
  };

  const onRefreshPatientGetCallLogsAction = () => {
    console.log('onRefreshPatientGetCallLogsAction');
    handleFilterPatientCallLogs();
  };

  const handleFilterPatientCallLogs = () => {
    console.log('handleFilterPatientCallLogs', state.fromDateTimeLogs, state.toDateTimeLogs);
    const fromDate = state.fromDateTimeLogs || moment().subtract(7, 'day');
    const toDate = state.toDateTimeLogs || moment();
    const fromDateTimeLogs = moment(fromDate);
    const toDateTimeLogs = moment(toDate);
    console.log('toDateTimeLogs', toDateTimeLogs);
    console.log('fromDateTimeLogs', fromDateTimeLogs);
    if (toDateTimeLogs.isBefore(fromDateTimeLogs, 'minute')) {
      setState({
        logsDatesErrorVisible: ' ',
        logsDatesErrorText: ' The From Date has to be before the To Date.',
      });
      return;
    }

    CarePatientStore.getPatientCallLogs({
      PatientID: patientID,
      From: moment(fromDateTimeLogs).toISOString(),
      To: moment(toDateTimeLogs).toISOString(),
    });

    setState({
      patientCallLogs: [],
      loadingLogs: true,
    });
  };

  const onPatientGetCallLogsAction = response => {
    console.log('patientProfile:onPatientGetCallLogsAction', response);

    if (response.ok) {
      setState({
        patientCallLogs: response.CallLogs,
        loadingLogs: false,
      });
    } else {
      console.log('Error getting ' + AuthStore.getPatientLabel().toLowerCase() + ' information');
    }
  };

  const onGetPatientNotesAction = response => {
    console.log('onGetPatientNotesAction', response);

    if (response.ok) {
      setState({
        careNotesArray: response.Notes,
      });
    } else {
      Message.show('There was an error getting the ' + AuthStore.getPatientLabel().toLowerCase() + ' notes, please refresh.');
    }
  };

  const onGetPatientFamilyMembersAction = response => {
    console.log('onGetPatientFamilyMembersAction', response);
    if (response.ok) {
      setState({
        familyMembersArray: response.FamilyMembers,
        moveFamilyEnabled: response.MoveFamilyEnabled,
        accountAutoAnswer: response.AccountAutoAnswer,
      });
    }
  };
  const onGetDeliveredPatientMessagesAction = (response: GetScheduledPatientMessagesResponse) => {
    console.log('onGetDeliveredPatientMessagesAction ==>', response);
    setState({
      loading: false,
    });

    if (response.ok) {
      setState({
        careDeliveredMessagesArray: [],
      });

      let sorteredArray1: PatientMessage[] = [].concat(response.Messages);
      let sorteredArray2: PatientMessage[] = [].concat(response.Messages);

      let sorteredArray3: PatientMessage[] = [].concat(response.Messages);
      let sorteredArray4: PatientMessage[] = [].concat(response.Messages);

      sorteredArray1 = sorteredArray1.sort(
        (a, b) => new Date(b.DeliveryTime).getTime() - new Date(a.DeliveryTime).getTime() || new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime(),
      );
      sorteredArray2 = sorteredArray2.sort(
        (a, b) => Number(new Date(a.DeliveryTime)) - Number(new Date(b.DeliveryTime)) || Number(new Date(a.CreatedDate)) - Number(new Date(b.CreatedDate)),
      );

      sorteredArray3 = sorteredArray3.sort((a, b) => {
        //@ts-ignore
        const textA = a.Status.toLowerCase();
        //@ts-ignore
        const textB = b.Status.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });
      sorteredArray4 = sorteredArray4.sort((a, b) => {
        //@ts-ignore
        const textA = a.Status.toLowerCase();
        //@ts-ignore
        const textB = b.Status.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      setState({
        careDeliveredMessagesArray: sorteredArray1,
        arrayDeliveryDesc: sorteredArray1,
        arrayDeliveryAsc: sorteredArray2,

        arrayStatusDesc: sorteredArray3,
        arrayStatusAsc: sorteredArray4,

        // arrayCategoryDesc: sorteredArray5,
        // arrayCategoryAsc: sorteredArray6,
      });
    } else {
      Message.show('There was an error getting the ' + AuthStore.getPatientLabel().toLowerCase() + ' messages, please refresh.');
    }
  };

  const onPatientGetDataAction = response => {
    console.log('patientProfile:onPatientGetDataAction', response);

    if (response.ok) {
      setState({
        patientTimezone: response.TimezoneString,

        PhotosCount: response.PhotosCount,
        enableBiometrics: response.EnableBiometrics,
        enableAccountBiometrics: response.AccountEnableBiometrics,
        enableBiometricsFeature: response.EnableBiometrics && response.AccountEnableBiometrics,

        enableBluetoothButtons: response.EnableBluetoothButtons,
        enableAccountBluetoothButtons: response.AccountEnableBluetoothButtons,
        enableBluetoothButtonsFeature: response.EnableBluetoothButtons && response.AccountEnableBluetoothButtons,

        enableAutoAnswer: response.EnableAutoAnswer,
        enableAccountAutoAnswer: response.AccountEnableAutoAnswer,

        enableImpairedRingtone: response.EnableImpairedRingtone,

        enableAppIncomingCall: response.EnableAppIncomingCall,
        enableAccountAppIncomingCall: response.AccountEnableAppIncomingCall,

        enableButtonsSchedule: response.EnableCallButtonSchedule || response.EnableEmergencyButtonSchedule,

        showAccountCallButton: response.AccountShowCallButton,
        showCallButton: response.ShowCallButton,

        showAccountCallButton2: response.AccountShowCallButton2,
        showCallButton2: response.ShowCallButton2,

        showAccountCheckInButton: response.AccountShowCheckInButton,
        showCheckInButton: response.ShowCheckInButton,

        showAccountEmergencyButton: response.AccountShowEmergencyButton,
        showEmergencyButton: response.ShowEmergencyButton,

        showWeather: response.ShowWeather,
        showCallPhoneFromDevice: response.CallPhoneFromDevice,

        enableAccountMemoryBox: response.AccountEnableMemoryBox,
        enableMemoryBox: response.EnableMemoryBox,
        enableBlankMemoryBox: response.EnableBlankMemoryBox,
        showCallButtonsBar: response.ShowCallButtonsBar,
        showAccountCallButtonsBar: response.AccountShowCallButtonsBar,

        enableVoiceMessage: response.EnableVoiceMessage,
        enableExternalApps: response.EnableExternalApps,
        accountExternalApps: response.AccountExternalAppsEnabled,
      });
    } else {
      console.log('Error getting ' + AuthStore.getPatientLabel().toLowerCase() + ' Information');
    }
  };

  const callLogsList = state.patientCallLogs?.map((log, key) => {
    return (
      <CallLogItemRow
        key={key}
        LogID={log.ID}
        StartTime={log.StartTime}
        DateString={log.DateString}
        TimeString={log.TimeString}
        WasAnswered={log.WasAnswered}
        InProgress={log.InProgress}
        EndTime={log.EndTime}
        Participants={log.Participants}
        PatientTimeZone={log.patientTimezone}
        RequestID={log.RequestID}
        RequestType={log.RequestType}
        RequestTime={log.RequestTime}
        RequestEscalated={log.Escalated}
        Class={{
          ID: log.ClassID,
        }}
        HasNotes={log.HasNotes}
        Notes={log.Notes}
        NotesUserID={log.NotesUserID}
        PatientLabel={log.clientRoleName}
        Status={log.Status}
      />
    );
  });

  const careNotesList = state.careNotesArray?.map((note, key) => {
    return (
      <CarePatientNoteItemRow
        key={key}
        index={key}
        NoteID={note.ID}
        PatientID={patientID}
        Note={note.Note}
        CreatedDate={note.CreatedDate}
        UserID={note.UserID}
        User={note.User}
        TimeZone={state.patientTimezone}
        Type={note.Type}
      />
    );
  });

  const familyMembersList: JSX.Element[] | undefined = state.familyMembersArray?.map((family, key) => {
    return (
      <CareFamilyMemberItemRow
        key={key}
        MemberID={family.FamilyMemberID}
        Admin={family.Admin}
        Name={family.FirstName + ' ' + family.LastName}
        FirstName={family.FirstName}
        LastName={family.LastName}
        Email={family.Email}
        Telephone={family.Telephone}
        UserImageThumb={family.UserImageThumb}
        ProfileCreated={family.ProfileCreated}
        //ReceiveUpdates={family.ReceiveUpdates}
        AutoAnswer={family.AutoAnswer}
        AccountAutoAnswer={state.accountAutoAnswer}
      />
    );
  });

  const assignmentsList = state.assignmentsArray?.map((assignment, key) => {
    return (
      <CarePatientAssignmentItemRow
        key={key}
        AssignmentID={assignment.ID}
        CareGiverID={assignment.CareGiverID}
        CareGiverName={assignment.CareGiverName}
        Message={assignment.Message}
        Date={assignment.Date}
        SmsDelivered={assignment.SmsDelivered}
        MessageRead={assignment.MessageRead}
        CheckIn={assignment.CheckIn}
        CheckOut={assignment.CheckOut}
        TimeZone={state.patientTimezone}
      />
    );
  });

  const careDeliveredMessagesList = state.careDeliveredMessagesArray?.map((message, key) => {
    return (
      <CarePatientMessageItemRow
        key={key}
        Message={message.Message}
        DeliveryDate={message.DeliveryTime}
        Status={message.Status}
        SignatureUrl={message.SignatureUrl}
        //Category={message.Category}
        Timezone={message.Timezone}
      />
    );
  });

  const onGetPatientAssignmentsAction = response => {
    console.log('onGetPatientAssignmentsAction');
    console.log(response);
    if (response.ok) {
      setState({
        assignmentsArray: [],
      });
      setState({
        assignmentsArray: response.Assignments,
        patientTimezone: response.PatientTimezone,
      });
    }
  };

  const handleSortDeliveryDesc = () => {
    console.log('handleSortDeliveryDesc');
    state.careDeliveredMessagesArray = [];

    setState({
      sortDeliveryDesc: ' nucleus-icon-inactive hide ',
      sortDeliveryAsc: ' nucleus-icon-active ',

      sortStatusDesc: ' nucleus-icon-inactive ',
      sortStatusAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careDeliveredMessagesArray: state.arrayDeliveryDesc,
    });
  };

  const handleSortDeliveryAsc = () => {
    console.log('handleSortDeliveryAsc');
    state.careDeliveredMessagesArray = [];

    setState({
      sortDeliveryDesc: ' nucleus-icon-active ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortStatusDesc: ' nucleus-icon-inactive ',
      sortStatusAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careDeliveredMessagesArray: state.arrayDeliveryAsc,
    });
  };

  const handleSortStatusDesc = () => {
    console.log('handleSortStatusDesc');

    state.careDeliveredMessagesArray = [];

    setState({
      sortDeliveryDesc: ' nucleus-icon-inactive ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide',

      sortStatusDesc: ' nucleus-icon-inactive hide ',
      sortStatusAsc: ' nucleus-icon-active ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careDeliveredMessagesArray: state.arrayStatusDesc,
    });
  };

  const handleSortStatusAsc = () => {
    console.log('handleSortStatusAsc');
    state.careDeliveredMessagesArray = [];

    setState({
      sortDeliveryDesc: ' nucleus-icon-inactive ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide',

      sortStatusDesc: ' nucleus-icon-active ',
      sortStatusAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careDeliveredMessagesArray: state.arrayStatusAsc,
    });
  };

  const handleNewPatientMessageModal = () => {
    CarePatientStore.handleNewPatientMessageModal();
  };

  const onRefreshMessageTabAction = () => {
    if (state.patientMessagesMode === 'Scheduled') {
      handleScheduledMessagesTab();
    } else {
      handleDeliveredMessagesTab();
    }
  };

  const deleteAllFamilyMembers = () => {
    if (window.confirm('Are you sure you want to Delete all ' + AuthStore.getFamilyLabelPlural() + '?')) {
      CarePatientStore.deleteFamilyMembers({
        PatientID: patientID,
      });
    }
  };

  const moveAllFamilyMembers = () => {
    console.log('moveAllFamilyMembers');

    setState({
      moveFamilyModalOpen: true,
    });
  };

  const handleCloseMoveFamilyModal = () => {
    console.log('handleCloseMoveFamilyModal');
    setState({
      moveFamilyModalOpen: false,
    });
  };

  const onPatientGetBiometricDataAction = response => {
    console.log('patientProfile:onPatientGetBiometricDataAction', response);

    if (response.ok) {
      setState({
        bloodPressureAlert: response.PatientBloodPressureAlert,
        oxiMeterAlert: response.PatientOxiMeterAlert,
        scaleAlert: response.PatientScaleAlert,
        temperatureAlert: response.PatientTemperatureAlert,
        bloodSugarAlert: response.PatientBloodSugarAlert,
        sleepHoursAlert: response.PatientSleepAlert,
      });
    } else {
      console.log('Error getting ' + AuthStore.getPatientLabel().toLowerCase() + ' Biometric Information');
    }
  };

  const refreshFamilyMembers = () => {
    CarePatientStore.getFamilyMembers({
      PatientID: patientID,
    });
  };

  const onDeletePatientNoteAction = () => {
    handleFilterPatientNotes();
  };

  const onUpdateCallLogNotesAction = () => {
    handleFilterPatientNotes();
  };

  useEffect(() => {
    CareCommunicationStore.on('onCallEndedReported', onCallEndedReportedAction);
    CarePatientStore.on('onDeletePatientNote', onDeletePatientNoteAction);
    CarePatientStore.on('onUpdateCallLogNotesAction', onUpdateCallLogNotesAction);
    CarePatientStore.on('onRefreshPatientGetCallLogs', onRefreshPatientGetCallLogsAction);
    CarePatientStore.on('onAddPatientNote', onAddPatientNoteAction);
    CarePatientStore.on('onUpdatePatientNote', onUpdatePatientNoteAction);
    CarePatientStore.on('onPatientGetCallLogs', onPatientGetCallLogsAction);
    CarePatientStore.on('onGetPatientNotes', onGetPatientNotesAction);
    CarePatientStore.on('onPatientGetData', onPatientGetDataAction);
    CarePatientStore.on('onGetPatientFamilyMembers', onGetPatientFamilyMembersAction);
    CarePatientStore.on('onGetDeliveredPatientMessages', onGetDeliveredPatientMessagesAction);
    CarePatientStore.on('onRefreshMessageTab', onRefreshMessageTabAction);
    CarePatientStore.on('onPatientGetBiometricData', onPatientGetBiometricDataAction);
    CarePatientStore.on('onGetPatientAssignments', onGetPatientAssignmentsAction);
    CarePatientStore.on('onUpdateCallRequestLogNotes', onUpdateCallRequestLogNotesAction);
    return () => {
      CarePatientStore.removeListener('onUpdateCallRequestLogNotes', onUpdateCallRequestLogNotesAction);
      CarePatientStore.removeListener('onUpdateCallLogNotesAction', onUpdateCallLogNotesAction);
      CarePatientStore.removeListener('onDeletePatientNote', onDeletePatientNoteAction);
      CareCommunicationStore.off('onCallEndedReported', onCallEndedReportedAction);
      CarePatientStore.removeListener('onRefreshPatientGetCallLogs', onRefreshPatientGetCallLogsAction);
      CarePatientStore.removeListener('onPatientGetCallLogs', onPatientGetCallLogsAction);
      CarePatientStore.removeListener('onUpdatePatientNote', onUpdatePatientNoteAction);
      CarePatientStore.removeListener('onAddPatientNote', onAddPatientNoteAction);
      CarePatientStore.removeListener('onGetPatientNotes', onGetPatientNotesAction);
      CarePatientStore.removeListener('onPatientGetData', onPatientGetDataAction);
      CarePatientStore.removeListener('onGetPatientFamilyMembers', onGetPatientFamilyMembersAction);
      CarePatientStore.removeListener('onGetDeliveredPatientMessages', onGetDeliveredPatientMessagesAction);
      CarePatientStore.removeListener('onRefreshMessageTab', onRefreshMessageTabAction);
      CarePatientStore.removeListener('onPatientGetBiometricData', onPatientGetBiometricDataAction);
      CarePatientStore.removeListener('onGetPatientAssignments', onGetPatientAssignmentsAction);
    };
  }, []);

  useEffect(() => {
    const toDate = moment().endOf('day').toDate();
    const fromDate = moment().subtract(7, 'days').startOf('day').toDate();

    setState({
      fromDateTimeLogs: fromDate,
      toDateTimeLogs: toDate,

      fromDateTimeMsgs: fromDate,
      toDateTimeMsgs: toDate,

      fromDateTimeNotes: fromDate,
      toDateTimeNotes: toDate,
    });

    const fromParamString = moment(fromDate).toISOString();
    const toParamString = moment(toDate).toISOString();

    CarePatientStore.getPatientCallLogs({
      PatientID: patientID,
      From: fromParamString,
      To: toParamString,
    });
    if (GeneralStore.getClientTab() == 2) {
      handleRequestLogsTab();
    } else if (GeneralStore.getClientTab() == 3) {
      handleCaresTab();
    } else if (GeneralStore.getClientTab() == 4) {
      CarePatientStore.getFamilyMembers({
        PatientID: patientID,
      });
      handleFamilyTab();
    } else if (GeneralStore.getClientTab() == 5) {
      CarePatientStore.getPatientNotes({
        PatientID: patientID,
        From: fromParamString,
        To: toParamString,
      });
      handleNotesTab();
    } else if (GeneralStore.getClientTab() == 6) {
      CarePatientStore.getDeliveredPatientMessages({
        PatientID: patientID,
        FromDateTime: '',
        ToDateTime: '',
      });
      handleMessagesTab();
    } else if (GeneralStore.getClientTab() == 7) {
      handleBiometricsTab();
    } else if (GeneralStore.getClientTab() == 8) {
      handleConfigTab();
      handleDeviceSettingsTab();
    } else if (GeneralStore.getClientTab() == 9) {
      handleConfigTab();
      handleBiometricsAlertsTab();
    } else if (GeneralStore.getClientTab() == 10) {
      handleConfigTab();
      handleButtonsTab();
    } else if (GeneralStore.getClientTab() == 11) {
      handleConfigTab();
      handleScheduleTab();
    } else if (GeneralStore.getClientTab() == 12) {
      handleBulletinTab();
    } else if (GeneralStore.getClientTab() == 13) {
      handleAppsTab();
    } else {
      handleDefaultTab();
    }
  }, []);

  let patientLogsTable = '';
  let patientLogsLoading = 'hide';
  if (state.loadingLogs) {
    patientLogsTable = ' hide';
    patientLogsLoading = ' ';
  }

  let tableResultsView = ' hide';
  //let tableNoResultsView = ' hide';
  let tableLoadingView = '';
  if (state.loading) {
    tableResultsView = ' hide';
    //tableNoResultsView = ' hide';
    tableLoadingView = '';
  } else {
    tableResultsView = ' ';
    //tableNoResultsView = ' hide';
    tableLoadingView = ' hide';
  }

  const patientMessagesEnabled = patientDevices?.length > 0;

  return (
    <>
      <div className="patientSettings">
        <div className="bottom-border-menu ">
          <div className="tabSmallContainer">
            <a id={'tab-call-logs'} className={'staffTabsSmall2 nucleus-link ' + state.callLogsTab} onClick={handleCallLogsTab}>
              &nbsp;&nbsp;Outgoing Call Logs&nbsp;&nbsp;
            </a>
          </div>

          <div className="tabSmallContainer">
            <a id="tab-request-logs" className={'staffTabsSmall2 nucleus-link ' + state.requestLogsTab} onClick={handleRequestLogsTab}>
              &nbsp;&nbsp;Call Request Log&nbsp;&nbsp;
            </a>
          </div>

          {state.showCheckInButton ? (
            <div className="tabSmallContainer">
              <a id="tab-care-logs" className={'staffTabsSmall2 nucleus-link ' + state.careLogsTab} onClick={handleCaresTab}>
                &nbsp;&nbsp;Visit Logs&nbsp;&nbsp;
              </a>
            </div>
          ) : (
            <a />
          )}

          <div className="tabSmallContainer">
            <a id="tab-care-messages" className={'staffTabsSmall2 nucleus-link ' + state.careMessagesTab} onClick={handleMessagesTab}>
              &nbsp;&nbsp;Messages&nbsp;&nbsp;
            </a>
          </div>

          {state.enableBiometrics ? (
            <div className="tabSmallContainer">
              <a id="tab-biometrics" className={'staffTabsSmall2 nucleus-link ' + state.biometricsTab} onClick={handleBiometricsTab}>
                &nbsp;&nbsp;Biometrics&nbsp;&nbsp;
              </a>
            </div>
          ) : (
            <a />
          )}

          <div className="tabSmallContainer">
            <a id="tab-care-family" className={'staffTabsSmall2 nucleus-link ' + state.careFamilyTab} onClick={handleFamilyTab}>
              &nbsp;&nbsp;{AuthStore.getFamilyLabelPlural()}&nbsp;&nbsp;
            </a>
          </div>

          <div className="tabSmallContainer">
            <a id="tab-care-notes" className={'staffTabsSmall2 nucleus-link ' + state.careNotesTab} onClick={handleNotesTab}>
              &nbsp;&nbsp; {AuthStore.getPatientLabel() + ' Notes'}&nbsp;&nbsp;
            </a>
          </div>

          {state.enableButtonsSchedule ? (
            <div className="tabSmallContainer">
              <a id="tab-schedule" className={'staffTabsSmall2 nucleus-link ' + state.scheduleTab} onClick={handleScheduleTab}>
                &nbsp;&nbsp;Buttons Schedule &nbsp;&nbsp;
              </a>
            </div>
          ) : (
            <a />
          )}
          <div className="tabSmallContainer">
            <a id="tab-device-settings" className={'staffTabsSmall2 nucleus-link ' + state.deviceSettingsTab} onClick={handleDeviceSettingsTab}>
              &nbsp;&nbsp; Device Settings &nbsp;&nbsp;
            </a>
          </div>
          {AuthStore.canAccessBulletin() && AuthStore.isBulletinEnabled() && (
            <div className="tabSmallContainer">
              <a id="tab-device-settings" className={'staffTabsSmall2 nucleus-link ' + state.bulletinTab} onClick={handleBulletinTab}>
                &nbsp;&nbsp; Bulletin Board &nbsp;&nbsp;
              </a>
            </div>
          )}
          {AuthStore.canAccessExternalApps() && (
            <div className="tabSmallContainer">
              <a id="tab-device-settings" className={'staffTabsSmall2 nucleus-link ' + state.appsTab} onClick={handleAppsTab}>
                &nbsp;&nbsp; Apps &nbsp;&nbsp;
              </a>
            </div>
          )}
        </div>

        <br />

        <div className={'row padding-15 ' + state.callLogsContent}>
          <div className="row  ">
            &nbsp;&nbsp;
            <span className="nucleus-labels nucleus-bold">From:</span> &nbsp;
            <div style={{ width: 140, display: 'inline-flex' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  //ref={this.fromDateTimeLogsRef}
                  variant="dialog"
                  className="browser-default"
                  value={state.fromDateTimeLogs}
                  onChange={date => {
                    setState({
                      fromDateTimeLogs: date.toDate(),
                      logsDatesErrorVisible: ' hide',
                    });
                  }}
                  autoOk={true}
                  onError={console.log}
                  //minDate={new Date("2018-01-01T00:00")}
                  format={'MM/DD/YYYY'}
                />
              </MuiPickersUtilsProvider>
            </div>
            &nbsp;&nbsp;&nbsp;
            <span className="nucleus-labels nucleus-bold">To:</span> &nbsp;
            <div style={{ width: 140, display: 'inline-flex' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  //ref={this.toDateTimeLogsRef}
                  variant="dialog"
                  className="browser-default"
                  value={state.toDateTimeLogs}
                  onChange={date => {
                    setState({
                      toDateTimeLogs: date.toDate(),
                      logsDatesErrorVisible: ' hide',
                    });
                  }}
                  autoOk={true}
                  onError={console.log}
                  minDate={new Date(state.fromDateTimeLogs).setDate(new Date(state.fromDateTimeLogs).getDate() + 2)}
                  format={'MM/DD/YYYY'}
                />
              </MuiPickersUtilsProvider>
            </div>
            &nbsp;&nbsp;&nbsp;
            <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={handleFilterPatientCallLogs}>
              Filter Results
            </a>
            &nbsp;
            <br />
            <span className={'nucleus-form-error center-align ' + state.logsDatesErrorVisible}>{state.logsDatesErrorText}</span>
          </div>
          <br />

          <div className={'assignments-table-pending ' + patientLogsTable}>
            <table className="bordered highlight  nucleus-table">
              <thead>
                <tr>
                  <th className="table-col-25">
                    <span className="nucleus-table-header-small">Date & Time</span>
                  </th>
                  <th className="table-col-10 left-align">
                    <span className="nucleus-table-header-small">Call Type</span>
                  </th>
                  <th className="table-col-10 left-align">
                    <span className="nucleus-table-header-small">Status</span>
                  </th>
                  <th className="table-col-10 left-align">
                    <span className="nucleus-table-header-small">Duration</span>
                  </th>
                  <th className="table-col-30 left-align">
                    <span className="nucleus-table-header-small">Participants</span>
                  </th>
                  <th className="table-col-10 center-align">
                    <span className="nucleus-table-header-small">Emergency</span>
                  </th>
                  <th className="table-col-5 right-align">
                    <span className="nucleus-table-header-small">Notes&nbsp;</span>
                  </th>
                </tr>
              </thead>
              <tbody>{callLogsList}</tbody>
            </table>
          </div>

          <div className={'row center-align ' + patientLogsLoading}>
            <br />
            <br />
            <br />
            <Spinner size={34} speed={0.6} animating={state.loadingLogs} style={{ display: 'inline-flex' }} />
            <br />
            <br />
            <br />
          </div>
        </div>

        <div className={'row padding-15 ' + state.requestLogsContent}>
          <PatientRequestSection patientID={patientID} />
        </div>

        <div className={'row padding-15 ' + state.careLogsContent}>
          <div className="assignments-table-pending ">
            <table className="bordered highlight  nucleus-table">
              <thead>
                <tr>
                  <th className="table-col-17 left-align">
                    <span className="nucleus-table-header-small">{AuthStore.getCaregiverLabel()}</span>
                  </th>
                  <th className="table-col-22 left-align">
                    <span className="nucleus-table-header-small">Message</span>
                  </th>
                  <th className="table-col-18 left-align">
                    <span className="nucleus-table-header-small">Date/Time of Care</span>
                  </th>
                  <th className="table-col-7 center-align">
                    <span className="nucleus-table-header-small">SMS Sent</span>
                  </th>

                  <th className="table-col-18 left-align">
                    <span className="nucleus-table-header-small">Check-in</span>
                  </th>
                  <th className="table-col-18 left-align">
                    <span className="nucleus-table-header-small">Check-out</span>
                  </th>
                </tr>
              </thead>

              <tbody>{assignmentsList}</tbody>
            </table>
          </div>
        </div>

        <div className={'row padding-15 ' + state.careMessagesContent}>
          <div>
            <a
              className={'staffTabsSmall nucleus-link ' + (state.patientMessagesMode == 'Scheduled' ? 'staffTab' : '')}
              onClick={handleScheduledMessagesTab}
              style={{ marginLeft: 12, marginRight: 12, paddingLeft: 6, paddingRight: 6 }}
            >
              Scheduled Messages
            </a>
            <a
              className={'staffTabsSmall nucleus-link ' + (state.patientMessagesMode == 'Delivered' ? 'staffTab' : '')}
              onClick={handleDeliveredMessagesTab}
              style={{ marginLeft: 12, marginRight: 12, paddingLeft: 6, paddingRight: 6 }}
            >
              Delivered Messages
            </a>
          </div>
          <br />

          <div className={'assignments-table-pending a-bit-lower '}>
            <PatientMessages
              patientID={patientID}
              patientMessagesEnabled={patientMessagesEnabled}
              enableVoiceMessage={state.enableVoiceMessage}
              enableBiometrics={state.enableBiometrics}
              tableVisibility={state.patientMessagesMode == 'Scheduled'}
            />
          </div>
          {state.patientMessagesMode == 'Delivered' && (
            <div className={'assignments-table-pending a-bit-lower padding-15'}>
              <div className="row  ">
                &nbsp;&nbsp;
                <span className="nucleus-labels nucleus-bold">From:</span> &nbsp;
                <div style={{ width: 140, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      //ref={this.fromDateTimeMsgsRef}
                      variant="dialog"
                      className="browser-default"
                      value={state.fromDateTimeMsgs}
                      onChange={date => {
                        setState({
                          fromDateTimeMsgs: date.toDate(),
                        });
                      }}
                      autoOk={true}
                      onError={console.log}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={'MM/DD/YYYY'}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                &nbsp;&nbsp;&nbsp;
                <span className="nucleus-labels nucleus-bold">To:</span> &nbsp;
                <div style={{ width: 140, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      //ref={this.toDateTimeMsgsRef}
                      variant="dialog"
                      className="browser-default"
                      value={state.toDateTimeMsgs}
                      onChange={date => {
                        setState({
                          toDateTimeMsgs: date.toDate(),
                        });
                      }}
                      autoOk={true}
                      onError={console.log}
                      minDate={new Date(state.fromDateTimeMsgs).setDate(new Date(state.fromDateTimeMsgs).getDate() + 2)}
                      format={'MM/DD/YYYY'}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                &nbsp;&nbsp;&nbsp;
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={handleFilterPatientMessages}>
                  Filter Results
                </a>
                &nbsp;
              </div>
              <br />
              <table className={'bordered highlight nucleus-table ' + tableResultsView}>
                <thead>
                  <tr>
                    <th className="table-col-20 left-align">
                      <span className="nucleus-table-header-small">Sent</span>
                      <a className={'nucleus-font-medium ' + state.sortDeliveryDesc} onClick={handleSortDeliveryDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a className={'nucleus-font-medium ' + state.sortDeliveryAsc} onClick={handleSortDeliveryAsc}>
                        &nbsp;▼
                      </a>
                    </th>
                    {/*<th className="table-col-20 left-align"><span className="nucleus-table-header-small">Sender</span></th> */}
                    <th className="table-col-40 left-align">
                      <span className="nucleus-table-header-small">Message</span>
                    </th>
                    {/*<th className="table-col-20 left-align"><span className="nucleus-table-header-small">Created Date</span></th> */}

                    <th className="table-col-20 left-align">
                      <span className="nucleus-table-header-small">Response</span>
                      <a className={'nucleus-font-medium ' + state.sortStatusDesc} onClick={handleSortStatusDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a className={'nucleus-font-medium ' + state.sortStatusAsc} onClick={handleSortStatusAsc}>
                        &nbsp;▼
                      </a>
                    </th>

                    <th className="table-col-20 center-align">
                      <span className="nucleus-table-header-small">Signature</span>
                    </th>
                  </tr>
                </thead>

                <tbody>{careDeliveredMessagesList}</tbody>
              </table>

              <div className={'row center-align ' + tableLoadingView}>
                <br />
                <br />
                <br />
                <Spinner color="#2096F3" size={34} speed={0.6} animating={state.loading} style={{ display: 'inline-flex' }} />
                <br />
                <br />
                <br />
              </div>

              <div className="fixed-action-btn">
                {!state.loading && state.careDeliveredMessagesArray && state.fromTime && (
                  <PatientMessagesReports
                    patientLabel={AuthStore.getPatientLabel()}
                    patientFullName={patientFullName}
                    patientMessages={state.careDeliveredMessagesArray}
                    fromTime={state.fromTime}
                    toTime={state.toTime}
                  ></PatientMessagesReports>
                )}
                {patientMessagesEnabled && (
                  <a
                    className={'btn-floating btn-large waves-effect waves-light green tooltipped '}
                    data-position="left"
                    data-delay="50"
                    data-tooltip="New Message"
                    onClick={handleNewPatientMessageModal}
                    style={{ marginRight: 12 }}
                  >
                    <i className="material-icons">message</i>
                  </a>
                )}
              </div>
            </div>
          )}
        </div>

        {state.enableBiometrics ? (
          <div className={'row padding-15 ' + state.biometricsContent}>
            <PatientBiometricsSection
              patientID={patientID}
              accountID={AuthStore.getUserAccountID()}
              patientDevices={patientDevices}
              enableBiometrics={state.enableBiometrics}
              bloodPressureAlert={state.bloodPressureAlert}
              oxiMeterAlert={state.oxiMeterAlert}
              scaleAlert={state.scaleAlert}
              temperatureAlert={state.temperatureAlert}
              bloodSugarAlert={state.bloodSugarAlert}
              sleepHoursAlert={state.sleepHoursAlert}
            />
          </div>
        ) : (
          <div />
        )}

        <div className={'row padding-15 ' + state.careFamilyContent}>
          <div className="assignments-table-pending ">
            <table className="bordered highlight  nucleus-table">
              <thead>
                <tr>
                  <th className="table-col-25 left-align">
                    <span className="nucleus-table-header-small">Name</span>
                  </th>
                  <th className="table-col-25 left-align">
                    <span className="nucleus-table-header-small">Phone Number</span>
                  </th>
                  <th className="table-col-25 left-align">
                    <span className="nucleus-table-header-small">Email</span>
                  </th>
                  <th className="table-col-15 center-align">
                    <span className="nucleus-table-header-small">Status</span>
                  </th>
                  <th className="table-col-10 center-align">
                    <span className="nucleus-table-header-small">Admin</span>
                  </th>
                </tr>
              </thead>
              <tbody>{familyMembersList}</tbody>
            </table>

            {familyMembersList?.length > 0 && state.moveFamilyEnabled && (
              <div style={{ marginTop: 25 }}>
                <a className="txt-white nucleus-font-14 nucleus-red-btn" onClick={deleteAllFamilyMembers} style={{ marginTop: 25 }}>
                  {'Delete All ' + AuthStore.getFamilyLabelPlural()}
                </a>

                <a className="txt-white nucleus-font-14 nucleus-red-btn" onClick={moveAllFamilyMembers} style={{ marginTop: 25, marginLeft: 25 }}>
                  {'Move All ' + AuthStore.getFamilyLabelPlural()}
                </a>
              </div>
            )}
          </div>

          <div className="fixed-action-btn btn-add-new">
            <Link to={'/newFamilyMember/' + patientID}>
              <span
                className="btn-floating btn-large waves-effect waves-light orange tooltipped "
                data-position="left"
                data-delay="50"
                data-tooltip={'New ' + AuthStore.getFamilyLabel()}
              >
                <i className="material-icons">add</i>
              </span>
            </Link>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>

        <div className={'row padding-15 ' + state.careNotesContent}>
          <div className="row  ">
            &nbsp;&nbsp;
            <span className="nucleus-labels nucleus-bold">From:</span> &nbsp;
            <div style={{ width: 140, display: 'inline-flex' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  //ref={this.fromDateTimeNotesRef}
                  variant="dialog"
                  className="browser-default"
                  value={state.fromDateTimeNotes}
                  onChange={date => {
                    setState({
                      fromDateTimeNotes: date.toDate(),
                      notesDatesErrorVisible: ' hide',
                    });
                  }}
                  autoOk={true}
                  onError={console.log}
                  //minDate={new Date("2018-01-01T00:00")}
                  format={'MM/DD/YYYY'}
                />
              </MuiPickersUtilsProvider>
            </div>
            &nbsp;&nbsp;&nbsp;
            <span className="nucleus-labels nucleus-bold">To:</span> &nbsp;
            <div style={{ width: 140, display: 'inline-flex' }}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  //ref={this.toDateTimeNotesRef}
                  variant="dialog"
                  className="browser-default"
                  value={state.toDateTimeNotes}
                  onChange={date => {
                    setState({
                      toDateTimeNotes: date.toDate(),
                      notesDatesErrorVisible: ' hide',
                    });
                  }}
                  autoOk={true}
                  onError={console.log}
                  minDate={new Date(state.fromDateTimeNotes).setDate(new Date(state.fromDateTimeNotes).getDate() + 2)}
                  format={'MM/DD/YYYY'}
                />
              </MuiPickersUtilsProvider>
            </div>
            &nbsp;&nbsp;&nbsp;
            <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={handleFilterPatientNotes}>
              Filter Results
            </a>
            &nbsp;
            <br />
            <span className={'nucleus-form-error center-align ' + state.notesDatesErrorVisible}>{state.notesDatesErrorText}</span>
          </div>
          <br />

          <div className="assignments-table-pending ">
            <table id={'notes-table'} className="bordered highlight  nucleus-table">
              <thead id={'notes-thead'}>
                <tr>
                  <th className="table-col-20 left-align">
                    <span className="nucleus-table-header-small">Date & Time</span>
                  </th>
                  <th className="table-col-20 left-align">
                    <span className="nucleus-table-header-small">Added By</span>
                  </th>
                  <th className="table-col-15 left-align">
                    <span className="nucleus-table-header-small">Type</span>
                  </th>
                  <th className="table-col-45 left-align">
                    <span className="nucleus-table-header-small">Notes</span>
                  </th>

                  {/* <th className="center-align"><span className="nucleus-table-header-small">Read</span></th> */}
                </tr>
              </thead>

              <tbody>{careNotesList}</tbody>
            </table>
          </div>

          <div className="fixed-action-btn btn-add-new">
            <a
              id="new-patient-note"
              className="btn-floating btn-large waves-effect waves-light orange tooltipped "
              data-position="left"
              data-delay="50"
              data-tooltip={'New ' + AuthStore.getPatientLabel() + ' Note'}
              onClick={handleNewNote}
            >
              <i className="material-icons">add</i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>

        <div className={'row ' + state.configurationContent}>
          <PatientConfigSection
            patientID={patientID}
            PhotosCount={state.PhotosCount}
            showAccountCallButton={state.showAccountCallButton}
            showCallButton={state.showCallButton}
            showAccountCallButton2={state.showAccountCallButton2}
            showCallButton2={state.showCallButton2}
            showAccountCheckInButton={state.showAccountCheckInButton}
            showCheckInButton={state.showCheckInButton}
            showAccountEmergencyButton={state.showAccountEmergencyButton}
            showEmergencyButton={state.showEmergencyButton}
            showWeather={state.showWeather}
            showCallPhoneFromDevice={state.showCallPhoneFromDevice}
            enableAccountMemoryBox={state.enableAccountMemoryBox}
            enableMemoryBox={state.enableMemoryBox}
            enableBlankMemoryBox={state.enableBlankMemoryBox}
            showCallButtonsBar={state.showCallButtonsBar}
            showAccountCallButtonsBar={state.showAccountCallButtonsBar}
            zipCode={patientZipCode}
            enableBiometrics={state.enableBiometrics}
            enableAccountBiometrics={state.enableAccountBiometrics}
            enableBluetoothButtons={state.enableBluetoothButtons}
            enableAccountBluetoothButtons={state.enableAccountBluetoothButtons}
            enableAutoAnswer={state.enableAutoAnswer}
            enableAccountAutoAnswer={state.enableAccountAutoAnswer}
            enableAccountAppIncomingCall={state.enableAccountAppIncomingCall}
            enableAppIncomingCall={state.enableAppIncomingCall}
            enableImpairedRingtone={state.enableImpairedRingtone}
            enableExternalApps={state.enableExternalApps}
            accountExternalApps={state.accountExternalApps}
          />
        </div>

        {state.bulletinContent == '' && <PatientBulletin patientID={patientID}></PatientBulletin>}

        {state.appsTab == 'staffSubTab' && <ExternalApps patientID={patientID}></ExternalApps>}
        <div className={'row padding-15 ' + state.scheduleContent}>
          <ScheduleView patientID={patientID} />
        </div>
      </div>
      <PatientProfileMoveFamilyModal
        patientID={patientID}
        patientName={patientFullName}
        open={state.moveFamilyModalOpen}
        closeModal={handleCloseMoveFamilyModal}
        refresh={refreshFamilyMembers}
      />
      <div id="modalPatientNewNote" className="modal modalPatientProfile modalScrollableNotWide">
        <div className="modal-content nucleus-modal-content txt-special-color">
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseNewNoteModal} />
          <h3 className="nucleus-page-subtitle">&nbsp; New {AuthStore.getPatientLabel()} Note</h3>
          <div className="row"></div>
          <span>&nbsp;&nbsp; Notes</span>
          <div className="input-field col s12 modalCallLogContent2">
            <textarea id="note-textarea" ref={textNewPatientNote} className="nucleus-textarea-big " defaultValue="" onChange={validateNewNoteText}></textarea>
            <br />
            <div className="row no-margin">
              <div className="col s12 center-align no-margin">
                <p></p>
                <span className=" modalSeparator">
                  {' '}
                  <label> &nbsp;</label>
                </span>
                <a id="handle-save-note" className={'txt-white nucleus-font-modal-btn ' + state.saveNewNoteBtnStyle} onClick={handleSaveNewNote}>
                  Save
                </a>
                <div>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
