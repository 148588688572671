//@ts-nocheck
import { RootState, getPatientAppsThunk, resetPatientAppThunk, updatePatientAppsConfigThunk } from '@nucleus-care/nucleuscare-backend-client';
import React from 'react';
import { useState, useEffect } from 'react';
import {
  useSelector,
  useDispatch,
  //DefaultRootState
} from 'react-redux';

import { SpinnerCircular } from 'spinners-react';
import AuthStore from '../../../stores/AuthStore';
import Message from '../../../utils/Message';

import AppTableRow from '../../ExternalApps/AppTableRow';

type AppsParams = {
  patientId: string;
};

const ExternalApps = props => {
  const patientAppsStore = useSelector((state: RootState) => {
    //console.log("patientAppsStore useSelector", state.accountExternalApps.patientApps);
    return state.accountExternalApps.patientApps;
  });

  const dispatch = useDispatch();
  const [stateLoading, setStateLoading] = useState(false);
  const [stateSearchText, setStateSearchText] = useState('');

  const [stateSortingStyles, setStateSortingStyles] = useState({
    appNameAsc: ' nucleus-icon-inactive ',
    appNameDesc: ' nucleus-icon-inactive hide ',
  });
  const [stateSortingMode, setStateSortingMode] = useState('appNameAsc');
  const handleSortingMode = (tag, tag2) => {
    console.log('handleSortingMode', tag, tag2);
    const object = {
      appNameAsc: ' nucleus-icon-inactive ',
      appNameDesc: ' nucleus-icon-inactive hide ',
    };
    object[tag] = ' nucleus-icon-active ';
    object[tag2] = ' nucleus-icon-inactive hide ';
    setStateSortingStyles(object);
    setStateSortingMode(tag);
  };

  useEffect(() => {
    console.log('ExternalApps useEffect');

    refreshAppsList();
    $('.modalBulletin').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }, []);

  const refreshAppsList = () => {
    dispatch(getPatientAppsThunk(props.patientID));
    setStateLoading(true);
  };

  const onSearchTextChange = textInput => {
    console.log('onSearchTextChange', textInput.target.value);
    setStateSearchText(textInput.target.value);
  };

  useEffect(() => {
    console.log('ExternalApps useEffect patientAppsStore', patientAppsStore.getLoading, patientAppsStore.getComplete);
    if (!patientAppsStore.getLoading) {
      if (patientAppsStore.getComplete) {
        //Message.show("Report updated");
        setStateLoading(false);
      }
      // dispatch(
      // 	reportsSliceClean()
      // );
    } else if (patientAppsStore.error) {
      Message.show('Error getting Apps');
    }
  }, [patientAppsStore.getLoading, patientAppsStore.getComplete]);

  useEffect(() => {
    console.log('ExternalApps useEffect patientAppsStore', patientAppsStore.resetLoading, patientAppsStore.resetComplete);
    if (!patientAppsStore.resetLoading) {
      if (patientAppsStore.resetComplete) {
        //Message.show("App updated");
        setStateLoading(false);
      }
      refreshAppsList();
    } else if (patientAppsStore.error) {
      Message.show('Error getting Apps');
    }
  }, [patientAppsStore.resetLoading, patientAppsStore.resetComplete]);

  const patientAppConfigChange = appData => {
    //console.log("patientAppConfigChange", appData);
    setStateLoading(true);
    dispatch(
      resetPatientAppThunk({
        patientId: appData.PatientID,
        externalAppId: appData.ExternalAppID,
        appEnabled: appData.enabled,
      }),
    );
  };

  const handleEnableAllApps = () => {
    console.log('handleEnableAllApps');

    dispatch(
      updatePatientAppsConfigThunk({
        patientId: props.patientID,
        accountId: AuthStore.getUserAccountID(),
        appsEnabled: true,
      }),
    );
    //setStateSaveButtonStyle("nucleus-submit-btn");
    //$('#modalEnableAllApps').modal('open');
  };

  // const onEnableAllApps =(overwriteConfig) =>{
  //     console.log("onEnableAllApps overwriteConfig", overwriteConfig);
  //     setStateSaveButtonStyle("nucleus-submit-btn-disabled");
  //     setStateUpdateMode(2);
  //     dispatch(
  //         updateAccountAppsConfigThunk({
  //             accountId: AuthStore.getUserAccountID().toLowerCase(),
  //             appsEnabled: true,
  //             overwriteConfig:overwriteConfig
  //         })
  //     )
  // }

  const handleDisableAllApps = () => {
    console.log('handleDisableAllApps');

    dispatch(
      updatePatientAppsConfigThunk({
        patientId: props.patientID,
        accountId: AuthStore.getUserAccountID(),
        appsEnabled: false,
      }),
    );
    //setStateSaveButtonStyle("nucleus-submit-btn");
    //$('#modalDisableAllApps').modal('open');
  };

  // const onDisableAllApps =(save) =>{
  //     console.log("onDisableAllApps", save);
  //     setStateSaveButtonStyle("nucleus-submit-btn-disabled");
  //     setStateUpdateMode(2);
  //     if (save){
  //         dispatch(
  //             updateAccountAppsConfigThunk({
  //                 accountId: AuthStore.getUserAccountID().toLowerCase(),
  //                 appsEnabled: false,
  //                 overwriteConfig: false
  //             })
  //         )
  //     }
  // }

  const generateTableRows = () => {
    console.log('render generateTableRows>', patientAppsStore);
    const appsRowArray = [];
    if (patientAppsStore && patientAppsStore.apps && patientAppsStore.apps.length > 0) {
      let array = [].concat(patientAppsStore.apps);

      if (stateSortingMode == 'appNameDesc') {
        array = array.sort((a, b) => {
          const textA = a.Name.toLowerCase();
          const textB = b.Name.toLowerCase();
          if (textB < textA)
            //sort string ascending
            return -1;
          if (textB > textA) return 1;
          return 0;
        });
      } else if (stateSortingMode == 'appNameAsc') {
        array = array.sort((a, b) => {
          const textA = a.Name.toLowerCase();
          const textB = b.Name.toLowerCase();
          if (textA < textB)
            //sort string ascending
            return -1;
          if (textA > textB) return 1;
          return 0;
        });
      }

      if (stateSearchText.length == 0) {
        array.map((appData, key) => {
          appsRowArray.push(<AppTableRow key={key} data={appData} onToggle={patientAppConfigChange}></AppTableRow>);
        });
      } else {
        const text = stateSearchText.toLocaleLowerCase();
        array.map((appData, key) => {
          if (
            appData.Name.toLowerCase().indexOf(text) >= 0
            //(identifier.toLowerCase().indexOf(text) >= 0) ||
          ) {
            appsRowArray.push(<AppTableRow key={key} data={appData} onToggle={patientAppConfigChange}></AppTableRow>);
          }
        });
      }
      //console.log("Generated array", outputArray);
      if (appsRowArray && appsRowArray.length > 0) {
        return <tbody>{appsRowArray}</tbody>;
      } else {
        return (
          <tbody className="center-align ">
            <tr style={{ borderBottomStyle: 'none' }}>
              <td colSpan="7" className="center-align ">
                <br />
                <br />
                <br />
                <span className="nucleus-table-header-medium text-gray"> {'There are no apps with this name'} </span>
                <br />
                <br />
                <br />
              </td>
            </tr>
          </tbody>
        );
      }
    }
    return (
      <tbody className="center-align ">
        <tr style={{ borderBottomStyle: 'none' }}>
          <td colSpan="7" className="center-align ">
            <br />
            <br />
            <br />
            <span className="nucleus-table-header-medium text-gray"> {'There are no available apps'} </span>
            <br />
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <div className="">
      <div className="row  " style={{ padding: 15 }}>
        <div style={{ marginTop: 0, marginBottom: 0, height: 56 }}>
          <div className="row a-bit-lower left-align">
            <div className="col s6 m6 no-margin">
              <span className="nucleus-tools-page-text-title a-bit-lower">Search &nbsp;&nbsp;&nbsp;</span>
              <input type="text" className="nucleus-input-form-small-left" style={{ width: 500 }} onChange={onSearchTextChange} placeholder={'Search for an App'} />
              &nbsp;&nbsp;&nbsp;
              <a
                className="txt-white nucleus-font-small nucleus-submit-btn-small"
                onClick={() => {
                  refreshAppsList();
                }}
              >
                {' '}
                Refresh
              </a>
            </div>
            <div className="ol s6 m6 no-margin right-align">
              &nbsp;&nbsp;
              <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={handleEnableAllApps}>
                {' '}
                Enable All Apps
              </a>
              &nbsp;&nbsp;
              <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={handleDisableAllApps}>
                {' '}
                Disable All Apps
              </a>
              {stateLoading && (
                <div style={{ marginLeft: 5, marginTop: 5, marginRight: 10 }}>
                  <div>
                    <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="34" thickness="100" />
                  </div>
                </div>
              )}
              {!stateLoading && <div style={{ width: 36, marginLeft: 5, marginTop: 5, marginRight: 10 }}></div>}
            </div>
          </div>
        </div>
        <div className="users-table-pending altoImportantContainer">
          <table className="bordered highlight nucleus-table">
            <thead>
              <tr>
                <th className="table-col-30">
                  <span className="nucleus-table-header-medium ">{'System Apps'}</span>
                  <a class={'nucleus-font-small ' + stateSortingStyles.appNameDesc} onClick={() => handleSortingMode('appNameAsc', 'appNameDesc')}>
                    {' '}
                    &nbsp;▼
                  </a>
                  <a class={'nucleus-font-small ' + stateSortingStyles.appNameAsc} onClick={() => handleSortingMode('appNameDesc', 'appNameAsc')}>
                    &nbsp;▲
                  </a>
                </th>
                <th className="table-col-70 left-align">
                  <span className="nucleus-table-header-medium">{'Make Available'}</span>
                  {/* <a class={ "nucleus-font-small " + stateSortingStyles.nameDesc } onClick={()=>handleSortingMode("nameAsc","nameDesc")}> &nbsp;▼</a>
                                    <a class={ "nucleus-font-small " + stateSortingStyles.nameAsc  } onClick={()=>handleSortingMode("nameDesc","nameAsc")} >&nbsp;▲</a> */}
                </th>
              </tr>
            </thead>
            {generateTableRows()}
          </table>
        </div>
      </div>
    </div>
  );
};

export default ExternalApps;
