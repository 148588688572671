//@ts-nocheck
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import NucleusBulletinEditor from '../../../components/BulletinBoard/NucleusBulletinEditor';
import Message from '../../../utils/Message';

import { RootState, getBulletinsThunk, createBulletinThunk, updateBulletinThunk, deleteBulletinThunk, patientBulletinSliceClean } from '@nucleus-care/nucleuscare-backend-client';

type PatientBulletinParams = {
  patientId: string;
};

const PatientBulletin = props => {
  const patientBulletinStore = useSelector((state: RootState) => {
    //console.log("patientBulletinStore useSelector", state);
    return state.patientBulletins.data;
  });

  const dispatch = useDispatch();

  const [stateBtn1Style, setStateBtn1Style] = useState('staffTab');
  const [stateBtn2Style, setStateBtn2Style] = useState('');
  const [stateBtn3Style, setStateBtn3Style] = useState('');
  const [stateBtn4Style, setStateBtn4Style] = useState('');
  const [stateBtn5Style, setStateBtn5Style] = useState('');

  const [stateExistingButtons, setStateExistingButtons] = useState(0);
  const [stateAccountButtons, setStateAccountButtons] = useState(0);

  const [stateBtn1Name, setStateBtn1Name] = useState('');
  const [stateBtn2Name, setStateBtn2Name] = useState('');
  const [stateBtn3Name, setStateBtn3Name] = useState('');
  const [stateBtn4Name, setStateBtn4Name] = useState('');
  const [stateBtn5Name, setStateBtn5Name] = useState('');

  const [stateSelectedButton, setStateSelectedButton] = useState({});
  const [stateSelectedButtonIndex, setStateSelectedButtonIndex] = useState(0);

  const [stateLoading, setStateLoading] = useState(false);
  const [stateEditorState, setStateEditorState] = useState(EditorState.createEmpty());

  const txtNewBulletinNameRef = useRef(null);
  // First UseEffect
  useEffect(() => {
    console.log('PatientBulletin useEffect');
    console.log('PatientBulletin useEffect props', props);

    refreshBulletins();
    $('.modalBulletin').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }, []);

  const refreshBulletins = () => {
    dispatch(getBulletinsThunk(props.patientID));
    setStateLoading(true);
  };

  useEffect(() => {
    console.log('PatientBulletin useEffect patientBulletinStore', patientBulletinStore);

    if (!patientBulletinStore.getLoading) {
      if (patientBulletinStore.getComplete) {
        setStateLoading(false);
        //Message.show(`Bulletins loaded.`);
        dispatch(patientBulletinSliceClean());
        if (patientBulletinStore.activeAccountButtons) {
          console.log('PatientBulletin useEffect patientBulletinStore activeAccountButtons', patientBulletinStore.activeAccountButtons);
          setStateAccountButtons(patientBulletinStore.activeAccountButtons);
        }
        console.log('patientBulletinStore.personal', patientBulletinStore.personal.length);
        setStateExistingButtons(patientBulletinStore.personal.length);
        if (patientBulletinStore.personal.length > 0) {
          refreshButtons();
        }
      } else if (patientBulletinStore.error) {
        Message.show(`Error loading 1`);
      }
    }

    if (!patientBulletinStore.postLoading) {
      if (patientBulletinStore.postComplete) {
        //Message.show(`Bulletins created.`);
        setStateLoading(false);
        dispatch(patientBulletinSliceClean());
        refreshBulletins();
        handleCloseNewBulletinModal();
      } else if (patientBulletinStore.error) {
        Message.show(`Error loading 2`);
      }
    }
    if (!patientBulletinStore.putLoading) {
      if (patientBulletinStore.putComplete) {
        //Message.show(`Bulletins created.`);
        setStateLoading(false);
        dispatch(patientBulletinSliceClean());
        refreshBulletins();
      } else if (patientBulletinStore.error) {
        Message.show(`Error loading 3`);
      }
    }
    if (!patientBulletinStore.deleteLoading) {
      if (patientBulletinStore.deleteComplete) {
        //Message.show(`Bulletins created.`);
        setStateLoading(false);
        dispatch(patientBulletinSliceClean());
        refreshBulletins();
        handleCloseDeleteBulletinModal();

        if (stateSelectedButtonIndex > 0) {
          handleSelectBtn(stateSelectedButtonIndex - 1);
        }
      } else if (patientBulletinStore.error) {
        Message.show(`Error loading 4`);
      }
    }
  }, [patientBulletinStore]);

  const refreshButtons = () => {
    console.log('loadingButtons!!!!!!!!!!!!!!!', stateSelectedButtonIndex);
    if (patientBulletinStore.personal[0]) {
      setStateBtn1Name(patientBulletinStore.personal[0].Name);
    }
    if (patientBulletinStore.personal[1]) {
      setStateBtn2Name(patientBulletinStore.personal[1].Name);
    }
    if (patientBulletinStore.personal[2]) {
      setStateBtn3Name(patientBulletinStore.personal[2].Name);
    }
    if (patientBulletinStore.personal[3]) {
      setStateBtn4Name(patientBulletinStore.personal[3].Name);
    }
    if (patientBulletinStore.personal[4]) {
      setStateBtn5Name(patientBulletinStore.personal[4].Name);
    }

    let editorState = ''; //EditorState.createEmpty()
    let htmlContent = '';
    if (patientBulletinStore.personal[stateSelectedButtonIndex] && patientBulletinStore.personal[stateSelectedButtonIndex].Content) {
      htmlContent = patientBulletinStore.personal[stateSelectedButtonIndex].Content;
    }
    //console.log("Refreshing!!!!", patientBulletinStore.personal[stateSelectedButtonIndex]);
    const contentBlock = htmlToDraft(htmlContent);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    }
    setStateSelectedButton(patientBulletinStore.personal[stateSelectedButtonIndex]);
    setStateEditorState(editorState);
  };

  const handleSelectBtn = index => {
    console.log('handleSelectBtn', index);

    setStateSelectedButtonIndex(index);
    setStateSelectedButton(patientBulletinStore.personal[index]);
    console.log('setStateSelectedButton', patientBulletinStore.personal[index]);

    let editorState = ''; //EditorState.createEmpty()
    const htmlContent = patientBulletinStore.personal[index].Content || '';
    const contentBlock = htmlToDraft(htmlContent);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      editorState = EditorState.createWithContent(contentState);
    }
    setStateEditorState(editorState);

    setStateBtn1Style('');
    setStateBtn2Style('');
    setStateBtn3Style('');
    setStateBtn4Style('');
    setStateBtn5Style('');
    switch (index) {
      case 0:
        setStateBtn1Style('staffTab');
        break;
      case 1:
        setStateBtn2Style('staffTab');
        break;
      case 2:
        setStateBtn3Style('staffTab');
        break;
      case 3:
        setStateBtn4Style('staffTab');
        break;
      case 4:
        setStateBtn5Style('staffTab');
        break;
    }
  };

  const handleNewBulletin = () => {
    $('#modalNewBulletin').modal('open');
    txtNewBulletinNameRef.current.value = '';
  };

  const handleCloseNewBulletinModal = () => {
    $('#modalNewBulletin').modal('close');
  };

  const handleSaveNewBulletin = () => {
    if (txtNewBulletinNameRef.current.value == '') {
      Message.show('The Name is empty');
      txtNewBulletinNameRef.current.focus();
      return;
    }
    setStateLoading(true);
    dispatch(
      createBulletinThunk({
        patientId: props.patientID,
        name: txtNewBulletinNameRef.current.value,
      }),
    );
  };

  const handleSaveBulletinContent = currentEditorState => {
    console.log('handleSaveBulletinContent', currentEditorState);
    console.log('handleSaveBulletinContent selected', stateSelectedButton);

    const text = draftToHtml(convertToRaw(currentEditorState.getCurrentContent()));
    const textHTML = text.toString('html');
    console.log('save text', text);
    console.log('save textHTML', textHTML);
    setStateLoading(true);
    dispatch(
      updateBulletinThunk({
        bulletinId: stateSelectedButton.ID.toLowerCase(),
        content: textHTML,
      }),
    );
  };

  const handleDeleteBulletinModal = () => {
    $('#modalDeleteBulletin').modal('open');
  };

  const handleCloseDeleteBulletinModal = () => {
    $('#modalDeleteBulletin').modal('close');
  };

  const handleDeleteBulletin = () => {
    setStateLoading(true);
    dispatch(
      deleteBulletinThunk({
        bulletinId: stateSelectedButton.ID.toLowerCase(),
      }),
    );
  };

  return (
    <div className="">
      <div className="row  " style={{ paddingRight: 25 }}>
        {stateExistingButtons >= 1 && (
          <a class={'staffTabs2 nucleus-link ' + stateBtn1Style} onClick={handleSelectBtn.bind(this, 0)}>
            {stateBtn1Name}
          </a>
        )}

        {stateExistingButtons >= 2 && (
          <a class={'staffTabs2 nucleus-link ' + stateBtn2Style} onClick={handleSelectBtn.bind(this, 1)}>
            {stateBtn2Name}
          </a>
        )}

        {stateExistingButtons >= 3 && (
          <a class={'staffTabs2 nucleus-link ' + stateBtn3Style} onClick={handleSelectBtn.bind(this, 2)}>
            {stateBtn3Name}
          </a>
        )}

        {stateExistingButtons >= 4 && (
          <a class={'staffTabs2 nucleus-link ' + stateBtn4Style} onClick={handleSelectBtn.bind(this, 3)}>
            {stateBtn4Name}
          </a>
        )}

        {stateExistingButtons >= 5 && (
          <a class={'staffTabs2 nucleus-link ' + stateBtn5Style} onClick={handleSelectBtn.bind(this, 4)}>
            {stateBtn5Name}
          </a>
        )}
        <br />
        <br />
        <div style={{ paddingLeft: 20 }}>
          <NucleusBulletinEditor
            loading={stateLoading}
            buttonsEnabled={stateExistingButtons > 0}
            editorState={stateEditorState}
            onSaveBulletin={handleSaveBulletinContent}
            onHandleDeleteButton={handleDeleteBulletinModal}
            emptyEditorMessage={'There are no Bulletin buttons created'}
          ></NucleusBulletinEditor>
        </div>
        {5 - (stateAccountButtons + stateExistingButtons) > 0 && (
          <div className="fixed-action-btn btn-add-new">
            <a
              className="btn-floating btn-large waves-effect waves-light orange tooltipped "
              data-position="left"
              data-delay="50"
              data-tooltip="New Bulletin Button"
              onClick={handleNewBulletin}
            >
              <i className="material-icons">add</i>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        )}

        <div id="modalNewBulletin" className="modal modalBulletin modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseNewBulletinModal} />
            <h3 className="nucleus-page-subtitle">New Bulletin Board</h3>
            <br />
            <br />

            <div className="row no-margin">
              <span className="nucleus-labels nucleus-font-label-title">
                <b>{'Please enter the name you want to be displayed on the bulletin board.'}</b>
              </span>
              <br />
              <br />
              <input
                id="txtNewBulletinName"
                type="text"
                ref={txtNewBulletinNameRef}
                className="nucleus-input-form black-bottom-border"
                style={{ backgroundColor: 'white', paddingLeft: 5, width: '95%' }}
                maxLength={25}
                placeholder={'Name'}
                autocomplete="off" /*onChange={this.validateCaption}*/
              />
              <br />

              <div className="row no-margin">
                <div className="col s12 center-align" style={{ margin: 0, padding: 0, paddingBottom: 5 }}>
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <div>&nbsp;</div>
                  <a class={'txt-white nucleus-font-small nucleus-submit-btn' + (stateLoading ? '-disabled' : '')} onClick={handleCloseNewBulletinModal}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a class={'txt-white nucleus-font-small nucleus-submit-btn' + (stateLoading ? '-disabled' : '')} onClick={handleSaveNewBulletin}>
                    &nbsp;&nbsp;&nbsp;Save&nbsp;&nbsp;&nbsp;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalDeleteBulletin" className="modal modalBulletin modalScrollableNotWide">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseDeleteBulletinModal} />
            <h3 className="nucleus-page-subtitle">Delete Bulletin Board</h3>
            <br />
            <br />

            <div className="row no-margin">
              <span className="nucleus-labels nucleus-font-label-title">
                <b>{'Are you sure you want to delete the Bulletin ' + (stateSelectedButton ? stateSelectedButton.Name : '') + ' ?'}</b>
              </span>

              <div className="row no-margin">
                <div className="col s12 center-align" style={{ margin: 0, padding: 0, paddingBottom: 5 }}>
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <div>&nbsp;</div>
                  <a class={'txt-white nucleus-font-small nucleus-submit-btn' + (stateLoading ? '-disabled' : '')} onClick={handleCloseDeleteBulletinModal}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a class={'txt-white nucleus-font-small nucleus-submit-btn' + (stateLoading ? '-disabled' : '')} onClick={handleDeleteBulletin}>
                    &nbsp;&nbsp;&nbsp;Confirm&nbsp;&nbsp;&nbsp;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientBulletin;
