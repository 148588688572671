import Bugsnag from '@bugsnag/js';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AuthStore from '../stores/AuthStore';

export function AuthenticateBeforeRendering({ children }: { children: JSX.Element }) {
  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    AuthStore.getMe(dispatch)
      .then(response => {
        console.log('Response ----', response);
        if (process.env.REACT_APP_BUGSNAG_API_KEY) {
          Bugsnag.setUser(response.CareUserID, response.Email, response.FirstName + ' ' + response.LastName);
        }
        setAuthenticated(true);
      })
      .catch(error => {
        console.log('Error ----', error);
        AuthStore.logout(dispatch);
        navigate('/login', {
          replace: true,
        });
      });
  }, []);

  if (authenticated) {
    return children;
  }

  return null;
}
