import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useState } from 'react';
import authStore from 'stores/AuthStore';
import message from 'utils/Message';
import { GroupCategory } from './types';

const LIMIT = 20;

const useProvidersGroups = () => {
  const [page] = useState(1);
  const [hasMore] = useState(true);
  const [companies, setCompanies] = useState<GroupCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);

  const getCompanies = () => {
    setCompanies([]);
    if (isLoading || !hasMore) return false;
    setIsLoading(true);
    httpApi
      .get(`/providers/${authStore.getProviderID()}/groups?limit=${LIMIT}&page=${page}`)
      .then(({ data }) => {
        console.log('getCompanies(Providers) data', data);

        if (data) {
          setIsLoading(false);
          setTotal(data.totalProviderGroups);
          setCompanies(prevCompanies => [...prevCompanies, ...data.providerGroups]);
        }
      })
      .catch(() => {
        setIsLoading(false);
        message.show('Sorry, There was a problem getting provider groups. Please try again.');
      });
  };

  const loadMore = () => {
    console.log('ylog load more page', page);
    console.log('load more hasMore', hasMore);
    console.log('load more isLoading', isLoading);
    if (hasMore && !isLoading) {
      console.log('load more yes');
      getCompanies();
    }
  };

  return {
    companies,
    getCompanies,
    isLoading,
    loadMore,
    hasMore,
    total,
  };
};

export default useProvidersGroups;
