import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { v4 as uuid } from 'uuid';
import { IProviderListItem } from '../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { ProviderAvailabilityResponse } from '../pages/Providers/provider.types';

// MOCK API
export const getProviders = async (): Promise<IProviderListItem[]> => {
  return new Promise(resolve => {
    setTimeout(() => {
      const providers: IProviderListItem[] = [];
      for (let i = 1; i <= 5; i++) {
        const id = uuid();
        providers.push({
          ID: id,
          providerName: `Provider ${id[0]}`,
          numberOfAccounts: Math.floor(Math.random() * 100),
          numberOfDevices: Math.floor(Math.random() * 100),
          numberOfUsers: Math.floor(Math.random() * 100),
        });
      }
      resolve(providers);
    }, 1000);
  });
};
export const getProviderByID = async (providerId: string): Promise<IProviderListItem> => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({
        ID: providerId,
        providerName: providerId,
        numberOfAccounts: 0,
        numberOfDevices: 0,
        numberOfUsers: 0,
      });
    }, 1000);
  });
};

export const getProviderCallLogs = async ({
  providerId,
  userId,
  from,
  to,
  limit = 10000,
  page = 0,
}: {
  providerId: string;
  userId: string;
  from: string;
  to: string;
  limit?: number;
  page?: number;
}): Promise<void> => {
  const { data } = await httpApi.get(`v2/providers/${providerId}/users/${userId}/call_logs?fromDate=${from}&toDate=${to}&limit=${limit}&page=${page}`);
  return data;
};

export const getProviderAccounts = async (providerId: string): Promise<[]> => {
  const { data } = await httpApi.get(`/providers/${providerId}/accounts`);
  return data?.providersAccounts;
};

export const getCallLogDataCatalogs = async (providerId: string) => {
  console.log('getCallLogDataCatalogs providerId', providerId);

  try {
    const { data } = await httpApi.get('providers/' + providerId + '/config_catalogs');
    return data;
  } catch (e) {
    console.warn('getCallLogDataCatalogs error', e);
    return undefined;
  }
};

export const getCallLogData = async (providerId: string, callLogId: string) => {
  console.log('getCallLogData providerId', providerId);
  console.log('getCallLogData callLogId', callLogId);

  try {
    const { data } = await httpApi.get('providers/' + providerId + '/call/' + callLogId);
    return data;
  } catch (e) {
    return undefined;
  }
};

export type tUpdateCallLog = {
  Notes: string;
  CareProviderCallReasonID: string;
  CareProviderCallTypeID: string | null;
  CareProviderCallOutcomeID: string;
};

export const updateCallLogData = async (providerId: string, callLogId: string, data: tUpdateCallLog) => {
  console.log('updateCallLogData providerId', providerId);
  console.log('updateCallLogData callLogId', callLogId);

  try {
    const response = await httpApi.put('providers/' + providerId + '/call/' + callLogId, data);
    return response.data;
  } catch (e) {
    console.warn('updateCallLogData error', e);
    return undefined;
  }
};

export const getProviderAvailabilityData = async (providerId: string, from: string, to: string): Promise<any> => {
  const { data } = await httpApi.get(`/providers/${providerId}/schedule-availabilities?fromDateTime=${from}&toDateTime=${to}`);
  return data as ProviderAvailabilityResponse;
};

export const getProviderAvailabilityDataPaginated = async (providerId: string, from: string, to: string, page: number, limit: number, sorting: string = 'desc'): Promise<any> => {
  const { data } = await httpApi.get(`/providers/${providerId}/schedule-availabilities?fromDateTime=${from}&toDateTime=${to}&page=${page}&limit=${limit}&sort=${sorting}`);
  return data as ProviderAvailabilityResponse;
};
