export const ArrowLeftSVG = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3964 6.20389C10.5607 6.20389 10.7251 6.26444 10.8548 6.39418C11.1057 6.64503 11.1057 7.06021 10.8548 7.31106L6.06287 12.103L10.8548 16.895C11.1057 17.1458 11.1057 17.561 10.8548 17.8119C10.604 18.0627 10.1888 18.0627 9.93796 17.8119L4.68756 12.5615C4.43671 12.3106 4.43671 11.8954 4.68756 11.6446L9.93796 6.39418C10.0677 6.26444 10.2321 6.20389 10.3964 6.20389Z"
        fill="#0092FF"
      />
      <path
        d="M5.29372 11.4545L19.8513 11.4545C20.2059 11.4545 20.5 11.7486 20.5 12.1032C20.5 12.4579 20.2059 12.752 19.8513 12.752L5.29372 12.752C4.93908 12.752 4.64499 12.4579 4.64499 12.1032C4.64499 11.7486 4.93908 11.4545 5.29372 11.4545Z"
        fill="#0092FF"
      />
    </svg>
  );
};
