import styled from 'styled-components';

const NucleusNavBarContainer = styled.div`
  flex: 1;
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
  justify-content: flex-start;
  border-bottom: 2px solid #b4c6d3;
`;

const NucleusTab = styled.a<{ active?: boolean }>`
  color: #0a313f;
  margin-right: 30px;
  font-family: Fira Sans;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  padding: 20px 0 8px 0;
  &:hover {
    cursor: pointer;
  }
  ${({ active }) =>
    active &&
    `
        border-bottom: 4px solid #0092FF;
        margin-bottom: -3px;
    `}
`;

export interface NucleusTabProps {
  route: string;
  title: string;
  slug: string;
  disabled: boolean;
  fields?: string[];
  titleInfo?: string;
}

export type NucleusNavBarProps = {
  tabs: NucleusTabProps[];
  onTabChange?: (tab: NucleusTabProps) => void;
  activeTab?: string;
};

export const UINucleusNavBar = ({ tabs = [], onTabChange, activeTab }: NucleusNavBarProps) => {
  return (
    <NucleusNavBarContainer>
      {!!tabs.length &&
        tabs.map(tab => (
          <NucleusTab key={tab.slug} active={activeTab === tab.slug} onClick={() => onTabChange?.(tab)}>
            {tab.title}
          </NucleusTab>
        ))}
    </NucleusNavBarContainer>
  );
};
