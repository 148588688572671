//@ts-nocheck
import React from 'react';

import Spinner from 'react-activity/dist/Spinner';
import { AuthStore, GeneralStore, CareQueueStore, CarePatientStore } from 'stores';

import { JQuery } from 'types/jquery.types';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
import QueueItemRow from './QueueItemRow';

class Queue extends React.Component<
  {
    location: any;
    history: any;
    match: any;
  },
  {
    queueRequetsArray: any;
    queueEmergenciesArray: any;
    emergencyAllowed: boolean;
    selectedQueueItemRequestID: string;
    selectedQueueItemUserID: string;
    selectedQueueItemPatientName: string;
    markAsHandledRequestID: string;
    markAsHandledUserID: string;
    patientName: string;
    firstRequestedDateTime: string;
    isEmergency: string;
    escalated: string;
    numOfRequests: string;
    notes: string;
    alreadyHandledFirstName: string;
    alreadyHandledLastName: string;
    btnDeleteQueueItem: string;
    btnMarkAsHandledQueueItem: string;
    udpateDate: Date;
    patientsDataComplete: boolean;
    loadingHandleAll: boolean;
    deviceOffset?: any;
    timezoneString?: any;
  }
> {
  private dateOptions: any;
  private timeOptions: any;

  constructor(props) {
    super(props);
    validateAccessGranted('/queue');
    this.state = {
      queueRequetsArray: null,
      queueEmergenciesArray: null,

      emergencyAllowed: AuthStore.getEmergencyModeActive().toString() == 'true',

      selectedQueueItemRequestID: '',
      selectedQueueItemUserID: '',
      selectedQueueItemPatientName: '',

      markAsHandledRequestID: '',
      markAsHandledUserID: '',
      patientName: '',
      firstRequestedDateTime: '',
      isEmergency: '',
      escalated: '',
      numOfRequests: '',
      notes: '',

      alreadyHandledFirstName: '',
      alreadyHandledLastName: '',

      btnDeleteQueueItem: 'nucleus-submit-btn-small',
      btnMarkAsHandledQueueItem: 'nucleus-submit-btn-small',

      udpateDate: new Date(),
      patientsDataComplete: false, // Deprecated April 12th, 2018

      loadingHandleAll: false,
    };

    this.onGetCareCallRequestsQueueAction = this.onGetCareCallRequestsQueueAction.bind(this);
    this.onOpenDeleteCareItemModalAction = this.onOpenDeleteCareItemModalAction.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDeleteQueueItem = this.handleDeleteQueueItem.bind(this);
    this.onReportCallHandledAction = this.onReportCallHandledAction.bind(this);

    this.onOpenMarkAsHandledModalAction = this.onOpenMarkAsHandledModalAction.bind(this);
    this.handleCloseMarkAsHandledModal = this.handleCloseMarkAsHandledModal.bind(this);
    this.handleMarkAsHandled = this.handleMarkAsHandled.bind(this);
    this.onReportCallAddressedAction = this.onReportCallAddressedAction.bind(this);
    this.handleCloseAlreadyHandledModal = this.handleCloseAlreadyHandledModal.bind(this);

    this.onGetAllPatientsDataHashMapAction = this.onGetAllPatientsDataHashMapAction.bind(this);
    this.onHandledRequestRemovedFromStoreAction = this.onHandledRequestRemovedFromStoreAction.bind(this);

    this.handleNoClick = this.handleNoClick.bind(this);

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      //this.datetimeFormat = "m/d/Y h:i A";
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
    } else {
      //console.log("0");
      //this.datetimeFormat = "m/d/Y H:i";
      //this.timeFormat = "HH:mm A";
      this.timeOptions = { hourCycle: 'h23', hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
    }
  }

  componentDidMount() {
    //Register Listener
    CareQueueStore.on('onGetCareCallRequestsQueue', this.onGetCareCallRequestsQueueAction);
    GeneralStore.on('onOpenDeleteCareItemModal', this.onOpenDeleteCareItemModalAction);
    CareQueueStore.on('onOpenMarkAsHandledModal', this.onOpenMarkAsHandledModalAction);
    CareQueueStore.on('onReportCallHandled', this.onReportCallHandledAction);
    CareQueueStore.on('onReportCallAddressed', this.onReportCallAddressedAction);

    CarePatientStore.on('onGetAllPatientsDataHashMap', this.onGetAllPatientsDataHashMapAction);

    CareQueueStore.on('onHandledRequestRemovedFromStore', this.onHandledRequestRemovedFromStoreAction);

    //Get all the Users Array Data from WS

    CareQueueStore.getCareCallRequestsQueueForUser({
      UserID: AuthStore.getUserID(),
    });
    ($('.modalMarkAsHandled') as unknown as JQuery).modal();
    $('.modalAlert').modal();
    $('.dismissible-modal').modal({
      dismissible: false,
      complete: () => {}, // Callback for Modal close
    });
  }

  componentWillUnmount() {
    //Remove Listener
    CareQueueStore.removeListener('onGetCareCallRequestsQueue', this.onGetCareCallRequestsQueueAction);
    GeneralStore.removeListener('onOpenDeleteCareItemModal', this.onOpenDeleteCareItemModalAction);
    CareQueueStore.removeListener('onOpenMarkAsHandledModal', this.onOpenMarkAsHandledModalAction);
    CareQueueStore.removeListener('onReportCallHandled', this.onReportCallHandledAction);
    CareQueueStore.removeListener('onReportCallAddressed', this.onReportCallAddressedAction);

    CarePatientStore.removeListener('onGetAllPatientsDataHashMap', this.onGetAllPatientsDataHashMapAction);

    CareQueueStore.removeListener('onHandledRequestRemovedFromStore', this.onHandledRequestRemovedFromStoreAction);
  }

  onGetCareCallRequestsQueueAction(success) {
    console.log('onGetCareCallRequestsQueueAction', CareQueueStore.getEmergencyList());
    this.setState({
      queueEmergenciesArray: [],
      queueRequetsArray: [],
    });

    if (success) {
      this.setState({
        queueEmergenciesArray: CareQueueStore.getEmergencyList(),
        queueRequetsArray: CareQueueStore.getRequestList(),
      });
    } else {
      Message.show('Sorry, There was a problem getting the queue. Please try again.');
    }
  }

  onOpenDeleteCareItemModalAction(queueItem) {
    console.log('Delete QueueItem : ' + queueItem.requestID + ' - ' + queueItem.userID + ' - ' + queueItem.patientName);
    this.setState({
      selectedQueueItemRequestID: queueItem.requestID,
      selectedQueueItemUserID: queueItem.userID,
      selectedQueueItemPatientName: queueItem.patientName,
    });
    $('#modalDeleteQueueItem').modal('open');
  }

  handleCancelDelete() {
    $('#modalDeleteQueueItem').modal('close');
  }

  handleDeleteQueueItem() {
    this.setState({
      btnDeleteQueueItem: 'nucleus-submit-btn-small-disabled',
    });

    CareQueueStore.reportCallHandled({
      RequestID: this.state.selectedQueueItemRequestID,
      UserID: this.state.selectedQueueItemUserID,
    });
  }

  onReportCallHandledAction(response) {
    console.log('onReportCallHandledAction');
    console.log(response);

    //$('#modalDeleteQueueItem').modal('close');
    if (response.ok) {
      Message.show('Call request handled!');

      console.log('REQUEST HANDLEADO ', response.requestID);
      CareQueueStore.removeRequestFromQueue(response.requestID);
      CareQueueStore.stopNotificationSound();
      $('#modalMarkAsHandled').modal('close');
    } else {
      if (response.AlreadyHandled) {
        this.setState({
          alreadyHandledFirstName: response.FirstName,
          alreadyHandledLastName: response.LastName,
          loadingHandleAll: false,
        });
        $('#modalCallAlreadyHandledModal').modal('open');
      } else {
        Message.show('Error handling the call request, please try again.');
        $('#modalMarkAsHandled').modal('close');
      }
    }
    this.setState({
      //btnDeleteQueueItem : 'nucleus-submit-btn-small'
      btnMarkAsHandledQueueItem: 'nucleus-submit-btn-small',
    });
  }

  onOpenMarkAsHandledModalAction(markAsHandledData) {
    console.log('onOpenMarkAsHandledModalAction : ', markAsHandledData);

    this.setState({
      markAsHandledRequestID: markAsHandledData.ID,
      markAsHandledUserID: markAsHandledData.userID,
      patientName: markAsHandledData.patientName,
      firstRequestedDateTime: markAsHandledData.startTime,
      isEmergency: markAsHandledData.emergency ? 'Y' : 'N',
      escalated: markAsHandledData.escalated ? 'Y' : 'N',
      numOfRequests: markAsHandledData.requests,
      notes: markAsHandledData.notes,
      deviceOffset: markAsHandledData.deviceOffset,
      timezoneString: markAsHandledData.timezoneString,
      btnMarkAsHandledQueueItem: 'nucleus-submit-btn-small',
      loadingHandleAll: false,
    });

    //@ts-ignore
    this.refs.markAsHandledNotes.value = markAsHandledData.notes;
    $('#modalMarkAsHandled').modal('open');
  }

  handleCloseMarkAsHandledModal() {
    if (this.state.loadingHandleAll) {
      return;
    }
    $('#modalMarkAsHandled').modal('close');
  }

  handleMarkAsHandled() {
    console.log('Mark As Handled Button');
    this.setState({
      btnMarkAsHandledQueueItem: 'nucleus-submit-btn-small-disabled',
      loadingHandleAll: true,
    });

    CareQueueStore.reportCallHandled({
      RequestID: this.state.markAsHandledRequestID,
      UserID: this.state.markAsHandledUserID,
      //@ts-ignore
      Notes: this.refs.markAsHandledNotes.value,
    });
  }

  onReportCallAddressedAction(response) {
    console.log('onReportCallAddressedAction');
    console.log('response: ', response);

    if (response.ok) {
      Message.show('Call request marked as handled');
      $('#modalMarkAsHandled').modal('close');
    } else {
      if (response.AlreadyHandled) {
        this.setState({
          alreadyHandledFirstName: response.FirstName,
          alreadyHandledLastName: response.LastName,
        });
        $('#modalCallAlreadyHandledModal').modal('open');
      } else {
        Message.show('Error marking as handled the call request!');
        $('#modalMarkAsHandled').modal('close');
      }
    }
    this.setState({
      btnMarkAsHandledQueueItem: 'nucleus-submit-btn-small',
      loadingHandleAll: false,
    });
  }

  handleCloseAlreadyHandledModal() {
    $('#modalMarkAsHandled').modal('close');
    $('#modalCallAlreadyHandledModal').modal('close');
  }

  onHandledRequestRemovedFromStoreAction() {
    this.setState({
      queueEmergenciesArray: [],
      queueRequetsArray: [],
    });
    this.setState({
      queueEmergenciesArray: CareQueueStore.getEmergencyList(),
      queueRequetsArray: CareQueueStore.getRequestList(),
    });
  }

  onGetAllPatientsDataHashMapAction() {
    console.log('Habemus Patients');
    //DEPRECATED APRIL 12th, 2018, GET INFO FROM THE PATIENT. Data is now included in the response.
    /*
        console.log("PATIENTS HASH: ", CarePatientStore.getPatientDataComplete());
        if (CarePatientStore.getPatientDataComplete() > 0){
            this.setState({
                udpateDate: new Date(),
                patientsDataComplete : true
            });
        }
        */
  }

  handleNoClick() {
    console.log('Click');
  }

  render() {
    console.log('QUEUE RENDER:');

    const emergencySectionVisibility = this.state.emergencyAllowed ? ' ' : ' hide';

    const loadingHandleAllVisibility = this.state.loadingHandleAll ? '' : ' hide';

    const emergencyList = [];
    const requestList = [];

    const requestDate = new Date(this.state.firstRequestedDateTime); //).toUTCString();

    const alreadyHandledPatientName = this.state.alreadyHandledFirstName + ' ' + this.state.alreadyHandledLastName;

    requestDate.setHours(requestDate.getHours() + this.state.deviceOffset);
    const requestDateString =
      requestDate.toLocaleDateString('en-US', this.dateOptions) + ' - ' + requestDate.toLocaleTimeString('en-US', this.timeOptions) + ' - ' + this.state.timezoneString;

    if (this.state.queueEmergenciesArray && this.state.emergencyAllowed) {
      this.state.queueEmergenciesArray.map((emergency, key) => {
        emergencyList.push(
          <QueueItemRow
            key={key}
            RequestID={emergency.RequestID}
            PatientID={emergency.PatientID}
            PatientName={emergency.Name}
            patientFirstName={emergency.PatientFirstName}
            patientLastName={emergency.PatientLastName}
            PatientTelephone={emergency.Telephone}
            DeviceID={emergency.DeviceID}
            DeviceName={emergency.DeviceName}
            ConnectDevice={emergency.ConnectDevice}
            RequestTime={emergency.RequestTime}
            DeviceOffset={emergency.DeviceOffset}
            Num={emergency.NumOfRequests}
            TextColorClass={' txt-red'}
            IsEmergency={emergency.IsEmergency}
            Escalated={emergency.Escalated}
            TimezoneString={emergency.TimezoneString}
            Notes={emergency.Notes}
          />,
        );
      });
    }

    if (this.state.queueRequetsArray != null) {
      this.state.queueRequetsArray.map((request, key) => {
        requestList.push(
          <QueueItemRow
            key={key}
            RequestID={request.RequestID}
            PatientID={request.PatientID}
            PatientName={request.Name}
            patientFirstName={request.PatientFirstName}
            patientLastName={request.PatientLastName}
            PatientTelephone={request.Telephone}
            DeviceID={request.DeviceID}
            DeviceName={request.DeviceName}
            ConnectDevice={request.ConnectDevice}
            RequestTime={request.RequestTime}
            DeviceOffset={request.DeviceOffset}
            Num={request.NumOfRequests}
            TextColorClass={' '}
            IsEmergency={request.IsEmergency}
            Escalated={request.Escalated}
            TimezoneString={request.TimezoneString}
            Notes={request.Notes}
          />,
        );
      });
    }

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">Call Queue</p>
          <div className="users-table-pending altoImportantContainer">
            {/* <div className="nucleus-table-container"> */}

            <div className="row">
              <div className={'no-margin ' + emergencySectionVisibility}>
                <span className="nucleus-page-subtitle-special txt-red">Emergency Calls</span>

                <table className="bordered highlight  nucleus-table">
                  <thead>
                    <tr>
                      <th className="table-col-25 no-padding">
                        <span className="nucleus-table-header no-padding">Name</span>
                      </th>
                      <th className="table-col-18">
                        <span className="nucleus-table-header">Device Name</span>
                      </th>
                      {/*<th className="table-col-15"><span className="nucleus-table-header">Telephone</span></th>*/}

                      <th className="table-col-17 left-align">
                        <span className="nucleus-table-header">First Requested</span>
                      </th>
                      <th className="table-col-15 center-align">
                        <span className="nucleus-table-header">Time in Queue</span>
                      </th>
                      <th className="table-col-10 center-align">
                        <span className="nucleus-table-header">Requests</span>
                      </th>
                      <th className="table-col-15 right-align no-padding">
                        <span className="nucleus-table-header">Mark as Handled</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody>{emergencyList}</tbody>
                </table>

                <br />
                <br />
                <br />
                <br />
              </div>
              <span className="nucleus-page-subtitle-special">Non-Emergency Calls</span>
              <table className="bordered highlight  nucleus-table">
                <thead>
                  <tr>
                    <th className="table-col-25 no-padding">
                      <span className="nucleus-table-header">Name</span>
                    </th>
                    <th className="table-col-18">
                      <span className="nucleus-table-header">Device Name</span>
                    </th>
                    {/*<th className="table-col-15"><span className="nucleus-table-header">Telephone</span></th>*/}

                    <th className="table-col-17 left-align">
                      <span className="nucleus-table-header">First Requested</span>
                    </th>
                    <th className="table-col-15 center-align">
                      <span className="nucleus-table-header">Time in Queue</span>
                    </th>
                    <th className="table-col-10 center-align">
                      <span className="nucleus-table-header">Requests</span>
                    </th>
                    <th className="table-col-15 right-align no-padding">
                      <span className="nucleus-table-header">Mark as Handled</span>
                    </th>
                  </tr>
                </thead>

                <tbody>{requestList}</tbody>
              </table>
            </div>

            <div id="modalDeleteQueueItem" className="modal modalDeleteQueueItem dismissible-modal">
              <div className="modal-content modal-content-delete">
                <h3 className="nucleus-page-subtitle"> Delete Call Request</h3>
                <br />
                <br />
                <div className="row">
                  <div className="col s12 left-align nucleus-font-medium">
                    Are you sure you want to remove the call request from {this.state.selectedQueueItemPatientName}?
                    <br />
                  </div>
                </div>
                <div className="row ">
                  <div className="col s12 right-align ">
                    <p></p>
                    <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDelete}>
                      Cancel
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className={'txt-white nucleus-font-small ' + this.state.btnDeleteQueueItem} onClick={this.handleDeleteQueueItem}>
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalMarkAsHandled" className="modal modalMarkAsHandled modalScrollable">
            <div className="modal-content nucleus-modal-content txt-special-color nucleus-modal-wrapper2">
              <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseMarkAsHandledModal} />
              <h3 className="nucleus-page-subtitle">&nbsp; Mark as handled</h3>
              <br />
              <div className="row no-margin">
                <div className="col s12 left-align ">
                  <div className="row bottom-separated top-separated-margin">
                    <span className="nucleus-labels-bold nucleus-font-label-title">Patient Name:</span>
                    <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.patientName}</span>
                  </div>
                  <div className="row bottom-separated">
                    <span className="nucleus-labels-bold nucleus-font-label-title">First Request Date & Time:</span>{' '}
                    <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestDateString}</span>
                  </div>
                  <div className="row bottom-separated">
                    <span className="nucleus-labels-bold nucleus-font-label-title">Emergency:</span>
                    <span className="nucleus-labels"> &nbsp; {this.state.isEmergency}</span>
                  </div>

                  <div className="row bottom-separated">
                    <span className="nucleus-labels-bold nucleus-font-label-title">Escalated:</span>
                    <span className="nucleus-labels"> &nbsp; {this.state.escalated}</span>
                  </div>
                  <div className="row bottom-separated">
                    <span className="nucleus-labels-bold nucleus-font-label-title">Requests:</span>
                    <span className="nucleus-labels"> &nbsp; {this.state.numOfRequests}</span>
                  </div>
                </div>
              </div>
              <div className="input-field col s12 no-margin-top" key={this.state.notes}>
                <div className="row bottom-separated">
                  <span className="nucleus-labels-bold">Notes:</span> <br />
                </div>
                <textarea ref="markAsHandledNotes" className="nucleus-textarea " placeholder="Enter note"></textarea>
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-small ' + this.state.btnMarkAsHandledQueueItem} onClick={this.handleMarkAsHandled}>
                      Mark as handled
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
                <div className={'row center-align spinner-modal-center' + loadingHandleAllVisibility} onClick={this.handleNoClick}>
                  <br />
                  <br />
                  <br />
                  <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingHandleAll} style={{ display: 'inline-flex' }} />
                </div>
              </div>
            </div>
          </div>

          <div id="modalCallAlreadyHandledModal" className="modal modalAlert modalScrollableNotWide center-margin">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img className={'responsive-img img-close-modal '} src="/img/close_modal.png" onClick={this.handleCloseAlreadyHandledModal} />
              <h3 className="nucleus-page-subtitle">Call Already Handled</h3>
              <br />
              <br />
              <div className="row ">
                <span className="nucleus-labels">The call request was already handled by {alreadyHandledPatientName} and will be removed from the queue</span>
              </div>
              <div className="row ">
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-small ' + this.state.btnMarkAsHandledQueueItem} onClick={this.handleCloseAlreadyHandledModal}>
                      Ok
                    </a>
                  </div>
                </div>
              </div>
              <div className={'row center-align spinner-modal' + loadingHandleAllVisibility} onClick={this.handleNoClick}>
                <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingHandleAll} style={{ display: 'inline-flex' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Queue;
