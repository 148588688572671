import { useEffect, useState } from 'react';
import AuthStore from 'stores/AuthStore';

export const ModalRequestDetails = ({
  onClose,
  requestCallID,
  patientFullName,
  requestDateTime,
  requestCallParticipants,
  requestEmergency,
  requestStatus,
  requestHandledDateTime,
  requestHandler,
  requestEscalated,
  requestRequests,
  requestCallDuration,
  requestCallStatus,
  requestCallNotes,
  saveNoteBtnStyle,
  handleSaveRequestNotes,
  requestNotes,
}: {
  onClose: () => void;
  requestCallID?: string;
  patientFullName: string;
  requestDateTime: string;
  requestEmergency: string;
  requestStatus: string;
  requestHandledDateTime: string;
  requestHandler: string;
  requestEscalated: string;
  requestRequests: string;
  requestCallDuration: string;
  requestCallStatus: string;
  requestCallParticipants: any[];
  requestCallNotes: string;
  saveNoteBtnStyle: string;
  requestNotes: string;
  handleSaveRequestNotes: (notes: string) => void;
}) => {
  const patientLabel = AuthStore.getPatientLabel();
  const [notes, setNotes] = useState<string>(requestNotes);

  // - - - - - - - - - - - - - - - - - - - - - - -
  // Request Logs Section
  // - - - - - - - - - - - - - - - - - - - - - - -
  let callDetailsColumn1 = 'col s8';
  let callDetailsColumn2 = 'col s4';

  if (requestCallID) {
    callDetailsColumn1 = 'col s6';
    callDetailsColumn2 = 'col s6';
  }

  let txtAreaEditNoteState = '';
  if (saveNoteBtnStyle == 'nucleus-submit-btn-disabled') {
    txtAreaEditNoteState = 'disabled';
  }

  const [callLogDetailsParticipants, setCallLogDetailsParticipants] = useState<any[]>([]);

  useEffect(() => {
    const requestCallParticipantRows = requestCallParticipants?.map((participantData, key) => {
      callLogDetailsParticipants.push(
        <div className="row bottom-separated" key={key}>
          <span className="nucleus-labels nucleus-font-label-body">{participantData.Description}</span>
        </div>,
      );
    });
    setCallLogDetailsParticipants(requestCallParticipantRows);
  }, [requestCallParticipants]);

  return (
    <div id="modalRequestDetails" className="modal modalPatientProfile modalScrollableWidth">
      <div className="modal-content nucleus-modal-content txt-special-color nucleus-modal-wrapper2">
        <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={onClose} />
        <h3 className="nucleus-page-subtitle">&nbsp; Call Request Details</h3>
        <br />
        <br />
        <div className="row no-margin">
          <div className={callDetailsColumn1 + ' left-align '}>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">{patientLabel + ' Name: '}</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {patientFullName}</span>
            </div>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">First Request Date & Time:</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestDateTime}</span>
            </div>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">Emergency:</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestEmergency}</span>
            </div>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">Status:</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestStatus}</span>
            </div>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">Handled On:</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestHandledDateTime}</span>
            </div>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">Handled By:</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestHandler}</span>
            </div>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">Escalated:</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestEscalated}</span>
            </div>
            <div className="row bottom-separated">
              <span className=" nucleus-font-label-title">Requests:</span>
              <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestRequests}</span>
            </div>
          </div>
          <div className={callDetailsColumn2 + ' left-align '}>
            {!!requestCallID && (
              <div className="row no-margin">
                <div className="row bottom-separated">
                  <span className="nucleus-font-label-title">Call Duration:</span>
                  <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestCallDuration}</span>
                </div>

                <div className="row bottom-separated">
                  <span className="nucleus-font-label-title">Call Status:</span>
                  <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {requestCallStatus}</span>
                </div>
                <div className="row bottom-separated">
                  <span className="nucleus-font-label-title">Participants:</span>
                </div>
                {callLogDetailsParticipants}
                <div className="row no-margin">
                  <span className="nucleus-font-label-title">Call Notes:</span>
                </div>
                <div className="row bottom-separated grayBorder padding-6">
                  <span className="nucleus-labels nucleus-font-label-body special-span"> {requestCallNotes}</span>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="input-field row no-margin">
          <div className="col s12 bottom-separated">
            <span className="nucleus-font-label-title">Notes:</span> <br />
          </div>
          <div className="col s12 bottom-separated a-bit-lower2">
            <textarea
              onChange={e => {
                setNotes(e.target.value);
              }}
              className="nucleus-textarea "
              placeholder="Enter note"
              disabled={!!txtAreaEditNoteState}
            ></textarea>
          </div>

          <br />
          <div className="row no-margin">
            <div className="col s12 center-align no-margin">
              <p></p>
              <span className=" modalSeparator">
                {' '}
                <label> &nbsp;</label>
              </span>
              <br />
              <a
                className={'txt-white nucleus-font-modal-btn ' + saveNoteBtnStyle}
                onClick={() => {
                  handleSaveRequestNotes(notes);
                }}
              >
                Save
              </a>
              <div>&nbsp;</div>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
