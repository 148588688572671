export enum ProviderCallRequestStatus {
  Canceled = 'Canceled',
  Declined = 'Declined',
  NotAnswered = 'Not Answered',
  Failed = 'Failed',
  Busy = 'Busy',
}
export type ProviderCallRequest = {
  AccountName: string;
  AccountStateID: string;
  AccountState: string;
  AccountStateCode: string;
  CallStatus: ProviderCallRequestStatus;
  CallAttemptedBy: string;
  ConnectDevice: boolean;
  DeviceID: string;
  DeviceName: string;
  DeviceOffset: number;
  Escalated: boolean;
  EscalatedAt: Date | null;
  IsAddressed: boolean;
  HandledTime: string;
  Name: string;
  Notes: string;
  NumOfRequests: number;
  PatientFirstName: string;
  PatientID: string;
  PatientLastName: string;
  RequestID: string;
  RequestTime: string;
  Room: string;
  Telephone: string;
  RequestedByUserID: string;
  RequestedByUserName: string | null;
  CallReason?: string;
};
