//@ts-nocheck
import React from 'react';

class VideoCallToggle extends React.Component<
  {
    initialValue?: boolean;
    onToggle?: (newState: boolean) => void;
  },
  {
    isVideoCallEnabled: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      // by default assume video call is enabled
      isVideoCallEnabled: this.props.initialValue != undefined ? this.props.initialValue : true,
    };
  }

  componentDidUpdate(prevProps) {
    // Check if the initialValue prop has changed
    if (prevProps.initialValue !== this.props.initialValue) {
      // Update the internal state to match the new prop
      this.setState({ isVideoCallEnabled: this.props.initialValue });
    }
  }

  handleToggle = () => {
    const newVideoState = !this.state.isVideoCallEnabled;
    this.setState({ isVideoCallEnabled: newVideoState });
    if (this.props.onToggle) {
      this.props.onToggle(newVideoState);
    }
  };

  render() {
    const { isVideoCallEnabled } = this.state;

    const VideoIcon = () => (
      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="3" width="11.2" height="10.4" rx="2.5" stroke="#0A313F" strokeWidth="1.5" />
        <path
          d="M12.2 7.12855L15.7441 4.67091C16.2747 4.30302 17 4.68271 17 5.32832V11.0837C17 11.7269 16.2793 12.1072 15.7484 11.744L12.2 9.31715V7.12855Z"
          stroke="#0A313F"
          strokeWidth="1.5"
        />
      </svg>
    );

    const SpeakerIcon = () => (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.2609 4.3706C10.2609 2.65827 8.24923 1.73774 6.95343 2.85712L5.26752 4.31349C4.90423 4.62732 4.44018 4.8 3.96011 4.80001L3.49998 4.80001C2.11928 4.80002 1 5.91931 1 7.30001V8.70003C1 10.0807 2.11929 11.2 3.5 11.2H3.96014C4.44021 11.2 4.90427 11.3727 5.26756 11.6865L6.95343 13.1429C8.24923 14.2623 10.2609 13.3417 10.2609 11.6294V8V4.3706Z"
          stroke="#0A313F"
          strokeWidth="1.5"
        />
        <path
          d="M12.5761 10.3156C13.0607 9.6707 13.3478 8.86907 13.3478 8.0004C13.3478 7.13172 13.0607 6.33009 12.5761 5.68518"
          stroke="#0A313F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          d="M14.1196 12.6309C15.0887 11.341 15.663 9.73778 15.663 8.00043C15.663 6.26308 15.0887 4.65982 14.1196 3.37"
          stroke="#0A313F"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </svg>
    );

    return (
      <div
        style={{
          height: '35px',
          width: '60px',
          backgroundColor: isVideoCallEnabled ? '#4CAF50' : '#f44336',
          borderRadius: '20px',
          padding: '2px',
          transition: 'background-color 0.1s',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
        }}
        onClick={this.handleToggle}
      >
        <div
          style={{
            height: '28px',
            width: '28px',
            backgroundColor: 'white',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
            transform: isVideoCallEnabled ? 'translateX(26px)' : 'translateX(3px)',
            transition: 'transform 0.1s',
          }}
        >
          {isVideoCallEnabled ? <VideoIcon /> : <SpeakerIcon />}
        </div>
      </div>
    );
  }
}

export default VideoCallToggle;
