import { EventEmitter } from 'events';
//import Dispatcher from "../Dispatcher";
//import Config from "../Config";
import CaregiverApi from '../apis/CaregiverApi';

class CaregiverStore extends EventEmitter {
  private CaregiverApi: CaregiverApi;
  private CaregiversList: any[];
  private CaregiversHash: any;
  private CaregiverSMSList: any[];
  private CaregiverSMSNames: string;
  private CaregiverSMSMessage: string;

  constructor() {
    super();

    this.CaregiverApi = new CaregiverApi(this);
    try {
      this.CaregiversList = localStorage.CaregiversList ? JSON.parse(localStorage.CaregiversList) : [];
      this.CaregiversHash = localStorage.CaregiversHash ? JSON.parse(localStorage.CaregiversHash) : [];
      this.CaregiverSMSList = [];
      this.CaregiverSMSNames = '';
      this.CaregiverSMSMessage = '';
    } catch (e) {
      console.error('Error parsing CaregiversList from localStorage', e);
    }
    //console.warn(this);
  }

  getAllCaregivers() {
    return this.CaregiversList;
  }

  getCaregiverInfo(caregiverID) {
    return this.CaregiversHash[caregiverID];
  }

  getCaregiverDataComplete() {
    return Object.keys(this.CaregiversHash).length;
  }

  UpdateCaregiverData({ ID, Email, FirstName, LastName, Telephone, Address }) {
    console.log('UpdateCaregiverData ', ID, Email, FirstName, LastName, Telephone, Address);

    this.CaregiverApi.UpdateCaregiverData({ ID, Email, FirstName, LastName, Telephone, Address }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdateCaregiver', rsp);
    });
  }

  getAllCaregiversData({ AccountID }) {
    //console.log("getAllCaregiversData ", AccountID );

    this.CaregiverApi.getAllCaregiverData({ AccountID }, rsp => {
      console.log('getAllCaregiverData Response');
      console.log(rsp);

      if (rsp.ok) {
        this.CaregiversList = rsp.CareGivers;
        //this.saveToLocalStorage();
      }
      //*-
      this.emit('onGetAllCaregiversData', rsp.ok);

      if (rsp.ok) {
        const caregiversArray = [];
        let caregiverInfo = {};
        for (let i = 0; i < rsp.CareGivers.length; i++) {
          caregiverInfo = {
            Email: rsp.CareGivers[i].Email,
            Name: rsp.CareGivers[i].FirstName + ' ' + rsp.CareGivers[i].LastName,
            FirstName: rsp.CareGivers[i].FirstName,
            LastName: rsp.CareGivers[i].LastName,
            Telephone: rsp.CareGivers[i].Telephone,
            Address: rsp.CareGivers[i].Address,
            Pin: rsp.CareGivers[i].pin,
          };
          caregiversArray[rsp.CareGivers[i].ID] = caregiverInfo;
        }
        this.CaregiversHash = caregiversArray;
        this.saveToLocalStorage();

        this.emit('onHasAllCaregiversData', rsp.ok);
      }
    });
  }

  // getCareDevices ({AccountID}) {
  // 	console.log("getCareDevices ", AccountID);

  // 	this.PatientApi.GetCareDevices({AccountID},(rsp) => {

  // 		console.log("Nucleus Response");
  // 		console.log(rsp);

  // 		this.emit("onGetDevicesAction", rsp);
  // 	});
  // }

  // assignDeviceToPatient ({PatientID, DeviceID}) {
  // 	console.log("assignDeviceToPatient ", PatientID, DeviceID);

  // 	this.PatientApi.AssignDevicePatient({PatientID, DeviceID},(rsp) => {

  // 		console.log("Nucleus Response");
  // 		console.log(rsp);

  // 		this.emit("onAssignDevicePatientAction", rsp);
  // 	});
  // }

  // unassignDeviceToPatient ({PatientID}) {
  // 	console.log("unassignDeviceToPatient ", PatientID);

  // 	this.PatientApi.UnassignDevicePatient({PatientID},(rsp) => {

  // 		console.log("Nucleus Response");
  // 		console.log(rsp);

  // 		this.emit("onUnassignDevicePatientAction", rsp);
  // 		this.emit("onUnassignDevicePatientAction2", rsp);
  // 	});
  // }

  getCaregiverAssignments({ CareGiverID }) {
    console.log('getCaregiverAssignments ', CareGiverID);

    this.CaregiverApi.GetCaregiverAssignments({ CareGiverID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onGetCaregiverAssignments', rsp);
    });
  }

  deleteAssignment({ ID }) {
    console.log('deleteAssignment ', ID);

    this.CaregiverApi.DeleteAssignment({ ID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onDeleteAssignment', rsp);
    });
  }

  clearCaregiverSMSList() {
    this.CaregiverSMSList = [];
  }

  addCaregiverSMSList(caregiver) {
    console.log('addCaregiverSMSList');
    console.log(caregiver);
    this.CaregiverSMSList.push(caregiver);

    this.emit('onSMSListHasChanged', this.CaregiverSMSList.length);

    this.CaregiverSMSNames = '';
    for (let i = 0; i < this.CaregiverSMSList.length; i++) {
      if (i != 0) {
        this.CaregiverSMSNames = this.CaregiverSMSNames + ', ';
      }
      this.CaregiverSMSNames = this.CaregiverSMSNames + this.CaregiverSMSList[i].Name;
    }
    console.log('Names: ', this.CaregiverSMSNames);
  }

  removeCaregiverSMSList(caregiverID) {
    console.log('removeCaregiverSMSList');
    console.log(caregiverID);
    let index = -1;
    this.CaregiverSMSNames = '';

    console.log('LENGTH ', this.CaregiverSMSList.length);
    for (let i = 0; i < this.CaregiverSMSList.length; i++) {
      if (this.CaregiverSMSList[i].ID == caregiverID) {
        index = i;
        console.log('Coincide en: ', i);
        break;
      }
    }
    console.log('Index es: ', index);
    if (index >= 0) {
      this.CaregiverSMSList.splice(index, 1);
    }
    console.log('ARRAY ', this.CaregiverSMSList);
    this.emit('onSMSListHasChanged', this.CaregiverSMSList.length);

    //Create SMS Receivers Names
    this.CaregiverSMSNames = '';
    for (let i = 0; i < this.CaregiverSMSList.length; i++) {
      if (i != 0) {
        this.CaregiverSMSNames = this.CaregiverSMSNames + ', ';
      }
      this.CaregiverSMSNames = this.CaregiverSMSNames + this.CaregiverSMSList[i].Name;
    }
    console.log('Names: ', this.CaregiverSMSNames);
  }

  setSMSMessage(message) {
    this.CaregiverSMSMessage = message;
  }

  getSMSList() {
    return this.CaregiverSMSList.length;
  }

  getSMSListData() {
    return this.CaregiverSMSList;
  }

  getSMSNames() {
    return this.CaregiverSMSNames;
  }

  getSMSMessage() {
    return this.CaregiverSMSMessage;
  }

  sendSMS({ CareGiverID, Message }) {
    console.log('sendSMS ', CareGiverID, Message);

    this.CaregiverApi.SendCaregiverSMS({ CareGiverID, Message }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onSendCaregiverSMS', rsp);
    });
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    localStorage.CaregiversList = JSON.stringify(this.CaregiversList);
    localStorage.CaregiversHash = JSON.stringify(this.CaregiversHash);
  }
}

const careGiverStore = new CaregiverStore();

export default careGiverStore;
