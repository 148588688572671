import classNames from 'classnames';
import React from 'react';
import { JQuery } from 'types/jquery.types';
import AuthStore from '../../stores/AuthStore';

import CompressUtil from '../../utils/CompressUtil';
import Message from '../../utils/Message';
import nucleusNetwork, { ControlSecret } from '../../utils/NucleusNetwork';
import PasswordForm from './SettingsUserEditForm';
class SettingsUserEdit extends React.Component<
  {
    currentMode: string;
    userFirstName: string;
    userLastName: string;
    userImageUrl: string;
    userEmail: string;
    userTelephone: string;
    userAddress: string;
    userIsOnDuty: boolean;
  },
  {
    firstName: string;
    btnStyle: string;
    errorView1: string;
    errorText1: string;
    errorView2: string;
    errorText2: string;
    currentPasswordMode: boolean;
    newPassword: string;
    newPasswordState: boolean;
    clientImageFilePreviewURL: any;
    uploadingUserImageStatus?: string;
  }
> {
  private imgRef: any;
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      btnStyle: 'disabled',
      errorView1: ' hide',
      errorText1: 'Your password must be at least 6 characters',
      errorView2: ' hide',
      errorText2: 'Your new passwords do not match',
      currentPasswordMode: true,
      newPassword: '',
      newPasswordState: false,
      clientImageFilePreviewURL: null,
    };
    this.submitUpdateUserForm = this.submitUpdateUserForm.bind(this);
    this.validateTxtInput = this.validateTxtInput.bind(this);
    this.passwordStateChange = this.passwordStateChange.bind(this);
    this.selectedUserImageFile = this.selectedUserImageFile.bind(this);
    this.onUserImageUpdatedAction = this.onUserImageUpdatedAction.bind(this);
  }

  componentDidMount() {
    ($('.tooltipped') as unknown as JQuery).tooltip({ delay: 50 });
    AuthStore.on('onUserImageUpdated', this.onUserImageUpdatedAction);
  }

  componentWillUnmount() {
    ($('.tooltipped') as unknown as JQuery).tooltip('remove');
    AuthStore.removeListener('onUserImageUpdated', this.onUserImageUpdatedAction);
  }

  validateTxtInput = () => {
    console.log('validateTxtInput', this.state.newPassword);
    //@ts-ignore
    if (this.refs.txtUserEditOldPassword.value.length > 0 && this.state.newPassword.length > 0) {
      this.setState({
        btnStyle: '',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }
    // if (this.refs.txtUserEditNewPassword.value.length > 0){
    //     this.setState({
    //         errorView1 : ' hide',
    //         errorView2 : ' hide'
    //     });
    // }
    // if (this.refs.txtUserEditConfirmPassword.value.length > 0){
    //     this.setState({
    //         errorView1 : ' hide',
    //         errorView2 : ' hide'
    //     });
    // }
  };

  passwordStateChange = (state, newPassword) => {
    console.log('passwordStateChange', state, newPassword);

    //@ts-ignore
    if (this.refs.txtUserEditOldPassword.value.length > 0 && state) {
      this.setState({
        btnStyle: '',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }

    this.setState({
      newPassword: newPassword,
      newPasswordState: state,
    });
  };
  selectedUserImageFile(e) {
    const files = e.target.files;
    if (files.length > 0) {
      console.log('selectedUserImageFile', e);
      const value = files[0];
      CompressUtil(value).then(data => {
        const selectedImage = data;
        // console.log("selectedImage:",selectedImage);
        const reader = new FileReader();
        const url = reader.readAsDataURL(value);
        const urlImage = URL.createObjectURL(value);
        reader.onloadend = () => {
          this.setState({
            clientImageFilePreviewURL: urlImage,
          });
        };
        console.log('LocalURL image:User:', [reader.result]);
        console.log('LocalURL:', url); // Would see a path?
        console.log('selectedImage', selectedImage);
        this.setState({
          uploadingUserImageStatus: 'Uploading...',
        });
        console.log('Uploading...');

        const headers = Object.assign(
          {},
          {
            'Authorization': `Bearer ${nucleusNetwork.getToken()}`,
            'X-EntityType': 'Control',
            'X-Secret': ControlSecret,
          },
        );

        const config = {
          headers,
        };
        AuthStore.updateUserImage(
          {
            UserID: AuthStore.getUserID(),
            image: selectedImage,
          },
          config,
        );
      });
    } else {
      console.log('No File Selected');
    }
  }

  onUserImageUpdatedAction(response) {
    console.log('onUserImageUpdatedAction', response);
    if (response.ok) {
      Message.show('Image updated');
    } else {
      Message.show('Error updating image');
    }
  }

  submitUpdateUserForm() {
    ////setTimeout(function(){ $('select').material_select(); }, 500);
    console.log('submitUpdateUserForm');

    // if (this.refs.txtUserEditFirstName.value === "") {
    //     Message.show('FirstName is required.');
    //     return;
    // }
    // if (this.refs.txtUserEditLastName.value === "") {
    //     Message.show('LastName is required.');
    //     return;
    // }
    // if (this.refs.txtUserEditEmail.value === "") {
    //     Message.show('Ups! The email is not valid.');
    //     return;
    // }
    // if (this.refs.txtUserEditTelephone.value === "") {
    //     Message.show('Telephone is required.');
    //     return;
    // }
    // if (this.refs.txtUserEditAddress1.value === "") {
    //     //Message.show('Address is required.');
    //     //return;
    // }

    let userPassword = null;
    console.log('Password Default');
    //@ts-ignore
    if (this.refs.txtUserEditOldPassword.value !== '' || this.state.newPassword !== '') {
      //Message.show('Address is required.');
      //return;

      // if (this.refs.txtUserEditOldPassword.value === "") {
      //     Message.show('Your current password is required.');
      //     return;
      // }
      // if (this.refs.txtUserEditNewPassword.value === "") {
      //     Message.show('Please enter your new password.');
      //     return;
      // }
      // if (this.refs.txtUserEditConfirmPassword.value === "") {
      //     Message.show('Please confirm your new password.');
      //     return;
      // }
      // if (this.refs.txtUserEditNewPassword.value !== this.refs.txtUserEditConfirmPassword.value) {
      //     Message.show('Your new passwords do not match.');
      //     return;
      // }

      userPassword = this.state.newPassword;

      // if (userPassword.length < 6) {
      //     this.setState({
      //         errorView1 : " ",
      //         errorText1 : "Your password must be at least 6 characters"
      //     });
      //     return;
      // }
      // if (userPassword.search(/[a-zA-Z]/)< 0) {
      //     this.setState({
      //         errorView1 : " ",
      //         errorText1 : "Your password must contain at least one letter"
      //     });
      //     return;
      // }
      // if (userPassword.search(/\d/) < 0 && userPassword.search(/[\!\@\#\$\%\^\&\*\(\)\_\+]/) < 0){
      //     this.setState({
      //         errorView1 : " ",
      //         errorText1 : "Your password must contain at least one digit or one special character"
      //     });
      //     return;
      // }
      // if (userPassword != this.refs.txtUserEditConfirmPassword.value ){
      //     this.setState({
      //         errorView2 : " ",
      //         errorText1 : "Your new passwords do not match"
      //     });
      //     return;
      // }
    }

    // if (this.refs.txtUserEditFirstName.value != this.props.userFirstName ||
    //     this.refs.txtUserEditLastName.value != this.props.userLastName ||
    //     this.refs.txtUserEditTelephone.value != this.props.userTelephone ||
    //     this.refs.txtUserEditAddress1.value != this.props.userAddress){
    //     updateUserData = true;
    // }

    AuthStore.updateUserData({
      UserID: AuthStore.getUserID(),
      //@ts-ignore
      Password: this.refs.txtUserEditOldPassword.value,
      NewPassword: userPassword,
      IsOnDuty: this.props.userIsOnDuty,
      UpdateUserData: false,
      UpdateUserPassword: true,
    });

    //Get the User Data from WS
    // DEPRECATED 8 June 2021
    // UserStore.UpdateUserData({
    //     UserID : UserStore.getID(),
    //     FirstName : this.refs.txtUserEditFirstName.value,
    //     LastName : this.refs.txtUserEditLastName.value,
    //     Email : this.refs.txtUserEditEmail.value,
    //     Telephone : this.refs.txtUserEditTelephone.value,
    //     Address: this.refs.txtUserEditAddress1.value,
    //     Password: this.refs.txtUserEditOldPassword.value,
    //     NewPassword :userPassword,
    //     UpdateUserData :updateUserData,
    //     UpdateUserPassword:updateUserPassword,
    //     IsAdmin: "",
    //     IsOnDuty:""
    // });
  }

  render() {
    const currentSection = classNames({
      hide: this.props.currentMode != 'Edit',
    });
    const defaultPasswordValue = '';

    const userData = {
      firstName: this.props.userFirstName,
      lastName: this.props.userLastName,
      email: this.props.userEmail,
    };

    return (
      <div className={'nucleus-user-pending nucleus-form-container ' + currentSection}>
        <div className="row">
          <div className="col s3 m3 l2">
            <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
              <style>
                {`
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-circle {
                                border-radius: 0;
                            }
                            
                            .ratio {
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: cover;
                            
                                height: 0;
                                padding-bottom: 100%;
                                position: relative;
                                width: 100%;
                            }
                            .img-circle {
                                border-radius: 50%;
                            }
                            .img-responsive {
                                display: block;
                                height: auto;
                                max-width: 100%;
                            }
                            .inputfile {
                                width: 0.1px;
                                height: 0.1px;
                                opacity: 0;
                                overflow: hidden;
                                position: absolute;
                                z-index: -1;
                            }
                            .inputfile + label {
                                margin-top:10px;
                                font-size: 1.25em;
                                // font-weight: 700;
                                text-decoration: underline;
                                color: gray;
                                // background-color: black;
                                display: inline-block;
                            }
                            
                            .inputfile:focus + label,
                            .inputfile + label:hover {
                                cursor: pointer;
                                // background-color: red;
                            }
                            .inputfile {
                                cursor: pointer;
                            }
                            `}
              </style>
              <div style={{ width: '70%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <img
                  className=""
                  style={{ width: 140, height: 140, borderRadius: 70, objectFit: 'cover' }}
                  src={this.state.clientImageFilePreviewURL ? this.state.clientImageFilePreviewURL : this.props.userImageUrl ? this.props.userImageUrl : '/img/user_placeholder.png'}
                  ref={img => (this.imgRef = img)}
                  onError={() => (this.imgRef.src = '/img/user_placeholder.png')}
                />
                <div>
                  <input
                    onChange={e => {
                      const value = e.target.files[0];
                      console.log('value:', value);
                      const reader = new FileReader();
                      const url = reader.readAsDataURL(value);
                      const urlImage = URL.createObjectURL(value);
                      reader.onloadend = function () {
                        this.setState({
                          clientImageFilePreviewURL: urlImage,
                        });
                      }.bind(this);
                      console.log('Value image:patient:', [reader.result]);
                      console.log('LocalURL:', url); // Would see a path?
                      this.selectedUserImageFile(e);
                    }}
                    multiple={false}
                    type="file"
                    name="file"
                    id="file"
                    className="inputfile"
                  />
                  <label htmlFor="file">Upload Photo</label>
                </div>
              </div>
            </div>
          </div>
          <div className="col s9 m9 l7">
            <div className="row hide">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels"> First Name</span>
              </div>
              <div className="col s8" key={this.props.userFirstName}>
                <input ref="txtUserEditFirstName" type="text" className="validate nucleus-input-form" defaultValue={this.props.userFirstName} />
              </div>
            </div>
            <div className="row hide">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels"> Last Name</span>
              </div>
              <div className="col s8" key={this.props.userLastName}>
                <input ref="txtUserEditLastName" type="text" className="validate nucleus-input-form" defaultValue={this.props.userLastName} />
              </div>
            </div>
            <div className="row hide">
              <div className="col s4">
                <span className=" nucleus-labels"> Email</span>
              </div>
              <div className="col s8" key={this.props.userEmail}>
                <input ref="txtUserEditEmail" type="text" className="validate nucleus-input-form-disabled" disabled defaultValue={this.props.userEmail} />
              </div>
            </div>
            <div className="row hide">
              <div className="col s4">
                <span className=" nucleus-labels"> Telephone</span>
              </div>
              <div className="col s8" key={this.props.userTelephone}>
                <input ref="txtUserEditTelephone" type="text" className="validate nucleus-input-form" defaultValue={this.props.userTelephone} />
              </div>
            </div>
            <div className="row hide">
              <div className="col s4">
                <span className=" nucleus-labels"> Address</span>
              </div>
              <div className="col s8" key={this.props.userAddress}>
                <input ref="txtUserEditAddress1" type="text" className="validate nucleus-input-form" defaultValue={this.props.userAddress} />
              </div>
            </div>

            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Current Password</span>
              </div>
              <div className="col s8">
                <div style={{ display: 'flex' }} key={defaultPasswordValue}>
                  <input
                    ref="txtUserEditOldPassword"
                    type={this.state.currentPasswordMode ? 'password' : 'text'}
                    className="validate nucleus-input-form"
                    placeholder="Current Password"
                    defaultValue={defaultPasswordValue}
                    onChange={this.validateTxtInput}
                  />
                  <img
                    src="/img/view_password.png"
                    className="nucleus-link"
                    style={{ width: 24, height: 16, marginLeft: -24, marginTop: 6 }}
                    onClick={() => {
                      this.setState({
                        currentPasswordMode: !this.state.currentPasswordMode,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <PasswordForm onPasswordStateChange={this.passwordStateChange} userData={userData}></PasswordForm>
            {/* 
                        <div className="row">
                            <div className="col s4">
                                <span className=" nucleus-labels"> New Password</span> 
                            </div>
                            <div className="col s8">
                                <input 
                                    ref="txtUserEditNewPassword" 
                                    type="password" 
                                    className="validate nucleus-input-form" 
                                    defaultValue={defaultPasswordValue} 
                                    onChange={this.validateTxtInput}/>
                                <span class={ "nucleus-form-error center-align " + this.state.errorView1 } > {this.state.errorText1}</span>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s4">
                                <span className=" nucleus-labels"> Confirm Password</span> 
                            </div>
                            <div className="col s8">
                                <input 
                                    ref="txtUserEditConfirmPassword" 
                                    type="password" 
                                    className="validate nucleus-input-form" 
                                    defaultValue={defaultPasswordValue} 
                                    onChange={this.validateTxtInput}/>
                                <span class={ "nucleus-form-error center-align " + this.state.errorView2 } > {this.state.errorText2}</span>
                            </div>
                        </div>
                        <br/> 
                        */}
            {/*
                        <div className="row">
                            <div className="col s4">
                            </div>
                            <div className="col s8 center-align">
                                <a className="txt-white nucleus-font-small nucleus-submit-btn" onClick={this.submitUpdateUserForm}>Submit</a>
                            </div>
                        </div>
                        */}
          </div>
          <div className="col s0 m0 l3"></div>
        </div>

        <div className="fixed-action-btn btn-add-new">
          <a
            className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
            data-position="left"
            data-tooltip="Save profile"
            onClick={this.submitUpdateUserForm}
          >
            <i className="material-icons">save</i>
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>
    );
  }
}

export default SettingsUserEdit;
