export const VideoIcon = () => {
  return (
    <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="8" width="33.5996" height="31.9996" rx="2.5" stroke="#0A313F" strokeWidth="2" />
      <path
        d="M34.6001 20.7023L47.7363 11.3597C48.2659 10.983 48.9999 11.3617 48.9999 12.0116V35.9991C48.9999 36.6467 48.2707 37.026 47.7405 36.6541L34.6001 27.4364V20.7023Z"
        stroke="#0A313F"
        strokeWidth="2"
      />
    </svg>
  );
};
