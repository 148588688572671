import { getClientInstance, setClientToken } from '@nucleus-care/react-native-nucleuscare-connect-security-client';
import config from '../Config';

export function getInstance() {
  return getClientInstance({
    url: config.authUrl,
  });
}

export function setAuthorizationToken(token) {
  setClientToken(token);
}
