import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { FC, useState } from 'react';
import styled from 'styled-components';
import authStore from '../../stores/AuthStore';
import Message from '../../utils/Message';

import { UIModal } from '../UI';
import { ModalAction } from '../UI/Modals/Base/ActionButtons';
import { GroupCategory, TierTitles } from './types';

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;

const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  category?: GroupCategory | null;
  tierTitles?: TierTitles;
  deletedSuccessCallback?: () => void;
}

const DeleteGroupCategory: FC<Props> = ({ closeModal, isOpen, category, tierTitles, deletedSuccessCallback }) => {
  const [isLoading, setIsLoading] = useState(false);
  const deleteGroupCategory = () => {
    httpApi
      .delete(`/providers/${authStore.getProviderID()}/groups/${category?.id}`)
      .then(() => {
        Message.show(`${tierTitles?.Tier1} deleted`);
        deletedSuccessCallback?.();
        closeModal();
      })
      .catch(() => {
        Message.show(`Error deleting ${tierTitles?.Tier1} `);
        setIsLoading(false);
      });
  };

  const submit = () => {
    deleteGroupCategory();
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Proceed',
        onClick: submit,
        disabled: isLoading,
      },
    ];

    return data;
  };

  const renderGroups = () => {
    return <div>{category?.groups?.map(group => <div key={group.name}>{group.name}</div>)}</div>;
  };

  return (
    <UIModal size="medium" isOpen={isOpen} close={closeModal} actions={getActions()}>
      <Title>WARNING!</Title>
      <Note>You are about to delete</Note>
      <Name>{`${tierTitles?.Tier1}: ${category?.name}`}</Name>

      <Note>This will affect automatic reports sent to emails defined for this {tierTitles?.Tier1 || 'company'}.</Note>
    </UIModal>
  );
};

export default DeleteGroupCategory;
