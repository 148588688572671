import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useEffect, useState } from 'react';
import authStore from '../../stores/AuthStore';
import Message from '../../utils/Message';

export type Account = {
  id: string;
  name?: string;
};

const useAccountSelector = () => {
  const [allAccounts, setAllAccounts] = useState<Account[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<Account[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchAccounts();
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchAccounts();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const fetchAccounts = async () => {
    setIsLoading(true);
    try {
      const { data } = await httpApi.get(`/providers/${authStore.getProviderID()}/accounts`);
      if (searchTerm) {
        const fetchedAccounts = data.providersAccounts.map((account: any) => ({
          id: account.AccountID,
          name: account.AccountName,
        }));

        const filteredAccounts = fetchedAccounts.filter(fp => !selectedAccounts.some(sp => sp.id === fp.id) && fp.name?.toLowerCase().includes(searchTerm.toLowerCase()));
        setAllAccounts(filteredAccounts);
      } else {
        setAllAccounts([]);
      }
      setIsLoading(false);
    } catch (e) {
      console.warn('error fetching accounts', e);

      if (e.response) {
        Message.show(`${e.response.data?.message || 'Error getting accounts'}`);
      } else {
        Message.show('Error getting accounts');
      }
      setIsLoading(false);
    }
  };

  const toggleAccountsSelection = (accountId: string) => {
    const isCurrentlySelected = selectedAccounts.some(p => p.id === accountId);

    if (isCurrentlySelected) {
      setSelectedAccounts(selectedAccounts.filter(p => p.id !== accountId));
    } else {
      const patientToAdd = allAccounts.find(p => p.id === accountId);
      if (patientToAdd) setSelectedAccounts([...selectedAccounts, patientToAdd]);
    }
  };

  const reset = () => {
    setSelectedAccounts([]);
  };

  return {
    searchTerm,
    accounts: allAccounts,
    selectedAccounts,
    toggleAccountsSelection,
    reset,
    setSearchTerm,
    isLoading,
  };
};

export default useAccountSelector;
