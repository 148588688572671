import { EventEmitter } from 'events';

import { httpApi, setUser, resetUser } from '@nucleus-care/nucleuscare-backend-client';
import axios from 'axios';
import packageJson from '../../package.json';
import LoginApi from '../apis/LoginApi';
import Config from '../Config';
import { getInstance } from '../utils/auth-client';
import { setTokenToHttpClients } from '../utils/get-token';
import Message from '../utils/Message';

const ControlSecret = 'f33c6a40-bd07-11eb-8529-0242ac130003';
axios.defaults.headers['X-EntityType'] = 'Control';
axios.defaults.headers['X-Secret'] = ControlSecret;

type UserMeResponse = {
  AccountAccessGroups: boolean;
  AccountAdmin: boolean;
  AccountID: string;
  AccountName: string;
  Address: string;
  AssignedProviderID: string;
  AudioCallShortcut: boolean;
  AutomaticCallRequestBargeIn: boolean;
  BargeInCall: boolean;
  BargeInCallShortcut: boolean;
  CanAccessGroups: boolean;
  CanAccessReports: boolean;
  CanDeleteDevices: boolean;
  CanDeletePatients: boolean;
  CanImportClients: boolean;
  CanRestartDevices: boolean;
  CanSeeAlertsTab: boolean;
  CanSeeBulletinBoard: boolean;
  CanSeeClientsTab: boolean;
  CanSeeDevicesTab: boolean;
  CanSeeGroupsTab: boolean;
  CanSeeMemoryBox: boolean;
  CanSeeMessageCenterTab: boolean;
  CanSeeQueueTab: boolean;
  CanSeeStaffTab: boolean;
  CanSeeVideoSessionTab: boolean;
  CanSendMessageToAllPatients: boolean;
  CareUserID: string;
  CaregiverRoleName: string;
  CaregiverRoleNamePlural: string;
  ClientRoleName: string;
  ClientRoleNamePlural: string;
  CompanyLogo: string;
  CompanyName: string;
  Created: boolean;
  Email: string;
  EnableAutoAnswer: boolean;
  EnableBiometrics: boolean;
  EnableClassroom: boolean;
  EnableControlIncomingCall: boolean;
  EnableImportClients: boolean;
  EnableRingOnIncomingCall: boolean;
  EnableSalesforceImport: boolean;
  ExternalAppsEnabled: boolean;
  FamilyRoleName: string;
  FamilyRoleNamePlural: string;
  FirstName: string;
  Format12Hrs: boolean;
  GroupsButtonText: string;
  HIPAA_AutoLogoutTimeout: number;
  HIPAA_IsCompliant: boolean;
  HasBulletinBoardActive: boolean;
  IsAdmin: boolean;
  IsHipaaCompliant: boolean;
  IsOnDuty: boolean;
  LastName: string;
  LinkMessagesEnabled: boolean;
  LocaleTag: string;
  MemoryBoxButtonText: string;
  MobileCallResponse: string;
  OnlyUrgent: boolean;
  ProviderID: string | null;
  ProviderRequestSound: number;
  Region: string;
  ShowEmergencyButton: boolean;
  ShowFamilyCallButton: boolean;
  ShowVideoStats: boolean;
  SilentBargeInCallShortcut: boolean;
  Telephone: string;
  TwoFactorAuth: boolean;
  UsePanicEmergencyRingtone: boolean;
  UserBargeInEnabled: boolean;
  UserID: string;
  UserImageThumb: string;
  UserImageUrl: string;
  UserRoleName: string;
  UserRoleNamePlural: string;
  VideoCallShortcut: boolean;
};

class AuthStore extends EventEmitter {
  private LoginApi: LoginApi;
  private nucleusUserID: string | null;
  private nucleusProviderID: string | null;
  private nucleusUserEmail: string | null;
  private nucleusUserFirstName: string | null;
  private nucleusUserLastName: string | null;
  private nucleusUserFullName: string | null;
  private nucleusAccountID: string | null;
  private nucleusUserRol: string | null;
  private nucleusOnDuty: boolean | null;
  private nucleusOnlyUrgent: boolean | null;
  private nucleusProfileCreated: boolean | null;
  private nucleusNewSession: string | null;
  private nucleusTemporalPassword: string | null;
  private nucleusCompanyName: string | null;
  private nucleusCompanyLogo: string | null;
  private userLabel: string | null;
  private caregiverLabel: string | null;
  private patientLabel: string | null;
  private userLabelPlural: string | null;
  private caregiverLabelPlural: string | null;
  private patientLabelPlural: string | null;
  private familyLabel: string | null;
  private familyLabelPlural: string | null;
  private onDutyLabel: string | null;
  private usePanicSound: boolean | null;
  private allowMessageToAllPatients: boolean | null;
  private canSendPatientsMessage: boolean | null;
  private controlIncomingCall: boolean | null;
  private ringOnIncomingCall: boolean | null;
  private allowAutoAnswer: boolean | null;
  private emergencyModeActive: boolean | null;
  nucleusVideoCallShortcut: boolean | null;
  nucleusAudioCallShortcut: boolean | null;
  nucleusBargeInCallShortcut: boolean | null;
  nucleusSilentBargeInCallShortcut: boolean | null;
  nucleusUserBargeInEnabled: boolean | null;
  public nucleusAutomaticCallRequestBargeIn: boolean | null;
  private staffAccess: boolean | null;
  private clientsAccess: boolean | null;
  private queueAccess: boolean | null;
  private alertAccess: boolean | null;
  private messagesAccess: boolean | null;
  private picturesAccess: boolean | null;
  private picturesLabel: string | boolean | null;
  private groupLabel: string | null;
  private bulletinAccess: boolean | null;
  private bulletinEnabled: boolean | null;
  private devicesAccess: boolean | null;
  private deletePatientsAccess: boolean | null;
  private deleteDevicesAccess: boolean | null;
  private videoSessionAccess: boolean | null;
  private restartDevicesAccess: boolean | null;
  private appsAccess: boolean | null;
  private linkMessageEnabled: boolean | null;
  private enableBiometrics: boolean | null;
  private isHipaaCompliant: boolean | null;
  private autoLogoutTimeout: number | null;
  private Time12HrsFormat: string | null;
  private TwoFactorAuthEnabled: boolean | null;
  private userIP: string | null;
  private reportsStoreAccess: boolean | null;
  private groupsStoreAccess: boolean | null;
  private callShortcutsEnabled: boolean;
  private token: string | null;
  private timeout: NodeJS.Timeout | null;
  private ProviderCallRequestSound: number;

  constructor() {
    super();

    this.timeout = null;
    this.token = null;
    this.LoginApi = new LoginApi(this, getInstance());
    this.nucleusUserID = localStorage.nucleusUserID;
    this.nucleusProviderID = localStorage.nucleusProviderID;
    this.nucleusUserEmail = localStorage.nucleusUserEmail;
    this.nucleusUserFirstName = localStorage.nucleusUserFirstName;
    this.nucleusUserLastName = localStorage.nucleusUserLastName;
    this.nucleusUserFullName = localStorage.nucleusUserFullName;
    this.nucleusAccountID = localStorage.nucleusAccountID;
    this.nucleusUserRol = localStorage.nucleusUserRol;
    this.nucleusOnDuty = localStorage.nucleusOnDuty === 'true';
    this.nucleusOnlyUrgent = localStorage.nucleusOnlyUrgent === 'true';
    this.nucleusProfileCreated = localStorage.nucleusProfileCreated === 'true';
    this.nucleusNewSession = localStorage.nucleusNewSession;

    this.nucleusCompanyName = localStorage.nucleusCompanyName;
    this.nucleusCompanyLogo = localStorage.nucleusCompanyLogo;

    this.nucleusTemporalPassword = localStorage.nucleusTemporalPassword || '';

    this.userLabel = localStorage.userLabel || 'User';
    this.caregiverLabel = localStorage.caregiverLabel || 'Caregiver';
    this.patientLabel = localStorage.patientLabel || 'Patient';

    this.userLabelPlural = localStorage.userLabelPlural || 'Users';
    this.caregiverLabelPlural = localStorage.caregiverLabelPlural || 'Caregivers';
    this.patientLabelPlural = localStorage.patientLabelPlural || 'Patients';
    this.familyLabel = localStorage.familyLabel || 'Family Member';
    this.familyLabelPlural = localStorage.familyLabelPlural || 'Family Members';

    this.onDutyLabel = localStorage.onDutyLabel || 'Receive Calls';

    this.usePanicSound = localStorage.usePanicSound === 'true';
    this.allowMessageToAllPatients = localStorage.allowMessageToAllPatients === 'true';
    this.canSendPatientsMessage = localStorage.canSendPatientsMessage === 'true';

    this.controlIncomingCall = localStorage.controlIncomingCall === 'true';
    this.ringOnIncomingCall = localStorage.ringOnIncomingCall === 'true';
    this.allowAutoAnswer = localStorage.allowAutoAnswer === 'true';
    this.emergencyModeActive = localStorage.emergencyModeActive === 'true';

    this.nucleusVideoCallShortcut = localStorage.nucleusVideoCallShortcut === 'true';
    this.nucleusAudioCallShortcut = localStorage.nucleusAudioCallShortcut === 'true';
    this.nucleusBargeInCallShortcut = localStorage.nucleusBargeInCallShortcut === 'true';
    this.nucleusSilentBargeInCallShortcut = localStorage.nucleusSilentBargeInCallShortcut === 'true';

    this.nucleusUserBargeInEnabled = localStorage.nucleusUserBargeInEnabled === 'true';

    this.nucleusAutomaticCallRequestBargeIn = localStorage.nucleusAutomaticCallRequestBargeIn === 'true';

    console.log('localStorage.nucleusAutomaticCallRequestBargeIn', localStorage.nucleusAutomaticCallRequestBargeIn);
    console.log('V2');

    this.callShortcutsEnabled = this.nucleusVideoCallShortcut || this.nucleusAudioCallShortcut || this.nucleusBargeInCallShortcut || this.nucleusSilentBargeInCallShortcut;

    this.staffAccess = localStorage.staffAccess === 'true' || false;
    this.clientsAccess = localStorage.clientsAccess === 'true' || false;
    this.queueAccess = localStorage.queueAccess === 'true' || false;
    this.alertAccess = localStorage.alertAccess === 'true' || false;
    this.messagesAccess = localStorage.messagesAccess === 'true' || false;
    this.picturesAccess = localStorage.picturesAccess === 'true' || false;
    this.picturesLabel = localStorage.picturesLabel === 'true' || 'Memory Box';
    this.groupLabel = localStorage.groupLabel || 'Groups';
    this.bulletinAccess = localStorage.bulletinAccess === 'true' || false;
    this.bulletinEnabled = localStorage.bulletinEnabled === 'true' || false;

    this.devicesAccess = localStorage.devicesAccess === 'true' || false;
    this.deletePatientsAccess = localStorage.deletePatientsAccess === 'true' || false;
    this.deleteDevicesAccess = localStorage.deleteDevicesAccess === 'true' || false;
    this.videoSessionAccess = localStorage.videoSessionAccess === 'true' || false;
    this.restartDevicesAccess = localStorage.restartDevicesAccess === 'true' || false;

    this.appsAccess = localStorage.appsAccess === 'true' || false;
    this.linkMessageEnabled = localStorage.linkMessageEnabled === 'true' || false;

    this.enableBiometrics = localStorage.enableBiometrics === 'true' || false;
    this.isHipaaCompliant = localStorage.isHipaaCompliant === 'true' || false;
    this.autoLogoutTimeout = localStorage.autoLogoutTimeout || 100;

    this.Time12HrsFormat = localStorage.Time12HrsFormat || true;
    this.TwoFactorAuthEnabled = String(localStorage.TwoFactorAuthEnabled) == 'true' || false;

    this.userIP = localStorage.userIP || '127.0.0.1';

    this.reportsStoreAccess = false;
    this.groupsStoreAccess = false;

    this.ProviderCallRequestSound = localStorage.ProviderCallRequestSound;
  }

  setToken(token) {
    this.token = token;
  }

  getUserID() {
    return this.nucleusUserID;
  }
  getProviderID() {
    return this.nucleusProviderID;
  }

  isProvider() {
    return Boolean(this.nucleusProviderID);
  }

  getProviderCallRequestSound() {
    return this.ProviderCallRequestSound;
  }

  getUser() {
    const patientFirstN = this.getUserFirstName();
    const patientLastN = this.getUserLastName();
    let Initials = '';

    if (patientFirstN?.length) {
      Initials = patientFirstN.substring(0, 1);
    }
    if (patientLastN?.length) {
      Initials = Initials + patientLastN.substring(0, 1);
    }
    return {
      ID: this.getUserID(),
      AccountID: this.getUserAccountID(),
      FullName: this.getUserFullName(),
      FirstName: this.getUserFirstName(),
      LastName: this.getUserLastName(),
      Email: this.getUserEmail(),
      Company: this.getCompanyName(),
      Logo: this.getCompanyLogo(),
      Rol: this.getUserRol(),
      Initials,
    };
  }

  getUserFirstName() {
    return this.nucleusUserFirstName;
  }

  getUserLastName() {
    return this.nucleusUserLastName;
  }

  getUserEmail() {
    return this.nucleusUserEmail;
  }

  getUserFullName() {
    return this.nucleusUserFullName;
  }

  getUserAccountID() {
    return this.nucleusAccountID;
  }

  getCompanyName() {
    if (this.nucleusCompanyName != undefined && this.nucleusCompanyName != 'undefined' && this.nucleusCompanyName != null) {
      return this.nucleusCompanyName + ' - ';
    } else {
      return '';
    }
  }
  getCompanyLogo() {
    console.log('LOGO', this.nucleusCompanyLogo);
    return this.nucleusCompanyLogo;
  }

  setIsAdmin(value) {
    this.nucleusUserRol = value ? 'admin' : 'user';
    this.saveToLocalStorage();
  }
  getUserRol() {
    return this.nucleusUserRol;
  }

  setOnDuty(value) {
    this.nucleusOnDuty = value;
    this.saveToLocalStorage();
  }
  getOnDuty() {
    return this.nucleusOnDuty;
  }

  setOnlyUrgent(value) {
    this.nucleusOnlyUrgent = value;
    this.saveToLocalStorage();
  }
  getOnlyUrgent() {
    return this.nucleusOnlyUrgent;
  }

  getProfileCreated() {
    return String(this.nucleusProfileCreated) == 'true';
  }

  setProfileCreated(profileCreated) {
    this.nucleusProfileCreated = profileCreated;
    this.saveToLocalStorage();
  }

  getIsNewSession() {
    this.saveToLocalStorage();
    return this.nucleusNewSession;
  }
  clearIsNewSession() {
    this.nucleusNewSession = 'yes';
    this.saveToLocalStorage();
  }

  getNucleusUserTemporalPassword() {
    return this.nucleusTemporalPassword;
  }

  getUserLabel() {
    return this.userLabel;
  }
  getCaregiverLabel() {
    return this.caregiverLabel;
  }
  getPatientLabel() {
    return this.patientLabel;
  }

  getUserLabelPlural() {
    return this.userLabelPlural;
  }
  getCaregiverLabelPlural() {
    return this.caregiverLabelPlural;
  }
  getPatientLabelPlural() {
    return this.patientLabelPlural;
  }
  getFamilyLabel() {
    return this.familyLabel;
  }
  getFamilyLabelPlural() {
    return this.familyLabelPlural;
  }

  getOnDutyLabel() {
    return this.onDutyLabel;
  }

  getUsePanic() {
    return this.usePanicSound;
  }

  getMessageToAllPatientsAllowed() {
    console.log('MESSAGEPATIENTS');
    return this.canSendPatientsMessage;
  }

  setCanSendPatientsMessage(value) {
    this.canSendPatientsMessage = value;
    this.saveToLocalStorage();
  }

  getControlIncomingCall() {
    return this.controlIncomingCall;
  }

  getAutoAnswerAllowed() {
    return this.allowAutoAnswer;
  }

  getEmergencyModeActive() {
    return this.emergencyModeActive;
  }

  getUseNucleusRing() {
    return this.ringOnIncomingCall;
  }

  canAccessStaff() {
    return this.staffAccess;
  }

  canAccessClients() {
    return this.clientsAccess;
  }

  canAccessQueue() {
    return this.queueAccess;
  }

  canAccessAlerts() {
    return this.alertAccess;
  }

  canAccessMessages() {
    return this.messagesAccess;
  }

  canAccessPictures() {
    return this.picturesAccess;
  }

  getPicturesLabel() {
    return this.picturesLabel;
  }

  getGroupLabel() {
    return this.groupLabel;
  }

  canAccessBulletin() {
    return this.bulletinAccess;
  }

  isBulletinEnabled() {
    return this.bulletinEnabled;
  }

  canAccessDevices() {
    return this.devicesAccess;
  }

  canAccessVideoSession() {
    return this.videoSessionAccess;
  }

  canAccessExternalApps() {
    return this.appsAccess;
  }

  isLinkMessageEnabled() {
    return this.linkMessageEnabled;
  }

  setReportsStoreAccess(allowed) {
    this.reportsStoreAccess = allowed;
  }

  getReportsStoreAccess() {
    return this.reportsStoreAccess;
  }
  setGroupsStoreAccess(allowed) {
    this.groupsStoreAccess = allowed;
  }

  getGroupsStoreAccess() {
    return this.groupsStoreAccess;
  }

  canDeletePatients() {
    return this.deletePatientsAccess;
  }
  canDeleteDevices() {
    return this.deleteDevicesAccess;
  }
  canRestartDevices() {
    return this.restartDevicesAccess;
  }

  getAccountBiometricsEnabled() {
    return this.enableBiometrics;
  }

  getIsHipaaCompliant() {
    return this.isHipaaCompliant;
  }

  getTime12Hrs() {
    return this.Time12HrsFormat;
  }

  get2FactorAuthEnabled() {
    return this.TwoFactorAuthEnabled;
  }

  getAutoLogoutTimeout() {
    return this.autoLogoutTimeout;
  }

  requestCurrentIP() {
    console.log('requestCurrentIP ');

    this.LoginApi.requestCurrentIP({}, rsp => {
      if (rsp.ip) {
        this.userIP = rsp.ip;
        this.saveToLocalStorage();
      }
    });
  }

  getCurrentIP() {
    return this.userIP;
  }

  loginProvider(dispatch, rsp) {
    localStorage.setItem('nucleusToken', rsp.Jwt);
    setTokenToHttpClients(rsp.Jwt);

    if (!rsp.TwoFactorAuth) {
      this.saveToLocalStorage();
      return this.getMe(dispatch);
    }

    this.emit('onAuthStoreLogin', {
      ok: rsp.status === 200,
      internetError: rsp.errorInternetConnection,
      profileCreated: rsp.Created,
      loginError: rsp.error,
      twoFactorRequired: rsp.TwoFactorAuth,
      email: rsp.Email,
      telephone: rsp.Telephone,
    });
  }

  async login({ Email, Password, PlatformInformation, dispatch }) {
    this.LoginApi.login({ Email, Password, PlatformInformation, dispatch }, rsp => {
      console.log('Nucleus Login Response 1');

      if (rsp.status === 200) {
        localStorage.setItem('nucleusToken', rsp.Jwt);
        this.nucleusTemporalPassword = Password;
        setTokenToHttpClients(rsp.Jwt);
        httpApi.defaults.headers['Authorization'] = `Bearer ${rsp.Jwt}`;
      }

      if (!rsp.TwoFactorAuth && rsp.status === 200) {
        this.saveToLocalStorage();
        return this.getMe(dispatch);
      }

      this.emit('onAuthStoreLogin', {
        ok: rsp.status === 200,
        internetError: rsp.errorInternetConnection,
        profileCreated: rsp.Created,
        loginError: rsp.error,
        twoFactorRequired: rsp.TwoFactorAuth,
        email: rsp.Email,
        telephone: rsp.Telephone,
      });
    });
  }

  getMe(dispatch): Promise<UserMeResponse> {
    console.log('getMe');
    let rsp;
    return httpApi
      .get<UserMeResponse>('/user/me', { timeout: 12000 })
      .then(response => {
        console.log('user/me response', response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
        return data;
      })
      .catch(e => {
        console.log('AuthStore : getMe error', e);
        if (e.response && e.response.status === 401) {
          rsp = {
            ok: false,
            //error: 'Unauthorized',
          };
        } else {
          rsp = {
            ok: false,
            error: e.message,
          };
        }
        return rsp;
      })
      .then(data => {
        console.log('rsp???', rsp);
        if (rsp && rsp.ok) {
          if (dispatch) {
            const { ...user } = rsp;
            dispatch(setUser(user));
          }

          this.nucleusUserID = rsp.CareUserID;
          this.nucleusProviderID = rsp.ProviderID;
          this.nucleusUserEmail = rsp.Email;
          this.nucleusUserFirstName = rsp.FirstName;
          this.nucleusUserLastName = rsp.LastName;
          this.nucleusUserFullName = rsp.FirstName + ' ' + rsp.LastName;
          this.nucleusAccountID = rsp.AccountID;
          this.nucleusUserRol = rsp.IsAdmin ? 'admin' : 'user';
          this.nucleusOnDuty = rsp.IsOnDuty;

          this.nucleusVideoCallShortcut = rsp.VideoCallShortcut;
          this.nucleusAudioCallShortcut = rsp.AudioCallShortcut;
          this.nucleusBargeInCallShortcut = rsp.BargeInCallShortcut;
          this.nucleusSilentBargeInCallShortcut = rsp.SilentBargeInCallShortcut;
          this.nucleusUserBargeInEnabled = rsp.UserBargeInEnabled;

          this.callShortcutsEnabled = !!(
            this.nucleusVideoCallShortcut ||
            this.nucleusAudioCallShortcut ||
            this.nucleusBargeInCallShortcut ||
            this.nucleusSilentBargeInCallShortcut
          );

          this.nucleusAutomaticCallRequestBargeIn = rsp.AutomaticCallRequestBargeIn;

          this.nucleusOnlyUrgent = rsp.OnlyUrgent;
          this.nucleusProfileCreated = rsp.Created.toString();

          this.nucleusCompanyName = rsp.CompanyName;
          this.nucleusCompanyLogo = rsp.CompanyLogo;

          this.userLabel = rsp.UserRoleName;
          this.caregiverLabel = rsp.CaregiverRoleName;
          this.patientLabel = rsp.ClientRoleName;

          this.userLabelPlural = rsp.UserRoleNamePlural;
          this.caregiverLabelPlural = rsp.CaregiverRoleNamePlural;
          this.patientLabelPlural = rsp.ClientRoleNamePlural;
          this.familyLabel = rsp.FamilyRoleName || 'Family Member';
          this.familyLabelPlural = rsp.FamilyRoleNamePlural || 'Family Members';

          this.onDutyLabel = rsp.OnDutyName || 'Receive Calls';

          this.usePanicSound = rsp.UsePanicEmergencyRingtone;
          this.allowMessageToAllPatients = false; // Deprecated rsp.EnableMessageToAllPatients;
          this.canSendPatientsMessage = false; // DEPRECATED - CARE-2259	rsp.CanSendMessageToAllPatients;
          this.controlIncomingCall = rsp.EnableControlIncomingCall;
          this.ringOnIncomingCall = rsp.EnableRingOnIncomingCall;
          this.allowAutoAnswer = rsp.EnableAutoAnswer;
          this.emergencyModeActive = rsp.ShowEmergencyButton;

          this.staffAccess = rsp.CanSeeStaffTab;
          this.clientsAccess = rsp.CanSeeClientsTab;
          this.queueAccess = rsp.CanSeeQueueTab;
          this.alertAccess = rsp.CanSeeAlertsTab;
          this.messagesAccess = rsp.CanSeeMessageCenterTab;
          this.picturesAccess = rsp.CanSeeMemoryBox;
          this.picturesLabel = rsp.MemoryBoxButtonText;
          this.groupLabel = rsp.GroupsButtonText;
          this.bulletinAccess = rsp.CanSeeBulletinBoard;
          this.bulletinEnabled = rsp.HasBulletinBoardActive;
          this.devicesAccess = rsp.CanSeeDevicesTab;
          this.deletePatientsAccess = rsp.CanDeletePatients;
          this.deleteDevicesAccess = rsp.CanDeleteDevices;
          this.videoSessionAccess = rsp.CanSeeVideoSessionTab;
          this.restartDevicesAccess = rsp.CanRestartDevices || false;

          this.appsAccess = rsp.ExternalAppsEnabled;
          this.linkMessageEnabled = rsp.LinkMessagesEnabled;

          this.enableBiometrics = rsp.EnableBiometrics;

          this.isHipaaCompliant = rsp.IsHipaaCompliant;
          this.autoLogoutTimeout = rsp.HIPAA_AutoLogoutTimeout;

          this.Time12HrsFormat = rsp.Format12Hrs;

          this.TwoFactorAuthEnabled = rsp.TwoFactorAuth;

          this.ProviderCallRequestSound = rsp.ProviderRequestSound;

          this.saveToLocalStorage();
        } else if (rsp.error) {
          Message.show(rsp.error || 'Error login');
        } else if (this.nucleusTemporalPassword) {
          this.emit('onAuthStoreLogin', {
            ok: false,
            loginError: ' ',
          });
        }
        this.emit('onGetMe', rsp);
        return data;
      });
  }

  verifyCode(code) {
    this.LoginApi.verifyCode(code, rsp => {
      this.emit('onVerifyCode', rsp);
    });
  }

  send2FactorVerification() {
    console.log('send2FactorVerification');
    this.LoginApi.sendVerificationCode(rsp => {
      this.emit('onVerificationCodeSent', {
        ok: rsp.status === 200,
        waitToResend: rsp.code === 8,
        Mode: rsp.Telephone ? 'Telephone' : 'Email',
        Telephone: rsp.Telephone,
        Email: rsp.Email,
      });
    });
  }
  //Verify2FA

  requestResetPassword({ Email }) {
    console.log('requestResetPassword ', Email);
    this.LoginApi.requestResetPassword({ Email }, rsp => {
      //console.log("Nucleus Response", rsp);
      this.emit('onResetRequestedAction', rsp);
    });
  }

  resetPassword({ Email, Token, Password }) {
    console.log('resetPassword ', Email, Token, Password);

    this.LoginApi.resetPassword({ Email, Token, Password }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onResetPasswordAction', rsp);
    });
  }

  resetTempPassword(UserID) {
    console.log('AuthStoreControl:resetTempPassword ', UserID);
    const paramData = {
      userId: UserID,
    };
    return this.LoginApi.resetTempPassword(paramData)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  }

  resetPassword2({ Email, Token, Password }) {
    console.log('resetPassword2', Email, Token, Password);

    const paramsData = {
      password: Password,
      tempToken: Token,
    };

    this.LoginApi.resetPassword2(paramsData, rsp => {
      //console.log("Nucleus Response", rsp);
      const response = Object.assign({}, rsp, {
        ok: rsp.ok,
      });
      this.emit('onResetPasswordAction', response);
    });
  }

  updateUserData({ UserID, Password, NewPassword, IsOnDuty, UpdateUserData, UpdateUserPassword }) {
    console.log('UpdateUserData ', UserID, Password, NewPassword, IsOnDuty, UpdateUserData, UpdateUserPassword);

    const paramsData = {
      oldPassword: Password,
      newPassword: NewPassword,
      isOnDuty: IsOnDuty,
    };
    this.LoginApi.updateUserPassword(paramsData, rsp => {
      console.log('Nucleus Response 34');
      console.log(rsp);

      // if (rsp.ok){
      // 	this.UserFirstName = FirstName;
      // 	this.UserLastName = LastName;
      // 	this.UserFullName = FirstName + ' ' + LastName;
      // 	this.UserEmail = Email;
      // 	this.UserTelephone = Telephone;
      // 	this.UserAddress = Address;

      // 	this.saveToLocalStorage();
      // }

      this.emit('onUserUpdateData', {
        ok: rsp.ok,
        //credentials : rsp.okCredentials,
        passwordUpdated: rsp.PasswordUpdated,
        passwordUpdateError: rsp.PasswordUpdateError,
        updateUserData: UpdateUserData,
        updateUserPassword: UpdateUserPassword,
        //internetError : rsp.errorInternetConnection
      });
    });
  }

  updateUserImage({ UserID, image }, config) {
    console.log('updateUserImage ', UserID, image, config);
    const data = new FormData();
    data.append('UserID', UserID);
    data.append('photo', image);
    axios
      .post(Config.domain + 'CareUser.asmx/UpdateUser', data, config)
      .then(res => {
        console.log('response:: CareUser.asmx/UpdateUser - AuthStore.updateUserImage>', res.data);
        this.emit('onUserImageUpdated', res.data);
      })
      .catch(err => {
        console.error('error:: CareUser.asmx/UpdateUser - AuthStore.updateUserImage>', err);
        this.emit('onUserImageUpdated', err);
      });
  }

  resetCustomerPassword({ Email, Token, Password }) {
    console.log('resetCustomerPassword ', Email, Token, Password);

    this.LoginApi.resetCustomerPassword({ Email, Token, Password }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onResetCustomerPasswordAction', rsp);
    });
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');
    localStorage.nucleusUserID = this.nucleusUserID;
    localStorage.nucleusUserEmail = this.nucleusUserEmail;
    localStorage.nucleusUserFirstName = this.nucleusUserFirstName;
    localStorage.nucleusUserLastName = this.nucleusUserLastName;
    localStorage.nucleusUserFullName = this.nucleusUserFullName;
    localStorage.nucleusAccountID = this.nucleusAccountID;
    localStorage.nucleusUserRol = this.nucleusUserRol;
    localStorage.nucleusOnDuty = this.nucleusOnDuty;
    localStorage.nucleusOnlyUrgent = this.nucleusOnlyUrgent;

    localStorage.nucleusProfileCreated = this.nucleusProfileCreated;
    localStorage.nucleusNewSession = this.nucleusNewSession;

    localStorage.nucleusTemporalPassword = this.nucleusTemporalPassword;

    localStorage.nucleusCompanyName = this.nucleusCompanyName;
    localStorage.nucleusCompanyLogo = this.nucleusCompanyLogo;

    localStorage.nucleusVideoCallShortcut = this.nucleusVideoCallShortcut;
    localStorage.nucleusAudioCallShortcut = this.nucleusAudioCallShortcut;
    localStorage.nucleusBargeInCallShortcut = this.nucleusBargeInCallShortcut;
    localStorage.nucleusSilentBargeInCallShortcut = this.nucleusSilentBargeInCallShortcut;
    localStorage.nucleusUserBargeInEnabled = this.nucleusUserBargeInEnabled;

    localStorage.nucleusAutomaticCallRequestBargeIn = this.nucleusAutomaticCallRequestBargeIn;

    localStorage.userLabel = this.userLabel;
    localStorage.caregiverLabel = this.caregiverLabel;
    localStorage.patientLabel = this.patientLabel;

    localStorage.userLabelPlural = this.userLabelPlural;
    localStorage.caregiverLabelPlural = this.caregiverLabelPlural;
    localStorage.patientLabelPlural = this.patientLabelPlural;
    localStorage.familyLabel = this.familyLabel;
    localStorage.familyLabelPlural = this.familyLabelPlural;
    localStorage.onDutyLabel = this.onDutyLabel;
    localStorage.usePanicSound = this.usePanicSound;
    localStorage.allowMessageToAllPatients = this.allowMessageToAllPatients;
    localStorage.canSendPatientsMessage = this.canSendPatientsMessage;
    localStorage.controlIncomingCall = this.controlIncomingCall;
    localStorage.ringOnIncomingCall = this.ringOnIncomingCall;
    localStorage.allowAutoAnswer = this.allowAutoAnswer;
    localStorage.emergencyModeActive = this.emergencyModeActive;

    localStorage.staffAccess = this.staffAccess;
    localStorage.clientsAccess = this.clientsAccess;
    localStorage.queueAccess = this.queueAccess;
    localStorage.alertAccess = this.alertAccess;
    localStorage.messagesAccess = this.messagesAccess;
    localStorage.picturesAccess = this.picturesAccess;
    localStorage.picturesLabel = this.picturesLabel;
    localStorage.groupLabel = this.groupLabel;
    localStorage.bulletinAccess = this.bulletinAccess;
    localStorage.bulletinEnabled = this.bulletinEnabled;
    localStorage.devicesAccess = this.devicesAccess;
    localStorage.videoSessionAccess = this.videoSessionAccess;

    localStorage.appsAccess = this.appsAccess;
    localStorage.linkMessageEnabled = this.linkMessageEnabled;

    localStorage.deletePatientsAccess = this.deletePatientsAccess;
    localStorage.deleteDevicesAccess = this.deleteDevicesAccess;
    localStorage.restartDevicesAccess = this.restartDevicesAccess;

    localStorage.enableBiometrics = this.enableBiometrics;
    localStorage.isHipaaCompliant = this.isHipaaCompliant;
    localStorage.autoLogoutTimeout = this.autoLogoutTimeout;
    localStorage.Time12HrsFormat = this.Time12HrsFormat;
    localStorage.TwoFactorAuthEnabled = this.TwoFactorAuthEnabled;
    localStorage.userIP = this.userIP;

    localStorage.ProviderCallRequestSound = this.ProviderCallRequestSound;
  }

  _logout = dispatch => {
    const platformInformation = {
      ClientIPAddress: this.getCurrentIP(),
      ClientUserAgent: window.navigator.userAgent,
      WebControlVersion: packageJson.version,
    };

    console.warn('Loggin out');
    this.LoginApi.logout({
      userId: this.nucleusUserID,
      platformInformation: JSON.stringify(platformInformation),
    })
      .catch(() => {
        console.warn('Error logout request');
      })
      .finally(() => {
        console.warn('Logout finally:');

        this.nucleusUserID = null;
        this.nucleusUserFirstName = null;
        this.nucleusUserEmail = null;
        this.nucleusUserLastName = null;
        this.nucleusUserFullName = null;
        this.nucleusAccountID = null;
        this.nucleusUserRol = null;
        this.nucleusOnDuty = null;
        this.nucleusOnlyUrgent = null;
        this.nucleusProfileCreated = null;
        this.nucleusNewSession = null;
        this.nucleusTemporalPassword = null;

        this.userLabel = null;
        this.caregiverLabel = null;
        this.patientLabel = null;

        this.userLabelPlural = null;
        this.caregiverLabelPlural = null;
        this.patientLabelPlural = null;
        this.familyLabel = null;
        this.familyLabelPlural = null;
        this.onDutyLabel = null;
        this.usePanicSound = null;
        this.allowMessageToAllPatients = null;
        this.canSendPatientsMessage = null;
        this.controlIncomingCall = null;
        this.ringOnIncomingCall = null;
        this.allowAutoAnswer = null;
        this.emergencyModeActive = null;
        this.nucleusAutomaticCallRequestBargeIn = null;

        this.nucleusVideoCallShortcut = null;
        this.nucleusAudioCallShortcut = null;
        this.nucleusBargeInCallShortcut = null;
        this.nucleusSilentBargeInCallShortcut = null;
        this.nucleusUserBargeInEnabled = null;

        this.staffAccess = null;
        this.clientsAccess = null;
        this.queueAccess = null;
        this.alertAccess = null;
        this.messagesAccess = null;
        this.picturesAccess = null;
        this.picturesLabel = null;
        this.groupLabel = null;
        this.bulletinAccess = null;
        this.bulletinEnabled = null;
        this.devicesAccess = null;
        this.deletePatientsAccess = null;
        this.deleteDevicesAccess = null;
        this.enableBiometrics = null;
        this.videoSessionAccess = null;
        this.restartDevicesAccess = null;

        this.appsAccess = null;
        this.linkMessageEnabled = null;

        this.isHipaaCompliant = null;
        this.autoLogoutTimeout = null;
        this.Time12HrsFormat = null;
        this.TwoFactorAuthEnabled = null;

        localStorage.removeItem('nucleusToken');
        delete localStorage.nucleusUserID;
        delete localStorage.nucleusUserFirstName;
        delete localStorage.nucleusUserEmail;
        delete localStorage.nucleusUserLastName;
        delete localStorage.nucleusUserFullName;
        delete localStorage.nucleusAccountID;
        delete localStorage.nucleusUserRol;
        delete localStorage.nucleusProfileCreated;
        delete localStorage.nucleusNewSession;
        delete localStorage.nucleusTemporalPassword;

        delete localStorage.userLabel;
        delete localStorage.caregiverLabel;
        delete localStorage.patientLabel;
        delete localStorage.userLabelPlural;
        delete localStorage.caregiverLabelPlural;
        delete localStorage.patientLabelPlural;
        delete localStorage.familyLabel;
        delete localStorage.familyLabelPlural;
        delete localStorage.onDutyLabel;
        delete localStorage.usePanicSound;
        delete localStorage.allowMessageToAllPatients;
        delete localStorage.canSendPatientsMessage;
        delete localStorage.controlIncomingCall;
        delete localStorage.ringOnIncomingCall;
        delete localStorage.allowAutoAnswer;
        delete localStorage.emergencyModeActive;

        delete localStorage.staffAccess;
        delete localStorage.clientsAccess;
        delete localStorage.queueAccess;
        delete localStorage.alertAccess;
        delete localStorage.messagesAccess;
        delete localStorage.picturesAccess;
        delete localStorage.picturesLabel;
        delete localStorage.groupLabel;
        delete localStorage.bulletinAccess;
        delete localStorage.bulletinEnabled;
        delete localStorage.devicesAccess;
        delete localStorage.deletePatientsAccess;
        delete localStorage.deleteDevicesAccess;
        delete localStorage.videoSessionAccess;
        delete localStorage.restartDevicesAccess;

        delete localStorage.appsAccess;
        delete localStorage.linkMessageEnabled;

        delete localStorage.nucleusAutomaticCallRequestBargeIn;

        delete localStorage.nucleusVideoCallShortcut;
        delete localStorage.nucleusAudioCallShortcut;
        delete localStorage.nucleusBargeInCallShortcut;
        delete localStorage.nucleusSilentBargeInCallShortcut;
        delete localStorage.nucleusUserBargeInEnabled;

        delete localStorage.enableBiometrics;

        delete localStorage.isHipaaCompliant;
        delete localStorage.autoLogoutTimeout;
        delete localStorage.Time12HrsFormat;
        delete localStorage.TwoFactorAuthEnabled;

        dispatch(resetUser());
        this.emit('onAuthStoreLogout', {
          ok: false,
        });
        //window.location.reload();
      });
  };

  justLogout(dispatch) {
    this._logout(dispatch);
  }

  isAuthenticated() {
    return Boolean(this.nucleusUserID);
  }

  logout(dispatch) {
    console.log('logout');
    this._logout(dispatch);
  }
}

const authStore = new AuthStore();

export default authStore;
