//@ts-nocheck
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import packageJson from '../../../package.json';
import { getAccountClassSessionConfigSelector } from '../../selectors/accountConfig';
import { getAccessToReportsTabSelector, getAccessToGroupsTabSelector } from '../../selectors/user';

import { AuthStore, GeneralStore, CareAlertsStore, CarePatientStore, CareQueueStore } from '../../stores';
import {
  BulletinBoardSVG,
  ClassesSVG,
  MemoryBoxSVG,
  DevicesSVG,
  SettingsSVG,
  ClientsSVG,
  StaffSVG,
  QueueSVG,
  AlertsSVG,
  MessagesSVG,
  AlertsCountSVG,
  ReportsSVG,
  AppsSVG,
  GroupsSVG,
} from './SidebarSVGs';

import NavigationListener from '../NavigationListener';

function ClassesMenuFromSelector({ componentRenderer }) {
  console.log('ClassesMenuFromSelector');
  const configState = getAccountClassSessionConfigSelector();
  console.log('ClassesMenuFromSelector configState', configState);
  if (configState.config && configState.config.Enabled && AuthStore.canAccessVideoSession()) {
    return componentRenderer(configState.config.TabNameLabelPlural);
  }
  return null;
}

function ReportsMenuFromSelector({ componentRenderer }) {
  console.log('ReportsMenuFromSelector');
  const hasAccess = getAccessToReportsTabSelector();
  console.log('ReportsMenuFromSelector hasAccess', hasAccess);
  AuthStore.setReportsStoreAccess(hasAccess);
  if (!hasAccess) return null;
  return componentRenderer();
}

function GroupsMenuFromSelector({ componentRenderer }) {
  console.log('GroupsMenuFromSelector');
  const hasAccess = getAccessToGroupsTabSelector();
  console.log('GroupsMenuFromSelector hasAccess', hasAccess);
  AuthStore.setGroupsStoreAccess(hasAccess);
  if (!hasAccess) return null;
  return componentRenderer();
}

class SideBar extends React.Component<
  {
    match?: any;
    dispatch: any;
    location?: any;
    handleMenusVisibility?: (path: string) => void;
    history?: any;
  },
  {
    userMenuClass: string;
    patientMenuClass: string;
    devicesMenuClass: string;
    caregiverMenuClass: string;
    queueMenuClass: string;
    alertsMenuClass: string;
    messagesMenuClass: string;
    picturesMenuClass: string;
    groupsMenuClass: string;
    bulletinMenuClass: string;
    classesMenuClass: string;
    reportsMenuClass: string;
    settingsMenuClass: string;
    appsMenuClass: string;
    queueNumber: number;
    queueNumberVisibility: string;
    alertsNumber: number;
    alertsNumberVisibility: string;
    lastCallRequestID: string;
    lastCallRequestPatientID: string;
    staffVisibility: string;
    clientsVisibility: string;
    queueVisibility: string;
    alertsVisibility: string;
    messagesVisibility: string;
    picturesVisibility: string;
    bulletinVisibility: string;
    devicesVisibility: string;
    appsVisibility: string;
    callRequests: number;
  }
> {

  private dateOptions: any;
  private timeOptions: any;
  private ringingStatus: boolean;
  private isPolling: boolean;
  private callRequests: number;

  constructor(props) {
    super(props);
    this.state = {
      userMenuClass: ' ',
      patientMenuClass: ' ',
      devicesMenuClass: ' ',
      caregiverMenuClass: ' ',
      queueMenuClass: ' ',
      alertsMenuClass: ' ',
      messagesMenuClass: ' ',
      picturesMenuClass: ' ',
      groupsMenuClass: ' ',
      bulletinMenuClass: ' ',
      classesMenuClass: ' ',
      reportsMenuClass: ' ',
      settingsMenuClass: ' ',
      appsMenuClass: ' ',
      queueNumber: 0,
      queueNumberVisibility: ' hide',
      alertsNumber: 0,
      alertsNumberVisibility: ' hide',

      lastCallRequestID: '',
      lastCallRequestPatientID: '',
      callRequests: CareQueueStore.getQueueNumber(),

      staffVisibility: AuthStore.canAccessStaff() ? '' : ' hide',
      clientsVisibility: AuthStore.canAccessClients() ? '' : ' hide',
      queueVisibility: AuthStore.canAccessQueue() ? '' : ' hide',
      alertsVisibility: AuthStore.canAccessAlerts() ? '' : ' hide',
      messagesVisibility: AuthStore.canAccessMessages() ? '' : ' hide',
      picturesVisibility: AuthStore.canAccessPictures() ? '' : ' hide',
      bulletinVisibility: AuthStore.canAccessBulletin() ? '' : ' hide',
      devicesVisibility: AuthStore.canAccessDevices() ? '' : ' hide',
      appsVisibility: AuthStore.canAccessExternalApps() ? '' : ' hide',
    };

    this.callRequests = CareQueueStore.getQueueNumber();

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      //this.datetimeFormat = "m/d/Y h:i A";
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      //this.datetimeFormat = "m/d/Y H:i";
      //this.timeFormat = "HH:mm A";
      this.timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }

    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleMenusVisibility = this.handleMenusVisibility.bind(this);

  }

  componentDidMount() {
    //Register Listener
    CareQueueStore.on('onGetQueueNumber', this.onGetQueueNumberAction);
    CareQueueStore.on('onGetCareCallRequestsQueue', this.onGetCareCallRequestsQueueAction);
    CareQueueStore.on('onStartPollingQueue', this.onStartPollingQueueAction);
    CareQueueStore.on('onQueueItemClicked', this.onQueueItemClickedAction);
    CareQueueStore.on('onHandledRequestRemovedFromStore', this.onHandledRequestRemovedFromStoreAction);
    CareQueueStore.on('onGenerateNotification', this.onGenerateNotificationAction);
    CareAlertsStore.on('onAlertsCountUpdated', this.onAlertsCountUpdatedAction);
    CareAlertsStore.on('onAlertsCountUpdated_2', this.onAlertsCountUpdated_2Action);

    if (this.state.queueMenuClass == ' ') {
      this.isPolling = true;
      CareQueueStore.setPollingQueueNumber(true);
      CareQueueStore.getCareQueueNumber({
        UserID: AuthStore.getUserID(),
      });
    } else {
      CareQueueStore.setPollingQueueNumber(false);
    }

    this.ringingStatus = false;

    CareAlertsStore.getAlertsCountForAccount({
      AccountID: AuthStore.getUserAccountID(),
    });
  }

  componentDidUpdate(prevProps) {
    try {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        this.handleMenusVisibility(this.props.location.pathname);
      }
    } catch (error) {
      console.log('SideBar error on componentDidUpdate:', error);
    }
  }

  componentWillUnmount() {
    //Remove Listener
    CareQueueStore.removeListener('onGetQueueNumber', this.onGetQueueNumberAction);
    CareQueueStore.removeListener('onGetCareCallRequestsQueue', this.onGetCareCallRequestsQueueAction);
    CareQueueStore.removeListener('onStartPollingQueue', this.onStartPollingQueueAction);
    CareQueueStore.removeListener('onQueueItemClicked', this.onQueueItemClickedAction);

    CareQueueStore.removeListener('onHandledRequestRemovedFromStore', this.onHandledRequestRemovedFromStoreAction);
    CareQueueStore.removeListener('onGenerateNotification', this.onGenerateNotificationAction);

    CareAlertsStore.removeListener('onAlertsCountUpdated', this.onAlertsCountUpdatedAction);
    CareAlertsStore.removeListener('onAlertsCountUpdated_2', this.onAlertsCountUpdated_2Action);
  }

  onStartPollingQueueAction = () => {
    this.startPollingQueue();
  };

  startPollingQueue = () => {
    console.log('startPollingQueue ' + !this.isPolling);
    console.log('QUEUES NOW!!!! : ', CareQueueStore.getQueueNumber());
    if (!this.isPolling) {
      this.isPolling = true;
      CareQueueStore.setPollingQueueNumber(true);
      CareQueueStore.getCareQueueNumber({
        UserID: AuthStore.getUserID(),
      });
      // Listen to "onGetQueueNumber"
    }

    GeneralStore.setStaffTab(1);
  };

  onGetQueueNumberAction = response => {
    ////console.log("onGetQueueNumberAction", response.number);
    ////console.log("onGetQueueNumberAction", CareQueueStore.getQueueNumber());
    // QUEUE BADGE, CARE-3624, Implemented Nov 2018
    if (response.number != this.state.queueNumber) {
      this.setState({
        queueNumber: response.number,
        queueNumberVisibility: response.number > 0 ? ' ' : ' hide',
      });
    }

    if (response.number < CareQueueStore.getQueueNumber()) {
      ////console.log("Serian ", (response.number - CareQueueStore.getQueueNumber()));
      CareQueueStore.setQueueNumber(response.number);
    }

    const newQueues = response.number - CareQueueStore.getQueueNumber();
    this.isPolling = false;

    if (this.state.queueMenuClass == ' ') {
      if (newQueues > 0) {
        this.createNotification();
      }

      if (!this.isPolling) {
        this.isPolling = true;
        setTimeout(function () {
          CareQueueStore.getCareQueueNumber({
            UserID: AuthStore.getUserID(),
          });
          //Listen to "onGetQueueNumber"
        }, (1000 * 1 * 10));
      }
    }
  };

  onGetCareCallRequestsQueueAction = () => {
    console.log('onGetCareCallRequestsQueueAction');
    ////console.log(this.callRequests);
    const queueRequests = CareQueueStore.getQueueNumber();
    ////console.log(queueRequests);

    if (queueRequests != this.state.queueNumber) {
      this.setState({
        queueNumber: queueRequests,
        queueNumberVisibility: queueRequests > 0 ? ' ' : ' hide',
      });
    }

    if (this.state.queueMenuClass == ' menu-active') {
      if (this.callRequests < CareQueueStore.getQueueNumber()) {
        console.log('There was a pending request on Queue');
        this.callRequests = CareQueueStore.getQueueNumber();
        this.createNotification();
      }

      setTimeout(function () {
        CareQueueStore.getCareCallRequestsQueueForUser({
          UserID: AuthStore.getUserID(),
        });
        //Listen to "onGetCareCallRequestsQueue"
      }, 10000); // TODO-T:10000
    }
  };

  onHandledRequestRemovedFromStoreAction = () => {
    this.callRequests = CareQueueStore.getQueueNumber();
  };

  onGenerateNotificationAction = () => {
    const lastCallRequest = CareQueueStore.getLastPendingRequest() || CareQueueStore.getLastRequest();

    if (lastCallRequest && !lastCallRequest.IsAddressed && AuthStore.getOnDuty().toString() == 'true') {
      this.setState({
        lastCallRequestID: lastCallRequest.RequestID,
        lastCallRequestPatientID: lastCallRequest.PatientID,
      });
      const patient = CarePatientStore.getPatientInfo(lastCallRequest.PatientID);
      if (patient) {
        console.log('NOTIFICATION PATIENT: ', patient);
        console.log('NOTIFICATION REQUEST: ', lastCallRequest.IsAddressed);
        this.showNotifications(patient.Name, lastCallRequest.DeviceName, lastCallRequest.PatientID, lastCallRequest.IsEmergency, lastCallRequest.RequestTime);
      }
    }
  };

  onQueueItemClickedAction = () => {
    this.stopNotifications();
  };

  selectUserMenu = () => {
    this.resetMenus();

    this.setState({
      userMenuClass: ' menu-active',
    });
  };

  selectPatientMenu = () => {
    this.resetMenus();

    this.setState({
      patientMenuClass: ' menu-active',
    });
  };

  selectCaregiverMenu = () => {
    this.resetMenus();

    this.setState({
      caregiverMenuClass: ' menu-active',
    });
  };

  selectQueueMenu = () => {
    this.resetMenus();

    this.setState({
      queueMenuClass: ' menu-active',
      ////queueNumber : 0,
      ////queueNumberVisibility : " hide"
    });
    this.isPolling = false;
    CareQueueStore.setPollingQueueNumber(false);
  };

  selectAlertsMenu = () => {
    this.resetMenus();
    this.setState({
      alertsMenuClass: ' menu-active',
    });
  };

  selectMessagesMenu() {
    this.resetMenus();
    this.setState({
      messagesMenuClass: ' menu-active',
    });
  }

  selectPicturesMenu = () => {
    this.resetMenus();

    this.setState({
      picturesMenuClass: ' menu-active',
    });
  };

  selectGroupsMenu = () => {
    this.resetMenus();

    this.setState({
      groupsMenuClass: ' menu-active',
    });
  };

  selectBulletinMenu = () => {
    this.resetMenus();

    this.setState({
      bulletinMenuClass: ' menu-active',
    });
  };

  selectDevicesMenu = () => {
    this.resetMenus();

    this.setState({
      devicesMenuClass: ' menu-active',
    });
  };

  selectClassesMenu = () => {
    this.resetMenus();
    this.setState({
      classesMenuClass: ' menu-active',
    });
  };

  selectReportsMenu = () => {
    this.resetMenus();
    this.setState({
      reportsMenuClass: ' menu-active',
    });
  };

  selectAppsMenu() {
    this.resetMenus();
    this.setState({
      appsMenuClass: ' menu-active',
    });
  }

  selectSettingsMenu = () => {
    this.resetMenus();
    this.setState({
      settingsMenuClass: ' menu-active',
    });
  };

  resetMenus = () => {
    this.setState({
      userMenuClass: ' ',
      patientMenuClass: ' ',
      devicesMenuClass: ' ',
      caregiverMenuClass: ' ',
      queueMenuClass: ' ',
      alertsMenuClass: ' ',
      messagesMenuClass: ' ',
      picturesMenuClass: ' ',
      groupsMenuClass: ' ',
      bulletinMenuClass: ' ',
      classesMenuClass: ' ',
      reportsMenuClass: ' ',
      appsMenuClass: ' ',
      settingsMenuClass: ' ',
    });
  };
  handleNavigation(previousLocation, currentLocation) {
    console.log('Sidebar handleNavigation', previousLocation, currentLocation);
    if (previousLocation?.pathname) {
      this.handleMenusVisibility(previousLocation?.pathname);
    }
  }

  handleMenusVisibility(currentPath) {
    console.log('PAGE 1:', currentPath);

    if (currentPath.includes('/users') || currentPath.includes('/userProfile') || currentPath.includes('/newUser') || currentPath.includes('/newAssignedPatient')) {
      this.selectUserMenu();
    } else if (currentPath.includes('/patients') || currentPath.includes('/patientProfile') || currentPath.includes('/newPatient') || currentPath.includes('/newFamilyMember')) {
      this.selectPatientMenu();
    } else if (currentPath.includes('/caregivers') || currentPath.includes('/caregiverProfile') || currentPath.includes('/newCaregiver')) {
      //this.selectCaregiverMenu();
      this.selectUserMenu();
    } else if (currentPath.includes('/settings')) {
      this.selectSettingsMenu();
    } else if (currentPath.includes('/messages')) {
      this.selectMessagesMenu();
    } else if (currentPath.includes('/queue')) {
      this.selectQueueMenu();
    } else if (currentPath.includes('/alerts')) {
      this.selectAlertsMenu();
    } else if (currentPath.includes('/pictures')) {
      this.selectPicturesMenu();
    } else if (currentPath.includes('/groups')) {
      this.selectGroupsMenu();
    } else if (currentPath.includes('/bulletin')) {
      this.selectBulletinMenu();
    } else if (currentPath.includes('/classes')) {
      this.selectClassesMenu();
    } else if (currentPath.includes('/devices')) {
      this.selectDevicesMenu();
    } else if (currentPath.includes('/videoSession')) {
      this.selectClassesMenu();
    } else if (
      currentPath.includes('/reports') //|| currentPath.includes("/reportCalls")
    ) {
      this.selectReportsMenu();
    } else if (currentPath.includes('/externalApps')) {
      this.selectAppsMenu();
    } else {
      this.resetMenus();
    }
  }

  handleLogout = () => {
    const { dispatch } = this.props;
    console.log('handleLogout, dispatch', dispatch);
    AuthStore.logout(dispatch);
  };

  createNotification = () => {
    const lastCallRequest = CareQueueStore.getLastRequest();
    console.log('NEW REQUEST: '); //, lastCallRequest);

    if (AuthStore.getOnDuty().toString() == 'true') {
      //(lastCallRequest.PatientID != this.state.lastCallRequestPatientID ) &&
      if (!lastCallRequest.IsAddressed && lastCallRequest.RequestID != this.state.lastCallRequestID) {
        this.setState({
          lastCallRequestID: lastCallRequest.RequestID,
          lastCallRequestPatientID: lastCallRequest.PatientID,
        });
        const patient = CarePatientStore.getPatientInfo(lastCallRequest.PatientID);
        if (patient) {
          console.log('NOTIFICATION PATIENT: ', patient);
          console.log('NOTIFICATION REQUEST: ', lastCallRequest.IsAddressed);

          this.showNotifications(patient.Name, lastCallRequest.DeviceName, lastCallRequest.PatientID, lastCallRequest.IsEmergency, lastCallRequest.RequestTime);
        }
      }
    }
  };

  showNotifications = (patientName, deviceName, patientID, emergency, requestTime) => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    } else {
      const dateTimeString = new Date(requestTime).toLocaleDateString() + ' ' + new Date(requestTime).toLocaleTimeString('en-US', this.timeOptions);
      const notify = new Notification('Nucleus Care - Incoming Call', {
        body: dateTimeString + ' ' + patientName + ' - ' + deviceName + ' requests a call.',
      });
      notify.onclick = () => {
        console.log('Notification Click');
        this.props.history.push('/queue');
        notify.close();
        parent.focus();
        window.focus();
        this.stopNotifications();
      };
      notify.onclose = () => {
        console.log('Notification Close');
        this.stopNotifications();
      };
    }
  };

  stopNotifications = () => {
    console.log('PAUSE');
  };

  onAlertsCountUpdatedAction = response => {
    console.log('onAlertsCountUpdatedAction');

    if (response.ok) {
      if (response.alerts != this.state.alertsNumber) {
        this.setState({
          alertsNumber: response.alerts,
          alertsNumberVisibility: response.alerts > 0 ? ' ' : ' hide',
        });
      }
    }

    setTimeout(function () {
      CareAlertsStore.getAlertsCountForAccount({
        AccountID: AuthStore.getUserAccountID(),
      });
    }, 30000);
  };

  onAlertsCountUpdated_2Action = response => {
    console.log('onAlertsCountUpdated_2Action');

    if (response.ok) {
      if (response.alerts != this.state.alertsNumber) {
        this.setState({
          alertsNumber: response.alerts,
          alertsNumberVisibility: response.alerts > 0 ? ' ' : ' hide',
        });
      }
    }
  };

  getVersion = () => {
    let version = 'v 2';
    try {
      version = 'v ' + packageJson.version;
    } catch (error) {
      console.warn('Error getVersion cannot read packageJson');
    }
    return version;
  };

  render() {
    return (
      <div className="blue col s2 m2 l1 xl1  no-margin nucleus-sidebar">
        <NavigationListener onNavigationLocationChange={this.handleNavigation}></NavigationListener>
        <div className="row txt-white menu-content">
          <Link id={'sili-dashboard'} to={`/provider/${AuthStore.getProviderID()}/accounts`} className="txt-white">
            <div className="sidebar-logo center-align" onClick={this.startPollingQueue}>
              <br />
              <img className="responsive-img" src="/img/nucleus-logo.svg" />
            </div>
          </Link>

          <Link id={'sili-users'} to={'/users'} className={'txt-white ' + this.state.staffVisibility}>
            <div
              className={'center-align row nucleus-menu' + this.state.userMenuClass}
              onClick={() => {
                this.selectUserMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <StaffSVG active={this.state.userMenuClass.length > 1} />
              </div>
              <div className="col m12">Staff</div>
            </div>
          </Link>

          <Link id={'sili-patients'} to={'/patients'} className={'txt-white ' + this.state.clientsVisibility}>
            <div
              className={'center-align row nucleus-menu' + this.state.patientMenuClass}
              onClick={() => {
                this.selectPatientMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <ClientsSVG active={this.state.patientMenuClass.length > 1} />
              </div>
              <div className="col m12">{AuthStore.getPatientLabelPlural()}</div>
            </div>
          </Link>

          <GroupsMenuFromSelector
            componentRenderer={() => (
              <Link id={'sili-groups'} to={'/groups'} className={'txt-white '}>
                <div
                  className={'center-align row nucleus-menu' + this.state.groupsMenuClass}
                  onClick={() => {
                    this.selectGroupsMenu();
                    this.startPollingQueue();
                  }}
                >
                  <div className="col m12">
                    <GroupsSVG active={this.state.groupsMenuClass.length > 1} />
                  </div>
                  <div className="col m12">{AuthStore.getGroupLabel()}</div>
                </div>
              </Link>
            )}
          />

          <Link id={'sili-queue'} to={'/queue'} className={'txt-white ' + this.state.queueVisibility}>
            <div className={'center-align row nucleus-menu  ' + this.state.queueMenuClass} onClick={this.selectQueueMenu}>
              <div className="row no-margin">
                <div className="col m12 nucleus-active-menu">
                  <QueueSVG active={this.state.queueMenuClass.length > 1} />
                  {this.state.queueNumber > 0 && <AlertsCountSVG count={this.state.queueNumber} />}
                  {/* <span className={ "nucleus-menu-notification " + this.state.queueNumberVisibility }>&nbsp;&nbsp;&nbsp;&nbsp; {this.state.queueNumber} &nbsp;&nbsp;&nbsp;&nbsp;</span> */}
                </div>
                <div className="col m12">Queue</div>
              </div>
            </div>
          </Link>

          <Link id={'sili-alerts'} to={'/alerts'} className={'txt-white ' + this.state.alertsVisibility}>
            <div className={'center-align row nucleus-menu  ' + this.state.alertsMenuClass} onClick={this.selectAlertsMenu}>
              <div className="row no-margin">
                <div className="col m12 nucleus-active-menu">
                  <AlertsSVG active={this.state.alertsMenuClass.length > 1} />
                  {this.state.alertsNumber > 0 && <AlertsCountSVG count={this.state.alertsNumber} />}
                  {/* <span className={ "nucleus-menu-notification " + this.state.alertsNumberVisibility }>&nbsp;&nbsp; {this.state.alertsNumber} &nbsp;&nbsp;</span> */}
                </div>
                <div className="col m12">Alerts</div>
              </div>
            </div>
          </Link>

          <Link id={'sili-messages'} to={'/messages'} className={'txt-white ' + this.state.messagesVisibility}>
            <div
              className={'center-align row nucleus-menu' + this.state.messagesMenuClass}
              onClick={() => {
                this.selectMessagesMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <MessagesSVG active={this.state.messagesMenuClass.length > 1} />
              </div>
              <div className="col m12">Messages</div>
            </div>
          </Link>

          <Link id={'sili-pictures'} to={'/pictures'} className={'txt-white ' + this.state.picturesVisibility}>
            <div
              className={'center-align row nucleus-menu' + this.state.picturesMenuClass}
              onClick={() => {
                this.selectPicturesMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <MemoryBoxSVG active={this.state.picturesMenuClass.length > 1} />
              </div>
              <div className="col m12">{AuthStore.getPicturesLabel() || 'Memory Box'}</div>
            </div>
          </Link>

          <Link id={'sili-bulletin'} to={'/bulletin'} className={'txt-white ' + this.state.bulletinVisibility}>
            <div
              className={'center-align row nucleus-menu' + this.state.bulletinMenuClass}
              onClick={() => {
                this.selectBulletinMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <BulletinBoardSVG active={this.state.bulletinMenuClass.length > 1} />
              </div>
              <div className="col m12">Bulletin Board</div>
            </div>
          </Link>

          <Link id={'sili-devices'} to={'/devices'} className={'txt-white ' + this.state.devicesVisibility}>
            <div
              className={'center-align row nucleus-menu' + this.state.devicesMenuClass}
              onClick={() => {
                this.selectDevicesMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <DevicesSVG active={this.state.devicesMenuClass.length > 1} />
              </div>
              <div className="col m12">{'Devices'}</div>
            </div>
          </Link>

          <ClassesMenuFromSelector
            componentRenderer={menuName => (
              <Link id={'sili-videoSession'} to={'/videoSession'} className="txt-white">
                <div
                  className={'center-align row nucleus-menu' + this.state.classesMenuClass}
                  onClick={() => {
                    this.selectClassesMenu();
                    this.startPollingQueue();
                  }}
                >
                  <div className="col m12">
                    <ClassesSVG active={this.state.classesMenuClass.length > 1} />
                  </div>
                  <div className="col m12">{menuName}</div>
                </div>
              </Link>
            )}
          />

          <ReportsMenuFromSelector
            componentRenderer={() => (
              <Link id="reports-tab" to={'/reports'} className="txt-white">
                <div
                  className={'center-align row nucleus-menu' + this.state.reportsMenuClass}
                  onClick={() => {
                    this.selectReportsMenu();
                    this.startPollingQueue();
                  }}
                >
                  <div className="col m12">
                    <ReportsSVG active={this.state.reportsMenuClass.length > 1} />
                  </div>
                  <div className="col m12">Reports</div>
                </div>
              </Link>
            )}
          />

          <Link id={'sili-apps'} to={'/externalApps'} className={'txt-white ' + this.state.appsVisibility}>
            <div
              className={'center-align row nucleus-menu' + this.state.appsMenuClass}
              onClick={() => {
                this.selectAppsMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <AppsSVG active={this.state.appsMenuClass.length > 1} />
              </div>
              <div className="col m12">{'Apps'}</div>
            </div>
          </Link>

          <Link id={'sili-settings'} to={'/settings'} className="txt-white">
            <div
              className={'center-align row nucleus-menu' + this.state.settingsMenuClass}
              onClick={() => {
                this.selectSettingsMenu();
                this.startPollingQueue();
              }}
            >
              <div className="col m12">
                <SettingsSVG active={this.state.settingsMenuClass.length > 1} />
              </div>
              <div className="col m12">Settings</div>
            </div>
          </Link>

          <div className="row"></div>

          <div id={'sili-logout'} className="row txt-white nucleus-sidebar-bottom nucleus-link" onClick={this.handleLogout}>
            <div className="center-align row nucleus-menu">
              <div className="col m12" style={{ color: 'white', fontWeight: 'normal' }}>
                Logout
              </div>
            </div>
          </div>
          <p className="nucleus-font-book" style={{ textAlign: 'center', marginTop: 12 }}>
            <span style={{ fontSize: 12, color: '#FFFFFFA9' }}>{this.getVersion()}</span>
          </p>

          {/*<span onClick={this.TestFunction}>Test</span> */}
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const SideBarFC = props => {
  return useReactRouterProps(SideBar, props);
};

export default connect(null, mapDispatchToProps)(SideBarFC);
