import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { UIModal, UIInput } from 'components/UI';
import { ModalAction } from 'components/UI/Modals/Base/ActionButtons';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import authStore from 'stores/AuthStore';
import styled from 'styled-components';
import Message from 'utils/Message';
import { providerGroupLevelKeyIndex, TierTitles, ReportFrequency } from './types';
import { daysOfTheWeek, daysOfTheMonth, mothOfYear, periodOptions } from './utils/FrequencyConstants';

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const FrequencyForm = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
`;

const FrequencyText = styled.div`
  font-size: 16px;
  color: #0a313f;
  margin-right: 10px;
  font-weight: 600;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  tierSelected: keyof typeof providerGroupLevelKeyIndex;
  tierTitles: TierTitles;
  frequencies: ReportFrequency[];
  requestSuccessCallback?: () => void;
}

const TITLES = {
  tier1: 'Edit Card Details',
  tier2: 'Edit Card Details',
  tier3: 'Edit Card Details',
};

const EditLevelTitle: FC<Props> = ({ closeModal, isOpen, tierSelected, tierTitles, frequencies, requestSuccessCallback }) => {
  const [tierSingular, setTierSingular] = useState('');
  const [tierSingularError, setTierSingularError] = useState('');
  const [tierPlural, setTierPlural] = useState('');
  const [tierPluralError, setTierPluralError] = useState('');

  const [frequencyPeriodError, setFrequencyPeriodError] = useState('');
  const [weeklyFrequencyError, setWeeklyFrequencyError] = useState('');
  const [monthlyFrequencyError, setMonthlyFrequencyError] = useState('');
  const [yearlyFrequencyError, setYearlyFrequencyError] = useState('');

  const [saveCategoryError, setSaveCategoryError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [frequencyPeriod, setFrequencyPeriod] = useState('');
  const [weeklyFrequency, setWeeklyFrequency] = useState('');
  const [monthlyFrequency, setMonthlyFrequency] = useState('');
  const [yearlyFrequency, setYearlyFrequency] = useState('');

  const [currentFrequencyId, setCurrentFrequencyId] = useState<string | null>(null);

  const daysOfTheWeekOptions = useMemo(() => daysOfTheWeek, []);
  const daysOfTheMonthOptions = useMemo(() => daysOfTheMonth, []);
  const monthsOfTheYear = useMemo(() => mothOfYear, []);

  useEffect(() => {
    switch (tierSelected) {
      case 'tier1': {
        const tier1Frequency = frequencies.find(f => Number(f.GroupLevel) === 1);
        setFrequencyPeriod(tier1Frequency?.RecurringType || '');
        setWeeklyFrequency(tier1Frequency?.DayOfWeek || '');
        setMonthlyFrequency(tier1Frequency?.DayOfMonth || '');
        setYearlyFrequency(tier1Frequency?.MonthOfYear || '');
        setCurrentFrequencyId(tier1Frequency?.id || null);
        console.log('tier1Frequency', tier1Frequency);
        break;
      }
      case 'tier2': {
        const tier2Frequency = frequencies.find(f => Number(f.GroupLevel) === 2);
        setFrequencyPeriod(tier2Frequency?.RecurringType || '');
        setWeeklyFrequency(tier2Frequency?.DayOfWeek || '');
        setMonthlyFrequency(tier2Frequency?.DayOfMonth || '');
        setYearlyFrequency(tier2Frequency?.MonthOfYear || '');
        setCurrentFrequencyId(tier2Frequency?.id || null);
        console.log('tier2Frequency', tier2Frequency);
        break;
      }
      case 'tier3': {
        const tier3Frequency = frequencies.find(f => Number(f.GroupLevel) === 3);
        setFrequencyPeriod(tier3Frequency?.RecurringType || '');
        setWeeklyFrequency(tier3Frequency?.DayOfWeek || '');
        setMonthlyFrequency(tier3Frequency?.DayOfMonth || '');
        setYearlyFrequency(tier3Frequency?.MonthOfYear || '');
        setCurrentFrequencyId(tier3Frequency?.id || null);
        break;
      }
    }
    return () => {
      setFrequencyPeriod('');
      setWeeklyFrequency('');
      setMonthlyFrequency('');
      setYearlyFrequency('');
      setCurrentFrequencyId(null);
    };
  }, [frequencies, tierSelected, isOpen]);

  const buildFrequencyObject = () => {
    switch (tierSelected) {
      case 'tier1':
        return {
          groupLevel: 1,
          recurringType: frequencyPeriod,
          dayOfWeek: weeklyFrequency ?? null,
          dayOfMonth: monthlyFrequency ?? null,
          monthOfYear: yearlyFrequency ?? null,
        };
      case 'tier2':
        return {
          groupLevel: 2,
          recurringType: frequencyPeriod,
          dayOfWeek: weeklyFrequency ?? null,
          dayOfMonth: monthlyFrequency ?? null,
          monthOfYear: yearlyFrequency ?? null,
        };
      case 'tier3':
        return {
          groupLevel: 3,
          recurringType: frequencyPeriod,
          dayOfWeek: weeklyFrequency ?? null,
          dayOfMonth: monthlyFrequency ?? null,
          monthOfYear: yearlyFrequency ?? null,
        };
    }
  };
  const handleWeeklyFrequency = e => {
    setWeeklyFrequency(e.value);
  };
  const handleMonthlyFrequency = e => {
    setMonthlyFrequency(e.value);
  };
  const handleYearlyFrequency = e => {
    setYearlyFrequency(e.value);
  };

  const renderSelectBasedOnFrequency = () => {
    switch (frequencyPeriod) {
      case 'WEEKLY':
        return (
          <div>
            <Select
              styles={selectStyles}
              className="accountSelect"
              options={daysOfTheWeekOptions}
              value={daysOfTheWeekOptions.find(d => d.value === Number(weeklyFrequency))}
              isSearchable
              placeholder="Select day"
              onChange={handleWeeklyFrequency}
            />
            <Error visible={!!weeklyFrequencyError}>{weeklyFrequencyError}</Error>
          </div>
        );
      case 'MONTHLY':
        return (
          <div>
            <Select
              styles={selectStyles}
              options={daysOfTheMonthOptions}
              value={daysOfTheMonthOptions.find(d => d.value === Number(monthlyFrequency))}
              isSearchable
              placeholder="Select day"
              onChange={handleMonthlyFrequency}
            />
            <Error visible={!!monthlyFrequencyError}>{monthlyFrequencyError}</Error>
          </div>
        );
      case 'ANNUALLY':
        return (
          <div>
            <Select
              styles={selectStyles}
              options={monthsOfTheYear}
              value={monthsOfTheYear.find(d => d.value === Number(yearlyFrequency))}
              isSearchable
              placeholder="Select month"
              onChange={handleYearlyFrequency}
            />
            <Error visible={!!yearlyFrequencyError}>{yearlyFrequencyError}</Error>
            <Select
              styles={selectStyles}
              options={daysOfTheMonthOptions}
              value={daysOfTheMonthOptions.find(d => d.value === Number(monthlyFrequency))}
              isSearchable
              placeholder="Select day"
              onChange={handleMonthlyFrequency}
            />
            <Error visible={!!monthlyFrequencyError}>{monthlyFrequencyError}</Error>

          </div>
        );
      default:
        return <></>;
    }
  };
  useEffect(() => {
    switch (tierSelected) {
      case 'tier1':
        setTierSingular(tierTitles.Tier1);
        setTierPlural(tierTitles.Tier1Plural);
        break;
      case 'tier2':
        setTierSingular(tierTitles.Tier2);
        setTierPlural(tierTitles.Tier2Plural);
        break;
      case 'tier3':
        setTierSingular(tierTitles.Tier3);
        setTierPlural(tierTitles.Tier3Plural);
        break;
    }
    return () => {
      setTierSingular('');
      setTierPlural('');
    };
  }, [tierTitles, tierSelected]);

  useEffect(() => {
    if (tierSingular) {
      setTierSingularError('');
    }
    if (tierPlural) {
      setTierPluralError('');
    }
    if (frequencyPeriod) {
      setFrequencyPeriodError('');
    }
    if (weeklyFrequency) {
      setWeeklyFrequencyError('');
    }
    if (monthlyFrequency) {
      setMonthlyFrequencyError('');
    }
    if (yearlyFrequency) {
      setYearlyFrequencyError('');
      setMonthlyFrequencyError("");
    }
    return () => {
      setTierSingularError('');
      setTierPluralError('');
      setFrequencyPeriodError('');
      setWeeklyFrequencyError('');
      setMonthlyFrequencyError('');
      setYearlyFrequencyError('');
    };
  }, [tierSingular, tierPlural, frequencyPeriod, weeklyFrequency, monthlyFrequency, yearlyFrequency]);

  const buildTierTitlesObject = () => {
    switch (tierSelected) {
      case 'tier1':
        return {
          Tier1: tierSingular,
          Tier1Plural: tierPlural,
        };
      case 'tier2':
        return {
          Tier2: tierSingular,
          Tier2Plural: tierPlural,
        };
      case 'tier3':
        return {
          Tier3: tierSingular,
          Tier3Plural: tierPlural,
        };
    }
  };
  const editTierTitle = async () => {
    try {
      const tierObject = buildTierTitlesObject();
      console.log('tierObject', tierObject);

      await httpApi.put(`/providers/${authStore.getProviderID()}/account_grouping_strings`, tierObject);
      if (currentFrequencyId) {
        await editFrequency();
      } else {
        await createFrequency();
      }
      Message.show('Strings edited');
      requestSuccessCallback?.();
      handleCloseModal();
    } catch (e: any) {
      setSaveCategoryError(e.response.data?.message || 'Error editing strings');
      setIsLoading(false);
    }
  };

  const editFrequency = async () => {
    try {
      const data = buildFrequencyObject();
      await httpApi.put(`providers/${authStore.getProviderID()}/frequency/${currentFrequencyId}`, data);
      setIsLoading(false);
      handleCloseModal();
    } catch (e: any) {
      setSaveCategoryError(e.response.data?.message || 'Error editing frequency');
      setIsLoading(false);
    }
  };
  const createFrequency = async () => {
    try {
      const data = buildFrequencyObject();
      await httpApi.post(`providers/${authStore.getProviderID()}/frequency`, data);
      setIsLoading(false);
      handleCloseModal();
    } catch (e: any) {
      setSaveCategoryError(e.response.data?.message || 'Error editing frequency');
      setIsLoading(false);
    }
  };

  const validate = () => {
    if (!tierSingular && tierSingular.trim() === '') {
      setTierSingularError('Singular title is required');
      return false;
    }
    if (!tierPlural && tierPlural.trim() === '') {
      setTierPluralError('Plural title is required');
      return false;
    }
    if (frequencyPeriod === 'WEEKLY' && !weeklyFrequency) {
      setWeeklyFrequencyError('Weekly frequency is required');
      return false;
    }
    if (frequencyPeriod === 'MONTHLY' && !monthlyFrequency) {
      setMonthlyFrequencyError('Monthly frequency is required');
      return false;
    }
    if (frequencyPeriod === 'ANNUALLY' && !yearlyFrequency || !monthlyFrequency) {
      setYearlyFrequencyError('Yearly frequency is required');
      setMonthlyFrequencyError("Monthly frequency is required");
      return false;
    }

    return true;
  };
  useEffect(() => {
    setDisableSubmit(!validate());
  }, [tierSingular, tierPlural, frequencyPeriod, weeklyFrequency, monthlyFrequency, yearlyFrequency]);
  const submit = () => {
    if (!validate()) return;
    editTierTitle();
  };

  const handleTierSingular = (e: ChangeEvent<HTMLInputElement>) => {
    setTierSingular(e.target.value);
  };
  const handleTierPlural = (e: ChangeEvent<HTMLInputElement>) => {
    setTierPlural(e.target.value);
  };

  const handleFrequencyPeriod = e => {
    setFrequencyPeriod(e.value);
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Save',
        onClick: submit,
        disabled: isLoading || disableSubmit,
      },
    ];
    return data;
  };

  const handleCloseModal = () => {
    closeModal();
    setSaveCategoryError('');
  };

  return (
    <UIModal size="large" isOpen={isOpen} close={handleCloseModal} title={TITLES[tierSelected]} actions={getActions()}>
      <InputGroup>
        <UIInput label="Tier (singular):" placeholder="Company" value={tierSingular} onChange={handleTierSingular} error={tierSingularError || saveCategoryError} />
        <UIInput label="Tier (plural):" placeholder="Companies" value={tierPlural} onChange={handleTierPlural} error={tierPluralError || saveCategoryError} />
        <FrequencyText>Call logs report frequency:</FrequencyText>
        <FrequencyForm>
          <span style={{ width: '15%', marginTop: 15 }}>Repeat every</span>
          <div style={{ width: '40%', marginLeft: 20 }}>
            <div>
              <Select
                styles={selectStyles}
                className="accountSelect"
                options={periodOptions}
                value={periodOptions.find(p => p.value === frequencyPeriod)}
                isSearchable
                placeholder="Select period"
                onChange={handleFrequencyPeriod}
              />
              <Error visible={!!frequencyPeriodError}>{frequencyPeriodError}</Error>
            </div>
          </div>
          <div style={{ width: '40%', marginLeft: 20 }}>{renderSelectBasedOnFrequency()}</div>
        </FrequencyForm>
      </InputGroup>
    </UIModal>
  );
};
const Error = styled.div<{ visible?: boolean }>`
  color: #fe3824;
  font-size: 10px;
  margin-top: 5px;
  margin-bottom: 0px;
  height: 15px;
  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;
const selectStyles = {
  control: provided => ({
    ...provided,
    height: '50px',
    minHeight: '50px',
  }),
  valueContainer: provided => ({
    ...provided,
    height: '50px',
    padding: '0 6px',
  }),
  input: provided => ({
    ...provided,
    margin: '0px',
  }),
  placeholder: provided => ({
    ...provided,
    margin: '0px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  }),
};
export default EditLevelTitle;
