import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import authStore from '../../stores/AuthStore';
import Message from '../../utils/Message';
import AccountSelector from '../Account/AccountSelector';
import useAccountSelector from '../Account/useAccountSelector';
import { UIModal } from '../UI';
import { ModalAction } from '../UI/Modals/Base/ActionButtons';
import { ProviderGroup, TierTitles } from './types';
import useRegions from './useRegions';

const Label = styled.div`
  font-size: 16px;
  color: #0a313f;
  margin-bottom: 5px;
  font-weight: 600;
`;

const GroupName = styled.div`
  font-size: 16px;
  color: #0092ff;
  margin-bottom: 24px;
  font-weight: 300;
`;

const Divider = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  group?: ProviderGroup | null;
  tierTitles?: TierTitles;
}

const AddAccountToGroup: FC<Props> = ({ closeModal, isOpen, group }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { accounts, selectedAccounts, toggleAccountsSelection, reset, searchTerm, setSearchTerm } = useAccountSelector();
  const { accountsPerRegion, getAccountsByRegionId } = useRegions();

  const onCloseModal = () => {
    reset();
    closeModal();
  };

  useEffect(() => {
    if (group?.id && isOpen) {
      getAccountsByRegionId(group.id);
    }
  }, [group, isOpen]);

  const save = async () => {
    onCloseModal();

    const selectedIds = selectedAccounts.map(p => p.id);

    if (selectedIds.length === 0) {
      Message.show('Please select at least one account');
      return;
    }

    try {
      setIsLoading(true);
      await httpApi.put(`/providers/${authStore.getProviderID()}/groups/${group?.id}/accounts`, {
        accountIds: selectedIds,
        level: 3,
      });
      setIsLoading(false);
      Message.show('Successfully added to Group');
      onCloseModal();
    } catch (error) {
      Message.show('Error while adding to the group.');
      setIsLoading(false);
    }
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Add',
        onClick: save,
        disabled: isLoading,
      },
    ];

    return data;
  };

  if (!group) return null;

  return (
    <UIModal size="medium" isOpen={isOpen} close={onCloseModal} title="Add Account to Group" actions={getActions()}>
      <Label>You are adding account to:</Label>
      <GroupName>Group: {group.name}</GroupName>
      <Divider />
      <Label>Account to add</Label>
      <AccountSelector
        accounts={accounts}
        togglePatientSelection={toggleAccountsSelection}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedAccounts={selectedAccounts}
        accountsPerGroup={accountsPerRegion}
      />
    </UIModal>
  );
};

export default AddAccountToGroup;
