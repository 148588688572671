import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { ICreateProviderSchedule, IProviderAvailabilitySlot, PatientSchedule, ProviderPatient, ProviderSchedule, ScheduleAvailability } from './providerSchedule.types';

export const fetchPatientsForProvider = (providerId: string, search: string, limit: number) => {
  return httpApi.get<ProviderPatient[]>(`/providers/${providerId}/patients?search=${search}&limit=${limit}`);
};

export const fetchScheduleForProvider = (providerId: string) => {
  return httpApi.get<ProviderSchedule[]>(`/providers/${providerId}/schedule?limit=1000`);
};

export const createScheduleForProvider = ({ patientID, startTime, accountID, providerID, scheduleAvailabilitySlotID, endTime }: ICreateProviderSchedule) => {
  return httpApi.post<ProviderSchedule[]>(`/providers/${providerID}/schedule`, {
    PatientID: patientID,
    StartTime: startTime,
    AccountID: accountID,
    ProviderID: providerID,
    ProviderAvailabilitySlotID: scheduleAvailabilitySlotID,
    EndTime: endTime,
  });
};

export const deleteScheduleForProvider = (providerId: string, patientScheduleId: string) => {
  return httpApi.delete<ProviderSchedule[]>(`/providers/${providerId}/schedule/${patientScheduleId}`);
};

export const fetchScheduleAvailability = async ({
  providerId,
  month,
  year,
  startDay,
  endDate,
}: {
  providerId: string;
  year: number;
  month: number;
  startDay?: Date;
  endDate?: Date;
}) => {
  try {
    let url = `/providers/${providerId}/schedule-availabilities/by-date-range?month=${month}&year=${year}`;

    if (startDay && endDate) {
      url += `&startDay=${startDay.toISOString()}&endDate=${endDate.toISOString()}`;
    }

    const { data } = await httpApi.get<{
      scheduleAvailabilities: ScheduleAvailability[];
      totalScheduleAvailabilities: number;
      scheduleAvailabilitiesWithSlots: IProviderAvailabilitySlot[];
    }>(url);
    console.log('fetchScheduleAvailability', data);
    return data;
  } catch (e) {
    console.warn('fetchScheduleAvailability error', e);
    return {
      scheduleAvailabilities: [],
      scheduleAvailabilitiesWithSlots: [],
      totalScheduleAvailabilities: 0,
    };
  }
};

export const fetchPatientAppointments = async ({
  providerId,
  patientId,
  month,
  year,
  startDay,
  endDate,
}: {
  providerId: string;
  patientId: string;
  year: number;
  month: number;
  startDay?: Date;
  endDate?: Date;
}) => {
  try {
    let url = `/providers/${providerId}/patients/${patientId}/schedule?month=${month}&year=${year}`;
    if (startDay && endDate) {
      url += `&startDay=${startDay.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    const { data } = await httpApi.get<{
      schedules: PatientSchedule[];
      totalSchedules: number;
    }>(url);
    return data;
  } catch (e) {
    console.warn('fetchPatientAppointments error', e);
    return {
      schedules: [],
      totalSchedules: 0,
    };
  }
};
