//Message
class Message {
  show(text, time = 3000) {
    //console.log("Message Show");
    //console.log(text, time);
    //@ts-ignore
    Materialize.toast(text, time, 'rounded');
  }
}

const message = new Message();
export default message;
