import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { FC, useState, useEffect } from 'react';
import styled from 'styled-components';

import { UIModal } from '../../../components/UI';
import { ModalAction } from '../../../components/UI/Modals/Base/ActionButtons';
import Message from '../../../utils/Message';
import { Group } from './types';

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;

const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

const Groups = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  category?: Group | null;
}

type PreviewData = {
  totalPatientsInGroup: number;
};

const DeleteGroup: FC<Props> = ({ closeModal, isOpen, category }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<PreviewData>();

  useEffect(() => {
    if (category && isOpen) {
      getPreviewData();
    }
  }, [category, isOpen]);

  const deleteGroupCategory = () => {
    httpApi
      .delete(`/groups/${category?.id}`)
      .then(() => {
        Message.show('Group deleted');
        closeModal();
      })
      .catch(() => {
        Message.show('Error deleting Group');
        setIsLoading(false);
      });
  };

  const submit = () => {
    deleteGroupCategory();
  };

  const getPreviewData = () => {
    httpApi
      .get(`/groups/${category?.id}/deletePreview`)
      .then(({ data }: { data: PreviewData }) => {
        setData(data);
      })
      .catch(() => {
        Message.show('Error getting preview data for Group');
        setIsLoading(false);
      });
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Proceed',
        onClick: submit,
        disabled: isLoading,
      },
    ];

    return data;
  };

  return (
    <UIModal size="medium" isOpen={isOpen} close={closeModal} actions={getActions()}>
      <Title>WARNING!</Title>
      <Note>You are about to delete</Note>
      <Name>Group: {category?.name}</Name>
      <Note>This will affect the following </Note>
      <Groups>Clients: {data?.totalPatientsInGroup}</Groups>
    </UIModal>
  );
};

export default DeleteGroup;
