import { ReactElement, useMemo, useState } from 'react';
import Spinner from 'react-activity/dist/Spinner';
import styled from 'styled-components';
import EditIcon from '../assets/edit.svg';
import SearchImg from '../assets/search-icon.svg';
import GroupContainer from './GroupContainer';

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  background-color: ${(props: { isActive?: boolean }) => (props.isActive ? '#E2E8F3' : 'transparent')};
`;

const Content = styled.div`
  padding: 10px 20px 0px 20px;
  display: flex;
  align-items: center;
`;

const Items = styled.div`
  overflow-y: auto;
  max-height: 50vh;
`;

const Text = styled.p`
  color: #0a313f;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
`;

const EditContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SearchIcon = styled.img`
  width: 22px;
  height: 19px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const EditText = styled.div`
  color: #0092ff;
  font-size: 10px;
  margin-right: 5px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const SmallLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;

interface GroupItem {
  id: string;
  name: string;
}

interface GroupListProps<T extends GroupItem> {
  data?: T[];
  onItemClick: (item: T) => void;
  selectedGroup?: T | null;
  buttonOnClick: () => void;
  onActionClick: (item: T) => void;
  buttonLabel?: string;
  title: string;
  searchEnabled?: boolean;
  isLoading?: boolean;
  isVisible?: boolean;
  isEditable?: boolean;
  loadMore: () => void;
  hasMore: boolean;
  total?: number;
  containerStyle?: React.CSSProperties;
}

const GroupList = <T extends GroupItem>({
  data,
  buttonOnClick,
  onItemClick,
  selectedGroup,
  onActionClick,
  buttonLabel,
  title,
  isVisible = true,
  isEditable = true,
  isLoading,
  containerStyle,
  loadMore,
  hasMore,
  total,
}: GroupListProps<T>): ReactElement => {
  const [searchText, setSearchText] = useState('');

  const searchEnabled = useMemo(() => {
    if (!data) return false;
    return data?.length > 1;
  }, [data]);

  const handleSearch = e => {
    setSearchText(e.target.value);
  };

  const renderSearchInput = () => {
    if (!searchEnabled) return null;

    return (
      <Content>
        <SearchIcon src={SearchImg} width="28" alt="Search" />
        <input value={searchText} type="text" placeholder={`Search by ${title} name`} onChange={handleSearch} />
      </Content>
    );
  };

  const filteredData = useMemo(() => data?.filter(item => item.name.toLowerCase().includes(searchText?.toLowerCase())), [data, searchText]);

  const getTitle = () => {
    if (!total) {
      return title;
    }
    return `${title} (${total})`;
  };

  const renderContent = () => {
    if (isLoading && !data) {
      return (
        <LoadingContainer>
          <Spinner color="#2096F3" size={34} speed={0.6} animating={isLoading} />
        </LoadingContainer>
      );
    }

    return (
      <>
        {renderSearchInput()}

        <Items>
          {filteredData?.map(item => {
            return (
              <Item key={item.id} onClick={() => onItemClick(item)} isActive={selectedGroup?.name === item.name}>
                <Text>{item.name}</Text>
                {isEditable && (
                  <EditContainer onClick={() => onActionClick(item)}>
                    {selectedGroup?.id === item.id && (
                      <>
                        <EditText>Edit</EditText>
                        <img style={{ cursor: 'pointer' }} src={EditIcon} alt="arrow" />
                      </>
                    )}
                  </EditContainer>
                )}
              </Item>
            );
          })}
        </Items>
      </>
    );
  };

  return (
    <>
      <GroupContainer title={getTitle()} buttonLabel={isVisible ? buttonLabel : undefined} buttonOnClick={buttonOnClick} containerStyle={containerStyle}>
        {renderContent()}
      </GroupContainer>
    </>
  );
};

export default GroupList;
