import MomentUtils from '@date-io/moment';
import { debounce } from '@material-ui/core';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { AxiosError } from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';
import { UIInput, UIModal } from '../../../../components/UI';
import { UINucleusContentContainer } from '../../../../components/UI/NucleusContainer/Content';
import { useNucleusProviders } from '../../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import AuthStore from '../../../../stores/AuthStore';
import message from '../../../../utils/Message';
import { IProviderAvailability, ProviderAvailability } from '../../provider.types';
import { createProviderAvailability, getProviderStrings, updateProviderAvailability } from '../../utils/providerApi';
import { CalendarIconSVG } from '../assets/CalendarIcon';
import { ClockIconSVG } from '../assets/ClockIcon';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  background-color: #fff;
  padding-left: 10px;
`;

const ColumnLeftAligned = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Note = styled.p`
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #0a313f;
`;

const Divider = styled.div`
  border: 1px solid #b4c6d3;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const ErrorLabel = styled.div`
  color: #ff0000;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;

export const ProviderAvailabilityModal = ({
  closeModal,
  isOpen,
  formState,
  fetchAvailability,
  availabilityUpdateData,
}: {
  closeModal: () => void;
  isOpen: boolean;
  formState: 'update' | 'create';
  fetchAvailability: () => void;
  availabilityUpdateData?: undefined | IProviderAvailability;
}) => {
  const {
    state: {
      provider: { data: providerData },
    },
  } = useNucleusProviders();

  //FORM
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [availableSlots, setAvailableSlots] = useState<number>(0);
  const [minutesError, setMinutesError] = useState<string>('');
  const [maxValidMinutes, setMaxValidMinutes] = useState<number>(0);
  const [slotDuration, setSlotDuration] = useState<number>(5);
  const [scheduleDay, setScheduleDay] = useState<Date>(new Date());
  const [startTime, setStartTime] = useState<Date>(moment().add(5, 'minutes').toDate());
  const [endTime, setEndTime] = useState<Date>(moment().add(10, 'minutes').toDate());

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [disableForm, setDisableForm] = useState<boolean>(false);

  const [providerUsersRoleName, setProviderUsersRoleName] = useState<string>('');
  const providerId = AuthStore.getProviderID()!;
  const checkIfInPast = date => {
    const isPastDate = moment(date).isBefore(moment(), 'minute');
    if (isPastDate) {
      return true;
    }
    return false;
  };
  const checkIfDayIsInPast = date => {
    const isPastDate = moment(date).isBefore(moment(), 'day');
    if (isPastDate) {
      return true;
    }
    return false;
  };
  const addProviderAvailability = debounce(async () => {
    setIsLoading(true);
    if (formState === 'update' && availabilityUpdateData) {
      try {
        await updateProviderAvailability(providerId, availabilityUpdateData.id, {
          availableSlots: Number(availableSlots),
          slotDuration: Number(slotDuration),
          startTime: moment(startTime).toDate(),
          endTime: moment(endTime).toDate(),
        });
        fetchAvailability();
        message.show('Successfully updated provider availability');
        onCloseModal();
      } catch (e) {
        const errorMsg = (e as AxiosError).response?.data?.message || 'Failed to update provider availability';
        message.show(errorMsg);
      }

      setIsLoading(false);
      return;
    }
    const dayInPast = checkIfDayIsInPast(scheduleDay);
    if (dayInPast) {
      setErrorMessage('Day cannot be in the past');
      return;
    }
    if (errorMessage) return;
    setErrorMessage(null);
    if (!availableSlots || !slotDuration || !endTime || !startTime) {
      setErrorMessage('Please fill in all fields');
      setIsLoading(false);
      return;
    }
    const startTimeFormatted = moment(startTime).toDate();
    const endTimeFormatted = moment(endTime).toDate();

    const formData: ProviderAvailability = {
      availableSlots: Number(availableSlots),
      slotDuration: Number(slotDuration),
      startTime: startTimeFormatted,
      endTime: endTimeFormatted,
    };
    const response = await createProviderAvailability(providerId, formData);
    if (response?.id) {
      fetchAvailability();
      message.show('Successfully created provider availability');
      onCloseModal();
    } else {
      message.show('Failed to create provider availability');
    }
    setIsLoading(false);
  }, 500);

  const onCloseModal = () => {
    setAvailableSlots(0);
    setSlotDuration(0);
    setScheduleDay(new Date());
    setStartTime(moment().add(5, 'minutes').toDate());
    setEndTime(moment().add(10, 'minutes').toDate());
    closeModal();
  };

  useEffect(() => {
    if (formState === 'update') {
      if (availabilityUpdateData) {
        setAvailableSlots(availabilityUpdateData.availableSlots);
        setSlotDuration(availabilityUpdateData.slotDuration);
        setScheduleDay(moment(availabilityUpdateData.startTime).toDate());
        setStartTime(moment(availabilityUpdateData.startTime).toDate());
        setEndTime(moment(availabilityUpdateData.endTime).toDate());
        return;
      } else {
        message.show('Failed to load provider availability data');
        onCloseModal();
      }
    } else {
      setAvailableSlots(0);
      setSlotDuration(0);
      setScheduleDay(new Date());
      setStartTime(moment().add(5, 'minutes').toDate());
      setEndTime(moment().add(10, 'minutes').toDate());
    }
    return () => {
      setAvailableSlots(0);
      setSlotDuration(0);
      setScheduleDay(new Date());
      setStartTime(moment().add(5, 'minutes').toDate());
      setEndTime(moment().add(10, 'minutes').toDate());
    };
  }, [formState, availabilityUpdateData]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (formState === 'update') return;
      const checkDayInPast = checkIfDayIsInPast(scheduleDay);
      const isToday = moment(scheduleDay).isSame(moment(), 'day');
      if (!isToday) {
        if (checkDayInPast) {
          setScheduleDay(new Date());
          setStartTime(moment().add(5, 'minutes').toDate());
          setEndTime(moment().add(10, 'minutes').toDate());
          return;
        }
      } else {
        const ifInPast = checkIfInPast(startTime);
        const ifEndTimeInPast = checkIfInPast(endTime);
        if (ifInPast) {
          setStartTime(moment().add(5, 'minutes').toDate());
          const endTimeLess10Minutes = endTime.setMinutes(endTime.getMinutes() - 5);
          if (checkIfInPast(endTimeLess10Minutes)) {
            setEndTime(moment().add(10, 'minutes').toDate());
          }
        }
        if (ifEndTimeInPast) {
          setEndTime(moment().add(10, 'minutes').toDate());
        }
      }
    }, 1000 * 20);
    return () => clearInterval(interval);
  }, [scheduleDay, startTime, endTime]);

  useEffect(() => {
    const isDayInPast = checkIfDayIsInPast(scheduleDay);
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    const minutes = duration.asMinutes();
    setMaxValidMinutes(Math.floor(minutes));
    if (minutes < 5) {
      setErrorMessage('Please select a valid start time and end time (minimum range between start and end time is 5 minutes)');
      setDisableForm(true);
      return;
    } else {
      setErrorMessage(null);
      setDisableForm(false);
    }
    if (availableSlots < 1) {
      setDisableForm(true);
      return;
    }
    if (slotDuration < 5) {
      setDisableForm(true);
    }
    if (isDayInPast) {
      setErrorMessage('Day cannot be in the past');
      setDisableForm(true);
      return;
    }
    const isStartTimeInPast = checkIfInPast(startTime);
    if (isStartTimeInPast) {
      setErrorMessage('Start time cannot be in the past');
      setDisableForm(true);
      return;
    }
    const isEndTimeBeforeStartTime = moment(endTime).isBefore(moment(startTime), 'minute');
    if (isEndTimeBeforeStartTime) {
      setErrorMessage('End time cannot be before start time');
      setDisableForm(true);
      return;
    }
    setErrorMessage(null);
    setDisableForm(false);
    // calculate minutes between start time and end time
  }, [availableSlots, slotDuration, startTime, endTime, scheduleDay]);
  const onScheduleDayChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    if (date) {
      setScheduleDay(date?.toDate());
      const newStartTime = moment(startTime)
        .set({
          year: scheduleDay.getFullYear(),
          month: scheduleDay.getMonth(),
          date: scheduleDay.getDate(),
        })
        .toDate();
      const newEndTime = moment(endTime)
        .set({
          year: scheduleDay.getFullYear(),
          month: scheduleDay.getMonth(),
          date: scheduleDay.getDate(),
        })
        .toDate();
      setStartTime(newStartTime);
      setEndTime(newEndTime);
    }
  };
  const onStartTimeChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    const isPast = checkIfInPast(date);
    if (isPast) {
      setErrorMessage('Start time cannot be in the past');
      return;
    }
    if (date) {
      setStartTime(date?.toDate());
    }
  };
  const onEndTimeChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    if (date) {
      setEndTime(date?.toDate());
    }
  };
  useEffect(() => {
    if (!scheduleDay) return;
    if (scheduleDay.getDate() === startTime.getDate() && scheduleDay.getDate() === endTime.getDate()) return;
    const newStartTime = moment(startTime)
      .set({
        year: scheduleDay.getFullYear(),
        month: scheduleDay.getMonth(),
        date: scheduleDay.getDate(),
      })
      .toDate();
    const newEndTime = moment(endTime)
      .set({
        year: scheduleDay.getFullYear(),
        month: scheduleDay.getMonth(),
        date: scheduleDay.getDate(),
      })
      .toDate();
    setStartTime(newStartTime);
    setEndTime(newEndTime);
  }, [scheduleDay]);
  const dateFormat = 'MM/DD/YYYY';
  const timeFormat = 'hh:mm A';

  useEffect(() => {
    async function fetchProviderStrings() {
      const { UserRoleNamePlural } = await getProviderStrings(providerId);
      setProviderUsersRoleName(UserRoleNamePlural);
    }

    fetchProviderStrings();
  }, [providerData]);

  if (!isOpen) return null;

  return (
    <UIModal
      close={closeModal}
      isOpen={isOpen}
      size="mediumLarge"
      title={`${formState === 'create' ? 'Set' : 'Edit'} Provider Availability`}
      actions={[
        {
          label: 'Cancel',
          onClick: () => onCloseModal(),
          disabled: false,
          buttonVariant: 'secondary',
        },
        {
          label: { create: 'Set Provider Availability', update: 'Update Provider Availability' }[formState],
          onClick: () => addProviderAvailability(),
          disabled: !!errorMessage || !!minutesError || disableForm || isLoading,
          buttonVariant: 'primary',
        },
      ]}
    >
      <ColumnLeftAligned>
        <Note>Day</Note>
        <InputContainer>
          <CalendarIconSVG />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              minDate={new Date()}
              minDateMessage="Date cannot be in the past"
              variant="dialog"
              className="browser-default ui-nucleus"
              value={scheduleDay}
              onChange={onScheduleDayChange}
              onError={console.log}
              disablePast
              onClose={() => {}}
              style={styles.dateTimePickerInput}
              format={dateFormat}
              name="day"
            />
          </MuiPickersUtilsProvider>
        </InputContainer>
      </ColumnLeftAligned>

      <Divider />
      <Row
        style={{
          justifyContent: 'space-between',
        }}
      >
        <ColumnLeftAligned>
          <Note>Start Time</Note>
          <InputContainer>
            <ClockIconSVG />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <TimePicker
                variant="dialog"
                className="browser-default ui-nucleus"
                style={styles.dateTimePickerInput}
                value={startTime}
                onChange={onStartTimeChange}
                onError={console.log}
                onClose={() => {}}
                format={timeFormat}
              />
            </MuiPickersUtilsProvider>
          </InputContainer>
        </ColumnLeftAligned>
        <ColumnLeftAligned>
          <Note style={{}}>End Time</Note>
          <InputContainer>
            <ClockIconSVG />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <TimePicker
                variant="dialog"
                className="browser-default ui-nucleus"
                style={styles.dateTimePickerInput}
                value={endTime}
                onChange={onEndTimeChange}
                onError={console.log}
                onClose={() => {}}
                format={timeFormat}
              />
            </MuiPickersUtilsProvider>
          </InputContainer>
        </ColumnLeftAligned>
      </Row>
      <Divider />
      <Row style={{}}>
        <ColumnLeftAligned>
          <Note style={{ marginBottom: 0 }}>Length of time slots</Note>
          <Row style={{ marginTop: 0 }}>
            <UIInput
              label=""
              style={{
                borderWidth: 0,
                width: 56,
                textAlign: 'center',
              }}
              placeholder="1"
              max={maxValidMinutes}
              minLength={1}
              required
              value={slotDuration}
              onChange={e => {
                const value: number = Number(e.target.value);
                const regex = /^[0-9\b]+$/;
                if (!regex.test(value.toString())) return;
                if (!value || !regex.test(value.toString())) {
                  setMinutesError('Please enter a valid number');
                  setDisableForm(true);
                  setSlotDuration(value);
                  return;
                }
                if (maxValidMinutes <= 1) {
                  setMinutesError('Please select a valid start time and end time');
                  setDisableForm(true);
                  setSlotDuration(value);
                  return;
                }
                if (Number(value) < 5) {
                  setMinutesError('Minimum slot duration is 5 minutes');
                  setDisableForm(true);
                  setSlotDuration(value);
                  return;
                }
                if (Number(value) > maxValidMinutes) {
                  setMinutesError(`The selected value exceeds the maximum duration of ${maxValidMinutes} minutes, which is the range between the start and end time.`);
                  setDisableForm(true);
                  setSlotDuration(value);
                  return;
                }
                setMinutesError('');
                setDisableForm(false);
                setSlotDuration(value);
              }}
            />
            <Note style={{ marginLeft: 20 }}>Minutes</Note>
          </Row>
          <ErrorLabel>{!!minutesError && minutesError}</ErrorLabel>
        </ColumnLeftAligned>
      </Row>
      <Row style={{}}>
        <ColumnLeftAligned>
          <Note style={{ marginBottom: 0 }}>Number of {providerUsersRoleName || 'provider users'}</Note>
          <Row style={{ marginTop: 0 }}>
            <UIInput
              label=""
              style={{
                borderWidth: 0,
                width: 56,
                textAlign: 'center',
              }}
              minLength={1}
              placeholder="1"
              required
              value={availableSlots}
              min={0}
              onChange={e => {
                const value: number = Number(e.target.value);
                const regex = /^[0-9\b]+$/;
                if (!regex.test(value.toString())) return;
                if (availableSlots < 1) {
                  setErrorMessage(`The minimum number of ${providerUsersRoleName || 'provider users'} required is 1.`);
                  setDisableForm(true);
                }
                setAvailableSlots(value);
              }}
            />
            <Note style={{ marginLeft: 20 }}>{providerUsersRoleName}</Note>
          </Row>
        </ColumnLeftAligned>
      </Row>
      <ErrorLabel>{!!errorMessage && errorMessage}</ErrorLabel>
      <UINucleusContentContainer centerContent>
        {isLoading && <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />}
      </UINucleusContentContainer>
    </UIModal>
  );
};

const styles = {
  dateTimePickerInput: {
    backgroundColor: '#FFF',
    padding: 10,
  },
};
