import { FC } from 'react';
import styled from 'styled-components';
import { Patient } from '../../../components/PatientsSelector/usePatientsSelector';

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  max-height: 205px;
  overflow-y: auto;
  border-radius: 6px;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 27px;
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
  margin-right: 10px;
`;

const Name = styled.p`
  font-size: 16px;
  color: #0a313f;
  margin: 0;
`;

type Props = {
  data: Patient[];
  total: number;
};

const ExistingClientsList: FC<Props> = ({ data, total }) => {
  if (!data?.length) {
    return null;
  }

  return (
    <Container>
      <Title>Existing clients ({total})</Title>
      {data?.map(user => {
        return (
          <Item key={user.id}>
            <Left>
              <Avatar src={user.thumbnailUrl || '/img/user_placeholder.png'} alt={user.firstName} />
              <Name>
                {user.firstName} {user.lastName}
              </Name>
            </Left>
          </Item>
        );
      })}
    </Container>
  );
};

export default ExistingClientsList;
