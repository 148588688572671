import React from 'react';

import { Link } from 'react-router-dom';
import CaregiverStore from '../../stores/CaregiverStore';

import CaregiverMessageItemRow from './CaregiverMessageItemRow';

class NewMessage extends React.Component {
  constructor() {
    super();

    this.state = {
      panel: 'Form',
      messageToSend: CaregiverStore.getSMSMessage(),
      btnStyle: ' nucleus-submit-btn',
      messageList: CaregiverStore.getSMSListData(),
    };

    this.submitCreateUserForm = this.submitCreateUserForm.bind(this);
    this.onCreateUserAction = this.onCreateUserAction.bind(this);
  }

  componentDidMount() {
    //CaregiverStore.on("onCreateUserAction", this.onCreateUserAction);
    console.log('SMS List');
    console.log(this.state.messageList);
  }

  componentWillUnmount() {
    //CaregiverStore.removeListener("onCreateUserAction", this.onCreateUserAction);
  }

  submitCreateUserForm() {
    /*
        this.setState({
            btnStyle : " nucleus-submit-btn-disabled"
        });
        //Get the User Data from WS
        CareUserStore.CreateNewUser({
            AccountID : AuthStore.getUserAccountID(),
            FirstName : this.refs.txtNewUserFirstName.value,
            LastName : this.refs.txtNewUserLastName.value,
            Email : this.refs.txtNewUserEmail.value
        });
        */
  }

  onCreateUserAction(response) {
    /*if (response.ok){
            this.setState({
                panel : "Message",
                newUserEmail : this.refs.txtNewUserEmail.value
            });
        } else {
            if (response.ExistingUser){
                Message.show("Email address already exist in the system.");
            } else{
                Message.show("Error creating new User");
            }
            
        }
        this.setState({
            btnStyle : " nucleus-submit-btn"
        }); */
  }

  render() {
    let sendMessageItems = [];
    if (this.state.messageList != null) {
      this.state.messageList.map((item, key) => {
        sendMessageItems.push(
          <CaregiverMessageItemRow key={key} CaregiverID={item.ID} CaregiverName={item.Name} CaregiverTelephone={item.Telephone} Message={this.state.messageToSend} />,
        );
      });
    }

    return (
      <div class="nucleus-page altoImportantContainer">
        <p class="nucleus-page-title">New Message</p>
        <br />

        <div id="nucleus-new-user-confirmation-pending">
          <div class="row">
            <div class="col s12">
              <span class="nucleus-link nucleus-font-medium">Message:</span>
              <p class=" nucleus-labels"> {this.state.messageToSend}</p>
            </div>
          </div>

          <br />
          <br />
          <div class="row">
            <div class="col s12">
              <span class="nucleus-link nucleus-font-medium">Details:</span>
              <br />
              <div class="">{sendMessageItems}</div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <div class="row">
            <div class="col s12 center-align">
              <Link to="/users">
                <span class={'txt-white nucleus-font-small ' + this.state.btnStyle}>Done</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewMessage;
