export enum ProviderScheduleStatus {
  Canceled = 'Canceled',
  Declined = 'Declined',
  NotAnswered = 'NotAnswered',
  Failed = 'Failed',
  Done = 'Done',
  Calling = 'Calling',
  Disconnected = 'Disconnected',
}
export type ProviderSchedule = {
  AccountName: string;
  Status: ProviderScheduleStatus;
  ID: string;
  PatientID: string;
  FirstName: string;
  LastName?: string;
  PatientName: string;
  DeviceID: string;
  DeviceName: string;
  AccountID: string;
  ProviderID: string;
  StatusByID: string;
  StatusByUser: string;
  StartTime: Date;
  CallStartedAt: Date;
  CallEndedAt: Date;
  EndTime: Date;
  CreatedAt: Date;
  CreatedBy: string;
};

export interface ICreateProviderSchedule {
  patientID: string;
  accountID: string;
  providerID: string;
  scheduleAvailabilitySlotID: string;
  startTime: Date;
  endTime: Date;
}

export interface ProviderPatient {
  PatientID: string;
  AccountID: string;
  AccountName?: string;
  FirstName: string;
  LastName: string;
  PatientName: string;
}

export type PatientSchedule = {
  ID: string;
  PatientID: string;
  PatientName: string;
  ProviderID: string;
  AccountID: string;
  DeviceID: string;
  DeviceName: string;
  Status: string;
  StatusByID: string;
  StatusByUser: string;
  StartTime: Date;
  EndTime: Date;
  CallStartedAt: Date;
  CallEndedAt: Date;
};

export type ScheduleAvailability = {
  id: string;
  availableSlots: number;
  slotDuration: number;
  startTime: Date;
  endTime: Date;
};

export interface IProviderAvailabilitySlot {
  ProviderID: string;
  SlotDuration: number;
  StartTime: Date;
  EndTime: Date;
  ScheduledAppoiments: number;
  Full: boolean;
  ProviderAvailabilitySlotID: string;
}
