
import NucleusSearchInput from 'components/NucleusSearchInput';
import NucleusTable from 'components/NucleusTable';
import { UIModal, useModal } from 'components/UI';

import { UIAvatar } from 'components/UI/Avatar';
import { UICheckbox } from 'components/UI/Checkbox';
import { ModalAction } from 'components/UI/Modals/Base/ActionButtons';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import authStore from 'stores/AuthStore';
import CareUserStore from 'stores/CareUserStore';
import Message from 'utils/Message';
import { AssignedPatient } from './UserAssignedPatients/UserAssignedPatients';
import { fetchAvailablePatients } from './UserAssignedPatients/assignmentActions';

export interface ISelectedAssignatedPatients {
  PatientID: string;
  Assigned: boolean;
}

interface Props {
  modalOpen: boolean;
  onCloseModal: () => void;
  onSavePatientsAssignationData: (selectedPatientsList: ISelectedAssignatedPatients[]) => void;
}

const NewUserAssignedPatients: FC<Props> = ({ modalOpen, onSavePatientsAssignationData, onCloseModal }) => {
  const { isOpen: isAssignationsModalOpen, openModal: openAssignationsModal, closeModal: closeAssignationsModal } = useModal();

  const [searchText, setSearchText] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [userPatientsList, setUserPatientsList] = useState<AssignedPatient[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<ISelectedAssignatedPatients[]>([]);
  const params = useParams<{
    userId: string;
  }>();

  useEffect(() => {
    if (modalOpen) {
      openAssignationsModal();
    } else {
      closeAssignationsModal();
    }
    return () => {};
  }, [modalOpen]);

  useEffect(() => {
    fetchPatients();
  }, []);

  const fetchPatients = useCallback(() => {
    if (authStore.getUserID()) {
      fetchAvailablePatients({
        limit: 1000,
        page: 1,
        userId: authStore.getUserID(),
        cb: ({ response, success }) => {
          onGetPossiblePatientsAction(response, success);
        },
      });
    }
  }, [params?.userId, CareUserStore.getUserAllPatientsAccess()]);

  const onGetPossiblePatientsAction = (response, success) => {
    if (response && success) {
      if (response?.patients) {
        const newUserPatientsList = response.patients.map(patient => ({
          ...patient,
          assignedToUser: false,
        }));
        setUserPatientsList(newUserPatientsList);
      }
    } else {
      Message.show('Error getting available ' + authStore.getPatientLabelPlural().toLowerCase() + ' information');
    }
  };

  useEffect(() => {
    setSelectedPatients(userPatientsList.filter(p => p.assignedToUser).map(p => ({ PatientID: p.id, Assigned: p.assignedToUser })));
    () => {
      setSelectedPatients([]);
    };
  }, [userPatientsList]);

  const onPatientSelected = (patient: AssignedPatient) => {
    const patientId = patient.id;
    const selected = selectedPatients.find(p => p.PatientID == patientId);
    if (selected) {
      const index = selectedPatients.indexOf(selected);
      const newSelectedPatients = [...selectedPatients];
      newSelectedPatients[index].Assigned = !newSelectedPatients[index].Assigned;
      setSelectedPatients(newSelectedPatients);
      return false;
    } else {
      setSelectedPatients(prevState => [...prevState, { PatientID: patientId, Assigned: true }]);
      return true;
    }
  };

  const savePatientsAssignmentData = useCallback(() => {
    onSavePatientsAssignationData(selectedPatients);
  }, [selectedPatients]);

  const handleCloseModal = () => {
    closeAssignationsModal();
    onCloseModal();
  };

  const submit = () => {
    savePatientsAssignmentData();
    closeAssignationsModal();
    onCloseModal();
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: handleCloseModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Save',
        onClick: submit,
        disabled: isLoading,
      },
    ];
    return data;
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Name',
        accessor: 'FirstName',
        Cell: ({ row }) => {
          return (
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <UIAvatar size="x-small" src={row.original?.Avatar} alt="avatar" />
              <span style={{ marginLeft: 5 }}>
                {row.original.firstName} {row.original.lastName}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Group',
        accessor: 'LastName',
      },
      {
        Header: () => (
					<span style={{display:'inline-flex'}}>
          <span style={{ display: 'flex', justifyContent: 'space-evenly', marginLeft:-10 }}>
            <label style={{ fontSize: 14, color: '#000', fontWeight: 'bold', paddingRight:10}}>{'Select all'}</label>
            <UICheckbox
              checked={selectedPatients.length === userPatientsList.length && selectedPatients.filter(p => p.Assigned === true).length === userPatientsList.length}
              onClick={() => {
                if (selectedPatients.length === userPatientsList.length && selectedPatients.filter(p => p.Assigned === true).length === userPatientsList.length) {
                  const newSelectedList = userPatientsList.map(p => {
                    return { PatientID: p.id, Assigned: false };
                  });
                  setSelectedPatients(newSelectedList);
                } else {
                  const patients = userPatientsList.map(patient => {
                    return { PatientID: patient.id, Assigned: true };
                  });
                  setSelectedPatients(patients);
                }
              }}
            />
          </span>
					</span>
        ),
        accessor: 'PatientID',
        showSort: false,
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>
                <UICheckbox checked={selectedPatients.find(p => p.PatientID == row.original.id)?.Assigned || false} onClick={() => onPatientSelected(row.original)} />
              </span>
            </div>
          );
        },
      },
    ];
    return cols;
  }, [selectedPatients, userPatientsList]);

  return (
    <>
      <UIModal size="large" isOpen={isAssignationsModalOpen} close={handleCloseModal} title={authStore.getPatientLabelPlural() + ' assignments'} actions={getActions()}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
            <NucleusSearchInput
              onChange={e => {
                setSearchText(e.target.value);
              }}
              value={searchText}
              inputContainerWidth={'100%'}
              placeholder={`Filter by ${authStore.getPatientLabel()} Name`}
            />
          </div>
          <div
            style={{
              maxHeight: '60vh',
              overflowY: 'scroll',
            }}
          >
            <NucleusTable
              stickyThead
              data={userPatientsList.filter(p => p.firstName?.toLowerCase()?.includes(searchText?.toLowerCase()) || p.lastName?.toLowerCase()?.includes(searchText?.toLowerCase()))}
              columns={columns}
            />
          </div>
        </div>
      </UIModal>
    </>
  );
};
export default NewUserAssignedPatients;
