import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useState } from 'react';
import { Patient } from '../../../components/PatientsSelector/usePatientsSelector';
import Message from '../../../utils/Message';
import { Group } from './types';

const LIMIT = 20;

const useGroups = () => {
  const [page, setPage] = useState(1);
  const [hasMoreGroups, setHasMoreGroups] = useState(true);
  const [groups, setGroups] = useState<Group[]>([]);
  const [totalGroups, setTotalGroups] = useState<Group[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [patientsPerGroup, setPatientsPerGroup] = useState<Patient[]>([]);
  const [totalPatients, setTotalPatients] = useState(0);

  const getGroupsByCategoryId = (categoryId: string) => {
    console.log('getGroupsByCategoryId');
    setGroups([]);
    if (isLoading || !hasMoreGroups) return false;
    setIsLoading(true);
    httpApi
      .get(`/groupCategories/${categoryId}/groups?limit=${LIMIT}&page=${page}`)
      .then(({ data }) => {
        console.log('getGroupsByCategoryId then', data);
        if (data) {
          setIsLoading(false);
          setTotalGroups(data.totalGroups);
          setGroups(prevGroups => [...prevGroups, ...data.groups]);
          // setPage((prevPage) => prevPage + 1);
          // if (data?.groups?.length === 0) {
          //   setHasMoreGroups(false);
          // }
        }
      })
      .catch(() => {
        setIsLoading(false);
        setGroups([]);
        Message.show('Sorry, There was a problem getting groups. Please try again.');
      });
  };

  const loadMoreGroups = (categoryId: string) => {
    console.log('loadMoreGroups');
    if (hasMoreGroups && !isLoading) {
      getGroupsByCategoryId(categoryId);
    }
  };

  const getPatientsByGroupId = (categoryId: string) => {
    setPatientsPerGroup([]);
    setTotalPatients(0);
    httpApi
      .get(`/groups/${categoryId}/patients?limit=10&page=1`)
      .then(({ data }) => {
        setIsLoading(false);
        if (data?.patients) {
          setPatientsPerGroup(data.patients);
          setTotalPatients(data?.totalPatients);
        } else {
          setPatientsPerGroup([]);
          setTotalPatients(0);
        }
      })
      .catch(() => {
        setTotalPatients(0);
        setPatientsPerGroup([]);
        Message.show('Sorry, There was a problem getting patients. Please try again.');
      });
  };

  const removePatientFromGroup = (patientIds: string[], groupId: string) => {
    return httpApi.delete(`/groups/${groupId}/patients`, {
      data: { patientIds },
    });
  };

  return {
    groups,
    getGroupsByCategoryId,
    isLoading,
    getPatientsByGroupId,
    patientsPerGroup,
    totalPatients,
    removePatientFromGroup,
    loadMoreGroups,
    hasMoreGroups,
    totalGroups,
  };
};

export default useGroups;
