import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useSortBy, useTable } from 'react-table';
import { SortArrowDown, SortArrowUp, SortNeutral } from './assets/NucleusTableSVGs';

const NucleusTable = ({
  tableStyles,
  tbodyStyles,
  stickyThead,
  columns,
  data,
  checkForDisabled = false, // if true, will check for a disabled property on the row and render it as disabled
  onRowClick,
  handleSortChange = () => {},
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable({ columns, data }, useSortBy);
  const renderSortIcon = useCallback(
    column => {
      if (column.isSorted) {
        if (column.isSortedDesc) {
          return <SortArrowDown />;
        } else {
          return <SortArrowUp />;
        }
      } else {
        return <SortNeutral />;
      }
    },
    [headerGroups],
  );
  useEffect(() => {
    if (sortBy.length) {
      handleSortChange && handleSortChange(sortBy[0]);
    } else {
      handleSortChange && handleSortChange(undefined);
    }
  }, [sortBy]);
  return (
    <table {...getTableProps()} className="b-table b-table-colored b-table-hover b-table-active " style={tableStyles}>
      <thead className={stickyThead ? 'nucleus-table-header sticky-thead' : 'nucleus-table-header'}>
        {headerGroups.map(headerGroup => {
          const { key, ...headerGroupPropsWithoutKey } = headerGroup.getHeaderGroupProps();
          return (
            <tr {...headerGroupPropsWithoutKey} key={key}>
              {headerGroup.headers.map(column => {
                const { key, ...columnProps } = column.getHeaderProps(column.getSortByToggleProps());
                return (
                  column.show !== false && (
                    <th {...columnProps} key={key || column.Header} className={column.className}>
                      <span className="nucleus-table-header">
                        {column.render('Header')}
                        {column.canSort && renderSortIcon(column)}
                      </span>
                    </th>
                  )
                );
              })}
            </tr>
          );
        })}
      </thead>
      <tbody style={tbodyStyles} {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          const { key: rowKey, ...rowProps } = row.getRowProps();
          return (
            <tr
              {...rowProps}
              key={rowKey}
              onClick={() => onRowClick?.({ title: 'details', row })}
              style={{
                color: checkForDisabled && row.original.disabled ? '#888' : undefined,
                cursor: onRowClick ? 'pointer' : 'default',
              }}
            >
              {row.cells.map(cell => {
                const { key, ...cellProps } = cell.getCellProps();
                return (
                  <td {...cellProps} key={key} className={cell.column.className}>
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
NucleusTable.propTypes = {
  tableStyles: PropTypes.object,
  tbodyStyles: PropTypes.object,
  stickyThead: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  checkForDisabled: PropTypes.bool,
  onRowClick: PropTypes.func,
};
export default NucleusTable;
