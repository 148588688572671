import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import authStore from '../../stores/AuthStore';
import Message from '../../utils/Message';

import { UIModal, UIInput, useModal } from '../UI';
import { ModalAction } from '../UI/Modals/Base/ActionButtons';
import DeleteGroupCategory from './DeleteGroupCategory';
import { GroupCategory, TierTitles } from './types';

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

interface Props {
  closeModal: (fetchAgain: boolean) => void;
  isOpen: boolean;
  category?: GroupCategory | null;
  tierTitles?: TierTitles;
  deletedSuccessCallback?: () => void;
}

const AddEditProviderGroupCompany: FC<Props> = ({ closeModal, isOpen, category, tierTitles, deletedSuccessCallback }) => {
  const TITLES = useMemo(() => {
    return {
      add: `Add ${tierTitles?.Tier1 || 'Company'}`,
      edit: `Edit ${tierTitles?.Tier1 || 'Company'}`,
    };
  }, [tierTitles]);
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [saveCategoryError, setSaveCategoryError] = useState('');
  const [mode, setMode] = useState('add');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isDeleteOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  useEffect(() => {
    if (titleError) {
      setTitleError('');
    }
  }, [title]);

  useEffect(() => {
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    setTitle(category?.name || '');
    if (category) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [category]);

  const saveGroup = () => {
    httpApi
      .post(`/providers/${authStore.getProviderID()}/groups`, {
        name: title,
        level: 1,
      })
      .then(({ data }) => {
        setIsLoading(false);
        if (data.id) {
          Message.show('New Company created');
          handleCloseModal();
        } else {
          Message.show('Error creating new Company');
        }
      })
      .catch(e => {
        if (e.response) {
          setSaveCategoryError(e.response.data?.message);
        } else {
          Message.show('Error creating new Company');
        }
        setIsLoading(false);
      });
  };

  const editGroup = () => {
    httpApi
      .put(`/providers/${authStore.getProviderID()}/groups/${category?.id}`, {
        name: title.trim(),
      })
      .then(() => {
        setIsLoading(false);
        Message.show('Company edited');
        handleCloseModal();
      })
      .catch(e => {
        setSaveCategoryError(e.response.data?.message);
        setIsLoading(false);
      });
  };

  const validate = () => {
    if (!title || title.trim() === '') {
      setTitleError('Name is required');
      return false;
    }
    return true;
  };

  const submit = () => {
    if (!validate()) return;

    if (mode === 'add') {
      saveGroup();
    } else {
      editGroup();
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const title = e.target.value.trim();
    setTitle(title);
  };

  const handleOpenDeleteModal = () => {
    handleCloseModal(false);
    openDeleteModal();
  };

  const handleCloseModal = (fetchAgain = true) => {
    closeModal(fetchAgain);
    setTitle('');
    setSaveCategoryError('');
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: handleCloseModal,
        buttonVariant: 'secondary',
      },
    ];

    if (mode === 'edit') {
      data.push(
        {
          label: `Delete ${tierTitles?.Tier1}`,
          onClick: handleOpenDeleteModal,
          buttonVariant: 'danger',
        },
        {
          label: mode === 'edit' ? 'Save' : 'Add',
          onClick: submit,
          disabled: isLoading,
        },
      );
    } else {
      data.push({
        label: mode === 'edit' ? 'Save' : 'Add',
        onClick: submit,
        disabled: isLoading,
      });
    }

    return data;
  };

  const onCloseDeleteModal = () => {
    handleCloseModal();
    closeDeleteModal();
  };

  return (
    <>
      <UIModal size="medium" isOpen={isOpen} close={handleCloseModal} title={TITLES[mode]} actions={getActions()}>
        <InputGroup>
          <UIInput label="Name:" placeholder="Name" value={title} onChange={handleTitleChange} error={titleError || saveCategoryError} />
        </InputGroup>
      </UIModal>
      <DeleteGroupCategory deletedSuccessCallback={deletedSuccessCallback} tierTitles={tierTitles} isOpen={isDeleteOpen} closeModal={onCloseDeleteModal} category={category} />
    </>
  );
};

export default AddEditProviderGroupCompany;
