//@ts-nocheck
import {
  getUserConfig,
  getLoggedInUserConfig,
  clearUserConfig,
  enableClassSessionAccessToUser,
  enableSalesforceImportToUser,
  enableReportsToUser,
  updateUserConfigPromise,
} from '@nucleus-care/nucleuscare-backend-client';
import classNames from 'classnames';
import {
  UINucleusColumnsContainer,
  UINucleusColumnContent,
  UICheckboxLine 
} from 'components/UI';
import TelephoneInputUnderline from 'components/UI/Inputs/TelephoneInputUnderlined';
import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { useClassSessionPluralLabelSelector, useClassSessionAccessSelector } from '../../selectors/accountConfig';
import AuthStore from '../../stores/AuthStore';
import CareUserStore from '../../stores/CareUserStore';
import Message from '../../utils/Message';
import { AccessCheckbox } from './AccessCheckbox';
import { useConfigAccessSelector } from './selectors/userAccessConfig';
import styled from 'styled-components';

export function BackendClientFetcher({ render, userId }) {
  const dispatch = useDispatch();
  const label = useClassSessionPluralLabelSelector();

  useEffect(() => {
    if (userId) {
      dispatch(getUserConfig(userId));
    }
  }, [userId]);

  return render(dispatch, label);
}

function ClassSessionAccessCheckbox(props) {
  const dispatch = useDispatch();
  const { allow, success, error } = useConfigAccessSelector('allowClassSession');
  const hasAccess = useClassSessionAccessSelector();
  const label = useClassSessionPluralLabelSelector();
  useEffect(() => {
    if (success) {
      const responseResult = allow ? 'Enabled' : 'Disabled';
      Message.show(`Access to ${label} Screen ${responseResult}`);
    }
    return () => {
      dispatch(clearUserConfig());
    };
  }, [success, allow]);
  useEffect(() => {
    if (error) {
      Message.show(`Error configuring Access to ${label} Screen`);
    }
  }, [error]);
  if (!hasAccess) return null;
  return <AccessCheckbox checked={allow} {...props} />;
}

export function SalesforceImportAccessCheckbox(props) {
  const dispatch = useDispatch();
  const { allow, success, error } = useConfigAccessSelector('allowSalesforceImport');
  useEffect(() => {
    if (success) {
      const responseResult = allow ? 'Enabled' : 'Disabled';
      Message.show(`Import Salesforce Users ${responseResult}`);
    }
    return () => {
      dispatch(clearUserConfig());
    };
  }, [success, allow]);
  useEffect(() => {
    if (error) {
      Message.show('Error enabling salesforce import permission');
    }
  }, [error]);
  return <AccessCheckbox checked={allow} {...props} />;
}

function ReportsAccessCheckbox(props) {
  const dispatch = useDispatch();
  const { allow, success, error } = useConfigAccessSelector('allowReports');
  useEffect(() => {
    if (success) {
      const responseResult = allow ? 'Enabled' : 'Disabled';
      Message.show(`Reports tab ${responseResult}`);
    }
    return () => {
      dispatch(clearUserConfig());
    };
  }, [success, allow]);
  useEffect(() => {
    if (error) {
      Message.show('Error enabling reports tab');
    }
  }, [error]);
  return <AccessCheckbox checked={allow} {...props} />;
}
class UserProfileEdit extends React.Component<
  {
    userId: string;
    userFirstName: string;
    userLastName: string;
    userImageUrl: string;
    userTelephone: string;
    userAddress?: string;
    userIsAdmin: boolean;
    userAllPatientsAccess: boolean;
    userMessageAllPatients: boolean;
    userCanReceiveEscalation: boolean;
    userIsOnDuty: boolean;
    currentMode: string;
    onRefresh: (userId: string) => void;
    accountEscalationEmail: string;
    adminAccess: boolean;
    staffAccess: boolean;
    clientAccess: boolean;
    groupsAccess: boolean;
    queueAccess: boolean;
    messagesAccess: boolean;
    alertsAccess: boolean;
    picturesAccess: boolean;
    bulletinBoardAccess: boolean;
    canDeletePatients: boolean;
    canDeleteDevices: boolean;
    bargeInCallEnabled: boolean;
    canRestartDevices: boolean;
    devicesAccess: boolean;
    classesAccess: any;
    userData: any;
    userEmail: string;
  },
  {
    firstName: string;
    isAdminControlView: boolean;
    allPatientsAccessControlView: boolean;
    errorEmailVisibility: string;
    errorTelephoneVisibility: string;
    errorEmailText: string;
    errorTelephoneText: string;
    userTelephone: string;
    saveBtnState: string;
    userIsAdmin: boolean;
    userIsOnDuty;
  }
> {
  private txtUserUpdateTelephone: string;
  private TwoFactorEnabled: boolean;
  private imgRef: HTMLImageElement;
  private fileInputRef: HTMLInputElement;

  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      isAdminControlView: true,
      allPatientsAccessControlView: true,

      errorEmailVisibility: 'hide',
      errorEmailText: 'Invalid Email',
      userTelephone: this.props.userTelephone || '',
      errorTelephoneVisibility: 'hide',
      errorTelephoneText: 'Telephone already registered',

      saveBtnState: '',
      userIsAdmin: false,
      userIsOnDuty: false,
    };

    this.submitUpdateCareUserForm = this.submitUpdateCareUserForm.bind(this);
    this.onSetUserIsAdminAction = this.onSetUserIsAdminAction.bind(this);

    this.onUserUpdateDataAction = this.onUserUpdateDataAction.bind(this);
    //this.onUpdateUserBroadcastMessageAction = this.onUpdateUserBroadcastMessageAction.bind(this);
    this.onUpdateReceiveEscalationAction = this.onUpdateReceiveEscalationAction.bind(this);

    this.onSetSeeAllPatientsAction = this.onSetSeeAllPatientsAction.bind(this);
    //this.handleSelectAdmin = this.handleSelectAdmin.bind(this);

    this.handleStaffAccess = this.handleStaffAccess.bind(this);
    this.handleClientAccess = this.handleClientAccess.bind(this);
    this.handleGroupsAccess = this.handleGroupsAccess.bind(this);
    this.handleQueueAccess = this.handleQueueAccess.bind(this);
    this.handleMessagesAccess = this.handleMessagesAccess.bind(this);
    this.handleAlertsAccess = this.handleAlertsAccess.bind(this);
    this.handlePicturesAccess = this.handlePicturesAccess.bind(this);
    this.handleBulletinBoardAccess = this.handleBulletinBoardAccess.bind(this);
    this.handleDeletePatients = this.handleDeletePatients.bind(this);
    this.handleDeleteDevices = this.handleDeleteDevices.bind(this);
    this.handleBargeInCallsEnabled = this.handleBargeInCallsEnabled.bind(this);
    this.handleDevicesAccess = this.handleDevicesAccess.bind(this);

    this.handleCanImportClientsConfig = this.handleCanImportClientsConfig.bind(this);
    this.handleRestartDevicesAccess = this.handleRestartDevicesAccess.bind(this);

    this.state.userTelephone = this.props.userTelephone || '';

    this.TwoFactorEnabled = AuthStore.get2FactorAuthEnabled();

  }

  componentDidMount() {
    //Register Listener
    CareUserStore.on('onSetUserIsAdminAction', this.onSetUserIsAdminAction);
    CareUserStore.on('onUserUpdateData', this.onUserUpdateDataAction);
    //CareUserStore.on("onUpdateUserBroadcastMessage", this.onUpdateUserBroadcastMessageAction);
    CareUserStore.on('onUpdateReceiveEscalation', this.onUpdateReceiveEscalationAction);
    CareUserStore.on('onSetSeeAllPatients', this.onSetSeeAllPatientsAction);
    this.state.userTelephone = this.props.userTelephone || '';
  }

  componentWillReceiveProps() {
    console.log('UserProfileEdit componentWillReceiveProps');
    console.log('UserProfileEdit componentWillReceiveProps userData', this.props.userData);
    this.state.userTelephone = this.props.userTelephone || '';
  }

  componentWillUnmount() {
    //Remove Listener
    CareUserStore.removeListener('onSetUserIsAdminAction', this.onSetUserIsAdminAction);
    CareUserStore.removeListener('onUserUpdateData', this.onUserUpdateDataAction);
    //CareUserStore.removeListener("onUpdateUserBroadcastMessage", this.onUpdateUserBroadcastMessageAction);
    CareUserStore.removeListener('onUpdateReceiveEscalation', this.onUpdateReceiveEscalationAction);
    CareUserStore.removeListener('onSetSeeAllPatients', this.onSetSeeAllPatientsAction);
  }

  async handleClassesAccess(dispatch, { currentTarget }) {
    const { userId } = this.props;
    dispatch(
      enableClassSessionAccessToUser({
        userId,
        currentStatus: currentTarget.checked,
      }),
    );
  }

  async handleSalesforceImport(dispatch, { currentTarget }) {
    const { userId } = this.props;
    dispatch(
      enableSalesforceImportToUser({
        userId,
        currentStatus: currentTarget.checked,
      }),
    ).then(() => {
      if (userId === AuthStore.getUserID()) {
        dispatch(getLoggedInUserConfig(userId));
      }
    });
  }

  async handleReportsAccess(dispatch, { currentTarget }) {
    const { userId } = this.props;
    console.log('handleReportsAccess');
    dispatch(
      enableReportsToUser({
        userId,
        currentStatus: currentTarget.checked,
      }),
    ).then(() => {
      if (userId === AuthStore.getUserID()) {
        dispatch(getLoggedInUserConfig(userId));
      }
    });
  }

  onTelephoneInputChange=(phoneNumber)=>{
    console.log("onTelephoneInputChange");
    console.log("onTelephoneInputChange phoneNumber", phoneNumber);
    this.state.userTelephone = phoneNumber;

      this.setState({
        errorTelephoneVisibility: 'hide',
      });
  }
  

  submitUpdateCareUserForm() {
    ////setTimeout(function(){ $('select').material_select(); }, 500);
    console.log('submitUpdateUserForm');

    if (this.refs.txtUserUpdateFirstName.value === '') {
      Message.show('FirstName is required.');
      return;
    }

    if (this.refs.txtUserUpdateLastName.value === '') {
      Message.show('LastName is required.');
      return;
    }

    if (this.refs.txtUserUpdateEmail.value === '') {
      Message.show('Ups! The email is not valid.');
      return;
    }
    // if (this.refs.txtUserUpdateTelephone.value === "") {
    //     Message.show('Telephone is required.');
    //     return;
    // }
    //if (this.refs.txtUserUpdateAddress.value === "") {
    //Message.show('Address is required.');
    //return;
    //}

    if (this.refs.txtUserUpdateEmail.value.length > 0) {
      this.setState({
        errorEmailVisibility: 'hide',
      });
    }

    let userTelephone = this.state.userTelephone;    
    if (userTelephone.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
    if (userTelephone.length > 0 || this.TwoFactorEnabled) {
      userTelephone = userTelephone.replace(/[()-\s]/g, '');

      if ((userTelephone.length > 0 || this.TwoFactorEnabled) && userTelephone.length < 12) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Telephone must be at least 10 digits',
        });
        return;
      }

      const containsLetter = /[a-zA-Z]/.test(userTelephone);
      if (containsLetter) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone.',
        });
        return;
      }
    }
    if (userTelephone.length == 0) {
      userTelephone = '_';
    }

    const updateUserData = {
      UserID: this.props.userId,
      FirstName: this.refs.txtUserUpdateFirstName.value,
      LastName: this.refs.txtUserUpdateLastName.value,
      Email: this.refs.txtUserUpdateEmail.value,
      Telephone: userTelephone,
      Address: this.refs.txtUserUpdateAddress.value,
      IsAdmin: this.refs.checkIsAdmin.checked,
      IsOnDuty: this.props.userIsOnDuty,
    };

    this.setState({
      saveBtnState: 'disabled',
    });

    CareUserStore.UpdateUserData(updateUserData);
    //Listen to UserProfile.js->onUserUpdateDataAction

    //Get the User Data from WS
    //Removed Sep 22 - CARE-997
    // CareUserStore.setSeeAllPatients({
    //     UserID : this.props.userId,
    //     CanSee : this.refs.checkSeeAllPatients.checked
    // });

    /*
        DEPRECATED January 18th 2018 - CARE-2236
        CareUserStore.UpdateUserBroadcastMessage({
            UserID : this.props.userId,
            CanSendMessageToAllPatients : this.refs.checkMessageAllPatients.checked
        });
        */
    // Listen to "onUpdateUserBroadcastMessage"

    // CareUserStore.UpdateReceiveEscalation({
    //     UserID : this.props.userId,
    //     ReceiveEscalationCallRequest : this.refs.checkReceiveEscalation.checked
    // });
    // Listen to "onUpdateReceiveEscalation"
  }

  onUserUpdateDataAction(response) {
    //console.log("onUserUpdateDataAction", response);

    this.setState({
      saveBtnState: '',
    });

    if (!response.ok) {
      if (response.emailRegistered) {
        this.setState({
          errorEmailVisibility: '',
          errorTelephoneVisibility: 'hide',
          errorEmailText: 'Email already exists',
        });
      } else if (response.telephoneRegistered) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Telephone already registered',
        });
      } else {
        Message.show('Error updating ' + AuthStore.getUserLabel());
      }
    }
  }

  /*
    DEPRECATED January 18th 2018 - CARE-2236
    onUpdateUserBroadcastMessageAction(response){

        console.log("onUpdateUserBroadcastMessageAction", response);
        console.log(response);

        if (!response.ok){
            Message.show("Error updating 'Can Send Message to All " + AuthStore.getPatientLabelPlural() + "'" );  
        } 

        if (this.props.userId == AuthStore.getUserID()) {
            AuthStore.setCanSendPatientsMessage(response.canSend);
        }
    }
    */

  onUpdateReceiveEscalationAction(response) {
    console.log('onUpdateReceiveEscalationAction', response);
    console.log(response);

    if (!response.ok) {
      Message.show('Error updating Receive Escalation Call Request Alerts');
    }
  }

  onSetSeeAllPatientsAction(result) {
    console.log('onSetUserOnDutyAction');
    console.log(result);

    if (result.ok) {
      // if (result.canSee){
      //     Message.show("You can see all the patients now");
      // }else{
      //     Message.show("You will see only your assigned patients");
      // }
      Message.show('Can see all patients updated');
    } else {
      Message.show('Error updating your Profile, please try again');
    }
  }

  /*
    handleSelectAdmin(e){
        console.log("Checkbox");
        //console.log(e);
        let setIsAdmin = e.target.checked;

        //Deprecated
        //Removed Sep 22 - CARE-997 
        // this.refs.checkSeeAllPatients.checked = setIsAdmin;

        this.setState({
            allPatientsAccessControlView : !setIsAdmin
        });
    }
    */

  //onChange={this.handleIsAdminProfile}
  handleIsAdminProfile = e => {
    console.log('Checkbox', this.props.userId);
    const isAdmin = e.target.checked;
    console.log(isAdmin);

    this.setState({
      allPatientsAccessControlView: !isAdmin,
    });

    //Get the User Data from WS
    CareUserStore.setUserIsAdmin({
      UserID: this.props.userId,
      IsAdmin: isAdmin,
    });
  };

  onSetUserIsAdminAction(result) {
    console.log('onSetUserIsAdminAction');
    console.log(result);
    console.log(this.props.userId);

    if (result.ok) {
      this.setState({
        userIsAdmin: result.isAdmin,
      });
      if (result.isAdmin) {
        Message.show(this.props.userFirstName + ' ' + this.props.userLastName + ' is Admin now!');
      } else {
        Message.show(this.props.userFirstName + ' ' + this.props.userLastName + ' has no privileges now!');
      }

      if (result.userId == AuthStore.getUserID()) {
        AuthStore.setIsAdmin(result.isAdmin);
      }

      this.props.onRefresh(this.props.userId);
    } else {
      Message.show('Error updating ' + this.props.userFirstName + ' ' + this.props.userLastName + ' IsAdmin, please try again.');
    }
  }

  handleStaffAccess(e) {
    const access = e.target.checked;
    console.log('handleStaffAccess', access);

    updateUserConfigPromise(this.props.userId, {
      CanSeeStaffTab: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeStaffTab ? ' enabled' : ' disabled';

        Message.show('Staff Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Staff Access ');
      });
  }

  handleClientAccess(e) {
    const access = e.target.checked;
    console.log('handleClientAccess', access);
    updateUserConfigPromise(this.props.userId, {
      CanSeeClientsTab: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeClientsTab ? ' enabled' : ' disabled';

        Message.show('Clients Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Clients Access ');
      });
  }

  handleGroupsAccess(e) {
    const access = e.target.checked;
    console.log('handleGroupsAccess', access);
    updateUserConfigPromise(this.props.userId, {
      CanAccessGroups: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanAccessGroups ? ' enabled' : ' disabled';

        Message.show('Groups Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Groups Access ');
      });
  }

  handleQueueAccess(e) {
    const access = e.target.checked;
    console.log('handleQueueAccess', access);

    updateUserConfigPromise(this.props.userId, {
      CanSeeQueueTab: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeQueueTab ? ' enabled' : ' disabled';

        Message.show('Queue Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Queue Access ');
      });

    // CareUserStore.setQueueAccess({
    //     UserID: this.props.userId,
    //     CanSeeQueueTab: access
    // });
  }

  handleMessagesAccess(e) {
    const access = e.target.checked;
    console.log('handleMessagesAccess', access);

    updateUserConfigPromise(this.props.userId, {
      CanSeeMessageCenterTab: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeMessageCenterTab ? ' enabled' : ' disabled';

        Message.show('Messages Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Messages Access ');
      });
  }

  handleAlertsAccess(e) {
    const access = e.target.checked;
    console.log('handleAlertsAccess', access);

    updateUserConfigPromise(this.props.userId, {
      CanSeeAlertsTab: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeAlertsTab ? ' enabled' : ' disabled';

        Message.show('Alerts Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Alerts Access ');
      });
  }

  handlePicturesAccess(e) {
    const access = e.target.checked;
    console.log('handlePicturesAccess', access);

    updateUserConfigPromise(this.props.userId, {
      CanSeeMemoryBox: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeMemoryBox ? ' enabled' : ' disabled';

        Message.show('Memory Box Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Memory Box Access ');
      });
  }

  handleBulletinBoardAccess(e) {
    const access = e.target.checked;
    console.log('handleBulletinBoardAccess', access);

    updateUserConfigPromise(this.props.userId, {
      CanSeeBulletinBoard: access,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeBulletinBoard ? ' enabled' : ' disabled';

        Message.show('Bulletin Board Access ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Bulletin Board Access ');
      });

    // CareUserStore.setBulletinBoardAccess({
    //     UserID: this.props.userId,
    //     CanSeeBulletinBoard: access
    // });
  }

  handleDeletePatients(e) {
    const enabled = e.target.checked;
    console.log('handleDeletePatients', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanDeletePatients: enabled,
    })
      .then(({ data }) => {
        const responseResult = data.CanDeletePatients ? ' enabled' : ' disabled';

        Message.show('Delete ' + AuthStore.getPatientLabelPlural() + ' ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Delete ' + AuthStore.getPatientLabelPlural());
      });

    // CareUserStore.setDeletePatientsEnabled({
    //     UserID: this.props.userId,
    //     CanDeletePatients: enabled
    // });
    // Listen to "onDeletePatientsEnabled"
  }

  handleDeleteDevices(e) {
    const enabled = e.target.checked;
    console.log('handleDeleteDevices', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanDeleteDevices: enabled,
    })
      .then(({ data }) => {
        const responseResult = data.CanDeleteDevices ? ' enabled' : ' disabled';

        Message.show('Delete Devices ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Delete Devices');
      });

    // CareUserStore.setDeleteDevicesEnabled({
    //     UserID: this.props.userId,
    //     CanDeleteDevices: enabled
    // });
    // Listen to "onDeleteDevicesEnabled"
  }

  handleBargeInCallsEnabled(e) {
    const enabled = e.target.checked;
    console.log('handleBargeInCallsEnabled', enabled);

    updateUserConfigPromise(this.props.userId, {
      BargeInCall: enabled,
    })
      .then(({ data }) => {
        const responseResult = data.BargeInCall ? ' enabled' : ' disabled';

        Message.show('Barge In Calls ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Barge In Calls');
      });

    // CareUserStore.setBargeInCallsEnabled({
    //     UserID: this.props.userId,
    //     Enabled: enabled
    // });
    // Listen to "onBargeInCallsEnabled"
  }

  handleDevicesAccess(e) {
    const enabled = e.target.checked;
    console.log('handleDevicesAccess', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanSeeDevicesTab: enabled,
    })
      .then(({ data }) => {
        const responseResult = data.CanSeeDevicesTab ? ' enabled' : ' disabled';

        Message.show('Access to Devices Screen ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Access to Devices Screen');
      });
    ///here
    // CareUserStore.setDevicesAccess({
    //     UserID: this.props.userId,
    //     CanSeeDevicesTab: enabled
    // });
    //Listen to "onSetDevicesAccessUpdated"
  }

  handleCanImportClientsConfig(e) {
    const enabled = e.target.checked;
    console.log('handleCanImportClientsConfig', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanImportClients: enabled,
    })
      .then(({ data }) => {
        console.log('updateUserConfigPromise response', data);
        const responseResult = data.CanImportClients ? ' enabled' : ' disabled';
        Message.show('User can import Clients ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring: User can import Clients');
      });
  }

  handleRestartDevicesAccess(e) {
    const enabled = e.target.checked;
    console.log('handleRestartDevicesAccess', enabled);

    updateUserConfigPromise(this.props.userId, {
      CanRestartDevices: enabled,
    })
      .then(({ data }) => {
        const responseResult = data.CanRestartDevices ? ' enabled' : ' disabled';
        Message.show('Access to Restart Devices ' + responseResult);
      })
      .catch(e => {
        console.warn('updateUserConfig error', e);
        Message.show('Error configuring Access to Restart Devices');
      });

    // CareUserStore.setRestartDevicesAccess({
    //     UserID: this.props.userId,
    //     CanRestartDevices: enabled
    // });
    //Listen to "onRestartDevicesAccessUpdated"
  }

  render() {
    const currentSection = classNames({
      hide: this.props.currentMode != 'Edit',
    });

    const isAdminValue = !!this.props.userIsAdmin;
    const seeAllPatientsValue = this.props.userAllPatientsAccess ? 'checked' : '';

    const staffAccessValue = this.props.staffAccess ? 'checked' : '';
    const clientAccessValue = this.props.clientAccess ? 'checked' : '';
    const groupsAccessValue = this.props.groupsAccess ? 'checked' : '';
    const queueAccessValue = this.props.queueAccess ? 'checked' : '';
    const messagesAccessValue = this.props.messagesAccess ? 'checked' : '';
    const alertsAccessValue = this.props.alertsAccess ? 'checked' : '';
    const picturesAccessValue = this.props.picturesAccess ? 'checked' : '';
    const bulletinBoardAccessValue = this.props.bulletinBoardAccess ? 'checked' : '';
    const canDeleteDevicesValue = this.props.canDeleteDevices ? 'checked' : '';
    const canDeletePatientsValue = this.props.canDeletePatients ? 'checked' : '';
    const bargeInCallEnabledValue = this.props.bargeInCallEnabled ? 'checked' : '';
    const devicesAccessValue = this.props.devicesAccess ? 'checked' : '';

    const isAdminCheckDisabled = !this.props.adminAccess;
    const allPatientsAccessDisabled = !this.state.allPatientsAccessControlView;

    let adminAccessVisibility = ' ';

    if (this.props.userId == AuthStore.getUserID() && !this.props.adminAccess) {
      adminAccessVisibility = ' hide';
    }
    //console.log("USERPROFILEEDIT", this.props.accountEscalationEmail);

    return (
      <div className={'nucleus-user-pending nucleus-form-container ' + currentSection}
        style={{ backgroundColor: 'white', borderRadius:16}}>
        <br />
        <div className="row">
          {/* <div className="col s2 m2 l1 ">
                <img
                  className="responsive-img circle"
                  src={this.props.userImageUrl || "/img/user_placeholder.png"}
                />
              </div> */}

          <div className="col s4 m3 l2">
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <style>
                {`
                  .img-circle {
                      border-radius: 50%;
                  }
                  .img-circle {
                      border-radius: 0;
                  }
                  
                  .ratio {
                      background-position: center center;
                      background-repeat: no-repeat;
                      background-size: cover;
                  
                      height: 0;
                      padding-bottom: 100%;
                      position: relative;
                      width: 100%;
                  }
                  .img-circle {
                      border-radius: 50%;
                  }
                  .img-responsive {
                      display: block;
                      height: auto;
                      max-width: 100%;
                  }
                  .inputfile {
                      width: 0.1px;
                      height: 0.1px;
                      opacity: 0;
                      overflow: hidden;
                      position: absolute;
                      z-index: -1;
                  }
                  .inputfile + label {
                      margin-top:10px;
                      font-size: 1.25em;
                      // font-weight: 700;
                      text-decoration: underline;
                      color: gray;
                      // background-color: black;
                      display: inline-block;
                  }
                  
                  .inputfile:focus + label,
                  .inputfile + label:hover {
                      cursor: pointer;
                      // background-color: red;
                  }
                  .inputfile {
                      cursor: pointer;
                  }
                `}
              </style>
              <div
                style={{
                  width: '70%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <img
                  className=""
                  style={{ width: 140, height: 140, borderRadius: 70, objectFit: 'cover' }}
                  src={this.props.userImageUrl ? this.props.userImageUrl : '/img/user_placeholder.png'}
                  ref={img => (this.imgRef = img)}
                  //onError={() => (this.imgRef.src = '/img/user_placeholder.png')}
                />
              </div>
            </div>
          </div>

          <div className="col s8 m8 l6 ">
            <div className="row">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels"> First Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s8 " key={this.props.userFirstName}>
                <input id="txtUserUpdateFirstName" ref="txtUserUpdateFirstName" type="text" className="validate nucleus-input-form" defaultValue={this.props.userFirstName} />
              </div>
            </div>
            <div className="row">
              <div className="col s4 valgin-wrapper">
                <span className=" nucleus-labels"> Last Name</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s8" key={this.props.userLastName}>
                <input id="txtUserUpdateLastName" ref="txtUserUpdateLastName" type="text" className="validate nucleus-input-form" defaultValue={this.props.userLastName} />
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Email</span> <span className="nucleus-labels txt-red">*</span>
              </div>
              <div className="col s8" key={this.props.userEmail}>
                <input id="txtUserUpdateEmail" ref="txtUserUpdateEmail" type="text" className="validate nucleus-input-form-disabled" disabled defaultValue={this.props.userEmail} />
                <span className={'nucleus-form-error center-align ' + this.state.errorEmailVisibility}> {this.state.errorEmailText}</span>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Telephone</span>
                {this.TwoFactorEnabled && <span className="nucleus-labels txt-red">&nbsp;*</span>}
              </div>
              <div className="col s8" key={'txtUserTelephone'}>
                <TelephoneInputUnderline
                  inputId='txtUserTelephone'
                  value={this.state.userTelephone}
                  onChange={this.onTelephoneInputChange}
                  onBlur={this.validateForm}
                  style={{marginLeft:3}}
                />
                <span className={'nucleus-form-error center-align ' + this.state.errorTelephoneVisibility}>{this.state.errorTelephoneText}</span>
              </div>
            </div>
            <div className="row">
              <div className="col s4">
                <span className=" nucleus-labels"> Address</span>
              </div>
              <div className="col s8" key={this.props.userAddress}>
                <input id="txtUserUpdateAddress" ref="txtUserUpdateAddress" type="text" className="validate nucleus-input-form" defaultValue={this.props.userAddress} />
              </div>
            </div>
            {/* MOVED FOR GOOD LOOKING IN UI */}
            {/* <div className={"row " + adminAccessVisibility}>
                  <div className="col s4">
                    <span className=" nucleus-labels"> Admin Access</span>
                  </div>
                  <div className="col s8 no-margin">
                    <div className="switch no-margin ">
                      <label className="no-margin" key={isAdminValue}>
                        <input
                          type="checkbox"
                          ref="checkIsAdmin"
                          disabled={isAdminCheckDisabled}
                          defaultChecked={isAdminValue}
                          onChange={this.handleIsAdminProfile}
                        />
                        <span className="lever nucleus-check"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row hide">
                  <div className="col s4">
                    <span className=" nucleus-labels">
                      {" "}
                      Can View All {AuthStore.getPatientLabelPlural()}
                    </span>
                  </div>
                  <div className="col s8">
                    <div className="switch">
                      <label key={seeAllPatientsValue}>
                        <input
                          type="checkbox"
                          ref="checkSeeAllPatients"
                          disabled={allPatientsAccessDisabled}
                          defaultChecked={seeAllPatientsValue}
                        />
                        <span className="lever"></span>
                      </label>
                    </div>
                  </div>
                </div> */}
            {/*
                        // DEPRECATED January 18th 2018 - CARE-2236
                        <div className={"row  medium-space  " + adminAccessVisibility }>
                            <div className="col s4 ">
                                <span className=" nucleus-labels"> Can Send Message to All { AuthStore.getPatientLabelPlural() }</span> 
                            </div>
                            <div className="col s8">
                                <span key={allPatientsMessageValue}>
                                    <input ref="checkMessageAllPatients" type="checkbox" className="filled-in" id={"checkMessageAllPatients" + this.state.accountID } defaultChecked={allPatientsMessageValue}/>
                                    <label for={"checkMessageAllPatients"+ this.state.accountID }>&nbsp;</label>
                                </span>
                            </div>
                        </div>
                        */}

            {/*
                        // DEPRECATED January 10th 2019 - CARE-3974
                        <div className={"row  medium-space " + adminAccessVisibility }>
                            <div className="col s4">
                                <span className={" nucleus-labels " + receiveEscalationStyle}> Receive Escalation Alerts</span> 
                            </div>
                            <div className="col s8">
                               <span key={receiveEscalationValue}>
                                    <input ref="checkReceiveEscalation" type="checkbox" className="filled-in " id="checkReceiveEscalation" disabled={receiveEscalationMode} defaultChecked={receiveEscalationValue}/>
                                    <label htmlFor="checkReceiveEscalation">&nbsp;</label>
                                </span>
                            </div>
                        </div>*/}
            <style>
            {`
              .pdl-0 {
                padding:0 0 !important;
              }
            `}
            </style>

            <div className={'row ' + adminAccessVisibility}>
              <div className="col s4">
                <span className=" nucleus-labels"> Admin Access</span>
              </div>
              <div className="col s8">
                <div className="switch" style={{marginLeft:-9}}>
                  <label className="" key={isAdminValue}>
                    <input
                      type="checkbox" 
                      ref="checkIsAdmin" 
                      disabled={isAdminCheckDisabled} 
                      defaultChecked={isAdminValue} 
                      onChange={this.handleIsAdminProfile} 
                    />
                    <span className="lever nucleus-check"></span>
                  </label>
                </div>
              </div>
            </div>

            {/*  Disabled */}
            <div className="row hide">
              <div className="col s8">
                <span className=" nucleus-labels"> Can View All {AuthStore.getPatientLabelPlural()}</span>
              </div>
              <div className="col">
                <div className="switch">
                  <label key={seeAllPatientsValue}>
                    <input type="checkbox" ref="checkSeeAllPatients" disabled={allPatientsAccessDisabled} defaultChecked={seeAllPatientsValue} />
                    <span className="lever"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col s0 m1 l4"></div>
        </div>
        <div className="row">
          <div className={'col s4 m3 l2'}></div>
          <div className={'col s8 m8 l8'}>
            <div className={'col s6 pdl-0'}>
              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels">Can Access Staff Tab</span>
                </div>
                <div className="col s2">
                  <span key={staffAccessValue}>
                    <input ref="checkStaffAccess" type="checkbox" className="filled-in" id="checkStaffAccess" onChange={this.handleStaffAccess} defaultChecked={staffAccessValue} />
                    <label htmlFor="checkStaffAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels">Can Access {AuthStore.getPatientLabelPlural()} Tab</span>
                </div>
                <div className="col s2">
                  <span key={clientAccessValue}>
                    <input
                      ref="checkClientAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkClientAccess"
                      onChange={this.handleClientAccess}
                      defaultChecked={clientAccessValue}
                    />
                    <label htmlFor="checkClientAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels">Can Access Groups Tab</span>
                </div>
                <div className="col s2">
                  <span key={groupsAccessValue}>
                    <input
                      ref="checkGroupsAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkGroupsAccess"
                      onChange={this.handleGroupsAccess}
                      defaultChecked={groupsAccessValue}
                    />
                    <label htmlFor="checkGroupsAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels">Can Access Queue Tab</span>
                </div>
                <div className="col s2">
                  <span key={queueAccessValue}>
                    <input
                      ref="checkQueueAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkQueueAccess"
                      onChange={this.handleQueueAccess}
                      defaultChecked={!!queueAccessValue}
                    />
                    <label htmlFor="checkQueueAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Access Message Center Tab</span>
                </div>
                <div className="col s2">
                  <span key={messagesAccessValue}>
                    <input
                      ref="checkMessagesAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkMessagesAccess"
                      onChange={this.handleMessagesAccess}
                      defaultChecked={messagesAccessValue}
                    />
                    <label htmlFor="checkMessagesAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Access Alerts Tab</span>
                </div>
                <div className="col s2">
                  <span key={alertsAccessValue}>
                    <input
                      ref="checkAlertsAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkAlertsAccess"
                      onChange={this.handleAlertsAccess}
                      defaultChecked={!!alertsAccessValue}
                    />
                    <label htmlFor="checkAlertsAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Access Memory Box Tab</span>
                </div>
                <div className="col s2">
                  <span key={picturesAccessValue}>
                    <input
                      ref="checkPicturesAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkPicturesAccess"
                      onChange={this.handlePicturesAccess}
                      defaultChecked={!!picturesAccessValue}
                    />
                    <label htmlFor="checkPicturesAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Access Bulletin Board Tab</span>
                </div>
                <div className="col s2">
                  <span key={bulletinBoardAccessValue}>
                    <input
                      ref="checkBulletinBoardAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkBulletinBoardAccess"
                      onChange={this.handleBulletinBoardAccess}
                      defaultChecked={!!bulletinBoardAccessValue}
                    />
                    <label htmlFor="checkBulletinBoardAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Access Devices Tab</span>
                </div>
                <div className="col s2">
                  <span key={devicesAccessValue}>
                    <input
                      ref="checkDevicesAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkDevicesAccess"
                      onChange={this.handleDevicesAccess}
                      defaultChecked={!!devicesAccessValue}
                    />
                    <label htmlFor="checkDevicesAccess">&nbsp;</label>
                  </span>
                </div>
              </div>

              <BackendClientFetcher
                userId={this.props.userId}
                render={(dispatch, label) => (
                  <div>
                    <ClassSessionAccessCheckbox
                      hasAdminVisibility={this.props.adminAccess}
                      label={`Can Access ${label} tab`}
                      id="checkClassesAccess"
                      onChange={e => this.handleClassesAccess(dispatch, e)}
                    />
                    <ReportsAccessCheckbox
                      hasAdminVisibility={this.props.adminAccess}
                      label="Can Access Reports tab"
                      id="checkReportsAccess"
                      onChange={e => this.handleReportsAccess(dispatch, e)}
                    />
                  </div>
                )}
              />
            </div>
            <div className={'col s6 pdl-0'}>
              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Delete {AuthStore.getPatientLabelPlural()}</span>
                </div>
                <div className="col s2">
                  <span key={canDeletePatientsValue}>
                    <input
                      ref="checkDeletePatientsEnabled"
                      type="checkbox"
                      className="filled-in"
                      id="checkDeletePatientsEnabled"
                      onChange={this.handleDeletePatients}
                      defaultChecked={!!canDeletePatientsValue}
                    />
                    <label htmlFor="checkDeletePatientsEnabled">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Edit/Delete Devices</span>
                </div>
                <div className="col s2">
                  <span key={canDeleteDevicesValue}>
                    <input
                      ref="checkDeleteDevicesEnabled"
                      type="checkbox"
                      className="filled-in"
                      id="checkDeleteDevicesEnabled"
                      onChange={this.handleDeleteDevices}
                      defaultChecked={!!canDeleteDevicesValue}
                    />
                    <label htmlFor="checkDeleteDevicesEnabled">&nbsp;</label>
                  </span>
                </div>
              </div>
              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Place Barge In Calls</span>
                </div>
                <div className="col s2">
                  <span key={bargeInCallEnabledValue}>
                    <input
                      ref="checkBargeInCallsEnabled"
                      type="checkbox"
                      className="filled-in"
                      id="checkBargeInCallsEnabled"
                      defaultChecked={!!bargeInCallEnabledValue}
                      onChange={this.handleBargeInCallsEnabled}
                    />
                    <label htmlFor="checkBargeInCallsEnabled">&nbsp;</label>
                  </span>
                </div>
              </div>
              {/* IMPORT SALESFORCE USERS */}
              <BackendClientFetcher
                userId={this.props.userId}
                render={dispatch => (
                  <div>
                    <SalesforceImportAccessCheckbox
                      hasAdminVisibility={this.props.adminAccess}
                      label="Can Import Salesforce Users"
                      id="checkSalesforceImport"
                      onChange={e => this.handleSalesforceImport(dispatch, e)}
                    />
                  </div>
                )}
              />

              {this.props.userData.accountEnableImportClients && this.props.adminAccess && (
                <div className={'row '} style={{ display: 'flex' }}>
                  <div className="col s10">
                    <span className=" nucleus-labels">Can Import Clients from CSV</span>
                  </div>
                  <div className="col s2">
                    <span key={this.props.userData.canImportClients ? 'checked' : ''}>
                      <input
                        ref="checkCanImportClients"
                        type="checkbox"
                        className="filled-in"
                        id="checkCanImportClients"
                        onChange={this.handleCanImportClientsConfig}
                        defaultChecked={!!(this.props.userData.canImportClients ? 'checked' : '')}
                      />
                      <label htmlFor="checkCanImportClients"></label>
                    </span>
                  </div>
                </div>
              )}

              <div className={'row ' + adminAccessVisibility} style={{ display: 'flex' }}>
                <div className="col s10">
                  <span className=" nucleus-labels"> Can Restart Devices</span>
                </div>
                <div className="col s2">
                  <span key={this.props.canRestartDevices ? 'checked' : ''}>
                    <input
                      ref="checkRestartDevicesAccess"
                      type="checkbox"
                      className="filled-in"
                      id="checkRestartDevicesAccess"
                      onChange={this.handleRestartDevicesAccess}
                      defaultChecked={!!(this.props.canRestartDevices ? 'checked' : '')}
                    />
                    <label htmlFor="checkRestartDevicesAccess">&nbsp;</label>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="fixed-action-btn btn-add-new">
          <a
            id="update-user"
            className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
            data-position="left"
            data-delay="50"
            data-tooltip={'Save ' + AuthStore.getUserLabel()}
            onClick={this.submitUpdateCareUserForm}
            style={{marginRight:16}}
          >
            <i className="material-icons">save</i>
          </a>
          
        </div>
      </div>
    );
  }
}

const UINucleusColumnsMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
//Connect redux to component to expose this.props.dispatch
export default connect(null, null)(UserProfileEdit);
