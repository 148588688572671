import { FC } from 'react';
import styled from 'styled-components';
import { ButtonVariant, UIButton } from '../../Buttons';

const ModalActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-evenly;
  margin-top: 25px;
`;

const StyledButton = styled.div`
  flex-basis: calc(40% - 5px);
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
`;

export type ModalAction = {
  label: string;
  onClick: () => Promise<void> | void;
  disabled?: boolean;
  buttonVariant?: ButtonVariant;
};

type Props = {
  actions?: ModalAction[];
};

const ActionButtons: FC<Props> = ({ actions }) => {
  if (!actions) {
    return null;
  }

  const actionsMap = actions.map(({ label, onClick, buttonVariant, disabled }) => (
    <StyledButton key={label}>
      <UIButton onClick={onClick} text={label} variant={buttonVariant} disabled={disabled} />
    </StyledButton>
  ));

  return <ModalActions>{actionsMap}</ModalActions>;
};

export default ActionButtons;
