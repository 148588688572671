import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class QueueApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<----------------------------------Get All Patients Data--------------------------->
  //<------------------------------------- DEPRECATED --------------------------------->
  getCareCallRequestsQueue(data, callback) {
    const random_num = Math.floor(new Date().getTime() / 1000);

    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareCall.asmx/GetCallRequestsQueue?rnd=' + random_num,
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Get All Patients Data--------------------------->
  getCareCallRequestsQueueForUser(data, callback) {
    let rsp;
    httpNodeApi
      .get('user/' + data.UserID + '/call_requests_queue', {})
      .then(response => {
        //console.log("/user/" + UserID + "/call_requests_queue Response:", response);
        //console.log("user/:userId/call_requests_queue response", response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on user/:userId/call_requests_queue' };
        console.log('Error user/:userId/call_requests_queue error', e);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        //console.log("user/:userId/call_requests_queue finally");
        //console.log("rsp",rsp);
        callback(rsp);
      });
  }

  //<----------------------------------Report Call Handled--------------------------->
  ReportCallRequestHandled(data, callback) {
    let rsp;
    httpNodeApi
      .post('call/report_call_request_handled', data)
      .then(response => {
        //console.log("call/report_call_request_handled response", response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_call_request_handled' };
        //console.log("Error call/report_call_request_handled error", error);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        //console.log("call/report_call_request_handled finally");
        //console.log("rsp",rsp);
        callback(rsp);
      });
  }

  ReportCallAddressed(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareCall.asmx/ReportCallAddressed',
      data: data,
      callback: callback,
    });
  }

  ReportCallInProgress(data, callback) {
    let rsp;
    httpNodeApi
      .post('call/report_connected_call', data)
      .then(response => {
        console.log('call/report_connected_call response', response);
        const { data } = response;
        rsp = {
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error on call/report_connected_call' };
        console.log('Error call/report_connected_call error', e);
        rsp = {
          ...data,
        };
      })
      .finally(() => {
        console.log('call/report_connected_call finally');
        console.log('rsp', rsp);

        callback(rsp);
      });
  }
}

export default QueueApi;
