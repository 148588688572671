import React, { useState } from 'react';
import styled from 'styled-components';
import { Patient } from './usePatientsSelector';

const Input = styled.input`
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  border-color: transparent;
`;

const PatientTag = styled.span`
  padding: 5px 10px;
  margin: 5px;
  background-color: #dadada;
  color: #0a313f;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-right: 5px;
`;

const Content = styled.div`
  background-color: #fff;
  padding: 18px 10px;
  position: relative;
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  color: #0a313f;
  margin-left: 8px;
  cursor: pointer;
  font-weight: bold;
`;

const Label = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

const ResultsPreview = styled.div`
  position: absolute;
  background-color: #fff;
  bottom: 90px;
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.5);
  left: 0;
`;

const SearchItem = styled.p`
  padding-bottom: 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
`;

type PatientSelectInputProps = {
  patients: Patient[];
  togglePatientSelection: (patientId: string) => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  selectedPatients: Patient[];
  patientsPerGroup: Patient[];
};

const PatientSelector: React.FC<PatientSelectInputProps> = ({ searchTerm, setSearchTerm, patients, togglePatientSelection, selectedPatients, patientsPerGroup }) => {
  const [isFocused, setIsFocused] = useState(false);

  const highlightText = (name, highlight) => {
    if (!name || !highlight) return null;
    const parts = name?.split(new RegExp(`(${highlight})`, 'gi'));
    return parts.map((part, index) => (part.toLowerCase() === highlight.toLowerCase() ? <mark key={index}>{part}</mark> : part));
  };

  const handleValueChange = e => {
    setSearchTerm(e.target.value);
  };

  const renderSelectedPatients = () => {
    return selectedPatients?.map(patient => (
      <PatientTag key={patient.id}>
        {patient.name}
        <CloseButton onClick={() => togglePatientSelection(patient.id)}>x</CloseButton>
      </PatientTag>
    ));
  };

  const renderSearchResults = () => {
    if (!searchTerm || !isFocused) {
      return null;
    }
    const isMatchingPatient = patientsPerGroup.some(
      patient => patient.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) || patient.lastName?.toLowerCase().includes(searchTerm.toLowerCase()),
    );
    if (isMatchingPatient) {
      return null;
    }
    const items = patients.map(patient => (
      <SearchItem
        key={patient.id}
        onClick={() => {
          togglePatientSelection(patient.id);
          setSearchTerm('');
        }}
      >
        {highlightText(patient.name, searchTerm)}
      </SearchItem>
    ));

    if (!items.length) {
      return null;
    }

    return <ResultsPreview>{items}</ResultsPreview>;
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };

  return (
    <div>
      <Label>Start typing and a list of names to choose from will appear</Label>
      <Content>
        <Input type="text" className="ui-nucleus" value={searchTerm} onChange={handleValueChange} placeholder="Type name" onFocus={() => setIsFocused(true)} onBlur={handleBlur} />
        {renderSearchResults()}
        {renderSelectedPatients()}
      </Content>
    </div>
  );
};

export default PatientSelector;
