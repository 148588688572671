//@ts-nocheck
import React from 'react';

import MdmDeviceApi from '../../apis/MdmDeviceApi';
import CarePatientStore from '../../stores/CarePatientStore';
import Message from '../../utils/Message';

class AccountDeviceItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.MdmDeviceApi = new MdmDeviceApi(this);
    this.state = {
      deviceID: this.props.ID,
      patientName: this.props.PatientName,
      status: false,
    };
    //console.log(this.state.patientID);
    this.handleRowClicked = this.handleRowClicked.bind(this);

    this.handleDeviceReboot = this.handleDeviceReboot.bind(this);

    this.lastClickedTime = null;
  }

  componentDidMount() {
    //console.log(this.state.patientID);
    this.setState({
      deviceID: this.props.ID,
    });
    //$('.tooltipped').tooltip({delay: 50});
    this.lastClickedTime = new Date();
    this.lastClickedTime.setMinutes(this.lastClickedTime.getMinutes() - 2);
  }

  componentWillUnmount() {
    //$('.tooltipped').tooltip('remove');
  }

  handleRowClicked() {
    //window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
  }

  async handleDeviceReboot() {
    console.log('handleDeviceReboot', this.props.Mac, this.props.ID);
    console.log('handleDeviceReboot this.props.MdmID', this.props.MdmID);
    const now = new Date();
    let difference = (now.getTime() - this.lastClickedTime.getTime()) / 1000;
    difference /= 60;
    //let minutes = Math.abs(Math.round(difference));

    if (difference > 1) {
      this.lastClickedTime = now;
      if (this.props.HexnodeID) {
        CarePatientStore.requestRebootDeviceNew({
          Mac: this.props.Mac,
          DeviceID: this.props.ID,
        });
      } else {
        console.log('Request device reboot', this.props.MdmDeviceID, this.props.ID);
        try {
          const response = await this.MdmDeviceApi.requestDeviceReboot({ MdmID: this.props.MdmID });
          if (response) {
            Message.show('Reboot request sent');
          }
        } catch (e) {
          Message.show('Failed to send reboot request');
        }
      }
    } else {
      console.log('The Reboot has already been requested');
    }
  }

  handleCheckRemoteLogs = event => {
    console.log('this has changed ', event.target.checked);

    // CareAccountStore.handleEnabledRemoteLogs({
    // 	DeviceID: this.props.ID,
    // 	Enabled: event.target.checked
    // });
  };

  render() {
    let patientName = this.props.PatientName;
    if (patientName != null && patientName.length > 25) {
      patientName = patientName.substring(0, 25) + ' ...';
    }

    let deviceName = this.props.DeviceName;
    if (deviceName != null && deviceName.length > 18) {
      deviceName = deviceName.substring(0, 18) + ' ...';
    }

    //console.log("DEVICE INFO>>> ", this.props.DeviceInfo);
    let deviceStatusIcon = '/img/icon_device_inactive.png';
    let deviceLastCheckIn = 'Device not assigned';
    //let deviceStatus =  "No device assigned";
    let deleteDeviceVisibility = ' ';
    //let rebootDeviceVisibility = " hide";
    //let rebootDeviceSpace = " ";
    let deviceVersion = '';
    //let deviceToolTip = "os";
    let timeZone = '';

    //console.log(this.props.DeviceInfo);

    deviceStatusIcon = this.props.DeviceInfoOnline ? '/img/icon_device_active.png' : '/img/icon_device_inactive.png';
    deviceLastCheckIn = 'Device Offline';
    try {
      if (this.props.DeviceInfoLastCheckinTime) {
        const lastDate = new Date(this.props.DeviceInfoLastCheckinTime);
        //lastDate.setHours(lastDate.getHours() + ( this.props.DeviceInfoTimezoneOffset ));
        deviceLastCheckIn =
          lastDate.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: 'numeric' }) +
          ' ' +
          lastDate.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
      }
    } catch (error) {}
    //deviceStatus = (this.props.DeviceInfo.Online) ? ("Device Online " + this.props.DeviceInfo.Version ) : deviceLastCheckIn;
    deleteDeviceVisibility = ' hide';
    deviceVersion = this.props.DeviceInfoAppVersion || ''; //this.props.DeviceOS + " " +
    //deviceToolTip = this.props.DeviceInfo.OS;
    timeZone = this.props.DeviceInfoTimezone;

    // let defaultValueCheckbox = '';
    // if (this.props.RemoteLogs) {
    // 	defaultValueCheckbox = "checked";
    // }
    if (this.props.DeviceInfo2 != '{}' && this.props.DeviceInfo2 != '' && this.props.DeviceInfo2 != null) {
      const deviceInfo2 = JSON.parse(this.props.DeviceInfo2);

      deviceVersion = deviceInfo2.systemName + ' ' + deviceInfo2.systemVersion + ', ' + (deviceInfo2.model ? deviceInfo2.model + ', ' : '') + deviceVersion;
      if (deviceInfo2.brand == 'goldtek') {
        deviceVersion = 'Legacy ' + deviceVersion;
      }
    }
    let mac = this.props.Mac || '';
    if (this.props.HexnodeID != null) {
      mac = this.props.HexnodeID;
    }
    if (this.props.MdmDeviceID) {
      mac = this.props.MdmDeviceID;
    }

    return (
      <tr style={{ backgroundColor: 'transparent' }}>
        {/* <td className="">
						{this.props.HexnodeID ? this.props.HexnodeID : this.props.Mac}
					</td> */}
        <td className="b-col-2">{mac}</td>
        <td className="b-col-2">{patientName}</td>
        <td className="b-col-2">{deviceName}</td>
        <td className="b-col-2">{this.props.PreAssignedName}</td>

        <td className="b-col-2">{deviceVersion}</td>
        <td className="b-col" style={{ textAlign: 'center' }}>
          {this.props.Battery ? this.props.Battery + '%' : ''}
        </td>
        <td className="b-col center-align">
          <img className=" " src={deviceStatusIcon} />
        </td>
        <td className="b-col-2">{deviceLastCheckIn}</td>
        <td className="b-col-2" style={{ textAlign: 'center' }}>
          {timeZone}
        </td>

        <td className="b-col-2 center-align">
          <span className="nucleus-table-icon nucleus-link tooltipped " data-delay="50" data-position="top" data-tooltip="Remote restart">
            {this.props.RestartDeviceAccess && <img src="/img/icon_device_restart.png" className="nucleus-link" width="28" onClick={this.handleDeviceReboot} />}
          </span>
        </td>
      </tr>
    );
  }
}

export default AccountDeviceItemRow;
