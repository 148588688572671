import { NucleusCallView, CallMediaType, Region } from '@nucleus-care/nucleuscare-connect-webrtc';
import { VideoViewData } from '../callLayout.types';

export const VideoRenderer = ({
  stateInCall,
  videoContainerH,
  stateVideoBgColor,
  data,
  key,
}: {
  stateInCall: boolean;
  videoContainerH: any;
  stateVideoBgColor: string;
  data: VideoViewData;
  key: number;
}) => {
  // console.log("rendering VideoRender", Date.now())
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        borderRadius: 10,
        padding: stateInCall ? 0 : 14,
        margin: 2,
        height: videoContainerH.current - (stateInCall ? 0 : 14),

        overflow: 'hidden',
        backgroundColor: stateVideoBgColor,
      }}
    >
      <NucleusCallView
        key={key}
        calleeId={data.entityId}
        deviceId={data.deviceId}
        isPreview={data.type == 'Audio' || !data.host ? false : true}
        mediaType={data.type == 'Audio' ? CallMediaType.Audio : CallMediaType.Video}
        region={Region.US}
        useRelayOnly={false}
      />
    </div>
  );
};
