import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useState } from 'react';
import authStore from 'stores/AuthStore';
import Message from 'utils/Message';
import { Account } from '../Account/useAccountSelector';
import { ProviderGroup } from './types';

const LIMIT = 20;

const useRegions = () => {
  const [page] = useState(1);
  const [hasMoreGroups] = useState(true);
  const [groups, setGroups] = useState<ProviderGroup[]>([]);
  const [totalGroups, setTotalGroups] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [accountsPerRegion, setAccountsPerRegion] = useState<Account[]>([]);
  const [totalAccountsPerRegion, setTotalAccountsPerRegion] = useState(0);

  const getGroupsByCategoryId = (providerGroupId: string) => {
    console.log('getGroupsByCategoryId');
    setGroups([]);
    if (isLoading || !hasMoreGroups) return false;
    setIsLoading(true);
    httpApi
      .get(`/providers/${authStore.getProviderID()}/groups/${providerGroupId}?limit=${LIMIT}&page=${page}`)
      .then(({ data }) => {
        console.log('getGroupsByCategoryId then', data);
        if (data) {
          setIsLoading(false);
          setTotalGroups(data.totalProviderGroups);
          setGroups(prevGroups => {
            const newGroups = [...prevGroups, ...data.providerGroups];
            const uniqueGroups = Array.from(new Set(newGroups.map(group => group.id))).map(id => newGroups.find(group => group.id === id));
            return uniqueGroups;
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        setGroups([]);
        Message.show('Sorry, There was a problem getting regions. Please try again.');
      });
  };

  const loadMoreGroups = (categoryId: string) => {
    console.log('loadMoreGroups');
    if (hasMoreGroups && !isLoading) {
      getGroupsByCategoryId(categoryId);
    }
  };

  const getAccountsByRegionId = (categoryId: string) => {
    setAccountsPerRegion([]);
    setTotalAccountsPerRegion(0);
    httpApi
      .get(`/providers/${authStore.getProviderID()}/groups/${categoryId}/accounts`)
      .then(({ data }) => {
        console.log('getAccountsByRegionId', data);
        setIsLoading(false);
        if (data?.providerGroupAccounts) {
          setAccountsPerRegion(data.providerGroupAccounts);
          setTotalAccountsPerRegion(data?.totalProviderGroupAccounts);
        } else {
          setAccountsPerRegion([]);
          setTotalAccountsPerRegion(0);
        }
      })
      .catch(() => {
        setTotalAccountsPerRegion(0);
        setAccountsPerRegion([]);
        Message.show('Sorry, There was a problem getting accounts. Please try again.');
      });
  };

  const removeGroupMember = (groupId: string) => {
    return httpApi.delete(`/providers/${authStore.getProviderID()}/groups/${groupId}`);
  };

  return {
    groups,
    getGroupsByCategoryId,
    isLoading,
    getAccountsByRegionId,
    accountsPerRegion,
    totalAccountsPerRegion,
    removeGroupMember,
    loadMoreGroups,
    hasMoreGroups,
    totalGroups,
  };
};

export default useRegions;
