import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import NucleusSearchInput from 'components/NucleusSearchInput';
import NucleusTable from 'components/NucleusTable';
import { UIModal } from 'components/UI';

import { UIAvatar } from 'components/UI/Avatar';
import { UICheckbox } from 'components/UI/Checkbox';
import { ModalAction } from 'components/UI/Modals/Base/ActionButtons';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import authStore from 'stores/AuthStore';
import Message from 'utils/Message';
import { AssignedPatient } from './UserAssignedPatients';

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  patientsList: AssignedPatient[];
  fetchUserAssignedPatients: () => void;
  accessAllPatients: boolean;
}

const TITLES = {
  assign: 'Edit Assignments',
};

const EditUserAssignedPatients: FC<Props> = ({ closeModal, isOpen, patientsList, fetchUserAssignedPatients, accessAllPatients }) => {
  const [title, setTitle] = useState('');
  const [searchText, setSearchText] = useState<string>('');
  const [titleError, setTitleError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [previousSelectedPatients, setPreviousSelectedPatients] = useState<{ PatientID: string; Assigned: boolean }[]>([]);
  const [selectedPatients, setSelectedPatients] = useState<{ PatientID: string; Assigned: boolean }[]>([]);
  const params = useParams<{
    userId: string;
  }>();

  useEffect(() => {
    if (titleError) {
      setTitleError('');
    }
  }, [title]);

  useEffect(() => {
    const onlyAssignedPatientsList = patientsList
      .filter(p => p.assignedToUser)
      .map(p => ({ PatientID: p.id, Assigned: p.assignedToUser }));
      setSelectedPatients(onlyAssignedPatientsList.map(p => ({ ...p })));
      setPreviousSelectedPatients(onlyAssignedPatientsList.map(p => ({ ...p })));
    () => {
      setSelectedPatients([]);
      setPreviousSelectedPatients([]);
    };
  }, [patientsList]);

  const onPatientSelected = (patient: AssignedPatient) => {
    const patientId = patient.id;
    const selected = selectedPatients.find(p => p.PatientID == patientId);
    if (selected) {
      const index = selectedPatients.indexOf(selected);
      const newSelectedPatients = [...selectedPatients];
      newSelectedPatients[index].Assigned = !newSelectedPatients[index].Assigned;
      setSelectedPatients(newSelectedPatients);
      return false;
    } else {
      setSelectedPatients(prevState => [...prevState, { PatientID: patientId, Assigned: true }]);
      return true;
    }
  };

  const saveAssignment = useCallback(() => {
    const onlyModifiedPatients = selectedPatients.filter(sp => {
      const previous = previousSelectedPatients.find(pp => pp.PatientID === sp.PatientID);
      return !previous || previous.Assigned !== sp.Assigned;
    });
    setIsLoading(true);
    httpApi
      .patch(`user/${params?.userId}/patientsAssignment`, { patients: onlyModifiedPatients })
      .then(() => {
        fetchUserAssignedPatients();
        setIsLoading(false);
        closeModal();
        Message.show(`${authStore.getPatientLabelPlural()} assignment saved successfully`);
      })
      .catch(() => {
        setIsLoading(false);
        Message.show(`${authStore.getPatientLabelPlural()} assignment failed`);
      });
  }, [params.userId, selectedPatients]);

  const submit = () => {
    saveAssignment();
  };

  const handleCloseModal = () => {
    closeModal();
    setTitle('');
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: handleCloseModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Save',
        onClick: submit,
        disabled: isLoading,
      },
    ];
    return data;
  };
  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Name',
        accessor: 'FirstName',
        Cell: ({ row }) => {
          return (
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <UIAvatar size="x-small" src={row.original?.Avatar} alt="avatar" />
              <span style={{ marginLeft: 5 }}>
                {row.original.firstName} {row.original.lastName}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Group',
        accessor: 'LastName',
      },
      {
        Header: 'Access',
        accessor: 'PatientID',
        showSort: false,
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>
                <UICheckbox
                  disabled={accessAllPatients}
                  checked={selectedPatients.find(p => p.PatientID == row.original.id)?.Assigned || false}
                  onClick={() => onPatientSelected(row.original)}
                />
              </span>
            </div>
          );
        },
      },
    ];
    return cols;
  }, [selectedPatients, patientsList]);

  return (
    <>
      <UIModal size="large" isOpen={isOpen} close={handleCloseModal} title={TITLES['assign']} actions={getActions()}>
        <div>
          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
            <NucleusSearchInput
              onChange={e => {
                setSearchText(e.target.value);
              }}
              value={searchText}
              inputContainerWidth={'100%'}
              placeholder={`Filter by ${authStore.getPatientLabel()} Name`}
            />
          </div>
          <div
            style={{
              maxHeight: '60vh',
              overflowY: 'scroll',
            }}
          >
            <NucleusTable
              stickyThead
              data={patientsList.filter(p => p.firstName?.toLowerCase()?.includes(searchText?.toLowerCase()) || p.lastName?.toLowerCase()?.includes(searchText?.toLowerCase()))}
              columns={columns}
            />
          </div>
        </div>
      </UIModal>
    </>
  );
};

export default EditUserAssignedPatients;
