import { debounce } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
//@ts-ignore
import AutoComplete from 'react-autocomplete';
import styled from 'styled-components';
import { ProviderPatient } from '../providerSchedule.types';

const SearchInput = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #fff !important;
  font-family: Barlow;
`;

const SearchResultRow = styled.div`
  padding: 10px;
  background-color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-family: Barlow;
`;

const SearchResultsList = styled.div`
  border: 1px solid #f0f0f0;
  background-color: #fff;
  z-index: 1000;
  border-radius: 5px;
  font-family: Barlow;
  max-height: 300px;
  overflow-y: scroll;
`;

const SearchResultTitle = styled.div`
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 500;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: Barlow;
`;

export const ProviderPatientSearchAutoComplete = ({
  onSearch,
  results,
  onSelect,
  showAccount,
}: {
  onSearch: (value: string) => void;
  results: ProviderPatient[];
  onSelect: (patient: ProviderPatient) => void;
  showAccount?: boolean;
}) => {
  const [value, setValue] = useState('');

  const serchDebounce = useRef(
    debounce((value: string) => {
      onSearch(value);
    }, 500),
  );

  useEffect(() => {
    serchDebounce.current(value);
  }, [value]);

  return (
    <AutoComplete
      getItemValue={(item: ProviderPatient) => item.PatientID}
      items={results}
      renderInput={props => <SearchInput className="ui-nucleus" {...props} />}
      renderItem={(item: ProviderPatient, isHighlighted) => (
        <SearchResultRow key={item.PatientID} style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
          {item.PatientName} {showAccount && <span style={{ color: '#999' }}> - {item.AccountName}</span>}
        </SearchResultRow>
      )}
      renderMenu={(items, value, style) => {
        if (!value) {
          return <div style={{ ...style, backgroundColor: '#fff' }}></div>;
        }
        return (
          <SearchResultsList style={{ ...style }}>
            <SearchResultTitle>Select from the options below</SearchResultTitle>
            {items}
          </SearchResultsList>
        );
      }}
      wrapperStyle={{
        width: '100%',
        zIndex: 1000,
      }}
      placeholder="Start typing to see a list of patients"
      value={value}
      inputProps={{
        placeholder: 'Start typing to see a list of patients',
      }}
      onChange={e => setValue(e.target.value)}
      onSelect={identifierId => {
        const patient = results.find(p => p.PatientID === identifierId);
        if (!patient) return;

        onSelect(patient);
        setValue(patient?.PatientName || '');
      }}
    />
  );
};
