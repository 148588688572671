import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { connect } from 'react-redux';
import { JQuery } from 'types/jquery.types';
import AuthStore from '../../stores/AuthStore';

import CarePatientStore from '../../stores/CarePatientStore';
import GeneralStore from '../../stores/GeneralStore';

class PatientItemRow extends React.Component<
  {
    ID: string;
    PatientID: string;
    FirstName: string;
    LastName: string;
    Telephone: string;
    Address: string;
    DefaultDeviceID: string;
    DefaultDeviceName: string;
    DefaultDeviceInfo: string;
    PatientDevices: string[];
    IsOnline: boolean;
    PatientTotalDevices: number;
    PatientOnlineDevices: number;
    FamilyMembersActive: number;
    FamilyMembers: number;
    MemoryBoxItems: number;
    ImageThumb: string;
    AccountEnableAutoAnswer: boolean;
    BargeInEnabled: boolean;
    shortcuts: boolean;
  },
  {
    patientID: string;
    patientName: string;
    status: boolean;
    redirect: boolean;
  }
> {
  imgRef: HTMLImageElement;
  isConnectDevice: boolean;

  constructor(props) {
    super(props);
    this.state = {
      patientID: this.props.PatientID,
      patientName: this.props.FirstName + ' ' + this.props.LastName,
      status: this.props.IsOnline,
      redirect: false,
    };
    //console.log(this.state.patientID);
    this.handleRowClicked = this.handleRowClicked.bind(this);
    this.handleDeletePatient = this.handleDeletePatient.bind(this);
    this.isConnectDevice = true;
  }

  componentDidMount() {
    //console.log(this.state.patientID);
    this.setState({
      patientID: this.props.PatientID,
    });
    if (this.props.DefaultDeviceID) {
      try {
        const deviceInfo = JSON.parse(this.props.DefaultDeviceInfo);
        //console.log("this.props.DefaultDeviceID ----->", deviceInfo);
        if (deviceInfo.brand == 'goldtek') {
          this.isConnectDevice = false;
        }
      } catch (error) {
        console.error('this.props.DefaultDeviceID ----->');
      }
    }
  }

  componentWillUnmount() {
    ($('.tooltipped') as unknown as JQuery).tooltip('remove');
  }

  handleRowClicked() {
    GeneralStore.setClientTab(1);
    GeneralStore.setPageBeforePatient('patients');
    console.log('going->', '/patientProfile/' + this.props.PatientID + '/null');

    //@ts-ignore
    this.props.navigate('/patientProfile/' + this.props.PatientID + '/null');
    //window.location.assign('/patientProfile/' + this.props.PatientID + '/null');
    // <Navigate
    //       to={'/patientProfile/' + this.props.PatientID + '/null'}
    //       state={{
    //           from: location,
    //       }}
    //     />
  }

  handleDeletePatient = () => {
    GeneralStore.openDeletePatientModal({
      PatientID: this.props.PatientID,
    });
  };

  prepareCall = () => {
    const callData = {
      Devices: this.props.PatientDevices?.map(deviceId => ({
        id: deviceId.toLowerCase(),
        name: '',
      })),
      PatientID: this.props.PatientID,
      ConnectDevice: this.isConnectDevice, //this.props.DefaultDeviceInfo,
    };

    return callData;
  };

  handleAudioCall = e => {
    e.stopPropagation();
    console.log('handleAudioCall ', this.props.ID);
    CarePatientStore.handlePatientProfileInfoAudioCall(this.prepareCall());
    //this.props.dispatch(startCall(callData))
  };

  handleVideoCall = e => {
    e.stopPropagation();
    console.log('handleVideoCall ', this.props.ID);

    CarePatientStore.handlePatientProfileInfoVideoCall(this.prepareCall());
    //this.props.dispatch(startCall(callData))
  };

  handleBargeCall = e => {
    e.stopPropagation();
    console.log('handleBargeCall ', this.props.ID);
    CarePatientStore.handlePatientProfileInfoBargeCall(this.prepareCall());
    // this.props.dispatch(startCall(callData))
  };

  handleSilentBargeCall = e => {
    e.stopPropagation();
    console.log('handleSilentBargeCall ', this.props.ID);
    CarePatientStore.handlePatientProfileInfoSilentBargeCall(this.prepareCall());
    //this.props.dispatch(startCall(callData))
  };

  render() {
    let clientName = this.props.FirstName + ' ' + this.props.LastName;
    if (clientName != null && clientName.length > 32) {
      clientName = clientName.substring(0, 32) + ' ...';
    }

    let clientPhone = this.props.Telephone;
    if (clientPhone != null && clientPhone.length > 18) {
      clientPhone = clientPhone.substring(0, 18) + ' ...';
    }

    let clientAddress = this.props.Address;
    if (clientAddress != null && clientAddress.length > 35) {
      clientAddress = clientAddress.substring(0, 35) + ' ...';
    }

    //console.log("DEVICE INFO>>> ", this.props.DevicesInfo);
    // let deviceStatusIcon = "/img/icon_device_inactive.png";
    // let deviceStatus =  "No device assigned";

    let deviceStatusIcon = '/img/icon_no_device.png';
    let deviceStatus = 'No devices assigned to the patient';

    if (this.props.PatientTotalDevices > 0) {
      if (this.props.PatientOnlineDevices == this.props.PatientTotalDevices) {
        deviceStatusIcon = '/img/icon_device_active.png';
        deviceStatus = 'All devices are online';
      } else if (this.props.PatientOnlineDevices == 0) {
        deviceStatusIcon = '/img/icon_device_inactive.png';
        deviceStatus = 'All devices are offline';
      } else {
        deviceStatusIcon = '/img/icon_device_semi_active.png';
        deviceStatus = 'There are some devices offline';
      }
    }

    const shortcuts =
      AuthStore.nucleusVideoCallShortcut || AuthStore.nucleusAudioCallShortcut || AuthStore.nucleusBargeInCallShortcut || AuthStore.nucleusSilentBargeInCallShortcut;

    return (
      <tr id={this.props.ID} onClick={this.handleRowClicked}>
        <td className="nucleus-row">
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {/* ClientImageRow */}
            <img
              src={this.props.ImageThumb ? this.props.ImageThumb : '/img/user_placeholder.png'}
              ref={img => {
                this.imgRef = img;
              }}
              onError={() => {
                this.imgRef.src = '/img/user_placeholder.png';
              }}
              style={{
                width: 50,
                height: 50,
                marginRight: 10,
                marginLeft: 0,
                borderRadius: 25,
                objectFit: 'cover',
              }}
            />
            {clientName}
          </div>
        </td>
        {this.props.shortcuts && (
          <td
            className="nucleus-row"
            style={{
              //   display: "inline-block",
              textAlign: 'center',
            }}
          >
            {AuthStore.nucleusVideoCallShortcut && this.props.DefaultDeviceID && (
              <img
                src="/img/icon_videocall_big.png"
                className="nucleus-link tooltipped"
                data-position="top"
                data-delay="10"
                style={{
                  pointerEvents: this.props.DefaultDeviceID ? 'all' : 'none',
                  opacity: this.props.DefaultDeviceID ? 1 : 0.3,
                }}
                data-tooltip="Video Call"
                onClick={this.handleVideoCall}
              />
            )}
            {AuthStore.nucleusAudioCallShortcut && this.props.DefaultDeviceID && (
              <img
                src="/img/icon_audiocall_big.png"
                className="nucleus-link tooltipped"
                data-position="top"
                data-delay="10"
                style={{
                  pointerEvents: this.props.DefaultDeviceID ? 'all' : 'none',
                  opacity: this.props.DefaultDeviceID ? 1 : 0.3,
                }}
                data-tooltip="Audio Call"
                onClick={this.handleAudioCall}
              />
            )}
            {this.props.AccountEnableAutoAnswer && (
              <span className={'no-margin '}>
                {AuthStore.nucleusBargeInCallShortcut && this.props.DefaultDeviceID && this.props.BargeInEnabled && (
                  <img
                    src="/img/icon_new_instant_big.png"
                    className="nucleus-link tooltipped"
                    data-position="top"
                    data-delay="10"
                    style={{
                      pointerEvents: this.props.DefaultDeviceID ? 'all' : 'none',
                      opacity: this.props.DefaultDeviceID ? 1 : 0.3,
                    }}
                    data-tooltip="Auto Answer"
                    onClick={this.handleBargeCall}
                  />
                )}
                {AuthStore.nucleusSilentBargeInCallShortcut && this.props.DefaultDeviceID && this.props.BargeInEnabled && (
                  <img
                    src="/img/icon_new_silent_instant_big.png"
                    className="nucleus-link tooltipped"
                    data-position="top"
                    style={{
                      pointerEvents: this.props.DefaultDeviceID ? 'all' : 'none',
                      opacity: this.props.DefaultDeviceID ? 1 : 0.3,
                    }}
                    data-delay="10"
                    data-tooltip="Silent Auto Answer"
                    onClick={this.handleSilentBargeCall}
                  />
                )}
              </span>
            )}
          </td>
        )}
        <td className="nucleus-row">{clientPhone}</td>
        {!shortcuts && <td className="nucleus-row">{clientAddress}</td>}
        <td
          style={{
            textAlign: 'center',
          }}
          className="nucleus-row"
        >
          {this.props.PatientTotalDevices}
        </td>
        <td className="nucleus-row center-align">{this.props.FamilyMembersActive + ' / ' + this.props.FamilyMembers}</td>
        <td className="nucleus-row center-align">{this.props.MemoryBoxItems}</td>
        <td className="center-align">
          <img className=" tooltipped " src={deviceStatusIcon} data-position="left" data-delay="50" data-tooltip={deviceStatus} />
        </td>
        <style jsx={true}>{`
          td img {
            margin-left: 10px;
          }
        `}</style>
      </tr>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

const PatientItemRowFC = props => {
  return useReactRouterProps(PatientItemRow, props);
};

export default connect(undefined, mapDispatchToProps)(PatientItemRowFC);
