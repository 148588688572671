import { startCall } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import { CallingMethods } from 'components/WebRTCCallView';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { connect } from 'react-redux';

import { AuthStore, GeneralStore, CareCommunicationStore, CarePatientStore, CareQueueStore } from '../../stores';

import Message from '../../utils/Message';

import NavigationListener from '../NavigationListener';

class TopBar extends React.Component {
  private componentAlive: boolean;
  private getCountTimeout: any;
  private reproduceAlertTimeout: any;
  private flashAlertTimeout: any;
  private currentPath: string;
  private alertSound: any;
  private ringingStatus: boolean;
  private dateOptions: Intl.DateTimeFormatOptions;
  private timeOptions: Intl.DateTimeFormatOptions;

  constructor(props) {
    super(props);
    this.componentAlive = true;

    this.getCountTimeout = null;
    this.reproduceAlertTimeout = null;
    this.flashAlertTimeout = null;

    this.state = {
      active: true,
      backVisibility: ' hide',
      pendingRequestExist: false,
      callInProgress: false,
      queueItems: 0,
      topBarStyle: ' ',
      incomingCallBarStyle: ' hide',
      incomingCallFlashingBarStyle: ' bar-yellow ',
      pendingRequestID: '',
      pendingRequestTimeString: '',
      pendingRequestDateString: '',
      pendingRequestPatientID: '',
      pendingRequestPatientName: '',
      pendingRequestEmergency: false,
      handlePendingCallBtnStyle: ' nucleus-submit-btn',

      loadingVisibility: ' hide',
    };

    this.currentPath = '';

    this.handleBack = this.handleBack.bind(this);
    this.playAlert = this.playAlert.bind(this);
    this.stopAlert = this.stopAlert.bind(this);
    this.reproduceAlert = this.reproduceAlert.bind(this);
    this.startFlashingBar = this.startFlashingBar.bind(this);

    this.onRequestHandleBackAction = this.onRequestHandleBackAction.bind(this);
    this.handleBackVisibility = this.handleBackVisibility.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);

    this.onGetQueueSizeAction = this.onGetQueueSizeAction.bind(this);
    this.onQueueChangedAction = this.onQueueChangedAction.bind(this);
    this.onStopNotificationSoundAction = this.onStopNotificationSoundAction.bind(this);
    this.onGetAllPatientsDataHashMapAction = this.onGetAllPatientsDataHashMapAction.bind(this);
    this.handleIncomingCall = this.handleIncomingCall.bind(this);
    this.handleCloseIncomingCallBar = this.handleCloseIncomingCallBar.bind(this);

    this.onReportCallHandledAction = this.onReportCallHandledAction.bind(this);

    this.onRefreshIncomingCallBarStatusAction = this.onRefreshIncomingCallBarStatusAction.bind(this);
    this.onUpdateCallInProgressAction = this.onUpdateCallInProgressAction.bind(this);

    this.onShowLoadingAction = this.onShowLoadingAction.bind(this);
    this.onHideLoadingAction = this.onHideLoadingAction.bind(this);
  }

  componentDidMount() {
    GeneralStore.on('onRequestHandleBack', this.onRequestHandleBackAction);
    CareQueueStore.on('onGetQueueSize', this.onGetQueueSizeAction);
    CareQueueStore.on('onQueueChanged', this.onQueueChangedAction);
    CareQueueStore.on('onStopNotificationSound', this.onStopNotificationSoundAction);
    CareCommunicationStore.on('onReportCallHandled', this.onReportCallHandledAction);
    CarePatientStore.on('onGetAllPatientsDataHashMap', this.onGetAllPatientsDataHashMapAction);

    CareCommunicationStore.on('onRefreshIncomingCallBarStatus', this.onRefreshIncomingCallBarStatusAction);
    CareCommunicationStore.on('onUpdateCallInProgress', this.onUpdateCallInProgressAction);

    GeneralStore.on('onShowLoading', this.onShowLoadingAction);
    GeneralStore.on('onHideLoading', this.onHideLoadingAction);

    if (AuthStore.getControlIncomingCall().toString() == 'true') {
      CareQueueStore.getPendingRequest();
      this.reproduceAlert();
      this.startFlashingBar();
    }

    this.alertSound = new Audio('https://app-dev.nucleusportal.com/audio/ding_alert_sound.mp3');
    this.alertSound.muted = false;
    this.ringingStatus = false;

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      //this.datetimeFormat = "m/d/Y h:i A";
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      //this.datetimeFormat = "m/d/Y H:i";
      //this.timeFormat = "HH:mm A";
      this.timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h23',
      };
    }
  }

  cancelRingTimeouts = () => {
    if (this.getCountTimeout) {
      clearTimeout(this.getCountTimeout);
      this.getCountTimeout = null;
    }
    if (this.reproduceAlertTimeout) {
      clearTimeout(this.reproduceAlertTimeout);
      this.reproduceAlertTimeout = null;
    }
    if (this.flashAlertTimeout) {
      clearTimeout(this.flashAlertTimeout);
      this.flashAlertTimeout = null;
    }
  };

  componentWillUnmount() {
    console.log('componentWillUnmount ************** TopBar.js');
    this.cancelRingTimeouts();
    this.stopAlert();

    GeneralStore.removeListener('onRequestHandleBack', this.onRequestHandleBackAction);
    CareQueueStore.removeListener('onGetQueueSize', this.onGetQueueSizeAction);
    CareQueueStore.removeListener('onQueueChanged', this.onQueueChangedAction);
    CareQueueStore.removeListener('onStopNotificationSound', this.onStopNotificationSoundAction);
    CareCommunicationStore.removeListener('onReportCallHandled', this.onReportCallHandledAction);
    CarePatientStore.removeListener('onGetAllPatientsDataHashMap', this.onGetAllPatientsDataHashMapAction);

    CareCommunicationStore.removeListener('onRefreshIncomingCallBarStatus', this.onRefreshIncomingCallBarStatusAction);
    CareCommunicationStore.removeListener('onUpdateCallInProgress', this.onUpdateCallInProgressAction);

    GeneralStore.removeListener('onShowLoading', this.onShowLoadingAction);
    GeneralStore.removeListener('onHideLoading', this.onHideLoadingAction);

    this.setState({
      active: false,
    });

    this.componentAlive = false;
  }

  handleNavigation(previousLocation, currentLocation) {
    console.log('Topbar handleNavigation', previousLocation, currentLocation);
    if (previousLocation?.pathname) {
      this.handleBackVisibility(previousLocation?.pathname);
    }
  }

  handleBackVisibility(currentPath) {
    console.log('PAGE 2: ', currentPath);
    const pages = [
      'userProfile',
      'newUser',
      'patientProfile',
      'newPatient',
      'caregiverProfile',
      'newCaregiver',
      'newMessage',
      'newFamilyMember',
      'newAssignedPatient',
      'reports/calls',
    ];
    let showBack = false;
    for (let i = 0; i < pages.length; i++) {
      showBack = currentPath.includes(pages[i]);
      if (showBack) {
        break;
      }
    }
    this.setState({
      backVisibility: showBack ? ' ' : ' hide',
    });
    this.currentPath = currentPath;
  }

  handleBack() {
    if (GeneralStore.getProfileMode() == 'Edit') {
      GeneralStore.cancelEditProfileMode();
    } else if (this.currentPath.includes('patientProfile')) {
      this.props.history.push('/' + GeneralStore.getPageBeforePatient());
    } else {
      this.props.history.navigate(-1);
      //this.history.goBack();
    }
  }

  onRequestHandleBackAction() {
    this.handleBack();
  }

  onGetQueueSizeAction(data) {
    console.log('onGetQueueSizeAction', data);

    if (AuthStore.getControlIncomingCall().toString() == 'true' && AuthStore.getOnDuty().toString() == 'true' && !AuthStore.isProvider()) {
      this.setState({
        pendingRequestExist: data.request ? true : false,
        topBarStyle: data.request ? ' hide' : ' ',
        incomingCallBarStyle: data.request ? ' ' : ' hide',
      });
      if (data.request) {
        if (data.request.RequestID != this.state.pendingRequestID) {
          this.setState({
            pendingRequestID: data.request.RequestID,
            pendingRequestTimeString: new Date(data.request.RequestTime).toLocaleTimeString('en-US', this.timeOptions),
            pendingRequestDateString:
              new Date(data.request.RequestTime).toLocaleDateString() + ' ' + new Date(data.request.RequestTime).toLocaleTimeString('en-US', this.timeOptions),
            pendingRequestPatientID: data.request.PatientID,
            pendingRequestPatientName: data.request.Name,
            patientFirstName: data.request.PatientFirstName,
            patientLastName: data.request.PatientLastName,
            pendingRequestPatientDeviceID: data.request.DeviceID,
            pendingRequestPatientDeviceName: data.request.DeviceName,
            ConnectDevice: data.request.ConnectDevice,
            pendingRequestEmergency: data.request.IsEmergency,
            handlePendingCallBtnStyle: ' nucleus-submit-btn',
          });
          //console.log("QueueSize PlayAlert from onGetQueueSizeAction");
          this.playAlert();
          if (!data.request.Name) {
            const patientInfo = CarePatientStore.getPatientInfo(data.request.PatientID);
            //console.log( "PatientINFO: ", patientInfo);
            if (patientInfo) {
              this.setState({
                pendingRequestPatientName: patientInfo.Name,
              });
            }
          }
        }
        this.getCountTimeout = setTimeout(() => {
          CareQueueStore.getPendingRequest();
        }, 10000);
        //console.log("onGetQueueSizeAction PUNTO 1");
      } else {
        if (this.componentAlive) {
          this.getCountTimeout = setTimeout(() => {
            CareQueueStore.getPendingRequest();
          }, 10000);
        }

        if (this.ringingStatus) {
          console.log('onGetQueueSizeAction PUNTO 2');
          this.stopAlert();
        }
      }
    } else if (AuthStore.getOnDuty().toString() == 'false' && this.state.pendingRequestExist) {
      this.setState({
        //pendingRequestExist : data.request ? true : false,
        topBarStyle: ' ',
        incomingCallBarStyle: ' hide',
      });
      if (this.ringingStatus) {
        this.stopAlert();
      }
    }
  }

  onQueueChangedAction(data) {
    //console.log("onQueueChangedAction", data);

    if (AuthStore.getControlIncomingCall().toString() == 'true' && AuthStore.getOnDuty().toString() == 'true' && !AuthStore.isProvider()) {
      this.setState({
        pendingRequestExist: data.request ? true : false,
        topBarStyle: data.request ? ' hide' : ' ',
        incomingCallBarStyle: data.request ? ' ' : ' hide',
      });
      if (data.request) {
        console.log('onQueueSizeChangedAction FLOW 1');
        console.log('data.request.RequestID', data.request.RequestID);
        console.log('this.state.pendingRequestID', this.state.pendingRequestID);
        if (data.request.RequestID != this.state.pendingRequestID) {
          console.log('onQueueSizeChangedAction FLOW 1.1');
          //this.state.pendingRequestEmergency = data.request.IsEmergency;

          if (this.state.pendingRequestEmergency != data.request.IsEmergency) {
            this.stopAlert();
          }

          this.setState({
            pendingRequestID: data.request.RequestID,
            pendingRequestTimeString: new Date(data.request.RequestTime).toLocaleTimeString('en-US', this.timeOptions),
            pendingRequestDateString:
              new Date(data.request.RequestTime).toLocaleDateString() + ' ' + new Date(data.request.RequestTime).toLocaleTimeString('en-US', this.timeOptions),
            pendingRequestPatientID: data.request.PatientID,
            pendingRequestPatientName: data.request.Name,
            patientFirstName: data.request.PatientFirstName,
            patientLastName: data.request.PatientLastName,
            pendingRequestPatientDeviceID: data.request.DeviceID,
            pendingRequestPatientDeviceName: data.request.DeviceName,
            ConnectDevice: data.request.ConnectDevice,
            pendingRequestEmergency: data.request.IsEmergency,
            handlePendingCallBtnStyle: ' nucleus-submit-btn',
          });
          //console.log("QueueSize PlayAlert from onQueueChangedAction");
          this.playAlert();

          if (!data.request.Name) {
            const patientInfo = CarePatientStore.getPatientInfo(data.request.PatientID);
            //console.log(patientInfo);
            if (patientInfo) {
              this.setState({
                pendingRequestPatientName: patientInfo.Name,
              });
            }
          }
        } else {
          console.log('onQueueSizeChangedAction FLOW 1.11');
        }
      } else {
        console.log('onQueueSizeChangedAction FLOW 1.2', this.ringingStatus);
        if (this.ringingStatus) {
          console.log('onQueueSizeChangedAction PUNTO 2');
          this.stopAlert();
        }
      }
    } else if (AuthStore.getOnDuty().toString() == 'false' && this.state.pendingRequestExist) {
      this.setState({
        //pendingRequestExist : data.request ? true : false,
        topBarStyle: ' ',
        incomingCallBarStyle: ' hide',
      });
      if (this.ringingStatus) {
        this.stopAlert();
      }
    }
  }

  onGetAllPatientsDataHashMapAction() {
    //console.log("onQueueSizePatientsHashMap");
    const patientInfo = CarePatientStore.getPatientInfo(this.state.pendingRequestPatientID);
    if (patientInfo) {
      this.setState({
        pendingRequestPatientName: patientInfo.Name,
      });
    }
  }

  playAlert() {
    console.log('playAlert()!');
    if (this.state.pendingRequestExist && !this.state.callInProgress && !this.ringingStatus && AuthStore.getOnDuty().toString() == 'true') {
      console.log('this.state.pendingRequestExist', this.state.pendingRequestExist);
      console.log('this.state.callInProgress', this.state.callInProgress);
      console.log('this.ringingStatus', this.ringingStatus);
      console.log('this.state.pendingRequestEmergency', this.state.pendingRequestEmergency);
      this.ringingStatus = true;
      if (this.state.pendingRequestEmergency && AuthStore.getUsePanic().toString() == 'true') {
        // if (this.alertSound == undefined){
        //    			this.alertSound = new Audio('https://care-dev.nucleuslife.io/control/audio/ding_alert_sound.mp3');
        //  		} else{
        //  			this.alertSound.pause();
        //        		this.alertSound.currentTime = 0;
        //  		}
        ////console.log("QueueSize PlayAlert 1");
        if (this.alertSound) this.alertSound.pause();
        this.alertSound = null;
        this.alertSound = new Audio(
          '/audio/PanicEmergencyRingtone.mp3',
          //"https://care.nucleuslife.io/control/audio/PanicEmergencyRingtone.mp3"
        );
        this.alertSound.currentTime = 0;
        this.alertSound.loop = false;

        console.log('playAlert1');
      } else if (AuthStore.getUseNucleusRing().toString() == 'true') {
        //console.log("QueueSize PlayAlert 2");
        if (this.alertSound) this.alertSound.pause();
        this.alertSound = null;
        this.alertSound = new Audio('/callSound/ring.mp3');
        this.alertSound.currentTime = 0;
        this.alertSound.loop = false;

        console.log('playAlert2');
      } else {
        //console.log("QueueSize PlayAlert 3");
        if (this.alertSound) this.alertSound.pause();
        this.alertSound = null;
        this.alertSound = new Audio(
          '/audio/ding_alert_sound.mp3',
          //"https://care-dev.nucleuslife.io/control/audio/ding_alert_sound.mp3"
        );
        this.alertSound.currentTime = 0;
        this.alertSound.loop = false;
        console.log('playAlert3');
      }
      try {
        //this.alertSound.autoplay = true;
        // this.alertSound.pause();
        // this.alertSound.play();
        this.alertSound.addEventListener('canplay', () => {
          if (this.ringingStatus) {
            this.alertSound.play();
            clearTimeout(this.flashAlertTimeout);
            this.flashAlertTimeout = setTimeout(() => {
              if (this.componentAlive) {
                this.startFlashingBar();
              }
            }, 1000);
          }
        });
      } catch (ex) {
        console.log('Auch');
      }
    } else {
      console.log('playAlert()! else');
      //console.log(this.state.pendingRequestExist);
      //console.log(this.state.callInProgress);
      //console.log(this.ringingStatus);
      //console.log(AuthStore.getOnDuty().toString());
    }
  }

  reproduceAlert() {
    //console.log("QueueSize PlayAlert from reproduceAlert");
    this.playAlert();

    if (this.state.pendingRequestEmergency && AuthStore.getUsePanic().toString() == 'true') {
      this.reproduceAlertTimeout = setTimeout(() => {
        this.ringingStatus = false;
        this.reproduceAlert();
      }, 41000);
      CareQueueStore.generateNotification();
    } else if (AuthStore.getUseNucleusRing().toString() == 'true') {
      this.reproduceAlertTimeout = setTimeout(() => {
        this.ringingStatus = false;
        this.reproduceAlert();
      }, 5000);
    } else {
      this.reproduceAlertTimeout = setTimeout(() => {
        this.ringingStatus = false;
        this.reproduceAlert();
      }, 60000);
      CareQueueStore.generateNotification();
    }
  }

  stopAlert() {
    console.log('TopBar StopAlert');
    if (this.alertSound) {
      this.alertSound.pause();
      this.alertSound.currentTime = 0;
      this.ringingStatus = false;
      this.alertSound = null;
    }
  }

  onStopNotificationSoundAction() {
    this.stopAlert();
  }

  startFlashingBar() {
    //console.log("startFlashingBar");
    if (this.state.pendingRequestExist) {
      if (this.state.pendingRequestEmergency) {
        if (this.state.incomingCallFlashingBarStyle == ' bar-red ') {
          this.setState({
            incomingCallFlashingBarStyle: ' bar-gray ',
          });
        } else {
          this.setState({
            incomingCallFlashingBarStyle: ' bar-red ',
          });
        }
      } else {
        if (this.state.incomingCallFlashingBarStyle == ' bar-yellow ') {
          this.setState({
            incomingCallFlashingBarStyle: ' bar-gray ',
          });
        } else {
          this.setState({
            incomingCallFlashingBarStyle: ' bar-yellow ',
          });
        }
      }
    }
    if (this.componentAlive) {
      clearTimeout(this.flashAlertTimeout);
      this.flashAlertTimeout = setTimeout(() => {
        if (this.componentAlive) {
          this.startFlashingBar();
        }
      }, 1000);
    }
  }

  handleIncomingCall() {
    console.log('INCOMING CALL', this.state.pendingRequestID);
    CareQueueStore.markRequestAddressed(this.state.pendingRequestID);
    this.stopAlert();

    this.setState({
      callInProgress: true,
      handlePendingCallBtnStyle: ' nucleus-submit-btn-disabled',
    });

    const patientName = this.state.patientFirstName + ' ' + this.state.patientLastName;
    const deviceId = this.state.pendingRequestPatientDeviceID.toLowerCase();
    const patientId = this.state.pendingRequestPatientID.toLowerCase();
    const participants: FullCallEntity[] = [
      {
        devicesIds: [deviceId],
        name: patientName,
        entityId: patientId,
        host: false,
        status: 'connected',
        type: CallType.Video,
      },
    ];

    const callData: WebRTCCall = {
      requestId: this.state.pendingRequestID,
      patientId: patientId,
      callingMethod: CallingMethods.Call,
      participants,
      multiCall: false,
      patientFirstName: this.state.patientFirstName,
      patientLastName: this.state.patientLastName,
      patientName,
      deviceName: this.state.pendingRequestPatientDeviceName,
      patientThumb: '',
      type: CallType.Video,
    };

    this.props.dispatch(startCall(callData));
  }

  onReportCallHandledAction(response) {
    console.log('onReportCallHandledAction', response);

    this.setState({
      handlePendingCallBtnStyle: ' nucleus-submit-btn',
    });

    if (response.ok) {
      console.log('REQUEST HANDLED ', response.requestID);
      CareQueueStore.removeRequestFromQueue(response.requestID);

      CareQueueStore.getPendingRequest();
      this.setState({
        topBarStyle: ' ',
        incomingCallBarStyle: ' hide',
        pendingRequestExist: false,
      });
    } else {
      Message.show('Error canceling the call request, please try again.');
    }
  }

  onRefreshIncomingCallBarStatusAction() {
    this.setState({
      handlePendingCallBtnStyle: ' nucleus-submit-btn',
    });
  }

  onUpdateCallInProgressAction(value) {
    if (this.state.callInProgress != value) {
      if (value) {
        this.stopAlert();
      } else {
        //console.log("QueueSize PlayAlert from onUpdateCallInProgressAction");
        this.playAlert();
      }
    }
    this.setState({
      callInProgress: value,
    });
  }

  handleCloseIncomingCallBar() {
    this.setState({
      topBarStyle: ' ',
      incomingCallBarStyle: ' hide',
      pendingRequestExist: false,
    });
  }

  onShowLoadingAction() {
    this.setState({
      loadingVisibility: ' ',
    });
  }

  onHideLoadingAction() {
    this.setState({
      loadingVisibility: ' hide',
    });
  }

  render() {
    return (
      <div className="nucleus-topbar">
        <div className={'row alto ancho top-bar-container ' + this.state.topBarStyle} style={{ backgroundColor: '#f0f0f0' }}>
          <div className="col s4 m4 left-align alto valign-wrapper">
            <a className={'nucleus-link ' + this.state.backVisibility} onClick={this.handleBack}>
              &nbsp;&nbsp;&nbsp;
              <img className="responsive-img" src="/img/back_arrow.png" />
              &nbsp;&nbsp;&nbsp;
              <span className="nucleus-font nucleus-font-family-medium"> Back</span>
            </a>
          </div>
          <div className="col s4 m4 center-align alto" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span className="nucleus-font nucleus-font-family-medium"> CONTROL</span>
          </div>
          <div className="col s4 m4 alto" style={{ fontSize: 16, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <span className="nucleus-font nucleus-font-family-medium">{AuthStore.getUserEmail()}</span>
          </div>
        </div>

        <div className={'row alto ancho incoming-call-container ' + this.state.incomingCallFlashingBarStyle + this.state.incomingCallBarStyle}>
          <span className="nucleus-page-subtitle-special">
            {this.state.pendingRequestTimeString} &nbsp;&nbsp; Incoming call from &nbsp;&nbsp; {this.state.pendingRequestPatientName}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a className={'txt-white nucleus-font-family-big ' + this.state.handlePendingCallBtnStyle} onClick={this.handleIncomingCall}>
            Accept Call
          </a>
          {/*  <img className="responsive-img img-close-modal close-incoming-call" src="/img/close_modal.png" onClick={this.handleCloseIncomingCallBar}/> */}
        </div>
        <NavigationListener onNavigationLocationChange={this.handleNavigation}></NavigationListener>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch,
  };
};

const TopBarFC = props => {
  return useReactRouterProps(TopBar, props);
};
export default connect(undefined, mapDispatchToProps)(TopBarFC);
