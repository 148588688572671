import React, { useEffect } from 'react';
import { AuthStore, GeneralStore, CarePatientStore } from 'stores';

const CarePatientNoteItemRow: React.FC<{
  index: number;
  NoteID: number;
  CreatedDate: string;
  User: string;
  UserID: string;
  Type: string;
  Note: string;
  PatientID: string;
  TimeZone: string;
}> = props => {
  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions: Intl.DateTimeFormatOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

  useEffect(() => {
    console.log('NOTE ID ', props.NoteID);
    if (AuthStore.getTime12Hrs().toString() === 'true') {
      timeOptions.hour12 = true;
      timeOptions.hour = '2-digit';
      timeOptions.minute = '2-digit';
    } else {
      timeOptions.hour = '2-digit';
      timeOptions.minute = '2-digit';
      timeOptions.hourCycle = 'h23';
    }
  }, []);

  const handleRowClicked = () => {
    const createdDate = new Date(props.CreatedDate);

    console.log('Note Item clicked, Type: ', props.Type);

    if (props.Type === 'General') {
      GeneralStore.requestOpenEditNotesModal({
        NoteID: props.NoteID,
        Note: props.Note,
        UserID: props.UserID.toUpperCase(),
        User: props.User,
        Type: props.Type,
        DateTime: createdDate.toLocaleDateString('en-US', dateOptions) + ' ' + createdDate.toLocaleTimeString('en-US', timeOptions) + ' - ' + props.TimeZone,
      });
    } else if (props.Type === 'Outgoing Call') {
      CarePatientStore.getCallLogDetailsData({
        LogID: props.NoteID,
        PatientID: props.PatientID,
        TimeZone: props.TimeZone,
      });
    } else if (props.Type === 'Call Request') {
      CarePatientStore.getCallRequestLogDetailsData({
        ID: props.NoteID,
        PatientID: props.PatientID,
        PatientTimeZone: props.TimeZone,
      });
    }
  };

  let shortMessage = props.Note;
  if (shortMessage != null && shortMessage.length > 65) {
    shortMessage = shortMessage.substring(0, 65) + ' ...';
  }

  return (
    <tr id={`note-${props.index}`} onClick={handleRowClicked}>
      <td className="nucleus-row">{props.CreatedDate + ' - ' + props.TimeZone}</td>
      <td className="nucleus-row">{props.User}</td>
      <td className="nucleus-row">{props.Type}</td>
      <td className="nucleus-row">{shortMessage}</td>
    </tr>
  );
};

export default CarePatientNoteItemRow;
