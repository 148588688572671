import NucleusSearchInput from 'components/NucleusSearchInput';
import NucleusTable from 'components/NucleusTable';
import { UIAvatar, useModal } from 'components/UI';
import { UIButton } from 'components/UI/Buttons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import authStore from 'stores/AuthStore';
import CareUserStore from 'stores/CareUserStore';
import message from 'utils/Message';
import { fetchAvailablePatients, removeUserPatient } from './assignmentActions';
import EditUserAssignedPatients from './EditUserAssignedPatients';
import { UICheckbox } from 'components/UI/Checkbox';
import { UIModal } from 'components/UI';
import { ModalAction } from 'components/UI/Modals/Base/ActionButtons';

export type AssignedPatient = {
  id: string;
  firstName: string;
  lastName: string;
  telephone: string;
  assignedToUser: boolean;
  assignedToUsersGroup: boolean;
  groupNames: string;
  thumbnailUrl: string;
};
const UserAssignedPatients = () => {
  const params = useParams<{ userId: string }>();
  const [searchText, setSearchText] = useState<string>('');
  const [UserAssignedPatients, setUserAssignedPatients] = useState<AssignedPatient[]>([]);
  const [allPatientsAccess, setAllPatientsAccess]= useState(false);
  const [allPatientsAccessModalOpen, setAllPatientsAccessModalOpen]= useState(false);
  const [allPatientsAccessModalLabel, setAllPatientsAccessModalLabel] = useState('allow');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPatients();
  }, [params?.userId, CareUserStore.getUserAllPatientsAccess()]);


  const fetchPatients = useCallback(() => {
    if (params.userId && !(CareUserStore.getUserAllPatientsAccess().toString() == 'true')) {
      fetchAvailablePatients({
        limit: 1000,
        page: 1,
        userId: params.userId,
        cb: ({ response, success }) => {
          onGetPossiblePatientsAction(response, success);
        },
      });
    }
  }, [params?.userId, CareUserStore.getUserAllPatientsAccess()]);

  const onGetPossiblePatientsAction = (response, success) => {
    if (response && success) {
      setUserAssignedPatients(response.patients);
      setAllPatientsAccess(response.allPatientsAccess);
      setAllPatientsAccessModalLabel(response.allPatientsAccess ? 'cancel' : 'allow');
    } else {
      message.show('Error getting available ' + authStore.getPatientLabelPlural().toLowerCase() + ' information');
    }
  };

  const { 
    isOpen: isEditOpen, 
    openModal: openEditModal, 
    closeModal: closeEditModal 
  } = useModal();

  const handleCanAccessAllPatientsToggle = ()=>{
    setAllPatientsAccessModalOpen(true);
  }

  const closeAllPatientsAccessModal = ()=> {
    setAllPatientsAccessModalOpen(false);
  }

  const saveAllPatientsAccessModal = ()=> {
    setLoading(true);
    CareUserStore.updateSeeAllPatients({
      UserID: params.userId,
      CanSee: !allPatientsAccess,
      callback : onAllPatientsAccessUpdated
    })
  }
  const onAllPatientsAccessUpdated = (response)=>{
    setLoading(false);
    if (response.ok) {
      closeAllPatientsAccessModal();
      fetchPatients();
      if (response.access) {
        message.show(authStore.getUserLabel() + ' has all ' + authStore.getPatientLabelPlural().toLowerCase() + ' access now');
      } else {
        message.show(authStore.getUserLabel() + ' can see only assigned ' + authStore.getPatientLabelPlural().toLowerCase());
      }
    } else {
      message.show('Error updating ' + authStore.getUserLabel().toLowerCase() + ' profile, please try again.');
    }
  }
  
  const getAllPatientsAccessModalActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeAllPatientsAccessModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Confirm',
        onClick: saveAllPatientsAccessModal,
        disabled: loading,
      },
    ];
    return data;
  };

  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Name',
        accessor: 'firstName',
        Cell: ({ row }) => {
          return (
            <span style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <UIAvatar size="small" src={row.original?.thumbnailUrl || ''} alt="avatar" style={{ marginRight: 10 }} />
              <span style={{ marginLeft: 5 }}>
                {row.original.firstName} {row.original.lastName}
              </span>
            </span>
          );
        },
      },
      {
        Header: 'Telephone',
        accessor: 'telephone',
      },
      {
        Header: 'Group',
        accessor: 'groupNames',
      },
      {
        accessor: 'id',
        showSort: false,
        Cell: ({ row }) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span style={{ minHeight: 55, minWidth: 100 }}>
                {row.original.assignedToUser && !row.original.assignedToUsersGroup && (
                  <UIButton
                    text="Remove"
                    variant="clear-danger"
                    onClick={() => {
                      removeUserPatient({
                        patientId: row.original.id,
                        userId: params.userId,
                        cb({ success, message: textMessage }) {
                          if (success) {
                            message.show(textMessage);
                            fetchPatients();
                          } else {
                            message.show(textMessage);
                          }
                        },
                      });
                    }}
                  />
                )}
              </span>
            </div>
          );
        },
      },
    ];
    return cols;
  }, [params.userId, fetchPatients]);

  return (
    <div
      style={{
        paddingTop: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', paddingTop: 10 }}>
          <NucleusSearchInput
            value={searchText}
            onChange={e => {
              const value = e.target.value;
              setSearchText(value);
            }}
            placeholder={`Filter by ${authStore.getPatientLabel()} Name`}
          />
        </div>
        <div style={{}}>
          <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', marginBottom: 20 }}>
            <label className='nucleus-font-medium' style={{ fontSize: 16, color: '#000', marginRight: 12 }}>
              { 'Can access all ' + (authStore.getPatientLabelPlural()?.toLocaleLowerCase())}
            </label>
            <UICheckbox
              checked={allPatientsAccess}
              onClick={() => {
                handleCanAccessAllPatientsToggle();
              }}
            />
          </div>
          <UIButton
            showChevron
            onClick={() => {
              openEditModal();
            }}
            text="Edit Assignments"
            variant="secondary"
          />
        </div>
      </div>
      <div style={{}}>
        <NucleusTable
          columns={columns}
          data={UserAssignedPatients.filter(
            user => user.firstName.toLowerCase().includes(searchText.toLowerCase()) || user.lastName.toLowerCase().includes(searchText.toLowerCase()),
          )}
        />
      </div>
      <div>
        <EditUserAssignedPatients 
          isOpen={isEditOpen} 
          fetchUserAssignedPatients={fetchPatients} 
          closeModal={closeEditModal} 
          patientsList={UserAssignedPatients} 
          accessAllPatients={allPatientsAccess}
        />
      </div>

      <UIModal 
        size='medium' 
        isOpen={allPatientsAccessModalOpen} 
        close={closeAllPatientsAccessModal} 
        title={ "All " + authStore.getPatientLabelPlural() + " access"} 
        actions={getAllPatientsAccessModalActions()}
      >
          <div className="modal-content modal-general-content">
            <br />
            <div className="row">
              <div className="col s12 center-align nucleus-font-medium">
                Are you sure you want to {allPatientsAccessModalLabel} access to all {authStore.getPatientLabelPlural().toLowerCase()}?
              </div>
              <br />
              <br />
            </div>
          </div>
      </UIModal>
    </div>
  );
};

export default UserAssignedPatients;
