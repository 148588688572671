import React, { FC, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Account } from '../Account/useAccountSelector';
import SearchImg from './assets/search-icon.svg';
import GroupContainer from './GroupContainer';
import { ProviderGroup } from './types';

type Props = {
  group?: ProviderGroup;
  buttonOnClick: () => void;
  buttonLabel: string;
  accounts?: Account[];
  removeMemberFromGroup?: (groupId: string) => void;
  containerStyle?: React.CSSProperties;
  title: string;
  openEditLevelTitle: () => void;
  selectedAccount?: Account;
  onItemClick: (account: Account) => void;
};

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;

  background-color: ${(props: { isActive?: boolean }) => (props.isActive ? '#E2E8F3' : 'transparent')};
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
`;

const Name = styled.p`
  font-size: 16px;
  color: #0a313f;
  margin: 0;
  margin-left: 10px;
`;

const RemoveText = styled.p`
  color: #ff4c3f;
  font-size: 10px;
  margin: 0;
`;

const Content = styled.div`
  padding: 10px 20px 0px 20px;
  display: flex;
  align-items: center;
`;

const SearchIcon = styled.img`
  width: 22px;
  height: 19px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const AccountsList: FC<Props> = ({ accounts, buttonOnClick, buttonLabel, removeMemberFromGroup, containerStyle, title, openEditLevelTitle, selectedAccount, onItemClick }) => {
  const [searchText, setSearchText] = useState('');

  const searchEnabled = useMemo(() => {
    if (!accounts) return false;
    return accounts?.length > 1;
  }, [accounts]);

  const handleSearch = e => {
    setSearchText(e.target.value);
  };

  const filteredData = useMemo(() => accounts?.filter(item => item.name?.toLowerCase().includes(searchText?.toLowerCase())), [accounts, searchText]);

  const getTitle = () => {
    if (accounts?.length === 0 || !accounts) {
      return `${title} (0)`;
    }
    return `${title} (${accounts?.length})`;
  };

  const renderSearchInput = () => {
    if (!searchEnabled) return null;

    return (
      <Content>
        <SearchIcon src={SearchImg} width="28" alt="Search" />
        <input value={searchText} type="text" placeholder={`Search by ${title} name`} onChange={handleSearch} />
      </Content>
    );
  };

  return (
    <GroupContainer
      showEditTitle
      editButtonLabel="Edit"
      editButtonOnClick={openEditLevelTitle}
      title={getTitle()}
      buttonLabel={buttonLabel}
      buttonOnClick={buttonOnClick}
      containerStyle={containerStyle}
    >
      {renderSearchInput()}
      {filteredData?.map(account => {
        return (
          <Item isActive={selectedAccount?.id === account.id} onClick={() => onItemClick(account)} key={account.id}>
            <Left>
              <Name>{account.name}</Name>
            </Left>
            {removeMemberFromGroup && <RemoveText onClick={() => removeMemberFromGroup(account.id)}>remove</RemoveText>}
          </Item>
        );
      })}
    </GroupContainer>
  );
};

export default AccountsList;
