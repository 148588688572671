//@ts-nocheck
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { GeneralStore, CareAlertsStore } from '../../stores';

class AlertItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertID: this.props.AlertID,
      patientID: this.props.PatientID,
      patientName: this.props.PatientName,
      cancelIconVisibility: ' nucleus-link',
      //patientName:this.props.FirstName + ' ' + this.props.LastName
    };

    //this.handleRowClicked = this.handleRowClicked.bind(this);
    this.markAlertAsHandled = this.markAlertAsHandled.bind(this);
    this.handlePatientClicked = this.handlePatientClicked.bind(this);

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
    //this.dateOptions.timeZone = 'UTC';
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
    //this.timeOptions.timeZone = 'UTC';
    this.alertDateAndTime = '';
  }

  componentDidMount() {
    //Register Listener
    //DEPRECATED APRIL 12th, 2018, GET INFO FROM THE PATIENT. Data is now included in the response.
    /*
        console.log("QUEUE ITEM ROW: ", this.state.patientID);
        let patientInfo = CarePatientStore.getPatientInfo(this.props.PatientID);
        //console.log("ITEM QUEUE: ", patientInfo);
        if (patientInfo){
            this.setState({
                patientName : patientInfo.Name,
                patientTelephone: patientInfo.Telephone,
                patientAddress: patientInfo.Address
            });
        }
        */
    //console.log(this.state.patientID);
  }

  componentWillUnmount() {
    //Remove Listener
  }

  handleRowClicked() {}

  markAlertAsHandled() {
    console.log('markAlertAsHandled');

    CareAlertsStore.openAlertDetails({
      AlertID: this.props.AlertID,
      PatientID: this.props.PatientID,
      PatientName: this.props.PatientName,
      AlertOccurredOn: this.props.AlertOccurredOn + ' - ' + this.props.AlertPatientTimeZone,
      AlertDescription: this.props.AlertDescription,
    });

    // this.setState({
    //     cancelIconVisibility : ' nucleus-link-disabled'
    // });
  }

  handlePatientClicked() {
    GeneralStore.setPageBeforePatient('alerts');
    this.props.history.push('/patientProfile/' + this.props.PatientID + '/null');
  }

  render() {
    const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    let timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    const alertDate = new Date(this.props.AlertOccurredOn);
    //alertDate.setHours(alertDate.getHours() + (this.props.AlertPatientTimezoneOffset));

    if (this.props.AlertAccountDateFormat != '12') {
      timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }
    const alertDateLabel = alertDate.toLocaleDateString('en-US', dateOptions) + ' ' + alertDate.toLocaleTimeString('en-US', timeOptions);

    //let dateStrings = ''; // (new Date(this.props.RequestTime)).toString();
    //console.log("Date", (new Date(this.props.AlertOcurredOn)).toString());

    //let requestDateString = new Date(this.props.RequestTime).toDateString() + " " + new Date(this.props.RequestTime).toTimeString();

    /*
        let alertDateTime = new Date(this.props.AlertOccurredOn); //).toUTCString();

        //alertDateTime.setHours(alertDateTime.getHours() + this.props.AlertPatientTimeZone);
        alertDateTime = alertDateTime.toLocaleDateString('en-US', this.dateOptions) + " - " + alertDateTime.toLocaleTimeString('en-US', this.timeOptions) + " - " + this.props.AlertPatientTimeZone;

        this.alertDateAndTime  = alertDateTime;
        */
    /*
        let cleanDescription = this.props.AlertDescription;
        let position = cleanDescription.search("This");
        if (position > -1){
            cleanDescription = cleanDescription.substring(position);
        }
        position = cleanDescription.search("<br");
        if (position > -1){
            cleanDescription = cleanDescription.substring(0,position);
        }
        */

    let shortDescription = this.props.AlertDescription;
    if (shortDescription != null && shortDescription.length > 85) {
      shortDescription = shortDescription.substring(0, 85) + ' ...';
    }

    return (
      <tr className=" ">
        <td className="">{alertDateLabel + ' - ' + this.props.AlertPatientTimeZone}</td>
        <td className="nucleus-row" onClick={this.handlePatientClicked}>
          {this.props.PatientName}
        </td>
        <td className="" height="43">
          <span className="">{this.props.AlertDescription}</span>{' '}
        </td>

        <td className="valign-wrapper">
          &nbsp;&nbsp;&nbsp;
          <span class={'nucleus-table-icon '}>
            <img src="/img/mark_as_handled.png" className="nucleus-link top-bit-separated" onClick={this.markAlertAsHandled} />
          </span>
          {/*<span className="nucleus-table-icon" ><i className="Tiny material-icons">mode_edit</i></span>
                    
                    
                    &nbsp;&nbsp;&nbsp;
                    <span className="nucleus-font-small">
                      <input type="checkbox" className="filled-in nucleus-font-small" id={"checkItem"+ this.props.RequestID }/>
                      <label className="nucleus-font-small" for={"checkItem"+ this.props.RequestID }>&nbsp;</label>
                    </span>
                */}
        </td>
      </tr>
    );
  }
}

export default props => useReactRouterProps(AlertItemRow, props);
