import BugsnagPerformance from '@bugsnag/browser-performance';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createTheme, ThemeProvider } from '@mui/material';
import store from '@nucleus-care/nucleuscare-backend-client';
import { Provider } from 'react-redux';
import { Routes, Route } from 'react-router-dom';

import packageJson from '../package.json';
import CallPlayer from './components/CallPlayer';
import CallSessionView from './components/CallSession';
import { WebRTCCallView } from './components/WebRTCCallView';
import { ContextProvider } from './context-api/ContextProvider';
import Alerts from './pages/Alerts/Alerts';
import Bulletin from './pages/BulletinBoard/BulletinBoard';
import CompleteProfile from './pages/CompleteProfile/CompleteProfile';
import Dashboard from './pages/Dashboard/Dashboard';
import Devices from './pages/Devices/Devices';
import ExternalApps from './pages/ExternalApps/AppsPage';
import ForgotPassword from './pages/ForgotPassword';
import Groups from './pages/Groups/Groups';
import Login from './pages/Login';
import Login2Factor from './pages/Login2Factor';
import Main from './pages/Main';
import Messages from './pages/Messages/Messages';
import NewMessage from './pages/NewMessage/NewMessage';
import NewFamilyMember from './pages/Patient/NewFamilyMember';
import NewPatient from './pages/Patient/NewPatient';
import PatientProfile from './pages/Patient/PatientProfile';
import Patients from './pages/Patients/Patients';
import Pictures from './pages/Pictures/Pictures';

import ProviderAccounts from './pages/Providers/Accounts/ProviderAccounts';
import ProviderCallLogs from './pages/Providers/CallLogs/ProviderCallLogs';
import ManageUsers from './pages/Providers/ManageUsers/ManageUsers';
import ProviderGrouping from './pages/Providers/ProviderGrouping/ProviderGrouping';
import ProviderQueue from './pages/Providers/Queue/ProviderQueue';
import ProviderSchedule from './pages/Providers/Schedule/ProviderSchedule';
import ScheduleAvailability from './pages/Providers/Schedule/ScheduleAvailability';
import Queue from './pages/Queue/Queue';
import CallsReport from './pages/Reports/ReportCalls';
import Reports from './pages/Reports/ReportsList';
import ResetClientPassword from './pages/ResetClientPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import NewAssignedPatient from './pages/Users/NewAssignedPatient';
import NewUser from './pages/Users/NewUser';
import UserProfile from './pages/Users/UserProfile';
import Users from './pages/Users/Users';

import Settings from './pages/UserSettings/Settings';
import VideoSession from './pages/VideoSession/VideoSession';

import BackendClientLauncher from './utils/BackendClientLauncher';

// Initialize Bugsnag if the API key is provided
// if you want to test Bugsnag in local development, you can set the API key in the .env file
// otherwise, the API key will be set in the CI/CD pipeline
if (process.env.REACT_APP_BUGSNAG_API_KEY) {
  Bugsnag.start({
    appVersion: packageJson.version,
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.REACT_APP_ENVIRONMENT,
  });
  BugsnagPerformance.start({ apiKey: process.env.REACT_APP_BUGSNAG_API_KEY, releaseStage: process.env.REACT_APP_ENVIRONMENT });
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196F3', //Nucleus Blue
    },
    secondary: {
      main: '#FFCA20', //Nucleus Yellow
    },
  }, //,fontFamily: font // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
});

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BackendClientLauncher>
          <ContextProvider>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/login2Factor" element={<Login2Factor />} />
              <Route path="/forgot" element={<ForgotPassword />} />
              <Route path="/reset/:email/:token" element={<ResetPassword />}></Route>
              <Route path="/resetCustomer/:email/:token" element={<ResetClientPassword />}></Route>
              <Route path="/completeProfile" element={<CompleteProfile />} />

              <Route path="/" element={<Main />}>
                <Route index element={<Dashboard />} />
                <Route path="/call-session" element={<CallSessionView />} />

                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users" element={<Users />} />
                <Route path="/userProfile/:userId" element={<UserProfile />} />
                <Route path="/newUser" element={<NewUser />} />
                <Route path="/newAssignedPatient/:userId" element={<NewAssignedPatient />} />
                <Route path="/patients" element={<Patients />} />
                <Route path="/patientProfile/:patientId" element={<PatientProfile />} />
                <Route path="/patientProfile/:patientId/:pendingRequest/:autoCallMode" element={<PatientProfile />} />
                <Route path="/patientProfile/:patientId/:pendingRequest" element={<PatientProfile />} />
                <Route path="/newPatient" element={<NewPatient />} />
                <Route path="/newFamilyMember/:patientId" element={<NewFamilyMember />} />
                <Route path="/devices" element={<Devices />} />
                <Route path="/queue" element={<Queue />} />
                <Route path="/alerts" element={<Alerts />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/pictures" element={<Pictures />} />
                <Route path="/bulletin" element={<Bulletin />} />
                <Route path="/videoSession" element={<VideoSession />} />
                <Route path="/reports" element={<Reports />} />
                <Route path="/reports/calls" element={<CallsReport />} />
                <Route path="/externalApps" element={<ExternalApps />} />
                <Route path="/groups" element={<Groups />} />
                <Route path="/settings" element={<Settings />} />

                <Route path="/call" element={<CallPlayer />} />
                <Route path="/seness-call/" element={<WebRTCCallView />} />
                <Route path="/newMessage" element={<NewMessage />} />
              </Route>

              <Route path="/provider" element={<Main />}>
                <Route path={`/provider/:providerId/accounts`} element={<ProviderAccounts />} />
                <Route path={'/provider/queue'} element={<ProviderQueue />} />
                <Route path={'/provider/call-logs'} element={<ProviderCallLogs />} />
                <Route path={`/provider/schedule`} element={<ProviderSchedule />} />
                <Route path={`/provider/schedule/availability`} element={<ScheduleAvailability />} />
                <Route path={`/provider/grouping`} element={<ProviderGrouping />} />
                <Route path={`/provider/manage-users`} element={<ManageUsers />} />
              </Route>
            </Routes>
          </ContextProvider>
        </BackendClientLauncher>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
