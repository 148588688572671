import { EventEmitter } from 'events';
//import Dispatcher from "../Dispatcher";

class GeneralStore extends EventEmitter {
  private staffTab: number;
  private clientTab: number;
  private PatientAssignList: any[];
  private PatientAssignNames: string;
  private pageBeforePatient: string;
  private profileMode: string;
  private dstStartDate: any;
  private dstEndDate: any;
  private timezonesArray: any[];
  private StandardTimezones: any[];
  private DaylightTimezones: any[];

  constructor() {
    super();

    this.staffTab = localStorage.staffTab || 1;
    this.clientTab = localStorage.clientTab || 1;
    this.PatientAssignList = [];
    this.PatientAssignNames = '';

    this.pageBeforePatient = localStorage.pageBeforePatient || 'patients';
    this.profileMode = localStorage.profileMode || 'Info';

    this.dstStartDate = null;
    this.dstEndDate = null;

    this.timezonesArray = [
      { key: 'Morocco Standard Time', name: '(GMT) Casablanca' },
      { key: 'GMT Standard Time', name: '(GMT) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London' },
      { key: 'Greenwich Standard Time', name: '(GMT) Monrovia, Reykjavik' },
      { key: 'W. Europe Standard Time', name: '(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna' },
      { key: 'Central Europe Standard Time', name: '(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague' },
      { key: 'Romance Standard Time', name: '(GMT+01:00) Brussels, Copenhagen, Madrid, Paris' },
      { key: 'Central European Standard Time', name: '(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb' },
      { key: 'W. Central Africa Standard Time', name: '(GMT+01:00) West Central Africa' },
      { key: 'Jordan Standard Time', name: '(GMT+02:00) Amman' },
      { key: 'GTB Standard Time', name: '(GMT+02:00) Athens, Bucharest, Istanbul' },
      { key: 'Middle East Standard Time', name: '(GMT+02:00) Beirut' },
      { key: 'Egypt Standard Time', name: '(GMT+02:00) Cairo' },
      { key: 'South Africa Standard Time', name: '(GMT+02:00) Harare, Pretoria' },
      { key: 'FLE Standard Time', name: '(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius' },
      { key: 'Israel Standard Time', name: '(GMT+02:00) Jerusalem' },
      { key: 'E. Europe Standard Time', name: '(GMT+02:00) Minsk' },
      { key: 'Namibia Standard Time', name: '(GMT+02:00) Windhoek' },
      { key: 'Arabic Standard Time', name: '(GMT+03:00) Baghdad' },
      { key: 'Arab Standard Time', name: '(GMT+03:00) Kuwait, Riyadh' },
      { key: 'Russian Standard Time', name: '(GMT+03:00) Moscow, St. Petersburg, Volgograd' },
      { key: 'E. Africa Standard Time', name: '(GMT+03:00) Nairobi' },
      { key: 'Georgian Standard Time', name: '(GMT+03:00) Tbilisi' },
      { key: 'Iran Standard Time', name: '(GMT+03:30) Tehran' },
      { key: 'Arabian Standard Time', name: '(GMT+04:00) Abu Dhabi, Muscat' },
      { key: 'Azerbaijan Standard Time', name: '(GMT+04:00) Baku' },
      { key: 'Mauritius Standard Time', name: '(GMT+04:00) Port Louis' },
      { key: 'Caucasus Standard Time', name: '(GMT+04:00) Yerevan' },
      { key: 'Afghanistan Standard Time', name: '(GMT+04:30) Kabul' },
      { key: 'Ekaterinburg Standard Time', name: '(GMT+05:00) Ekaterinburg' },
      { key: 'Pakistan Standard Time', name: '(GMT+05:00) Islamabad, Karachi' },
      { key: 'West Asia Standard Time', name: '(GMT+05:00) Tashkent' },
      { key: 'India Standard Time', name: '(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi' },
      { key: 'Sri Lanka Standard Time', name: '(GMT+05:30) Sri Jayawardenepura' },
      { key: 'Nepal Standard Time', name: '(GMT+05:45) Kathmandu' },
      { key: 'N. Central Asia Standard Time', name: '(GMT+06:00) Almaty, Novosibirsk' },
      { key: 'Central Asia Standard Time', name: '(GMT+06:00) Astana, Dhaka' },
      { key: 'Myanmar Standard Time', name: '(GMT+06:30) Yangon (Rangoon)' },
      { key: 'SE Asia Standard Time', name: '(GMT+07:00) Bangkok, Hanoi, Jakarta' },
      { key: 'North Asia Standard Time', name: '(GMT+07:00) Krasnoyarsk' },
      { key: 'China Standard Time', name: '(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi' },
      { key: 'North Asia East Standard Time', name: '(GMT+08:00) Irkutsk, Ulaan Bataar' },
      { key: 'Singapore Standard Time', name: '(GMT+08:00) Kuala Lumpur, Singapore' },
      { key: 'W. Australia Standard Time', name: '(GMT+08:00) Perth' },
      { key: 'Taipei Standard Time', name: '(GMT+08:00) Taipei' },
      { key: 'Tokyo Standard Time', name: '(GMT+09:00) Osaka, Sapporo, Tokyo' },
      { key: 'Korea Standard Time', name: '(GMT+09:00) Seoul' },
      { key: 'Yakutsk Standard Time', name: '(GMT+09:00) Yakutsk' },
      { key: 'Cen. Australia Standard Time', name: '(GMT+09:30) Adelaide' },
      { key: 'AUS Central Standard Time', name: '(GMT+09:30) Darwin' },
      { key: 'E. Australia Standard Time', name: '(GMT+10:00) Brisbane' },
      { key: 'AUS Eastern Standard Time', name: '(GMT+10:00) Canberra, Melbourne, Sydney' },
      { key: 'West Pacific Standard Time', name: '(GMT+10:00) Guam, Port Moresby' },
      { key: 'Tasmania Standard Time', name: '(GMT+10:00) Hobart' },
      { key: 'Vladivostok Standard Time', name: '(GMT+10:00) Vladivostok' },
      { key: 'Central Pacific Standard Time', name: '(GMT+11:00) Magadan, Solomon Is., New Caledonia' },
      { key: 'New Zealand Standard Time', name: '(GMT+12:00) Auckland, Wellington' },
      { key: 'Fiji Standard Time', name: '(GMT+12:00) Fiji, Kamchatka, Marshall Is.' },
      { key: 'Tonga Standard Time', name: "(GMT+13:00) Nuku'alofa" },
      { key: 'Azores Standard Time', name: '(GMT-01:00) Azores' },
      { key: 'Cape Verde Standard Time', name: '(GMT-01:00) Cape Verde Is.' },
      { key: 'Mid-Atlantic Standard Time', name: '(GMT-02:00) Mid-Atlantic' },
      { key: 'E. South America Standard Time', name: '(GMT-03:00) Brasilia' },
      { key: 'Argentina Standard Time', name: '(GMT-03:00) Buenos Aires' },
      { key: 'SA Eastern Standard Time', name: '(GMT-03:00) Georgetown' },
      { key: 'Greenland Standard Time', name: '(GMT-03:00) Greenland' },
      { key: 'Montevideo Standard Time', name: '(GMT-03:00) Montevideo' },
      { key: 'Newfoundland Standard Time', name: '(GMT-03:30) Newfoundland' },
      { key: 'Atlantic Standard Time', name: '(GMT-04:00) Atlantic Time (Canada)' },
      { key: 'SA Western Standard Time', name: '(GMT-04:00) La Paz' },
      { key: 'Central Brazilian Standard Time', name: '(GMT-04:00) Manaus' },
      { key: 'Pacific SA Standard Time', name: '(GMT-04:00) Santiago' },
      { key: 'Venezuela Standard Time', name: '(GMT-04:30) Caracas' },
      { key: 'SA Pacific Standard Time', name: '(GMT-05:00) Bogota, Lima, Quito, Rio Branco' },
      { key: 'Eastern Standard Time', name: '(GMT-05:00) Eastern Time (US & Canada)' },
      { key: 'US Eastern Standard Time', name: '(GMT-05:00) Indiana (East)' },
      { key: 'Central America Standard Time', name: '(GMT-06:00) Central America' },
      { key: 'Central Standard Time', name: '(GMT-06:00) Central Time (US & Canada)' },
      { key: 'Central Standard Time (Mexico)', name: '(GMT-06:00) Guadalajara, Mexico City, Monterrey' },
      { key: 'Canada Central Standard Time', name: '(GMT-06:00) Saskatchewan' },
      { key: 'US Mountain Standard Time', name: '(GMT-07:00) Arizona' },
      { key: 'Mountain Standard Time (Mexico)', name: '(GMT-07:00) Chihuahua, La Paz, Mazatlan' },
      { key: 'Mountain Standard Time', name: '(GMT-07:00) Mountain Time (US & Canada)' },
      { key: 'Pacific Standard Time', name: '(GMT-08:00) Pacific Time (US & Canada)' },
      { key: 'Pacific Standard Time (Mexico)', name: '(GMT-08:00) Tijuana, Baja California' },
      { key: 'Alaskan Standard Time', name: '(GMT-09:00) Alaska' },
      { key: 'Hawaiian Standard Time', name: '(GMT-10:00) Hawaii' },
      { key: 'Samoa Standard Time', name: '(GMT-11:00) Midway Island, Samoa' },
      { key: 'Dateline Standard Time', name: '(GMT-12:00) International Date Line West' },
    ];

    this.StandardTimezones = [
      { value: '+1', name: '(GMT+01) Central European Time - Stockholm' },
      { value: '+2', name: '(GMT+02) Israel Standard Time - Jerusalem' },
      { value: '-5', name: '(GMT-05) Eastern Standard Time - New York' },
      { value: '-6', name: '(GMT-06) Central Standard Time - Chicago' },
      { value: '-7', name: '(GMT-07) Mountain Standard Time - Salt Lake City' },
      { value: '-8', name: '(GMT-08) Pacific Standard Time - Los Angeles' },
      { value: '-9', name: '(GMT-09) Alaska Standard Time - Anchorage' },
      { value: '-10', name: '(GMT-10) Hawaii Standard Time - Honolulu' },
    ];

    this.DaylightTimezones = [
      { value: '+2', name: '(GMT+02) Central European Summer Time - Stockholm' },
      { value: '+3', name: '(GMT+03) Israel Daylight Time - Jerusalem' },
      { value: '-4', name: '(GMT-04) Eastern Daylight Time - New York' },
      { value: '-5', name: '(GMT-05) Central Daylight Time - Chicago' },
      { value: '-6', name: '(GMT-06) Mountain Daylight Time - Salt Lake City' },
      { value: '-7', name: '(GMT-07) Pacific Daylight Time - Los Angeles' },
      { value: '-8', name: '(GMT-08) Alaska Daylight Time - Anchorage' },
      { value: '-9', name: '(GMT-09) Hawaii Daylight Time - Honolulu' },
    ];
  }

  requestHandleBack() {
    console.log('requestHandleBack');

    this.emit('onRequestHandleBack');
  }

  openDeletePatientModal({ PatientID }) {
    console.log('openDeletePatientModal', PatientID);

    this.emit('openDeletePatientModalAction', PatientID);
  }

  openDeleteUserModal({ UserID }) {
    console.log('openDeleteUserModal', UserID);

    this.emit('openDeleteUserModalAction', UserID);
  }

  requestOpenDeleteModal({ RequestID, UserID, PatientName }) {
    console.log('requestOpenDeleteModal', RequestID, UserID, PatientName);

    this.emit('onOpenDeleteCareItemModal', { requestID: RequestID, userID: UserID, patientName: PatientName });
  }

  requestOpenCaregiverAssignmentModal({ AssignmentID, AssignmentPatientID, AssignmentMessage, AssignmentDate, AssignmentCheckIn, AssignmentCheckOut }) {
    console.log(
      'requestOpenCaregiverAssignmentModal',
      AssignmentID,
      AssignmentPatientID,
      AssignmentMessage,
      '>',
      AssignmentDate,
      '>>',
      AssignmentCheckIn,
      '>>>',
      AssignmentCheckOut,
    );

    this.emit('onOpenCaregiverAssignmentModal', {
      assignmentID: AssignmentID,
      assignmentPatientID: AssignmentPatientID,
      assignmentMessage: AssignmentMessage,
      assignmentDate: AssignmentDate,
      assignmentCheckIn: AssignmentCheckIn,
      assignmentCheckOut: AssignmentCheckOut,
    });
  }

  requestOpenFamilyMemberModal({
    MemberID,
    MemberFirstName,
    MemberLastName,
    MemberEmail,
    MemberTelephone,
    MemberReceiveUpdates,
    MemberProfileCreated,
    AccountAutoAnswer,
    MemberAutoAnswer,
    MemberAdmin,
  }) {
    console.log(
      'requestOpenFamilyMemberModal',
      MemberID,
      MemberFirstName,
      MemberLastName,
      '>',
      MemberEmail,
      '>>',
      MemberTelephone,
      '>>>',
      MemberReceiveUpdates,
      '>>>>',
      MemberProfileCreated,
      '>>>>>',
      AccountAutoAnswer,
      '>>>>>>',
      MemberAutoAnswer,
      MemberAdmin,
    );

    this.emit('onOpenFamilyMemberModal', {
      memberID: MemberID,
      memberFirstName: MemberFirstName,
      memberLastName: MemberLastName,
      memberEmail: MemberEmail,
      memberTelephone: MemberTelephone,
      memberReceiveUpdates: MemberReceiveUpdates,
      memberProfileCreated: MemberProfileCreated,
      AccountAutoAnswer: AccountAutoAnswer,
      MemberAutoAnswer: MemberAutoAnswer,
      MemberAdmin,
    });
  }

  requestOpenEditNotesModal(notesData) {
    //console.log("requestOpenEditNotesModal", notesData);

    this.emit('onOpenEditNotesModal', notesData);
  }

  requestOpenEditMessageModal({ PatientMessageID, MessageID, Message, CreatedDateTime, DeliveryDateTime, Status, Editable }) {
    console.log('requestOpenEditMessageModal', PatientMessageID, MessageID, Message, CreatedDateTime, DeliveryDateTime, Status, Editable);

    this.emit('onOpenEditMessageModal', {
      patientMessageID: PatientMessageID,
      messageID: MessageID,
      message: Message,
      createdDateTime: CreatedDateTime,
      deliveryDateTime: DeliveryDateTime,
      status: Status,
      editable: Editable,
    });
  }

  setStaffTab(tab) {
    console.log('setStaffTab', tab);

    this.staffTab = tab;
    this.saveToLocalStorage();
  }

  getStaffTab() {
    return this.staffTab;
  }

  setClientTab(tab) {
    console.log('setClientTab', tab);

    this.clientTab = tab;
    this.saveToLocalStorage();
  }
  getClientTab() {
    return this.clientTab;
  }

  setProfileMode(mode) {
    console.log('setProfileMode', mode);
    this.profileMode = mode;
  }
  getProfileMode() {
    return this.profileMode;
  }
  cancelEditProfileMode() {
    console.log('cancelEditProfileMode');

    this.emit('onRequestCancelEditProfileMode');
  }

  //AssignPatientToClient

  clearPatientAssignList() {
    this.PatientAssignList = [];
  }
  addPatientAssignList(patient) {
    console.log('addPatientAssignList');
    console.log(patient);

    this.PatientAssignList.push(patient);

    this.emit('onPatientAssignListHasChanged', this.PatientAssignList.length);

    this.PatientAssignNames = '';
    for (let i = 0; i < this.PatientAssignList.length; i++) {
      if (i != 0) {
        this.PatientAssignNames = this.PatientAssignNames + ', ';
      }
      this.PatientAssignNames = this.PatientAssignNames + this.PatientAssignList[i].Name;
    }
    console.log('Names: ', this.PatientAssignNames);
  }

  removePatientAssignList(patientID) {
    console.log('removePatientAssignList');
    console.log(patientID);
    let index = -1;
    this.PatientAssignNames = '';

    console.log('LENGTH ', this.PatientAssignList.length);
    for (let i = 0; i < this.PatientAssignList.length; i++) {
      if (this.PatientAssignList[i].ID == patientID) {
        index = i;
        console.log('Coincide en: ', i);
        break;
      }
    }
    console.log('Index es: ', index);
    if (index >= 0) {
      this.PatientAssignList.splice(index, 1);
    }
    console.log('ARRAY ', this.PatientAssignList);
    this.emit('onPatientAssignListHasChanged', this.PatientAssignList.length);

    //Create SMS Receivers Names
    this.PatientAssignNames = '';
    for (let i = 0; i < this.PatientAssignList.length; i++) {
      if (i != 0) {
        this.PatientAssignNames = this.PatientAssignNames + ', ';
      }
      this.PatientAssignNames = this.PatientAssignNames + this.PatientAssignList[i].Name;
    }
    console.log('Names: ', this.PatientAssignNames);
  }

  getPatientAssignList() {
    return this.PatientAssignList.length;
  }

  getPatientAssignListData() {
    return this.PatientAssignList;
  }

  setPageBeforePatient(page) {
    console.log('setPageBeforePatient', page);

    this.pageBeforePatient = page;
    this.saveToLocalStorage();
  }
  getPageBeforePatient() {
    return this.pageBeforePatient;
  }

  requestRefreshBiometrics() {
    console.log('requestRefreshBiometrics');

    this.emit('onRequestRefreshBiometrics');
  }

  getTimeZoneDescription(key) {
    let timezoneDescription = 'Timezone';
    for (let i = 0; i < this.timezonesArray.length; i++) {
      //console.log("Va el: ", this.timezonesArray[i].key);
      if (this.timezonesArray[i].key == key) {
        timezoneDescription = this.timezonesArray[i].name;
        //console.log("Coincide!", this.timezonesArray[i].key, this.timezonesArray[i].name);
        break;
      }
    }
    return timezoneDescription;
  }

  GetStandardTimezones() {
    return this.StandardTimezones;
  }

  GetDaylightTimezones() {
    return this.DaylightTimezones;
  }

  getDSTDates() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // DST Start
    const firstOfMarch = new Date(currentYear, 2, 1);
    const daysUntilFirstSundayInMarch = (7 - firstOfMarch.getDay()) % 7;
    const secondSundayInMarch = firstOfMarch.getDate() + daysUntilFirstSundayInMarch + 7;
    this.dstStartDate = new Date(currentYear, 2, secondSundayInMarch);

    // DST End
    const firstOfNovember = new Date(currentYear, 10, 1);
    const daysUntilFirstSundayInNov = (7 - firstOfNovember.getDay()) % 7;
    const firstSundayInNovember = firstOfNovember.getDate() + daysUntilFirstSundayInNov;
    this.dstEndDate = new Date(currentYear, 10, firstSundayInNovember);

    console.log('DST Start', this.dstStartDate);
    console.log('DST End', this.dstEndDate);
  }

  getIsDST() {
    console.log('getIsDST');
    let result = false;
    const today = new Date();

    if (this.dstStartDate != null && this.dstEndDate != null && today >= this.dstStartDate && today <= this.dstEndDate) {
      result = true;
    }
    return result;
  }

  showLoading() {
    console.log('showLoading');

    this.emit('onShowLoading');
  }

  hideLoading() {
    console.log('hideLoading');

    this.emit('onHideLoading');
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    localStorage.staffTab = this.staffTab;
    localStorage.clientTab = this.clientTab;

    localStorage.pageBeforePatient = this.pageBeforePatient;
  }
}

const generalStore = new GeneralStore();

export default generalStore;
