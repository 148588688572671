import React from 'react';

import CareUserStore from '../../stores/CareUserStore';

class UserPatientAvailableItemRow extends React.Component<
  {
    UserID: string;
    PatientID: number;
    Name: string;
    Telephone: string;
    Assigned: boolean;
    PatientsListMode?: boolean;
    DefaultSelected: boolean;
  },
  {
    patientID: number;
    patientName: string;
    DefaultSelected: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      patientID: this.props.PatientID,
      patientName: this.props.Name,
      DefaultSelected: this.props.DefaultSelected,
    };
    //console.log(this.state.patientID);
    this.handleRowClicked = this.handleRowClicked.bind(this);

    this.handleSelectPatientForAssign = this.handleSelectPatientForAssign.bind(this);
  }

  componentDidMount() {
    //console.log(this.state.patientID);
    // this.setState({
    //     patientID : this.props.PatientID
    // });
  }

  componentWillUnmount() {}

  handleRowClicked() {
    //window.location.assign("#/caregiverProfile/" + this.props.CaregiverID);
  }

  handleSelectPatientForAssign(event) {
    //console.log("this has changed ", event.target.checked);
    //CHANGED Sep 20th - Change CARE-893
    // let patient = {
    //     ID : this.props.PatientID,
    //     Name : this.props.Name ,
    //     Telephone : this.props.Telephone
    // };

    // if (event.target.checked){
    //     GeneralStore.addPatientAssignList(patient);
    // } else {
    //     GeneralStore.removePatientAssignList(this.props.PatientID);
    // }

    if (event.target.checked) {
      CareUserStore.assignPatient({
        UserID: this.props.UserID,
        PatientID: this.props.PatientID,
      });
    } else {
      CareUserStore.unassignPatient({
        UserID: this.props.UserID,
        PatientID: this.props.PatientID,
      });
    }
  }

  render() {
    let patientName = this.state.patientName;
    if (patientName != null && patientName.length > 29) {
      patientName = patientName.substring(0, 29) + ' ...';
    }

    let defaultValueCheckbox = '';
    if (this.props.Assigned) {
      defaultValueCheckbox = 'checked';
    }

    return (
      <tr>
        <td>{patientName}</td>
        <td>{this.props.Telephone}</td>

        <td className="valign-wrapper">
          <span key={defaultValueCheckbox}>
            <input
              ref="selectCheckPatient"
              type="checkbox"
              defaultChecked={!!defaultValueCheckbox}
              className="filled-in"
              id={'checkPatient' + this.props.PatientID}
              disabled={this.props.PatientsListMode}
              onChange={this.handleSelectPatientForAssign}
            />
            <label htmlFor={'checkPatient' + this.props.PatientID}>&nbsp;</label>
          </span>
        </td>
      </tr>
    );
  }
}

export default UserPatientAvailableItemRow;
