import React from 'react';
import { useState } from 'react';

import VideoSessionRow from './VideoSessionRow';

type VideoSessionTableParams = {
  accountConfig: any;
  classSessions: any;
  searchTextValue: string;
  handleEditClass: (data: any) => void;
  handleStartClass: (data: any) => void;
};

const VideoSessionTable = (props: VideoSessionTableParams) => {
  const [stateSortingStyles, setStateSortingStyles] = useState({
    dateAsc: ' nucleus-icon-inactive hide ',
    dateDesc: ' nucleus-icon-active ',

    teacherAsc: ' nucleus-icon-inactive ',
    teacherDesc: ' nucleus-icon-inactive hide ',

    nameAsc: ' nucleus-icon-inactive ',
    nameDesc: ' nucleus-icon-inactive hide ',

    numberAsc: ' nucleus-icon-inactive ',
    numberDesc: ' nucleus-icon-inactive hide ',
  });

  const [stateSortingMode, setStateSortingMode] = useState('dateDesc');

  const handleSortingMode = (tag, tag2) => {
    console.log('handleSortingMode', tag, tag2);

    const object = {
      dateAsc: ' nucleus-icon-inactive ',
      dateDesc: ' nucleus-icon-inactive hide ',

      teacherAsc: ' nucleus-icon-inactive ',
      teacherDesc: ' nucleus-icon-inactive hide ',

      nameAsc: ' nucleus-icon-inactive ',
      nameDesc: ' nucleus-icon-inactive hide ',

      numberAsc: ' nucleus-icon-inactive ',
      numberDesc: ' nucleus-icon-inactive hide ',
    };
    object[tag] = ' nucleus-icon-active ';
    object[tag2] = ' nucleus-icon-inactive hide ';
    setStateSortingStyles(object);
    setStateSortingMode(tag);
  };

  const getTableRows = () => {
    console.log('#props.classSessions>', props.classSessions);
    //console.log("#props.classSessions> stateReportRendered", stateReportRendered);
    // if (stateReportRendered){
    //     return;
    // }

    //console.log("props.classSessions>", props.classSessions.length);
    const availableSessions = [];
    //let array = [];
    // if (props.classSessions && props.classSessions.array){
    //     array = Object.keys(props.classSessions.array);
    // }

    console.log('Le Array 1', props.classSessions.array);
    //console.log("The Array 2", array);
    if (props.classSessions && props.classSessions.array && props.classSessions.array.length) {
      //console.log("The Array 3");
    }

    if (props.classSessions && props.classSessions.array && props.classSessions.array.length > 0) {
      let array = [].concat(props.classSessions.array);
      if (stateSortingMode == 'dateDesc') {
        array = array.sort((a, b) => Number(new Date(b.UtcStartTime)) - Number(new Date(a.UtcStartTime)));
      } else if (stateSortingMode == 'dateAsc') {
        array = array.sort((a, b) => Number(new Date(a.UtcStartTime)) - Number(new Date(b.UtcStartTime)));
      } else if (stateSortingMode == 'teacherDesc') {
        array = array.sort((a, b) => {
          const textA = a.UserFirstName.toLowerCase() + ' ' + a.UserLastName.toLowerCase();
          const textB = b.UserFirstName.toLowerCase() + ' ' + b.UserLastName.toLowerCase();
          if (textB < textA)
            //sort string ascending
            return -1;
          if (textB > textA) return 1;
          return 0;
        });
      } else if (stateSortingMode == 'teacherAsc') {
        array = array.sort((a, b) => {
          const textA = a.UserFirstName.toLowerCase() + ' ' + a.UserLastName.toLowerCase();
          const textB = b.UserFirstName.toLowerCase() + ' ' + b.UserLastName.toLowerCase();
          if (textA < textB)
            //sort string ascending
            return -1;
          if (textA > textB) return 1;
          return 0;
        });
      } else if (stateSortingMode == 'nameDesc') {
        array = array.sort((a, b) => {
          const textA = a.Name.toLowerCase();
          const textB = b.Name.toLowerCase();
          if (textB < textA)
            //sort string ascending
            return -1;
          if (textB > textA) return 1;
          return 0;
        });
      } else if (stateSortingMode == 'nameAsc') {
        array = array.sort((a, b) => {
          const textA = a.Name.toLowerCase();
          const textB = b.Name.toLowerCase();
          if (textA < textB)
            //sort string ascending
            return -1;
          if (textA > textB) return 1;
          return 0;
        });
      } else if (stateSortingMode == 'numberDesc') {
        array = array.sort((a, b) => {
          const textA = a.TotalParticipants;
          const textB = b.TotalParticipants;
          if (textB < textA)
            //sort string ascending
            return -1;
          if (textB > textA) return 1;
          return 0;
        });
      } else if (stateSortingMode == 'numberAsc') {
        array = array.sort((a, b) => {
          const textA = a.TotalParticipants;
          const textB = b.TotalParticipants;
          if (textA < textB)
            //sort string ascending
            return -1;
          if (textA > textB) return 1;
          return 0;
        });
      }

      array.map((session, key) => {
        //console.log("Session", session);
        const userName = session.UserFirstName + ' ' + session.UserLastName;

        if (
          props.searchTextValue.length == 0 ||
          userName.toLowerCase().indexOf(props.searchTextValue) >= 0 ||
          (session.Name && session.Name.toLowerCase().indexOf(props.searchTextValue) >= 0)
        ) {
          availableSessions.push(
            <VideoSessionRow key={key} data={session} editClass={props.handleEditClass} startClass={props.handleStartClass} accountConfig={props.accountConfig}></VideoSessionRow>,
          );
        }
      });

      // Object.entries(props.classSessions.array).forEach(([key, session]) => {
      //     //console.log("Le maera session", key);
      // });

      return <tbody>{availableSessions}</tbody>;
    }
    return (
      <tbody className="center-align ">
        <tr style={{ borderBottomStyle: 'none' }}>
          <td colSpan={7} className="center-align ">
            <br />
            <br />
            <br />
            <span className="nucleus-table-header-medium text-gray"> {`There are no ${props.accountConfig.TabNameLabelPlural.toLowerCase()} for this date range`} </span>
            <br />
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <div className="row">
      <table className="bordered highlight nucleus-table">
        <thead>
          <tr>
            <th className="table-col-18">
              <span className="nucleus-table-header-medium ">{props.accountConfig.HostLabel}</span>
              <a className={'nucleus-font-small ' + stateSortingStyles.teacherDesc} onClick={() => handleSortingMode('teacherAsc', 'teacherDesc')}>
                {' '}
                &nbsp;▼
              </a>
              <a className={'nucleus-font-small ' + stateSortingStyles.teacherAsc} onClick={() => handleSortingMode('teacherDesc', 'teacherAsc')}>
                &nbsp;▲
              </a>
            </th>
            <th className="table-col-20 center-align">
              <span className="nucleus-table-header-medium">{`${props.accountConfig.TabNameLabel} Name`}</span>
              <a className={'nucleus-font-small ' + stateSortingStyles.nameDesc} onClick={() => handleSortingMode('nameAsc', 'nameDesc')}>
                {' '}
                &nbsp;▼
              </a>
              <a className={'nucleus-font-small ' + stateSortingStyles.nameAsc} onClick={() => handleSortingMode('nameDesc', 'nameAsc')}>
                &nbsp;▲
              </a>
            </th>
            <th className="table-col-12 center-align">
              <span className="nucleus-table-header-medium">Participants</span>
              <a className={'nucleus-font-small ' + stateSortingStyles.numberDesc} onClick={() => handleSortingMode('numberAsc', 'numberDesc')}>
                {' '}
                &nbsp;▼
              </a>
              <a className={'nucleus-font-small ' + stateSortingStyles.numberAsc} onClick={() => handleSortingMode('numberDesc', 'numberAsc')}>
                &nbsp;▲
              </a>
            </th>
            <th className="table-col-18 center-align">
              <span className="nucleus-table-header-medium">Start Time</span>
              <a className={'nucleus-font-small ' + stateSortingStyles.dateDesc} onClick={() => handleSortingMode('dateAsc', 'dateDesc')}>
                {' '}
                &nbsp;▼
              </a>
              <a className={'nucleus-font-small ' + stateSortingStyles.dateAsc} onClick={() => handleSortingMode('dateDesc', 'dateAsc')}>
                &nbsp;▲
              </a>
            </th>
            <th className="table-col-18 center-align">
              <span className="nucleus-table-header-medium">End Time</span>
              {/* <a className={ "nucleus-font-small " + this.state.sortSenderDesc } onClick={this.handleSortSenderDesc}> &nbsp;▲</a>
                            <a className={ "nucleus-font-small " + this.state.sortSenderAsc }  onClick={this.handleSortSenderAsc} >&nbsp;▼</a> */}
            </th>

            <th className="table-col-5 center-align">
              <span className="nucleus-table-header-small">Edit</span>
            </th>
            <th className="table-col-9 center-align">
              <span className="nucleus-table-header-small">{`Start ${props.accountConfig.TabNameLabel}`}</span>
            </th>
          </tr>
        </thead>

        {getTableRows()}
      </table>
    </div>
  );
};

export default VideoSessionTable;
