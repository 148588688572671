import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';

export const saveNewUserAssignedPatients = async ({ userId, patients }) => {
  try {
    httpNodeApi
      .patch(`user/${userId}/patientsAssignment`, { patients: patients })
      .then(response => {
        console.log(`saveNewUserAssignedPatients response`, response);
      })
      .catch(error => {
        console.error(`saveNewUserAssignedPatients failed:`, error);
      });
  } catch (error) {
    console.error('Error fetching user patients:', error);
    throw error;
  }
};
