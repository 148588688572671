import { setAuthorizationToken as setAuthorizationTokenBackend } from '@nucleus-care/nucleuscare-backend-client';
import { setAuthorizationToken as setAuthorizationTokenAuth } from './auth-client';
import httpClient from './httpClient';
import nucleusNetwork from './NucleusNetwork';

export function setTokenToHttpClients(token) {
  httpClient.setAuthorizationToken(token);
  setAuthorizationTokenAuth(token);
  setAuthorizationTokenBackend(token);
  nucleusNetwork.setToken(token);
}

export function getToken() {
  const token = localStorage.getItem('nucleusToken');
  setTokenToHttpClients(token);
}
