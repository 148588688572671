import React, { useState } from 'react';

const CallButton = ({
  customIcon,
  icon,
  defaultValue,
  activeIcon,
  text,
  onClick,
  textClassName = '',
  textStyle = {},
}: {
  customIcon?: JSX.Element;
  icon?: string;
  activeIcon?: string;
  defaultValue?: boolean;
  text: string;
  onClick?: (value: boolean) => void;
  textClassName?: string;
  textStyle?: React.CSSProperties;
}) => {
  const [Active, setActive] = useState(defaultValue != undefined ? defaultValue : false);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <span className={textClassName} style={{ marginLeft: 15, marginRight: 5, ...textStyle }}>
        {text}
      </span>
      {customIcon ? (
        customIcon
      ) : (
        <img
          onClick={() => {
            setActive(!Active);
            onClick?.(!Active);
          }}
          src={Active ? activeIcon : icon}
          style={{
            height: 40,
            width: 40,
            cursor: 'pointer',
          }}
        />
      )}
    </div>
  );
};

export default CallButton;
