import PropTypes from 'prop-types';
import React, { useState } from 'react';

const NucleusSearchInput = ({ onChange, value, placeholder, width, onRefresh, lastRefresh = '', inputMinWidth, inputContainerWidth, loading, buttonType, register }) => {
  const [inputValue, setInputValue] = useState('');
  const changeHandler = e => {
    if (onChange) {
      onChange(e);
      return;
    }
    const value = e.target.value;
    setInputValue(value);
  };
  return (
    <div className="nucleusSearchInputMainContainer">
      <div
        className="nucleusSearchInputContainer"
        style={{
          minWidth: inputContainerWidth || 340,
          width: width,
        }}
      >
        <button
          style={{
            backgroundColor: 'transparent',
            border: 'none',
            alignSelf: 'center',
            marginBottom: '3%',
          }}
          type={buttonType || 'button'}
        >
          <img src="/img/search_icon.svg" alt="search icon" />
        </button>
        <input
          className="nucleusSearchInput"
          placeholder={placeholder}
          value={value || inputValue}
          onChange={changeHandler}
          style={{
            minWidth: inputMinWidth,
          }}
          {...register}
        ></input>
        <button style={{ backgroundColor: 'transparent', border: 'none' }} type={buttonType || 'button'}>
          <img style={{ cursor: 'pointer' }} src="/img/arrow.svg" alt="arrow" />
        </button>
        <style>
          {`
            @keyframes spin {
              from {
                  transform:rotate(0deg);
              }
              to {
                  transform:rotate(360deg);
              }
            }
            .spin-image {
              animation-name: spin;
              animation-duration: 1000ms;
              animation-iteration-count: infinite;
              animation-timing-function: linear; 
            }
          `}
        </style>
        {!!onRefresh && (
          <img
            onClick={() => {
              if (!loading && onRefresh) onRefresh();
            }}
            onKeyDown={() => {}}
            style={{
              cursor: 'pointer',
            }}
            src="./img/clockwiserefresh.svg"
            className={`nucleus-ml-2 ${loading ? 'spin-image' : ''}`}
            alt="arrow"
          />
        )}
        {!!lastRefresh && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className="nucleus-ml-2" style={{ width: 320 }}>
              {`Last request: ${lastRefresh}`}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

NucleusSearchInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  width: PropTypes.number,
  onRefresh: PropTypes.func,
  lastRefresh: PropTypes.string,
  inputMinWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputContainerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  loading: PropTypes.bool,
  buttonType: PropTypes.string,
  register: PropTypes.object,
};

export default NucleusSearchInput;
