//@ts-nocheck
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useState, useEffect, useRef } from 'react';

import { Editor } from 'react-draft-wysiwyg'; //https://www.npmjs.com/package/react-draft-wysiwyg
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { SpinnerCircular } from 'spinners-react';
import AuthStore from 'stores/AuthStore';
import CareBulletinStore from 'stores/CareBulletinStore';
import Message from 'utils/Message';

const NucleusBulletinEditor = props => {
  // First UseEffect
  useEffect(() => {
    console.log('NucleusBulletinEditor useEffect');
    //console.log("NucleusBulletinEditor useEffect props", props);

    $('.modalBulletin').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }, []);

  const [stateLoading, setStateLoading] = useState(false);
  const [stateButtonsEnabled, setStateButtonsEnabled] = useState(false);
  const [stateEditorContent, setStateEditorContent] = useState(EditorState.createEmpty());

  const [stateMediaInserted, setStateMediaInserted] = useState(false);
  const [stateSaveErrorVisible, setStateSaveErrorVisible] = useState(false);
  const [stateSaveErrorText, setStateSaveErrorText] = useState('');

  const txtVideoUrlRef = useRef(null);
  const txtImageUrlRef = useRef(null);
  const selectImageRef = useRef(null);
  const selectPdfRef = useRef(null);
  const txtPdfFileName = useRef(null);

  const selectedImageRef = useRef(null);
  const [stateSelectedImage, setStateSelectedImage] = useState(null);
  const [stateSelectedImagePreview, setStateSelectedImagePreview] = useState(null);

  const [stateSelectedPdf, setStateSelectedPdf] = useState(null);

  useEffect(() => {
    //console.log("NucleusBulletinEditor useEffect props.loading", props.loading);
    setStateLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    //console.log("NucleusBulletinEditor useEffect props.buttonsEnabled", props.buttonsEnabled);
    setStateButtonsEnabled(props.buttonsEnabled);
  }, [props.buttonsEnabled]);

  useEffect(() => {
    console.log('NucleusBulletinEditor useEffect props.editorState', props.editorState);
    const text = draftToHtml(convertToRaw(props.editorState.getCurrentContent()));
    const textHTML = text.toString('html');
    if (textHTML && textHTML.length > 0 && (textHTML.includes('<iframe') || textHTML.includes('<img') || textHTML.includes('<embed'))) {
      setStateMediaInserted(true);
    } else {
      setStateMediaInserted(false);
    }
    setStateEditorContent(props.editorState);
  }, [props.editorState]);

  const handleSaveBulletinContent = () => {
    console.log('handleSaveBulletinContent');

    props.onSaveBulletin(stateEditorContent);
  };

  const handleAddVideo = () => {
    console.log('handleAddVideo');
    $('#modalAddVideoUrl').modal('open');
    txtVideoUrlRef.current.value = '';
  };

  const handleAddImage = () => {
    $('#modalAddImage').modal('open');
  };

  const handleClear = () => {
    console.log('handleClear');
    let newEditorState = EditorState.createEmpty();
    const contentBlock = htmlToDraft('');

    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    newEditorState = EditorState.createWithContent(contentState);
    setStateEditorContent(newEditorState);
    setStateMediaInserted(false);
  };

  const handleDeleteButton = () => {
    console.log('handleDeleteButton');
    props.onHandleDeleteButton();
  };

  const onEditorStateChange = editorState => {
    //console.log("onEditorStateChange", editorState);
    const text = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const textHTML = text.toString('html');
    if (textHTML && textHTML.length > 0 && (textHTML.includes('<iframe') || textHTML.includes('<img') || textHTML.includes('<embed'))) {
      setStateMediaInserted(true);
    } else {
      setStateMediaInserted(false);
    }
    setStateEditorContent(editorState);
  };

  const handleCloseAddVideoModal = () => {
    $('#modalAddVideoUrl').modal('close');
  };

  const handleSaveVideoUrl = () => {
    console.log('handleSaveVideoUrl');

    if (txtVideoUrlRef.current.value == '') {
      Message.show('The video url cannot be empty');
    } else {
      let videoIframeString = '';
      let videoUrl = txtVideoUrlRef.current.value;
      if (videoUrl.includes('watch?v=')) {
        videoUrl = videoUrl.replace('watch?v=', 'embed/');
        videoUrl = videoUrl.replace('youtube', 'youtube-nocookie');
      }
      console.log('Final video url 2', videoUrl);
      videoIframeString =
        `<p className="nucleusIframeContainer">
                    <iframe id="ytplayer"
                    width="854" 
                    height="480" 
                    src="` +
        videoUrl +
        `?rel=1&modestbranding=0&showinfo=0&showtitle=0&iv_load_policy=3&loop=0" 
                    frameborder="0" 
                    allowfullscreen=""></iframe>
                </p>`;

      console.log('videoIframeString', videoIframeString);
      const text = draftToHtml(convertToRaw(stateEditorContent.getCurrentContent()));
      let textHTML = text.toString('html');
      textHTML += videoIframeString.toString();

      const contentBlock = htmlToDraft(textHTML);
      if (contentBlock) {
        console.log('contentBlock', contentBlock);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);

        setStateEditorContent(editorState);
        setStateMediaInserted(true);
        handleCloseAddVideoModal();
      }
    }
  };

  const handleCloseAddImageModal = () => {
    $('#modalAddImage').modal('close');
  };

  const handleAddImageUrlModal = () => {
    $('#modalAddImageUrl').modal('open');
    txtImageUrlRef.current.value = '';
    selectedImageRef.current = null;
    setStateSelectedImage(null);
  };

  const handleCloseAddImageUrlModal = () => {
    $('#modalAddImageUrl').modal('close');
  };

  const handleSaveImageUrl = () => {
    console.log('handleSaveImageUrl');
    if (txtImageUrlRef.current.value == '') {
      Message.show('The image url cannot be empty');
    } else {
      loadImageUrl(txtImageUrlRef.current.value);
    }
  };

  const loadImageUrl = imageUrl => {
    let imageWrapper = '';
    console.log('Final image url 2', imageUrl);
    imageWrapper =
      `<p className="nucleusIframeContainer" style="max-width: 854px;align-content: center;align-items: center;">
                <img 
                    id="bulletinImage"
                    className="bulletinImage"
                    className="bulletinImage"
                    max-width: "854px"
                    width:"80%"
                    src="` +
      imageUrl +
      `" 
                    style="vertical-align:top; margin:5px 0px 0px;  width: auto; object-fit:contain;max-width: 854px;"
                />
            </p>`;

    console.log('imageWrapper', imageWrapper);
    const text = draftToHtml(convertToRaw(stateEditorContent.getCurrentContent()));
    let textHTML = text.toString('html');
    textHTML += imageWrapper.toString();

    const contentBlock = htmlToDraft(textHTML);
    if (contentBlock) {
      console.log('contentBlock', contentBlock);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      setStateEditorContent(editorState);
      setStateMediaInserted(true);
      $('#modalAddImage').modal('close');
      $('#modalAddImageUrl').modal('close');
    }
  };

  const openSelectImage = () => {
    console.log('Open->Select image to upload');

    selectImageRef.current.value = null;
    selectImageRef.current.click();
    //document.getElementById('selectImage').click();
  };

  const onImageSelected = e => {
    console.log('onImageSelected', e);

    if (!e.target.files || e.target.files.length === 0) {
      Message.show('Please, select an image');
      return;
    }

    const image = e.target.files[0];
    console.log('SelectedImage', image);
    selectedImageRef.current = image;
    setStateSelectedImage(image);
    const objectUrl = URL.createObjectURL(image);
    setStateSelectedImagePreview(objectUrl);

    $('#modalUploadImage').modal('open');
  };

  const onCancelUploadImage = () => {
    console.log('onCancelUploadImage');
    $('#modalUploadImage').modal('close');
    selectImageRef.current.value = null;
    selectedImageRef.current = null;
    setStateSelectedImage(null);
  };

  const onConfirmUploadImage = () => {
    console.log('onConfirmUploadImage');
    console.log('Selected Image', selectedImageRef.current);
    console.log('Selected Image', stateSelectedImage);

    $('#modalUploadImage').modal('close');
    $('#modalUploadingFile').modal('open');

    const imageName = stateSelectedImage.name;

    CareBulletinStore.UploadBulletinImage(AuthStore.getUserAccountID(), imageName, stateSelectedImage, imageUploadResponse => {
      console.log('NucleusBulletinEditor imageUploadResponse =>', imageUploadResponse);
      $('#modalUploadingFile').modal('close');
      if (imageUploadResponse && imageUploadResponse.ok && imageUploadResponse.ImageFileUrl) {
        loadImageUrl(imageUploadResponse.ImageFileUrl);
      } else {
        Message.show('Error uploading image, please try again');
      }
    });
    // setTimeout(() => {
    //     $('#modalUploadingFile').modal('close');
    //     loadImageUrl("https://nucleuscare-data.s3.us-west-2.amazonaws.com/bulletinboard/1d40c9591a579eacfc41f7032ee0fedd.jpg");
    // }, 2500);
  };

  const handleAddPdf = () => {
    $('#modalAddPdf').modal('open');
    setStateSelectedPdf(null);
    selectPdfRef.current.value = null;
    txtPdfFileName.current.value = '';
  };

  const handleCloseAddPdfModal = () => {
    $('#modalAddPdf').modal('close');
  };

  const openSelectPdf = () => {
    console.log('Open->Select image to upload');

    selectPdfRef.current.value = null;
    selectPdfRef.current.click();
    //document.getElementById('selectImage').click();
  };

  const onPdfSelected = e => {
    console.log('onPdfSelected', e);

    if (!e.target.files || e.target.files.length === 0) {
      Message.show('Please, select a pdf file');
      return;
    }
    const pdfFile = e.target.files[0];
    console.log('SelectedPdf', pdfFile);
    if (pdfFile) {
      setStateSelectedPdf(pdfFile);
      txtPdfFileName.current.value = pdfFile.name;
    }
    //$('#modalUploadImage').modal('open');
  };

  const onUploadPdfSelected = () => {
    console.log('onUploadPdfSelected');

    console.log('The selectedFile is ', stateSelectedPdf);
    if (stateSelectedPdf != null) {
      const fileName = stateSelectedPdf.name.replaceAll(' ', '');
      console.log('FileName', fileName);
      $('#modalAddPdf').modal('close');
      $('#modalUploadingFile').modal('open');
      CareBulletinStore.UploadBulletinFile(AuthStore.getUserAccountID(), fileName, stateSelectedPdf, fileUploadResponse => {
        console.log('NucleusBulletinEditor fileUploadResponse =>', fileUploadResponse);
        $('#modalUploadingFile').modal('close');
        if (fileUploadResponse && fileUploadResponse.ok && fileUploadResponse.PdfFileUrl) {
          loadPdfFileUrl(fileUploadResponse.PdfFileUrl);
        } else {
          Message.show('Error uploading PDF file, please try again');
        }
      });
    }
  };

  const loadPdfFileUrl = fileUrl => {
    let pdfFileWrapper = '';
    console.log('Final file url 2', fileUrl);
    pdfFileWrapper =
      `<p className="nucleusIframContainer" style="max-width: 854px;">
                <iframe
                    src="` +
      fileUrl +
      `?embedded=false&toolbar=0&navpanes=0&scrollbar=0"
                    frameBorder="0"
                    scrolling="auto"
                    className="bulletinPdf"
                    className="bulletinPdf"
                    width="854" 
                    height="480"
                ></iframe>
            </p>`;
    //  <object
    //     data="` + imageUrl + `"
    //     type="application/pdf"
    //     width="100%"
    //     height="500px">

    // </object>

    console.log('pdfFileWrapper', pdfFileWrapper);
    const text = draftToHtml(convertToRaw(stateEditorContent.getCurrentContent()));
    let textHTML = text.toString('html');
    textHTML += pdfFileWrapper.toString();

    const contentBlock = htmlToDraft(textHTML);
    if (contentBlock) {
      console.log('contentBlock', contentBlock);
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);

      setStateEditorContent(editorState);
      setStateMediaInserted(true);
      $('#modalAddImage').modal('close');
      $('#modalAddImageUrl').modal('close');
    }
  };
  let saveBtnMode = 'nucleus-submit-btn-small';
  // let divResultsView = ' hide';
  // let divNoResultsView = ' hide';
  // let divLoadingView = ' hide';
  if (stateLoading) {
    saveBtnMode = 'nucleus-submit-btn-small-disabled';
  }

  return (
    <div id="bulletinEditor">
      {stateButtonsEnabled && (
        <div className="row no-margin ">
          <span className="nucleus-labels">
            <b> &nbsp;Enter text and use the editor to apply formatting and design</b>
          </span>{' '}
          &nbsp;&nbsp;&nbsp;
          {props.onHandleDeleteButton && (
            <a className={'txt-white nucleus-font-small ' + saveBtnMode} onClick={handleDeleteButton} style={{ marginRight: 13 }}>
              Delete
            </a>
          )}
          <a className={'txt-white nucleus-font-small ' + saveBtnMode} onClick={handleClear} style={{ marginRight: 13 }}>
            Clear Editor
          </a>
          {!stateMediaInserted && (
            <a className={'txt-white nucleus-font-small ' + saveBtnMode} onClick={handleAddVideo} style={{ marginRight: 13 }}>
              Add video
            </a>
          )}
          {!stateMediaInserted && (
            <a className={'txt-white nucleus-font-small ' + saveBtnMode} onClick={handleAddImage} style={{ marginRight: 13 }}>
              Add image
            </a>
          )}
          {!stateMediaInserted && (
            <a className={'txt-white nucleus-font-small ' + saveBtnMode} onClick={handleAddPdf} style={{ marginRight: 13 }}>
              Add PDF
            </a>
          )}
          <a className={'txt-white nucleus-font-small ' + saveBtnMode} onClick={handleSaveBulletinContent} style={{ marginRight: 13 }}>
            Save
          </a>
          <br />
          <span className={'nucleus-form-error center-align ' + stateSaveErrorVisible}>{stateSaveErrorText}</span>
        </div>
      )}
      {stateButtonsEnabled && (
        <div className="row a-bit-lower font-Helvetica" style={{ backgroundColor: 'white', marginTop: 10 }}>
          <Editor
            editorState={stateEditorContent}
            wrapperClassName="nucleus-text-editor-wrapper-class"
            editorClassName="nucleus-text-editor"
            toolbarClassName="nucleus-text-editor-toolbar-class"
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', /*'image',*/ 'remove', 'history'],
              inline: { inDropdown: true, options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace' /*, 'superscript', 'subscript'*/] },
              list: { inDropdown: true },
              textAlign: {
                inDropdown: false,
                options: ['left', 'center', 'right', 'justify'],
              },
              link: { inDropdown: true },
              embedded: {
                className: undefined,
                popupClassName: 'nucleus-text-editor-embed-popup',
                defaultSize: {
                  width: 'auto',
                  height: 'auto',
                },
              },
              image: {
                icon: undefined,
                className: undefined,
                component: undefined,
                popupClassName: 'nucleus-text-editor-image-popup',
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: true,
                uploadCallback: undefined,
                previewImage: true,
                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: 'auto',
                  width: 'auto',
                },
              },
              remove: { icon: undefined, className: undefined, component: undefined },
              history: { inDropdown: true },
            }}
            preserveSelectionOnBlur={true}
            stripPastedStyles={false}
          >
            <textarea autofocus value={draftToHtml(convertToRaw(stateEditorContent.getCurrentContent()))} />
          </Editor>
        </div>
      )}
      {!stateButtonsEnabled && !stateLoading && (
        <div className={'row center-align '}>
          <br />
          <br />
          <br />
          <br />
          <span className="nucleus-table-header-medium text-gray"> {props.emptyEditorMessage} </span>
          <br />
          <br />
          <br />
          <br />
        </div>
      )}

      {stateLoading && (
        <div className={'row center-align '}>
          <br />
          <br />
          <br />
          <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness="100" />
          <br />
          <br />
          <br />
        </div>
      )}
      <div id="modalAddVideoUrl" className="modal modalBulletin modalScrollableNotWide">
        <div className="modal-content nucleus-modal-content txt-special-color">
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseAddVideoModal} />
          <h3 className="nucleus-page-subtitle">Add Video Url</h3>
          <br />
          <br />

          <div className="row no-margin">
            <span className="nucleus-labels nucleus-font-label-title">
              <b>{'Video Url'}</b>
            </span>
            <br />
            <br />
            <input
              id="txtVideoUrl"
              type="text"
              ref={txtVideoUrlRef}
              className="nucleus-input-form"
              style={{ backgroundColor: 'white', paddingLeft: 5, width: '95%' }}
              placeholder={'Insert video url'} /*onChange={this.validateCaption}*/
            />
            <br />

            <div className="row no-margin">
              <div className="col s12 center-align no-margin">
                <p></p>
                <span className=" modalSeparator">
                  {' '}
                  <label> &nbsp;</label>
                </span>
                <div>&nbsp;</div>
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={handleSaveVideoUrl}>
                  Save video url
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modalAddImage" className="modal modalBulletin modalScrollableNotWide">
        <div className="modal-content nucleus-modal-content txt-special-color">
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseAddImageModal} />
          <h3 className="nucleus-page-subtitle">Add Image</h3>
          <br />
          <br />
          <div className="row center-align">
            <span className="nucleus-labels nucleus-font-label-title">{'You can add an image by'}</span>
            <br />
            <br />
            <div className="row">
              <div className="col s12 ">
                <input
                  id="selectImage"
                  ref={selectImageRef}
                  type="file"
                  data-multiple-caption={'Select an image to upload'}
                  multiple={false}
                  onChange={onImageSelected}
                  accept={'image/png, image/jpeg'}
                  style={{ display: 'none' }}
                ></input>
                <p />
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={handleAddImageUrlModal}>
                  {'Add image URL'}
                </a>
                <p />
                <span className="nucleus-labels nucleus-font-label-title">{'or'}</span>
                <p />
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={openSelectImage}>
                  {'Select image to upload'}
                </a>
                {/* <a className={"txt-white nucleus-font-modal-btn " + this.state.uploadImageBtnStyle } onClick={this.selectPhotos} >Select Photos</a> */}
              </div>
            </div>
          </div>
          {/* <div className="row center-align no-margin">
                        <div className="col s12 no-margin">
                            <a className={"txt-white nucleus-font-small nucleus-submit-btn-small"} onClick={handleCloseAddImageModal}>Cancel</a>
                        </div>
                    </div> */}
        </div>
      </div>

      <div id="modalAddImageUrl" className="modal modalBulletin modalScrollableNotWide" style={{ marginTop: 10 }}>
        <div className="modal-content nucleus-modal-content txt-special-color">
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseAddImageUrlModal} />
          <h3 className="nucleus-page-subtitle">Add Image Url</h3>
          <br />
          <br />

          <div className="row no-margin">
            <span className="nucleus-labels nucleus-font-label-title">
              <b>{'Image Url'}</b>
            </span>
            <br />
            <br />
            <input
              id="txtImageUrl"
              type="text"
              ref={txtImageUrlRef}
              className="nucleus-input-form"
              style={{ backgroundColor: 'white', paddingLeft: 5, width: '95%' }}
              placeholder={'Insert image url'} /*onChange={this.validateCaption}*/
            />
            <br />

            <div className="row no-margin">
              <div className="col s12 center-align no-margin">
                <p />
                <span className=" modalSeparator">
                  {' '}
                  <label> &nbsp;</label>
                </span>
                <div>&nbsp;</div>
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={handleSaveImageUrl}>
                  Save image url
                </a>
                <p />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="modalUploadImage" className="modal modalBulletin modalScrollableWide" style={{ marginTop: 10 }}>
        <div className=" nucleus-modal-content txt-special-color">
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={onCancelUploadImage} />
          <h3 className="nucleus-page-subtitle">Upload Image</h3>
          <br />

          <div className="row no-margin center-align">
            <span className="nucleus-labels nucleus-font-label-title">
              <b>{'You selected the next image:'}</b>
            </span>
            <div className={'row center-align '}>
              <br />
              <img src={stateSelectedImagePreview} style={{ width: '80%' }}></img>
              <br />
            </div>
            <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={onCancelUploadImage}>
              Cancel
            </a>
            <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={onConfirmUploadImage}>
              Confirm
            </a>
          </div>
        </div>
      </div>

      <div id="modalAddPdf" className="modal modalBulletin modalScrollableNotWide">
        <div className="modal-content nucleus-modal-content txt-special-color">
          <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={handleCloseAddPdfModal} />
          <h3 className="nucleus-page-subtitle">Add Pdf</h3>
          <br />
          <br />
          <div className="row center-align">
            <div className="row no-margin">
              <div className="col s12 no-margin center-align">
                <input
                  id="selectPdf"
                  ref={selectPdfRef}
                  type="file"
                  data-multiple-caption={'Select a pdf to upload'}
                  multiple={false}
                  onChange={onPdfSelected}
                  accept={'application/pdf'}
                  style={{ display: 'none' }}
                ></input>
                {stateSelectedPdf == null && (
                  <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={openSelectPdf}>
                    {'Select pdf to upload'}
                  </a>
                )}
                <br />
                <br />
                <input
                  id="txtPdfFileName"
                  type="text"
                  ref={txtPdfFileName}
                  className="nucleus-input-form"
                  style={{ backgroundColor: 'white', paddingLeft: 5, width: '95%' }}
                  placeholder={'No File Chosen'} /*onChange={this.validateCaption}*/
                  disabled
                />
                <br />
                <br />
                {stateSelectedPdf != null && (
                  <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={onUploadPdfSelected}>
                    {'Upload'}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="modalUploadingFile" className="modal modalBulletin modalScrollableNotWide" style={{ marginTop: 10 }}>
        <div className="modal-content nucleus-modal-content txt-special-color center-align">
          <br />
          <br />
          <h3 className="nucleus-page-subtitle">Uploading file...</h3>

          <div className="row no-margin">
            <div className={'row center-align '}>
              <br />
              <br />
              <br />
              <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness="100" />
              <br />
              <br />
              <br />
            </div>
          </div>
          <span className="nucleus-labels nucleus-font-label-title">
            <b>{'Please wait in this screen.'}</b>
          </span>
        </div>
      </div>
    </div>
  );
};

export default NucleusBulletinEditor;
