export const AddButtonSVG = () => {
  return (
    <svg width="58" height="58" viewBox="0 0 58 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_1055_3114)">
        <circle cx="29" cy="28" r="25" fill="#FFCA20" />
      </g>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6016 19.9005V35.1201V37.0206H31.4024V35.1201V19.9005V18H27.6016V19.9005Z" fill="white" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M21.9004 29.4025H37.1196H39.02V25.6016H37.1196H21.9004H20V29.4025H21.9004Z" fill="white" />
      <defs>
        <filter id="filter0_d_1055_3114" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1055_3114" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1055_3114" result="shape" />
        </filter>
      </defs>
    </svg>
  );
};
