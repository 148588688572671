import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import PatientSelector from '../../../components/PatientsSelector/patientsSelector';
import usePatientSelector from '../../../components/PatientsSelector/usePatientsSelector';
import { UIModal } from '../../../components/UI';
import { ModalAction } from '../../../components/UI/Modals/Base/ActionButtons';
import Message from '../../../utils/Message';
import ExistingClientsList from './ExistingClientsList';
import { Group } from './types';
import useGroups from './useGroups';

const Label = styled.div`
  font-size: 16px;
  color: #0a313f;
  margin-bottom: 5px;
  font-weight: 600;
`;

const GroupName = styled.div`
  font-size: 16px;
  color: #0092ff;
  margin-bottom: 24px;
  font-weight: 300;
`;

const Divider = styled.div`
  margin-bottom: 24px;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  group?: Group | null;
}

const AddPatientsToGroup: FC<Props> = ({ closeModal, isOpen, group }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { patients, togglePatientSelection, reset, searchTerm, setSearchTerm, selectedPatients } = usePatientSelector();
  const { patientsPerGroup, getPatientsByGroupId, totalPatients } = useGroups();

  const onCloseModal = () => {
    reset();
    closeModal();
  };

  useEffect(() => {
    if (group?.id && isOpen) {
      getPatientsByGroupId(group.id);
    }
  }, [group, isOpen]);

  const save = () => {
    onCloseModal();

    const selectedIds = selectedPatients.map(p => p.id);

    if (selectedIds.length === 0) {
      Message.show('Please select at least one client');
      return;
    }

    httpApi
      .patch(`/groups/${group?.id}/patients`, {
        patientIds: selectedIds,
      })
      .then(() => {
        setIsLoading(false);
        Message.show('Successfully added to Group');
        onCloseModal();
      })
      .catch(() => {
        Message.show('Error while adding to the group.');
        setIsLoading(false);
      });
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModal,
        buttonVariant: 'secondary',
      },
      {
        label: 'Add',
        onClick: save,
        disabled: isLoading,
      },
    ];

    return data;
  };

  if (!group) return null;

  return (
    <UIModal size="medium" isOpen={isOpen} close={onCloseModal} title="Add Clients to Group" actions={getActions()}>
      <Label>You are adding clients to:</Label>
      <GroupName>Group: {group.name}</GroupName>
      <ExistingClientsList data={patientsPerGroup} total={totalPatients} />
      <Divider />
      <Label>Clients to add</Label>
      <PatientSelector
        patients={patients}
        togglePatientSelection={togglePatientSelection}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedPatients={selectedPatients}
        patientsPerGroup={patientsPerGroup}
      />
    </UIModal>
  );
};

export default AddPatientsToGroup;
