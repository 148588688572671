import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';
class LoginApi {
  private authClient: any;
  private context: any;

  constructor(context, authClient) {
    this.authClient = authClient;
    this.context = context;
  }

  async logout(data) {
    return await this.authClient.logout(data);
  }

  //<----------------------------------Authentication-------------------------------->
  login(data, callback) {
    const params = {
      email: data.Email,
      password: data.Password,
      platformInformation: data.PlatformInformation,
    };
    return this.authClient
      .loginWithCredentials(params)
      .then(data => {
        callback({
          status: data.status,
          ...data.data,
        });
      })
      .catch(e => {
        const { data } = e.response;
        callback({
          status: data.status,
          ...data.data,
        });
      });
  }

  sendVerificationCode(callback) {
    this.authClient
      .resendCode()
      .then(data => {
        callback({
          status: data.status,
          ...data.data,
        });
      })
      .catch(e => {
        if (!e.response) {
          callback({});
        }
        const { data } = e.response;
        callback(data);
      });
  }

  verifyCode(code, callback) {
    let response;
    this.authClient
      .verifyCode(code)
      .then(() => {
        response = {
          ok: true,
        };
      })
      .catch(e => {
        response = {
          ok: false,
          error: e,
        };
      })
      .finally(() => {
        callback(response);
      });
  }

  updateUserPassword(paramsData, callback) {
    console.log('Control LoginApi > updateUserPassword', paramsData);
    return this.authClient
      .updatePassword(paramsData)
      .then(() => {
        console.log('Login API Point 3.1');
        callback({
          ok: true,
          PasswordUpdated: true,
        });
      })
      .catch(e => {
        console.log('Login API Point 3.2', e);
        callback({
          ok: false,
          PasswordUpdateError: true,
        });
      });
  }

  resetPassword2(paramsData, callback) {
    console.log('Control > resetPassword2', paramsData);
    return this.authClient
      .resetPassword(paramsData)
      .then(() => {
        console.log('resetPassword API Point 3.1');
        callback({
          ok: true,
          PasswordUpdated: true,
        });
      })
      .catch(e => {
        console.log('resetPassword API Point 3.2', e);
        callback({
          ok: false,
          PasswordUpdateError: true,
        });
      });
  }

  resetTempPassword(paramsData) {
    console.log('Control > resetTempPassword', paramsData);
    return this.authClient
      .resetTempPassword(paramsData)
      .then(response => {
        console.log('resetTempPassword API response', response);
        return response.data;
      })
      .catch(e => {
        console.log('resetTempPassword API catch', e);
        if (e && e.response && e.response.data) {
          return e.response.data;
        }
      });
  }

  //<--------------------------- Request Reset Password ----------------------------->
  requestResetPassword(data, callback) {
    let response;
    return this.authClient
      .requestResetPassword(data.Email)
      .then(() => {
        response = {
          ok: true,
          existingUser: true,
        };
      })
      .catch(e => {
        response = {
          ok: false,
          existingUser: true,
        };
        if (e.response && e.response.data) {
          const { data } = e.response;
          if (data.code === 6) {
            response.existingUser = false;
          }
        }
      })
      .finally(() => callback(response));
  }

  //<-------------------------------- Reset Password -------------------------------->
  resetPassword(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/ResetPassword',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------------- Reset Customer Password -------------------------------->
  resetCustomerPassword(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'FamilyMember.asmx/ResetPassword',
      data: data,
      callback: callback,
    });
  }

  requestCurrentIP(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: 'https://jsonip.com/',
      data: data,
      callback: callback,
    });
  }
}

export default LoginApi;
