export const periodOptions = [
  { value: 'DAILY', label: 'Day(s)' },
  { value: 'WEEKLY', label: 'Week(s)' },
  { value: 'MONTHLY', label: 'Month(s)' },
  { value: 'ANNUALLY', label: 'Yearly' },
];

export const daysOfTheWeek = [
  { value: 7, label: 'Sunday' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
];

export const daysOfTheMonth = [
  { value: 1, label: 'On the 1st' },
  { value: 2, label: 'On the 2nd' },
  { value: 3, label: 'On the 3rd' },
  { value: 4, label: 'On the 4th' },
  { value: 5, label: 'On the 5th' },
  { value: 6, label: 'On the 6th' },
  { value: 7, label: 'On the 7th' },
  { value: 8, label: 'On the 8th' },
  { value: 9, label: 'On the 9th' },
  { value: 10, label: 'On the 10th' },
  { value: 11, label: 'On the 11th' },
  { value: 12, label: 'On the 12th' },
  { value: 13, label: 'On the 13th' },
  { value: 14, label: 'On the 14th' },
  { value: 15, label: 'On the 15th' },
  { value: 16, label: 'On the 16th' },
  { value: 17, label: 'On the 17th' },
  { value: 18, label: 'On the 18th' },
  { value: 19, label: 'On the 19th' },
  { value: 20, label: 'On the 20th' },
  { value: 21, label: 'On the 21st' },
  { value: 22, label: 'On the 22nd' },
  { value: 23, label: 'On the 23rd' },
  { value: 24, label: 'On the 24th' },
  { value: 25, label: 'On the 25th' },
  { value: 26, label: 'On the 26th' },
  { value: 27, label: 'On the 27th' },
  { value: 28, label: 'On the 28th' },
];

export const mothOfYear = [
  { value: 1, label: 'January' },
  { value: 2, label: 'February' },
  { value: 3, label: 'March' },
  { value: 4, label: 'April' },
  { value: 5, label: 'May' },
  { value: 6, label: 'June' },
  { value: 7, label: 'July' },
  { value: 8, label: 'August' },
  { value: 9, label: 'September' },
  { value: 10, label: 'October' },
  { value: 11, label: 'November' },
  { value: 12, label: 'December' },
];
