import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

export function useUserMeSelector() {
  return useSelector(({ me }) => {
    return me && me.data;
  });
}

const getUserCreated = createSelector(
  ({ me }) => {
    return me && me.data;
  },
  me => {
    if (!me) return false;
    return me.Created;
  },
);

export function useUserCreatedSelector() {
  return useSelector(getUserCreated);
}

export function getAccessToReportsTabSelector() {
  return useSelector(({ userLoggedInConfig }) => {
    const { configurations } = userLoggedInConfig;
    if (!configurations) return false;
    return !!(configurations.allowReports && configurations.allowReports.allow);
  });
}

export function getAccessToGroupsTabSelector() {
  return useSelector(({ userLoggedInConfig }) => {
    const { configurations } = userLoggedInConfig;
    if (!configurations) return false;
    return !!(configurations.allowGroups && configurations.allowGroups.allow);
  });
}
