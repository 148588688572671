import { IState } from 'pages/Providers/ManageUsers/types';
import { ProviderCallRequest } from 'pages/Providers/Queue/queue.types';
import { fetchQueueForProvider } from 'pages/Providers/Queue/QueueSource';
import { ProviderPatient, ProviderSchedule } from 'pages/Providers/Schedule/providerSchedule.types';
import { fetchPatientsForProvider, fetchScheduleForProvider } from 'pages/Providers/Schedule/ScheduleSource';
import React, { useReducer, useContext, createContext, ReactNode, useMemo } from 'react';

export interface IProviderListItem {
  ID: string;
  providerName: string;
  numberOfAccounts: number;
  numberOfDevices: number;
  numberOfUsers: number;
}

export interface IProviderAccount {
  ID: string;
  accountGroup: string;
  accountName: string;
  ProviderName: string;
  numberOfTotalResidents: number;
  numberOfDevices: number;
  numberOfOnlineDevices: number;
  numberOfUsers: number;
}
export interface IProviderUser {
  ID: string;
  Name: string;
  Email: string;
  Telephone: string;
  status: string;
  isAdmin: 'Y' | 'N' | boolean;
}

export interface INewProviderUser {
  email: string;
  firstName: string;
  lastName: string;
  telephone?: string;
  address?: string;
  states?: IState[];
  statesToRemove?: IState[];

  accountPermissions: {
    isAdmin: boolean;
    isHipaaAuthorized: boolean;
    canAccessStaff: boolean;
    canAccessClients: boolean;
    canAccessQueue: boolean;
    canAccessMessageCenter: boolean;
    canAccessAlerts: boolean;
    canAccessPhotos: boolean;
    canAccessBulletinBoard: boolean;
  };
  providerPermissions: {
    canAccessProvidersSchedule: boolean;
    canAccessTodaysSchedule: boolean;
    canAccessScheduleAvailability: boolean;
    canAccessPatientAppointments: boolean;
    canAccessProvidersQueue: boolean;
  };
}

export interface IProviderData {
  AccountCount: number;
  CreatedAt: string;
  DefaultScheduleSlotLengthInMinutes: number;
  Deleted: boolean;
  DevicesCount: number;
  ID: string;
  Logo: string;
  Name: string;
  UsersCount: number;
}

export interface IAccountCatalogListItem {
  AccountID: string;
  AccountName: string;
  AlreadyConnected: number;
  DeviceCount: number;
  OnlineDeviceCount: number;
  PatientCount: number;
  UsersCount: number;
}

export interface ProviderStringsResponse {
  LocaleTag: string;
  ProviderID: string;
  UserRoleName: string;
  UserRoleNamePlural: string;

  Tier1?: string;
  Tier1Plural?: string;
  Tier2?: string;
  Tier2Plural?: string;
  Tier3?: string;
  Tier3Plural?: string;
}

export interface ProviderAccountGroupingStringsForm {
  Tier1?: string;
  Tier1Plural?: string;
  Tier2?: string;
  Tier2Plural?: string;
  Tier3?: string;
  Tier3Plural?: string;
}

interface State {
  providers: IProviderListItem[];
  provider: {
    data: IProviderListItem | null;
    state: {
      loading: boolean;
      success: boolean;
      error: boolean;
    };
  };
  queue: ProviderCallRequest[];
  schedule: ProviderSchedule[];
  patients: ProviderPatient[];
}

type Action =
  | {
      type: 'set_providers_list';
      payload: {
        loading: boolean;
        success: boolean;
        error: boolean;
        providers: IProviderListItem[];
      };
    }
  | {
      type: 'set_provider';
      payload: {
        loading: boolean;
        success: boolean;
        error: boolean;
        provider: IProviderListItem | null;
      };
    }
  | { type: 'set_queue'; payload: ProviderCallRequest[] }
  | { type: 'set_schedule'; payload: ProviderSchedule[] }
  | { type: 'set_patients'; payload: ProviderPatient[] }
  | { type: 'unknown' };

type Actions = {
  fetchQueueForProvider: (providerId: string) => Promise<void>;
  fetchScheduleForProvider: (providerId: string) => Promise<void>;
  fetchPatientsForProvider: (providerId: string, search: string, limit: number) => Promise<void>;
};

interface NucleusProvidersContextProps {
  state: State;
  dispatch: React.Dispatch<Action>;
  actions: Actions;
}

const NucleusProvidersContext = createContext<NucleusProvidersContextProps | undefined>(undefined);

const nucleusProvidersReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set_providers_list':
      return {
        ...state,
        ...action.payload,
      };
    case 'set_provider':
      return {
        ...state,
        provider: {
          ...state.provider,
          data: action.payload.provider,
          state: {
            loading: action.payload.loading,
            success: action.payload.success,
            error: action.payload.error,
          },
        },
      };
    case 'set_queue':
      return {
        ...state,
        queue: action.payload,
      };
    case 'set_schedule':
      return {
        ...state,
        schedule: action.payload,
      };
    case 'set_patients':
      return {
        ...state,
        patients: action.payload,
      };
    default:
      console.warn('Unknown action type: ', action.type);
      return state;
  }
};

interface NucleusProvidersProviderProps {
  children: ReactNode;
}

export const NucleusProvidersProvider: React.FC<NucleusProvidersProviderProps> = ({ children }) => {
  const initialState: State = {
    providers: [],
    provider: {
      data: {
        ID: '',
        providerName: '',
        numberOfAccounts: 0,
        numberOfDevices: 0,
        numberOfUsers: 0,
      },
      state: {
        loading: false,
        success: false,
        error: false,
      },
    },
    queue: [],
    schedule: [],
    patients: [],
  };
  const [state, dispatch] = useReducer(nucleusProvidersReducer, initialState);

  const actions = useMemo(
    () => ({
      fetchQueueForProvider: async (providerId: string) => {
        const { data: providerQueue } = await fetchQueueForProvider(providerId);
        dispatch({
          type: 'set_queue',
          payload: providerQueue,
        });
      },
      fetchScheduleForProvider: async (providerId: string) => {
        const { data: providerQueue } = await fetchScheduleForProvider(providerId);
        dispatch({
          type: 'set_schedule',
          payload: providerQueue,
        });
      },
      fetchPatientsForProvider: async (providerId: string, search: string, limit: number) => {
        const { data: patients } = await fetchPatientsForProvider(providerId, search, limit);
        dispatch({
          type: 'set_patients',
          payload: patients,
        });
      },
    }),
    [state, dispatch],
  );

  const contextValue = React.useMemo(() => ({ state, dispatch, actions }), [state, dispatch, actions]);

  return <NucleusProvidersContext.Provider value={contextValue}>{children}</NucleusProvidersContext.Provider>;
};

export const useNucleusProviders = (): NucleusProvidersContextProps => {
  const context = useContext(NucleusProvidersContext);
  if (context === undefined) {
    throw new Error('useNucleusProviders must be used within a NucleusProvidersProvider');
  }
  return context;
};
