import { EventEmitter } from 'events';
import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import { DeviceParticipant, HandleCallPayload } from 'components/Communication/communication.types';
import PatientApi from '../apis/PatientApi';
import backendClient from '../utils/BackendClient';

export type PatientMessage = {
  ID: string;
  MessageID: string;
  CreatedDate: string;
  Timezone: string;
  BiometricTypeID: null;
  DeliveryTimeUTC: string;
  DeliveryTime: string;
  Message: string;
  Editable: boolean;
  Status: string;
  User: string;
  SignatureUrl: string;
  RecurringText: string;
};

export type GetScheduledPatientMessagesResponse = {
  PatientID: string;
  Messages: PatientMessage[];
  ok: boolean;
};
class CarePatientStore extends EventEmitter {
  PatientApi: PatientApi;
  PatientsList: any[];
  PatientsHash: any[];

  constructor() {
    super();

    this.PatientApi = new PatientApi(this);
    try {
      this.PatientsList = JSON.parse(localStorage.PatientsList) || [];
      this.PatientsHash = JSON.parse(localStorage.PatientsHash) || [];
    } catch (e) {
      console.error('Error parsing PatientsList from localStorage', e);
    }
    //console.warn(this);
  }

  getAllPatients() {
    //console.log("getAllPatients", this.PatientsList);
    return this.PatientsList;
  }

  getPatientInfo(patientID) {
    if (this.PatientsHash) {
      return this.PatientsHash[patientID];
    } else {
      return null;
    }
  }

  getPatientDataComplete() {
    // console.log(this.PatientsHash);
    // console.log("----");
    // console.log(this.PatientsList);
    // console.log("------|");
    return Object.keys(this.PatientsHash).length;
  }

  getPatientData({ PatientID }) {
    console.log('getPatientData ', PatientID);

    this.PatientApi.GetCarePatientData({ PatientID, Mode: 'web' }, rsp => {
      //console.log("getPatientData Response", rsp);

      this.emit('onPatientGetData', rsp);
    });
  }

  getPatientBiometricData({ PatientID }) {
    console.log('getPatientBiometricData ', PatientID);

    this.PatientApi.GetPatientBiometricData({ PatientID }, rsp => {
      //console.log("getPatientData Response", rsp);

      this.emit('onPatientGetBiometricData', rsp);
    });
  }

  getPatientCallLogs({ PatientID, From, To }) {
    console.log('getPatientCallLogs ', PatientID, From, To);
    let rsp;
    httpNodeApi
      .get('/patient/' + PatientID + `/call_logs?fromDate=${From}&toDate=${To}`)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error getting patients call logs' };
        console.error('Error getting patients call logs', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('Nucleus getPatientCallLogs finally');
        this.emit('onPatientGetCallLogs', rsp);
      });
  }

  getPatientCallRequestLogs({ PatientID, From, To }) {
    console.log('getPatientCallRequestLogs ', PatientID, From, To);

    this.PatientApi.GetPatientRequestLogs({ PatientID, From, To }, rsp => {
      //console.log("getPatientData Response", rsp);

      this.emit('onPatientGetRequestLogs', rsp);
    });
  }

  refreshPatientCallLogs() {
    this.emit('onRefreshPatientGetCallLogs');
  }

  UpdatePatientData({ PatientID, FirstName, LastName, Telephone, Address, DateOfBirth, EmergencyContactName, EmergencyContactTelephone, ZipCode }) {
    console.log('UpdatePatientData ', PatientID, FirstName, LastName, Telephone, Address, DateOfBirth, EmergencyContactName, EmergencyContactTelephone, ZipCode);

    this.PatientApi.UpdateCarePatientData(
      {
        PatientID,
        FirstName,
        LastName,
        Telephone,
        Address,
        DateOfBirth,
        EmergencyContactName,
        EmergencyContactTelephone,
        ZipCode,
      },
      rsp => {
        //console.log("Nucleus Response", rsp);

        this.emit('onUpdatePatientAction', rsp);
        this.emit('onUpdatePatientResponseArrived', rsp);
      },
    );
  }
  UploadPatientImage({ PatientID, File }) {
    console.log('UploadPatientImage ', PatientID, File);
    this.PatientApi.UploadCarePatientImage({ PatientID, File }, rsp => {
      console.log('UploadCarePatientImage rsp', rsp);
      // this.emit("onUploadPatientImageAction", rsp);
      // this.emit("onUploadPatientImageResponseArrived", rsp);
    });
  }

  updatePatientZipCode({ PatientID, ZipCode }) {
    console.log('updatePatientZipCode ', PatientID, ZipCode);

    this.PatientApi.UpdatePatientZipCode({ PatientID, ZipCode }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onZipCodeUpdated', rsp);
    });
  }

  getAllPatientsData({ UserID }) {
    console.log('getAllPatientsData ', UserID);

    let rsp;
    httpNodeApi
      .get('/user/' + UserID + '/patients?limit=500')
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error getting patients for user  (me)' };
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('Nucleus GetPatientsForUser finally');
        console.log('rsp', rsp);
        if (rsp.ok) {
          this.PatientsList = rsp.patients;
        }

        this.emit('onGetAllPatientsData', {
          success: rsp.ok,
          integrationEnabled: rsp.SalesforceIntegrationEnabled,
          bargeInEnabled: rsp.UserBargeInCallEnabled,
          canImportPatients: rsp.CanImportPatients,
          audioCallsOnly: rsp.AudioCallsOnly,
          allowVideoCalls: rsp.AllowVideoCalls,
        });

        if (rsp.ok && rsp.patients) {
          const patientsArray = [];
          let patientInfo = {};
          for (let i = 0; i < rsp.patients.length; i++) {
            patientInfo = {
              Name: rsp.patients[i].FirstName + ' ' + rsp.patients[i].LastName,
              Telephone: rsp.patients[i].Telephone,
              Address: rsp.patients[i].Address,
            };
            patientsArray[rsp.patients[i].PatientID] = patientInfo;
          }
          this.PatientsHash = patientsArray;
          this.saveToLocalStorage();

          this.emit('onGetAllPatientsDataHashMap');
        }
      });
  }

  setAllowVideoCallsForAccount(AccountID, allowVideoCalls, responseCallback) {
    const AccountConfig = {
      AllowVideoCalls: allowVideoCalls,
    };

    let rsp;
    httpNodeApi
      .put(`account/${AccountID}/update_account_config`, AccountConfig)
      .then(response => {
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response;
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        responseCallback(rsp);
      });
  }

  createNewPatient({ UserID, AccountID, FirstName, LastName, DateOfBirth, Telephone, Address, Imported }, callback) {
    console.log('createNewPatient', UserID, AccountID, FirstName, LastName, DateOfBirth, Telephone, Address);
    {
      this.PatientApi.CreateNewPatient(
        {
          UserID,
          AccountID,
          FirstName,
          LastName,
          DateOfBirth,
          Address,
          Telephone,
          Imported,
        },
        rsp => {
          //console.log("Nucleus Response", rsp);
          //this.emit("onCreatePatientAction", rsp);
          callback(rsp);
        },
      );
    }
  }

  CreateNewPatient({ UserID, AccountID, FirstName, LastName, Email, Telephone, Address, DateOfBirth, EmergencyContactName, EmergencyContactTelephone, DeviceID }) {
    console.log('UserID', UserID);
    console.log('AccountID', AccountID);
    console.log('FirstName', FirstName);
    console.log('LastName', LastName);
    console.log('Email', Email);
    console.log('Telephone', Telephone);
    console.log('Address', Address);
    console.log('DateOfBirth', DateOfBirth);
    console.log('EmergencyContactName', EmergencyContactName);
    console.log('EmergencyContactTelephone', EmergencyContactTelephone);
    console.log('DeviceID', DeviceID);

    this.PatientApi.CreateNewPatient(
      {
        UserID,
        AccountID,
        FirstName,
        LastName,
        Address,
        Telephone,
        DateOfBirth, // Deprecated > Activated again: Oct 10 2023
        EmergencyContactName, // Deprecated
        EmergencyContactTelephone, // Deprecated
      },
      rsp => {
        //console.log("Nucleus Response", rsp);

        this.emit('onCreatePatientAction', rsp);
      },
    );
  }

  getCareDevices({ AccountID }) {
    console.log('getCareDevices ', AccountID);
    this.PatientApi.GetCareDevices({ AccountID }, rsp => {
      //console.log("Nucleus Response", rsp);
      this.emit('onGetDevicesAction', rsp);
    });
  }

  getCareDevicesWarming({ AccountID }) {
    console.log('getCareDevicesWarming ', AccountID);
    this.PatientApi.GetCareDevices({ AccountID }, () => {
      //console.log("Nucleus Response", rsp);
    });
  }

  assignDeviceToPatient({ PatientID, DeviceID, DeviceName }) {
    backendClient.put(
      `patient/${PatientID}/devices/${DeviceID}?deviceName=${DeviceName}`,
      {
        deviceName: DeviceName,
      },
      rsp => {
        this.emit('onAssignDevicePatientAction', rsp);
      },
    );
  }

  updatePatientDevice({ DeviceID, Name, DeviceLabel }) {
    console.log('updatePatientDevice ', DeviceID, Name, DeviceLabel);

    this.PatientApi.UpdateDeviceName({ DeviceID, Name, DeviceLabel }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onDeviceUpdated', rsp);
    });
  }

  unassignDeviceToPatient({ PatientID, DeviceID }) {
    backendClient.delete(`patient/${PatientID}/devices/${DeviceID}`, rsp => {
      this.emit('onUnassignDevicePatientAction', rsp);
      this.emit('onUnassignDevicePatientAction2', rsp);
    });
  }

  getCallLogDetails({ LogID, LogStartTime, LogStatus, LogDuration, LogParticipants, LogRequest, Notes, NotesUserID }) {
    console.log('getCallLogDetails ', LogID, Notes, NotesUserID);

    this.PatientApi.GetCallLogNotes({ CallLogID: LogID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onOpenCallLogDetailsAction', {
        LogID,
        ok: rsp.ok,
        LogStartTime,
        LogStatus,
        LogDuration,
        LogParticipants,
        LogRequest,
        Notes: rsp.Notes,
        NotesUserID,
      });
    });
  }

  getCallLogDetailsData({ LogID, PatientID, TimeZone }) {
    console.log('getCallLogDetailsData ', LogID, PatientID, TimeZone);

    this.PatientApi.GetCallLogDetailsData({ CallLogID: LogID, PatientID }, rsp => {
      console.log('getCallLogDetailsData Nucleus Response', rsp);
      let callDuration = '';

      if (rsp.WasAnswered && rsp.EndTime && rsp.EndTime > 0) {
        // get total seconds between the times
        let delta = Math.abs(Number(new Date(rsp.EndTime)) - Number(new Date(rsp.StartTime))) / 1000;
        // calculate (and subtract) whole days
        const days = Math.floor(delta / 86400);
        delta -= days * 86400;
        // calculate (and subtract) whole hours
        const hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        // calculate (and subtract) whole minutes
        const minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        // what's left is seconds
        const seconds = Math.floor(delta % 60);

        callDuration = this.pad2(hours) + ':' + this.pad2(minutes) + ':' + this.pad2(seconds);
      }

      const responseCallLogData = {
        ok: rsp.ok,
        LogID: rsp.ID,
        LogStartTime: rsp.DateString + ' - ' + rsp.TimeString + ' - ' + rsp.patientTimezone,
        LogStatus: rsp.Status || '',
        LogDuration: callDuration,
        LogParticipants: rsp.Participants,
        LogRequest: {
          ID: rsp.RequestID,
          Type: rsp.RequestType,
          Time: rsp.RequestTime + ' - ' + rsp.patientTimezone,
          Escalated: rsp.RequestEscalated,
        },
        Notes: rsp.Notes,
        NotesUserID: rsp.NotesUserID,
      };

      //this.emit("onOpenCallLogDetailsAction", {LogID, ok: rsp.ok, LogStartTime, LogStatus, LogDuration, LogParticipants, LogRequest, Notes: rsp.Notes, NotesUserID});
      this.emit('onOpenCallLogDetailsAction', responseCallLogData);
    });
  }

  openCallRequestLogDetails(requestData) {
    console.log('openCallRequestLogDetails ', requestData);

    this.PatientApi.GetCallRequestLogDetailsNotes({ RequestCallLogID: requestData.ID }, rsp => {
      console.log('Nucleus Response', rsp);
      if (rsp.ok) {
        requestData['Notes'] = rsp.Notes;
        requestData['NotesUserID'] = rsp.NotesUserID;
        requestData['CallLogData'] = rsp.CallLogData;
      }

      this.emit('onGetCallRequestLogDetails', requestData);
    });
  }

  getCallRequestLogDetailsData(requestData) {
    console.log('getCallRequestLogDetailsData', requestData);

    this.PatientApi.GetCallRequestLogDetailsData({ RequestCallLogID: requestData.ID }, rsp => {
      console.log('Nucleus Response', rsp);
      requestData['ok'] = rsp.ok;

      if (rsp.ok) {
        requestData['RequestDateTime'] = rsp.RequestTime + ' - ' + requestData.PatientTimeZone;
        requestData['Emergency'] = rsp.IsEmergency;
        requestData['Status'] = rsp.Status;
        requestData['HandledDateTime'] = rsp.HandledTime + ' - ' + requestData.PatientTimeZone;
        requestData['HandlerName'] = rsp.HandlerName;
        requestData['Escalated'] = rsp.EscalationCallRequestSent ? 'Y' : 'N';
        requestData['Num'] = rsp.NumOfRequests;

        requestData['Notes'] = rsp.Notes;
        requestData['NotesUserID'] = rsp.NotesUserID;
        requestData['CallLogData'] = rsp.CallLogData;
      }

      this.emit('onGetCallRequestLogDetails', requestData);
    });
  }

  deletePatient({ PatientID }) {
    console.log('deletePatient ', PatientID);

    this.PatientApi.DeletePatient({ PatientID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onDeletePatientAction', rsp);
    });
  }

  async reportCallStarted({ PatientID, UserID, RequestID }) {
    console.log('reportCallStarted ', PatientID, UserID, RequestID);

    const rsp = await this.PatientApi.ReportCareCallStarted({ PatientID, UserID, RequestID });
    this.emit('onReportCallStartedAction', rsp);
  }

  async reportCallEnded({ CallLogID, WasAnswered }) {
    console.log('reportCallEnded ', CallLogID, WasAnswered);

    const data = await this.PatientApi.ReportCareCallEnded({ CallLogID, WasAnswered });
    this.emit('onReportCallEndedAction', data);
  }

  updateCallLogNotes({ CallLogID, Notes, UserID }) {
    console.log('updateCallLogNotes ', CallLogID, Notes, UserID);

    this.PatientApi.UpdateCallLogNotes({ CallLogID, Notes, UserID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdateCallLogNotesAction', rsp);
    });
  }

  updateCallRequestLogNotes({ CallRequestLogID, Notes, UserID }) {
    console.log('updateCallRequestLogNotes ', CallRequestLogID, Notes, UserID);

    this.PatientApi.UpdateCallRequestLogNotes({ CallRequestLogID, Notes, UserID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdateCallRequestLogNotes', rsp);
    });
  }

  getPatientDevices({ PatientID }) {
    //console.log("getPatientDevices ", PatientID, ShowAll);
    let rsp;
    httpNodeApi
      .get('/patient/' + PatientID + '/devices')
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error getting devices for patient:' + PatientID };
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('Nucleus GetPatientDevices finally');
        this.emit('onGetPatientDevices', rsp);
      });


    // this.PatientApi.GetPatientDevices({ PatientID }, rsp => {
    //   //console.log("Nucleus Response", rsp);
    //   this.emit('onGetPatientDevices', rsp);
    // });
  }

  updatePatientDevicePhotoFrameMode(deviceId:string, newValue:boolean, responseCallback:(rsp:any)=>void){
    const body = {
      enablePhotoFrameMode: newValue,
    };
    httpNodeApi
    .put(`device/${deviceId}/photo-frame-mode`, body)
    .then(response => {
      const { data } = response;
      const rsp = {
        ok: true,
        ...data,
      };
      responseCallback(rsp);  
    })
    .catch(e => {
      const { data } = e.response;
      const rsp = {
        ok: false,
        ...data,
      };
      responseCallback(rsp);
    });
  }

  getPatientAssignments({ PatientID, ShowAll }) {
    //console.log("getPatientAssignments ", PatientID, ShowAll);

    this.PatientApi.GetPatientAssignments({ PatientID, ShowAll }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onGetPatientAssignments', rsp);
    });
  }

  getFamilyMembers({ PatientID }) {
    console.log('getFamilyMembers ', PatientID);

    let rsp;
    httpNodeApi
      .get('/patient/' + PatientID + '/family')
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error getting patients for user  (me)' };
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('Nucleus GetPatientFamilyMembers finally');
        console.log('rsp', rsp);
        this.emit('onGetPatientFamilyMembers', rsp);
      });

    // this.PatientApi.GetPatientFamilyMembers({ PatientID }, (rsp) => {
    //   this.emit("onGetPatientFamilyMembers", rsp);
    // });
  }

  checkExistingTelephone({ Telephone }) {
    console.log('checkExistingTelephone ', Telephone);

    this.PatientApi.CheckExistingTelephone({ Telephone }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onCheckExistingTelephone', rsp);
    });
  }

  createNewFamilyMember({ PatientID, FirstName, LastName, Email, Telephone, Address, ReceiveUpdates }) {
    console.log('createNewFamilyMember ', PatientID, FirstName, LastName, Email, Telephone, Address, ReceiveUpdates);

    this.PatientApi.CreateNewFamilyMember(
      {
        PatientID,
        FirstName,
        LastName,
        Email,
        Telephone,
        Address,
        ReceiveUpdates,
      },
      rsp => {
        //console.log("Nucleus Response", rsp);

        this.emit('onCreateNewFamilyMember', rsp);
      },
    );
  }

  assignPatientMember({ FamilyMemberID, PatientID }) {
    console.log('assignPatientMember ', FamilyMemberID, PatientID);

    this.PatientApi.AssignPatientMember({ FamilyMemberID, PatientID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onPatientMemberAssigned', rsp);
    });
  }

  updateFamilyMember({ FamilyMemberID, FirstName, LastName, Telephone, Email, Password }) {
    console.log('updateFamilyMember ', FamilyMemberID, FirstName, LastName, Telephone, Email, Password);

    this.PatientApi.UpdateFamilyMember({ FamilyMemberID, FirstName, LastName, Telephone, Email, Password }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdateFamilyMember', rsp);
    });
  }

  updateFamilyMemberName({ FamilyMemberID, FirstName, LastName }) {
    console.log('updateFamilyMemberName ', FamilyMemberID, FirstName, LastName);

    this.PatientApi.UpdateFamilyMemberName({ FamilyMemberID, FirstName, LastName }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdateFamilyMemberName', rsp);
    });
  }

  updateFamilyMemberTelephone({ FamilyMemberID, Telephone }) {
    console.log('updateFamilyMemberTelephone ', FamilyMemberID, Telephone);

    this.PatientApi.UpdateFamilyMemberTelephone({ FamilyMemberID, Telephone }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdateFamilyMemberTelephone', rsp);
    });
  }

  deleteFamilyMember({ FamilyMemberID, PatientID }) {
    console.log('deleteFamilyMember ', FamilyMemberID, PatientID);

    this.PatientApi.DeleteFamilyMember({ FamilyMemberID, PatientID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onDeleteFamilyMember', rsp);
    });
  }

  reSendInvite({ FamilyMemberID }) {
    console.log('reSendInvite ', FamilyMemberID);

    this.PatientApi.RequestResendInvite({ FamilyMemberID }, rsp => {
      console.log('reSendInvite Response');
      console.log(rsp);

      this.emit('onResendInvite', rsp);
    });
  }

  getPatientNotes({ PatientID, From, To }) {
    console.log('getPatientNotes ', PatientID, From, To);

    this.PatientApi.GetPatientNotes({ PatientID, From, To }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onGetPatientNotes', rsp);
    });
  }

  addPatientNote({ PatientID, UserID, Note }) {
    console.log('addPatientNote ', PatientID, UserID, Note);

    this.PatientApi.AddPatientNote({ PatientID, UserID, Note }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onAddPatientNote', rsp);
    });
  }

  updatePatientNote({ ID, Note, UserID }) {
    console.log('updatePatientNote ', ID, Note, UserID);

    this.PatientApi.UpdatePatientNote({ ID, Note, UserID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdatePatientNote', rsp);
    });
  }

  deletePatientNote({ ID }) {
    console.log('deletePatientNote ', ID);

    this.PatientApi.DeletePatientNote({ ID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onDeletePatientNote', rsp);
    });
  }

  updatePatientMessage({ ID, Message }) {
    console.log('updatePatientMessage ', ID, Message);

    this.PatientApi.UpdatePatientMessage({ ID, Message }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onUpdatePatientMessage', rsp);
    });
  }
  deletePatientMessage({ MessageID }) {
    console.log('deletePatientMessage ', MessageID);

    this.PatientApi.DeletePatientMessage({ MessageID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onDeletePatientMessage', rsp);
    });
  }

  sendMessageToDevice({ PatientID, UserID, Message }) {
    console.log('sendMessageToDevice ', PatientID, UserID, Message);

    this.PatientApi.SendMessageToDevice({ PatientID, UserID, Message }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onMessageToDeviceSent', rsp);
    });
  }

  sendMessageToPatient({ PatientID, UserID, Message, DeliveryTime }) {
    console.log('sendMessageToPatient ', PatientID, UserID, Message, DeliveryTime);

    this.PatientApi.SendMessageToPatient({ PatientID, UserID, Message, DeliveryTime }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onMessageToPatientSent', rsp);
    });
  }

  sendBroadcastMessage({ UserID, Message, DeliveryTime }) {
    console.log('sendBroadcastMessage ', UserID, Message, DeliveryTime);

    this.PatientApi.SendBroadcastMessage({ UserID, Message, DeliveryTime }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onBroadcastMessageSent', rsp);
    });
  }

  getPatientMessages({ PatientID }) {
    //console.log("getPatientMessages ", PatientID);

    this.PatientApi.GetPatientMessages({ PatientID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onGetPatientMessages', rsp);
    });
  }

  getDeliveredPatientMessages({ PatientID, FromDateTime, ToDateTime }) {
    console.log('getDeliveredPatientMessages ', PatientID, FromDateTime, ToDateTime);

    this.PatientApi.GetDeliveredPatientMessages({ PatientID, FromDateTime, ToDateTime }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onGetDeliveredPatientMessages', rsp);
    });
  }

  getScheduledPatientMessages({ PatientID }) {
    //console.log("getScheduledPatientMessages ", PatientID);

    this.PatientApi.GetScheduledPatientMessages({ PatientID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onGetScheduledPatientMessages', rsp);
    });
  }

  handlePatientProfileInfoRefresh({ DeviceID, DeviceMac }) {
    console.log('handlePatientProfileInfoRefresh', DeviceID, DeviceMac);

    this.emit('onHandleRefresh', {
      DeviceID: DeviceID,
      DeviceMac: DeviceMac,
    });
  }

  handlePatientProfileInfoAudioCall({ Devices, PatientID, ConnectDevice }: { Devices: DeviceParticipant[]; PatientID?: string; ConnectDevice?: boolean }) {
    console.log('handlePatientProfileInfoAudioCall', Devices);

    this.emit<HandleCallPayload>('onHandleAudioCall', {
      Devices,
      PatientID,
      ConnectDevice,
    });
  }

  handlePatientProfileInfoVideoCall({ Devices, PatientID, ConnectDevice }: { Devices: DeviceParticipant[]; PatientID?: string; ConnectDevice?: boolean }) {
    console.log('handlePatientProfileInfoVideoCall', Devices);

    this.emit<HandleCallPayload>('onHandleVideoCall', {
      Devices: Devices,
      PatientID: PatientID,
      ConnectDevice: ConnectDevice,
    });
  }

  handlePatientProfileInfoBargeCall({ Devices, PatientID, ConnectDevice }: { Devices: DeviceParticipant[]; PatientID?: string; ConnectDevice?: boolean }) {
    console.log('handlePatientProfileInfoBargeCall', Devices);

    this.emit('onHandleBargeCall', {
      Devices,
      PatientID: PatientID,
      ConnectDevice: ConnectDevice,
    });
  }

  handlePatientProfileInfoSilentBargeCall({ Devices, PatientID, ConnectDevice }: { Devices: DeviceParticipant[]; PatientID?: string; ConnectDevice?: boolean }) {
    console.log('handlePatientProfileInfoBargeCall', Devices);

    this.emit('onHandleSilentBargeCall', {
      Devices,
      PatientID: PatientID,
      ConnectDevice: ConnectDevice,
    });
  }

  handlePatientProfileEditDevice({ DeviceID, DeviceMac, DeviceName, DeviceVolume, DeviceConcierge, DeviceLabel, DeviceOS, HexnodeID, IsLegacyDevice, PhotoFrameMode }) {
    console.log('handlePatientProfileEditDevice', DeviceID, DeviceMac, DeviceName, DeviceVolume, DeviceConcierge, DeviceLabel, DeviceOS, HexnodeID, IsLegacyDevice, PhotoFrameMode);

    this.emit('onOpenEditPatientDeviceModal', {
      DeviceID: DeviceID,
      DeviceMac: DeviceMac,
      DeviceName: DeviceName,
      DeviceLabel: DeviceLabel,
      DeviceVolume: DeviceVolume,
      HexnodeID,
      DeviceConcierge: DeviceConcierge,
      DeviceOS: DeviceOS,
      IsLegacyDevice: IsLegacyDevice,
      PhotoFrameMode: PhotoFrameMode
    });
  }

  handleRequestOpenButtonModal({ Mac, Type, Location }) {
    console.log('handleRequestOpenButtonModal', Mac, Type, Location);

    this.emit('onOpenButtonModal', {
      Mac: Mac,
      Type: Type,
      Location: Location,
    });
  }

  launchRequestRestart({ DeviceID, DeviceMac, HexnodeID }) {
    console.log('launchRequestRestart', DeviceID, DeviceMac, HexnodeID);

    this.emit('onRequestRestartDevice', {
      DeviceID: DeviceID,
      DeviceMac: DeviceMac,
      HexnodeID: HexnodeID,
    });
  }

  updateDeviceVolume({ DeviceID, RingtoneVolume }) {
    console.log('updateDeviceVolume ', DeviceID, RingtoneVolume);

    this.PatientApi.UpdateDeviceVolume({ DeviceID, RingtoneVolume }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['deviceID'] = DeviceID;
      rsp['volume'] = RingtoneVolume;
      this.emit('onDeviceVolumeUpdated', rsp);
      this.emit('onSelectedDeviceVolumeUpdated', rsp);
    });
  }

  // DEPRECATED: November 2nd 2021
  // updateDeviceConcierge({ DeviceID, Concierge }) {
  //   console.log("updateDeviceConcierge ", DeviceID, Concierge);

  //   this.PatientApi.UpdateDeviceConcierge({ DeviceID, Concierge }, (rsp) => {
  //     //console.log("Nucleus Response", rsp);

  //     rsp["deviceID"] = DeviceID;
  //     rsp["enable"] = Concierge;
  //     this.emit("onSelectedDeviceConciergeUpdated", rsp);
  //     this.emit("onDeviceConciergeUpdated", rsp);
  //   });
  // }

  updateDeviceCallShortcut({ DeviceID, PatientID, Value }) {
    console.log('updateDeviceCallShortcut ', DeviceID, PatientID, Value);

    this.PatientApi.UpdateDeviceCallShortcut({ DeviceID, PatientID, Value }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['deviceID'] = DeviceID;
      rsp['patientID'] = PatientID;
      rsp['enable'] = Value;
      this.emit('onSelectedDeviceCallShortcutUpdated', rsp);
      //this.emit("onDeviceUpdated", rsp);
    });
  }

  // CONFIG PATIENT

  getPatientAccountLocalesData(accountId) {
    console.log('getPatientAccountLocalesData', accountId);

    // this.PatientApi.GetLocalesData({ },(rsp) => {
    // 	this.emit("onGetPatientAccountLocalesData", rsp);
    // });
    let rsp;
    httpNodeApi
      .get(`account/${accountId}/get_languages`)
      .then(response => {
        //console.log("/user/" + UserID + "/patients Response:", response);
        const { data } = response;
        rsp = {
          ok: true,
          ...data,
        };
      })
      .catch(e => {
        const { data } = e.response || { error: 'Error getting patients account locales' };
        console.error('Error getting patients account locales', data);
        rsp = {
          ok: false,
          ...data,
        };
      })
      .finally(() => {
        console.log('Nucleus getPatientAccountLocales finally');
        this.emit('onGetPatientAccountLocalesData', rsp);
      });
  }

  updatePatientLocale({ PatientID, LocaleTag }) {
    console.log('updatePatientLocale ', PatientID, LocaleTag);
    const endpoint = 'CarePatient.asmx/UpdateLocaleTag';
    this.PatientApi.UpdatePatientConfigString(
      {
        PatientID,
        ConfigString: LocaleTag,
      },
      endpoint,
      rsp => {
        //console.log("Nucleus Response", rsp);
        rsp['locale'] = LocaleTag;
        this.emit('onPatientLocaleUpdated', rsp);
      },
    );
  }

  updatePatientAllowVideoCall({ PatientID, Enabled }) {
    console.log('updateShowCallButton ', PatientID, Enabled);
    const endpoint = 'CarePatient.asmx/UpdateEnableVideoCall';
    this.PatientApi.UpdatePatientConfigFlag({ PatientID, Enabled }, endpoint, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enabled'] = Enabled;
      this.emit('onAllowVideoCallUpdated', rsp);
    });
  }

  updateShowCallButton({ PatientID, ShowCallButton }) {
    console.log('updateShowCallButton ', PatientID, ShowCallButton);

    this.PatientApi.UpdateShowCallButton({ PatientID, ShowCallButton }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['show'] = ShowCallButton;
      this.emit('onShowCallButtonUpdated', rsp);
    });
  }

  updateShowCallButton2({ PatientID, ShowCallButton2 }) {
    console.log('updateShowCallButton2 ', PatientID, ShowCallButton2);

    this.PatientApi.UpdateShowCallButton2({ PatientID, ShowCallButton2 }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['show'] = ShowCallButton2;
      this.emit('onShowCallButton2Updated', rsp);
    });
  }

  updateShowCheckInButton({ PatientID, ShowCheckInButton }) {
    console.log('updateShowCheckInButton ', PatientID, ShowCheckInButton);

    this.PatientApi.UpdateShowCheckInButton({ PatientID, ShowCheckInButton }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['show'] = ShowCheckInButton;
      this.emit('onShowCheckInButtonUpdated', rsp);
    });
  }

  updateShowEmergencyButton({ PatientID, ShowEmergencyButton }) {
    console.log('updateShowEmergencyButton ', PatientID, ShowEmergencyButton);

    this.PatientApi.UpdateShowEmergencyButton({ PatientID, ShowEmergencyButton }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['show'] = ShowEmergencyButton;
      this.emit('onShowEmergencyButtonUpdated', rsp);
    });
  }

  updateShowWeather({ PatientID, ShowWeather }) {
    console.log('updateShowWeather ', PatientID, ShowWeather);

    this.PatientApi.UpdateShowWeather({ PatientID, ShowWeather }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['show'] = ShowWeather;
      this.emit('onShowWeatherUpdated', rsp);
    });
  }

  updateShowCallControls({ PatientID, ShowCallButtonsBar }) {
    console.log('updateShowCallControls ', PatientID, ShowCallButtonsBar);

    this.PatientApi.UpdateShowCallControls({ PatientID, ShowCallButtonsBar }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['show'] = ShowCallButtonsBar;
      this.emit('onShowCallControlsUpdated', rsp);
    });
  }

  updateCallPhoneFromDevice({ PatientID, CallPhoneFromDevice }) {
    console.log('updateCallPhoneFromDevice ', PatientID, CallPhoneFromDevice);

    this.PatientApi.UpdateCallPhoneFromDevice({ PatientID, CallPhoneFromDevice }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = CallPhoneFromDevice;
      this.emit('onCallPhoneFromDeviceUpdated', rsp);
    });
  }

  deleteFamilyPhotos({ PatientID }) {
    console.log('deleteFamilyPhotos ', PatientID);

    this.PatientApi.deleteFamilyPhotos({ PatientID }, rsp => {
      //console.log("Nucleus Response", rsp);
      this.getPatientData({ PatientID });
      this.emit('onPatientPhotosDeleted', rsp);
    });
  }

  deleteFamilyMembers({ PatientID }) {
    console.log('deleteFamilyMembers ', PatientID);

    this.PatientApi.deleteFamilyMembers({ PatientID }, rsp => {
      console.log('Nucleus Response', rsp);
      this.emit('onPatientFamilyDeleted');
      this.getFamilyMembers({ PatientID });
    });
  }

  updateEnableMemoryBox({ PatientID, EnableMemoryBox }) {
    console.log('updateEnableMemoryBox ', PatientID, EnableMemoryBox);

    this.PatientApi.UpdateEnableMemoryBox({ PatientID, EnableMemoryBox }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = EnableMemoryBox;
      this.emit('onEnableMemoryBoxUpdated', rsp);
    });
  }

  updateEnableBlankMemoryBox({ PatientID, EnableBlankMemoryBox }) {
    console.log('updateEnableBlankMemoryBox ', PatientID, EnableBlankMemoryBox);

    this.PatientApi.UpdateEnableBlankMemoryBox({ PatientID, EnableBlankMemoryBox }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = EnableBlankMemoryBox;
      this.emit('onEnableBlankMemoryBoxUpdated', rsp);
    });
  }

  updateEnableExternalApps({ PatientID, EnableExternalApps, callback }) {
    console.log('updateEnableExternalApps ', PatientID, EnableExternalApps);
    this.PatientApi.UpdateEnableExternalApps({ PatientID, EnableExternalApps }, rsp => {
      rsp['enable'] = EnableExternalApps;
      callback && callback(rsp);
    });
  }

  updateZipCode({ PatientID, ZipCode }) {
    console.log('updateZipCode ', PatientID, ZipCode);

    this.PatientApi.UpdateZipCode({ PatientID, ZipCode }, rsp => {
      //console.log("Nucleus Response", rsp);
      this.emit('onZipCodeUpdated', rsp);
    });
  }

  updateEnableBiometrics({ PatientID, EnableBiometrics }) {
    console.log('updateEnableBiometrics ', PatientID, EnableBiometrics);

    this.PatientApi.UpdateEnableBiometrics({ PatientID, EnableBiometrics }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = EnableBiometrics;
      this.emit('onEnableBiometricsUpdated', rsp);
    });
  }

  updateEnableBluetoothButtons({ PatientID, EnableBluetoothButtons }) {
    console.log('updateEnableBluetoothButtons ', PatientID, EnableBluetoothButtons);

    this.PatientApi.UpdateEnableBluetoothButtons({ PatientID, EnableBluetoothButtons }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = EnableBluetoothButtons;
      this.emit('onEnableBluetoothButtonsUpdated', rsp);
    });
  }

  updateEnableAutoAnswer({ PatientID, EnableAutoAnswer }) {
    console.log('updateEnableAutoAnswer ', PatientID, EnableAutoAnswer);

    this.PatientApi.UpdateEnableAutoAnswer({ PatientID, EnableAutoAnswer }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = EnableAutoAnswer;
      this.emit('onEnableAutoAnswerUpdated', rsp);
    });
  }

  updateEnableAppIncomingCall({ PatientID, EnableAppIncomingCall }) {
    console.log('updateEnableAppIncomingCall ', PatientID, EnableAppIncomingCall);

    this.PatientApi.UpdateEnableAppIncomingCall({ PatientID, EnableAppIncomingCall }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = EnableAppIncomingCall;
      this.emit('onEnableAppIncomingCallUpdated', rsp);
    });
  }

  updateEnableImpairedRingtone({ PatientID, Enable }) {
    console.log('updateEnableImpairedRingtone ', PatientID, Enable);

    this.PatientApi.UpdateEnableImpairedRingtone({ PatientID, Enable }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = Enable;
      this.emit('onEnableImpairedRingtoneUpdated', rsp);
    });
  }

  updateFamilyMemberEnableAutoAnswer({ PatientID, FamilyID, EnableAutoAnswer }) {
    console.log('updateFamilyMemberEnableAutoAnswer ', PatientID, FamilyID, EnableAutoAnswer);

    this.PatientApi.UpdateFamilyEnableAutoAnswer({ PatientID, FamilyID, EnableAutoAnswer }, rsp => {
      //console.log("Nucleus Response", rsp);

      rsp['enable'] = EnableAutoAnswer;
      this.emit('onEnableFamilyMemberAutoAnswerUpdated', rsp);
    });
  }

  updateFamilyMemberAdmin({ PatientID, FamilyID, Admin }) {
    console.log('updateFamilyMemberAdmin ', PatientID, FamilyID, Admin);

    this.PatientApi.UpdateFamilyAdmin({ PatientID, FamilyID, Admin }, rsp => {
      rsp['admin'] = Admin;
      this.emit('onFamilyMemberAdminUpdated', rsp);
    });
  }

  updateBloodPressureMetrics({ BloodPressureAlertID, Active, DiastolicAbove, DiastolicBelow, HeartRateAbove, HeartRateBelow, SystolicAbove, SystolicBelow }) {
    console.log('updateBloodPressureMetrics ', BloodPressureAlertID, Active, DiastolicAbove, DiastolicBelow, HeartRateAbove, HeartRateBelow, SystolicAbove, SystolicBelow);

    this.PatientApi.UpdateBloodPressureMetrics(
      {
        BloodPressureAlertID,
        Active,
        DiastolicAbove,
        DiastolicBelow,
        HeartRateAbove,
        HeartRateBelow,
        SystolicAbove,
        SystolicBelow,
      },
      rsp => {
        //console.log("Nucleus Response", rsp);

        //rsp["enable"] = BloodPressureMetrics;
        this.emit('onBloodPressureMetricsUpdated', rsp);
      },
    );
  }

  updateOxiMeterMetrics({ OxiMeterAlertID, Active, HeartRateAbove, HeartRateBelow, SPO2Above, SPO2Below, RespAbove, RespBelow }) {
    console.log('updateOxiMeterMetrics ', OxiMeterAlertID, Active, HeartRateAbove, HeartRateBelow, SPO2Above, SPO2Below, RespAbove, RespBelow);

    this.PatientApi.UpdateOxiMeterMetrics(
      {
        OxiMeterAlertID,
        Active,
        HeartRateAbove,
        HeartRateBelow,
        SPO2Above,
        SPO2Below,
        RespAbove,
        RespBelow,
      },
      rsp => {
        //console.log("Nucleus Response", rsp);

        //rsp["enable"] = BloodPressureMetrics;
        this.emit('onOxiMeterMetricsUpdated', rsp);
      },
    );
  }

  updateScaleMetrics({ ScaleAlertID, Active, WeightLBAbove, WeightLBBelow, TimeFrameForChangeCheck }) {
    console.log('updateScaleMetrics ', ScaleAlertID, Active, WeightLBAbove, WeightLBBelow, TimeFrameForChangeCheck);

    this.PatientApi.UpdateScaleMetrics(
      {
        ScaleAlertID,
        Active,
        WeightLBAbove,
        WeightLBBelow,
        TimeFrameForChangeCheck,
      },
      rsp => {
        //console.log("Nucleus Response", rsp);

        //rsp["enable"] = BloodPressureMetrics;
        this.emit('onScaleMetricsUpdated', rsp);
      },
    );
  }

  updateTemperatureMetrics({ TemperatureAlertID, Active, TemperatureAbove, TemperatureBelow }) {
    console.log('updateTemperatureMetrics ', TemperatureAlertID, Active, TemperatureAbove, TemperatureBelow);

    this.PatientApi.UpdateTemperatureMetrics({ TemperatureAlertID, Active, TemperatureAbove, TemperatureBelow }, rsp => {
      //console.log("Nucleus Response", rsp);

      //rsp["enable"] = BloodPressureMetrics;
      this.emit('onTemperatureMetricsUpdated', rsp);
    });
  }

  updateBloodSugarAlert({ BloodSugarAlertID, Active, SugarLevelBelow, SugarLevelAbove }) {
    console.log('updateBloodSugarAlert ', BloodSugarAlertID, Active, SugarLevelBelow, SugarLevelAbove);

    this.PatientApi.UpdateBloodSugarAlert({ BloodSugarAlertID, Active, SugarLevelBelow, SugarLevelAbove }, rsp => {
      //console.log("Nucleus Response", rsp);
      //rsp["enable"] = BloodPressureMetrics;
      this.emit('onBloodSugarAlertUpdated', rsp);
    });
  }

  updateHoursSleptAlert({ HoursSleptAlertID, Active, HoursSleptBelow, HoursSleptAbove }) {
    console.log('updateHoursSleptAlert ', HoursSleptAlertID, Active, HoursSleptBelow, HoursSleptAbove);

    this.PatientApi.UpdateHoursSleptAlert({ HoursSleptAlertID, Active, HoursSleptBelow, HoursSleptAbove }, rsp => {
      //console.log("Nucleus Response", rsp);
      //rsp["enable"] = BloodPressureMetrics;
      this.emit('onHoursSleptAlertUpdated', rsp);
    });
  }

  openDevicesSubTab() {
    this.emit('onOpenDevicesSubTab');
  }

  openBiometricsAlertsSubTab() {
    this.emit('onOpenBiometricsAlertsSubTab');
  }

  openButtonsSubTab() {
    this.emit('onOpenButtonsSubTab');
  }

  loadPatientRequestLogs() {
    this.emit('loadPatientRequestLogs');
  }

  getPatientSchedule({ PatientID }) {
    console.log('getPatientSchedule ', PatientID);

    this.PatientApi.GetPatientSchedule({ PatientID }, rsp => {
      this.emit('onGetPatientSchedule', rsp);
    });
  }
  addNewSchedule({ PatientID, Day, From, To }) {
    console.log('addNewSchedule ', PatientID, Day, From, To);

    this.PatientApi.AddNewPatientSchedule({ PatientID, Day, From, To }, rsp => {
      this.emit('onNewPatientScheduleAdded', rsp);
    });
  }

  handleOpenEditScheduleModal({ ID, Day, From, To, Source }) {
    console.log('handleOpenEditScheduleModal', ID, Day, From, To, Source);

    this.emit('onOpenEditScheduleModal', {
      ScheduleID: ID,
      ScheduleDay: Day,
      ScheduleFrom: From,
      ScheduleTo: To,
      ScheduleSource: Source,
    });
  }
  editSchedule({ ID, Day, From, To }) {
    console.log('editSchedule ', ID, Day, From, To);

    this.PatientApi.EditPatientSchedule({ ID, Day, From, To }, rsp => {
      this.emit('onPatientScheduleEdited', rsp);
    });
  }

  handleOpenDeleteScheduleModal({ ID, Day, From, To, Source }) {
    console.log('handleOpenDeleteScheduleModal', ID, Day, From, To, Source);

    this.emit('onOpenRemoveScheduleModal', {
      ScheduleID: ID,
      ScheduleDay: Day,
      ScheduleFrom: From,
      ScheduleTo: To,
      ScheduleSource: Source,
    });
  }

  removeSchedule({ ID }) {
    console.log('removeSchedule ', ID);

    this.PatientApi.RemovePatientSchedule({ ID }, rsp => {
      this.emit('onPatientScheduleRemoved', rsp);
    });
  }

  copyDefaultSchedule({ PatientID, Day, From, To }) {
    console.log('copyDefaultSchedule ', PatientID, Day, From, To);

    this.PatientApi.CopySchedule({ PatientID, Day, From, To }, rsp => {
      this.emit('onDefaultScheduleCopied', rsp);
    });
    //this.emit("onDefaultScheduleCopied", {});
  }

  copyModifiedDefaultSchedule({ PatientID, Day, From, To }) {
    console.log('copyModifiedDefaultSchedule ', PatientID, Day, From, To);

    this.PatientApi.CopySchedule({ PatientID, Day, From, To }, rsp => {
      this.emit('onModifiedDefaultScheduleCopied', rsp);
    });
    //this.emit("onDefaultScheduleCopied", {});
  }
  addModifiedSchedule({ PatientID, Day, From, To }) {
    console.log('addModifiedSchedule ', PatientID, Day, From, To);

    this.PatientApi.AddNewPatientSchedule({ PatientID, Day, From, To }, rsp => {
      this.emit('onModifiedPatientScheduleAdded', rsp);
    });
  }

  copyFilteredDefaultSchedule({ PatientID, Day, From, To }) {
    console.log('copyFilteredDefaultSchedule ', PatientID, Day, From, To);

    this.PatientApi.CopySchedule({ PatientID, Day, From, To }, rsp => {
      this.emit('onFilteredDefaultScheduleCopied', rsp);
    });
  }

  resetPatientUseSchedule({ PatientID }) {
    console.log('resetPatientUseSchedule ', PatientID);

    this.PatientApi.ResetPatientUseSchedule({ PatientID }, rsp => {
      console.warn('ResetPatientUseSchedule response', rsp);
      this.emit('onResetPatientScheduleComplete');
    });
  }

  //New Messages Section
  updateEnableVoiceMessage({ PatientID, EnableVoiceMessage }) {
    console.log('updateEnableVoiceMessage ', PatientID, EnableVoiceMessage);

    this.PatientApi.UpdateEnableVoiceMessage({ PatientID, EnableVoiceMessage }, rsp => {
      rsp['enabled'] = EnableVoiceMessage;
      this.emit('onEnableVoiceMessageUpdated', rsp);
    });
  }

  refreshScheduledMessagesTab() {
    this.emit('onRefreshScheduledMessagesTab');
  }

  handleNewPatientMessageModal() {
    this.emit('onHandleNewPatientMessageModal');
  }

  refreshCurrentPatientMessageTab() {
    this.emit('onRefreshMessageTab');
  }

  requestOpenPatientAssignmentModal(patientAssignmentData) {
    //console.log("requestOpenPatientAssignmentModal", patientAssignmentData);

    this.emit('onOpenPatientAssignmentModal', patientAssignmentData);
  }

  updateAssignment({ ID, CaregiverID, Message, CheckInDate, CheckOutDate, AssignmentDate }) {
    console.log('updateAssignment ', ID, CaregiverID, Message, AssignmentDate, CheckInDate, CheckOutDate);

    this.PatientApi.UpdateAssignment({ ID, CaregiverID, AssignmentDate, CheckInDate, CheckOutDate, Message }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onAssignmentUpdated', rsp);
    });
  }

  getAccountDevices({ UserID, Token, AccountID }) {
    console.log('getAccountDevices ', UserID, Token, AccountID);

    this.PatientApi.GetAccountDevices({ UserID, Token, AccountID }, rsp => {
      //console.log("getAccountDevices Response", rsp);
      this.emit('onGetAccountDevices', rsp);
    });
  }

  requestRebootDevice({ Mac, DeviceID }) {
    console.log('requestRebootDevice ', Mac, DeviceID);

    this.PatientApi.RequestRebootDevice({ Mac, DeviceID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onRebootDeviceRequested', rsp);
    });
  }

  requestRebootDeviceNew({ Mac, DeviceID }) {
    console.log('requestRebootDeviceNew ', Mac, DeviceID);

    this.PatientApi.RequestRebootDevice({ Mac, DeviceID }, rsp => {
      //console.log("Nucleus Response", rsp);

      this.emit('onRebootNewDeviceRequested', rsp);
    });
  }

  clearPatientCache(PatientID) {
    console.log('clearPatientCache', PatientID);

    this.PatientApi.ClearPatientCache({ PatientID }, rsp => {
      console.log('ClearPatientCache Response', rsp);
    });
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    localStorage.PatientsList = JSON.stringify(this.PatientsList);
    localStorage.PatientsHash = JSON.stringify(this.PatientsHash);
  }

  pad2(number) {
    return (number < 10 ? '0' : '') + number;
  }
}

const carePatientStore = new CarePatientStore();

export default carePatientStore;
