import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { ProviderCallRequest } from './queue.types';

export const fetchQueueForProvider = (providerId: string) => {
  return httpApi.get<ProviderCallRequest[]>(`/providers/${providerId}/call_request_queue`);
};

export const countQueueForProvider = (providerId: string) => {
  return httpApi.get<{ count: number }>(`/providers/${providerId}/call_request_queue/count`);
};
