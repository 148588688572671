import { ReactNode, FC } from 'react';
import styled from 'styled-components';

type Size = 'small' | 'medium' | 'large';

export type CardProps = {
  children?: ReactNode;
  title?: string;
  style?: React.CSSProperties;
  cardHeaderStyle?: React.CSSProperties;
};

export const UICard: FC<CardProps> = ({ children, title, style, cardHeaderStyle }) => {
  return (
    <CardContainer onClick={e => e.stopPropagation()} style={style}>
      {title && <CardHeader style={cardHeaderStyle}>{title}</CardHeader>}
      {children}
    </CardContainer>
  );
};

const CardContainer = styled.div<{ style?: React.CSSProperties }>`
  padding: 10px 20px;
  border-radius: 10px;
  background: #f3f4f5;
  position: relative;
  width: ${props => props.style?.width};
  height: ${props => props.style?.height};
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: ${props => props.style?.backgroundColor};
  background: ${props => props.style?.background};
`;

const CardHeader = styled.p`
  margin-top: 0;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
`;
