import { NucleusCommunicationSignaling } from '@nucleus-care/nucleuscare-connect-signaling';
import { MuteSVG } from 'components/CallSVGs/CallsSVGs';
import CallButton from 'components/WebRTCCallView/CallButton';
import { useState } from 'react';
import { VideoViewData } from '../callLayout.types';

export const VideoCallControls = ({
  stateInCall,
  data,
  labelFontSize,
  communicationSingleton,
}: {
  stateInCall: boolean;
  data: VideoViewData;
  labelFontSize: number;
  communicationSingleton: NucleusCommunicationSignaling;
}) => {
  const [stateInitialMute, setStateInitialMute] = useState(false);

  if (!stateInCall) {
    return null;
  }

  console.log('VideCallControls render', Date.now());

  return (
    <div className="call_view_overlay " style={{ flexDirection: 'column' }}>
      <div style={{ flex: 1, paddingTop: 12, marginLeft: -2 }}>
        {!data.host ? (
          <CallButton
            defaultValue={stateInitialMute}
            text={null}
            customIcon={
              <MuteSVG
                defaultValue={stateInitialMute}
                onClick={status => {
                  console.log('muteIcon customIcon', status);
                  setStateInitialMute(status);
                  status ? communicationSingleton.muteParticipant(data.deviceId) : communicationSingleton.unmuteParticipant(data.deviceId);
                }}
              />
            }
            activeIcon={'/img/mute-button.png'}
            onClick={status => {
              console.log('muteIcon onClick', status);
              //status ? NucleusCallView.muteAudio() : NucleusCallView.unmuteAudio();
            }}
          />
        ) : null}
      </div>
      <div className="videoCallViewPeerName" style={{ flex: 1 }}>
        <span className="nucleus-font-family-medium " style={{ fontSize: labelFontSize }}>
          {data.host ? 'Me' : data.fullName}
        </span>
      </div>
    </div>
  );
};
