//@ts-nocheck
import { getUserConfigPromise } from '@nucleus-care/nucleuscare-backend-client';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';

import { connect } from 'react-redux';
import AuthStore from 'stores/AuthStore';
import CareUserStore from 'stores/CareUserStore';
import GeneralStore from 'stores/GeneralStore';

import { validateAccessGranted } from 'utils/accessGrantedValidator';
import Message from 'utils/Message';
import { UserProfileBanner } from './UserProfileBanner';
import UserProfileEdit from './UserProfileEdit';
import UserProfileInfo from './UserProfileInfo';

class UserProfile extends React.Component<
  {
    match: any;
    history: any;
  },
  {
    currentMode: string;
    linkModeLabel: string;
    userID?: string;
    userFirstName: string;
    userLastName: string;
    userFullName: string;
    userEmail: string;
    userCreated: string;
    userTelephone: string;
    userAddress: string;
    userIsAdmin: boolean;
    userCanReceiveEscalation: boolean;
    userIsOnDuty: boolean;
    userOnlyUrgent: boolean;
    userAllPatientsAccess: boolean;
    userProfileCompleted: boolean;
    userCanSendMessageToAllPatients: boolean;
    userImageUrl: string;
    adminAccess: boolean;
    accountEscalationEmail: string;
    accessStaff: boolean;
    accessClients: boolean;
    accessGroups: boolean;
    accessQueue: boolean;
    accessMessages: boolean;
    accessAlerts: boolean;
    accessPictures: boolean;
    accessBulletinBoard: boolean;
    accessDevices: boolean;
    canDeletePatients: boolean;
    canDeleteDevices: boolean;
    bargeInCallEnabled: boolean;
    canRestartDevices: boolean;
    accountEnableImportClients: boolean;
    canImportClients: boolean;
  }
> {
  constructor(props) {
    super(props);

    validateAccessGranted('/userProfile');

    this.state = {
      currentMode: 'Info',
      linkModeLabel: 'Edit',
      userFirstName: '',
      userLastName: '',
      userFullName: '',
      userEmail: '',
      userCreated: '',
      userTelephone: '',
      userAddress: '',
      userIsAdmin: false,
      userCanReceiveEscalation: false,
      userIsOnDuty: false,
      userOnlyUrgent: false,
      userAllPatientsAccess: false,
      userProfileCompleted: false,
      userImageUrl: '/img/user_placeholder.png',
      adminAccess: AuthStore.getUserRol() == 'admin',
    };

    this.onUserGetDataAction = this.onUserGetDataAction.bind(this);
    this.onUserUpdateDataAction = this.onUserUpdateDataAction.bind(this);
    this.handleProfileMode = this.handleProfileMode.bind(this);
    this.handleRemoveUserModal = this.handleRemoveUserModal.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.onDeleteUserAction = this.onDeleteUserAction.bind(this);

    this.onRequestCancelEditProfileModeAction = this.onRequestCancelEditProfileModeAction.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    //Register Listener
    CareUserStore.on('onUserGetData', this.onUserGetDataAction);
    CareUserStore.on('onUserUpdateData', this.onUserUpdateDataAction);
    CareUserStore.on('onDeleteUserAction', this.onDeleteUserAction);
    GeneralStore.on('onRequestCancelEditProfileMode', this.onRequestCancelEditProfileModeAction);

    GeneralStore.setProfileMode('Info');
    console.log('UserProfile');
    console.log(match.params.userId);
    if (match.params.userId != null) {
      //Get the User Data from WS

      // this.props.dispatch(getUserConfig(match.params.userId))
      this.state.userID = match.params.userId;
      this.getUserData(match.params.userId);

      // Removed Sep 20th - Change CARE-893
      // CareUserStore.getAllPatientsForUser({
      //     UserID : match.params.userId
      // });

      // Removed Sep 20th - Change CARE-893
      // CareUserStore.getPossiblePatients({
      //     UserID : match.params.userId
      // });

      CareUserStore.getPossiblePatients({
        UserID: match.params.userId,
      });
    } else {
      this.props.history.push('/users');
    }

    $('.modalUserProfile').modal({
      dismissible: true,
      complete: function () {}, // Callback for Modal close
    });
    $('.tooltipped').tooltip({ delay: 50 });
  }

  componentWillUnmount() {
    //Remove Listener
    CareUserStore.removeListener('onUserGetData', this.onUserGetDataAction);
    CareUserStore.removeListener('onUserUpdateData', this.onUserUpdateDataAction);
    CareUserStore.removeListener('onDeleteUserAction', this.onDeleteUserAction);
    GeneralStore.removeListener('onRequestCancelEditProfileMode', this.onRequestCancelEditProfileModeAction);

    $('.tooltipped').tooltip('remove');
  }

  onUserGetDataAction(response) {
    console.log('onUserGetDataAction == ', response);

    // if (response.ok) {

    if (response.UserID == AuthStore.getUserID()) {
      AuthStore.setIsAdmin(response.IsAdmin);
    }

    CareUserStore.setUserAllPatientsAccess(response.SeeAllPatients);
    CareUserStore.setCurrentUserName(response.FirstName + ' ' + response.LastName);

    this.setState({
      userID: response.UserID,
      userFirstName: response.FirstName,
      userLastName: response.LastName,
      userFullName: response.FirstName + ' ' + response.LastName,
      userEmail: response.Email,
      userCreated: response.Created,
      userTelephone: response.Telephone || '',
      userAddress: response.Address,
      userIsAdmin: response.IsAdmin,
      userCanReceiveEscalation: response.ReceiveEscalationCallRequest,
      userIsOnDuty: response.IsOnDuty,
      userOnlyUrgent: response.OnlyUrgent,
      userAllPatientsAccess: response.SeeAllPatients,
      userProfileCompleted: response.Created,
      userCanSendMessageToAllPatients: response.CanSendMessageToAllPatients,
      accountEscalationEmail: response.AccountEscalationEmail,
      accessStaff: response.CanSeeStaffTab,
      accessClients: response.CanSeeClientsTab,
      accessGroups: response.CanAccessGroups,
      accessQueue: response.CanSeeQueueTab,
      accessMessages: response.CanSeeMessageCenterTab,
      accessAlerts: response.CanSeeAlertsTab,
      accessPictures: response.CanSeeMemoryBox,
      accessBulletinBoard: response.CanSeeBulletinBoard,
      accessDevices: response.CanSeeDevicesTab,
      canDeletePatients: response.CanDeletePatients,
      canDeleteDevices: response.CanDeleteDevices,
      bargeInCallEnabled: response.BargeInCall,
      canRestartDevices: response.CanRestartDevices,
      userImageUrl: response.UserImageThumb,
      adminAccess: AuthStore.getUserRol() == 'admin',
      accountEnableImportClients: response.EnableImportClients,
      canImportClients: response.CanImportClients,
    });

    // } else {
    // Message.show("Error getting user Information");
    // }
  }

  getUserData = userId => {
    getUserConfigPromise(userId)
      .then(({ data }) => {
        this.onUserGetDataAction(data);
        CareUserStore.shareUserData(data);
      })
      .catch(e => {
        console.warn('Error getting user Information', e);
        Message.show('Error getting user Information');
      });
  };

  onUserUpdateDataAction(response) {
    console.log('onUserUpdateDataAction', response);

    if (response.ok) {
      //console.log("IDS >>>>>" + response.UserID + ">>" + AuthStore.getUserID());
      if (response.UserID == AuthStore.getUserID()) {
        AuthStore.setIsAdmin(response.IsAdmin);
      }

      this.setState({
        currentMode: 'Info',
        linkModeLabel: 'Edit',
      });
      GeneralStore.setProfileMode('Info');
      Message.show(AuthStore.getUserLabel() + ' successfully updated');
      //this.onUserGetDataAction(true);

      this.getUserData(this.state.userID);
    }
  }

  handleRemoveUserModal() {
    $('#modalDeleteUser').modal('open');
  }

  handleCancelDelete() {
    $('#modalDeleteUser').modal('close');
  }

  handleDeleteUser() {
    CareUserStore.DeleteUser({
      UserID: this.state.userID,
    });
  }

  onDeleteUserAction(response) {
    if (response.ok) {
      this.props.history.navigate('/users');
      setTimeout(function () {
        Message.show(AuthStore.getUserLabel() + ' deleted successfully.');
      }, 700);
      $('#modalDeleteUser').modal('close');
    } else {
      $('#modalDeleteUser').modal('close');
      Message.show('Sorry, There was a problem deleting the ' + AuthStore.getUserLabel().toLowerCase() + '. Please try again.');
    }
  }

  onRequestCancelEditProfileModeAction() {
    this.handleProfileMode();
  }

  handleProfileMode() {
    if (this.state.currentMode == 'Info') {
      this.setState({
        currentMode: 'Edit',
        linkModeLabel: 'Cancel',
      });
      GeneralStore.setProfileMode('Edit');
    } else {
      this.setState({
        currentMode: 'Info',
        linkModeLabel: 'Edit',
      });
      GeneralStore.setProfileMode('Info');
    }
  }

  render() {
    const { match } = this.props;
    console.log('this.props', this.props);
    console.log('RENDER USER PROFILE ', this.state.userCreated);

    const infoVisibility = this.state.currentMode == 'Info' ? ' ' : ' hide';
    const editVisibility = this.state.currentMode == 'Info' ? ' hide' : ' ';

    let removeVisibility = '';

    if (match.params.userId == AuthStore.getUserID()) {
      removeVisibility = ' hide';
    }

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <div style={{ paddingTop: 30, paddingBottom: 80 }} className={infoVisibility}>
            <UserProfileBanner
              userId={this.state.userID}
              fullName={this.state.userFullName}
              imageSrc={this.state.userImageUrl}
              address={this.state.userAddress}
              isAdmin={this.state.userIsAdmin}
              phoneNumber={this.state.userTelephone}
              isOnDuty={this.state.userIsOnDuty}
              userProfileCompleted={this.state.userProfileCompleted}
              userOnlyUrgent={this.state.userOnlyUrgent}
              onHandleProfileMode={this.handleProfileMode}
              profileModeLabel={this.state.linkModeLabel}
              onHandleRemoveUser={this.handleRemoveUserModal}
              removeVisibility={removeVisibility}
            />
          </div>

          <p className={'nucleus-page-title ' + editVisibility}>
            {this.state.userFullName}
            &nbsp;&nbsp;&nbsp;
            <a className="nucleus-font-small  nucleus-link" onClick={this.handleProfileMode}>
              {' '}
              {this.state.linkModeLabel}
            </a>
          </p>

          <div className="altoImportantContainer">
            <UserProfileInfo
              currentMode={this.state.currentMode}
              userId={this.state.userID}
              userFullName={this.state.userFullName}
              userEmail={this.state.userEmail}
              userTelephone={this.state.userTelephone}
              userAddress={this.state.userAddress}
              userImageUrl={this.state.userImageUrl}
              userIsAdmin={this.state.userIsAdmin}
              userCanReceiveEscalation={this.state.userCanReceiveEscalation}
              userIsOnDuty={this.state.userIsOnDuty}
              userOnlyUrgent={this.state.userOnlyUrgent}
              userProfileCompleted={this.state.userProfileCompleted}
              userAllPatientsAccess={this.state.userAllPatientsAccess}
              userCreated={this.state.userCreated}
              adminAccess={this.state.adminAccess}
            />

            <UserProfileEdit
              currentMode={this.state.currentMode}
              userId={this.state.userID}
              userFirstName={this.state.userFirstName}
              userLastName={this.state.userLastName}
              userEmail={this.state.userEmail}
              userTelephone={this.state.userTelephone}
              userAddress={this.state.userAddress}
              userIsAdmin={this.state.userIsAdmin}
              userCanReceiveEscalation={this.state.userCanReceiveEscalation}
              userIsOnDuty={this.state.userIsOnDuty}
              userAllPatientsAccess={this.state.userAllPatientsAccess}
              adminAccess={this.state.adminAccess}
              userMessageAllPatients={this.state.userCanSendMessageToAllPatients}
              accountEscalationEmail={this.state.accountEscalationEmail}
              staffAccess={this.state.accessStaff}
              clientAccess={this.state.accessClients}
              groupsAccess={this.state.accessGroups}
              queueAccess={this.state.accessQueue}
              messagesAccess={this.state.accessMessages}
              alertsAccess={this.state.accessAlerts}
              picturesAccess={this.state.accessPictures}
              bulletinBoardAccess={this.state.accessBulletinBoard}
              devicesAccess={this.state.accessDevices}
              canDeletePatients={this.state.canDeletePatients}
              canDeleteDevices={this.state.canDeleteDevices}
              bargeInCallEnabled={this.state.bargeInCallEnabled}
              canRestartDevices={this.state.canRestartDevices}
              userImageUrl={this.state.userImageUrl}
              onRefresh={this.getUserData}
              userData={{
                accountEnableImportClients: this.state.accountEnableImportClients,
                canImportClients: this.state.canImportClients,
              }}
            />

            <div id="modalDeleteUser" className="modal modalUserProfile modalDeleteUser">
              <div className="modal-content modal-content-delete">
                <h3 className="nucleus-page-subtitle"> Delete {AuthStore.getUserLabel()}</h3>
                <p></p>
                <div className="row">
                  <div className="col s12 left-align nucleus-font-medium">
                    Are you sure you want to remove this {AuthStore.getUserLabel().toLowerCase()}?
                    <br />
                  </div>
                </div>
                <div className="row ">
                  <div className="col s12 right-align ">
                    <p></p>
                    <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDelete}>
                      Cancel
                    </a>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleDeleteUser}>
                      Confirm
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userConfig } = state;
  return { userConfig: userConfig.configurations.userConfig };
}

const UserProfileFC = props => {
  return useReactRouterProps(UserProfile, props);
};

export default connect(mapStateToProps, null)(UserProfileFC);
