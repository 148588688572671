//@ts-nocheck

class DetectWakeup {
  constructor(context) {
    console.log('DetectWakeup >> CONSTRUCTOR');
    this.context = context;
    this.lastTime = new Date().getTime();

    this.checkInterval = 60000; // 60 000 miliseconds = 60 seconds = 1 min

    console.log('DetectWakeup >> CONSTRUCTOR', this.lastTime, this.checkInterval);
  }

  init() {
    //console.log("Message Show");

    setInterval(() => {
      const currentTime = new Date().getTime();

      console.log('DetectWakeup >> ', currentTime > this.lastTime + this.checkInterval * 5);

      if (currentTime > this.lastTime + this.checkInterval * 5) {
        // ignore small delays
        // postMessage("wakeup");

        // console.log("Session expired");

        console.log('Wakeup', new Date().toLocaleTimeString());
        window.location.reload();
      }

      this.lastTime = currentTime;
    }, this.checkInterval);
  }
}

//const detectWakeup = new DetectWakeup();
export default DetectWakeup;
