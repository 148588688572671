export const CalendarIconSVG = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5631 15.9375H15.9381V21.5625H21.5631V15.9375ZM19.6881 3.75V5.625H10.3131V3.75H7.50059V5.625H6.09375C4.80457 5.625 3.75 6.67957 3.75 7.96875V23.9062C3.75 25.1954 4.80457 26.25 6.09375 26.25H23.9068C25.196 26.25 26.2506 25.1954 26.2506 23.9062V7.96875C26.2506 6.67957 25.196 5.625 23.9068 5.625H22.5006V3.75H19.6881ZM23.9068 23.9062H6.09375V11.4844H23.9068V23.9062Z"
        fill="#0A313F"
      />
    </svg>
  );
};
