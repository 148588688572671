import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import PatientSelector from '../../../components/PatientsSelector/patientsSelector';
import usePatientSelector from '../../../components/PatientsSelector/usePatientsSelector';
import { UIModal, UIInput, useModal } from '../../../components/UI';
import { ModalAction } from '../../../components/UI/Modals/Base/ActionButtons';
import Message from '../../../utils/Message';

import DeleteGroup from './DeleteGroup';
import { Group, GroupCategory } from './types';

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-size: 16px;
  color: #0a313f;
  margin-bottom: 5px;
  font-weight: 600;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  category?: GroupCategory | null;
  group?: Group | null;
}

const TITLES = {
  add: 'Add a New Group',
  edit: 'Edit Group',
};

const AddEditGroup: FC<Props> = ({ closeModal, isOpen, group, category }) => {
  const [title, setTitle] = useState('');
  const [mode, setMode] = useState('add');
  const [saveCategoryError, setSaveCategoryError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isDeleteOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
  const { patients, togglePatientSelection, reset, searchTerm, setSearchTerm, selectedPatients } = usePatientSelector();

  useEffect(() => {
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    if (titleError) {
      setTitleError('');
    }
  }, [title]);

  useEffect(() => {
    setTitle(group?.name || '');
    if (group) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [group]);

  const savePatients = (groupId: string) => {
    const selectedIds = selectedPatients.map(p => p.id);

    if (selectedIds.length === 0) {
      handleCloseModal();
      return;
    }

    httpApi
      .patch(`/groups/${groupId}/patients`, {
        patientIds: selectedIds,
      })
      .then(() => {
        setIsLoading(false);
        handleCloseModal();
      })
      .catch(() => {
        Message.show('Error while adding to the group.');
        setIsLoading(false);
      });
  };

  const saveGroup = () => {
    httpApi
      .post('/groups', {
        name: title,
        groupCategoryId: category?.id,
      })
      .then(({ data }) => {
        setIsLoading(false);
        if (data.id) {
          Message.show('New Group created');
          savePatients(data.id);
        } else {
          Message.show('Error creating new Group');
        }
      })
      .catch(e => {
        if (e.response) {
          setSaveCategoryError(e.response.data?.message || 'Error creating new Group');
        } else {
          Message.show('Error creating new Group');
        }
        setIsLoading(false);
      });
  };

  const editGroup = () => {
    httpApi
      .put(`/groups/${group?.id}`, {
        name: title,
      })
      .then(() => {
        setIsLoading(false);
        Message.show('Group edited');
        handleCloseModal();
      })
      .catch(e => {
        setSaveCategoryError(e.response.data?.message || 'Error creating new Group');
        setIsLoading(false);
      });
  };

  const validate = () => {
    if (!title) {
      setTitleError('Name is required');
      return false;
    }
    return true;
  };

  const submit = () => {
    if (!validate()) return;

    if (mode === 'add') {
      saveGroup();
    } else {
      editGroup();
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleOpenDeleteModal = () => {
    handleCloseModal();
    openDeleteModal();
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModal,
        buttonVariant: 'secondary',
      },
    ];

    if (mode === 'edit') {
      data.push(
        {
          label: 'Delete Group',
          onClick: handleOpenDeleteModal,
          buttonVariant: 'danger',
        },
        {
          label: mode === 'edit' ? 'Save' : 'Add',
          onClick: submit,
          disabled: isLoading,
        },
      );
    } else {
      data.push({
        label: mode === 'edit' ? 'Save' : 'Add',
        onClick: submit,
        disabled: isLoading,
      });
    }

    return data;
  };

  const renderPatientSelector = () => {
    if (mode === 'add') {
      return (
        <>
          <Label>Clients to add:</Label>
          <PatientSelector
            patients={patients}
            togglePatientSelection={togglePatientSelection}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedPatients={selectedPatients}
            patientsPerGroup={[]}
          />
        </>
      );
    }
  };

  const onCloseDeleteModal = () => {
    handleCloseModal();
    closeDeleteModal();
  };

  const handleCloseModal = () => {
    closeModal();
    reset();
    setTitle('');
    setSaveCategoryError('');
  };

  return (
    <>
      <UIModal size="medium" isOpen={isOpen} close={handleCloseModal} title={TITLES[mode]} actions={getActions()}>
        <InputGroup>
          <UIInput label="Group Name:" placeholder="Name" value={title} onChange={handleTitleChange} error={titleError || saveCategoryError} />
        </InputGroup>
        {renderPatientSelector()}
      </UIModal>
      <DeleteGroup category={group} closeModal={onCloseDeleteModal} isOpen={isDeleteOpen} />
    </>
  );
};

export default AddEditGroup;
