import React from 'react';
import styled from 'styled-components';
const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const TotalDevicesCell = ({ cell: { value } }) => {
  return <CellContainer>{value}</CellContainer>;
};

export default TotalDevicesCell;
