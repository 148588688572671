import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import { AuthStore, CarePatientStore, CareCommunicationStore } from 'stores';

import Message from '../../../utils/Message';

import CallRequestLogItemRow from './CallRequestLogItemRow';

class PatientRequestSection extends React.Component<
  {
    patientID: string;
  },
  {
    accountID: string;
    patientID: string;

    patientRequestsArray: any[];
    loadingRequests: boolean;

    datesErrorVisible: string;
    datesErrorText: string;

    fromDateRequests: Date;
    toDateRequests: Date;
    patientTimezone: string;
  }
> {
  private controlTimezone: number;
  private dateOptions: Intl.DateTimeFormatOptions;
  private timeOptions: Intl.DateTimeFormatOptions;

  constructor(props) {
    super(props);
    this.state = {
      accountID: AuthStore.getUserAccountID(),
      patientID: this.props.patientID,

      patientRequestsArray: [],
      loadingRequests: true,

      datesErrorVisible: ' hide',
      datesErrorText: 'The From Date has to be before the To Date.',

      fromDateRequests: null,
      toDateRequests: null,
      patientTimezone: '',
    };

    this.onPatientGetRequestLogsAction = this.onPatientGetRequestLogsAction.bind(this);
    this.handleFilterRequestLogs = this.handleFilterRequestLogs.bind(this);

    this.onUpdateCallRequestLogNotesAction = this.onUpdateCallRequestLogNotesAction.bind(this);

    this.loadPatientRequestLogsAction = this.loadPatientRequestLogsAction.bind(this);

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.controlTimezone = 0;
    this.onCallEndedReportedAction = this.onCallEndedReportedAction.bind(this);
  }

  componentDidMount() {
    CarePatientStore.on('onPatientGetRequestLogs', this.onPatientGetRequestLogsAction);
    //CarePatientStore.on("onGetCallRequestLogDetails", this.onGetCallRequestLogDetailsAction);

    CarePatientStore.on('onUpdateCallRequestLogNotes', this.onUpdateCallRequestLogNotesAction);
    CarePatientStore.on('loadPatientRequestLogs', this.loadPatientRequestLogsAction);

    CareCommunicationStore.on('onCallEndedReported', this.onCallEndedReportedAction);

    // CarePatientStore.on("onOpenRemoveScheduleModal", this.onOpenRemoveScheduleModalAction);
    // CarePatientStore.on("onPatientScheduleRemoved", this.onPatientScheduleRemovedAction);

    // CarePatientStore.on("onDefaultScheduleCopied", this.onDefaultScheduleCopiedAction);
    // CarePatientStore.on("onModifiedDefaultScheduleCopied", this.onModifiedDefaultScheduleCopiedAction);
    // CarePatientStore.on("onModifiedPatientScheduleAdded", this.onModifiedPatientScheduleAddedAction);
    // CarePatientStore.on("onFilteredDefaultScheduleCopied", this.onFilteredDefaultScheduleCopiedAction);

    const currentDate = new Date();
    const passedDate = new Date();

    passedDate.setDate(passedDate.getDate() - 8);
    passedDate.setHours(0);
    passedDate.setMinutes(0);
    passedDate.setSeconds(0);

    currentDate.setHours(23);
    currentDate.setMinutes(59);
    currentDate.setSeconds(0);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    this.controlTimezone = currentTimezone;

    console.log('RequestsLogs.js', 'fromDate ' + passedDate);
    console.log('RequestsLogs.js', 'toDate ' + currentDate);
    this.setState({
      fromDateRequests: new Date(passedDate),
      toDateRequests: new Date(currentDate),
    });
    passedDate.setHours(passedDate.getHours() + this.controlTimezone * -1);
    currentDate.setHours(currentDate.getHours() + this.controlTimezone * -1);

    const fromParamString = passedDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + passedDate.toLocaleTimeString('en-US', this.timeOptions);
    const toParamString = currentDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentDate.toLocaleTimeString('en-US', this.timeOptions);

    CarePatientStore.getPatientCallRequestLogs({
      PatientID: this.props.patientID,
      From: fromParamString,
      To: toParamString,
    });

    this.setState({
      loadingRequests: true,
    });
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onPatientGetRequestLogs', this.onPatientGetRequestLogsAction);
    //CarePatientStore.removeListener("onGetCallRequestLogDetails", this.onGetCallRequestLogDetailsAction);

    CarePatientStore.removeListener('onUpdateCallRequestLogNotes', this.onUpdateCallRequestLogNotesAction);
    CarePatientStore.removeListener('loadPatientRequestLogs', this.loadPatientRequestLogsAction);

    CareCommunicationStore.removeListener('onCallEndedReported', this.onCallEndedReportedAction);
    // CarePatientStore.removeListener("onOpenRemoveScheduleModal", this.onOpenRemoveScheduleModalAction);
    // CarePatientStore.removeListener("onPatientScheduleRemoved", this.onPatientScheduleRemovedAction);

    // CarePatientStore.removeListener("onDefaultScheduleCopied", this.onDefaultScheduleCopiedAction);
    // CarePatientStore.removeListener("onModifiedDefaultScheduleCopied", this.onModifiedDefaultScheduleCopiedAction);
    // CarePatientStore.removeListener("onModifiedPatientScheduleAdded", this.onModifiedPatientScheduleAddedAction);

    // CarePatientStore.removeListener("onFilteredDefaultScheduleCopied", this.onFilteredDefaultScheduleCopiedAction);
    // //$('.tooltipped').tooltip('remove');
  }

  // componentWillReceiveProps(){
  // }

  // componentWillUpdate(){
  // }

  // componentDidUpdate(){
  //     console.log("Config");
  //     if (this.props.enableBiometrics && this.props.enableBiometrics.toString() == "true"){
  //         this.loadBiometrics();
  //     }
  // }

  onCallEndedReportedAction(response) {
    if (response.PatientID && response.patientID == this.props.patientID) {
      this.handleFilterRequestLogs();
    }
  }

  loadPatientRequestLogsAction() {
    this.handleFilterRequestLogs();
  }

  handleFilterRequestLogs() {
    const fromDateTimeLogs = new Date(this.state.fromDateRequests);
    const toDateTimeLogs = new Date(this.state.toDateRequests);

    if (toDateTimeLogs < fromDateTimeLogs) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' The From Date has to be before the To Date.',
      });
      return;
    }

    fromDateTimeLogs.setHours(0);
    fromDateTimeLogs.setMinutes(0);
    fromDateTimeLogs.setSeconds(0);

    fromDateTimeLogs.setHours(fromDateTimeLogs.getHours() + this.controlTimezone * -1);

    toDateTimeLogs.setHours(23);
    toDateTimeLogs.setMinutes(59);
    toDateTimeLogs.setSeconds(0);

    toDateTimeLogs.setHours(toDateTimeLogs.getHours() + this.controlTimezone * -1);

    const fromParam = fromDateTimeLogs.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDateTimeLogs.toLocaleTimeString('en-US', this.timeOptions);
    const toParam = toDateTimeLogs.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDateTimeLogs.toLocaleTimeString('en-US', this.timeOptions);

    console.log('handleFilterRequestLogs', 'fromDate ' + fromParam);
    console.log('handleFilterRequestLogs', 'toDate ' + toParam);

    CarePatientStore.getPatientCallRequestLogs({
      PatientID: this.props.patientID,
      From: fromParam,
      To: toParam,
    });

    this.setState({
      loadingRequests: true,
    });
  }

  onPatientGetRequestLogsAction(response) {
    console.log('patientRequest:onPatientGetRequestLogsAction', response);

    this.setState({
      patientRequestsArray: [],
      loadingRequests: false,
    });

    if (response.ok) {
      this.setState({
        patientRequestsArray: response.RequestsLogs,
        patientTimezone: response.PatientTimeZone,
      });
    } else {
      console.log('Error getting ' + AuthStore.getPatientLabel().toLowerCase() + ' Request Call Logs');
    }
  }

  onUpdateCallRequestLogNotesAction(response) {
    // this.setState({
    //     saveNoteBtnStyle : 'nucleus-submit-btn'
    // });

    if (response.ok) {
      this.handleFilterRequestLogs();

      Message.show('Call Request Log Note updated!');
      //$('#modalRequestDetails').modal('close');
    } else {
      //$('#modalRequestDetails').modal('close');
      Message.show('There was a problem updating the call Request log note');
    }
  }

  // pad2(number) {
  //     return (number < 10 ? '0' : '') + number;
  // }

  render() {
    const requestLogsList = this.state.patientRequestsArray?.map((reqLog, key) => {
      return (
        <CallRequestLogItemRow
          key={key}
          ID={reqLog.ID}
          PatientTimeZone={this.state.patientTimezone}
          RequestTime={reqLog.RequestTime}
          IsEmergency={reqLog.IsEmergency}
          Status={reqLog.Status}
          HandledTime={reqLog.HandledTime}
          HandlerName={reqLog.HandledByFirstName + ' ' + reqLog.HandledByLastName}
          EscalationCallRequestSent={reqLog.EscalationCallRequestSent}
          NumOfRequests={reqLog.NumOfRequests}
          HasNotes={reqLog.HasNotes}
        />
      );
    });

    let logsTable = '';
    let logsLoading = 'hide';

    if (this.state.loadingRequests) {
      logsTable = ' hide';
      logsLoading = ' ';
    }

    return (
      <div className="">
        <div className="row  ">
          &nbsp;&nbsp;
          <span className="nucleus-labels nucleus-bold">From:</span> &nbsp;
          {/* <input 
                        id="fromDateRequests" 
                        ref="fromDateRequests" 
                        type="text" 
                        className="validate nucleus-date-picker-short width90" /> */}
          <div style={{ width: 140, display: 'inline-flex' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                //ref={this.fromDateRequestsRef}
                variant="dialog"
                className="browser-default"
                value={this.state.fromDateRequests}
                onChange={date => {
                  this.setState({
                    fromDateRequests: date.toDate(),
                    datesErrorVisible: ' hide',
                  });
                }}
                autoOk={true}
                onError={console.log}
                //minDate={new Date("2018-01-01T00:00")}
                format={'MM/DD/YYYY'}
              />
            </MuiPickersUtilsProvider>
          </div>
          &nbsp;&nbsp;&nbsp;
          <span className="nucleus-labels nucleus-bold">To:</span> &nbsp;
          {/* <input 
                        id="toDateRequests" 
                        ref="toDateRequests" 
                        type="text" 
                        className="validate nucleus-date-picker-short width90" /> */}
          <div style={{ width: 140, display: 'inline-flex' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                //ref={this.toDateRequestsRef}
                variant="dialog"
                className="browser-default"
                value={this.state.toDateRequests}
                onChange={date => {
                  this.setState({
                    toDateRequests: date.toDate(),
                    datesErrorVisible: ' hide',
                  });
                }}
                autoOk={true}
                onError={console.log}
                minDate={new Date(this.state.fromDateRequests).setDate(new Date(this.state.fromDateRequests).getDate() + 2)}
                format={'MM/DD/YYYY'}
              />
            </MuiPickersUtilsProvider>
          </div>
          &nbsp;&nbsp;&nbsp;
          <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={this.handleFilterRequestLogs}>
            Filter Results
          </a>
          &nbsp;
          <br />
          <span className={'nucleus-form-error center-align ' + this.state.datesErrorVisible}>{this.state.datesErrorText}</span>
        </div>
        <br />
        <div className={'assignments-table-pending ' + logsTable}>
          <table className="bordered highlight  nucleus-table">
            <thead>
              <tr>
                <th className="table-col-20">
                  <span className="nucleus-table-header-small">Request Date & Time</span>
                </th>
                <th className="table-col-10 center-align">
                  <span className="nucleus-table-header-small">Emergency</span>
                </th>
                <th className="table-col-10 left-align">
                  <span className="nucleus-table-header-small">Status</span>
                </th>
                <th className="table-col-20 left-align">
                  <span className="nucleus-table-header-small">Handled On</span>
                </th>
                <th className="table-col-15 left-align">
                  <span className="nucleus-table-header-small">Handled By</span>
                </th>
                <th className="table-col-10 center-align">
                  <span className="nucleus-table-header-small">Escalated</span>
                </th>
                <th className="table-col-10 center-align">
                  <span className="nucleus-table-header-small">Requests</span>
                </th>
                <th className="table-col-5 right-align">
                  <span className="nucleus-table-header-small">Notes&nbsp;</span>
                </th>
              </tr>
            </thead>
            <tbody>{requestLogsList}</tbody>
          </table>
        </div>

        <div className={'row center-align ' + logsLoading}>
          <br />
          <br />
          <br />
          <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingRequests} style={{ display: 'inline-flex' }} />
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default PatientRequestSection;
