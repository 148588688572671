import { useImperativeHandle, useEffect } from 'react';

let ringingStatus = false;
let notificationSound = new Audio('/callSound/ring.mp3');
const RinginAvatar = ({ callingFrameVisibility, patientInitials, refCustom }: { callingFrameVisibility?: any; patientInitials: string; refCustom?: any }) => {
  const startAnimation = () => {
    //@ts-ignore
    $('.ring1').animate(
      {
        width: '270px',
        height: '270px',
      },
      1500,
    );
    //@ts-ignore
    $('.ring2').animate(
      {
        width: '410px',
        height: '410px',
      },
      1600,
    );
    //@ts-ignore
    $('.ring3').animate(
      {
        width: '550px',
        height: '550px',
      },
      1700,
    );
    //@ts-ignore
    $('.ring4').animate(
      {
        width: '760px',
        height: '760px',
      },
      2000,
      () => {
        //@ts-ignore
        $('.ring1').width(180).height(180);
        //@ts-ignore
        $('.ring2').width(180).height(180);
        //@ts-ignore
        $('.ring3').width(180).height(180);
        //@ts-ignore
        $('.ring4').width(180).height(180);
        if (callingFrameVisibility != ' hide') {
          console.log('Animation ', callingFrameVisibility);

          startAnimation();
        }
      },
    );
  };

  const startCallingSound = () => {
    console.log('startCallingSound', ringingStatus);
    if (!ringingStatus && notificationSound) {
      notificationSound.loop = true;
      notificationSound.play().catch(error => {
        console.warn('Error playing sound - startCallingSound', error);
      });

      ringingStatus = true;
    }
  };

  const stopCallingSound = () => {
    console.log('>>> stopCallingSound ', ringingStatus);
    //console.log(notificationSound);

    if (ringingStatus) {
      if (notificationSound == undefined) {
        notificationSound = new Audio('/callSound/ring.mp3');
      } else {
        notificationSound.pause();
        notificationSound.currentTime = 0;
        ringingStatus = false;
      }
    }
  };

  useEffect(() => {
    startAnimation();
    startCallingSound();

    return () => {
      stopCallingSound();
    };
  }, []);

  useImperativeHandle(refCustom, () => ({
    startAnimation,
  }));

  return (
    <div className={'center-align nucleus-call-caller  ' + callingFrameVisibility} style={{}}>
      <img className="responsive-img call-rings ring4" src="/img/call_ring4.png" />
      <img className="responsive-img call-rings ring3" src="/img/call_ring3.png" />
      <img className="responsive-img call-rings ring2" src="/img/call_ring2.png" />
      <img className="responsive-img call-rings ring1" src="/img/call_ring1.png" />
      <div
        className="responsive-ig call-oval "
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <label
          className="call-name-capitals txt-white center-align "
          style={{
            fontSize: 115,
          }}
        >
          {patientInitials}
        </label>
      </div>
    </div>
  );
};

export default RinginAvatar;
