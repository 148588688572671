import { useSelector } from 'react-redux';

export function useConfigAccessSelector(accessName) {
  return useSelector(({ userConfig }) => {
    if (!userConfig) return {};
    const { configurations } = userConfig;
    if (!configurations[accessName]) return {};
    return configurations[accessName];
  });
}
