import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import AuthStore from '../stores/AuthStore';
import Message from '../utils/Message';

class ResetClientPassword extends React.Component<
  {
    match: any;
    history: any;
    dispatch?: any;
  },
  {
    btnStyle: string;
    errorView1: string;
    errorText: string;
  }
> {
  constructor() {
    super();
    this.state = {
      btnStyle: ' nucleus-submit-btn-disabled',
      errorView1: ' hide',
      errorText: '',
    };

    this.onResetCustomerPasswordAction = this.onResetCustomerPasswordAction.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
    this.validateTxtInput = this.validateTxtInput.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    //Register Listener
    AuthStore.on('onResetCustomerPasswordAction', this.onResetCustomerPasswordAction);

    console.log('URL PARAMS');
    console.log(match.params.email);
    console.log(match.params.token);
  }

  componentWillUnmount() {
    //Remove Listener
    AuthStore.removeListener('onResetCustomerPasswordAction', this.onResetCustomerPasswordAction);
  }

  onResetCustomerPasswordAction(response) {
    console.log('onResetCustomerPasswordAction');
    console.log(response);

    if (response.ok) {
      Message.show('Password reset complete.\nYou can login now in your Mobile App.');
      this.props.history.push('/login');
    } else {
      //Message.show("Error on reset your password please, try again.");
      this.setState({
        errorView1: ' ',
        errorText: 'Error on reset your password please, try again.',
      });
    }
  }

  resetPassword() {
    const { match } = this.props;
    AuthStore.resetCustomerPassword({
      Email: match.params.email,
      Token: match.params.token,
      Password: this.refs.nucleusNewPassword.value,
    });
  }

  validateFields() {
    if (this.refs.nucleusNewPassword.value === '') {
      Materialize.toast('Hey, you forgot the password!', 3000, 'rounded');
      return;
    }
    if (this.refs.nucleusConfirmPassword.value === '') {
      Materialize.toast('Hey, you forgot the password!', 3000, 'rounded');
      return;
    }
    if (this.refs.nucleusNewPassword.value != this.refs.nucleusConfirmPassword.value) {
      //Materialize.toast('Your passwords do not match', 3000, 'rounded');
      this.setState({
        errorView1: ' ',
        errorText: 'Your passwords do not match',
      });
      return;
    }

    const newPassword = this.refs.nucleusConfirmPassword.value;

    if (newPassword.length < 6) {
      this.setState({
        errorView1: ' ',
        errorText: 'Your password must be at least 6 characters',
      });
      return;
    }

    if (newPassword.search(/[a-zA-Z]/) < 0) {
      this.setState({
        errorView1: ' ',
        errorText: 'Your password must contain at least one letter',
      });
      return;
    }

    if (newPassword.search(/\d/) < 0 && newPassword.search(/[\!\@\#\$\%\^\&\*\(\)\_\+]/) < 0) {
      this.setState({
        errorView1: ' ',
        errorText: 'Your password must contain at least one digit or one special character',
      });
      return;
    }

    this.resetPassword();
  }

  validateFieldsFromKeyboard(e) {
    if (e.charCode == 13 || e.keyCode == 13 || e.key == 13) {
      this.validateFields();
    }
  }

  validateTxtInput(e) {
    //console.log(e.target.value);
    this.setState({
      errorView1: ' hide',
    });

    if (this.refs.nucleusNewPassword.value.length > 0 && this.refs.nucleusConfirmPassword.value.length > 0) {
      this.setState({
        btnStyle: ' nucleus-submit-btn',
      });
    } else {
      this.setState({
        btnStyle: ' nucleus-submit-btn-disabled',
      });
    }
  }

  render() {
    const style = {
      height: window.innerHeight,
      background: '#008FFF',
    };

    return (
      <div className="nucleus-login" style={style}>
        <div className="alto valign-wrapper">
          <div className="row ancho">
            <div className="col s2 m3 l3 border"></div>
            <div className="col s8 m6 l6 border center-align">
              <img className="responsive-img" src="/img/nucleus_2024_logo.png" />
              <br />
              <br />
              <br />
              <p className="txt-white nucleus-page-subtitle">Password Reset</p>

              <p className={'login-info login-error nucleus-font-medium nucleus-font ' + this.state.errorView1}> {this.state.errorText}</p>

              <div className="row center-align no-margin">
                <div className="input-field col s1 m3 l3"></div>
                <div className="input-field col s10 m6 l6">
                  <input
                    ref="nucleusNewPassword"
                    type="password"
                    name="nucleus-password"
                    className="validate nucleus-input-login nucleus-font"
                    placeholder="New password"
                    onKeyPress={this.validateFieldsFromKeyboard}
                    onChange={this.validateTxtInput}
                  />
                </div>
                <div className="input-field col s1 m3 l3"></div>
              </div>

              <div className="row center-align no-margin">
                <div className="input-field col s1 m3 l3"></div>
                <div className="input-field col s10 m6 l6">
                  <input
                    ref="nucleusConfirmPassword"
                    type="password"
                    name="nucleus-confirm"
                    className="validate nucleus-input-login nucleus-font"
                    placeholder="Confirm password"
                    onKeyPress={this.validateFieldsFromKeyboard}
                    onChange={this.validateTxtInput}
                  />
                </div>
                <div className="input-field col s1 m3 l3"></div>
              </div>
              <br />

              <div className="row center-align">
                <div className="col s12">
                  <a className={'txt-white nucleus-font-small nucleus-font ' + this.state.btnStyle} onClick={this.validateFields}>
                    Submit
                  </a>
                </div>
              </div>
              <br />
              <br />
            </div>
            <div className="col s2 m3 l3 border"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => useReactRouterProps(ResetClientPassword, props);
