import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import authStore from '../../stores/AuthStore';
import Message from '../../utils/Message';

import AccountSelector from '../Account/AccountSelector';
import useAccountSelector from '../Account/useAccountSelector';
import { UIModal, UIInput, useModal } from '../UI';
import { ModalAction } from '../UI/Modals/Base/ActionButtons';
import DeleteGroup from './DeleteGroup';
import { ProviderGroup, GroupCategory, TierTitles } from './types';

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.div`
  font-size: 16px;
  color: #0a313f;
  margin-bottom: 5px;
  font-weight: 600;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  category?: GroupCategory | null;
  group?: ProviderGroup | null;
  tierTitles: TierTitles;
  deletedSuccessCallback?: () => void;
}

const AddEditRegion: FC<Props> = ({ closeModal, isOpen, group, category, tierTitles, deletedSuccessCallback }) => {
  const TITLES = useMemo(() => {
    return {
      add: `Add ${tierTitles?.Tier2 || 'Region'}`,
      edit: `Edit ${tierTitles?.Tier2 || 'Region'}`,
    };
  }, [tierTitles]);
  const [title, setTitle] = useState('');
  const [mode, setMode] = useState('add');
  const [saveCategoryError, setSaveCategoryError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isDeleteOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();
  const { accounts, isLoading: loadingAccounts, selectedAccounts, toggleAccountsSelection, reset, searchTerm, setSearchTerm } = useAccountSelector();

  useEffect(() => {
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    if (titleError) {
      setTitleError('');
    }
  }, [title]);

  useEffect(() => {
    setTitle(group?.name || '');
    if (group) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [group]);

  const saveAccounts = (groupId: string) => {
    const selectedIds = selectedAccounts.map(p => p.id);

    if (selectedIds.length === 0) {
      handleCloseModal();
      return;
    }

    httpApi
      .put(`/providers/${authStore.getProviderID()}/groups/${groupId}/accounts`, {
        accountIds: selectedIds,
        level: 3,
      })
      .then(() => {
        setIsLoading(false);
        handleCloseModal();
      })
      .catch(() => {
        Message.show('Error while adding to the group.');
        setIsLoading(false);
      });
  };

  const saveRegion = () => {
    httpApi
      .post(`/providers/${authStore.getProviderID()}/groups`, {
        name: title,
        parentId: category?.id,
        level: 2,
      })
      .then(({ data }) => {
        setIsLoading(false);
        if (data.id) {
          Message.show(`New ${tierTitles.Tier2} created`);
          saveAccounts(data.id);
        } else {
          Message.show(`Error creating new ${tierTitles.Tier2 || 'region'}`);
        }
      })
      .catch(e => {
        if (e.response) {
          setSaveCategoryError(e.response.data?.message || `Error creating new  ${tierTitles.Tier2 || 'region'}`);
        } else {
          Message.show(`Error creating new ${tierTitles.Tier2 || 'region'}`);
        }
        setIsLoading(false);
      });
  };

  const editRegion = () => {
    httpApi
      .put(`/providers/${authStore.getProviderID()}/groups/${group?.id}`, {
        name: title.trim(),
      })
      .then(() => {
        setIsLoading(false);
        Message.show(`${tierTitles.Tier2 || 'region'} edited`);
        handleCloseModal();
      })
      .catch(e => {
        setSaveCategoryError(e.response.data?.message || `Error creating new ${tierTitles.Tier2 || 'region'}`);
        setIsLoading(false);
      });
  };

  const validate = () => {
    if (!title || title.trim() === '') {
      setTitleError('Name is required');
      return false;
    }
    return true;
  };

  const submit = () => {
    if (!validate()) return;

    if (mode === 'add') {
      saveRegion();
    } else {
      editRegion();
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleOpenDeleteModal = () => {
    handleCloseModal();
    openDeleteModal();
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModal,
        buttonVariant: 'secondary',
      },
    ];

    if (mode === 'edit') {
      data.push(
        {
          label: `Delete ${tierTitles.Tier2 || 'Region'}`,
          onClick: handleOpenDeleteModal,
          buttonVariant: 'danger',
        },
        {
          label: mode === 'edit' ? 'Save' : 'Add',
          onClick: submit,
          disabled: isLoading || loadingAccounts,
        },
      );
    } else {
      data.push({
        label: mode === 'edit' ? 'Save' : 'Add',
        onClick: submit,
        disabled: isLoading || loadingAccounts,
      });
    }

    return data;
  };

  const renderAccountsSelector = () => {
    if (mode === 'add') {
      return (
        <>
          <Label>Accounts to add:</Label>
          <AccountSelector
            accounts={accounts}
            togglePatientSelection={toggleAccountsSelection}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            selectedAccounts={selectedAccounts}
            accountsPerGroup={[]}
          />
        </>
      );
    }
  };

  const onCloseDeleteModal = () => {
    handleCloseModal();
    closeDeleteModal();
  };

  const handleCloseModal = () => {
    closeModal();
    reset();
    setTitle('');
    setSaveCategoryError('');
  };

  return (
    <>
      <UIModal size="medium" isOpen={isOpen} close={handleCloseModal} title={TITLES[mode]} actions={getActions()}>
        <InputGroup>
          <UIInput label={`Name:`} placeholder="Name" value={title} onChange={handleTitleChange} error={titleError || saveCategoryError} />
        </InputGroup>
        {renderAccountsSelector()}
      </UIModal>
      <DeleteGroup deletedSuccessCallback={deletedSuccessCallback} tierTitles={tierTitles} category={group} closeModal={onCloseDeleteModal} isOpen={isDeleteOpen} />
    </>
  );
};

export default AddEditRegion;
