//@ts-nocheck
import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';

import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AuthStore, GeneralStore, CareAlertsStore } from 'stores';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
import AlertItemRow from './AlertItemRow';
import AlertsReport from './AlertsReport';

class Alerts extends React.Component {
  constructor() {
    super();
    validateAccessGranted('/alerts');
    GeneralStore.getDSTDates();

    this.state = {
      alertsActiveTab: 'staffTab',
      alertReportTab: '',

      activeAlertsContent: '',
      alertsReportContent: 'hide',

      alertsArray: [],

      filterTimezonesArray: GeneralStore.getIsDST() ? GeneralStore.GetDaylightTimezones() : GeneralStore.GetStandardTimezones(),

      loading: false,
      loadingHandleAll: false,

      selectedAlertID: null,
      selectedAlertPatientID: null,
      selectedAlertPatientName: null,
      selectedAlertOccurredOn: null,
      selectedAlertDescription: null,

      selectedAlertTypeTag: null,
      selectedAlertTypeName: null,

      markAlertHandledBtnStyle: 'nucleus-submit-btn',
      markAllAlertsHandledBtnStyle: 'nucleus-submit-btn',

      datesErrorVisible: ' hide',
      datesErrorText: 'The From Date has to be before the To Date.',

      fromDateTime: null,
      toDateTime: null,
      // udpateDate: new Date(),
      // patientsDataComplete : false  // Deprecated April 12th, 2018
    };

    this.fromDateTimeRef = React.createRef();
    this.toDateTimeRef = React.createRef();

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.alertsRendered = false;

    this.callNotAnsweredList = [];
    this.devicesOfflineList = [];
    this.messagesAlertsList = [];
    this.biometricsAlertsList = [];
    this.homeAlertsList = [];
    this.bleDevicesAlertsList = [];
    this.miscAlertsList = [];
    this.batteryAlertsList = [];

    this.handleActiveAlertsTab = this.handleActiveAlertsTab.bind(this);
    this.handleAlertsReportTab = this.handleAlertsReportTab.bind(this);

    this.submitFilterAlerts = this.submitFilterAlerts.bind(this);

    this.onGetAlertsByAccountUpdatedAction = this.onGetAlertsByAccountUpdatedAction.bind(this);

    this.onOpenAlertDetailsAction = this.onOpenAlertDetailsAction.bind(this);

    this.handleCloseAlertModal = this.handleCloseAlertModal.bind(this);
    this.submitMarkAlertAsHandled = this.submitMarkAlertAsHandled.bind(this);
    this.onAlertHandledAction = this.onAlertHandledAction.bind(this);

    this.markAllAlertsByType = this.markAllAlertsByType.bind(this);
    this.handleCloseAllAlertsModal = this.handleCloseAllAlertsModal.bind(this);
    this.submitMarkAllAlertsAsHandled = this.submitMarkAllAlertsAsHandled.bind(this);
    this.onAlertsByTypeHandledAction = this.onAlertsByTypeHandledAction.bind(this);

    this.handleNoClick = this.handleNoClick.bind(this);

    this.messageFilterTimezonesArray = [];

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.datetimeFormat = 'MM/DD/YYYY hh:mm A';
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      this.datetimeFormat = 'MM/DD/YYYY HH:mm';
      //this.timeFormat = "HH:mm A";
      this.timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }
  }

  componentDidMount() {
    // //Register Listener

    CareAlertsStore.on('onGetAlertsByAccountUpdated', this.onGetAlertsByAccountUpdatedAction);
    CareAlertsStore.on('onOpenAlertDetails', this.onOpenAlertDetailsAction);
    CareAlertsStore.on('onAlertHandled', this.onAlertHandledAction);
    CareAlertsStore.on('onAlertsByTypeHandled', this.onAlertsByTypeHandledAction);

    console.log('>>>>>>>>> MOUNT MODAL');
    $('.modalAlert').modal({
      dismissible: false,
      complete: () => {}, // Callback for Modal close
    });

    const currentDate = new Date();
    const beginDate = new Date();
    beginDate.setDate(currentDate.getDate() - 7);
    currentDate.setDate(currentDate.getDate() + 1);
    console.log('DATE:', currentDate, beginDate);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('TIMEZONE:', currentTimezone);

    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    let formatedTimezone = '' + currentTimezone;

    if (currentTimezone > 0) {
      formatedTimezone = '+' + currentTimezone;
    }
    console.log('FORMATED TIMEZONE:', formatedTimezone);

    this.setState({
      fromDateTime: beginDate,
      toDateTime: currentDate,
    });

    // $('#fromDateTime').datetimepicker({
    // 		//minDate: '-1d',
    // 		format:this.datetimeFormat,
    // 		timepicker : true,
    // 		//onSelectDate : this.validateDate
    // 		//defaultDate : beginDate
    // 		validateOnBlur : true,
    // 		closeOnDateSelect:true
    // });
    // //this.refs.fromDateTime.value = (beginDate.getMonth()+1) + ".01." + beginDate.getFullYear();
    // this.refs.fromDateTime.value  = beginDate.toLocaleDateString('en-US', this.dateOptions) + " " + beginDate.toLocaleTimeString('en-US', this.timeOptions);

    // $('#toDateTime').datetimepicker({
    // 		//minDate: new Date(this.refs.fromDateTime.value),
    // 		format:this.datetimeFormat,
    // 		timepicker : true,
    // 		//onSelectDate : this.validateDate,
    // 		//defaultDate : currentDate
    // 		validateOnBlur : true,
    // 		closeOnDateSelect:true
    // });
    // //
    // //this.refs.toDateTime.value = (currentDate.getMonth()+1) + "." + ('0' + currentDate.getDate()).slice(-2) + "." + currentDate.getFullYear();
    // this.refs.toDateTime.value = currentDate.toLocaleDateString('en-US', this.dateOptions) + " " + currentDate.toLocaleTimeString('en-US', this.timeOptions);

    try {
      this.refs.selectTimeZone.value = formatedTimezone;
    } catch (err) {
      console.log('>>>>>> Catch Timezone');
    }

    if (this.refs.selectTimeZone.value == '') {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please select one of the available time zones.',
      });
      return;
    }

    CareAlertsStore.getAlertsByAccount({
      AccountID: AuthStore.getUserAccountID(),
      FromDateTime: beginDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + beginDate.toLocaleTimeString('en-US', this.timeOptions),
      ToDateTime: currentDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentDate.toLocaleTimeString('en-US', this.timeOptions),
      TimeZoneOffset: this.refs.selectTimeZone.value,
    });

    this.setState({
      loading: true,
    });
  }

  componentWillUnmount() {
    // //Remove Listener
    CareAlertsStore.removeListener('onGetAlertsByAccountUpdated', this.onGetAlertsByAccountUpdatedAction);
    CareAlertsStore.removeListener('onOpenAlertDetails', this.onOpenAlertDetailsAction);
    CareAlertsStore.removeListener('onAlertHandled', this.onAlertHandledAction);
    CareAlertsStore.removeListener('onAlertsByTypeHandled', this.onAlertsByTypeHandledAction);
  }

  handleActiveAlertsTab() {
    this.setState({
      alertsActiveTab: 'staffTab',
      alertReportTab: '',
      activeAlertsContent: '',
      alertsReportContent: 'hide',
    });

    this.submitFilterAlerts();
    //GeneralStore.setStaffTab(1);

    // CareUserStore.getAllUsersData({
    //     UserID : AuthStore.getUserID()
    // });
    // Message.show(AuthStore.getUserLabelPlural() + " information updated.");
  }

  handleAlertsReportTab() {
    this.setState({
      alertsActiveTab: '',
      alertReportTab: 'staffTab',
      activeAlertsContent: 'hide',
      alertsReportContent: '',
    });

    CareAlertsStore.refreshAlertsReport();
    //GeneralStore.setStaffTab(2);

    // CaregiverStore.getAllCaregiversData({
    //    	AccountID : AuthStore.getUserAccountID()
    // });
    // Message.show(AuthStore.getCaregiverLabelPlural() + " information updated.");
  }

  onFromDateChange = (date, value) => {
    console.log('onFromDateChange', date, value);
    this.setState({
      fromDateTime: value,
    });
  };

  onToDateChange = (date, value) => {
    console.log('onToDateChange', date, value);
    this.setState({
      toDateTime: value,
    });
  };

  submitFilterAlerts() {
    this.setState({
      datesErrorVisible: ' hide',
    });

    if (!(this.state.fromDateTime && this.state.fromDateTime.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please enter the From Date',
      });
      return;
    }

    if (!(this.state.toDateTime && this.state.toDateTime.toString().length > 0)) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please enter the To Date',
      });
      return;
    }

    const fromDate = new Date(this.state.fromDateTime);
    const toDate = new Date(this.state.toDateTime);

    if (toDate < fromDate) {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' The From Date has to be before the To Date.',
      });
      return;
    }
    if (this.refs.selectTimeZone.value == '') {
      this.setState({
        datesErrorVisible: ' ',
        datesErrorText: ' Please select one of the available time zones.',
      });
      return;
    }

    const fromDateParam = fromDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + fromDate.toLocaleTimeString('en-US', this.timeOptions);
    const toDateParam = toDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + toDate.toLocaleTimeString('en-US', this.timeOptions);

    console.log('PARAM 1', fromDateParam);
    console.log('PARAM 2', toDateParam);
    console.log('TIMEZONE', this.refs.selectTimeZone.value);
    console.log('ACCOUNTID', AuthStore.getUserAccountID());

    this.setState({
      loading: true,
    });

    CareAlertsStore.getAlertsByAccount({
      AccountID: AuthStore.getUserAccountID(),
      FromDateTime: fromDateParam,
      ToDateTime: toDateParam,
      TimeZoneOffset: this.refs.selectTimeZone.value,
    });
    // Listen: "onGetHandledAlertsUpdated"
  }

  onGetAlertsByAccountUpdatedAction(success) {
    console.log('onGetAlertsByAccountUpdatedAction', success); //, CareAlertsStore.getAlertsArray());
    this.setState({
      loading: false,
    });

    if (success) {
      //console.log("ALERTS: ", CareAlertsStore.getAlertsArray());
      //if (CareAlertsStore.getAlertsArray().length == 0){
      //    Message.show("There are no Alerts records for the selected period of time.");
      //}

      if (this.state.activeAlertsContent != 'hide') {
        this.alertsRendered = false;
        this.setState({
          alertsArray: CareAlertsStore.getAlertsArray(),
        });
      }
    } else {
      Message.show('Sorry, There was a problem getting the alerts information. Please try again.');
    }
  }

  onOpenAlertDetailsAction(alertData) {
    console.log('onOpenAlertDetailsAction', alertData);

    /*
      let cleanDescription = alertData.AlertDescription;
        let position = cleanDescription.search("This");
        if (position > -1){
            cleanDescription = cleanDescription.substring(position);
        }
        position = cleanDescription.search("<br");
        if (position > -1){
            cleanDescription = cleanDescription.substring(0,position);
        }
        */

    this.setState({
      selectedAlertID: alertData.AlertID,
      selectedAlertPatientID: alertData.PatientID,
      selectedAlertPatientName: alertData.PatientName,
      selectedAlertOccurredOn: alertData.AlertOccurredOn,
      selectedAlertDescription: alertData.AlertDescription,

      loadingHandleAll: false,
    });

    $('#modalHandleAlert').modal('open');
    this.refs.txtAlertHandledAction.value = '';
  }

  handleCloseAlertModal() {
    if (this.state.loadingHandleAll) {
      return;
    }
    $('#modalHandleAlert').modal('close');
  }

  submitMarkAlertAsHandled() {
    console.log('submitMarkAlertAsHandled');

    console.log('ID', this.state.selectedAlertID);
    console.log('Action', this.refs.txtAlertHandledAction.value);
    console.log('User', AuthStore.getUserID());

    this.setState({
      markAlertHandledBtnStyle: 'nucleus-submit-btn-disabled',
      loadingHandleAll: true,
    });

    CareAlertsStore.markAlertAsHandled({
      AlertID: this.state.selectedAlertID,
      UserID: AuthStore.getUserID(),
      ActionTaken: this.refs.txtAlertHandledAction.value,
    });
  }

  onAlertHandledAction(succes) {
    this.setState({
      markAlertHandledBtnStyle: 'nucleus-submit-btn',
      loadingHandleAll: false,
    });

    $('#modalHandleAlert').modal('close');

    if (succes) {
      Message.show('Alert marked as Handled');

      this.submitFilterAlerts();
    } else {
      Message.show('Error marking alert as Handled, please try again.');
    }
  }

  markAllAlertsByType(typeTag, typeName) {
    console.log('markAllAlertsByType', typeTag, typeName);

    this.setState({
      selectedAlertTypeTag: typeTag,
      selectedAlertTypeName: typeName,
      loadingHandleAll: false,
    });

    $('#modalHandleAllAlerts').modal('open');
    this.refs.txtAllAlertsHandledAction.value = '';
  }

  handleCloseAllAlertsModal() {
    if (this.state.loadingHandleAll) {
      return;
    }
    $('#modalHandleAllAlerts').modal('close');
  }

  submitMarkAllAlertsAsHandled() {
    console.log('submitMarkAllAlertsAsHandled');

    console.log('Type', this.state.selectedAlertTypeTag);
    console.log('Action', this.refs.txtAllAlertsHandledAction.value);
    console.log('User', AuthStore.getUserID());

    this.setState({
      markAllAlertsHandledBtnStyle: 'nucleus-submit-btn-disabled',
      loadingHandleAll: true,
    });

    CareAlertsStore.markAlertsByTypeAsHandled({
      AccountID: AuthStore.getUserAccountID(),
      TypeTag: this.state.selectedAlertTypeTag,
      UserID: AuthStore.getUserID(),
      ActionTaken: this.refs.txtAllAlertsHandledAction.value,
    });
  }

  onAlertsByTypeHandledAction(succes) {
    if (succes && this.state.selectedAlertTypeTag == 'callNotAnswered') {
      // This is because under the "callNotAnswered" Category is also the type "callNotEstablished"
      this.setState(
        {
          selectedAlertTypeTag: 'callNotEstablished',
        },
        () => {
          this.submitMarkAllAlertsAsHandled();
        },
      );
      return;
    }

    this.setState({
      markAllAlertsHandledBtnStyle: 'nucleus-submit-btn',
      loadingHandleAll: false,
    });

    $('#modalHandleAllAlerts').modal('close');

    if (succes) {
      Message.show('Alerts marked as Handled');

      /*
        CareAlertsStore.getAlertsCountForAccount({
          AccountID : AuthStore.getUserAccountID()
        });
        */
      this.submitFilterAlerts();
    } else {
      Message.show('Error marking alerts as Handled, please try again.');
    }
  }

  // onGetAllPatientsDataHashMapAction(){
  // 	console.log("Habemus Patients");
  // 	//DEPRECATED APRIL 12th, 2018, GET INFO FROM THE PATIENT. Data is now included in the response.
  // 	/*
  // 	console.log("PATIENTS HASH: ", CarePatientStore.getPatientDataComplete());
  // 	if (CarePatientStore.getPatientDataComplete() > 0){
  // 		this.setState({
  // 			udpateDate: new Date(),
  // 			patientsDataComplete : true
  // 		});
  // 	}
  // 	*/
  // }

  handleNoClick() {
    console.log('Click');
  }

  render() {
    console.log('ALERTS RENDER this.alertsRendered:', this.alertsRendered);
    console.log('ALERTS RENDER this.state.loading:', this.state.loading);

    let tableResultsView = ' hide';
    let tableNoResultsView = ' hide';
    let tableLoadingView = '';
    if (this.state.loading) {
      tableResultsView = ' hide';
      tableNoResultsView = ' hide';
      tableLoadingView = '';
    } else {
      if (this.state.alertsArray != null && this.state.alertsArray.length > 0) {
        tableResultsView = ' ';
        tableNoResultsView = ' hide';
      } else {
        tableResultsView = ' hide';
        tableNoResultsView = ' ';
      }
    }

    const loadingHandleAllVisibility = this.state.loadingHandleAll ? '' : ' hide';
    const handleAlertsState = this.state.loadingHandleAll ? 'disabled' : '';

    if (this.state.alertsArray != null && this.state.alertsArray.length && !this.alertsRendered) {
      this.alertsRendered = true;

      this.callNotAnsweredList = [];
      this.devicesOfflineList = [];
      this.messagesAlertsList = [];
      this.biometricsAlertsList = [];
      this.homeAlertsList = [];
      this.bleDevicesAlertsList = [];
      this.miscAlertsList = [];
      this.batteryAlertsList = [];

      console.log('ALERTS RENDER ARRAY');
      this.state.alertsArray.map((alert, key) => {
        if (alert.AlertTypeName == 'callNotAnswered' || alert.AlertTypeName == 'callNotEstablished') {
          this.callNotAnsweredList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }

        if (alert.AlertTypeName == 'deviceOffline') {
          this.devicesOfflineList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }

        if (alert.AlertTypeName == 'message') {
          this.messagesAlertsList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }

        if (alert.AlertTypeName == 'biometric') {
          this.biometricsAlertsList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }

        if (alert.AlertTypeName == 'home') {
          this.homeAlertsList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }

        if (alert.AlertTypeName == 'bleDevices') {
          this.bleDevicesAlertsList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }

        if (alert.AlertTypeName == 'misc') {
          this.miscAlertsList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }
        if (alert.AlertTypeName == 'battery') {
          this.batteryAlertsList.push(
            <AlertItemRow
              key={key}
              AlertID={alert.ID}
              PatientID={alert.PatientID}
              PatientName={alert.PatientName}
              AlertAccountDateFormat={alert.HoursFormat}
              AlertOccurredOn={alert.AlertOccurredOn}
              AlertPatientTimezoneOffset={alert.PatientTimezoneOffset}
              AlertPatientTimeZone={alert.PatientTimeZone}
              AlertDescription={alert.AlertDescription}
            />,
          );
        }
      });
      console.log('ALERTS RENDER ARRAY END');
    }

    if (this.state.filterTimezonesArray != null && this.state.filterTimezonesArray.length && this.messageFilterTimezonesArray.length == 0) {
      this.state.filterTimezonesArray.map((timezone, key) => {
        //console.log("Estamos imprimiendo ", timezone.value, timezone.name);
        this.messageFilterTimezonesArray.push(
          <option key={key} value={timezone.value}>
            {timezone.name}
          </option>,
        );
      });
    }

    const callNotAnsweredMode = this.callNotAnsweredList.length > 0 ? ' ' : ' hide';
    const devicesOfflineMode = this.devicesOfflineList.length > 0 ? ' ' : ' hide';
    const messagesAlertsMode = this.messagesAlertsList.length > 0 ? ' ' : ' hide';
    const biometricsAlertsMode = this.biometricsAlertsList.length > 0 ? ' ' : ' hide';
    const homeAlertsMode = this.homeAlertsList.length > 0 ? ' ' : ' hide';
    const bleDevicesAlertsMode = this.bleDevicesAlertsList.length > 0 ? ' ' : ' hide';
    const miscAlertsMode = this.miscAlertsList.length > 0 ? ' ' : ' hide';
    const batteryAlertsMode = this.batteryAlertsList.length > 0 ? ' ' : ' hide';

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">Alert Center</p>
          <a class={'staffTabs nucleus-link ' + this.state.alertsActiveTab} onClick={this.handleActiveAlertsTab}>
            &nbsp;Active Alerts&nbsp;
          </a>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a class={'staffTabs nucleus-link ' + this.state.alertReportTab} onClick={this.handleAlertsReportTab}>
            &nbsp;Handled Alerts&nbsp;
          </a>
          <br />
          <br />
          <div class={'users-table-pending altoImportantContainer ' + this.state.activeAlertsContent}>
            <div className="users-table-pending altoImportantContainer">
              {/* <div className="nucleus-table-container"> */}

              <div className="row">
                <span className="nucleus-labels">
                  <b> &nbsp;From</b>
                </span>{' '}
                &nbsp;
                {/* <input
									id="fromDateTime"
									ref="fromDateTime"
									type="text"
									className="validate nucleus-date-picker-short"
									autoComplete="off"
								/> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      ref={this.fromDateTimeRef}
                      variant="dialog"
                      className="browser-default"
                      value={this.state.fromDateTime}
                      onChange={this.onFromDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.datetimeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-labels">
                  <b>To</b>
                </span>{' '}
                &nbsp;
                {/* <input
									id="toDateTime"
									ref="toDateTime"
									type="text"
									className="validate nucleus-date-picker-short"
									autoComplete="off"
								/> */}
                <div style={{ width: 210, display: 'inline-flex' }}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDateTimePicker
                      ref={this.toDateTimeRef}
                      variant="dialog"
                      className="browser-default"
                      value={this.state.toDateTime}
                      onChange={this.onToDateChange}
                      onError={console.log}
                      onClose={() => {}}
                      //minDate={new Date("2018-01-01T00:00")}
                      format={this.datetimeFormat}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-labels">
                  <b>From/To TimeZone</b>
                </span>{' '}
                &nbsp;
                <select
                  ref="selectTimeZone"
                  className="browser-default nucleus-select select-large"
                  style={{ backgroundColor: 'transparent', borderBottomColor: '#e6e6e6', borderBottomWidth: 1 }}
                >
                  {this.messageFilterTimezonesArray}
                </select>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a class={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={this.submitFilterAlerts}>
                  Submit
                </a>
                &nbsp;
                <br />
                <span class={'nucleus-form-error center-align ' + this.state.datesErrorVisible}>{this.state.datesErrorText}</span>
                <br />
              </div>

              <div class={'row ' + tableResultsView}>
                <div class={'no-margin ' + callNotAnsweredMode}>
                  <span className="nucleus-table-header-small">Call Alerts</span>
                  <table className="bordered highlight nucleus-table table-fake-header">
                    <thead>
                      <tr>
                        <th className="table-col-20"></th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span class={'nucleus-table-icon '} data-tooltip-id="tooltip-mark-all" data-tooltip-content={'Click to mark all alerts in the category as handled'}>
                              &nbsp;&nbsp;&nbsp;
                              <img src="/img/mark_as_handled_group.png" className="nucleus-link" onClick={this.markAllAlertsByType.bind(this, 'callNotAnswered', 'Call Alerts')} />
                              <ReactTooltip id="tooltip-mark-all" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.callNotAnsweredList}</tbody>
                  </table>

                  <br />
                  <br />
                </div>

                <div class={'no-margin ' + devicesOfflineMode}>
                  <table className="bordered highlight  nucleus-table">
                    <thead>
                      <tr>
                        <th className="table-col-20">
                          <span className="nucleus-table-header-small">Devices Went Offline</span>
                        </th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span
                              class={'nucleus-table-icon '}
                              data-tooltip-id="devices-off-tooltip_mark_as_handled_group"
                              data-tooltip-content={'Click to mark all alerts in the category as handled'}
                            >
                              &nbsp;&nbsp;&nbsp;
                              <img
                                src="/img/mark_as_handled_group.png"
                                className="nucleus-link"
                                onClick={this.markAllAlertsByType.bind(this, 'deviceOffline', 'Devices Went Offline')}
                              />
                              <ReactTooltip id="devices-off-tooltip_mark_as_handled_group" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.devicesOfflineList}</tbody>
                  </table>
                  <br />
                  <br />
                </div>

                <div class={'no-margin ' + messagesAlertsMode}>
                  <table className="bordered highlight  nucleus-table">
                    <thead>
                      <tr>
                        <th className="table-col-20">
                          <span className="nucleus-table-header-small">Messages Alerts</span>
                        </th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span
                              class={'nucleus-table-icon '}
                              data-tooltip-id="messages-alerts-tooltip"
                              data-tooltip-content={'Click to mark all alerts in the category as handled'}
                            >
                              &nbsp;&nbsp;&nbsp;
                              <img src="/img/mark_as_handled_group.png" className="nucleus-link" onClick={this.markAllAlertsByType.bind(this, 'message', 'Messages Alerts')} />
                              <ReactTooltip id="messages-alerts-tooltip" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.messagesAlertsList}</tbody>
                  </table>
                  <br />
                  <br />
                </div>

                <div class={'no-margin ' + biometricsAlertsMode}>
                  <table className="bordered highlight  nucleus-table">
                    <thead>
                      <tr>
                        <th className="table-col-20">
                          <span className="nucleus-table-header-small">Biometrics Alerts</span>
                        </th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span
                              class={'nucleus-table-icon '}
                              data-tooltip-id="biometrics-alerts-tooltip"
                              data-tooltip-content={'Click to mark all alerts in the category as handled'}
                            >
                              &nbsp;&nbsp;&nbsp;
                              <img src="/img/mark_as_handled_group.png" className="nucleus-link" onClick={this.markAllAlertsByType.bind(this, 'biometric', 'Biometrics Alerts')} />
                              <ReactTooltip id="biometrics-alerts-tooltip" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>{this.biometricsAlertsList}</tbody>
                  </table>
                  <br />
                  <br />
                </div>

                <div class={'no-margin ' + homeAlertsMode}>
                  <table className="bordered highlight  nucleus-table">
                    <thead>
                      <tr>
                        <th className="table-col-20">
                          <span className="nucleus-table-header-small">Home Environment Alerts</span>
                        </th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span class={'nucleus-table-icon '} data-tooltip-id="home_env_tooltip" data-tooltip-content={'Click to mark all alerts in the category as handled'}>
                              &nbsp;&nbsp;&nbsp;
                              <img src="/img/mark_as_handled_group.png" className="nucleus-link" onClick={this.markAllAlertsByType.bind(this, 'home', 'Home Environment Alerts')} />
                              <ReactTooltip id="home_env_tooltip" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.homeAlertsList}</tbody>
                  </table>
                  <br />
                  <br />
                </div>

                <div class={'no-margin ' + bleDevicesAlertsMode}>
                  <table className="bordered highlight  nucleus-table">
                    <thead>
                      <tr>
                        <th className="table-col-20">
                          <span className="nucleus-table-header-small">BLE Devices Alerts</span>
                        </th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span class={'nucleus-table-icon '} data-tooltip-id="ble_tooltip_id" data-tooltip-content={'Click to mark all alerts in the category as handled'}>
                              &nbsp;&nbsp;&nbsp;
                              <img
                                src="/img/mark_as_handled_group.png"
                                className="nucleus-link"
                                onClick={this.markAllAlertsByType.bind(this, 'bleDevices', 'BLE Devices Alerts')}
                              />
                              <ReactTooltip id="ble_tooltip_id" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.bleDevicesAlertsList}</tbody>
                  </table>
                  <br />
                  <br />
                </div>

                <div class={'no-margin ' + batteryAlertsMode}>
                  <table className="bordered highlight  nucleus-table">
                    <thead>
                      <tr>
                        <th className="table-col-20">
                          <span className="nucleus-table-header-small">Battery Alerts</span>
                        </th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span
                              class={'nucleus-table-icon '}
                              data-tooltip-id="battery_tooltip_alerts"
                              data-tooltip-content={'Click to mark all alerts in the category as handled'}
                            >
                              &nbsp;&nbsp;&nbsp;
                              <img src="/img/mark_as_handled_group.png" className="nucleus-link" onClick={this.markAllAlertsByType.bind(this, 'battery', 'Battery Alerts')} />
                              <ReactTooltip id="battery_tooltip_alerts" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.batteryAlertsList}</tbody>
                  </table>
                  <br />
                  <br />
                </div>

                <div class={'no-margin ' + miscAlertsMode}>
                  <table className="bordered highlight  nucleus-table">
                    <thead>
                      <tr>
                        <th className="table-col-20">
                          <span className="nucleus-table-header-small">Miscellaneous Alerts</span>
                        </th>
                        <th className="table-col-15">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-60">
                          <span className="nucleus-table-header-small"></span>
                        </th>
                        <th className="table-col-5">
                          <span className="nucleus-table-header-small">
                            <span
                              class={'nucleus-table-icon '}
                              data-tooltip-id="miscellaneous_tooltip_id"
                              data-tooltip-content={'Click to mark all alerts in the category as handled'}
                            >
                              &nbsp;&nbsp;&nbsp;
                              <img src="/img/mark_as_handled_group.png" className="nucleus-link" onClick={this.markAllAlertsByType.bind(this, 'misc', 'Miscellaneous Alerts')} />
                              <ReactTooltip id="miscellaneous_tooltip_id" place="top" variant="dark" float={true} delayShow={500} />
                            </span>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.miscAlertsList}</tbody>
                  </table>
                  <br />
                  <br />
                </div>
              </div>
              <div class={'row center-align ' + tableNoResultsView}>
                <br />
                <br />
                <br />
                <br />
                <span className="nucleus-table-header-medium text-gray">There are no active alerts for this date range </span>
                <br />
                <br />
                <br />
                <br />
              </div>
              <div class={'row center-align ' + tableLoadingView}>
                <br />
                <br />
                <br />
                <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loading} style={{ display: 'inline-flex' }} />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
          <div class={'altoImportantContainer ' + this.state.alertsReportContent}>
            <AlertsReport />
          </div>
          <div id="modalHandleAlert" className="modal modalAlert modalScrollableNotWide">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img class={'responsive-img img-close-modal '} src="/img/close_modal.png" onClick={this.handleCloseAlertModal} />
              <h3 className="nucleus-page-subtitle">Mark as handled</h3>
              <br />
              <br />
              <div className="row ">
                <span className="nucleus-labels">
                  <b>Alert Date & Time:</b> {this.state.selectedAlertOccurredOn}
                </span>
              </div>
              <div className="row ">
                <span className="nucleus-labels">
                  <b>Patient:</b> {this.state.selectedAlertPatientName}
                </span>
              </div>
              <div className="row ">
                <span className="nucleus-labels">
                  <b>{this.state.selectedAlertDescription}</b>{' '}
                </span>
              </div>
              <div className="row ">
                <span className="nucleus-labels">Enter the actions taken to handle this alert:</span>
                <br />
                <textarea ref="txtAlertHandledAction" className="nucleus-textarea-medium " defaultValue="" disabled={handleAlertsState}></textarea>
                <br />
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a class={'txt-white nucleus-font-modal-btn ' + this.state.markAlertHandledBtnStyle} onClick={this.submitMarkAlertAsHandled}>
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              <div class={'row center-align spinner-modal' + loadingHandleAllVisibility} onClick={this.handleNoClick}>
                <br />
                <br />
                <br />
                <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingHandleAll} style={{ display: 'inline-flex' }} />
              </div>
            </div>
          </div>
          <div id="modalHandleAllAlerts" className="modal modalAlert modalScrollableNotWide">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img class={'responsive-img img-close-modal '} src="/img/close_modal.png" onClick={this.handleCloseAllAlertsModal} />
              <h3 className="nucleus-page-subtitle">Mark All Handled</h3>
              <br />
              <br />
              <div className="row ">
                <span className="nucleus-labels">
                  <b>{this.state.selectedAlertTypeName}</b>
                </span>
              </div>
              <div className="row ">
                <span className="nucleus-labels">Enter the actions taken to handle these alerts:</span>
                <br />
                <textarea ref="txtAllAlertsHandledAction" className="nucleus-textarea-medium " defaultValue="" disabled={handleAlertsState}></textarea>
                <br />
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a class={'txt-white nucleus-font-modal-btn ' + this.state.markAllAlertsHandledBtnStyle} onClick={this.submitMarkAllAlertsAsHandled}>
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              <div class={'row center-align spinner-modal' + loadingHandleAllVisibility} onClick={this.handleNoClick}>
                <br />
                <br />
                <br />
                <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loadingHandleAll} style={{ display: 'inline-flex' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Alerts;
