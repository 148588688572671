export const TrashSVG = () => {
  return (
    <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5042 3.82423C12.4909 3.82423 12.4709 3.82423 12.4509 3.82423C8.92369 3.45446 5.40319 3.31492 1.916 3.6847L0.555799 3.82423C0.275758 3.85214 0.029056 3.64283 0.00238546 3.34981C-0.0242851 3.05678 0.175744 2.80562 0.449117 2.77771L1.80931 2.63817C5.35651 2.26142 8.95037 2.40794 12.5509 2.77771C12.8243 2.80562 13.0243 3.06376 12.9976 3.34981C12.9776 3.6219 12.7576 3.82423 12.5042 3.82423Z"
        fill="#FF4C3F"
      />
      <path
        d="M4.17049 3.11864C4.14382 3.11864 4.11715 3.11864 4.08381 3.11166C3.8171 3.06283 3.63041 2.79073 3.67708 2.51166L3.82377 1.59769C3.93045 0.927918 4.07714 0 5.63067 0H7.37759C8.93782 0 9.08451 0.962802 9.18452 1.60467L9.33121 2.51166C9.37788 2.79771 9.19119 3.0698 8.92448 3.11166C8.65111 3.1605 8.39107 2.96515 8.35107 2.68608L8.20438 1.77909C8.11103 1.17211 8.09103 1.0535 7.38426 1.0535H5.63734C4.9306 1.0535 4.91726 1.15118 4.81725 1.77211L4.66389 2.6791C4.62389 2.93724 4.41052 3.11864 4.17049 3.11864Z"
        fill="#FF4C3F"
      />
      <path
        d="M8.64261 14.9998H4.36198C2.03498 14.9998 1.94163 13.6533 1.86829 12.5649L1.43489 5.53925C1.41489 5.2532 1.62825 5.00204 1.90163 4.98111C2.18167 4.96715 2.41504 5.18344 2.43504 5.46949L2.86843 12.4952C2.94178 13.5556 2.96845 13.9533 4.36198 13.9533H8.64261C10.0428 13.9533 10.0695 13.5556 10.1362 12.4952L10.5696 5.46949C10.5896 5.18344 10.8296 4.96715 11.103 4.98111C11.3763 5.00204 11.5897 5.24622 11.5697 5.53925L11.1363 12.5649C11.063 13.6533 10.9696 14.9998 8.64261 14.9998Z"
        fill="#FF4C3F"
      />
      <path
        d="M7.61103 11.1637H5.39071C5.11732 11.1637 4.89062 10.9265 4.89062 10.6404C4.89062 10.3544 5.11732 10.1172 5.39071 10.1172H7.61103C7.8844 10.1172 8.1111 10.3544 8.1111 10.6404C8.1111 10.9265 7.8844 11.1637 7.61103 11.1637Z"
        fill="#FF4C3F"
      />
      <path
        d="M8.16982 8.3727H4.83601C4.56264 8.3727 4.33594 8.13548 4.33594 7.84943C4.33594 7.56338 4.56264 7.32617 4.83601 7.32617H8.16982C8.4432 7.32617 8.6699 7.56338 8.6699 7.84943C8.6699 8.13548 8.4432 8.3727 8.16982 8.3727Z"
        fill="#FF4C3F"
      />
    </svg>
  );
};
