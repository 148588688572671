import { useSelector } from 'react-redux';

export function useIsLoadingAccountUsersProvider() {
  return useSelector(({ accountUsersProviders }) => {
    return accountUsersProviders.saving;
  });
}

export function useAccountUsersProvider() {
  return useSelector(({ accountUsersProviders }) => {
    if (!accountUsersProviders.success) return [];
    return accountUsersProviders.users;
  });
}
