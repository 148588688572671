import { useSelector } from 'react-redux';

export function useConfigAccessSelector(accessName) {
  return useSelector(({ userLoggedInConfig }) => {
    if (!userLoggedInConfig) return {};
    const { configurations } = userLoggedInConfig;
    if (!configurations[accessName]) return {};
    return configurations[accessName];
  });
}
