import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
class MdmDeviceApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<----------------------------------Reboot Mdm Device--------------------------->
  async requestDeviceReboot(data) {
    console.log('requestDeviceReboot', data);
    const response = await httpNodeApi.post(`/mdm/devices/${data.MdmID}/reboot`);
    return response;
  }
}

export default MdmDeviceApi;
