import React from 'react';
import { JQuery } from 'types/jquery.types';
import AuthStore from '../../stores/AuthStore';
import CareUserStore from '../../stores/CareUserStore';
import GeneralStore from '../../stores/GeneralStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
import UserPatientAvailableItemRow from './UserPatientAvailableItemRow';
class NewAssignedPatient extends React.Component<
  {
    match?: any;
  },
  {
    userFullName: string;
    userAllPatientsAccess: boolean;
    btnStyle: string;
    userAvailablePatients: any[];
    defaultSelected: boolean;
    handleAllPatientsAccessModalLabel: string;
    handleAllAccessCheckOldValue: boolean;
  }
> {
  private patientToAssign: number;
  private patientAssigned: number;

  constructor(props) {
    super(props);

    validateAccessGranted('/newAssignedPatient');

    const { match } = this.props;
    this.state = {
      userFullName: CareUserStore.getCurrentUserName(),
      userAllPatientsAccess: CareUserStore.getUserAllPatientsAccess().toString() == 'true',
      btnStyle: ' disabled',
      userAvailablePatients: [],
      defaultSelected: false,
      handleAllPatientsAccessModalLabel: 'allow',
      handleAllAccessCheckOldValue: false,
    };

    console.log(match.params.userId);
    console.log('Getting: ', CareUserStore.getUserAllPatientsAccess(), ' Pero: ' + (CareUserStore.getUserAllPatientsAccess().toString() == 'true'));

    this.onGetPossiblePatientsAction = this.onGetPossiblePatientsAction.bind(this);
    this.onPatientAssignListHasChangedAction = this.onPatientAssignListHasChangedAction.bind(this);

    this.submitAssignPatients = this.submitAssignPatients.bind(this);
    this.onAssignPatientAction = this.onAssignPatientAction.bind(this);
    this.onUnassignPatientAction = this.onUnassignPatientAction.bind(this);

    this.handleSelectAllClients = this.handleSelectAllClients.bind(this);

    this.onAssignPatientsGroupAction = this.onAssignPatientsGroupAction.bind(this);
    this.onUnassignPatientsGroupAction = this.onUnassignPatientsGroupAction.bind(this);

    this.patientToAssign = 0;
    this.patientAssigned = 0;

    this.handleCancelAssignAllPatients = this.handleCancelAssignAllPatients.bind(this);
    this.handleAssignAllPatients = this.handleAssignAllPatients.bind(this);
    this.onSetSeeAllPatientsAction = this.onSetSeeAllPatientsAction.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    // CareUserStore.on("onGetPossiblePatients", this.onGetPossiblePatientsAction);
    GeneralStore.on('onPatientAssignListHasChanged', this.onPatientAssignListHasChangedAction);

    CareUserStore.on('onAssignPatient', this.onAssignPatientAction);
    CareUserStore.on('onUnassignPatient', this.onUnassignPatientAction);

    CareUserStore.on('onAssignPatientsGroup', this.onAssignPatientsGroupAction);
    CareUserStore.on('onUnassignPatientsGroup', this.onUnassignPatientsGroupAction);

    CareUserStore.on('onSetSeeAllPatients', this.onSetSeeAllPatientsAction);

    if (match.params.userId && !(CareUserStore.getUserAllPatientsAccess().toString() == 'true')) {
      CareUserStore.getPossiblePatients({
        UserID: match.params.userId,
      });
    }
    ($('.tooltipped') as unknown as JQuery).tooltip({ delay: 50 });
    // $('.modal').modal({
    //       dismissible: true,
    //       complete: function() {  } // Callback for Modal close
    //     }
    // );
  }

  componentWillUnmount() {
    // CareUserStore.removeListener("onGetPossiblePatients", this.onGetPossiblePatientsAction);
    GeneralStore.removeListener('onPatientAssignListHasChanged', this.onPatientAssignListHasChangedAction);

    CareUserStore.removeListener('onAssignPatient', this.onAssignPatientAction);
    CareUserStore.removeListener('onUnassignPatient', this.onUnassignPatientAction);

    CareUserStore.removeListener('onAssignPatientsGroup', this.onAssignPatientsGroupAction);
    CareUserStore.removeListener('onUnassignPatientsGroup', this.onUnassignPatientsGroupAction);

    CareUserStore.removeListener('onSetSeeAllPatients', this.onSetSeeAllPatientsAction);
    ($('.tooltipped') as unknown as JQuery).tooltip('remove');
  }

  onGetPossiblePatientsAction(response) {
    console.log('onGetPossiblePatientsAction', response);
    console.log(response);

    if (response.ok) {
      this.setState({
        userAvailablePatients: response.Patients,
      });
    } else {
      Message.show('Error getting available ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' information');
    }
  }

  onPatientAssignListHasChangedAction(patientToAssign) {
    console.log('onPatientAssignListHasChangedAction', patientToAssign);
    this.patientToAssign = patientToAssign;
    if (patientToAssign > 0) {
      this.setState({
        btnStyle: '',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }
  }

  // CHANGED Sep 20th - Change CARE-893
  submitAssignPatients() {
    // Button to trigger this method is hidden.
    // this.patientAssigned = 0;
    // this.setState({
    //     btnStyle : "disabled"
    // });
    // let patientsToAssign = GeneralStore.getPatientAssignListData();
    // console.log(patientsToAssign);
    // for(var i = 0; i < patientsToAssign.length; i++){
    //     console.log("Assigning " + patientsToAssign[i].ID + ", " + patientsToAssign[i].Name);
    //     CareUserStore.assignPatient({
    //         UserID : match.params.userId,
    //         PatientID : patientsToAssign[i].ID
    //     });
    // }
  }

  // CHANGED Sep 20th - Change CARE-893
  // Method replaced
  // onAssignPatientAction(response){
  //     console.log("onAssignPatientAction", response);

  //     this.patientAssigned = this.patientAssigned + 1;
  //     if (this.patientAssigned >= this.patientToAssign){
  //         CareUserStore.getPossiblePatients({
  //             UserID : match.params.userId
  //         });
  //         CareUserStore.getAllPatientsForUser({
  //             UserID : match.params.userId
  //         });
  //         if (this.patientToAssign == 1 ){
  //             Message.show(AuthStore.getPatientLabel() + " Assigned");
  //         } else{
  //             Message.show(AuthStore.getPatientLabelPlural() + " Assigned");
  //         }
  //         let userID = match.params.userId;
  //         setTimeout(function(){
  //             window.location.assign("#/userProfile/" + userID);
  //         }, 100);
  //     }
  // }

  onAssignPatientAction(response) {
    console.log('onAssignPatientAction', response);

    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' Assigned');
    } else {
      Message.show('Error assigning ' + AuthStore.getPatientLabel().toLowerCase() + ' to this user.');
    }
  }

  onUnassignPatientAction(response) {
    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' Unassigned');
    } else {
      Message.show(
        'Sorry,  there was an error unassigning the ' + AuthStore.getPatientLabel().toLowerCase() + ' from the ' + AuthStore.getUserLabel().toLowerCase() + ', please try again',
      );
    }
  }

  handleSelectAllClients(checkbox) {
    console.log('This has changed ', checkbox.target.checked);

    // this.setState({
    //     defaultSelected : checkbox.target.checked,
    //     updated : new Date()
    // });

    // CHANGED Sep 20th - Change CARE-893
    // this.patientToAssign = this.state.userAvailablePatients.length;
    // this.patientAssigned = 0;

    // let patientsArray = this.state.userAvailablePatients;
    // if (checkbox.target.checked){
    //     for(var i = 0; i < patientsArray.length; i++){
    //         console.log("Assigning " + patientsArray[i].PatientID );
    //         if (!patientsArray[i].Assigned){
    //             CareUserStore.assignPatientsGroup({
    //                 UserID : match.params.userId,
    //                 PatientID : patientsArray[i].PatientID
    //             });
    //         } else{
    //             this.patientAssigned = this.patientAssigned + 1;
    //         }
    //     }
    // } else{

    //     for(var i = 0; i < patientsArray.length; i++){
    //         console.log("Unassigning " + patientsArray[i].PatientID );
    //         if (patientsArray[i].Assigned){
    //             CareUserStore.unassignPatientsGroup({
    //                 UserID : match.params.userId,
    //                 PatientID : patientsArray[i].PatientID
    //             });
    //         } else{
    //             this.patientAssigned = this.patientAssigned + 1;
    //         }
    //     }
    // }

    if (checkbox.target.checked) {
      this.setState({
        handleAllPatientsAccessModalLabel: 'allow',
        handleAllAccessCheckOldValue: false,
      });
      ($('#modalAssignAllPatients') as unknown as JQuery).modal('open');
    } else {
      this.setState({
        handleAllPatientsAccessModalLabel: 'cancel',
        handleAllAccessCheckOldValue: true,
      });
      ($('#modalAssignAllPatients') as unknown as JQuery).modal('open');
    }
  }

  onAssignPatientsGroupAction() {
    const { match } = this.props;
    this.patientAssigned = this.patientAssigned + 1;

    if (this.patientAssigned >= this.patientToAssign) {
      CareUserStore.getPossiblePatients({
        UserID: match.params.userId,
      });
      Message.show(AuthStore.getPatientLabelPlural() + ' Assigned');
    }
  }
  onUnassignPatientsGroupAction() {
    const { match } = this.props;
    this.patientAssigned = this.patientAssigned + 1;

    if (this.patientAssigned >= this.patientToAssign) {
      CareUserStore.getPossiblePatients({
        UserID: match.params.userId,
      });
      Message.show(AuthStore.getPatientLabelPlural() + ' Unassigned');
    }
  }

  handleCancelAssignAllPatients() {
    //@ts-ignore
    this.refs.checkSelectAll.checked = this.state.handleAllAccessCheckOldValue;
    ($('#modalAssignAllPatients') as unknown as JQuery).modal('close');
  }

  handleAssignAllPatients() {
    const { match } = this.props;
    CareUserStore.setSeeAllPatients({
      UserID: match.params.userId,
      //@ts-ignore
      CanSee: this.refs.checkSelectAll.checked,
    });
  }

  onSetSeeAllPatientsAction(result) {
    const { match } = this.props;
    console.log('onSetSeeAllPatientsAction');
    console.log(result);

    if (result.ok) {
      this.setState({
        userAllPatientsAccess: result.canSee,
      });

      CareUserStore.setUserAllPatientsAccess(result.canSee);
      if (result.canSee) {
        ($('#modalAssignAllPatients') as unknown as JQuery).modal('close');
        ////GeneralStore.requestHandleBack();
        //this.history.goBack();
        Message.show(AuthStore.getUserLabel() + ' has all ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' access now');
      } else {
        CareUserStore.getPossiblePatients({
          UserID: match.params.userId,
        });
        ($('#modalAssignAllPatients') as unknown as JQuery).modal('close');
        Message.show(AuthStore.getUserLabel() + ' can see only assigned ' + AuthStore.getPatientLabelPlural().toLowerCase());
      }
    } else {
      Message.show('Error updating ' + AuthStore.getUserLabel().toLowerCase() + ' profile, please try again.');
    }
  }

  render() {
    const { match } = this.props;
    const patientsList = [];
    if (this.state.userAvailablePatients != null) {
      this.state.userAvailablePatients.map((patient, key) => {
        patientsList.push(
          <UserPatientAvailableItemRow
            key={key}
            UserID={match.params.userId}
            PatientID={patient.PatientID}
            Name={patient.FirstName + ' ' + patient.LastName}
            Telephone={patient.Telephone}
            DefaultSelected={this.state.defaultSelected}
            Assigned={patient.Assigned}
          />,
        );
      });
    }

    console.log('Rendering : ', this.state.userAllPatientsAccess);
    const seeAllPatientsValue = this.state.userAllPatientsAccess ? 'checked' : '';
    const caregiversContent = this.state.userAllPatientsAccess ? ' hide' : '';

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">Assigned {AuthStore.getPatientLabelPlural()} List</p>
          <div className="row">
            <div className="col s8 no-margin left-align">
              <span className="nucleus-labels-height">
                {' '}
                {'Select the ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' that will be assigned to: ' + this.state.userFullName}
              </span>
            </div>
            <div className="col s4 no-margin right-align">
              <div className="switch">
                <span className="nucleus-labels-height"> Allow access to all {AuthStore.getPatientLabelPlural().toLowerCase()} </span>
                <label key={seeAllPatientsValue}>
                  <input type="checkbox" defaultChecked={!!seeAllPatientsValue} ref="checkSelectAll" onChange={this.handleSelectAllClients} />
                  <span className="lever"></span>
                </label>
              </div>
            </div>
          </div>

          <div className={'row ' + caregiversContent}>
            <div className="nucleus-table-container">
              <table className="bordered highlight nucleus-table">
                <thead>
                  <tr>
                    <th className="left-align table-col-45">
                      <span className="nucleus-table-header">Name</span>
                    </th>
                    <th className="left-align table-col-45">
                      <span className="nucleus-table-header">Telephone</span>
                    </th>
                    <th className="right-align table-col-5"></th>
                  </tr>
                </thead>

                <tbody>{patientsList}</tbody>
              </table>
            </div>
            <div className="fixed-action-btn btn-add-new hide">
              <a
                className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
                data-position="left"
                data-delay="50"
                data-tooltip={'Assign ' + AuthStore.getPatientLabelPlural()}
                onClick={this.submitAssignPatients}
              >
                <i className="material-icons">save</i>
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>

          <div id="modalAssignAllPatients" className="modal modalAssignAllPatients">
            <div className="modal-content modal-general-content">
              <h3 className="nucleus-page-subtitle"> All {AuthStore.getPatientLabelPlural()} access</h3>
              <p></p>
              <div className="row">
                <div className="col s12 left-align nucleus-font-medium">
                  Are you sure you want to {this.state.handleAllPatientsAccessModalLabel} access to all {AuthStore.getPatientLabelPlural().toLowerCase()}?
                  <br />
                </div>
              </div>
              <div className="row ">
                <div className="col s12 right-align ">
                  <p></p>
                  <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelAssignAllPatients}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleAssignAllPatients}>
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewAssignedPatient;
