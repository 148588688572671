export const VideoIcon = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Video">
        <rect id="Rectangle 74" x="1" y="4" width="16.8" height="16" rx="2.5" stroke="#0A313F" strokeWidth="2" />
        <path
          id="Rectangle 74_2"
          d="M17.8003 10.3512L23.7366 6.12921C24.2663 5.75251 25.0003 6.13119 25.0003 6.78114V17.2305C25.0003 17.8781 24.271 18.2574 23.7409 17.8855L17.8003 13.7183V10.3512Z"
          stroke="#0A313F"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
