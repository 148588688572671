import React, { ReactNode } from 'react';
import { NucleusProvidersProvider } from './nucleusProvidersContext/NucleusProvidersContext';

interface ContextProviderProps {
  children: ReactNode;
}

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
  return <NucleusProvidersProvider>{children}</NucleusProvidersProvider>;
};
