import { PasswordWithRules, Rules, RegexRule, LengthRule, BlackListRule, MustMatchRule } from '@nucleus-care/react-native-nucleuscare-connect-security-client';
import React, { ReactElement } from 'react';
import { useState, useEffect, useRef } from 'react';

type FormParams = {
  onPasswordStateChange: (data?: any, b?: any) => void;
  userData: any;
};

const SettingsUserEditForm = (props: FormParams) => {
  const [stateNewPassword1, setStateNewPassword1] = useState('');
  const [stateNewPassword2, setStateNewPassword2] = useState('');
  const nucleusNewPasswordRef = useRef();
  const nucleusConfirmPasswordRef = useRef();
  const [stateValidForm, setStateValidForm] = useState(false);

  const [stateInput1Secret, setStateInput1Secret] = useState(true);
  const [stateInput2Secret, setStateInput2Secret] = useState(true);

  useEffect(() => {
    console.log('PasswordForm useEffect');
    console.log('PasswordForm props', props);
    //setStateLoading(props.updatingUser);
  }, []);

  const validateTxtInput1 = e => {
    //console.log("validateTxtInput1", e.target.value);
    setStateNewPassword1(e.target.value);
    if (e.target.value == '') {
      const state = {
        isValid: false,
      };
      onPasswordHasChanged(state);
    }
  };

  const validateTxtInput2 = e => {
    //console.log("validateTxtInput2", e.target.value);
    setStateNewPassword2(e.target.value);

    if (e.target.value == '') {
      const state = {
        isValid: false,
      };
      onPasswordHasChanged(state);
    }
  };

  const onSwitchPassword1View = () => {
    setStateInput1Secret(!stateInput1Secret);
  };
  const onSwitchPassword2View = () => {
    setStateInput2Secret(!stateInput2Secret);
  };

  const onPasswordHasChanged = passwordsData => {
    console.log('onPasswordHasChanged', passwordsData);

    if (passwordsData.isValid != stateValidForm) {
      setStateValidForm(passwordsData.isValid);
      props.onPasswordStateChange(passwordsData.isValid, passwordsData.password);
    }
  };

  const renderPasswordForm = (errors: ReactElement) => {
    //console.log("renderPasswordForm errors", errors);

    return (
      <div>
        <div className="row">
          <div className="col s4">
            <span className=" nucleus-labels"> New Password</span>
          </div>
          <div className="col s8">
            <div style={{ display: 'flex' }}>
              <input
                //ref="txtUserEditNewPassword"
                ref={nucleusNewPasswordRef}
                type={stateInput1Secret ? 'password' : 'text'}
                className="validate nucleus-input-form"
                //defaultValue={defaultPasswordValue}
                placeholder="New Password"
                onChange={validateTxtInput1}
              />
              <img src="/img/view_password.png" className="nucleus-link" style={{ width: 24, height: 16, marginLeft: -24, marginTop: 6 }} onClick={onSwitchPassword1View} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col s4"></div>
          <div className="col s8">
            <p className="login-info nucleus-font-family-medium" style={{ fontSize: 14, marginBottom: 8, color: '#8a8a8a' }}>
              {' '}
              Password must:{' '}
            </p>
            {errors}
          </div>
        </div>

        <div className="row">
          <div className="col s4">
            <span className=" nucleus-labels"> Confirm Password</span>
          </div>
          <div className="col s8">
            <div style={{ display: 'flex' }}>
              <input
                //ref="txtUserEditConfirmPassword"
                ref={nucleusConfirmPasswordRef}
                type={stateInput2Secret ? 'password' : 'text'}
                className="validate nucleus-input-form"
                //defaultValue={defaultPasswordValue}
                placeholder="Confirm Password"
                onChange={validateTxtInput2}
              />
              <img src="/img/view_password.png" className="nucleus-link" style={{ width: 24, height: 16, marginLeft: -24, marginTop: 6 }} onClick={onSwitchPassword2View} />
            </div>
          </div>
        </div>

        {/* <div className="row center-align no-margin">
                    <div className="input-field col s1 m3 l3"></div>
                    <div className="input-field col s10 m6 l6" >
                        <div style={{display:'flex'}}>
                            <input 
                            ref={nucleusNewPasswordRef} 
                            type={stateInput1Secret ? "password" : "text"}
                            name="nucleus-password" 
                            className="validate nucleus-input-login2 nucleus-font"
                            placeholder="New password" 
                            onKeyPress={validateFieldsFromKeyboard } 
                            
                            style={{fontSize:16}} />

                            <img
                                src="/img/view_password.png"
                                className="nucleus-link"
                                style={{width:24, height:16, marginLeft:-24, marginTop:8}}
                                onClick={onSwitchPassword1View}
                                />
                        </div>
                    </div>
                    <div className="input-field col s1 m3 l3"></div>
                </div>
                <div className="input-field row no-margin left-align" >
                        <div className="col s1 m1 l1"></div>  
                        <div className="col s11 m11 l11">
                            
                        </div>    
                    </div>
                    <div className="input-field row no-margin left-align" >  
                        <div className="col s1 m1 l1"></div>  
                        <div className="col s11 m11 l11">
                            <div className="row no-margin" >  
                            {
                                errors
                            }
                            </div>                   
                        </div> 
                    </div>
                <br/>
                <div className="row center-align no-margin">
                    <div className="input-field col s1 m3 l3"></div>
                    <div className="input-field col s10 m6 l6">
                        <div style={{display:'flex'}}>
                            <input 
                            ref={nucleusConfirmPasswordRef}
                            type={stateInput2Secret ? "password" : "text"}
                            name="nucleus-confirm" 
                            className="validate nucleus-input-login2 nucleus-font"
                            placeholder="Confirm password" 
                            onKeyPress={validateFieldsFromKeyboard } 
                            onChange={validateTxtInput2}
                            style={{fontSize:16}} />
                            
                            <img
                                src="/img/view_password.png"
                                className="nucleus-link"
                                style={{width:24, height:16, marginLeft:-24, marginTop:8}}
                                onClick={onSwitchPassword1View}
                                />
                        </div>
                    </div>
                    <div className="input-field col s1 m3 l3"></div>
                </div> */}
      </div>
    );
  };

  const errorRow = (hasError, label) => {
    return (
      <div style={{ margin: 0, padding: 0 }}>
        <p className="login-info txt-white nucleus-font-small nucleus-font-book" style={{ margin: 0, lineHeight: 1, padding: 0 }}>
          <span style={{ color: hasError ? '#ff4a50' : '#9c9c9c', fontSize: 20, fontWeight: 'bolder' }}>&nbsp;·&nbsp;</span>
          {hasError && <span style={{ color: '#ff4a50', fontSize: 13 }}>{label}</span>}
          {!hasError && <span style={{ color: '#9c9c9c', fontSize: 13 }}>{label}</span>}
        </p>
      </div>
    );
  };

  return (
    <div>
      <PasswordWithRules password={stateNewPassword1} confirmationPassword={stateNewPassword2} renderContent={renderPasswordForm} onChange={onPasswordHasChanged}>
        <Rules>
          <LengthRule min={8} renderError={hasError => errorRow(hasError, 'Be at least 8 characters long.')} />
          <RegexRule regex={/([A-Z])/} renderError={hasError => errorRow(hasError, 'Contain at least 1 uppercase character.')} />
          <RegexRule regex={/([a-z])/} renderError={hasError => errorRow(hasError, 'Contain at least 1 lowercase character.')} />
          <RegexRule regex={/([0-9])/} renderError={hasError => errorRow(hasError, 'Contain at least 1 numeric character.')} />
          <BlackListRule list={[props.userData.firstName, props.userData.lastName]} renderError={hasError => errorRow(hasError, 'Not contain your username')} />
          <BlackListRule
            list={[props.userData.firstName, props.userData.lastName, props.userData.email]}
            renderError={hasError => errorRow(hasError, 'Not contain your contact info')}
          />
          <BlackListRule list={['12345678']} renderError={hasError => errorRow(hasError, 'Not be one that is disallowed')} />
          <MustMatchRule value={stateNewPassword2} renderError={hasError => errorRow(hasError, 'Password and Confirm Password must match.')} />
        </Rules>
      </PasswordWithRules>
    </div>
  );
};

export default SettingsUserEditForm;
