import React, { useCallback, useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

type sortModeType = 'asc' | 'desc';

export interface Pagination {
  currentPage: number;
  rowsPerPage: number;
  totalRows: number;
  sorting?: 'asc' | 'desc';
}

type PageData = {
  label: string;
  value: number;
};

const TablePagination = ({ currentPage, rowsPerPage, totalData, onPageSelected = (page: number) => {} }) => {
  const [showing, setShowing] = useState<{ from: number; to: number }>({ from: 0, to: 0 });
  const [pagesDictionary, setPagesDictionary] = useState<React.ReactNode[]>([]);
  const totalPages = useRef(0);

  useEffect(() => {
    console.log('TablePagination');
    console.log('TablePagination currentPage', currentPage);
    console.log('TablePagination rowsPerPage', rowsPerPage);
    console.log('TablePagination totalData', totalData);
    const pageLimit = currentPage * rowsPerPage;
    setShowing({
      from: (currentPage - 1) * rowsPerPage + 1,
      to: pageLimit > totalData ? totalData : pageLimit,
    });
    calculatePages();
  }, [currentPage]);

  const calculatePages = useCallback(() => {
    console.log('TablePagination calculatePages');
    const pages = totalData / rowsPerPage;
    console.log('TablePagination calculatePages pages', pages);
    totalPages.current = pages;
    setPagesDictionary([]);
    const pagesArray: PageData[] = [];
    let pageIndex = 0;
    pages > 2 && pagesArray.push({ label: '<', value: 0 });
    while (pageIndex < pages) {
      pageIndex++;
      pagesArray.push({ label: pageIndex.toString(), value: pageIndex });
    }
    console.log('TablePagination calculatePages pagesArray', pagesArray);
    pages > 2 && pagesArray.push({ label: '>', value: 1000 });

    const pagesItems: React.ReactNode[] = [];
    for (let index = 0; index < pagesArray.length; index++) {
      const page = pagesArray[index];
      pagesItems.push(<PageIndex pageData={page} currentPage={currentPage} onClick={onPageIndexClick} />);
    }
    setPagesDictionary(pagesItems);
  }, [currentPage]);

  const onPageIndexClick = (pageData: PageData) => {
    console.log('TablePagination onPageIndexClick pageData', pageData);
    console.log('TablePagination onPageIndexClick currentPage', currentPage);
    console.log('TablePagination onPageIndexClick  totalPages.current', totalPages.current);
    if (pageData.value != 0 && pageData.value != 1000) {
      onPageSelected(pageData.value);
    } else if (pageData.value == 0 && currentPage > 1) {
      onPageSelected(currentPage - 1);
    } else if (pageData.value == 1000 && currentPage < totalPages.current) {
      onPageSelected(currentPage + 1);
    }
  };

  if (totalData < 1)
    return (
      <PaginationContainer>
        <PaginationEmptyInfo>
          <span>{`Showing 0 of ${totalData}`}</span>
        </PaginationEmptyInfo>
      </PaginationContainer>
    );
  return (
    <PaginationContainer>
      <PaginationInfo>
        <span>{`Showing ${showing.from} - ${showing.to} of ${totalData}`}</span>
      </PaginationInfo>
      <PaginationContent>
        <PaginationPagesWrapper>{pagesDictionary}</PaginationPagesWrapper>
      </PaginationContent>
    </PaginationContainer>
  );
};

const PageIndex = ({ pageData, currentPage, onClick }) => {
  return (
    <div className={pageData.value == currentPage ? 'page-index-selected' : 'page-index'} onClick={() => onClick(pageData)}>
      <span>{pageData.label}</span>
    </div>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-top: 5px;
  border-top-color: #a1a1a131;
  border-top-style: solid;
  border-top-width: 1px;
`;
const PaginationEmptyInfo = styled.div`
  display: flex;
  color: #a0a0a0;
  font-family: 'Fira Sans';
`;
const PaginationInfo = styled.div`
  display: flex;
  margin-left: 9px;
  color: #a0a0a0;
  font-family: 'Fira Sans';
`;
const PaginationContent = styled.div`
  display: flex;
  justify-content: center;
`;

const PaginationPagesWrapper = styled.div`
  display: flex;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 28px;
  padding: 5px 10px;
`;

const styles = {
  passedDate: {
    color: '#ACB4BE',
  },
  validDate: {
    color: 'black',
    fontFamily: 'FiraSans',
  },
};

export default TablePagination;
