//@ts-nocheck
import moment from 'moment';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';

import { SpinnerCircular } from 'spinners-react';
import AuthStore from '../../stores/AuthStore';
import CarePatientStore from '../../stores/CarePatientStore';

import Message from '../../utils/Message';
import PatientsCss from './Patients.module.css';

// type patientType = {
//     FirstName :  string;
//     LastName :  string;
//     Telephone: string;
//     Address: string;
// }
// ===================================================================== \
// Import Patients from CSV	  																							|
// ===================================================================== /
const ImportPatientsFromCSV = props => {
  console.log('ImportPatientsFromCSV PropsData', props);

  useEffect(() => {
    $('.modalImportPatients').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
    $('.modalPopup').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });
  }, []);

  const [stateSelectFileBtnStyle, setStateSelectFileBtnStyle] = useState(' nucleus-submit-btn-mini');
  const [stateSelectFileError, setStateSelectFileError] = useState(false);
  const [selectedCsvFile, setSelectedCsvFile] = useState(null);
  const fileReader = new FileReader();
  const selectFileRef = useRef();
  const selectFileNameRef = useRef();
  const [stateCsvFileData, setStateCsvFileData] = useState([]);
  const csvFileData = useRef();
  const [stateCsvFileIndex, setStateCsvFileIndex] = useState(0);
  const csvFileIndex = useRef();
  const csvFileCounterOk = useRef();
  const csvFileCounterError = useRef();
  const [stateLoading, setStateLoading] = useState(false);
  const [stateProcesingFile, setStateProcesingFile] = useState(false);
  const [stateStatusString, setStateStatusString] = useState('');
  const [stateStatusDone, setStateStatusDone] = useState(false);

  const [statePopupTitle, setStatePopupTitle] = useState('');
  const [statePopupInfo, setStatePopupInfo] = useState('');

  // Export result to CSV
  const csvFileHeadersRef = useRef([]);
  csvFileHeadersRef.current = [
    { label: AuthStore.getPatientLabelPlural(), key: 'Number' },
    { label: 'FirstName', key: 'FirstName' },
    { label: 'LastName', key: 'LastName' },
    { label: 'DateOfBirth', key: 'DateOfBirth' },
    { label: 'Telephone', key: 'Telephone' },
    { label: 'Address', key: 'Address' },
    { label: 'Added', key: 'OkString' },
    { label: 'Details', key: 'Info' },
  ];
  //const [stateMessagesDataToExport, setStateMessagesDataToExport] = useState([]);
  const reportFileNameRef = useRef('');

  const onCloseImportModal = () => {
    console.log('onOpenImportModal');
    $('#modalImportPatients').modal('close');
  };

  const onOpenImportModal = () => {
    console.log('onOpenImportModal');
    setStateSelectFileBtnStyle(' nucleus-submit-btn-mini');

    setSelectedCsvFile(null);
    setStateLoading(false);
    setStateProcesingFile(false);
    setStateStatusDone(false);
    setStateCsvFileData([]);
    csvFileData.current = [];
    csvFileCounterOk.current = 0;
    csvFileCounterError.current = 0;
    selectFileRef.current.value = '';
    selectFileNameRef.current.value = '';

    $('#modalImportPatients').modal('open');
  };

  const handleSelectedFile = e => {
    console.log('handleSelectedFile');

    const csvFile = e.target.files[0];
    console.log('The selected file ', csvFile);
    if (csvFile) {
      const fileName = csvFile.name; //.replaceAll(" ", "_");
      selectFileNameRef.current.value = fileName;

      setSelectedCsvFile(csvFile);
      reportFileNameRef.current = ('Import' + AuthStore.getPatientLabelPlural() + 'From_' + fileName + '_Results').replaceAll(' ', '_');
    }
  };

  const handleSubmitFile = () => {
    console.log('handleSubmitFile');
    if (selectedCsvFile) {
      fileReader.onload = function (event) {
        let csvOutput = event.target.result;
        console.log('File ', csvOutput);
        csvOutput = csvOutput.replaceAll('\r', '');
        csvFileToArray(csvOutput);
      };
      fileReader.readAsText(selectedCsvFile);
    }
  };

  const csvFileToArray = string => {
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string.slice(string.indexOf('\n') + 1).split('\n');

    const array = csvRows.map(i => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    array.forEach((element, index) => {
      element.Number = index + 1;
      element.Ok = null;
      element.Processed = false;
      element.Info = null;
    });

    setStateCsvFileData(array);
    csvFileData.current = array;
    console.log('csvHeader', csvHeader);
    console.log('csvRows', csvRows);
    console.log('array', csvFileData.current);

    if (!csvHeader.includes('FirstName') || !csvHeader.includes('LastName')) {
      console.log('Missing headers');
      setStatePopupTitle('Missing data');
      setStatePopupInfo("There should be at least two fields in the CSV to import. 'FirstName' and 'LastName' should be on separate fields.");
      $('#modalPopup').modal('open');
      return;
    }

    setStateCsvFileIndex(0);
    csvFileIndex.current = 0;
    setStateSelectFileBtnStyle(' nucleus-submit-btn-mini-disabled');
    setStateLoading(true);
    setStateProcesingFile(true);
    csvFileCounterOk.current = 0;
    csvFileCounterError.current = 0;
    setStateStatusDone(false);

    if (csvFileIndex.current < csvFileData.current.length) {
      processPatient();
    }
  };

  const processPatient = () => {
    console.log('ProcessPatient Index', csvFileIndex.current, ' of ', csvFileData.current.length);
    const patient = csvFileData.current[csvFileIndex.current];
    console.log('Patient', patient);

    if (isNotValid(patient.FirstName) && isNotValid(patient.LastName)) {
      onPatientCreatedResult({
        ok: false,
        error: 'Not added: First name and last name are missing',
      });
      return;
    }

    if (isNotValid(patient.FirstName)) {
      onPatientCreatedResult({
        ok: false,
        error: 'Not added: First name is missing',
      });
      return;
    }

    if (isNotValid(patient.LastName)) {
      onPatientCreatedResult({
        ok: false,
        error: 'Not added: Last name is missing',
      });
      return;
    }

    setStateStatusString('Creating ' + AuthStore.getPatientLabel().toLowerCase() + ': ' + patient.FirstName + ' ' + patient.LastName + ' ...');

    let dateOfBirthFormatted = patient.DateOfBirth;
    if (patient.DateOfBirth) {
      if (moment(patient.DateOfBirth, ['MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'], true).isValid()) {
        dateOfBirthFormatted = moment(patient.DateOfBirth).format('YYYY-MM-DD');
        console.log('VALID Date of Birth:', dateOfBirthFormatted);
      } else {
        console.log('INVALID Date of Birth:', patient.DateOfBirth);
        dateOfBirthFormatted = patient.DateOfBirth;
      }
    }

    CarePatientStore.createNewPatient(
      {
        UserID: AuthStore.getUserID(),
        AccountID: AuthStore.getUserAccountID(),
        FirstName: patient.FirstName,
        LastName: patient.LastName,
        DateOfBirth: dateOfBirthFormatted,
        Telephone: patient.Telephone || '',
        Address: patient.Address || '',
        Imported: true,
      },
      onPatientCreatedResult,
    );
  };

  const onPatientCreatedResult = (response, processed = true) => {
    console.log('onPatientCreatedResult', response);

    const processedArray = [...csvFileData.current];
    processedArray[csvFileIndex.current].Ok = response.ok;
    processedArray[csvFileIndex.current].OkString = response.ok ? 'Yes' : 'No';
    processedArray[csvFileIndex.current].Processed = processed;
    processedArray[csvFileIndex.current].Info = response.info || response.error || '';

    if (response.ok) {
      csvFileCounterOk.current = csvFileCounterOk.current + 1;
    } else {
      csvFileCounterError.current = csvFileCounterError.current + 1;
    }

    console.log('Array changed!!!', processedArray);
    setStateCsvFileData(processedArray);
    csvFileData.current = processedArray;
    console.log('Array current changed!!!', csvFileData.current);

    next_();
  };

  const next_ = () => {
    console.log('next_');

    if (csvFileIndex.current + 1 < csvFileData.current.length) {
      setStateCsvFileIndex(stateCsvFileIndex + 1);
      csvFileIndex.current = csvFileIndex.current + 1;
      processPatient();
    } else {
      setStateLoading(false);
      setStateStatusString('');
      setStateSelectFileBtnStyle(' nucleus-submit-btn-mini hide');
      Message.show(AuthStore.getPatientLabelPlural() + ' import done!');
      setStateStatusDone(true);
    }
  };

  const isNotValid = data => {
    console.log('Check if _', data, '_isNotValid');
    return data == null || data == '' || data == undefined;
  };

  const handleCsvFileSelected = () => {
    console.log('handleCsvFileSelected');
    setStateSelectFileError(true);
  };

  const doneImportModal = () => {
    $('#modalImportPatients').modal('close');
    props.onDone && props.onDone();
  };

  const onCloseModalPopup = () => {
    $('#modalPopup').modal('close');
  };
  const DateCell = ({ data }) => {
    if (!data.DateOfBirth || data.DateOfBirth === '') {
      return (
        <td className="" style={{ color: '#979797' }}>
          {''}
        </td>
      );
    }
    if (moment(data.DateOfBirth, ['MM/DD/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD'], true).isValid()) {
      return <td className="">{moment(data.DateOfBirth).format('MM-DD-YYYY')}</td>;
    }
    return (
      <td className="" style={{ display: 'flex', alignItems: 'center' }}>
        <span className="nucleus-table-icon" style={{ color: 'red' }}>
          <i className="Tiny material-icons">warning</i>
          {'Invalid date format'}
        </span>
      </td>
    );
  };
  const renderPatientRows = () => {
    //stateCsvFileData
    console.log('renderPatientRows -->>>>', stateCsvFileData);
    const processedPatientsRows = [];
    if (csvFileData.current) {
      csvFileData.current.forEach((patient, index) => {
        if (patient.Processed) {
          processedPatientsRows.push(
            <tr key={index}>
              <td className="">
                {patient.Ok && (
                  <span className="nucleus-table-icon" style={{ color: 'green' }}>
                    <i className="Tiny material-icons">check</i>
                  </span>
                )}
                {!patient.Ok && (
                  <span className="nucleus-table-icon" style={{ color: 'red' }}>
                    <i className="Tiny material-icons">priority_high</i>
                  </span>
                )}
              </td>
              <td className="">{patient.FirstName}</td>
              <td className="">{patient.LastName}</td>
              <DateCell data={patient} />
              <td className="">{patient.Telephone || ''}</td>
              <td className="">{patient.Address || ''}</td>
              <td className="">{patient.Info}</td>
            </tr>,
          );
        }
      });
    }
    return processedPatientsRows;
  };

  return (
    <div style={{ display: 'inline' }}>
      <div style={{ display: 'block', textAlign: 'start' }}>
        <div id="modalImportPatients" className="modal modalImportPatients ">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3 className={PatientsCss.modalTitle} style={{ margin: 0 }}>
                {' '}
                Import {AuthStore.getPatientLabelPlural()}
              </h3>
              {!stateStatusDone && <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={onCloseImportModal} />}
            </div>
            <div className="row"></div>

            <div className="row no-margin">
              <div className="col s12 no-margin">
                <span className="nucleus-font nucleus-font-14">Select the CSV file.</span>
                <br />
                <span className="nucleus-font nucleus-font-14">Consider the file must have the header row, with no spaces between the header.</span>
              </div>
              <br />
              <br />
              <br />
              <div className="row no-margin">
                <div className="col s2 no-margin">
                  <span className="nucleus-labels">
                    <b>CSV file</b>
                  </span>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    className={'txt-white nucleus-font-small ' + stateSelectFileBtnStyle}
                    onClick={() => {
                      document.getElementById('selectFile').click();
                    }}
                  >
                    Select
                  </a>
                </div>
                <div className="col s6 no-margin">
                  <input
                    id="selectFile"
                    ref={selectFileRef}
                    type="file"
                    data-multiple-caption={'Select'}
                    multiple={false}
                    onChange={handleSelectedFile}
                    accept={'.csv'}
                    style={{ display: 'none' }}
                  ></input>
                  <input
                    ref={selectFileNameRef} //"txtSignFileUrl"
                    type={'url'}
                    className="validate nucleus-textfield"
                    placeholder="Csv file name"
                    disabled={true}
                    onChange={handleCsvFileSelected}
                  />
                  &nbsp;&nbsp;&nbsp;
                  {selectedCsvFile != null && (
                    <a className={'txt-white nucleus-font-small ' + stateSelectFileBtnStyle} onClick={handleSubmitFile}>
                      Submit
                    </a>
                  )}
                  {stateSelectFileError && <span className={'nucleus-form-error center-align '}>Please select csv file.</span>}
                </div>
                <div className="col s4 no-margin">
                  {stateLoading && (
                    <div style={{ margin: 0, padding: 0, marginTop: -10 }}>
                      <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="42" thickness="100" />
                    </div>
                  )}
                </div>
              </div>
              <br />

              <div className={'row no-margin'}>
                <div className="col s12 no-margin">
                  <span className="nucleus-labels">{stateStatusString}</span>
                </div>
              </div>
              <br />
              {/* 
                            <div className="col s9 ">
                                <span className="nucleus-font nucleus-font-medium"> { "this.state.messageReportBody" }</span>
                            </div> 
                            */}
              {stateProcesingFile && (
                <div className={'row no-margin center-align '}>
                  <div className="nucleus-report center-align message-report">
                    <div className={'row nucleus-msg-table-container no-margin  '}>
                      <table className="bordered highlight nucleus-report-table bg-white ">
                        <thead>
                          <tr className="header-biotable-row">
                            <th className="table-col-5 table-radius-top-left">
                              <span className="nucleus-table-header-small"></span>
                            </th>
                            <th className="table-col-20">
                              <span className="nucleus-table-header-small">{AuthStore.getPatientLabel()} First Name</span>
                            </th>
                            <th className="table-col-20">
                              <span className="nucleus-table-header-small">{AuthStore.getPatientLabel()} Last Name</span>
                            </th>
                            <th className="table-col-20 table-radius-top-right">
                              <span className="nucleus-table-header-small">Date of Birth</span>
                            </th>
                            <th className="table-col-15 table-radius-top-right">
                              <span className="nucleus-table-header-small">Telephone</span>
                            </th>
                            <th className="table-col-15 table-radius-top-right">
                              <span className="nucleus-table-header-small">Address</span>
                            </th>
                            <th className="table-col-25 table-radius-top-right">
                              <span className="nucleus-table-header-small">Details</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody>{renderPatientRows()}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
              <br />
              {stateStatusDone && (
                <div className={'row no-margin'}>
                  <div className="col s12 no-margin">
                    <span className="nucleus-labels">{csvFileCounterOk.current + ' ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' imported successfully.'}</span>
                  </div>
                  <div className="col s12 no-margin">
                    <span className="nucleus-labels">{csvFileCounterError.current + ' errors.'}</span>
                  </div>
                  <div className="col s12 no-margin center-align">
                    <a className={'txt-white nucleus-font nucleus-submit-btn'} onClick={doneImportModal}>
                      Close
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="fixed-action-btn">
            {stateStatusDone && (
              <CSVLink headers={csvFileHeadersRef.current} data={csvFileData.current} filename={reportFileNameRef.current + '.csv'} target="_blank">
                <a
                  className={'btn-floating txt-white nucleus-font-small nucleus-submit-btn-small tooltipped'}
                  style={{ width: 98, height: 35, textAlign: 'center', justifyContent: 'center', padding: 0 }}
                  data-position="top"
                  data-delay="50"
                  data-tooltip="Download CSV"
                >
                  <i className="material-icons" style={{ width: 32, marginTop: -3, marginRight: 3 }}>
                    save_alt
                  </i>
                  CSV
                </a>
              </CSVLink>
            )}
          </div>
        </div>

        <div id="modalPopup" className="modal modalPopup">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> {statePopupTitle}</h3>
            <p></p>
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                <br />
                {statePopupInfo}
                <br /> <br />
              </div>
            </div>
            <div className="row ">
              <div className="col s12 right-align ">
                <p></p>
                <a className={'txt-white nucleus-font-small nucleus-submit-btn-small'} onClick={onCloseModalPopup}>
                  Ok
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a className="nucleus-font-family-medium nucleus-link profile-header-btn3" style={{ marginLeft: 36 }} onClick={onOpenImportModal}>
        {'Import ' + AuthStore.getPatientLabelPlural() + ' from CSV'}
      </a>
    </div>
  );
};

export default ImportPatientsFromCSV;
