import { debounce } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { UIModal } from '../../../../../components/UI';

import { useNucleusProviders } from '../../../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import AuthStore from '../../../../../stores/AuthStore';
import message from '../../../../../utils/Message';
import { IProviderAvailabilitySlot, PatientSchedule, ProviderPatient } from '../../providerSchedule.types';
import { createScheduleForProvider, fetchPatientAppointments, fetchScheduleAvailability } from '../../ScheduleSource';
import { AvailableTimeSlots } from '../AvailableTimeSlots';
import { ProviderPatientSearchAutoComplete } from '../SearchAutoComplete';
import { DayPicker } from './components/DayPicker';

const ColumnLeftAligned = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 16px;
  font-family: Barlow;
`;

const Divider = styled.div`
  border: 1px solid #b4c6d3;
  margin-top: 30px;
  margin-bottom: 10px;
`;

const ErrorLabel = styled.div`
  color: #ff0000;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
`;

export const AddNewScheduleModal = ({ closeModal, isOpen, currentDate }: { closeModal: () => void; isOpen: boolean; currentDate: Date }) => {
  const {
    actions,
    dispatch,
    state: { patients: providerPatients },
  } = useNucleusProviders();
  const providerId = AuthStore.getProviderID()!;
  const SEARCH_LIMIT = 20;

  const [selectedTimeSlot, setSelectedTimeSlot] = useState<{
    slot: Moment;
    scheduleAvailabilitySlot: IProviderAvailabilitySlot;
  }>();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const checkIfSelectedDateIsInThePast = useCallback(() => {
    const isPast = moment(selectedTimeSlot?.slot).isBefore(moment(), 'minute');
    if (selectedTimeSlot && isPast) {
      message.show('You cannot schedule an appointment in the past');
      setSelectedTimeSlot(undefined);
    }
  }, [selectedTimeSlot]);
  useEffect(() => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(checkIfSelectedDateIsInThePast, 1000);
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = setInterval(checkIfSelectedDateIsInThePast, 1000);
    }
    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [selectedTimeSlot]);

  const [loading, setLoading] = useState<{
    fetchPatients: boolean;
    fetchScheduleAvailabilities: boolean;
    fetchPatientAppointments: boolean;
    creatingAppointment: boolean;
  }>({
    fetchPatients: false,
    fetchScheduleAvailabilities: false,
    fetchPatientAppointments: false,
    creatingAppointment: false,
  });
  const [scheduleDate, setScheduleDate] = useState<Date>(currentDate);
  const [search, setSearch] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedPatient, setSelectedPatient] = useState<ProviderPatient>();
  const [scheduleAvailabilitiesWithSlots, setScheduleAvailabilitiesWithSlots] = useState<IProviderAvailabilitySlot[]>([]);
  const [patientAppointments, setPatientAppointments] = useState<PatientSchedule[]>([]);

  const addSchedule = debounce(async () => {
    setErrorMessage(undefined);
    if (!selectedPatient) {
      setErrorMessage('Please select a patient');
      return;
    }
    if (!selectedTimeSlot?.scheduleAvailabilitySlot) {
      setErrorMessage('Please select a time slot');
      return;
    }
    const startTime = moment(scheduleDate)
      .hour(selectedTimeSlot?.slot?.hour() || 0)
      .minute(selectedTimeSlot?.slot?.minute() || 0)
      .toDate();
    const isPastDate = moment(startTime).isBefore(moment(), 'minute');
    if (isPastDate) {
      setErrorMessage('You cannot schedule an appointment in the past');
      setSelectedTimeSlot(undefined);
      return;
    }
    const slotDurationInMins = selectedTimeSlot?.scheduleAvailabilitySlot.SlotDuration;
    if (selectedPatient) {
      setLoading(state => {
        return {
          ...state,
          creatingAppointment: true,
        };
      });
      await createScheduleForProvider({
        patientID: selectedPatient.PatientID,
        startTime: startTime,
        accountID: selectedPatient.AccountID,
        providerID: providerId,
        scheduleAvailabilitySlotID: selectedTimeSlot.scheduleAvailabilitySlot.ProviderAvailabilitySlotID,
        endTime: moment(startTime).add(slotDurationInMins, 'minutes').toDate(),
      });
      // fetchScheduleAvailabilities(providerId);
      setSelectedPatient(undefined);
      setScheduleAvailabilitiesWithSlots([]);
      closeModal();
      setLoading(state => {
        return {
          ...state,
          creatingAppointment: false,
        };
      });
    }
  }, 500);

  const onRequestCallFromDateChange = (date: MaterialUiPickersDate, value?: string | null | undefined) => {
    if (date) {
      setScheduleDate(date?.toDate());
      const isPastDate = moment(date?.toDate()).isBefore(moment(), 'minute');
      if (isPastDate) {
        setErrorMessage('You cannot schedule an appointment in the past');
        setSelectedTimeSlot(undefined);
      }
    }
  };

  const checkIfInPast = date => {
    const isPastDate = moment(date).isBefore(moment(), 'minute');
    if (isPastDate) {
      setScheduleDate(new Date());
      setErrorMessage(undefined);
    }
  };

  const getScheduleAvailability = async (providerId: string) => {
    const month = moment(scheduleDate).month() + 1;
    const year = moment(scheduleDate).year();
    setLoading(state => {
      return {
        ...state,
        fetchScheduleAvailabilities: true,
      };
    });
    const data = await fetchScheduleAvailability({
      providerId,
      year,
      month,
      startDay: moment(scheduleDate).startOf('day').toDate(),
      endDate: moment(scheduleDate).endOf('day').toDate(),
    });

    const filteredSelectedDate = data.scheduleAvailabilitiesWithSlots.filter(slot => {
      const slotDate = moment(slot.StartTime);
      return slotDate.isSame(scheduleDate, 'day');
    });
    setScheduleAvailabilitiesWithSlots(filteredSelectedDate);
    setLoading(state => {
      return {
        ...state,
        fetchScheduleAvailabilities: false,
      };
    });
  };

  const getPatientAppointments = async (providerId: string, patientId: string) => {
    const month = moment(scheduleDate).month() + 1;
    const year = moment(scheduleDate).year();
    setLoading(state => {
      return {
        ...state,
        fetchPatientAppointments: true,
      };
    });
    const appointments = await fetchPatientAppointments({
      providerId,
      patientId,
      year,
      month,
      startDay: moment(scheduleDate).startOf('day').toDate(),
      endDate: moment(scheduleDate).endOf('day').toDate(),
    });
    setPatientAppointments(appointments.schedules);
    setLoading(state => {
      return {
        ...state,
        fetchPatientAppointments: false,
      };
    });
  };

  useEffect(() => {
    const isPastDate = moment(currentDate).isBefore(moment(), 'minute');
    if (isPastDate) {
      setScheduleDate(new Date());
    } else {
      setErrorMessage(undefined);
      setScheduleDate(currentDate);
    }
  }, [currentDate]);

  useEffect(() => {
    if (providerId && isOpen) {
      actions.fetchPatientsForProvider(providerId, search, SEARCH_LIMIT);
    }
    return () => {
      setErrorMessage(undefined);
    };
  }, [providerId, search]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedPatient(undefined);
      setScheduleDate(currentDate);
      setSearch('');
      dispatch({
        type: 'set_patients',
        payload: [],
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const handleFocus = () => {
      checkIfInPast(scheduleDate);
    };
    window.addEventListener('focusTab', handleFocus);
    return () => {
      window.removeEventListener('focusTab', handleFocus);
    };
  }, [scheduleDate]);

  useEffect(() => {
    const interval = setInterval(() => {
      checkIfInPast(scheduleDate);
    }, 1000);

    return () => clearInterval(interval);
  }, [scheduleDate]);

  useEffect(() => {
    if (scheduleDate) {
      if (selectedTimeSlot && moment(selectedTimeSlot.slot).isSame(scheduleDate, 'day')) return;
      setSelectedTimeSlot(undefined);
    }
  }, [scheduleDate]);

  useEffect(() => {
    if (providerId && selectedPatient?.PatientID) {
      Promise.all([getScheduleAvailability(providerId), getPatientAppointments(providerId, selectedPatient?.PatientID)]);
    }
  }, [providerId, selectedPatient?.PatientID, scheduleDate]);

  //Clear all the states when the modal is closed
  useEffect(() => {
    if (!isOpen) {
      setSearch('');
      setErrorMessage(undefined);
      setSelectedPatient(undefined);
      setScheduleAvailabilitiesWithSlots([]);
      setPatientAppointments([]);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <UIModal
      close={closeModal}
      isOpen={isOpen}
      size="large"
      title="Schedule a New Appointment"
      actions={[
        {
          label: 'Cancel',
          onClick: () => closeModal(),
          disabled: false,
          buttonVariant: 'secondary',
        },
        {
          label: 'Schedule',
          onClick: () => addSchedule(),
          disabled: !selectedPatient || !selectedTimeSlot || !!errorMessage || loading.creatingAppointment,
          buttonVariant: 'primary',
        },
      ]}
    >
      <ColumnLeftAligned>
        <Note>Patient</Note>
        <ProviderPatientSearchAutoComplete
          onSearch={(value: string) => {
            setSearch(value);
          }}
          showAccount
          results={providerPatients}
          onSelect={(patient: ProviderPatient) => {
            setSelectedPatient(patient);
          }}
        />
      </ColumnLeftAligned>

      <Divider />
      <DayPicker disablePast checkIfInPast={checkIfInPast} onRequestCallFromDateChange={onRequestCallFromDateChange} scheduleDate={scheduleDate} />
      <AvailableTimeSlots
        scheduleAvailabilitiesWithSlots={scheduleAvailabilitiesWithSlots}
        patientAppointments={patientAppointments}
        patient={selectedPatient}
        scheduleDate={scheduleDate}
        loading={loading.fetchScheduleAvailabilities || loading.fetchPatientAppointments}
        setSelectedSlot={setSelectedTimeSlot}
        selectedSlot={selectedTimeSlot}
        onSlotSelect={slot => {
          checkIfSelectedDateIsInThePast();
          setSelectedTimeSlot(slot);
        }}
      />
      <ErrorLabel>{!!errorMessage && errorMessage}</ErrorLabel>
    </UIModal>
  );
};
