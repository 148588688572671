import { EventEmitter } from 'events';
import PatientApi from 'apis/PatientApi';
import QueueApi from 'apis/QueueApi';
import { CallingMethods } from '../components/WebRTCCallView/WebRTCCallView';

export type CareCallProps = {
  patientId?: string;
  PatientID?: string;
  patientFullName?: string;
  ConnectDevice?: boolean;
  DeviceID?: string;
  entityId?: string;
  userName?: string;
  DeviceName: string;
  PatientName: string;
  patientFirstName: string;
  patientLastName: string;
  pendingRequestPatientID?: string;
  pendingRequestPatientName?: string;
  pendingRequestPatientDeviceID?: string;
  pendingRequestPatientDeviceName?: string;
  callingMethod?: CallingMethods;
  callerName?: string;
};

export type DeviceInfo = {
  PatientID: string;
  PatientName: string;
  PatientFirstName: string;
  PatientLastName: string;
  DeviceID: string;
  DeviceName: string;
  isDeviceLegacy: boolean;
  ConnectDevice: boolean;
};

//We need to move this to the queue.types.ts file under regular Queue folder
export type CallRequest = {
  AccountName: string;
  ConnectDevice: boolean;
  DeviceID: string;
  DeviceName: string;
  DeviceOffset: number;
  Escalated: boolean;
  IsAddressed: boolean;
  HandledTime: string;
  Name: string;
  Notes: string;
  NumOfRequests: number;
  PatientFirstName: string;
  PatientID: string;
  PatientLastName: string;
  RequestID: string;
  RequestTime: string;
  Room: string;
  Telephone: string;
};

export enum CommunicationEvent {
  OnGetQueueSize = 'onGetQueueSize',
  OnStartCallToPatient = 'onStartCallToPatient',
  OnStartAudioCallToPatient = 'onStartAudioCallToPatient',
  OnStartVideoCallToPatient = 'onStartVideoCallToPatient',
  OnStartInstantCallToPatient = 'onStartInstantCallToPatient',
  OnStartSilentInstantCallToPatient = 'onStartSilentInstantCallToPatient',
  OnCallStartedReported = 'onCallStartedReported',
  OnCallEndedReported = 'onCallEndedReported',
  OnReportCallEnded = 'onReportCallEnded',
  OnReportCallHandled = 'onReportCallHandled',
  OnReportCallEstablished = 'onReportCallEstablished',
  OnReportCallInProgress = 'onReportCallInProgress',
  OnRefreshIncomingCallBarStatus = 'onRefreshIncomingCallBarStatus',
  OnUpdateCallInProgress = 'onUpdateCallInProgress',
}

class CareCommunicationStore extends EventEmitter {
  private QueueApi: QueueApi;
  private PatientApi: PatientApi;
  private QueueList: CallRequest[];
  private QueueNumber: number;
  public Status: {
    CONNECTED: string;
    FAILED: string;
    CANCELED: string;
    NOT_ANSWERED: string;
    BUSY: string;
    DECLINED: string;
    DISCONNECTED: string;
    DO_NOT_DISTURB: string;
  };

  constructor() {
    super();

    this.QueueApi = new QueueApi(this);
    this.PatientApi = new PatientApi(this);
    try {
      this.QueueList = localStorage.QueueList ? JSON.parse(localStorage.QueueList) : [];
    } catch (e) {
      console.log('Error parsing QueueList from localStorage', e);
      this.QueueList = [];
    }
    this.QueueNumber = localStorage.QueueNumber;

    this.Status = {
      CONNECTED: 'Connected',
      FAILED: 'Failed',
      CANCELED: 'Canceled',
      NOT_ANSWERED: 'Not Answered',
      BUSY: 'Busy',
      DECLINED: 'Declined',
      DISCONNECTED: 'Disconnected',
      DO_NOT_DISTURB: 'DND',
    };
  }

  getQueue() {
    return this.QueueList;
  }

  getPendingRequest() {
    let request: CallRequest | undefined = undefined;
    if (typeof this.QueueList === 'object' && this.QueueList.length > 0) {
      request = this.QueueList[0];
    }
    this.emit(CommunicationEvent.OnGetQueueSize, { request: request });
  }

  getQueueNumber() {
    return this.QueueNumber;
  }

  setQueueNumber(value) {
    this.QueueNumber = value;
  }

  /* = = = = = = = = = = = = = = = = = = = = = */
  callPatient(requestInfo) {
    console.log('callPatient ', requestInfo);

    this.emit(CommunicationEvent.OnStartCallToPatient, requestInfo);
  }

  audioCallPatient(info) {
    console.log('audioCallPatient ', info);

    this.emit(CommunicationEvent.OnStartAudioCallToPatient, info);
  }

  videoCallPatient(info) {
    console.log('videoCallPatient ', info);

    this.emit(CommunicationEvent.OnStartVideoCallToPatient, info);
  }

  instantCallPatient(info) {
    console.log('instantCallPatient ', info);

    this.emit(CommunicationEvent.OnStartInstantCallToPatient, info);
  }

  silentInstantCallPatient(info) {
    console.log('silentInstantCallPatient ', info);

    this.emit(CommunicationEvent.OnStartSilentInstantCallToPatient, info);
  }

  async reportCallStarted({
    CallLogID,
    PatientID,
    DeviceID,
    UserID,
    RequestID,
    CallType,
    entityId,
  }: {
    CallLogID: string;
    PatientID?: string;
    DeviceID: string;
    UserID: string;
    RequestID: string;
    CallType: string;
    entityId?: string;
  }) {
    console.log('reportCallStarted ', CallLogID, PatientID, UserID, RequestID, CallType, DeviceID, '.');
    const entityType = PatientID ? 'Patient' : 'User';
    const data = await this.PatientApi.ReportCareCallStarted({ CallLogID, PatientID, UserID, RequestID, CallType, DeviceID, entityId, entityType });
    console.log('Nucleus Response ReportCareCallStarted', data);

    this.emit(CommunicationEvent.OnCallStartedReported, data);
  }

  reportMultiCallStarted({
    CallLogID,
    UserID,
    ClassID,
    PatientIDs,
    DeviceIDs,
    CallType,
    entityIds,
  }: {
    CallLogID: string;
    UserID: string;
    ClassID?: string;
    PatientIDs: string;
    DeviceIDs: string;
    CallType: string;
    entityIds?: string;
  }) {
    console.log('reportMultiCallStarted ', CallLogID, UserID, ClassID, PatientIDs, DeviceIDs, CallType, '.');
    const entityType = PatientIDs ? 'Patient' : 'User';
    this.PatientApi.ReportMultiCallStarted({ CallLogID, UserID, ClassID, PatientIDs, DeviceIDs, CallType, entityIds, entityType }, rsp => {
      console.log('Nucleus Response ReportCareCallStarted', rsp);

      this.emit(CommunicationEvent.OnCallStartedReported, rsp);
    });
    this.emit(CommunicationEvent.OnCallStartedReported, { ok: true });
  }

  async reportCallEndedStatus({
    CallLogID,
    WasAnswered = false,
    PatientID,
    AverageAvailableKbps,
    Status,
    entityId,
  }: {
    CallLogID: string;
    WasAnswered?: boolean;
    PatientID: string;
    AverageAvailableKbps?: number;
    Status: string;
    entityId?: string;
  }) {
    console.warn('reportCallEnded ', CallLogID, WasAnswered, PatientID, AverageAvailableKbps, Status, '!');

    let Endpoint;

    const { CONNECTED, FAILED, CANCELED, NOT_ANSWERED, BUSY, DO_NOT_DISTURB, DECLINED, DISCONNECTED } = this.Status;

    const entityType = PatientID ? 'Patient' : 'User';
    const payload = {
      CallLogID,
      WasAnswered,
      AverageAvailableKbps,
      Endpoint,
      entityId,
      entityType,
    };

    switch (Status) {
      case CONNECTED:
        Endpoint = 'ReportCallEndedSuccessfully';
        break;
      case FAILED:
        Endpoint = 'ReportCallFailed';
        break;
      case CANCELED:
        Endpoint = 'ReportCallCanceled';
        break;
      case NOT_ANSWERED:
        Endpoint = 'ReportCallNotAnswered';
        break;
      case BUSY:
        Endpoint = 'ReportCallBusy';
        break;
      case DO_NOT_DISTURB:
        Endpoint = 'ReportCallDoNotDisturb';
        break;
      case DECLINED:
        Endpoint = 'ReportCallDeclined';
        break;
      case DISCONNECTED:
        Endpoint = 'ReportCallDisconnected';
        break;
    }

    if (Endpoint == 'ReportCallEndedSuccessfully') {
      const rsp = await this.PatientApi.ReportCareCallEnded(payload);
      this.emit(CommunicationEvent.OnCallEndedReported, { patientID: PatientID, entityId });
      this.emit(CommunicationEvent.OnReportCallEnded, rsp);
    } else if (Endpoint == 'ReportCallFailed') {
      this.PatientApi.ReportFailedCall(payload, rsp => {
        console.warn('Nucleus ReportFailedCall Response', rsp);

        this.emit(CommunicationEvent.OnCallEndedReported, rsp);
        this.emit(CommunicationEvent.OnReportCallEnded, rsp);
      });
    } else if (Endpoint == 'ReportCallCanceled') {
      this.PatientApi.ReportCanceledCall(payload, rsp => {
        console.warn('Nucleus ReportCanceledCall Response', rsp);

        this.emit(CommunicationEvent.OnCallEndedReported, { patientID: PatientID, entityId });
        this.emit(CommunicationEvent.OnReportCallEnded, rsp);
      });
    } else if (Endpoint == 'ReportCallNotAnswered') {
      this.PatientApi.ReportNotAnsweredCall(payload, rsp => {
        console.warn('Nucleus ReportNotAnsweredCall Response', rsp);

        this.emit('onCallEndedReported', { patientID: PatientID, entityId });
        this.emit('onReportCallEnded', rsp);
      });
    } else if (Endpoint == 'ReportCallBusy') {
      this.PatientApi.ReportBusyCall(payload, rsp => {
        console.warn('Nucleus ReportBusyCall Response', rsp);

        this.emit('onCallEndedReported', { patientID: PatientID, entityId });
        this.emit('onReportCallEnded', rsp);
      });
    } else if (Endpoint == 'ReportCallDoNotDisturb') {
      this.PatientApi.ReportCallDoNotDisturb(payload, rsp => {
        console.warn('Nucleus ReportCallDoNotDisturb Response', rsp);

        this.emit('onCallEndedReported', { patientID: PatientID, entityId });
        this.emit('onReportCallEnded', rsp);
      });
    } else if (Endpoint == 'ReportCallDeclined') {
      this.PatientApi.ReportDeclinedCall(payload, rsp => {
        console.warn('Nucleus ReportDeclinedCall Response', rsp);

        this.emit('onCallEndedReported', { patientID: PatientID, entityId });
        this.emit('onReportCallEnded', rsp);
      });
    } else if (Endpoint == 'ReportCallDisconnected') {
      this.PatientApi.ReportDisconnectedCall(payload, rsp => {
        console.warn('Nucleus ReportDisconnectedCall Response', rsp);

        this.emit('onCallEndedReported', { patientID: PatientID, entityId });
        this.emit('onReportCallEnded', rsp);
      });
    } else {
      this.PatientApi.ReportCareCallEndStatus(payload, rsp => {
        console.warn('Nucleus Response', rsp);
        console.error('Check 10');

        this.emit('onCallEndedReported', { patientID: PatientID, entityId });
        this.emit('onReportCallEnded', rsp);
      });
    }
  }

  async reportMultiCallEndedStatus({
    CallLogID,
    WasAnswered = false,
    PatientID,
    AverageAvailableKbps,
    Status,
    entityId,
  }: {
    CallLogID: string;
    WasAnswered?: boolean;
    PatientID: string;
    AverageAvailableKbps?: number;
    Status: string;
    entityId?: string;
  }) {
    console.log('reportMultiCallEndedStatus ', CallLogID, WasAnswered, PatientID, AverageAvailableKbps, Status, '!');

    const { CONNECTED, FAILED, CANCELED, NOT_ANSWERED, BUSY, DO_NOT_DISTURB, DECLINED, DISCONNECTED } = this.Status;
    const entityType = PatientID ? 'Patient' : 'User';

    const data = {
      CallLogID,
      entityId,
      entityType,
    };

    switch (Status) {
      case CONNECTED:
        {
          const rsp = await this.PatientApi.ReportCareCallEnded(data);
          this.emit('onReportCallEnded', rsp);
        }
        break;
      case FAILED:
        this.PatientApi.ReportFailedCall(data, rsp => {
          console.log('Nucleus ReportFailedCall Response', rsp);
          //this.emit("onCallEndedReported", { patientID: PatientID });
          this.emit('onReportCallEnded', rsp);
        });
        //Endpoint = "ReportCallFailed";
        break;
      case CANCELED:
        this.PatientApi.ReportCanceledCall(data, rsp => {
          console.log('Nucleus ReportCanceledCall Response', rsp);
          //this.emit("onCallEndedReported", { patientID: PatientID });
          this.emit('onReportCallEnded', rsp);
        });
        //Endpoint = "ReportCallCanceled";
        break;
      case NOT_ANSWERED:
        this.PatientApi.ReportNotAnsweredCall(data, rsp => {
          console.log('Nucleus ReportNotAnsweredCall Response', rsp);
          //this.emit("onCallEndedReported", { patientID: PatientID });
          this.emit('onReportCallEnded', rsp);
        });
        //Endpoint = "ReportCallNotAnswered";
        break;
      case BUSY:
        this.PatientApi.ReportBusyCall(data, rsp => {
          console.log('Nucleus ReportBusyCall Response', rsp);
          //this.emit("onCallEndedReported", { patientID: PatientID });
          this.emit('onReportCallEnded', rsp);
        });
        //Endpoint = "ReportCallBusy";
        break;
      case DO_NOT_DISTURB:
        this.PatientApi.ReportCallDoNotDisturb(data, rsp => {
          console.log('Nucleus ReportCallDoNotDisturb Response', rsp);
          //this.emit("onCallEndedReported", { patientID: PatientID });
          this.emit('onReportCallEnded', rsp);
        });
        //Endpoint = "ReportCallDoNotDisturb";
        break;
      case DECLINED:
        this.PatientApi.ReportDeclinedCall(data, rsp => {
          console.log('Nucleus ReportDeclinedCall Response', rsp);
          //this.emit("onCallEndedReported", { patientID: PatientID });
          this.emit('onReportCallEnded', rsp);
        });
        //Endpoint = "ReportCallDeclined";
        break;
      case DISCONNECTED:
        this.PatientApi.ReportDisconnectedCall(data, rsp => {
          console.log('Nucleus ReportDisconnectedCall Response', rsp);
          //this.emit("onCallEndedReported", { patientID: PatientID });
          this.emit('onReportCallEnded', rsp);
        });
        //Endpoint = "ReportCallDisconnected";
        break;
      default:
        console.error('Check 15');
        break;
    }
  }

  async reportCallEnded({
    CallLogID,
    WasAnswered,
    PatientID,
    AverageAvailableKbps,
    entityId,
  }: {
    CallLogID: string;
    WasAnswered: boolean;
    PatientID?: string;
    AverageAvailableKbps?: number;
    entityId?: string;
  }) {
    console.log('reportCallEnded ', CallLogID, WasAnswered, PatientID, AverageAvailableKbps, '!');

    const entityType = PatientID ? 'Patient' : 'User';
    const data = await this.PatientApi.ReportCareCallEnded({ CallLogID, entityId, entityType });
    console.log('Nucleus Response', data);

    this.emit(CommunicationEvent.OnCallEndedReported, { patientID: PatientID });
    this.emit(CommunicationEvent.OnReportCallEnded, data);
  }

  async reportMultiCallEnded({ CallLogID, WasAnswered, PatientID, AverageAvailableKbps }) {
    console.log('reportMultiCallEnded ', CallLogID, WasAnswered, PatientID, AverageAvailableKbps, '!');

    const rsp = await this.PatientApi.ReportCareCallEnded({ CallLogID, WasAnswered, AverageAvailableKbps });
    console.log('Nucleus Response', rsp);

    this.emit(CommunicationEvent.OnReportCallEnded, rsp);
    this.emit(CommunicationEvent.OnReportCallEnded, { ok: true });
  }

  reportCallHandled({ RequestID, UserID }) {
    console.log('reportCallHandled ', RequestID, UserID);

    this.QueueApi.ReportCallRequestHandled({ RequestID, UserID }, rsp => {
      this.emit(CommunicationEvent.OnReportCallHandled, { ok: rsp.ok, requestID: RequestID });
    });
  }

  reportCallEstablished({ RequestID, UserID }: { RequestID: string; UserID: string; entityId?: string }) {
    console.log('reportCallEstablished ', RequestID, UserID);

    this.QueueApi.ReportCallRequestHandled({ RequestID, UserID }, rsp => {
      this.emit(CommunicationEvent.OnReportCallEstablished, { ok: rsp.ok, requestID: RequestID });
    });
  }

  reportCallInProgress({ CallLogID }: { CallLogID: string }) {
    console.log('reportCallInProgress ', CallLogID);

    this.QueueApi.ReportCallInProgress({ CallLogID }, rsp => {
      console.log('CareCommunicationStore.reportCallInProgress rsp = ', rsp);
      this.emit(CommunicationEvent.OnReportCallInProgress, rsp);
    });
  }

  refreshIncomingCallBarStatus() {
    console.log('refreshIncomingCallBarStatus ');

    this.emit(CommunicationEvent.OnRefreshIncomingCallBarStatus);
  }

  updateCallInProgress(callInProgress) {
    console.log('updateCallInProgress ', callInProgress);

    this.emit(CommunicationEvent.OnUpdateCallInProgress, callInProgress);
  }

  saveToLocalStorage() {
    console.log('saveToLocalStorage');

    localStorage.QueueList = JSON.stringify(this.QueueList);
    localStorage.QueueNumber = this.QueueNumber;
  }
}

const careCommunicationStore = new CareCommunicationStore();

export default careCommunicationStore;
