import { useSelector } from 'react-redux';

export function useClassSessionPluralLabelSelector() {
  return useSelector(({ accountClassSessionConfig }) => {
    if (!accountClassSessionConfig) return '';
    const { config } = accountClassSessionConfig;
    if (!config) return '';
    const { TabNameLabelPlural = '' } = config;
    return TabNameLabelPlural;
  });
}

export function useClassSessionAccessSelector() {
  return useSelector(({ accountClassSessionConfig }) => {
    if (!accountClassSessionConfig) return false;
    const { config } = accountClassSessionConfig;
    if (!config) return false;
    const { Enabled = false } = config;
    return Enabled;
  });
}

export function getAccountClassSessionConfigSelector() {
  return useSelector(({ accountClassSessionConfig }) => {
    return accountClassSessionConfig;
  });
}

export function useHasSalesforceIntegration() {
  return useSelector(({ accountIntegrationConfig }) => {
    return accountIntegrationConfig.config?.salesforce ?? false;
  });
}
