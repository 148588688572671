import signalingData from '@nucleus-care/nucleuscare-connect-signaling/package.json';
import webrtcData from '@nucleus-care/nucleuscare-connect-webrtc/package.json';
import React from 'react';
import packageJson from '../../../package.json';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import Message from '../../utils/Message';

import SettingsUser from './SettingsUser';
import SettingsUserEdit from './SettingsUserEdit';
import UserGuides from './UserGuides';

class Settings extends React.Component<
  {
    history?: any;
  },
  {
    appVersion: string;
    currentMode: string;
    linkModeLabel: string;
    userID: any;
    userFirstName: string;
    userLastName: string;
    userFullName: string;
    userEmail: string;
    userCreated: string;
    userTelephone: string;
    userAddress: string;
    userIsAdmin: boolean;
    userIsOnDuty: boolean;
    userAllPatientsAccess?: boolean;
    userImageUrl?: string;
    companyShowCallButton?: boolean;
    companyShowCheckInButton?: boolean;
    companyShowEmergencyButton?: boolean;
  }
> {
  constructor(props) {
    super(props);

    this.state = {
      appVersion: packageJson?.version || '2.74.01', // Version when this project was migrated to the new azure resource (standalone)
      currentMode: 'Info',
      linkModeLabel: 'Edit',
      userID: null,
      userFirstName: '',
      userLastName: '',
      userFullName: '',
      userEmail: '',
      userCreated: '',
      userTelephone: '',
      userAddress: '',
      userIsAdmin: false,
      userIsOnDuty: false,
    };

    this.onUserGetDataResult = this.onUserGetDataResult.bind(this);
    this.onUserUpdateDataResult = this.onUserUpdateDataResult.bind(this);
    this.handleProfileMode = this.handleProfileMode.bind(this);
  }

  componentDidMount() {
    //Register Listener
    UserStore.on('onUserGetData', this.onUserGetDataResult);
    AuthStore.on('onUserUpdateData', this.onUserUpdateDataResult);

    //Get the User Data from WS
    UserStore.getUserData({
      UserID: AuthStore.getUserID(),
    });
  }

  componentWillUnmount() {
    //Remove Listener
    UserStore.removeListener('onUserGetData', this.onUserGetDataResult);
    AuthStore.removeListener('onUserUpdateData', this.onUserUpdateDataResult);
  }

  onUserGetDataResult(success) {
    console.log('onUserGetDataResult', success);
    console.log(success);

    this.setState({
      userID: UserStore.getID(),
      userFirstName: UserStore.getFirstName(),
      userLastName: UserStore.getLastName(),
      userFullName: UserStore.getFullName(),
      userEmail: UserStore.getEmail(),
      userCreated: UserStore.getCreatedDate(),
      userTelephone: UserStore.getTelephone(),
      userAddress: UserStore.getAddress(),
      userIsAdmin: UserStore.getIsAdmin(),
      userIsOnDuty: UserStore.getIsOnDuty(),
      userAllPatientsAccess: UserStore.getAllPatientsAccess(),
      userImageUrl: UserStore.getImageUrl(),
      companyShowCallButton: UserStore.getShowCallButton(),
      companyShowCheckInButton: UserStore.getShowCheckInButton(),
      companyShowEmergencyButton: UserStore.getShowEmergencyButton(),
    });
  }

  onUserUpdateDataResult(response) {
    console.log('onUserUpdateDataResult', response);
    console.log(response);

    if (response.ok) {
      if (response.updateUserData) {
        Message.show('Profile successfully updated');
        this.setState({
          currentMode: 'Info',
          linkModeLabel: 'Edit',
        });
      }
      if (response.updateUserPassword && response.passwordUpdated) {
        Message.show('Password updated');
        this.setState({
          currentMode: 'Info',
          linkModeLabel: 'Edit',
        });
      } else {
        Message.show('Error: ' + response.passwordUpdateError);
      }

      this.onUserGetDataResult(response.ok);
    } else {
      if (!response.okCredentials) {
        Message.show('Your user password is not correct.');
      } else {
        Message.show('There was a problem updating the user profile');
      }
    }
  }

  handleProfileMode() {
    if (this.state.currentMode == 'Info') {
      this.setState({
        currentMode: 'Edit',
        linkModeLabel: 'Cancel',
      });
    } else {
      this.setState({
        currentMode: 'Info',
        linkModeLabel: 'Edit',
      });
    }
  }

  render() {
    /*
     *
     */
    let websiteVersion = window.location.hostname + '.';
    websiteVersion = ' ' + websiteVersion.substr(0, websiteVersion.indexOf('.'));
    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page-medium">
          <p className="nucleus-page-title">Settings &nbsp;&nbsp;&nbsp;</p>
          <h3 className="nucleus-page-subtitle">User Profile </h3>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a className="nucleus-font nucleus-font-small nucleus-link" onClick={this.handleProfileMode}>
            {' '}
            {this.state.linkModeLabel}
          </a>
          <br />
          <br />
          <SettingsUser
            currentMode={this.state.currentMode}
            userFullName={this.state.userFullName}
            userEmail={this.state.userEmail}
            userTelephone={this.state.userTelephone}
            userAddress={this.state.userAddress}
            userImageUrl={this.state.userImageUrl}
            userIsAdmin={this.state.userIsAdmin}
            userIsOnDuty={this.state.userIsOnDuty}
            userAllPatientsAccess={this.state.userAllPatientsAccess}
            companyShowCallButton={this.state.companyShowCallButton}
            companyShowCheckInButton={this.state.companyShowCheckInButton}
            companyShowEmergencyButton={this.state.companyShowEmergencyButton}
            userAccountID={UserStore.getAccountID()}
          />
          {this.state.currentMode != 'Edit' && <UserGuides />}
          {this.state.currentMode == 'Edit' && (
            <SettingsUserEdit
              currentMode={this.state.currentMode}
              userFirstName={this.state.userFirstName}
              userLastName={this.state.userLastName}
              userEmail={this.state.userEmail}
              userTelephone={this.state.userTelephone}
              userAddress={this.state.userAddress}
              userIsOnDuty={this.state.userIsOnDuty}
              userImageUrl={this.state.userImageUrl}
            />
          )}
          <p className="nucleus-font-book" style={{ color: 'darkgray' }}>
            <span>{'v ' + this.state.appVersion + websiteVersion}</span>
            <br />
            <span>{'s ' + signalingData.version}</span>
            <br />
            <span>{'w ' + webrtcData.version}</span>
          </p>
        </div>
      </div>
    );
  }
}

export default Settings;
