import React from 'react';

import CaregiverStore from '../../stores/CaregiverStore';

class CaregiverMessageItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caregiverID: this.props.CaregiverID,
      caregiverName: this.props.CaregiverName,
      caregiverMessage: this.props.Message,
      caregiverNumber: this.props.CaregiverTelephone,
      messageStatus: 'Sending...',
    };
    //console.log(this.state.patientID);
    this.onSendCaregiverSMSAction = this.onSendCaregiverSMSAction.bind(this);
  }

  componentDidMount() {
    //console.log(this.state.patientID);
    CaregiverStore.on('onSendCaregiverSMS', this.onSendCaregiverSMSAction);

    CaregiverStore.sendSMS({
      CareGiverID: this.state.caregiverID,
      Message: this.state.caregiverMessage,
    });
  }

  componentWillUnmount() {
    CaregiverStore.removeListener('onSendCaregiverSMS', this.onSendCaregiverSMSAction);
  }

  onSendCaregiverSMSAction(response) {
    console.log('onSendCaregiverSMSAction');
    console.log(response);

    if (response.caregiverID == this.state.caregiverID) {
      console.log('onSendCaregiverSMSAction The choosen one');
      if (response.ok) {
        this.setState({
          messageStatus: 'Sent!',
        });
      } else {
        this.setState({
          messageStatus: 'Error',
        });
      }
    }
  }

  render() {
    return (
      <div class="row">
        <div class="col s8 nucleus-row">{this.state.caregiverName}</div>
        <div class="col s4 nucleus-row">{this.state.messageStatus}</div>
      </div>
    );
  }
}

export default CaregiverMessageItemRow;
