import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const useReactRouterProps = (Custom: React.ComponentClass, props: any): React.ComponentElement<any, any> => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Custom
      {...{
        params,
        navigate,
        history: {
          // Hack to support push from react-router-dom v5
          push: navigate,
          replace: (path: string) => {
            navigate(path, { replace: true });
          },
          navigate,
        },
        location,
        // Hack to support match from react-router-dom v5
        match: {
          params,
        },
        ...props,
      }}
    />
  );
};
