import moment, { Moment } from 'moment';
import { useEffect } from 'react';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';
import { IProviderAvailabilitySlot, PatientSchedule, ProviderPatient } from '../providerSchedule.types';

const AvailableTimeSlotsContainer = styled.div`
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  min-height: 200px;
  max-height: 300px;
  overflow-y: scroll;
  justify-content: center;
  padding: 0px;
  font-family: Barlow;
`;

const SlotGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  padding: 0 10px; /* This will add spacing to the left and right */
  justify-content: center; /* This centers the grid items in the container if it's not full width */
  align-items: center;
`;

const SlotContainer = styled.div<{
  $backgroundColor?: boolean;
  $disabled?: boolean;
}>`
  height: 50px;
  border-radius: 5px;
  background-color: ${props => props.$backgroundColor || 'transparent'};
  border: 1px solid #0a313f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.$disabled ? 0.5 : 1)};
  ${props =>
    !props.$disabled &&
    `
    &:hover {
      background-color: #FFCA20;
    }
  `}
`;

const AvailableTimeSlotsTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  align-self: flex-start;
  font-family: Barlow;
  margin-left: 16px;
`;

const renderTimeSlot = ({
  patientAppointments,
  slot,
  isFull,
  isSelected,
  onSlotClick,
  isPast,
}: {
  patientAppointments: PatientSchedule[];
  slot: Moment;
  isSelected: boolean;
  isFull: boolean;
  isPast: boolean;
  onSlotClick: (slot: Moment) => void;
}) => {
  const currentSlot = slot.clone();
  const isScheduled = patientAppointments.some(appointment => {
    const appointmentStart = moment(appointment.StartTime);
    const appointmentEnd = moment(appointment.EndTime);
    return currentSlot.isBetween(appointmentStart, appointmentEnd, 'minute', '[)');
  });

  const slotLabel = currentSlot.format('hh:mm A');
  let backgroundColor = 'transparent';
  if (isScheduled) {
    backgroundColor = '#0092FF';
  } else if (isFull) {
    backgroundColor = '#848484';
  } else if (isSelected) {
    backgroundColor = '#FFCA20';
  }
  const disabled = isScheduled || isFull || isPast;
  return (
    <SlotContainer
      key={currentSlot.format('HHmm')}
      onClick={() => {
        if (!disabled) {
          onSlotClick(currentSlot);
        }
      }}
      $disabled={disabled}
      $backgroundColor={backgroundColor}
    >
      <span>{slotLabel}</span>
    </SlotContainer>
  );
};

const sortByStartTime = (a: IProviderAvailabilitySlot, b: IProviderAvailabilitySlot) => {
  if (a.StartTime > b.StartTime) {
    return 1;
  }
  if (a.StartTime < b.StartTime) {
    return -1;
  }
  return 0;
};

export const AvailableTimeSlots = ({
  scheduleDate,
  onSlotSelect,
  selectedSlot,
  setSelectedSlot,
  scheduleAvailabilitiesWithSlots,
  patientAppointments,
  loading,
  patient,
}: {
  patient?: ProviderPatient;
  scheduleDate: Date;
  selectedSlot?: {
    slot: Moment;
    scheduleAvailabilitySlot: IProviderAvailabilitySlot;
  };
  setSelectedSlot: (data: { slot: Moment; scheduleAvailabilitySlot: IProviderAvailabilitySlot }) => void;
  loading: boolean;
  onSlotSelect: (data: { slot: Moment; scheduleAvailabilitySlot: IProviderAvailabilitySlot }) => void;
  scheduleAvailabilitiesWithSlots: IProviderAvailabilitySlot[];
  patientAppointments: PatientSchedule[];
}) => {
  useEffect(() => {
    if (selectedSlot) {
      onSlotSelect(selectedSlot);
    }
  }, [selectedSlot]);

  if (!scheduleDate) {
    return null;
  }

  //If there is no patient selected we should not show anything
  if (!patient) {
    return null;
  }

  return (
    <AvailableTimeSlotsContainer>
      {scheduleDate && loading && <SpinnerCircular />}

      {scheduleAvailabilitiesWithSlots.length === 0 && !loading && <p>There are no available time slots for this day</p>}
      {!!scheduleAvailabilitiesWithSlots.length && (
        <AvailableTimeSlotsTitle>Available appointment slots for {moment(scheduleDate).format('MMMM DD, YYYY')}</AvailableTimeSlotsTitle>
      )}

      <SlotGridContainer>
        {scheduleAvailabilitiesWithSlots.sort(sortByStartTime).map(scheduleAvailabilitySlot => {
          const slots: JSX.Element[] = [];

          const currentSlot = moment(scheduleAvailabilitySlot.StartTime);
          const isPast = currentSlot.isBefore(moment(), 'minute');
          const slotElement = renderTimeSlot({
            patientAppointments,
            slot: currentSlot,
            isSelected: selectedSlot?.slot?.isSame(currentSlot, 'minute') || false,
            isFull: scheduleAvailabilitySlot.Full,
            isPast,
            onSlotClick: slot => {
              setSelectedSlot({
                slot,
                scheduleAvailabilitySlot,
              });
            },
          });
          slots.push(slotElement);

          return slots;
        })}
      </SlotGridContainer>
    </AvailableTimeSlotsContainer>
  );
};
