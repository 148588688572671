//@ts-nocheck
import MomentUtils from '@date-io/moment';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import Spinner from 'react-activity/dist/Spinner';
import AuthStore from '../../stores/AuthStore';
import CarePatientStore, { GetScheduledPatientMessagesResponse } from '../../stores/CarePatientStore';
import MessagesStore from '../../stores/MessagesStore';

import Message from '../../utils/Message';

import CarePatientScheduledMessageItemRow from './CarePatientScheduledMessageItemRow';

class PatientMessages extends React.Component<
  {
    patientID: string;
    patientMessagesEnabled: boolean;
    enableVoiceMessage: boolean;
    enableBiometrics: boolean;
    tableVisibility: boolean;
  },
  {
    accountID: string;
    patientID: string;

    patientName: string;

    enableVoiceMessage: boolean;
    enableBiometrics: boolean;
    patientDevices: number;

    careScheduledMessagesArray: any[];

    loading: boolean;

    arrayDeliveryDesc: any[];
    arrayDeliveryAsc: any[];

    arrayRecurringDesc: any[];
    arrayRecurringAsc: any[];

    arrayCategoryDesc: any[];
    arrayCategoryAsc: any[];

    sortDeliveryDesc: string;
    sortDeliveryAsc: string;

    sortRecurringDesc: string;
    sortRecurringAsc: string;

    sortCategoryDesc: string;
    sortCategoryAsc: string;

    // Messages
    messageModalMode: string;
    messageSaveNewBtn: string;
    messageSaveNewBtnTitle: string;
    messageUpdateBtn: string;

    messageDateTime: any;
    messageEndDateTime: any;

    messageEditable: string;
    messageEditableRecurring: string;

    messageModalModeShow: string;
    messageModalOptionLabel: string;
    messageModeEdit: string;
    messageModeDelete: string;
    messageModalOptionColor: string;
    messageModalNotEditable: string;
    messageModalNotEditableText: string;

    deleteMessageBtnStyle: string;
    editMessageBtnStyle: string;

    newMessageSingleChoice: boolean;
    newMessageMultipleSelection: boolean;
    newMessageCategoryID: any;
    responseAlertCustomVisibility: string;
    savingMessage: boolean;
    savingMessageText: string;
  }
> {
  private checkMessageLinkOpen: React.RefObject<HTMLInputElement> = React.createRef();
  private checkRepeat: React.RefObject<HTMLInputElement> = React.createRef();
  private txtRepeatNumber: React.RefObject<HTMLInputElement> = React.createRef();
  private selectMessageRecurring: React.RefObject<HTMLSelectElement> = React.createRef();
  private selectMessageType: React.RefObject<HTMLSelectElement> = React.createRef();
  private selectMessageTemplate: React.RefObject<HTMLSelectElement> = React.createRef();
  private selectBiometricMessageType: React.RefObject<HTMLSelectElement> = React.createRef();
  private checkSunday: React.RefObject<HTMLInputElement> = React.createRef();
  private checkMonday: React.RefObject<HTMLInputElement> = React.createRef();
  private checkTuesday: React.RefObject<HTMLInputElement> = React.createRef();
  private checkWednesday: React.RefObject<HTMLInputElement> = React.createRef();
  private checkThursday: React.RefObject<HTMLInputElement> = React.createRef();
  private checkFriday: React.RefObject<HTMLInputElement> = React.createRef();
  private checkSaturday: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSingleOption1: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSingleOption2: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSingleOption3: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSingleOption4: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSingleOption5: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSingleOption6: React.RefObject<HTMLInputElement> = React.createRef();
  private txtMultiOption1: React.RefObject<HTMLInputElement> = React.createRef();
  private txtMultiOption2: React.RefObject<HTMLInputElement> = React.createRef();
  private txtMultiOption3: React.RefObject<HTMLInputElement> = React.createRef();
  private txtMultiOption4: React.RefObject<HTMLInputElement> = React.createRef();
  private txtMultiOption5: React.RefObject<HTMLInputElement> = React.createRef();
  private txtMultiOption6: React.RefObject<HTMLInputElement> = React.createRef();
  private textMessage: React.RefObject<HTMLTextAreaElement> = React.createRef();
  private txtNoResponseTimeout: React.RefObject<HTMLInputElement> = React.createRef();
  private txtExpirationTimeout: React.RefObject<HTMLInputElement> = React.createRef();
  private checkPersistentState: React.RefObject<HTMLInputElement> = React.createRef();
  private selectMessageCategory: React.RefObject<HTMLSelectElement> = React.createRef();
  private txtLinkMessageButton: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSignMessageButton: React.RefObject<HTMLInputElement> = React.createRef();
  private txtLinkMessage: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSignMessage: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption1of2: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption2of2: React.RefObject<HTMLInputElement> = React.createRef();

  private checkOption1of5: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption2of5: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption3of5: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption4of5: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption5of5: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption1of6: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption2of6: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption3of6: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption4of6: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption5of6: React.RefObject<HTMLInputElement> = React.createRef();
  private checkOption6of6: React.RefObject<HTMLInputElement> = React.createRef();
  private txtLinkMessageUrl: React.RefObject<HTMLInputElement> = React.createRef();
  private txtSignFileUrl: React.RefObject<HTMLInputElement> = React.createRef();

  private datetimeFormat: string;
  private timeFormat: string;
  private timeOptions: any;
  private controlTimezone: number;

  constructor(props) {
    super(props);
    this.state = {
      accountID: AuthStore.getUserAccountID(),
      patientID: this.props.patientID,

      patientName: 'Patient',

      enableVoiceMessage: this.props.enableVoiceMessage,
      enableBiometrics: this.props.enableBiometrics,
      patientDevices: 0,

      careScheduledMessagesArray: [],

      loading: false,

      arrayDeliveryDesc: [],
      arrayDeliveryAsc: [],

      arrayRecurringDesc: [],
      arrayRecurringAsc: [],

      arrayCategoryDesc: [],
      arrayCategoryAsc: [],

      sortDeliveryDesc: ' nucleus-icon-active',
      sortDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      // Messages
      messageModalMode: 'New',
      messageSaveNewBtn: ' ',
      messageSaveNewBtnTitle: 'Save',
      messageUpdateBtn: ' hide',

      messageDateTime: null,
      messageEndDateTime: null,

      messageEditable: '',
      messageEditableRecurring: '',

      messageModalModeShow: ' hide',
      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',
      messageModalNotEditable: ' hide',
      messageModalNotEditableText: 'You cannot edit this message because the message has been sent.',

      deleteMessageBtnStyle: 'nucleus-submit-btn',
      editMessageBtnStyle: ' nucleus-submit-btn ',

      newMessageSingleChoice: false,
      newMessageMultipleSelection: false,
      newMessageCategoryID: null,
      newMessageTemplate: null,

      messageCategories: MessagesStore.getMessageCategories(),
      messageCategoryDefault: null,
      messageTemplates: [],
      messageBiometricTypes: [],
      messageTemplateDefault: null,

      messageTemplateEditable: '',

      singleChoiceVisibility: ' hide',
      multipleOptionVisibility: ' hide',

      linkMessageVisibility: ' hide',
      signMessageVisibility: ' hide',

      messageLink: false, // Not used
      messageLinkUrl: '', // Not used
      messageLinkOpen: false,
      messageLinkButton: '', // Not used

      signMessage: false,
      signMessageFileUrl: null,
      signMessageButton: '',

      selectedPdfFile: null,
      selectedPdfFileName: '',
      selectedPdfFilePreviewURL: '',

      messageTemplateVisibility: ' hide',

      responseAlertCustomVisibility: ' hide',
      responseAlertFacesVisibility: ' hide',
      responseAlertYesNoVisibility: ' hide',

      responseAlertYesLabel: 'Yes',
      responseAlertNoLabel: 'No',

      messageOptionsError: ' hide',
      messageOptionsMaxError: ' hide',

      messageTemplateDetailsVisibiity: ' hide',

      messageBodyChars: 0,
      messageBodyError: ' hide',
      messageBodyErrorText: '',
      messageBodyLengthError: ' hide',
      messageBodyLengthErrorChars: '60',

      messageLinkError: ' hide',
      messageButtonError: ' hide',

      messageSignError: ' hide',
      messageSignButtonError: ' hide',

      messageO1Chars: 0,
      messageO2Chars: 0,
      messageO3Chars: 0,
      messageO4Chars: 0,
      messageO5Chars: 0,
      messageO6Chars: 0,

      messageTypeError: ' hide',
      //messageCategoryError : ' hide',
      messageTemplateError: ' hide',

      messageDeliveryDateTimeLabel: 'Deliver On',
      messageDeliveryImmediate: true,

      messageDeliveryDateError: ' ',
      messageEndDateError: ' ',
      messageBroadcast: true,

      messageDeliverTo: ' ',
      messageDeliveryAt: ' ',

      canSendAllPatients: '',
      messagePersistentState: false,

      messagePatientsList: {
        //'T' : 'https://st2.depositphotos.com/3101765/8372/v/450/depositphotos_83729944-stock-illustration-border-collie-animal-dog-watercolor.jpg' : '13'
      },
      messageAutocompletePatientsList: {},

      selectedMessagePatientsIDs: null,
      //selectPatientsVisibility : ' hide',

      messageRepeat: false,
      messageRepeatStyle: '',
      messageRepeatEveryError: ' hide',
      messageRepeatDaysError: ' hide',
      messageEscalationStyle: '',

      stopRecurringMessageVisibility: ' hide',
      stopRecurringMessageStyle: ' nucleus-submit-btn-small ',

      repeatConfigVisibility: ' hide',
      repeatWeekVisibility: ' hide',

      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',

      confirmMessageText: '',
      confirmMessageTextLine2: '',
      confirmMessageTextLine3: '',

      sortDescending: ' ',
      sortAscending: ' hide',

      selectedPatientMessageID: null,
      selectedMessageID: null,

      regularMessageMode: ' ',
      biometricMessageMode: ' hide',

      selectedBiometricTypeID: '',
      selectedBiometricTypeName: '',
      selectedBiometricTypeText: '',
      selectedBiometricTypeInputText: '',

      savingMessage: false,
      savingMessageText: '',
    };

    console.log('PatientMessages.js PROPS ', this.props);

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };

    //this.messageDateTimeRef = React.createRef();
    //this.messageEndDateTimeRef = React.createRef();

    this.handleEnableVoiceMessage = this.handleEnableVoiceMessage.bind(this);
    this.onEnableVoiceMessageUpdatedAction = this.onEnableVoiceMessageUpdatedAction.bind(this);

    this.onGetScheduledPatientMessagesAction = this.onGetScheduledPatientMessagesAction.bind(this);

    this.handleSortDeliveryDesc = this.handleSortDeliveryDesc.bind(this);
    this.handleSortDeliveryAsc = this.handleSortDeliveryAsc.bind(this);

    this.handleSortRecurringDesc = this.handleSortRecurringDesc.bind(this);
    this.handleSortRecurringAsc = this.handleSortRecurringAsc.bind(this);

    this.handleSortCategoryDesc = this.handleSortCategoryDesc.bind(this);
    this.handleSortCategoryAsc = this.handleSortCategoryAsc.bind(this);

    this.onPatientGetDataAction = this.onPatientGetDataAction.bind(this);
    this.onPatientGetDevicesAction = this.onPatientGetDevicesAction.bind(this);
    /*

        this.buttonsInfoLoaded = -1;
        */
    this.onRefreshMessageCategoriesAction = this.onRefreshMessageCategoriesAction.bind(this);
    this.onGetMessageTemplatesAction = this.onGetMessageTemplatesAction.bind(this);
    this.onGetBiometricMessageTypesAction = this.onGetBiometricMessageTypesAction.bind(this);

    this.handleNewMessageModal = this.handleNewMessageModal.bind(this);
    this.handleCloseNewMessageModal = this.handleCloseNewMessageModal.bind(this);
    this.handleMessageModalMode = this.handleMessageModalMode.bind(this);

    this.setMessageDefaultCategory = this.setMessageDefaultCategory.bind(this);
    //this.handleSelectMessageCategory = this.handleSelectMessageCategory.bind(this);
    this.handleMessageText = this.handleMessageText.bind(this);

    this.handleSelectMessageType = this.handleSelectMessageType.bind(this);
    this.handleSelectBiometricMessageType = this.handleSelectBiometricMessageType.bind(this);

    this.handleSelectMessageTemplate = this.handleSelectMessageTemplate.bind(this);

    this.handleResetOptionsValidationError = this.handleResetOptionsValidationError.bind(this);

    this.handleMessagePersistentState = this.handleMessagePersistentState.bind(this);

    this.handleMessageLinkOpen = this.handleMessageLinkOpen.bind(this);
    this.handleMessageLinkText = this.handleMessageLinkText.bind(this);
    this.handleMessageButtonText = this.handleMessageButtonText.bind(this);

    this.handleSelectedFile = this.handleSelectedFile.bind(this);
    this.handleSelectedPdfFilePreview = this.handleSelectedPdfFilePreview.bind(this);
    this.handleSelectedPdfFileView = this.handleSelectedPdfFileView.bind(this);

    this.handleMessageSignText = this.handleMessageSignText.bind(this);
    this.handleMessageSignButtonText = this.handleMessageSignButtonText.bind(this);

    this.handleDeliveryImmediate = this.handleDeliveryImmediate.bind(this);
    this.handleDeliveryScheduled = this.handleDeliveryScheduled.bind(this);

    this.handleMessageRepeat = this.handleMessageRepeat.bind(this);
    this.handleStopRecurringMessageModal = this.handleStopRecurringMessageModal.bind(this);
    this.handleCancelStopRecurringMessageModal = this.handleCancelStopRecurringMessageModal.bind(this);
    this.handleStopRecurringMessage = this.handleStopRecurringMessage.bind(this);

    this.handleTxtRepeatEvery = this.handleTxtRepeatEvery.bind(this);
    this.handleSelectMessageRecurring = this.handleSelectMessageRecurring.bind(this);
    this.handleDayCheckChange = this.handleDayCheckChange.bind(this);
    this.handleMessageEnds = this.handleMessageEnds.bind(this);
    this.handleRepeatNeverEnds = this.handleRepeatNeverEnds.bind(this);
    this.handleRepeatEndDate = this.handleRepeatEndDate.bind(this);

    this.handleResumeSaveMessage = this.handleResumeSaveMessage.bind(this);
    this.handleCancelResumeMessageModal = this.handleCancelResumeMessageModal.bind(this);
    this.handleSaveMessage = this.handleSaveMessage.bind(this);

    this.onSaveNewMessageByUserAction = this.onSaveNewMessageByUserAction.bind(this);
    this.onSaveNewMessageFileAction = this.onSaveNewMessageFileAction.bind(this);

    this.onGetPatientMessageDetailsAction = this.onGetPatientMessageDetailsAction.bind(this);
    this.onOpenNotEditableMessageAction = this.onOpenNotEditableMessageAction.bind(this);
    this.handleCloseNotEditableModal = this.handleCloseNotEditableModal.bind(this);
    this.onHandleDeleteMessageAction = this.onHandleDeleteMessageAction.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);

    this.onHandleNotDeleteMessageAction = this.onHandleNotDeleteMessageAction.bind(this);
    this.handleCloseNotDeleteModal = this.handleCloseNotDeleteModal.bind(this);

    this.handleCancelResumeUpdateMessageModal = this.handleCancelResumeUpdateMessageModal.bind(this);

    this.handleResumeUpdateMessage = this.handleResumeUpdateMessage.bind(this);
    this.handleUpdateMessage = this.handleUpdateMessage.bind(this);
    this.onUpdateScheduledMessageAction = this.onUpdateScheduledMessageAction.bind(this);

    this.handleSubmitDeleteMessage = this.handleSubmitDeleteMessage.bind(this);
    this.onDeletePatientMessageAction = this.onDeletePatientMessageAction.bind(this);

    this.onStopRecurringMessageAction = this.onStopRecurringMessageAction.bind(this);

    this.onRefreshScheduledMessagesTabAction = this.onRefreshScheduledMessagesTabAction.bind(this);
    this.onHandleNewPatientMessageModalAction = this.onHandleNewPatientMessageModalAction.bind(this);

    this.validateUrlLink = this.validateUrlLink.bind(this);

    this.controlTimezone = 0;
  }

  componentDidMount() {
    CarePatientStore.on('onEnableVoiceMessageUpdated', this.onEnableVoiceMessageUpdatedAction);

    CarePatientStore.on('onGetScheduledPatientMessages', this.onGetScheduledPatientMessagesAction);

    CarePatientStore.on('onPatientGetData', this.onPatientGetDataAction);
    CarePatientStore.on('onGetPatientDevices', this.onPatientGetDevicesAction);

    MessagesStore.on('onRefreshMessageCategories', this.onRefreshMessageCategoriesAction);
    MessagesStore.on('onGetMessageTemplates', this.onGetMessageTemplatesAction);
    MessagesStore.on('onGetBiometricMessageTypes', this.onGetBiometricMessageTypesAction);

    MessagesStore.on('onSaveNewMessageByUser', this.onSaveNewMessageByUserAction);
    MessagesStore.on('onSaveNewMessageFile', this.onSaveNewMessageFileAction);

    MessagesStore.on('onGetPatientMessageDetails', this.onGetPatientMessageDetailsAction);

    MessagesStore.on('onOpenNotEditableMessage', this.onOpenNotEditableMessageAction);
    MessagesStore.on('onHandleDeleteMessage', this.onHandleDeleteMessageAction);
    MessagesStore.on('onHandleNotDeleteMessage', this.onHandleNotDeleteMessageAction);

    MessagesStore.on('onUpdateScheduledMessage', this.onUpdateScheduledMessageAction);

    MessagesStore.on('onDeletePatientMessage', this.onDeletePatientMessageAction);

    MessagesStore.on('onStopRecurringMessage', this.onStopRecurringMessageAction);

    CarePatientStore.on('onRefreshScheduledMessagesTab', this.onRefreshScheduledMessagesTabAction);
    CarePatientStore.on('onHandleNewPatientMessageModal', this.onHandleNewPatientMessageModalAction);

    this.setState({
      loading: true,
    });

    CarePatientStore.getScheduledPatientMessages({
      PatientID: this.props.patientID,
    });

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      this.datetimeFormat = 'MM/DD/YYYY hh:mm A';
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      this.datetimeFormat = 'MM/DD/YYYY HH:mm';
      this.timeOptions = { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' };
    }

    this.setMessageDefaultCategory('Generic');

    MessagesStore.getMessageSingleChoiceTemplates();
    MessagesStore.getMessageBiometricsTemplates({
      AccountID: AuthStore.getUserAccountID(),
    });

    $('.modalPatientProfile').modal();

    const currentDate = new Date();
    console.log('DATE:', currentDate);
    const currentTimezone = currentDate.getTimezoneOffset() / 60;
    console.log('TIMEZONE:', currentTimezone);
    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    this.controlTimezone = currentTimezone;
    console.log('CONTROL TIMEZONE:', this.controlTimezone);
  }

  componentWillUnmount() {
    CarePatientStore.removeListener('onEnableVoiceMessageUpdated', this.onEnableVoiceMessageUpdatedAction);

    CarePatientStore.removeListener('onGetScheduledPatientMessages', this.onGetScheduledPatientMessagesAction);

    CarePatientStore.removeListener('onPatientGetData', this.onPatientGetDataAction);
    CarePatientStore.removeListener('onGetPatientDevices', this.onPatientGetDevicesAction);

    MessagesStore.removeListener('onRefreshMessageCategories', this.onRefreshMessageCategoriesAction);

    MessagesStore.removeListener('onGetMessageTemplates', this.onGetMessageTemplatesAction);
    MessagesStore.removeListener('onGetBiometricMessageTypes', this.onGetBiometricMessageTypesAction);

    MessagesStore.removeListener('onSaveNewMessageByUser', this.onSaveNewMessageByUserAction);
    MessagesStore.removeListener('onSaveNewMessageFile', this.onSaveNewMessageFileAction);

    MessagesStore.removeListener('onGetPatientMessageDetails', this.onGetPatientMessageDetailsAction);
    MessagesStore.removeListener('onOpenNotEditableMessage', this.onOpenNotEditableMessageAction);
    MessagesStore.removeListener('onHandleDeleteMessage', this.onHandleDeleteMessageAction);
    MessagesStore.removeListener('onHandleNotDeleteMessage', this.onHandleNotDeleteMessageAction);

    MessagesStore.removeListener('onUpdateScheduledMessage', this.onUpdateScheduledMessageAction);
    MessagesStore.removeListener('onDeletePatientMessage', this.onDeletePatientMessageAction);

    MessagesStore.removeListener('onStopRecurringMessage', this.onStopRecurringMessageAction);

    CarePatientStore.removeListener('onRefreshScheduledMessagesTab', this.onRefreshScheduledMessagesTabAction);
    CarePatientStore.removeListener('onHandleNewPatientMessageModal', this.onHandleNewPatientMessageModalAction);
  }

  componentWillReceiveProps() {}

  componentWillUpdate() {}

  componentDidUpdate() {
    //console.log("PatientMessages.js componentDidUpdate");
    // if (this.props.enableBiometrics && this.props.enableBiometrics.toString() == "true"){
    //     this.loadBiometrics();
    // }
  }

  handleRowClicked() {
    // GeneralStore.setClientTab(1);
    // GeneralStore.setPageBeforePatient("patients");
    // window.location.assign("#/patientProfile/" + this.props.PatientID + "/null" );
  }

  setMessageDefaultCategory(categoryTag) {
    if (!(this.state.messageCategories.length > 0)) {
      MessagesStore.refreshMessageCategries();
    } else {
      for (let i = 0; i < this.state.messageCategories.length; i++) {
        if (this.state.messageCategories[i].Category == categoryTag) {
          this.setState({
            messageCategoryDefault: this.state.messageCategories[i].ID,
            newMessageCategoryID: this.state.messageCategories[i].ID,
          });
          console.log('DEFAULT CATEGORY', this.state.messageCategories[i].Category, this.state.messageCategories[i].ID);
          break;
        }
      }
    }
  }
  handleEnableVoiceMessage(e) {
    const enabled = e.target.checked;
    console.log(enabled);

    CarePatientStore.updateEnableVoiceMessage({
      PatientID: this.props.patientID,
      EnableVoiceMessage: enabled,
    });
  }

  onEnableVoiceMessageUpdatedAction(response) {
    console.log('onEnableVoiceMessageUpdatedAction', response);

    if (response.ok) {
      this.setState({
        enableVoiceMessage: response.enabled,
      });
      if (response.enabled) {
        Message.show('Voice Message enabled.');
      } else {
        Message.show('Voice Message disabled.');
      }
    } else {
      Message.show('Error updating Voice Message, please try again.');
    }
  }

  onPatientGetDataAction(response) {
    //console.log("patientMessage:onPatientGetDataAction", response);

    this.setState({
      enableVoiceMessage: response.EnableVoiceMessage,
      patientName: response.FirstName + ' ' + response.LastName,
      enableBiometrics: response.EnableBiometrics,
    });
  }

  onPatientGetDevicesAction(response) {
    if (response.ok && response.Devices) {
      this.setState({
        patientDevices: response.Devices.length,
      });
    }
  }

  onRefreshScheduledMessagesTabAction() {
    console.log('Refreshing');
    this.setState({
      loading: true,
    });
  }

  onHandleNewPatientMessageModalAction() {
    this.handleNewMessageModal();
  }

  onGetScheduledPatientMessagesAction(response: GetScheduledPatientMessagesResponse) {
    console.log('onGetScheduledPatientMessagesAction');
    console.log(response);

    this.setState({
      loading: false,
    });

    if (response.ok) {
      this.setState({
        careScheduledMessagesArray: [],
      });

      let sorteredArray1 = [].concat(response.Messages);
      let sorteredArray2 = [].concat(response.Messages);

      let sorteredArray3 = [].concat(response.Messages);
      let sorteredArray4 = [].concat(response.Messages);

      // let sorteredArray5 = [].concat(response.Messages);
      // let sorteredArray6 = [].concat(response.Messages);

      sorteredArray1 = sorteredArray1.sort((a, b) => new Date(b.DeliveryTime) - new Date(a.DeliveryTime) || new Date(b.CreatedDate) - new Date(a.CreatedDate));
      sorteredArray2 = sorteredArray2.sort((a, b) => new Date(a.DeliveryTime) - new Date(b.DeliveryTime) || new Date(a.CreatedDate) - new Date(b.CreatedDate));

      sorteredArray3 = sorteredArray3.sort((a, b) => {
        const textA = a.RecurringText.toLowerCase();
        const textB = b.RecurringText.toLowerCase();
        if (textB < textA)
          //sort string ascending
          return -1;
        if (textB > textA) return 1;
        return 0;
      });
      sorteredArray4 = sorteredArray4.sort((a, b) => {
        const textA = a.RecurringText.toLowerCase();
        const textB = b.RecurringText.toLowerCase();
        if (textA < textB)
          //sort string ascending
          return -1;
        if (textA > textB) return 1;
        return 0;
      });

      this.setState({
        careScheduledMessagesArray: sorteredArray2,
        arrayDeliveryDesc: sorteredArray1,
        arrayDeliveryAsc: sorteredArray2,

        arrayRecurringDesc: sorteredArray3,
        arrayRecurringAsc: sorteredArray4,

        // arrayCategoryDesc : sorteredArray5,
        // arrayCategoryAsc : sorteredArray6
      });

      // console.log("ARRAY 1", sorteredArray1);
      // console.log("ARRAY 2", sorteredArray2);
      // console.log("ARRAY 3", sorteredArray3);
      // console.log("ARRAY 4", sorteredArray4);
      // console.log("ARRAY 5", sorteredArray5);
      // console.log("ARRAY 6", sorteredArray6);
    } else {
      Message.show('There was an error getting the ' + AuthStore.getPatientLabel().toLowerCase() + ' messages, please refresh.');
    }
  }

  handleSortDeliveryDesc() {
    console.log('handleSortDeliveryDesc');
    this.state.careScheduledMessagesArray = [];
    this.setState({
      sortDeliveryDesc: ' nucleus-icon-inactive hide ',
      sortDeliveryAsc: ' nucleus-icon-active ',

      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careScheduledMessagesArray: this.state.arrayDeliveryDesc,
    });
  }

  handleSortDeliveryAsc() {
    console.log('handleSortDeliveryAsc');
    this.state.careScheduledMessagesArray = [];
    this.setState({
      sortDeliveryDesc: ' nucleus-icon-active ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide ',

      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careScheduledMessagesArray: this.state.arrayDeliveryAsc,
    });
  }

  handleSortRecurringDesc() {
    console.log('handleSortRecurringDesc');
    this.state.careScheduledMessagesArray = [];
    this.setState({
      sortDeliveryDesc: ' nucleus-icon-inactive ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide',

      sortRecurringDesc: ' nucleus-icon-inactive hide ',
      sortRecurringAsc: ' nucleus-icon-active ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careScheduledMessagesArray: this.state.arrayRecurringDesc,
    });
  }

  handleSortRecurringAsc() {
    console.log('handleSortRecurringAsc');
    this.state.careScheduledMessagesArray = [];
    this.setState({
      sortDeliveryDesc: ' nucleus-icon-inactive ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide',

      sortRecurringDesc: ' nucleus-icon-active ',
      sortRecurringAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careScheduledMessagesArray: this.state.arrayRecurringAsc,
    });
  }

  handleSortCategoryDesc() {
    console.log('handleSortCategoryDesc');
    this.state.careScheduledMessagesArray = [];
    this.setState({
      sortDeliveryDesc: ' nucleus-icon-inactive ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide',

      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-inactive hide ',
      sortCategoryAsc: ' nucleus-icon-active ',

      careScheduledMessagesArray: this.state.arrayCategoryDesc,
    });
  }

  handleSortCategoryAsc() {
    console.log('handleSortCategoryAsc');
    this.state.careScheduledMessagesArray = [];
    this.setState({
      sortDeliveryDesc: ' nucleus-icon-inactive ',
      sortDeliveryAsc: ' nucleus-icon-inactive hide',

      sortRecurringDesc: ' nucleus-icon-inactive ',
      sortRecurringAsc: ' nucleus-icon-inactive hide ',

      sortCategoryDesc: ' nucleus-icon-active ',
      sortCategoryAsc: ' nucleus-icon-inactive hide ',

      careScheduledMessagesArray: this.state.arrayCategoryAsc,
    });
  }

  onMessageDateTimeChange = (date, value) => {
    console.log('onMessageDateTimeChange', date, value);
    this.setState({
      messageDateTime: value,
      messageDeliveryDateError: ' hide',
    });
  };

  onMessageEndDateTimeChange = (date, value) => {
    console.log('onMessageEndDateTimeChange', date, value);
    this.setState({
      messageEndDateTime: value,
      messageEndDateError: ' hide',
    });
  };

  onRefreshMessageCategoriesAction(response) {
    console.log('onRefreshMessageCategoriesAction', response);
    if (response.ok) {
      this.setState({
        messageCategories: response.Categories,
      });

      if (this.state.messageCategoryDefault == null) {
        for (let i = 0; i < response.Categories.length; i++) {
          if (response.Categories[i].Category == 'Generic') {
            this.setState({
              messageCategoryDefault: response.Categories[i].ID,
            });
            console.log('DEFAULT CATEGORY 2', response.Categories[i].Category, response.Categories[i].ID);
            break;
          }
        }
      }
    }
  }

  onGetMessageTemplatesAction(response) {
    console.log('onGetMessageTemplatesAction', response);

    if (response.ok) {
      this.setState({
        messageTemplates: response.Templates,
      });

      if (this.state.messageTemplateDefault == null) {
        for (let i = 0; i < response.Templates.length; i++) {
          if (response.Templates[i].Name == 'Custom Text') {
            this.setState({
              messageTemplateDefault: response.Templates[i].ID,
            });
            console.log('DEFAULT TEMPLATE', response.Templates[i].Name, response.Templates[i].ID);
            break;
          }
        }
      }
    }
  }

  onGetBiometricMessageTypesAction(response) {
    console.log('onGetBiometricMessageTypesAction', response);

    if (response.ok) {
      this.state.messageBiometricTypes = [];

      if (response.Types && response.Types.length > 0) {
        this.setState({
          messageBiometricTypes: response.Types,
          selectedBiometricTypeID: response.Types[0].ID,
          selectedBiometricTypeName: response.Types[0].Type,
          selectedBiometricTypeText: response.Types[0].MessageText,
          selectedBiometricTypeInputText: response.Types[0].InputTextFields,
        });
      }
      console.log('SELECTED BIOMETRIC TYPE', response.Types[0].ID, response.Types[0].Type);
    }
  }

  handleNewMessageModal() {
    console.log('handleNewMessageModal Uh');
    this.setMessageDefaultCategory('Generic');
    MessagesStore.getMessageBiometricsTemplates({
      AccountID: AuthStore.getUserAccountID(),
    });

    $('#modalNewMessage').modal('open');

    console.log('Message categories are: ', this.state.messageCategories);

    this.selectMessageType.current.value = '1';

    this.selectMessageTemplate.current.value = '';

    if (this.state.messageTemplateDefault != null) {
      this.selectMessageTemplate.current.value = this.state.messageTemplateDefault;
    }

    this.checkMessageLinkOpen.current.checked = false;

    this.checkRepeat.current.checked = false;

    this.txtRepeatNumber.current.value = '1';
    this.selectMessageRecurring.current.value = 'Day';

    let currentTime = new Date();
    currentTime = currentTime.getTime() + 5 * 60000;
    currentTime = new Date(currentTime);

    this.setState({
      messageModalMode: 'New',
      messageSaveNewBtn: ' ',
      messageSaveNewBtnTitle: 'Save',
      messageUpdateBtn: ' hide',

      messageEditable: '',
      messageEditableRecurring: '',

      messageModalModeShow: ' hide',
      messageModalOptionLabel: 'Delete',
      messageModeEdit: '',
      messageModeDelete: 'hide',
      messageModalOptionColor: 'txt-red',
      messageModalNotEditable: ' hide',

      deleteMessageBtnStyle: 'nucleus-submit-btn',
      editMessageBtnStyle: ' nucleus-submit-btn ',

      messageDateTime: currentTime,
      messageEndDateTime: currentTime,

      newMessageSingleChoice: false,
      newMessageMultipleSelection: false,
      //newMessageCategoryID : this.state.messageCategoryDefault || "",
      newMessageTemplate: this.selectMessageTemplate.current.value,

      messageTemplateEditable: '',

      singleChoiceVisibility: ' hide',
      multipleOptionVisibility: ' hide',
      linkMessageVisibility: ' hide',
      signMessageVisibility: ' hide',

      messageLink: false,
      messageLinkUrl: '',
      messageLinkOpen: false,
      messageLinkButton: '',

      signMessage: false,
      signMessageFileUrl: null,
      signMessageButton: '',

      selectedPdfFile: null,
      selectedPdfFileName: '',
      selectedPdfFilePreviewURL: '',

      messageTemplateVisibility: 'hide',

      responseAlertCustomVisibility: ' hide',
      responseAlertFacesVisibility: ' hide',
      responseAlertYesNoVisibility: ' hide',

      messageTemplateDetailsVisibiity: ' hide',

      messageOptionsError: ' hide',
      messageOptionsMaxError: ' hide',

      messageBodyChars: 0,
      messageBodyError: ' hide',
      messageBodyLengthError: ' hide',

      messageLinkError: ' hide',
      messageButtonError: ' hide',

      messageSignError: ' hide',
      messageSignButtonError: ' hide',

      messageO1Chars: 0,
      messageO2Chars: 0,
      messageO3Chars: 0,
      messageO4Chars: 0,
      messageO5Chars: 0,
      messageO6Chars: 0,

      messageTypeError: ' hide',
      //messageCategoryError : ' hide',
      messageTemplateError: ' hide',

      messageDeliveryDateTimeLabel: 'Deliver On',
      messageDeliveryImmediate: true,
      //     messageBroadcast : true,

      //     messageDeliverTo : " All " + AuthStore.getPatientLabelPlural(),
      //     messageDeliveryAt : " ",

      canSendAllPatients: '',

      messagePersistentState: false,

      messageDeliveryDateError: ' hide',
      messageEndDateError: ' hide',

      selectedMessagePatientsIDs: null,
      //selectPatientsVisibility : ' hide',

      messageRepeat: false,
      messageRepeatStyle: 'disabled',
      messageRepeatEveryError: ' hide',
      messageRepeatDaysError: ' hide',
      messageEscalationStyle: '',

      repeatConfigVisibility: ' hide',
      repeatWeekVisibility: ' hide',

      stopRecurringMessageVisibility: ' hide',
      stopRecurringMessageStyle: ' nucleus-submit-btn-small ',

      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',

      regularMessageMode: ' ',
      biometricMessageMode: ' hide',

      updateMessageMode: 'message',
      savingMessage: false,
      savingMessageText: '',

      messageBodyChars: 0,
    });

    this.checkSunday.current.checked = false;
    this.checkMonday.current.checked = false;
    this.checkTuesday.current.checked = false;
    this.checkWednesday.current.checked = false;
    this.checkThursday.current.checked = false;
    this.checkFriday.current.checked = false;
    this.checkSaturday.current.checked = false;

    this.txtSingleOption1.current.value = '';
    this.txtSingleOption2.current.value = '';
    this.txtSingleOption3.current.value = '';
    this.txtSingleOption4.current.value = '';
    this.txtSingleOption5.current.value = '';
    this.txtSingleOption6.current.value = '';

    this.txtMultiOption1.current.value = '';
    this.txtMultiOption2.current.value = '';
    this.txtMultiOption3.current.value = '';
    this.txtMultiOption4.current.value = '';
    this.txtMultiOption5.current.value = '';
    this.txtMultiOption6.current.value = '';

    this.textMessage.current.value = '';
    this.txtNoResponseTimeout.current.value = 0;
    this.txtExpirationTimeout.current.value = 1;

    this.checkPersistentState.current.checked = false;
  }

  handleCloseNewMessageModal() {
    $('#modalNewMessage').modal('close');
    this.setState({
      messagePersistentState: false,
    });
  }

  handleMessageModalMode() {
    console.log('handleMessageModalMode');

    if (this.state.messageModalOptionLabel == 'Delete') {
      this.setState({
        messageModalOptionLabel: 'Cancel',
        messageModeEdit: 'hide',
        messageModeDelete: '',
        messageModalOptionColor: '',
      });
    } else {
      this.setState({
        messageModalOptionLabel: 'Delete',
        messageModeEdit: '',
        messageModeDelete: 'hide',
        messageModalOptionColor: 'txt-red',
      });
    }
  }

  // handleSelectMessageCategory(){

  //     console.log("handleSelectMessageCategory");

  // }

  handleMessageText() {
    this.setState({
      messageBodyError: ' hide',
      messageBodyLengthError: ' hide',
      messageBodyChars: this.textMessage.current.value.length,
    });
  }

  handleSelectMessageType(e) {
    // Select the Message Type
    /* 
            Values:Type
            "1" : Plain Text
            "2" : Single Choice
            "3" : Multiple Selection 
            "4" : Browser Link (AuthStore.isLinkMessageEnabled())
            "5" : Biometrics (this.state.enableBiometrics)
            "6" : Signature
        */
    let regularMessageMode = ' ';
    let biometricMessageMode = ' hide';

    if (e.target.value == 5) {
      // Message type is "Biometrics"
      // Need to update the messageCategory

      console.warn("It's category Biometric");
      let biometricMessageText = this.state.selectedBiometricTypeText;
      biometricMessageText = biometricMessageText.replace('(mg/dL)', '');
      biometricMessageText = biometricMessageText.replace('(Lbs)', '');
      this.textMessage.current.value = biometricMessageText;

      this.selectBiometricMessageType.current.value = this.state.selectedBiometricTypeID;

      regularMessageMode = ' hide';
      biometricMessageMode = ' ';

      this.setMessageDefaultCategory('Biometrics');
    } else {
      console.warn("It's category No Biometric");
      this.textMessage.current.value = '';
      regularMessageMode = ' ';
      biometricMessageMode = ' hide';

      this.setMessageDefaultCategory('Generic');
    }

    this.setState({
      //messageCategoryError : ' hide',
      regularMessageMode: regularMessageMode,
      biometricMessageMode: biometricMessageMode,
      messageBodyChars: this.textMessage.current.value.length,

      messageOptionsError: ' hide',
      messageOptionsMaxError: ' hide',

      //messageRepeatStyle : '',
      messageTypeError: ' hide',

      canSendAllPatients: '',
      //messageBroadcast : true,

      messagePersistentState: false,
    });

    console.log('handleSelectMessageType');
    console.log('messagesCategories', this.state.messageCategories);
    console.log('=======>');
    this.checkPersistentState.current.checked = false;

    if (this.selectMessageType.current.value == '1' || this.selectMessageType.current.value == '5') {
      // PLAIN TEXT | Biometrics
      // PLAIN TEXT
      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' hide',

        messageTemplateVisibility: ' hide',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        newMessageTemplate: null,
        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',
      });
    } else if (this.selectMessageType.current.value == '2') {
      // SINGLE CHOICE
      this.setState({
        newMessageSingleChoice: true,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' ',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' hide',

        messageTemplateVisibility: ' ',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' ',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        //selectedMessagePatientsIDs : null,
        //selectPatientsVisibility : ' hide',

        messageTemplateEditable: '',
        messageBodyLengthError: ' hide',

        messageO1Chars: 0,
        messageO2Chars: 0,
        messageO3Chars: 0,
        messageO4Chars: 0,
        messageO5Chars: 0,
        messageO6Chars: 0,
      });

      this.selectMessageTemplate.current.value = '';

      if (this.state.messageTemplateDefault != null) {
        this.selectMessageTemplate.current.value = this.state.messageTemplateDefault;
      }

      this.txtSingleOption1.current.value = '';
      this.txtSingleOption2.current.value = '';
      this.txtSingleOption3.current.value = '';
      this.txtSingleOption4.current.value = '';
      this.txtSingleOption5.current.value = '';
      this.txtSingleOption6.current.value = '';
    } else if (this.selectMessageType.current.value == '3') {
      // MULTIPLE CHOICE
      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: true,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' ',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' hide',

        messageTemplateVisibility: ' ',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' ',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        //selectedMessagePatientsIDs : null,
        //selectPatientsVisibility : ' hide',

        newMessageTemplate: null,

        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',

        messageO1Chars: 0,
        messageO2Chars: 0,
        messageO3Chars: 0,
        messageO4Chars: 0,
        messageO5Chars: 0,
        messageO6Chars: 0,
      });

      this.selectMessageTemplate.current.value = '';

      if (this.state.messageTemplateDefault != null) {
        this.selectMessageTemplate.current.value = this.state.messageTemplateDefault;
      }

      this.txtMultiOption1.current.value = '';
      this.txtMultiOption2.current.value = '';
      this.txtMultiOption3.current.value = '';
      this.txtMultiOption4.current.value = '';
      this.txtMultiOption5.current.value = '';
      this.txtMultiOption6.current.value = '';
    } else if (this.selectMessageType.current.value == '4') {
      // BROWSER LINK
      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' ',
        signMessageVisibility: ' hide',

        messageLink: true,
        messageLinkUrl: '',
        messageLinkOpen: false,
        messageLinkButton: '',

        messageTemplateVisibility: ' hide',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        //messageRepeatStyle : '',
        messageTypeError: ' hide',
        messageLinkError: ' hide',
        messageButtonError: ' hide',

        newMessageTemplate: null,
        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',
      });

      this.checkMessageLinkOpen.current.checked = false;
      this.txtLinkMessageUrl.current.value = '';
      try {
        this.txtLinkMessageButton.current.value = '';
      } catch (error) {
        console.warn('Handled error setting this.txtLinkMessageButton.current.value');
      }
    } else if (this.selectMessageType.current.value == '6') {
      // SIGNATURE MESSAGE
      this.setState({
        newMessageSingleChoice: false,
        newMessageMultipleSelection: false,
        singleChoiceVisibility: ' hide',
        multipleOptionVisibility: ' hide',
        linkMessageVisibility: ' hide',
        signMessageVisibility: ' ',

        messageTemplateVisibility: ' hide',
        messageTemplateDetailsVisibiity: ' hide',

        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',

        newMessageTemplate: null,
        messageTemplateEditable: ' disabled',
        messageBodyLengthError: ' hide',

        messageRepeat: false,
        messageRepeatStyle: 'disabled',
        repeatConfigVisibility: ' hide',

        signMessage: false,
        //signMessageFileUrl :null,
        signMessageButton: '',

        messageSignError: ' hide',
        messageSignButtonError: ' hide',
      });

      this.checkRepeat.current.checked = false;
      this.txtSignFileUrl.current.value = '';
      try {
        this.txtSignMessageButton.current.value = '';
      } catch (error) {
        console.warn('Handled error setting this.txtSignMessageButton.current.value');
      }
    }

    this.checkOption1of2.current.checked = false;
    this.checkOption2of2.current.checked = false;

    this.checkOption1of5.current.checked = false;
    this.checkOption2of5.current.checked = false;
    this.checkOption3of5.current.checked = false;
    this.checkOption4of5.current.checked = false;
    this.checkOption5of5.current.checked = false;

    this.checkOption1of6.current.checked = false;
    this.checkOption2of6.current.checked = false;
    this.checkOption3of6.current.checked = false;
    this.checkOption4of6.current.checked = false;
    this.checkOption5of6.current.checked = false;
    this.checkOption6of6.current.checked = false;
  }

  handleSelectBiometricMessageType() {
    console.log('handleSelectBiometricMessageType');

    for (let i = 0; i < this.state.messageBiometricTypes.length; i++) {
      if (this.state.messageBiometricTypes[i].ID == this.selectBiometricMessageType.current.value) {
        let biometricMessageText = this.state.messageBiometricTypes[i].MessageText;
        this.setState({
          selectedBiometricTypeID: this.state.messageBiometricTypes[i].ID,
          selectedBiometricTypeName: this.state.messageBiometricTypes[i].Type,
          selectedBiometricTypeText: this.state.messageBiometricTypes[i].MessageText,
          selectedBiometricTypeInputText: this.state.messageBiometricTypes[i].InputTextFields,

          messageBodyChars: biometricMessageText.length,
        });

        biometricMessageText = biometricMessageText.replace('(mg/dL)', '');
        //biometricMessageText = biometricMessageText.replace("(Lbs)","");
        this.textMessage.current.value = biometricMessageText;
        console.log('SELECTED BIOMETRIC TYPE', this.state.messageBiometricTypes[i].Type);
        break;
      }
    }
  }

  handleSelectMessageTemplate() {
    this.setState({
      newMessageTemplate: this.selectMessageTemplate.current.value,
      messageTemplateError: ' hide',
    });

    if (this.selectMessageTemplate.current.value == this.state.messageTemplateDefault) {
      this.setState({
        singleChoiceVisibility: ' ',
        messageTemplateDetailsVisibiity: ' hide',
      });
    } else {
      this.setState({
        singleChoiceVisibility: ' hide',
        messageTemplateDetailsVisibiity: '',
      });
      this.txtSingleOption1.current.value = '';
      this.txtSingleOption2.current.value = '';
      this.txtSingleOption3.current.value = '';
      this.txtSingleOption4.current.value = '';
      this.txtSingleOption5.current.value = '';
      this.txtSingleOption6.current.value = '';
    }

    const singleChoiceTemplate = this.selectMessageTemplate.current.selectedOptions[0].text;

    if (singleChoiceTemplate == 'Yes / No - Text Buttons' || singleChoiceTemplate == 'Yes / No - Icon Buttons') {
      this.setState({
        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' ',

        responseAlertYesLabel: 'Yes',
        responseAlertNoLabel: 'No',
      });

      this.checkOption1of2.current.checked = false;
      this.checkOption2of2.current.checked = false;
    } else if (singleChoiceTemplate == 'Thumbs Up / Down') {
      this.setState({
        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' ',

        responseAlertYesLabel: 'Thumbs Up',
        responseAlertNoLabel: 'Thumbs Down',
      });

      this.checkOption1of2.current.checked = false;
      this.checkOption2of2.current.checked = false;
    } else if (singleChoiceTemplate == 'Feeling Emojis') {
      this.setState({
        responseAlertCustomVisibility: ' hide',
        responseAlertFacesVisibility: ' ',
        responseAlertYesNoVisibility: ' hide',
      });
      this.checkOption1of5.current.checked = false;
      this.checkOption2of5.current.checked = false;
      this.checkOption3of5.current.checked = false;
      this.checkOption4of5.current.checked = false;
      this.checkOption5of5.current.checked = false;
    } else {
      this.setState({
        responseAlertCustomVisibility: ' ',
        responseAlertFacesVisibility: ' hide',
        responseAlertYesNoVisibility: ' hide',
      });
      this.checkOption1of6.current.checked = false;
      this.checkOption2of6.current.checked = false;
      this.checkOption3of6.current.checked = false;
      this.checkOption4of6.current.checked = false;
      this.checkOption5of6.current.checked = false;
      this.checkOption6of6.current.checked = false;
    }
  }

  handleMessageLinkText() {
    this.setState({
      messageLinkError: ' hide',
    });
  }

  handleMessageButtonText() {
    this.setState({
      messageButtonError: ' hide',
    });
  }

  handleMessageSignText() {
    this.setState({
      messageSignError: ' hide',
    });
  }
  handleMessageSignButtonText() {
    this.setState({
      messageSignButtonError: ' hide',
    });
  }

  handleMessageLinkOpen(e) {
    const messageLinkAutoOpen = e.target.checked;
    console.log('MessageLink open', messageLinkAutoOpen);

    this.setState({
      messageLinkOpen: messageLinkAutoOpen,
      messageButtonError: ' hide',
    });
  }

  handleMessagePersistentState(e) {
    const messagePersistent = e.target.checked;
    console.log('handleMessagePersistentState', messagePersistent);
    this.setState({
      messagePersistentState: messagePersistent,
    });
  }

  handleResetOptionsValidationError() {
    if (this.state.messageOptionsError == ' ') {
      this.setState({
        messageOptionsError: ' hide',
      });
    }

    if (this.state.messageOptionsMaxError == ' ') {
      this.setState({
        messageOptionsMaxError: ' hide',
      });
    }

    if (this.selectMessageType.current.value == '2') {
      this.setState({
        messageO1Chars: this.txtSingleOption1.current.value.length,
        messageO2Chars: this.txtSingleOption2.current.value.length,
        messageO3Chars: this.txtSingleOption3.current.value.length,
        messageO4Chars: this.txtSingleOption4.current.value.length,
        messageO5Chars: this.txtSingleOption5.current.value.length,
        messageO6Chars: this.txtSingleOption6.current.value.length,
      });
    }

    if (this.selectMessageType.current.value == '3') {
      this.setState({
        messageO1Chars: this.txtMultiOption1.current.value.length,
        messageO2Chars: this.txtMultiOption2.current.value.length,
        messageO3Chars: this.txtMultiOption3.current.value.length,
        messageO4Chars: this.txtMultiOption4.current.value.length,
        messageO5Chars: this.txtMultiOption5.current.value.length,
        messageO6Chars: this.txtMultiOption6.current.value.length,
      });
    }
  }

  handleDeliveryImmediate() {
    // TODO: Cancel the Repeat Option
    if (this.state.messageEditableRecurring == 'disabled') {
      return;
    }

    this.checkRepeat.current.checked = false;
    const repeat = false;

    this.setState({
      messageDeliveryImmediate: true,

      messageRepeat: repeat,
      repeatConfigVisibility: repeat ? '' : ' hide',
      messageDeliveryDateTimeLabel: repeat ? 'First Delivery On' : 'Deliver On',
      repeatWeekVisibility: ' hide',

      messageRepeatStyle: 'disabled',
    });
  }

  handleDeliveryScheduled() {
    if (this.state.messageEditableRecurring == 'disabled') {
      return;
    }

    if (this.state.messageDeliveryImmediate) {
      console.log('Acomodamos la hora.');
      let currentTime = new Date();
      currentTime = currentTime.getTime() + 5 * 60000;
      currentTime = new Date(currentTime);

      this.state.messageDateTime = currentTime;
    }

    this.setState({
      messageDeliveryImmediate: false,
      messageRepeatStyle: this.selectMessageType.current.value == '6' ? 'disabled' : '',
    });
  }

  handleSelectedFile(e) {
    console.log('SelectedFile', e);

    if (this.state.signMessageFileUrl != null) {
      console.log('Previous PdfFile url', this.state.signMessageFileUrl);
      const oldFileName = this.state.signMessageFileName;
      let oldFileBucket = this.state.signMessageFileUrl;
      oldFileBucket = oldFileBucket.replace('https://s3-us-west-2.amazonaws.com/nucleuscare-data/signatures/', '');
      oldFileBucket = oldFileBucket.replace(`/${oldFileName}`, '');
      console.log('oldFileName', oldFileName);
      console.log('oldFileBucket', oldFileBucket);

      this.setState({
        signMessagePreviousFileName: oldFileName,
        signMessagePreviousFileBucket: oldFileBucket,
      });
    }

    //let files = e.target.files;
    const pdfFile = e.target.files[0];
    console.log('pdfFile:', pdfFile);
    if (pdfFile) {
      const fileName = pdfFile.name.replaceAll(' ', '_');
      this.txtSignFileUrl.current.value = fileName;

      const reader = new FileReader();
      const url = reader.readAsDataURL(pdfFile);
      const urlFile = URL.createObjectURL(pdfFile);
      reader.onloadend = function (e) {
        console.log('reader.onloaded', e);
        this.setState({
          selectedPdfFile: pdfFile,
          selectedPdfFileName: fileName,
          selectedPdfFilePreviewURL: urlFile,
          signMessageFileUrl: null,
        });
      }.bind(this);
      //console.log("Value image:image:",[reader.result]);
      console.log('url', url); // Would see a path?
      console.log('urlFile', urlFile); // Would see a path?
    }
  }

  handleSelectedPdfFilePreview() {
    //window.open(this.state.selectedPdfFile, '_blank');
    window.open(this.state.selectedPdfFilePreviewURL, '_blank');
  }

  handleSelectedPdfFileView() {
    window.open(this.state.signMessageFileUrl, '_blank');
  }

  handleResumeSaveMessage() {
    console.log('handleSaveMessage RESUME SAVE!!');

    if (this.state.newMessageCategoryID == null) {
      this.setState({
        messageCategoryError: ' ',
      });
      //$('#modalResumeSaveMessage').modal('close');
      console.log('Omg 1');
      return;
    }

    if (!(this.textMessage.current.value.length > 0)) {
      this.setState({
        messageBodyError: ' ',
        messageBodyErrorText:
          this.selectMessageType.current.value == '4' ? 'Please add a short message that will show, or read aloud, before the link will open.' : 'Please insert the message text.',
      });
      //$('#modalResumeSaveMessage').modal('close');
      console.log('Omg 2');
      return;
    }
    if (this.textMessage.current.value.length > 60) {
      this.setState({
        messageBodyLengthError: ' ',
      });
      //$('#modalResumeSaveMessage').modal('close');
      return;
    }

    if (this.selectMessageType.current.value == '') {
      this.setState({
        messageTypeError: ' ',
      });
      console.log('Omg 3');
      //$('#modalResumeSaveMessage').modal('close');
      return;
    }

    if (this.state.messageDateTime && this.state.messageDateTime.toString().length == 0) {
      this.setState({
        messageDeliveryDateError: ' ',
      });
      console.log('Omg 4');
      return;
    }

    if (this.selectMessageType.current.value == '2' && this.selectMessageTemplate.current.value == this.state.messageTemplateDefault) {
      let optionsIncluded = false;
      if (this.txtSingleOption1.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption2.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption3.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption4.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption5.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption6.current.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Omg 5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }

      let maxTextLimit = false;
      if (this.txtSingleOption1.current.value.length > 0 && this.txtSingleOption1.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption2.current.value.length > 0 && this.txtSingleOption2.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption3.current.value.length > 0 && this.txtSingleOption3.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption4.current.value.length > 0 && this.txtSingleOption4.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption5.current.value.length > 0 && this.txtSingleOption5.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption6.current.value.length > 0 && this.txtSingleOption6.current.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Omg 5.5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }
    }
    if (this.selectMessageType.current.value == '3') {
      let optionsIncluded = false;
      if (this.txtMultiOption1.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption2.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption3.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption4.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption5.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption6.current.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Omg 6');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }

      let maxTextLimit = false;
      if (this.txtMultiOption1.current.value.length > 0 && this.txtMultiOption1.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption2.current.value.length > 0 && this.txtMultiOption2.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption3.current.value.length > 0 && this.txtMultiOption3.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption4.current.value.length > 0 && this.txtMultiOption4.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption5.current.value.length > 0 && this.txtMultiOption5.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption6.current.value.length > 0 && this.txtMultiOption6.current.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Omg 6.5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }
    }

    if (this.selectMessageType.current.value === '4') {
      let errorEmptyLink = false;
      if (this.txtLinkMessageUrl.current.value === '') {
        this.setState({
          messageLinkError: ' ',
        });
        console.log('Msg-> 7.5');
        errorEmptyLink = true;
      }
      if (this.txtLinkMessageButton.current?.value === '') {
        this.setState({
          messageButtonError: ' ',
        });
        console.log('Msg-> 7.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }
    if (this.selectMessageType.current.value === '6') {
      let errorEmptyLink = false;
      if (this.txtSignFileUrl.current.value === '') {
        this.setState({
          messageSignError: ' ',
        });
        console.log('Msg-> 8.5');
        errorEmptyLink = true;
      }
      if (this.txtSignMessageButton.current.value === '') {
        this.setState({
          messageSignButtonError: ' ',
        });
        console.log('Msg-> 8.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }

    if (this.state.messageRepeat && !(this.txtRepeatNumber.current.value.length > 0)) {
      this.setState({
        messageRepeatEveryError: ' ',
      });
      console.log('Omg 7');
      //$('#modalResumeSaveMessage').modal('close');
      return;
    }

    if (this.selectMessageRecurring.current.value == 'Week') {
      if (
        !this.checkSunday.current.checked &&
        !this.checkMonday.current.checked &&
        !this.checkTuesday.current.checked &&
        !this.checkWednesday.current.checked &&
        !this.checkThursday.current.checked &&
        !this.checkFriday.current.checked &&
        !this.checkSaturday.current.checked
      ) {
        this.setState({
          messageRepeatDaysError: ' ',
        });
        console.log('Omg 7.5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }
    }

    if (this.state.messageRepeatEnds && new Date(this.state.messageEndDateTime) < new Date(this.state.messageDateTime)) {
      this.setState({
        messageEndDateError: ' ',
      });
      console.log('Omg 8');
      //$('#modalResumeSaveMessage').modal('close');
      return;
    }

    if (this.state.messageRepeat) {
      let repeatString = ' that will repeat every ' + this.txtRepeatNumber.current.value + ' ' + this.selectMessageRecurring.current.value;

      if (this.txtRepeatNumber.current.value > 1) {
        repeatString += 's';
      }

      if (this.selectMessageRecurring.current.value == 'Week') {
        const daysArray = [];
        if (this.checkSunday.current.checked) {
          daysArray.push('Sunday');
        }
        if (this.checkMonday.current.checked) {
          daysArray.push('Monday');
        }
        if (this.checkTuesday.current.checked) {
          daysArray.push('Tuesday');
        }
        if (this.checkWednesday.current.checked) {
          daysArray.push('Wednesday');
        }
        if (this.checkThursday.current.checked) {
          daysArray.push('Thursday');
        }
        if (this.checkFriday.current.checked) {
          daysArray.push('Friday');
        }
        if (this.checkSaturday.current.checked) {
          daysArray.push('Saturday');
        }
        const days = daysArray.join(', ');
        repeatString = repeatString + ' on ' + days;
      }

      let endRepeatString = '.';
      if (this.state.messageRepeatEnds) {
        endRepeatString = ', and the last delivery will be ' + this.state.messageEndDateTime + '.';
      }

      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The first delivery will be now' + endRepeatString,
          confirmMessageTextLine3: 'Once sent you will not be able to edit the message.',
        });
      } else {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The first delivery will be ' + this.state.messageDateTime + endRepeatString,
          confirmMessageTextLine3: '',
        });
      }
    } else {
      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: ' to be delivered now.',
          confirmMessageTextLine2: 'Once sent you will not be able to edit the message.',
          confirmMessageTextLine3: '',
        });
      } else {
        this.setState({
          confirmMessageText: ' to be delivered ' + this.state.messageDateTime + '.',
          confirmMessageTextLine2: '',
          confirmMessageTextLine3: '',
        });
      }
    }

    if (this.txtNoResponseTimeout.current.value == '' || this.txtNoResponseTimeout.current.value < 0) {
      this.txtNoResponseTimeout.current.value = 0;
    }

    if (this.selectMessageType.current.value == '4') {
      const linkUrl = this.validateUrlLink(this.txtLinkMessageUrl.current.value);
      this.txtLinkMessageUrl.current.value = linkUrl;
    }
    if (this.selectMessageType.current.value == '6') {
      const fileUrl = this.txtSignFileUrl.current.value;
      this.txtSignFileUrl.current.value = fileUrl;
    }

    $('#modalResumeSaveMessage').modal('open');
  }

  handleCancelResumeMessageModal() {
    $('#modalResumeSaveMessage').modal('close');
  }

  handleSaveMessage() {
    console.log('handleSaveMessage SAVE!!');
    let messageExpirationTime = 0;
    if (!this.state.messagePersistentState) {
      if (this.txtExpirationTimeout.current.value == '' || this.txtExpirationTimeout.current.value < 0) {
        this.txtExpirationTimeout.current.value = 1;
      }
      messageExpirationTime = this.txtExpirationTimeout.current.value;
    }

    this.setState({
      selectedMessagePatientsIDs: this.props.patientID,
    });

    let messageOptionsArray = null;
    let messageTemplateID = '';
    if (this.selectMessageType.current.value == '2' && this.selectMessageTemplate.current.value == this.state.messageTemplateDefault) {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption1.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption2.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption3.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption4.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption5.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption6.current.value);
    } else if (this.selectMessageType.current.value == '2' && this.selectMessageTemplate.current.value != this.state.messageTemplateDefault) {
      messageTemplateID = this.selectMessageTemplate.current.value;
    } else if (this.selectMessageType.current.value == '3') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption1.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption2.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption3.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption4.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption5.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption6.current.value);
    }
    console.log('Les Options sont', messageOptionsArray);

    let responseAlertsArray = null;
    if (this.state.responseAlertCustomVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.checkOption1of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption2of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption3of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption4of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption5of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption6of6.current.checked;
    } else if (this.state.responseAlertFacesVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.checkOption1of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption2of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption3of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption4of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption5of5.current.checked;
    } else if (this.state.responseAlertYesNoVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.checkOption1of2.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption2of2.current.checked;
    }

    console.log('Message Repeat: ', this.state.messageRepeat, 'Repeat Every: ', this.txtRepeatNumber.current.value, ' ', this.selectMessageRecurring.current.value);
    const deliveryDateTime = new Date(this.state.messageDateTime);

    let deliveryUTCTime =
      deliveryDateTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + deliveryDateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

    let sendNow = false;
    if (this.state.messageDeliveryImmediate) {
      sendNow = true;
      let currentTime = new Date();
      currentTime = currentTime.getTime() + 1 * 60000;
      currentTime = new Date(currentTime);
      //currentTime.setHours(currentTime.getHours() + this.controlTimezone);
      this.state.messageDateTime = currentTime; // currentTime.toLocaleDateString('en-US', this.dateOptions) + " " + currentTime.toLocaleTimeString('en-US', this.timeOptions);
      deliveryUTCTime =
        currentTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    }

    let endRepeatDate = '';
    if (this.state.messageRepeatEnds) {
      endRepeatDate = this.state.messageEndDateTime;
    }

    let biometricType = null;
    if (this.selectMessageType.current.value == '5') {
      // MessageType "5" : Biometrics
      biometricType = this.state.selectedBiometricTypeID;
    }

    const isLinkMessage = this.selectMessageType.current.value == '4';
    const isSignMessage = this.selectMessageType.current.value == '6';

    let linkMessageButtonText = '';
    if (!this.state.messageLinkOpen) {
      try {
        linkMessageButtonText = this.txtLinkMessageButton.current.value;
        if (this.selectMessageType.current.value == '6') {
          linkMessageButtonText = this.txtSignMessageButton.current.value;
        }
      } catch (error) {
        console.warn('Handled error setting this.txtLinkMessageButton.current.value');
      }
    }

    console.log('Repeat Ends :', this.state.messageRepeatEnds);
    console.log('Repeat Ends ON:', endRepeatDate);

    const linkUrl = this.txtLinkMessageUrl.current.value;
    const fileUrl = this.txtSignFileUrl.current.value;

    const newMessageData = {
      AccountID: AuthStore.getUserAccountID(),
      UserID: AuthStore.getUserID(),
      Message: this.textMessage.current.value,
      SendToAll: false,
      DeliveryTime: deliveryUTCTime,
      MessageCategoryID: this.state.newMessageCategoryID,
      IsPersistent: this.state.messagePersistentState,
      TtLHours: Number(messageExpirationTime),
      IsRecurring: this.state.messageRepeat,
      NoResponseTimeout: Number(this.txtNoResponseTimeout.current.value),

      IsRecurring: this.state.messageRepeat,
      RepeatEvery: Number(this.txtRepeatNumber.current.value),
      RepeatType: this.selectMessageRecurring.current.value,
      RepeatOnSunday: this.checkSunday.current.checked,
      RepeatOnMonday: this.checkMonday.current.checked,
      RepeatOnTuesday: this.checkTuesday.current.checked,
      RepeatOnWednesday: this.checkWednesday.current.checked,
      RepeatOnThursday: this.checkThursday.current.checked,
      RepeatOnFriday: this.checkFriday.current.checked,
      RepeatOnSaturday: this.checkSaturday.current.checked,
      EndDate: endRepeatDate,
      IsSingleOptionMessage: this.state.newMessageSingleChoice,
      IsMultipleOptionMessage: this.state.newMessageMultipleSelection,
      MessageOptionsTexts: messageOptionsArray,
      PatientsIDs: this.props.patientID,
      MessageOptionsTemplateID: messageTemplateID,
      IsLinkMessage: isLinkMessage,
      LinkMessageUrl: linkUrl,
      LinkMessageShouldOpen: this.state.messageLinkOpen,
      LinkMessageButton: linkMessageButtonText,
      IsSignatureMessage: isSignMessage,
      PdfFileUrl: isSignMessage ? fileUrl : null,
      BiometricTypeID: biometricType,
      MessageOptionsAlerts: responseAlertsArray,
      SendNow: sendNow,
    };

    console.log('Saving new message', newMessageData);

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn-disabled ',
      messageSaveNewBtnTitle: 'Saving...',
      savingMessage: true,
      savingMessageText: 'Saving message...',
    });

    MessagesStore.saveNewMessageByUser(newMessageData);
  }

  onSaveNewMessageByUserAction(response) {
    console.log('onSaveNewMessageByUserAction', response);

    if (response && response.ok) {
      if (this.state.savingMessage && response.ID && this.state.selectedPdfFile != null) {
        MessagesStore.UploadSignatureMessagePdfFile({
          MessageID: response.ID,
          PdfFileName: this.state.selectedPdfFileName,
          File: this.state.selectedPdfFile,
        });
        this.setState({
          savingMessageText: 'Saving message file...',
          selectedPdfFile: null,
        });
      } else {
        $('#modalResumeSaveMessage').modal('close');
        this.setState({
          loading: true,
        });
        this.setState({
          editMessageBtnStyle: ' nucleus-submit-btn ',
          messageSaveNewBtnTitle: 'Save',
          savingMessage: false,
          savingMessageText: 'New message created.',
        });
        CarePatientStore.refreshCurrentPatientMessageTab();
        // CarePatientStore.getScheduledPatientMessages({
        //     PatientID : this.props.patientID
        // });

        Message.show('Message saved');
        this.handleCloseNewMessageModal();
      }
    } else {
      $('#modalResumeSaveMessage').modal('close');
      Message.show('Error saving message');
      this.setState({
        editMessageBtnStyle: ' nucleus-submit-btn ',
        messageSaveNewBtnTitle: 'Save',
        savingMessage: false,
      });
    }
  }

  onSaveNewMessageFileAction(response) {
    console.log('onSaveNewMessageFileAction', response);

    if (response && response.ok) {
      $('#modalResumeSaveMessage').modal('close');
      this.setState({
        editMessageBtnStyle: ' nucleus-submit-btn ',
        savingMessage: false,
        savingMessageText: 'New message created.',
      });

      if (this.props.patientID) {
        CarePatientStore.getScheduledPatientMessages({
          PatientID: this.props.patientID,
        });
      } else {
        MessagesStore.refreshMessagesCenter();
      }
      Message.show('Message saved');
      this.handleCloseNewMessageModal();
    } else {
      Message.show('error saving message!');
      $('#modalResumeSaveMessage').modal('close');
      this.setState({
        editMessageBtnStyle: ' nucleus-submit-btn ',
        savingMessage: false,
      });
    }
    if (this.state.messageModalMode == 'Edit') {
      $('#modalResumeUpdateMessage').modal('close');
      this.handleCloseNewMessageModal();
    }
  }

  handleMessageRepeat(e) {
    const repeat = e.target.checked;

    this.setState({
      messageRepeat: repeat,
      repeatConfigVisibility: repeat ? '' : ' hide',
      messageDeliveryDateTimeLabel: repeat ? 'First Delivery On' : 'Deliver On',
    });

    if (!repeat) {
      this.setState({
        repeatWeekVisibility: ' hide',
      });
    } else {
      this.checkSunday.current.checked = false;
      this.checkMonday.current.checked = false;
      this.checkTuesday.current.checked = false;
      this.checkWednesday.current.checked = false;
      this.checkThursday.current.checked = false;
      this.checkFriday.current.checked = false;
      this.checkSaturday.current.checked = false;

      if (this.selectMessageRecurring.current.value == 'Week') {
        this.setState({
          repeatWeekVisibility: ' ',
        });
      }
    }
  }

  handleStopRecurringMessageModal() {
    $('#modalStopRecurringMessage').modal('open');
  }

  handleCancelStopRecurringMessageModal() {
    $('#modalStopRecurringMessage').modal('close');
  }

  handleStopRecurringMessage() {
    MessagesStore.stopRecurringMessage({
      MessageID: this.state.selectedMessageID,
      stop: true,
    });
    $('#modalStopRecurringMessage').modal('close');
  }

  onStopRecurringMessageAction(response) {
    console.log('onStopRecurringMessageAction', response);

    if (response.ok) {
      Message.show('Recurring message stopped');
      this.handleCloseNewMessageModal();
    } else {
      Message.show('Error stopping recurring message');
    }
  }

  handleTxtRepeatEvery() {
    this.setState({
      messageRepeatEveryError: ' hide',
    });
  }

  handleSelectMessageRecurring() {
    if (this.selectMessageRecurring.current.value == 'Week') {
      this.setState({
        repeatWeekVisibility: '',
      });
    } else {
      this.setState({
        repeatWeekVisibility: ' hide',
      });
      this.checkSunday.current.checked = false;
      this.checkMonday.current.checked = false;
      this.checkTuesday.current.checked = false;
      this.checkWednesday.current.checked = false;
      this.checkThursday.current.checked = false;
      this.checkFriday.current.checked = false;
      this.checkSaturday.current.checked = false;
    }
  }

  handleDayCheckChange() {
    if (this.state.messageRepeatDaysError == ' ') {
      this.setState({
        messageRepeatDaysError: ' hide',
      });
    }
  }

  handleMessageEnds(e) {
    const repeatEnds = e.target.checked;

    this.setState({
      messageRepeatEnds: repeatEnds,
      messageRepeatEndsDateSection: repeatEnds ? '' : ' hide',
    });
  }

  handleRepeatNeverEnds() {
    if (this.state.messageEditableRecurring == 'disabled') {
      return;
    }

    this.setState({
      messageRepeatEnds: false,
      messageRepeatEndsDateSection: ' hide',
    });
  }

  handleRepeatEndDate() {
    if (this.state.messageEditableRecurring == 'disabled') {
      return;
    }

    this.setState({
      messageRepeatEnds: true,
      messageRepeatEndsDateSection: ' ',
      messageEndDateError: ' hide',
    });
  }

  // TRIGGER UPDATE MESSAGE
  onGetPatientMessageDetailsAction(response) {
    console.log('onGetPatientMessageDetailsAction');
    console.log(response);

    //
    if (response.ok) {
      //Message.show("Got Message Info.");
      let messageEditLocked = response.data.messageRecipients > 1;
      console.log('MessageEdit Locked', messageEditLocked);

      if (response.data.Editable != undefined && !response.data.Editable) {
        messageEditLocked = true;
        console.log('----------> 2  No Editable At All. Message from classes');
      }

      $('#modalNewMessage').modal('open');

      let regularMessageMode = ' ';
      let biometricMessageMode = ' hide';
      if (response.data.BiometricTypeID && response.data.BiometricTypeID.length > 0) {
        regularMessageMode = ' hide';
        biometricMessageMode = ' ';
        console.log('Biometrics Message');

        for (let i = 0; i < this.state.messageBiometricTypes.length; i++) {
          if (this.state.messageBiometricTypes[i].ID == response.data.BiometricTypeID) {
            this.selectBiometricMessageType.current.value = response.data.BiometricTypeID;
            this.setState({
              selectedBiometricTypeID: this.state.messageBiometricTypes[i].ID,
              selectedBiometricTypeName: this.state.messageBiometricTypes[i].Type,
              selectedBiometricTypeText: this.state.messageBiometricTypes[i].MessageText,
              selectedBiometricTypeInputText: this.state.messageBiometricTypes[i].InputTextFields,
            });
            console.log('SELECTED BIOMETRIC TYPE', this.state.messageBiometricTypes[i].Type);
            break;
          }
        }
      }

      const delivery = new Date(response.data.DeliveryDateTime);
      //delivery.setHours(delivery.getHours() + (this.controlTimezone * -1));

      this.setState({
        selectedPatientMessageID: response.data.ID,
        selectedMessageID: response.data.MessageID,

        messageModalMode: 'Edit',
        messageSaveNewBtn: ' hide',
        messageSaveNewBtnTitle: 'Update',
        messageUpdateBtn: ' ',

        messageModalModeShow: ' hide ', // The DELETE Option is going to be another Modal.
        messageModalNotEditable: messageEditLocked ? ' ' : ' hide',
        messageModalNotEditableText:
          response.data.messageRecipients > 1
            ? 'Message cannot be edited because it was sent to multiple recipients.'
            : 'You cannot edit this message because the message has been sent.',
        messageModalOptionLabel: 'Delete',
        messageModeEdit: '',
        messageModeDelete: 'hide',
        messageModalOptionColor: 'txt-red',

        deleteMessageBtnStyle: ' nucleus-submit-btn',

        editMessageBtnStyle: !messageEditLocked ? ' nucleus-submit-btn ' : ' nucleus-submit-btn-disabled ',

        messageEditable: !messageEditLocked ? '' : 'disabled',
        messageEditableRecurring: '',

        messageDateTime: delivery, // delivery.toLocaleDateString('en-US', this.dateOptions) + " " + delivery.toLocaleTimeString('en-US', this.timeOptions);

        messageBroadcast: response.data.SendToAll,

        messagePersistentState: response.data.IsPersistent,
        selectedPdfFile: null,
        selectedPdfFileName: '',
        selectedPdfFilePreviewURL: '',

        messageOptionsError: ' hide',
        messageOptionsMaxError: ' hide',
        messageCategoryID: response.data.CategoryID,
        messageCategoryError: ' hide',

        messageDeliveryDateError: ' hide',
        messageEndDateError: ' hide',

        messageRepeat: response.data.IsRecurring,

        messageDeliveryDateTimeLabel: response.data.IsRecurring ? 'Next Delivery On' : 'Deliver On',

        repeatConfigVisibility: response.data.IsRecurring ? '' : ' hide',

        repeatWeekVisibility: response.data.IsRecurring && response.data.RepeatType == 'Week' ? '' : ' hide',

        //messageRepeat : false,
        messageRepeatStyle: messageEditLocked || response.data.IsRecurringStopped ? 'disabled' : '',
        messageRepeatEveryError: ' hide',
        messageRepeatDaysError: ' hide',

        stopRecurringMessageVisibility: response.data.IsRecurring ? ' hide' : ' hide',
        stopRecurringMessageStyle: response.data.IsRecurringStopped ? ' nucleus-submit-btn-small-disabled ' : ' nucleus-submit-btn-small ',

        messageRepeatEnds: response.data.EndDate != '',
        messageRepeatEndsDateSection: response.data.EndDate != '' ? '' : ' hide',

        messageDeliveryImmediate: false,

        regularMessageMode: regularMessageMode,
        biometricMessageMode: biometricMessageMode,

        messageBodyChars: response.data.Message.length,

        messageO1Chars: 0,
        messageO2Chars: 0,
        messageO3Chars: 0,
        messageO4Chars: 0,
        messageO5Chars: 0,
        messageO6Chars: 0,
      });

      this.textMessage.current.value = response.data.Message;
      this.txtNoResponseTimeout.current.value = response.data.NoResponseTimeout;
      this.checkPersistentState.current.checked = response.data.IsPersistent;
      if (!response.data.IsPersistent) {
        this.txtExpirationTimeout.current.value = response.data.TTLHours;
      }
      // /////
      ///// //
      if (response.data.IsSignatureMessage) {
        // SIGNATURE MESSAGE
        this.selectMessageType.current.value = '6';

        let filename = '';
        if (response.data.PdfFileUrl) {
          filename = response.data.PdfFileUrl.substring(response.data.PdfFileUrl.lastIndexOf('/') + 1);
        }

        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' ',

          responseAlertCustomVisibility: ' hide',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' hide',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          messageRepeatStyle: !messageEditLocked ? 'disabled' : 'disabled',
          messageTypeError: ' hide',

          messageLinkOpen: response.data.LinkMessageShouldOpen,
          messageLinkError: ' hide',
          messageButtonError: ' hide',

          signMessage: true,
          signMessageFileUrl: response.data.PdfFileUrl,
          signMessageFileName: filename,
          signMessageButton: response.data.LinkMessageButton,
          signMessagePreviousFileName: null,
          signMessagePreviousFileBucket: null,

          messageSignError: ' hide',
          messageSignButtonError: ' hide',

          //canSendAllPatients : ( !messageEditLocked) ? '' : 'disabled',
          //messageBroadcast : response.data.SendToAll,

          selectedMessagePatientsIDs: [],
          selectPatientsVisibility: response.data.SendToAll ? ' hide' : ' ',

          newMessageTemplate: null,
          //messageTemplateEditable : ' disabled'
        });

        this.txtSignFileUrl.current.value = filename;
        this.txtSignMessageButton.current.value = response.data.LinkMessageButton;

        if (this.txtExpirationTimeout.current.value == '' || this.txtExpirationTimeout.current.value < 0) {
          this.txtExpirationTimeout.current.value = 1;
        }
      } else if (response.data.IsLinkMessage) {
        // LINK
        this.selectMessageType.current.value = '4';

        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' ',
          signMessageVisibility: ' hide',

          responseAlertCustomVisibility: ' hide',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' hide',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          messageRepeatStyle: messageEditLocked || response.data.IsRecurringStopped ? 'disabled' : '',
          messageEscalationStyle: !messageEditLocked ? '' : 'disabled',
          messageTypeError: ' hide',

          messageLinkOpen: response.data.LinkMessageShouldOpen,
          messageLinkError: ' hide',
          messageButtonError: ' hide',

          //canSendAllPatients : MessageEditable ? '' : 'disabled',

          selectedMessagePatientsIDs: null,
          //selectPatientsVisibility : response.data.SendToAll ? ' hide' : ' '
          newMessageTemplate: null,
          messageTemplateEditable: ' disabled',
        });

        this.checkMessageLinkOpen.current.checked = response.data.LinkMessageShouldOpen;
        this.txtLinkMessageUrl.current.value = response.data.LinkMessageUrl;
        if (!response.data.LinkMessageShouldOpen) {
          this.txtLinkMessageButton.current.value = response.data.LinkMessageButton;
        }
      } else if (response.data.IsSingleOptionMessage) {
        this.selectMessageType.current.value = '2';

        this.setState({
          newMessageSingleChoice: true,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' ',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' hide',

          messageTemplateVisibility: ' ',

          responseAlertCustomVisibility: ' ',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageRepeatStyle: messageEditLocked || response.data.IsRecurringStopped ? 'disabled' : '',
          messageEscalationStyle: !messageEditLocked ? '' : 'disabled',
          messageTypeError: ' hide',

          // canSendAllPatients : 'disabled',
          // messageBroadcast : true,

          selectedMessagePatientsIDs: null,
          //selectPatientsVisibility : ' hide'
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',
          messageTemplateEditable: '',
          messageTemplateError: ' hide',
        });

        this.selectMessageTemplate.current.value = '';
        console.log('Template 1', response.data.MessageTemplateID);

        if (response.data.MessageTemplateID != null) {
          this.selectMessageTemplate.current.value = response.data.MessageTemplateID;
          console.log('Template 2', response.data.MessageTemplateID);
        } else {
          this.selectMessageTemplate.current.value = this.state.messageTemplateDefault;
        }

        console.log('Template 3', this.state.messageTemplateDefault);
        if (this.selectMessageTemplate.current.value == this.state.messageTemplateDefault) {
          console.log('Template 4', response.data.MessageTemplateID);
          this.setState({
            singleChoiceVisibility: ' ',
            messageTemplateDetailsVisibiity: ' hide',
            newMessageTemplate: this.selectMessageTemplate.current.value,
          });
          this.txtSingleOption1.current.value = response.data.options[0].OptionText;
          this.txtSingleOption2.current.value = response.data.options[1].OptionText;
          this.txtSingleOption3.current.value = response.data.options[2].OptionText;
          this.txtSingleOption4.current.value = response.data.options[3].OptionText;
          this.txtSingleOption5.current.value = response.data.options[4].OptionText;
          this.txtSingleOption6.current.value = response.data.options[5].OptionText;
        } else {
          this.setState({
            singleChoiceVisibility: ' hide',
            messageTemplateDetailsVisibiity: '',
            newMessageTemplate: this.selectMessageTemplate.current.value,
          });

          this.txtSingleOption1.current.value = '';
          this.txtSingleOption2.current.value = '';
          this.txtSingleOption3.current.value = '';
          this.txtSingleOption4.current.value = '';
          this.txtSingleOption5.current.value = '';
          this.txtSingleOption6.current.value = '';
        }
        console.log('SELECTED>', this.selectMessageTemplate.current.selectedOptions[0].text);
        this.setState({
          messageO1Chars: this.txtSingleOption1.current.value.length,
          messageO2Chars: this.txtSingleOption2.current.value.length,
          messageO3Chars: this.txtSingleOption3.current.value.length,
          messageO4Chars: this.txtSingleOption4.current.value.length,
          messageO5Chars: this.txtSingleOption5.current.value.length,
          messageO6Chars: this.txtSingleOption6.current.value.length,
        });
      } else if (response.data.IsMultipleOptionMessage) {
        this.selectMessageType.current.value = '3';

        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: true,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' ',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' hide',

          responseAlertCustomVisibility: ' ',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' ',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          messageRepeatStyle: messageEditLocked || response.data.IsRecurringStopped ? 'disabled' : '',
          messageEscalationStyle: !messageEditLocked ? '' : 'disabled',
          messageTypeError: ' hide',

          //canSendAllPatients : 'disabled',
          //messageBroadcast : true,

          selectedMessagePatientsIDs: null,
          //selectPatientsVisibility : ' hide',

          newMessageTemplate: null,

          messageTemplateEditable: ' disabled',
          //selectPatientsVisibility : ' hide'
        });

        this.selectMessageTemplate.current.value = '';

        if (this.state.messageTemplateDefault != null) {
          this.selectMessageTemplate.current.value = this.state.messageTemplateDefault;
        }

        this.txtMultiOption1.current.value = response.data.options[0].OptionText;
        this.txtMultiOption2.current.value = response.data.options[1].OptionText;
        this.txtMultiOption3.current.value = response.data.options[2].OptionText;
        this.txtMultiOption4.current.value = response.data.options[3].OptionText;
        this.txtMultiOption5.current.value = response.data.options[4].OptionText;
        this.txtMultiOption6.current.value = response.data.options[5].OptionText;

        this.setState({
          messageO1Chars: this.txtMultiOption1.current.value.length,
          messageO2Chars: this.txtMultiOption2.current.value.length,
          messageO3Chars: this.txtMultiOption3.current.value.length,
          messageO4Chars: this.txtMultiOption4.current.value.length,
          messageO5Chars: this.txtMultiOption5.current.value.length,
          messageO6Chars: this.txtMultiOption6.current.value.length,
        });
      } else {
        // PLAIN TEXT
        this.selectMessageType.current.value = '1';

        this.setState({
          newMessageSingleChoice: false,
          newMessageMultipleSelection: false,
          singleChoiceVisibility: ' hide',
          multipleOptionVisibility: ' hide',
          linkMessageVisibility: ' hide',
          signMessageVisibility: ' hide',

          responseAlertCustomVisibility: ' hide',
          responseAlertFacesVisibility: ' hide',
          responseAlertYesNoVisibility: ' hide',

          messageTemplateVisibility: ' hide',
          messageTemplateDetailsVisibiity: ' hide',
          messageOptionsError: ' hide',
          messageOptionsMaxError: ' hide',

          messageRepeatStyle: messageEditLocked || response.data.IsRecurringStopped ? 'disabled' : '',
          messageEscalationStyle: !messageEditLocked ? '' : 'disabled',
          messageTypeError: ' hide',

          //canSendAllPatients : MessageEditable ? '' : 'disabled',

          selectedMessagePatientsIDs: null,
          //selectPatientsVisibility : response.data.SendToAll ? ' hide' : ' '
          newMessageTemplate: null,
          messageTemplateEditable: ' disabled',
        });
      }

      // TODO Check this logic

      if (this.selectMessageType.current.value == '2' || this.selectMessageType.current.value == '3') {
        const singleChoiceTemplate = this.selectMessageTemplate.current.selectedOptions[0].text;
        if (singleChoiceTemplate == 'Yes / No - Text Buttons' || singleChoiceTemplate == 'Yes / No - Icon Buttons') {
          this.setState({
            responseAlertCustomVisibility: ' hide',
            responseAlertFacesVisibility: ' hide',
            responseAlertYesNoVisibility: ' ',
            responseAlertYesLabel: 'Yes',
            responseAlertNoLabel: 'No',
          });

          this.checkOption1of2.current.checked = response.data.options[0].ShouldAlert;
          this.checkOption2of2.current.checked = response.data.options[1].ShouldAlert;
        } else if (singleChoiceTemplate == 'Thumbs Up / Down') {
          this.setState({
            responseAlertCustomVisibility: ' hide',
            responseAlertFacesVisibility: ' hide',
            responseAlertYesNoVisibility: ' ',
            responseAlertYesLabel: 'Thumbs Up',
            responseAlertNoLabel: 'Thumbs Down',
          });

          this.checkOption1of2.current.checked = response.data.options[0].ShouldAlert;
          this.checkOption2of2.current.checked = response.data.options[1].ShouldAlert;
        } else if (singleChoiceTemplate == 'Feeling Emojis') {
          this.setState({
            responseAlertCustomVisibility: ' hide',
            responseAlertFacesVisibility: ' ',
            responseAlertYesNoVisibility: ' hide',
          });
          this.checkOption1of5.current.checked = response.data.options[0].ShouldAlert;
          this.checkOption2of5.current.checked = response.data.options[1].ShouldAlert;
          this.checkOption3of5.current.checked = response.data.options[2].ShouldAlert;
          this.checkOption4of5.current.checked = response.data.options[3].ShouldAlert;
          this.checkOption5of5.current.checked = response.data.options[4].ShouldAlert;
        } else {
          this.setState({
            responseAlertCustomVisibility: ' ',
            responseAlertFacesVisibility: ' hide',
            responseAlertYesNoVisibility: ' hide',
          });
          this.checkOption1of6.current.checked = response.data.options[0].ShouldAlert;
          this.checkOption2of6.current.checked = response.data.options[1].ShouldAlert;
          this.checkOption3of6.current.checked = response.data.options[2].ShouldAlert;
          this.checkOption4of6.current.checked = response.data.options[3].ShouldAlert;
          this.checkOption5of6.current.checked = response.data.options[4].ShouldAlert;
          this.checkOption6of6.current.checked = response.data.options[5].ShouldAlert;
        }
      }

      this.checkRepeat.current.checked = response.data.IsRecurring;

      if (
        messageEditLocked ||
        (response.data.IsRecurring && messageEditLocked) ||
        (response.data.IsRecurringStopped && messageEditLocked) ||
        response.data.messagesAlreadyDelivered > 0
      ) {
        this.setState({
          messageTemplateEditable: ' disabled',
          messageEditableRecurring: 'disabled',
        });
        console.log('In');
      }

      this.txtRepeatNumber.current.value = response.data.RepeatEvery;
      this.selectMessageRecurring.current.value = response.data.RepeatType;

      this.checkSunday.current.checked = response.data.RepeatOnSunday;
      this.checkMonday.current.checked = response.data.RepeatOnMonday;
      this.checkTuesday.current.checked = response.data.RepeatOnTuesday;
      this.checkWednesday.current.checked = response.data.RepeatOnWednesday;
      this.checkThursday.current.checked = response.data.RepeatOnThursday;
      this.checkFriday.current.checked = response.data.RepeatOnFriday;
      this.checkSaturday.current.checked = response.data.RepeatOnSaturday;

      let endRepeatDate = new Date();
      if (response.data.EndDate == '') {
        this.setState({
          messageRepeatEnds: false,
          messageRepeatEndsDateSection: ' hide',
          messageEndDateTime: endRepeatDate,
        });
      } else {
        endRepeatDate = new Date(response.data.EndDate);
        this.setState({
          messageRepeatEnds: true,
          messageRepeatEndsDateSection: ' ',
          messageEndDateError: ' hide',
          messageEndDateTime: endRepeatDate,
        });
      }
      //this.state.messageEndDateTime = endRepeatDate; // endRepeatDate.toLocaleDateString('en-US', this.dateOptions) + " " + endRepeatDate.toLocaleTimeString('en-US', this.timeOptions);
    } else {
      Message.show('Error getting message details.');
    }
  }

  onOpenNotEditableMessageAction() {
    $('#modalNotEditableMessage').modal('open');
  }

  handleCloseNotEditableModal() {
    $('#modalNotEditableMessage').modal('close');
  }

  onHandleDeleteMessageAction({ PatientMessageID }) {
    console.log('OPEN DELETE MESSAGE', PatientMessageID);

    this.setState({
      selectedPatientMessageID: PatientMessageID,
      deleteMessageBtnStyle: ' nucleus-submit-btn ',
    });
    $('#modalDeletePatientMessage').modal('open');
  }

  handleCancelDelete() {
    $('#modalDeletePatientMessage').modal('close');
  }

  onHandleNotDeleteMessageAction() {
    $('#modalNotDeleteMessage').modal('open');
  }

  handleCloseNotDeleteModal() {
    $('#modalNotDeleteMessage').modal('close');
  }

  handleResumeUpdateMessage() {
    console.log('handleResumeUpdateMessage RESUME UPDATE!!');

    // if (this.state.newMessageCategoryID == null){
    //     this.setState({
    //         messageCategoryError : ' '
    //     });
    //     console.log("Omg 1");
    //     return;
    // }

    if (!(this.textMessage.current.value.length > 0)) {
      this.setState({
        messageBodyError: ' ',
      });
      console.log('Omg 2');
      return;
    }

    if (this.textMessage.current.value.length > 60) {
      this.setState({
        messageBodyLengthError: ' ',
      });
      return;
    }

    if (this.selectMessageType.current.value == '') {
      this.setState({
        messageTypeError: ' ',
      });
      console.log('Omg 3');
      return;
    }

    if (this.state.messageDateTime && this.state.messageDateTime.toString().length == 0) {
      this.setState({
        messageDeliveryDateError: ' ',
      });
      console.log('Omg 4');
      return;
    }

    if (this.selectMessageType.current.value == '2' && this.selectMessageTemplate.current.value == this.state.messageTemplateDefault) {
      let optionsIncluded = false;
      if (this.txtSingleOption1.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption2.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption3.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption4.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption5.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtSingleOption6.current.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Omg 5');
        return;
      }

      let maxTextLimit = false;
      if (this.txtSingleOption1.current.value.length > 0 && this.txtSingleOption1.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption2.current.value.length > 0 && this.txtSingleOption2.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption3.current.value.length > 0 && this.txtSingleOption3.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption4.current.value.length > 0 && this.txtSingleOption4.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption5.current.value.length > 0 && this.txtSingleOption5.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtSingleOption6.current.value.length > 0 && this.txtSingleOption6.current.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Omg 5.5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }
    }
    if (this.selectMessageType.current.value == '3') {
      let optionsIncluded = false;
      if (this.txtMultiOption1.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption2.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption3.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption4.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption5.current.value.length > 0) {
        optionsIncluded = true;
      } else if (this.txtMultiOption6.current.value.length > 0) {
        optionsIncluded = true;
      }

      if (!optionsIncluded) {
        this.setState({
          messageOptionsError: ' ',
        });
        console.log('Omg 6');
        return;
      }

      let maxTextLimit = false;
      if (this.txtMultiOption1.current.value.length > 0 && this.txtMultiOption1.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption2.current.value.length > 0 && this.txtMultiOption2.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption3.current.value.length > 0 && this.txtMultiOption3.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption4.current.value.length > 0 && this.txtMultiOption4.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption5.current.value.length > 0 && this.txtMultiOption5.current.value.length > 15) {
        maxTextLimit = true;
      } else if (this.txtMultiOption6.current.value.length > 0 && this.txtMultiOption6.current.value.length > 15) {
        maxTextLimit = true;
      }

      if (maxTextLimit) {
        this.setState({
          messageOptionsMaxError: ' ',
        });
        console.log('Omg 6.5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }
    }

    if (this.selectMessageType.current.value == '4') {
      let errorEmptyLink = false;
      if (this.txtLinkMessageUrl.current.value == '') {
        this.setState({
          messageLinkError: ' ',
        });
        console.log('Msg-> 7.5');
        errorEmptyLink = true;
      }
      if (this.txtLinkMessageButton.current.value == '') {
        this.setState({
          messageButtonError: ' ',
        });
        console.log('Msg-> 7.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }
    //
    if (this.selectMessageType.current.value == '6') {
      let errorEmptyLink = false;
      if (this.txtSignFileUrl.current.value == '') {
        this.setState({
          messageSignError: ' ',
        });
        console.log('Msg-> 8.5');
        errorEmptyLink = true;
      }
      if (this.txtSignMessageButton.current.value == '') {
        this.setState({
          messageSignButtonError: ' ',
        });
        console.log('Msg-> 8.6');
        errorEmptyLink = true;
      }
      if (errorEmptyLink) {
        return;
      }
    }

    if (this.state.messageRepeat && !(this.txtRepeatNumber.current.value.length > 0)) {
      this.setState({
        messageRepeatEveryError: ' ',
      });
      console.log('Omg 7');
      //$('#modalResumeSaveMessage').modal('close');
      return;
    }

    if (this.selectMessageRecurring.current.value == 'Week') {
      if (
        !this.checkSunday.current.checked &&
        !this.checkMonday.current.checked &&
        !this.checkTuesday.current.checked &&
        !this.checkWednesday.current.checked &&
        !this.checkThursday.current.checked &&
        !this.checkFriday.current.checked &&
        !this.checkSaturday.current.checked
      ) {
        this.setState({
          messageRepeatDaysError: ' ',
        });
        console.log('Omg 7.5');
        //$('#modalResumeSaveMessage').modal('close');
        return;
      }
    }

    if (this.state.messageRepeatEnds && new Date(this.state.messageEndDateTime) < new Date(this.state.messageDateTime)) {
      this.setState({
        messageEndDateError: ' ',
      });
      console.log('Omg 8');
      //$('#modalResumeSaveMessage').modal('close');
      return;
    }
    if (this.state.messageRepeat) {
      let repeatString = ' that will repeat every ' + this.txtRepeatNumber.current.value + ' ' + this.selectMessageRecurring.current.value;

      if (this.txtRepeatNumber.current.value > 1) {
        repeatString += 's';
      }

      if (this.selectMessageRecurring.current.value == 'Week') {
        const daysArray = [];
        if (this.checkSunday.current.checked) {
          daysArray.push('Sunday');
        }
        if (this.checkMonday.current.checked) {
          daysArray.push('Monday');
        }
        if (this.checkTuesday.current.checked) {
          daysArray.push('Tuesday');
        }
        if (this.checkWednesday.current.checked) {
          daysArray.push('Wednesday');
        }
        if (this.checkThursday.current.checked) {
          daysArray.push('Thursday');
        }
        if (this.checkFriday.current.checked) {
          daysArray.push('Friday');
        }
        if (this.checkSaturday.current.checked) {
          daysArray.push('Saturday');
        }
        const days = daysArray.join(', ');
        repeatString = repeatString + ' on ' + days;
      }

      let endRepeatString = '.';
      if (this.state.messageRepeatEnds) {
        endRepeatString = ', and the last delivery will be ' + this.state.messageEndDateTime + '.';
      }

      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The next delivery will be now' + endRepeatString,
          confirmMessageTextLine3: 'Once sent you will not be able to edit the message.',
        });
      } else {
        this.setState({
          confirmMessageText: repeatString + '.',
          confirmMessageTextLine2: 'The next delivery will be ' + this.state.messageDateTime + endRepeatString,
          confirmMessageTextLine3: '',
        });
      }
    } else {
      if (this.state.messageDeliveryImmediate) {
        this.setState({
          confirmMessageText: ' to be delivered now.',
          confirmMessageTextLine2: 'Once sent you will not be able to edit the message.',
          confirmMessageTextLine3: '',
        });
      } else {
        this.setState({
          confirmMessageText: ' to be delivered ' + this.state.messageDateTime + '.',
          confirmMessageTextLine2: '',
          confirmMessageTextLine3: '',
        });
      }
    }

    if (this.txtNoResponseTimeout.current.value == '' || this.txtNoResponseTimeout.current.value < 0) {
      this.txtNoResponseTimeout.current.value = 0;
    }
    if (!this.state.messagePersistentState) {
      if (this.txtExpirationTimeout.current.value == '' || this.txtExpirationTimeout.current.value < 0) {
        this.txtExpirationTimeout.current.value = 1;
      }
    }

    if (this.selectMessageType.current.value == '4') {
      const linkUrl = this.validateUrlLink(this.txtLinkMessageUrl.current.value);
      this.txtLinkMessageUrl.current.value = linkUrl;
    }
    if (this.selectMessageType.current.value == '6') {
      const linkUrl = this.txtSignFileUrl.current.value;
      this.txtSignFileUrl.current.value = linkUrl;
    }

    $('#modalResumeUpdateMessage').modal('open');
  }

  handleCancelResumeUpdateMessageModal() {
    $('#modalResumeUpdateMessage').modal('close');
  }

  handleUpdateMessage() {
    console.log('handleUpdateMessage UPDATE!!');

    this.setState({
      selectedMessagePatientsIDs: this.props.patientID,
    });

    let messageOptionsArray = null;
    let messageTemplateID = '';
    if (this.selectMessageType.current.value == '2' && this.selectMessageTemplate.current.value == this.state.messageTemplateDefault) {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption1.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption2.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption3.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption4.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption5.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtSingleOption6.current.value);
    } else if (this.selectMessageType.current.value == '2' && this.selectMessageTemplate.current.value != this.state.messageTemplateDefault) {
      messageTemplateID = this.selectMessageTemplate.current.value;
    } else if (this.selectMessageType.current.value == '3') {
      messageOptionsArray = '';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption1.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption2.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption3.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption4.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption5.current.value) + ',';
      messageOptionsArray = messageOptionsArray + encodeURIComponent(this.txtMultiOption6.current.value);
    }
    console.log('Les Options sont', messageOptionsArray);

    let responseAlertsArray = null;
    if (this.state.responseAlertCustomVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.checkOption1of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption2of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption3of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption4of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption5of6.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption6of6.current.checked;
    } else if (this.state.responseAlertFacesVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.checkOption1of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption2of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption3of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption4of5.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption5of5.current.checked;
    } else if (this.state.responseAlertYesNoVisibility == ' ') {
      responseAlertsArray = '';
      responseAlertsArray = responseAlertsArray + this.checkOption1of2.current.checked + ',';
      responseAlertsArray = responseAlertsArray + this.checkOption2of2.current.checked;
    }

    const deliveryDateTime = new Date(this.state.messageDateTime);
    //deliveryDateTime.setHours(deliveryDateTime.getHours() + this.controlTimezone);
    let deliveryUTCTime =
      deliveryDateTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + deliveryDateTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });

    let sendNow = false;
    if (this.state.messageDeliveryImmediate) {
      sendNow = true;
      let currentTime = new Date();
      currentTime = currentTime.getTime() + 1 * 60000;
      currentTime = new Date(currentTime);
      //currentTime.setHours(currentTime.getHours() + this.controlTimezone);
      this.state.messageDateTime = currentTime; // currentTime.toLocaleDateString('en-US', this.dateOptions) + " " + currentTime.toLocaleTimeString('en-US', this.timeOptions);
      deliveryUTCTime =
        currentTime.toLocaleDateString('en-US', this.dateOptions) + ' ' + currentTime.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' });
    }

    let endRepeatDate = '';
    if (this.state.messageRepeatEnds) {
      endRepeatDate = this.state.messageEndDateTime;
    }

    console.log('Repeat Ends :', this.state.messageRepeatEnds);
    console.log('Repeat Ends ON:', endRepeatDate);

    let biometricType = null;
    if (this.selectMessageType.current.value == '5') {
      // MessageType "5" : Biometrics
      biometricType = this.state.selectedBiometricTypeID;
    }

    const isLinkMessage = this.selectMessageType.current.value == '4';
    const isSignMessage = this.selectMessageType.current.value == '6';

    let linkMessageButtonText = '';
    try {
      linkMessageButtonText = this.txtLinkMessageButton.current.value;
      if (this.selectMessageType.current.value == '6') {
        linkMessageButtonText = this.txtSignMessageButton.current.value;
      }
    } catch (error) {
      console.warn('Handled error setting this.txtLinkMessageButton.current.value');
    }

    const linkUrl = this.txtLinkMessageUrl.current.value;

    let messageExpirationTime = 0;
    if (!this.state.messagePersistentState) {
      if (this.txtExpirationTimeout.current.value == '' || this.txtExpirationTimeout.current.value < 0) {
        this.txtExpirationTimeout.current.value = 1;
      }
      messageExpirationTime = this.txtExpirationTimeout.current.value;
    }

    this.setState({
      editMessageBtnStyle: ' nucleus-submit-btn-disabled ',
      messageSaveNewBtnTitle: 'Saving...',
      savingMessage: true,
      savingMessageText: 'Updating message...',
    });

    MessagesStore.updateScheduledMessage({
      PatientMessageID: this.state.selectedPatientMessageID,
      Message: this.textMessage.current.value,
      SendToAll: false,
      DeliveryTime: deliveryUTCTime,
      MessageCategoryID: this.state.messageCategoryID, //this.state.newMessageCategory,
      IsPersistent: this.state.messagePersistentState,
      TtLHours: Number(messageExpirationTime),

      NoResponseTimeout: this.txtNoResponseTimeout.current.value,
      IsRecurring: this.state.messageRepeat,
      RepeatEvery: this.txtRepeatNumber.current.value,
      RepeatType: this.selectMessageRecurring.current.value,
      RepeatOnSunday: this.checkSunday.current.checked,
      RepeatOnMonday: this.checkMonday.current.checked,
      RepeatOnTuesday: this.checkTuesday.current.checked,
      RepeatOnWednesday: this.checkWednesday.current.checked,
      RepeatOnThursday: this.checkThursday.current.checked,
      RepeatOnFriday: this.checkFriday.current.checked,
      RepeatOnSaturday: this.checkSaturday.current.checked,
      EndDate: endRepeatDate,
      IsSingleOptionMessage: this.state.newMessageSingleChoice,
      IsMultipleOptionMessage: this.state.newMessageMultipleSelection,
      MessageOptionsTexts: messageOptionsArray,
      PatientsIDs: this.props.patientID,
      MessageOptionsTemplateID: messageTemplateID,
      BiometricTypeID: biometricType,
      MessageOptionsAlerts: responseAlertsArray,

      IsLinkMessage: isLinkMessage,
      LinkMessageUrl: linkUrl,
      LinkMessageShouldOpen: this.state.messageLinkOpen,
      LinkMessageButton: linkMessageButtonText,

      IsSignatureMessage: isSignMessage,

      SendNow: sendNow,
    });
  }

  onUpdateScheduledMessageAction(response) {
    console.log('onUpdateScheduledMessageAction', response);

    if (response.ok) {
      if (this.state.savingMessage && response.MessageID && this.state.selectedPdfFile != null) {
        MessagesStore.UploadSignatureMessagePdfFile({
          MessageID: response.MessageID,
          PdfFileName: this.state.selectedPdfFileName,
          File: this.state.selectedPdfFile,
        });
        if (this.state.signMessagePreviousFileName != null) {
          MessagesStore.DeleteSignatureMessagePdfFile({
            PdfFileName: this.state.signMessagePreviousFileName,
            SignaturesBucketName: this.state.signMessagePreviousFileBucket,
          });
        }
        this.setState({
          savingMessageText: 'Saving message file...',
          selectedPdfFile: null,
        });
      } else {
        $('#modalResumeUpdateMessage').modal('close');
        this.setState({
          editMessageBtnStyle: ' nucleus-submit-btn ',
          messageSaveNewBtnTitle: 'Update',
        });
        this.setState({
          loading: true,
        });

        CarePatientStore.getScheduledPatientMessages({
          PatientID: this.props.patientID,
        });

        Message.show('Message updated');
        this.handleCloseNewMessageModal();
      }
    } else {
      Message.show('Error updating message');
    }
  }

  handleSubmitDeleteMessage() {
    this.setState({
      deleteMessageBtnStyle: ' nucleus-submit-btn-disabled',
    });

    MessagesStore.deletePatientMessage({
      PatientMessageID: this.state.selectedPatientMessageID,
    });
  }

  onDeletePatientMessageAction(response) {
    console.log('onDeletePatientMessageAction', response);

    this.setState({
      deleteMessageBtnStyle: ' nucleus-submit-btn ',
    });

    if (response.ok) {
      this.setState({
        loading: true,
      });

      CarePatientStore.getScheduledPatientMessages({
        PatientID: this.props.patientID,
      });
      Message.show('Message deleted');
      $('#modalDeletePatientMessage').modal('close');
    } else {
      Message.show('Error updating message');
    }
  }

  validateUrlLink(url) {
    let linkUrl = url;

    if (linkUrl.indexOf('https://') == 0) {
      //console.log("Todo bien!!");
    } else if (linkUrl.indexOf('http://') == 0) {
      linkUrl = linkUrl.replace('http://', 'https://');
    } else if (linkUrl.search('www') > -1) {
      const v = linkUrl.search('www');
      linkUrl = linkUrl.substring(v + 3);
      linkUrl = 'https://www' + linkUrl;
    } else {
      linkUrl = 'https://www.' + linkUrl;
    }

    // if (linkUrl.indexOf("w.") > -1) {
    //     let v = linkUrl.indexOf("w.");
    //     linkUrl = linkUrl.substring(v+1);  // ww.fifa.com/fifaplus/es/home
    //     linkUrl = "https://www" + linkUrl;
    // }
    // else {
    //     linkUrl = "https://www." + linkUrl;
    //     linkUrl = linkUrl.replace("..", ".");
    // }
    return linkUrl;
  }

  render() {
    const voiceMessageEnabledValue = this.state.enableVoiceMessage ? 'checked' : '';

    const careScheduledMessagesList = [];
    if (this.state.careScheduledMessagesArray != null) {
      const currentMomentUTC = new Date();
      currentMomentUTC.setHours(currentMomentUTC.getHours() + this.controlTimezone);

      this.state.careScheduledMessagesArray.map((message, key) => {
        careScheduledMessagesList.push(
          <CarePatientScheduledMessageItemRow
            key={key}
            PatientMessageID={message.ID}
            MessageID={message.MessageID}
            PatientID={this.props.patientID}
            Message={message.Message}
            CreatedDate={message.CreatedDate}
            DeliveryDateTime={message.DeliveryTime}
            DeliveryDateTimeUTC={message.DeliveryTimeUTC}
            User={message.User}
            RecurringText={message.RecurringText}
            CanEdit={false}
            //Category={message.Category}

            Timezone={message.Timezone}
            NowUTC={currentMomentUTC}
          />,
        );
      });
    }

    // DEPRECATED -> Jan 2023, Messages Categories not needed anymore; CARE-10241 Signature Messages
    // let messageCategoriesList = [];
    // if (this.state.messageCategories != null && this.state.messageCategories.length){
    //     this.state.messageCategories.map((category, key) => {
    //         if (category.Category == 'Family'){
    //             return;
    //         }
    //         if (category.Category != 'Biometrics' || this.state.enableBiometrics){
    //             messageCategoriesList.push(<option key={category.ID} value={category.ID} >{category.Category}</option>);
    //         }
    //     });
    // }

    const messageTemplatesList = [];
    if (this.state.messageTemplates != null && this.state.messageTemplates.length) {
      this.state.messageTemplates.map(template => {
        messageTemplatesList.push(
          <option key={template.ID} value={template.ID}>
            {template.Name}
          </option>,
        );
      });
    }

    const messageBiometricTypesList = [];
    if (this.state.messageBiometricTypes != null && this.state.messageBiometricTypes.length) {
      this.state.messageBiometricTypes.map(biometricType => {
        messageBiometricTypesList.push(
          <option key={biometricType.ID} value={biometricType.ID}>
            {biometricType.Type}
          </option>,
        );
      });
    }

    let disabledFont = '';
    if (this.state.messageEditableRecurring == 'disabled') {
      disabledFont = ' placeholder-gray';
    }

    const singleChoiceTemplate = this.state.newMessageTemplate ? this.selectMessageTemplate.current.selectedOptions[0].text : '';
    let buttonsVisibility = ' hide';
    let iconsVisibility = ' hide';
    let thumbsVisibility = ' hide';
    let emojisVisibility = ' hide';

    if (singleChoiceTemplate == 'Yes / No - Text Buttons') {
      buttonsVisibility = ' ';
    } else if (singleChoiceTemplate == 'Yes / No - Icon Buttons') {
      iconsVisibility = ' ';
    } else if (singleChoiceTemplate == 'Thumbs Up / Down') {
      thumbsVisibility = ' ';
    } else if (singleChoiceTemplate == 'Feeling Emojis') {
      emojisVisibility = ' ';
    }

    let deliveryNowImg = '/img/radio_button_on.png';
    let deliveryScheduledImg = '/img/radio_button_off.png';

    if (!this.state.messageDeliveryImmediate) {
      deliveryScheduledImg = '/img/radio_button_on.png';
      deliveryNowImg = '/img/radio_button_off.png';
    }

    const deliveryDateTime = this.state.messageDeliveryImmediate ? ' hide' : '';

    let repeatNeverEndsImg = '/img/radio_button_on.png';
    let repeatEndDateImg = '/img/radio_button_off.png';

    if (this.state.messageRepeatEnds) {
      repeatNeverEndsImg = '/img/radio_button_off.png';
      repeatEndDateImg = '/img/radio_button_on.png';
    }

    let tableResultsView = ' hide';
    //let tableNoResultsView = ' hide';
    let tableLoadingView = '';
    if (this.state.loading) {
      tableResultsView = ' hide';
      //tableNoResultsView = ' hide';
      tableLoadingView = '';
    } else {
      tableResultsView = ' ';
      //tableNoResultsView = ' hide';
      tableLoadingView = ' hide';
    }
    const filterBtnMode = 'nucleus-submit-btn-mini';

    return (
      <div className="row no-margin ">
        {this.props.tableVisibility && (
          <div>
            <div className={'row no-margin'}>
              <div className="col m2 s3 no-margin">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="nucleus-labels"> Enable Voice Message</span>
              </div>

              <div className="col m10 s9 no-margin">
                <div id={'container-enable-voice-message'} className="switch">
                  <label id={'label-enable-voice-message'} key={voiceMessageEnabledValue}>
                    {' '}
                    {/* className="tooltipped" data-position="right" data-delay="50" data-tooltip="Coming back soon" */}
                    <input id={'input-enable-voice-message'} type="checkbox" defaultChecked={voiceMessageEnabledValue} onChange={this.handleEnableVoiceMessage} />{' '}
                    {/* disabled={true} */}
                    <span className="lever no-margin"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className={'row padding-15'}>
              <table className={'bordered highlight  nucleus-table ' + tableResultsView}>
                <thead>
                  <tr>
                    <th className="table-col-20 left-align">
                      <span className="nucleus-table-header-small">Next Delivery</span>
                      <a className={'nucleus-font-medium ' + this.state.sortDeliveryDesc} onClick={this.handleSortDeliveryDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a className={'nucleus-font-medium ' + this.state.sortDeliveryAsc} onClick={this.handleSortDeliveryAsc}>
                        &nbsp;▼
                      </a>
                    </th>
                    <th className="table-col-20 left-align">
                      <span className="nucleus-table-header-small">Recurring</span>
                      <a className={'nucleus-font-medium ' + this.state.sortRecurringDesc} onClick={this.handleSortRecurringDesc}>
                        {' '}
                        &nbsp;▲
                      </a>
                      <a className={'nucleus-font-medium ' + this.state.sortRecurringAsc} onClick={this.handleSortRecurringAsc}>
                        &nbsp;▼
                      </a>
                    </th>
                    <th className="table-col-40 left-align">
                      <span className="nucleus-table-header-small">Message</span>
                    </th>
                    {/* 
                                    <th className="table-col-10 left-align">
                                        <span className="nucleus-table-header-small">Category</span>
                                        <a className={ "nucleus-font-medium " + this.state.sortCategoryDesc } onClick={this.handleSortCategoryDesc}> &nbsp;▲</a>
                                        <a className={ "nucleus-font-medium " + this.state.sortCategoryAsc }  onClick={this.handleSortCategoryAsc} >&nbsp;▼</a>
                                    </th> 
                                    */}
                    <th className="table-col-10 right-align ">
                      <span className="nucleus-table-header"></span>
                    </th>
                  </tr>
                </thead>

                <tbody>{careScheduledMessagesList}</tbody>
              </table>

              <div className={'row center-align ' + tableLoadingView}>
                <br />
                <br />
                <br />
                <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loading} style={{ display: 'inline-flex' }} />
                <br />
                <br />
                <br />
              </div>

              <div className="fixed-action-btn">
                {this.props.patientMessagesEnabled && (
                  <a
                    className={'btn-floating btn-large waves-effect waves-light green tooltipped '}
                    data-position="left"
                    data-delay="50"
                    data-tooltip="New Message"
                    onClick={this.handleNewMessageModal}
                    style={{ marginRight: 12 }}
                  >
                    <i className="material-icons">message</i>
                  </a>
                )}
              </div>
              <br />
              <br />
            </div>
          </div>
        )}

        <div id="modalNewMessage" className="modal modalPatientProfile modalScrollable modalTall">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseNewMessageModal} />
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;{this.state.messageModalMode} Message</h3>
            <a className={'nucleus-font nucleus-font-small nucleus-link ' + this.state.messageModalModeShow} onClick={this.handleMessageModalMode}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className={this.state.messageModalOptionColor}> {this.state.messageModalOptionLabel}</span>
            </a>
            <a className={'nucleus-font nucleus-font-small ' + this.state.messageModalNotEditable}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="txt-red"> {this.state.messageModalNotEditableText} </span>
            </a>
            <br />
            <br />
            <div className={' modallScrollable ' + this.state.messageModeEdit}>
              <div className="row">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Message Type</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <select
                    ref={this.selectMessageType}
                    className="browser-default nucleus-select"
                    disabled={this.state.messageEditableRecurring}
                    onChange={this.handleSelectMessageType}
                  >
                    <option value="1">Plain Text</option>
                    <option value="2">Single Choice</option>
                    <option value="3">Multiple Selection</option>
                    {AuthStore.isLinkMessageEnabled() && <option value="4">Browser Link</option>}
                    {this.state.enableBiometrics && <option value="5">Biometrics</option>}
                    <option value="6">Signature</option>
                  </select>
                  <span className={'nucleus-form-error center-align ' + this.state.messageTypeError}>Please select a Type.</span>
                </div>
                <div className="col s2"></div>
              </div>

              <div className={'row no-margin ' + this.state.regularMessageMode}>
                <span className="nucleus-labels">
                  <b>&nbsp;&nbsp;&nbsp;Message:</b>
                </span>
                <div className="input-field col s12 ">
                  <textarea
                    ref={this.textMessage}
                    className={'nucleus-textarea-medium no-margin-bottom ' + disabledFont}
                    disabled={this.state.messageEditableRecurring}
                    onChange={this.handleMessageText}
                    maxLength="60"
                    placeholder="Type the message here..."
                  ></textarea>
                  <span className={'nucleus-font nucleus-font-14'}> {this.state.messageBodyChars}/60</span> &nbsp;&nbsp;&nbsp;
                  <span className={'nucleus-form-error center-align  ' + this.state.messageBodyError}>{this.state.messageBodyErrorText}</span>
                  <span className={'nucleus-form-error center-align  ' + this.state.messageBodyLengthError}>
                    Message body is too long, please keep it max {this.state.messageBodyLengthErrorChars} characters.
                  </span>
                  <br />
                  <div className="row no-margin"></div>
                </div>
                {this.selectMessageType.current?.value != '1' && <div className="nucleus-news-separator">&nbsp;</div>}
                <div className={'row ' + this.state.messageTemplateVisibility}>
                  <div className="col s4">
                    <span className="nucleus-labels">
                      <b>Response Display Type</b>
                    </span>
                  </div>
                  <div className="col s6 no-margin">
                    <select
                      ref={this.selectMessageTemplate}
                      className="browser-default nucleus-select"
                      disabled={this.state.messageEditable + this.state.messageTemplateEditable}
                      onChange={this.handleSelectMessageTemplate}
                    >
                      <option defaultValue="" disabled>
                        {' '}
                        Select Template{' '}
                      </option>
                      {messageTemplatesList}
                    </select>
                    <span className={'nucleus-form-error center-align ' + this.state.messageTemplateError}>Please select a Template.</span>
                  </div>
                  <div className="col s2"></div>
                </div>

                <div className={'row small-margin-bottom ' + this.state.linkMessageVisibility}>
                  <div className="row no-margin a-bit-lower">
                    <div className="col s2">
                      <span className="nucleus-labels">
                        <b>Link</b>
                      </span>
                    </div>
                    <div className="col s8 no-margin">
                      <input
                        ref={this.txtLinkMessageUrl}
                        type={'url'}
                        className="validate nucleus-textfield-100"
                        placeholder="https://www..."
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleMessageLinkText}
                      />
                      <span className={'nucleus-form-error center-align ' + this.state.messageLinkError}>Please insert the link.</span>
                    </div>
                    <div className="col s2"></div>
                  </div>
                  <div className="row no-margin a-bit-lower">
                    <div className="col s9">
                      <span className="nucleus-labels">
                        <b>Open link on browser on message arrival</b>
                      </span>
                    </div>
                    <div className="col s2 no-margin">
                      <div className="switch no-margin ">
                        <label className="no-margin">
                          <input type="checkbox" ref={this.checkMessageLinkOpen} defaultChecked="" onChange={this.handleMessageLinkOpen} />
                          <span className="lever nucleus-check"></span>
                        </label>
                      </div>
                    </div>
                    <div className="col s1"></div>
                  </div>
                  {!this.state.messageLinkOpen && (
                    <div className="row no-margin a-bit-lower">
                      <div className="col s3">
                        <span className="nucleus-labels">
                          <b>Button Text</b>
                        </span>
                      </div>
                      <div className="col s7 no-margin">
                        <input
                          ref={this.txtLinkMessageButton}
                          type={'text'}
                          className="validate nucleus-textfield-100"
                          placeholder="Press here"
                          disabled={this.state.messageEditableRecurring}
                          onChange={this.handleMessageButtonText}
                        />
                        <span className={'nucleus-form-error center-align ' + this.state.messageButtonError}>Please insert the button text.</span>
                      </div>
                      <div className="col s2"></div>
                    </div>
                  )}
                  {this.state.messageLinkOpen && (
                    <div className="row no-margin a-bit-lower">
                      <div className="col s12"></div>
                    </div>
                  )}
                </div>

                <div className={'row small-margin-bottom ' + this.state.signMessageVisibility}>
                  <div className="row no-margin a-bit-lower">
                    <div className="col s3">
                      <span className="nucleus-labels">
                        <b>Pdf file</b>
                      </span>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        className={'txt-white nucleus-font-small ' + filterBtnMode}
                        onClick={() => {
                          document.getElementById('selectFile').click();
                        }}
                      >
                        Select
                      </a>
                    </div>
                    <div className="col s7 no-margin">
                      <input
                        id="selectFile"
                        type="file"
                        data-multiple-caption={'Select'}
                        multiple={false}
                        onChange={this.handleSelectedFile}
                        accept={'application/pdf'}
                        style={{ display: 'none' }}
                      ></input>

                      <input
                        ref={this.txtSignFileUrl}
                        type={'url'}
                        className="validate nucleus-textfield-100"
                        placeholder="Pdf file name"
                        disabled={true}
                        onChange={this.handleMessageSignText}
                      />

                      <span className={'nucleus-form-error center-align ' + this.state.messageSignError}>Please select pdf file.</span>
                    </div>
                    <div className="col s2">
                      {this.state.selectedPdfFile != null && (
                        <a className={'txt-white nucleus-font-small ' + filterBtnMode} onClick={this.handleSelectedPdfFilePreview}>
                          View
                        </a>
                      )}
                      {this.state.signMessageFileUrl != null && (
                        <a className={'txt-white nucleus-font-small ' + filterBtnMode} onClick={this.handleSelectedPdfFileView}>
                          View
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="row no-margin a-bit-lower">
                    <div className="col s3">
                      <span className="nucleus-labels">
                        <b>Button Text</b>
                      </span>
                    </div>
                    <div className="col s7 no-margin">
                      <input
                        ref={this.txtSignMessageButton}
                        type={'text'}
                        className="validate nucleus-textfield-100"
                        placeholder="Press to read and sign"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleMessageSignButtonText}
                      />
                      <span className={'nucleus-form-error center-align ' + this.state.messageSignButtonError}>Please insert the button text.</span>
                    </div>
                    <div className="col s2"></div>
                  </div>
                </div>
                <div className={'row no-margin ' + this.state.singleChoiceVisibility}>
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>Single Choice Options</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">
                      The message sent to clients will include the options you fill out below. The client will select one option. (Examples: Yes/No, Food Choice, Activity Choice).
                      <br />
                      <b>Enter the appropriate text below. Leave blank if there are less options.</b>
                    </span>
                  </div>
                  <br />
                  <br />
                  <br />

                  <div className="row top-separated">
                    <div className="col s6">
                      <span className="nucleus-labels a-bit-right">
                        <b>1</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtSingleOption1}
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 1 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO1Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>2</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtSingleOption2}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 2 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO2Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>3</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtSingleOption3}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 3 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO3Chars}/15</span>
                      <br />
                      <span className={'nucleus-form-error center-align ' + this.state.messageOptionsError}>Enter at least one option text please.</span>
                      <span className={'nucleus-form-error center-align ' + this.state.messageOptionsMaxError}>Option Text Max length is 15 chars.</span>
                    </div>
                    <div className="col s6">
                      <span className="nucleus-labels">
                        <b>4</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtSingleOption4}
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 4 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO4Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>5</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtSingleOption5}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 5 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO5Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>6</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtSingleOption6}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 6 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO6Chars}/15</span>
                    </div>
                  </div>
                </div>

                <div className={'row no-margin ' + this.state.multipleOptionVisibility}>
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>Multiple Options</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">
                      The message sent to clients will include the options you fill out below. The client will select multiple options. (Examples: Food Choice, Activity Choice).
                      <br />
                      <b>Enter the appropriate text below. Leave blank if there are less options.</b>
                    </span>
                  </div>
                  <br />
                  <br />
                  <br />
                  <div className="row top-separated">
                    <div className="col s6">
                      <span className="nucleus-labels a-bit-right">
                        <b>1</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtMultiOption1}
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 1 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO1Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>2</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtMultiOption2}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 2 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO2Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>3</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtMultiOption3}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 3 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO3Chars}/15</span>
                      <br />
                      <span className={'nucleus-form-error center-align ' + this.state.messageOptionsError}>Enter at least one option text please.</span>
                      <span className={'nucleus-form-error center-align ' + this.state.messageOptionsMaxError}>Option Text Max length is 15 chars.</span>
                    </div>
                    <div className="col s6">
                      <span className="nucleus-labels">
                        <b>4</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtMultiOption4}
                        type="text"
                        className="validate nucleus-textfield"
                        placeholder=" Option 4 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO4Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>5</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtMultiOption5}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 5 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO5Chars}/15</span>
                      <br />
                      <span className="nucleus-labels">
                        <b>6</b>
                      </span>
                      &nbsp;&nbsp;
                      <input
                        ref={this.txtMultiOption6}
                        type="text"
                        className="validate nucleus-textfield top-separated-margin"
                        placeholder=" Option 6 Text"
                        maxLength="15"
                        disabled={this.state.messageEditableRecurring}
                        onChange={this.handleResetOptionsValidationError}
                      />
                      <span className={'nucleus-font nucleus-font-14'}> {this.state.messageO6Chars}/15</span>
                    </div>
                  </div>
                </div>

                <div className={'row no-margin ' + this.state.messageTemplateDetailsVisibiity}>
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>The message screen will display the message text, and the following option to choose from.</b>
                    </span>{' '}
                    <br />
                  </div>
                  <div className={'row top-separated ' + buttonsVisibility}>
                    <div className="col s3"></div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/btn_yes.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Yes</b>
                      </span>
                    </div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/btn_no.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>No</b>
                      </span>
                    </div>
                    <div className="col s3"></div>
                  </div>

                  <div className={'row top-separated ' + thumbsVisibility}>
                    <div className="col s3"></div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/thumb_up.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Thumbs Up</b>
                      </span>
                    </div>
                    <div className="input-field col s3 center-align">
                      <img src="/img/thumb_down.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Thumbs Down</b>
                      </span>
                    </div>
                    <div className="col s3"></div>
                  </div>

                  <div className={'row top-separated ' + iconsVisibility}>
                    <div className="col s3"></div>
                    <div className="input-field col s3 center-align">
                      &nbsp;
                      <img src="/img/accept_btn.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Yes</b>
                      </span>
                    </div>
                    <div className="input-field col s3 center-align">
                      &nbsp;
                      <img src="/img/decline_btn.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>No</b>
                      </span>
                    </div>
                    <div className="col s3"></div>
                  </div>

                  <div className={'row top-separated ' + emojisVisibility}>
                    <div className="input-field col s1">
                      <span className="nucleus-labels">&nbsp;</span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face1.png" />
                      <br />
                      <span className="nucleus-labels">
                        <b>Great</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face2.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>Good</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face3.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>So So</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face4.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>Bad</b>
                      </span>
                    </div>
                    <div className="input-field col s2 center-align">
                      <img src="/img/face5.png" />
                      <br />
                      &nbsp;
                      <span className="nucleus-labels">
                        <b>Awful</b>
                      </span>
                    </div>
                    <div className="input-field col s1">
                      <span className="nucleus-labels">&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className={'row no-margin ' + this.state.biometricMessageMode}>
                <div className="row">
                  <div className="col s4">
                    <span className="nucleus-labels">
                      <b>Biometric Message Type</b>
                    </span>
                  </div>
                  <div className="col s6 no-margin">
                    <select
                      ref={this.selectBiometricMessageType}
                      className="browser-default nucleus-select"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleSelectBiometricMessageType}
                    >
                      {messageBiometricTypesList}
                    </select>
                    <span className={'nucleus-form-error center-align ' + this.state.messageTypeError}>Please select a Type.</span>
                  </div>
                  <div className="col s2"></div>
                </div>

                <div className="row">
                  <div className="input-field col s12 no-margin-top">
                    <span className="nucleus-labels">
                      <b>The client will see the following message text:</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">&nbsp;{this.state.selectedBiometricTypeText}</span>
                    <br />
                    <span className="nucleus-labels">
                      <b>The client will be able to enter the following input field/s:</b>
                    </span>{' '}
                    <br />
                    <span className="nucleus-font nucleus-font-14">&nbsp;{this.state.selectedBiometricTypeInputText}</span>
                    <br />
                    <span className="nucleus-font nucleus-font-14">
                      <b>
                        Note: In order to receive alerts on the patients biometric you will need to configure each patient thresholds in the Patient screen under the Biometrics
                        Alerts Settings tab.
                        <br />
                      </b>
                    </span>
                  </div>
                </div>
              </div>

              <div className={'row no-margin ' + this.state.responseAlertCustomVisibility}>
                <div className="row no-margin">
                  <span className="nucleus-labels">
                    <b>&nbsp;&nbsp;&nbsp;Response Alert</b>
                  </span>{' '}
                  <br />
                  <span className="nucleus-font nucleus-font-14">
                    &nbsp;&nbsp;&nbsp;Check the value(s) that will trigger an alert email if selected by {AuthStore.getPatientLabel().toLowerCase()}
                  </span>
                </div>
                <div className="row no-margin">
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>1</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>2</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>3</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>4</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>5</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    <span className="nucleus-labels">
                      <b>6</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                </div>
                <div className="row no-margin">
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref={this.checkOption1of6} id="checkOption1of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption1of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref={this.checkOption2of6} id="checkOption2of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption2of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref={this.checkOption3of6} id="checkOption3of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption3of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref={this.checkOption4of6} id="checkOption4of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption4of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref={this.checkOption5of6} id="checkOption5of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption5of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <input ref={this.checkOption6of6} id="checkOption6of6" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption6of6" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                </div>
              </div>

              <div className={'row no-margin ' + this.state.responseAlertFacesVisibility}>
                <div className="row no-margin">
                  <span className="nucleus-labels">
                    <b>&nbsp;&nbsp;&nbsp;Response Alert</b>
                  </span>{' '}
                  <br />
                  <span className="nucleus-font nucleus-font-14">
                    &nbsp;&nbsp;&nbsp;Check the value(s) that will trigger an alert email if selected by {AuthStore.getPatientLabel().toLowerCase()}
                  </span>
                </div>

                <div className="row no-margin">
                  <div className="col s1 center-align"></div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Great</b>&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Good</b> &nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>So So</b>&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Bad</b> &nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;
                    <span className="nucleus-labels">
                      <b>Awful</b>&nbsp;&nbsp;&nbsp;
                    </span>
                  </div>
                  <div className="col s1 center-align"></div>
                </div>
                <div className="row no-margin">
                  <div className="col s1 center-align"></div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref={this.checkOption1of5} id="checkOption1of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption1of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref={this.checkOption2of5} id="checkOption2of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption2of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref={this.checkOption3of5} id="checkOption3of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption3of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref={this.checkOption4of5} id="checkOption4of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption4of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s2 center-align">
                    &nbsp;&nbsp;
                    <input ref={this.checkOption5of5} id="checkOption5of5" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption5of5" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s1 center-align"></div>
                </div>
              </div>

              <div className={'row no-margin ' + this.state.responseAlertYesNoVisibility}>
                <div className="row no-margin">
                  <span className="nucleus-labels">
                    <b>&nbsp;&nbsp;&nbsp;Response Alert</b>
                  </span>{' '}
                  <br />
                  <span className="nucleus-font nucleus-font-14">
                    &nbsp;&nbsp;&nbsp;Check the value(s) that will trigger an alert email if selected by {AuthStore.getPatientLabel().toLowerCase()}
                  </span>
                </div>

                <div className="row no-margin">
                  <div className="col s3 center-align"></div>
                  <div className="col s3 center-align">
                    <span className="nucleus-labels">
                      <b>{this.state.responseAlertYesLabel}</b>
                    </span>
                  </div>
                  <div className="col s3 center-align">
                    <span className="nucleus-labels">
                      <b>{this.state.responseAlertNoLabel}</b>{' '}
                    </span>
                  </div>
                  <div className="col s3 center-align"></div>
                </div>
                <div className="row no-margin">
                  <div className="col s3 center-align"></div>
                  <div className="col s3 center-align">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input ref={this.checkOption1of2} id="checkOption1of2" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption1of2" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s3 center-align">
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input ref={this.checkOption2of2} id="checkOption2of2" type="checkbox" className="filled-in" disabled={this.state.messageEditableRecurring} />
                    <label htmlFor="checkOption2of2" className="check-day">
                      &nbsp;
                    </label>
                  </div>
                  <div className="col s3 center-align"></div>
                </div>
              </div>

              <div className="nucleus-news-separator">&nbsp;</div>
              <div className="row small-margin-bottom">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Persistent</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <div className="switch no-margin ">
                    <label className="no-margin">
                      <input
                        type="checkbox"
                        ref={this.checkPersistentState}
                        value={this.state.messagePersistentState}
                        defaultChecked=""
                        onChange={this.handleMessagePersistentState}
                      />
                      <span className="lever nucleus-check"></span>
                    </label>
                  </div>
                </div>
                <div className="col s2"></div>
              </div>
              {!this.state.messagePersistentState && (
                <div className="row no-margin">
                  <div className="col s7">
                    <span className="nucleus-labels">
                      <b>Expiration Time (Hours)</b>
                    </span>
                  </div>
                  <div className="col s5 no-margin">
                    <input
                      ref={this.txtExpirationTimeout}
                      type="number"
                      className="validate nucleus-textfield textbox2 "
                      min="1"
                      step="1"
                      defaultValue="1"
                      placeholder="00"
                      disabled={this.state.messageEditableRecurring}
                    />
                  </div>
                </div>
              )}

              <div className="nucleus-news-separator">&nbsp;</div>
              <div className="row no-margin">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Send now</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <img src={deliveryNowImg} className="nucleus-icon-active" width="24" onClick={this.handleDeliveryImmediate} />
                </div>
                <div className="col s2"></div>
              </div>

              <div className="row no-margin">
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Scheduled Delivery</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <img src={deliveryScheduledImg} className="nucleus-icon-active" width="24" onClick={this.handleDeliveryScheduled} />
                </div>
                <div className="col s2"></div>
              </div>

              <div className={'row ' + deliveryDateTime}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>{this.state.messageDeliveryDateTimeLabel}</b>
                  </span>
                </div>
                <div className="col s8 no-margin left-align">
                  <div style={{ width: 210, display: 'inline-flex' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker
                        //ref={this.messageDateTimeRef}
                        variant="dialog"
                        className="browser-default"
                        value={this.state.messageDateTime}
                        onChange={this.onMessageDateTimeChange}
                        onError={console.log}
                        onClose={() => {}}
                        //minDate={new Date("2018-01-01T00:00")}
                        format={this.datetimeFormat}
                        disabled={this.state.messageEditableRecurring == 'disabled'}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <br />
                  <span className={'nucleus-form-error center-align ' + this.state.messageDeliveryDateError}>
                    Delivery Datetime cannot be earlier than the current date and time or Empty.
                  </span>
                </div>
              </div>

              <div className="nucleus-news-separator">&nbsp;</div>

              <div className="row no-margin">
                <div className="col s7">
                  <span className="nucleus-labels">
                    <b>Escalation Timeout Alert (Minutes)</b>
                  </span>
                </div>
                <div className="col s5 no-margin">
                  <input
                    ref={this.txtNoResponseTimeout}
                    type="number"
                    className="validate nucleus-textfield textbox2 "
                    min="0"
                    step="1"
                    defaultValue="0"
                    placeholder="0"
                    disabled={this.state.messageEditableRecurring}
                  />
                </div>
              </div>

              <div className="nucleus-news-separator">&nbsp;</div>

              <div className="row">
                {this.selectMessageType.current?.value == '6' && (
                  <div className="col s12">
                    <span className="nucleus-font nucleus-font-14">
                      <b>
                        Repeat is disabled for signature messages.
                        <br />
                      </b>
                    </span>
                  </div>
                )}
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Repeat</b>
                  </span>
                </div>
                <div className="col s8 no-margin left-align">
                  <div className="switch no-margin nucleus-switch-inline2">
                    <label className="no-margin">
                      <input type="checkbox" id="checkRepeat" ref={this.checkRepeat} disabled={this.state.messageRepeatStyle} onChange={this.handleMessageRepeat} />
                      <span className="lever"></span>
                    </label>
                  </div>

                  <a
                    className={'txt-white nucleus-font-small ' + this.state.stopRecurringMessageStyle + ' ' + this.state.stopRecurringMessageVisibility}
                    onClick={this.handleStopRecurringMessageModal}
                  >
                    Stop
                  </a>
                </div>
              </div>

              <div className={'row ' + this.state.repeatConfigVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Repeat Every</b>
                  </span>
                </div>
                <div className="col s8 no-margin">
                  <input
                    ref={this.txtRepeatNumber}
                    type="text"
                    className="validate nucleus-textfield textbox2 "
                    defaultValue="1"
                    placeholder="1"
                    disabled={this.state.messageEditableRecurring}
                    onChange={this.handleTxtRepeatEvery}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <select
                    ref={this.selectMessageRecurring}
                    className="browser-default nucleus-select selectx2 a-bit"
                    disabled={this.state.messageEditableRecurring}
                    onChange={this.handleSelectMessageRecurring}
                  >
                    <option value="Day">Day</option>
                    <option value="Week">Week</option>
                    <option value="Month">Month</option>
                    <option value="Year">Year</option>
                  </select>
                  <br />
                  <span className={'nucleus-form-error center-align ' + this.state.messageRepeatEveryError}>Please enter a number.</span>
                </div>
              </div>

              <div className={'row ' + this.state.repeatWeekVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Repeat On</b>
                  </span>
                </div>
                <div className="col s8 no-margin">
                  <span>
                    Su
                    <input
                      ref={this.checkSunday}
                      type="checkbox"
                      className="filled-in"
                      id="checkSunday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkSunday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    M
                    <input
                      ref={this.checkMonday}
                      type="checkbox"
                      className="filled-in"
                      id="checkMonday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkMonday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    T
                    <input
                      ref={this.checkTuesday}
                      type="checkbox"
                      className="filled-in"
                      id="checkTuesday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkTuesday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    W
                    <input
                      ref={this.checkWednesday}
                      type="checkbox"
                      className="filled-in"
                      id="checkWednesday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkWednesday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    Th
                    <input
                      ref={this.checkThursday}
                      type="checkbox"
                      className="filled-in"
                      id="checkThursday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkThursday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    F
                    <input
                      ref={this.checkFriday}
                      type="checkbox"
                      className="filled-in"
                      id="checkFriday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkFriday" className="check-day">
                      &nbsp;
                    </label>
                  </span>
                  <span>
                    S
                    <input
                      ref={this.checkSaturday}
                      type="checkbox"
                      className="filled-in"
                      id="checkSaturday"
                      disabled={this.state.messageEditableRecurring}
                      onChange={this.handleDayCheckChange}
                    />
                    <label htmlFor="checkSaturday" className="check-day">
                      &nbsp;
                    </label>
                  </span>

                  <br />
                  <span className={'nucleus-form-error center-align ' + this.state.messageRepeatDaysError}>Please select at least one day.</span>
                </div>
              </div>

              <div className={'row no-margin ' + this.state.repeatConfigVisibility}>
                <div className="col s12">
                  <span className="nucleus-labels">
                    <b>Ends:</b>
                  </span>
                </div>
              </div>

              <div className={'row no-margin a-bit-lower ' + this.state.repeatConfigVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Never</b>
                  </span>
                </div>
                <div className="col s6 no-margin">
                  <img src={repeatNeverEndsImg} className="nucleus-icon-active" width="24" onClick={this.handleRepeatNeverEnds} />
                </div>
                <div className="col s2"></div>
              </div>

              <div className={'row no-margin ' + this.state.repeatConfigVisibility}>
                <div className="col s4">
                  <span className="nucleus-labels">
                    <b>Ends on</b>
                  </span>
                </div>
                <div className="col s1 no-margin">
                  <img src={repeatEndDateImg} className="nucleus-icon-active" width="24" onClick={this.handleRepeatEndDate} />
                </div>
                <div className="col s7 no-margin">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.messageRepeatEndsDateSection != ' hide' && (
                    <div style={{ width: 210, display: 'inline-flex' }}>
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDateTimePicker
                          //ref={this.messageEndDateTimeRef}
                          variant="dialog"
                          className="browser-default"
                          value={this.state.messageEndDateTime}
                          onChange={this.onMessageEndDateTimeChange}
                          onError={console.log}
                          onClose={() => {}}
                          //minDate={new Date("2018-01-01T00:00")}
                          format={this.datetimeFormat}
                          disabled={this.state.messageEditableRecurring}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  )}
                  <br />
                  <span className={'nucleus-form-error center-align ' + this.state.messageEndDateError + ' ' + this.state.messageRepeatEndsDateSection}>
                    End Repeat Datetime cannot be earlier than the Delivery datetime .
                  </span>
                </div>
              </div>

              <div className="input-field col s12 ">
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.editMessageBtnStyle + this.state.messageSaveNewBtn} onClick={this.handleResumeSaveMessage}>
                      {this.state.messageSaveNewBtnTitle}
                    </a>
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.editMessageBtnStyle + this.state.messageUpdateBtn} onClick={this.handleResumeUpdateMessage}>
                      {this.state.messageSaveNewBtnTitle}
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
            {/*
                        <div className={" " + this.state.messageModeDelete }>
                            <div className="row">
                                <div className="col s12">
                                    <span className="nucleus-labels"> Are you sure you want to delete this message ? </span>
                                </div>
                            </div>

                            <div className="input-field col s12 modalCallLogContent2">
                                <br/>
                                <div className="row no-margin">
                                    <div className="col s12 center-align no-margin">
                                        <p></p>
                                        <span className=" modalSeparator"> <label> &nbsp;</label></span>
                                        <a className={"txt-white nucleus-font-small " + this.state.deleteMessageBtnStyle } onClick={this.handleSubmitDeleteMessage}>Delete</a>
                                        <div>&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        */}
          </div>
        </div>

        <div id="modalNotEditableMessage" className="modal modalPatientProfile modalScrollable modalTall">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;Edit Message</h3>
            <p></p>
            <div className={' '}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> The message is going to be sent, cannot be edited. </span>
                </div>
              </div>

              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-small nucleus-submit-btn'} onClick={this.handleCloseNotEditableModal}>
                      OK
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalNotDeleteMessage" className="modal modalPatientProfile modalScrollable modalTall">
          <div className="modal-content nucleus-modal-content txt-special-color">
            <h3 className="nucleus-page-subtitle"> &nbsp;&nbsp;Delete Message</h3>
            <p></p>
            <div className={' '}>
              <div className="row">
                <div className="col s12">
                  <span className="nucleus-labels"> The Message is scheduled to be sent soon. It is not possible to delete it. </span>
                </div>
              </div>

              <div className="input-field col s12 modalCallLogContent2">
                <br />
                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-small nucleus-submit-btn'} onClick={this.handleCloseNotDeleteModal}>
                      OK
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="modalResumeSaveMessage" className="modal modalPatientProfile modalResumeSaveMessage">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Message Confirmation</h3>
            <p></p>
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                You are about to send a message to {this.state.patientName + this.state.confirmMessageText} <br />
                {this.state.confirmMessageTextLine2} <br />
                {this.state.confirmMessageTextLine3}
              </div>
            </div>
            {!this.state.savingMessage && (
              <div className="row ">
                <div className="col s12 right-align ">
                  <p></p>
                  <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelResumeMessageModal}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleSaveMessage}>
                    Confirm
                  </a>
                </div>
              </div>
            )}
            {this.state.savingMessage && (
              <div className="row">
                <div className="col s12 center-align">
                  <Spinner color="#2096F3" size={34} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />
                </div>
                <div className="col s12 left-align">
                  <div className="col s12 left-align nucleus-font-medium">{this.state.savingMessageText}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div id="modalResumeUpdateMessage" className="modal modalPatientProfile modalResumeUpdateMessage">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Update Message Confirmation</h3>
            <p></p>
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                You are about to edit the message. {/* this.state.patientName + this.state.confirmMessageText */} <br />
                {/* this.state.confirmMessageTextLine2 */} <br />
                {/* this.state.confirmMessageTextLine3 */}
              </div>
            </div>
            {!this.state.savingMessage && (
              <div className="row ">
                <div className="col s12 right-align ">
                  <p></p>
                  <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelResumeUpdateMessageModal}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleUpdateMessage}>
                    Confirm
                  </a>
                </div>
              </div>
            )}
            {this.state.savingMessage && (
              <div className="row">
                <div className="col s12 center-align">
                  <Spinner color="#2096F3" size={34} speed={0.6} animating={true} style={{ display: 'inline-flex' }} />
                </div>
                <div className="col s12 left-align">
                  <div className="left-align nucleus-font-medium">{this.state.savingMessageText}</div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div id="modalDeletePatientMessage" className="modal modalPatientProfile modalScrollableMedium dismissible-modal">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Delete Message</h3>
            <br />
            <br />
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                Are you sure you want to delete this message ?
                <br />
              </div>
            </div>
            <div className="row ">
              <div className="col s12 right-align ">
                <p></p>
                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelDelete}>
                  Cancel
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className={'txt-white nucleus-font-small ' + this.state.deleteMessageBtnStyle} onClick={this.handleSubmitDeleteMessage}>
                  Confirm
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="modalStopRecurringMessage" className="modal modalMessages modalStopRecurringMessage">
          <div className="modal-content modal-content-delete">
            <h3 className="nucleus-page-subtitle"> Stop Recurring Message</h3>
            <p></p>
            <div className="row">
              <div className="col s12 left-align nucleus-font-medium">
                Are you sure you want to stop this message from repeating?
                <br />
              </div>
            </div>
            <div className="row ">
              <div className="col s12 right-align ">
                <p></p>
                <a className="txt-white nucleus-font-small nucleus-link" onClick={this.handleCancelStopRecurringMessageModal}>
                  Cancel
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className="txt-white nucleus-font-small nucleus-submit-btn-small" onClick={this.handleStopRecurringMessage}>
                  Confirm
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PatientMessages;
