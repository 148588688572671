import React from 'react';

import CarePatientStore from '../../../stores/CarePatientStore';

class CallRequestLogItemRow extends React.Component<{
  ID: number;
  RequestTime: string;
  IsEmergency: boolean;
  Status: string;
  HandledTime: string;
  HandlerName: string;
  EscalationCallRequestSent: boolean;
  NumOfRequests: number;
  HasNotes: boolean;
  PatientTimeZone: string;
}> {
  constructor(props) {
    super(props);
    this.state = {
      ID: null,
    };

    this.handleCallRequestLogDetails = this.handleCallRequestLogDetails.bind(this);
    //this.handleNotes = this.handleNotes.bind(this);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  pad2(number) {
    return (number < 10 ? '0' : '') + number;
  }

  handleCallRequestLogDetails() {
    console.log('handleCallRequestLogDetails');

    CarePatientStore.openCallRequestLogDetails({
      ID: this.props.ID,
      RequestDateTime: this.props.RequestTime + ' - ' + this.props.PatientTimeZone,
      Emergency: this.props.IsEmergency,
      Status: this.props.Status,
      HandledDateTime: this.props.HandledTime + ' - ' + this.props.PatientTimeZone,
      HandlerName: this.props.HandlerName,
      Escalated: this.props.EscalationCallRequestSent ? 'Y' : 'N',
      Num: this.props.NumOfRequests,
    });
  }

  render() {
    const requestTypeIndicator = this.props.IsEmergency ? '!' : '';
    const escalatedValue = this.props.EscalationCallRequestSent ? 'Y' : '';

    return (
      <tr onClick={this.handleCallRequestLogDetails}>
        <td className="nucleus-row">{this.props.RequestTime + ' - ' + this.props.PatientTimeZone}</td>
        <td className="nucleus-row center-align">
          <span className={'txt-red nucleus-font-family-big'}>
            <b>{requestTypeIndicator}</b>
          </span>
        </td>
        <td className={'nucleus-row left-align '}>{this.props.Status}</td>
        <td className="nucleus-row left-align">{this.props.HandledTime + ' - ' + this.props.PatientTimeZone}</td>
        <td className="nucleus-row left-align">{this.props.HandlerName}</td>
        <td className="nucleus-row center-align">{escalatedValue}</td>
        <td className="nucleus-row center-align">{this.props.NumOfRequests}</td>
        <td className="nucleus-row right-align padding-b0">
          {this.props.HasNotes ? (
            <span className="no-margin">
              {' '}
              <i className="material-icons txt-green"> done </i> &nbsp;&nbsp;
            </span>
          ) : (
            <a />
          )}
        </td>
      </tr>
    );
  }
}

export default CallRequestLogItemRow;
