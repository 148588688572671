import { startCall } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType, SignalingId } from '@nucleus-care/nucleuscare-connect-signaling';
import { CallingMethods } from 'components/WebRTCCallView';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';
import { UIModal, useModal } from '../../../../components/UI';
import { useNucleusProviders } from '../../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import AuthStore from '../../../../stores/AuthStore';
import { ProviderSchedule } from '../providerSchedule.types';
import { ProviderScheduleRow } from '../ProviderScheduleRow';
import { deleteScheduleForProvider } from '../ScheduleSource';

const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const DateTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  padding-top: 14px;
  width: 60px;
`;

const PatientCount = styled.div<{ $disabled: boolean }>(
  ({ $disabled }) => `
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  color: ${$disabled ? '#FFFFFF' : '#0A313F'};
  background-color: ${$disabled ? '#ACB4BE' : '#82CEFF'};
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex: 1;
`,
);

const Title = styled.p`
  color: #f00;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  margin: 0;
`;

const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 20px;
`;

const Name = styled.p`
  color: #0a313f;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

export const ScheduleByHour = ({ providerSchedule }: { providerSchedule: ProviderSchedule[] }) => {
  const dispatch = useDispatch();
  const { actions } = useNucleusProviders();

  const { closeModal, isOpen, openModal } = useModal();

  const [scheduleToDelete, setScheduleToDelete] = useState<ProviderSchedule>();

  const handleCallStart = (schedule: ProviderSchedule) => {
    const firstName = schedule.FirstName;
    const lastName = schedule.LastName;

    if (!schedule.DeviceID) {
      alert('Can not start call with Patient: ' + schedule.PatientName + ' please assign a default device for the patient');
      return;
    }

    // const callProps: CareCallProps = {
    //   PatientID: schedule.PatientID,
    //   PatientName: schedule.PatientName,
    //   patientFirstName: firstName,
    //   patientLastName: lastName || '',
    //   DeviceID: schedule.DeviceID,
    //   DeviceName: schedule.DeviceName,
    // };

    const patientId = new SignalingId(schedule.PatientID).toString();
    const deviceId = schedule.DeviceID;
    let callType = CallType.Video;

    if (AuthStore.nucleusAutomaticCallRequestBargeIn) {
      callType = CallType.BargeIn;
    }

    const patientName = schedule.PatientName;
    const participants: FullCallEntity[] = [
      {
        devicesIds: [new SignalingId(deviceId).toString()],
        name: patientName,
        entityId: patientId,
        host: false,
        status: 'connected',
        type: callType,
      },
    ];

    const callData: WebRTCCall = {
      requestId: '',
      patientId: patientId,
      callingMethod: CallingMethods.Call,
      participants,
      callerName: AuthStore.getUser().FullName,
      multiCall: false,
      patientFirstName: firstName,
      patientLastName: lastName,
      calleeFirstName: firstName,
      calleeLastName: lastName,
      patientName,
      deviceName: schedule.DeviceName,
      patientThumb: '',
      type: callType,
    };

    dispatch(startCall(callData));
  };

  const scheduleByHour = (() => {
    const scheduleByHour: { [key: string]: ProviderSchedule[] } = {};
    providerSchedule.forEach(item => {
      const hour = moment(item.StartTime).format('HH');
      if (!scheduleByHour[hour]) {
        scheduleByHour[hour] = [];
      }
      scheduleByHour[hour].push(item);
    });
    return scheduleByHour;
  })();

  const confirmDelete = async () => {
    if (!scheduleToDelete) return;

    await deleteScheduleForProvider(scheduleToDelete.ProviderID, scheduleToDelete.ID);
    await actions.fetchScheduleForProvider(scheduleToDelete.ProviderID);
    closeModal();
    setScheduleToDelete(undefined);
  };

  const getFormattedHour = (schedule: ProviderSchedule[], hour: string) => {
    const hourNumericValue = parseInt(hour);

    const normalizedMidnightAndNoon = hourNumericValue === 0 || hourNumericValue === 12 ? '12' : hourNumericValue;
    const amPmIndicator = hourNumericValue >= 12 ? 'PM' : 'AM';
    const isPastHour = moment(schedule[0].StartTime).isBefore(moment(), 'hour');
    return {
      formattedHour: (hourNumericValue > 12 ? hourNumericValue - 12 : normalizedMidnightAndNoon) + ':00',
      amPmIndicator,
      isPastHour,
    };
  };

  const sortedHours = Object.keys(scheduleByHour).sort((a, b) => parseInt(a) - parseInt(b));

  return (
    <Column>
      {sortedHours.map(hour => {
        const schedule = scheduleByHour[hour];
        const sortedSchedule = schedule.sort((a, b) => moment(a.StartTime).diff(moment(b.StartTime)));
        const singleSchedule = sortedSchedule.length === 1;
        const { formattedHour, amPmIndicator, isPastHour } = getFormattedHour(schedule, hour);

        return (
          <Column key={hour}>
            <Row>
              <DateTitle>{formattedHour + ` ${amPmIndicator}`}</DateTitle>
              <Column>
                <PatientCount $disabled={isPastHour}>
                  {sortedSchedule.length} {singleSchedule ? 'appointment' : 'appointments'}
                </PatientCount>
                {sortedSchedule.map(schedule => {
                  return (
                    <ProviderScheduleRow
                      key={schedule.ID}
                      providerSchedule={schedule}
                      canDelete={!isPastHour}
                      onDelete={() => {
                        setScheduleToDelete(schedule);
                        openModal();
                      }}
                      onClick={() => {
                        handleCallStart(schedule);
                      }}
                    />
                  );
                })}
              </Column>
            </Row>
          </Column>
        );
      })}
      <UIModal
        close={closeModal}
        isOpen={isOpen}
        size="large"
        actions={[
          {
            label: 'Cancel',
            onClick: () => closeModal(),
            disabled: false,
            buttonVariant: 'secondary',
          },
          {
            label: 'Proceed',
            onClick: () => confirmDelete(),
            disabled: false,
            buttonVariant: 'primary',
          },
        ]}
      >
        <Title>WARNING!</Title>
        <Note>You are about to delete an appointment scheduled for</Note>
        <Name>{scheduleToDelete?.PatientName}</Name>

        <Name>{moment(scheduleToDelete?.StartTime).format('MM/DD/YYYY - hh:mm A')}</Name>

        <Note>This action is permanent</Note>

        <Note>Would you like to proceed?</Note>
      </UIModal>
    </Column>
  );
};
