import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { UIModal, UIInput, useModal } from '../../../components/UI';
import { ModalAction } from '../../../components/UI/Modals/Base/ActionButtons';
import Message from '../../../utils/Message';
import DeleteGroupCategory from './DeleteGroupCategory';
import { GroupCategory } from './types';

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  category?: GroupCategory | null;
}

const TITLES = {
  add: 'Add a New Group Category',
  edit: 'Edit Group Category',
};

const AddEditGroupCategory: FC<Props> = ({ closeModal, isOpen, category }) => {
  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');
  const [saveCategoryError, setSaveCategoryError] = useState('');
  const [mode, setMode] = useState('add');
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen: isDeleteOpen, openModal: openDeleteModal, closeModal: closeDeleteModal } = useModal();

  useEffect(() => {
    if (titleError) {
      setTitleError('');
    }
  }, [title]);

  useEffect(() => {
    return () => {
      setTitle('');
    };
  }, []);

  useEffect(() => {
    setTitle(category?.name || '');
    if (category) {
      setMode('edit');
    } else {
      setMode('add');
    }
  }, [category]);

  const saveGroup = () => {
    httpApi
      .post('/groupCategories', {
        name: title,
      })
      .then(({ data }) => {
        setIsLoading(false);
        if (data.id) {
          Message.show('New Group Category created');
          handleCloseModal();
        } else {
          Message.show('Error creating new Group Category');
        }
      })
      .catch(e => {
        if (e.response) {
          setSaveCategoryError(e.response.data?.message);
        } else {
          Message.show('Error creating new Group Category');
        }
        setIsLoading(false);
      });
  };

  const editGroup = () => {
    httpApi
      .put(`/groupCategories/${category?.id}`, {
        name: title,
      })
      .then(() => {
        setIsLoading(false);
        Message.show('Group Category edited');
        handleCloseModal();
      })
      .catch(e => {
        setSaveCategoryError(e.response.data?.message);
        setIsLoading(false);
      });
  };

  const validate = () => {
    if (!title) {
      setTitleError('Name is required');
      return false;
    }
    return true;
  };

  const submit = () => {
    if (!validate()) return;

    if (mode === 'add') {
      saveGroup();
    } else {
      editGroup();
    }
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleOpenDeleteModal = () => {
    handleCloseModal();
    openDeleteModal();
  };

  const handleCloseModal = () => {
    closeModal();
    setTitle('');
    setSaveCategoryError('');
  };

  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: handleCloseModal,
        buttonVariant: 'secondary',
      },
    ];

    if (mode === 'edit') {
      data.push(
        {
          label: 'Delete Category',
          onClick: handleOpenDeleteModal,
          buttonVariant: 'danger',
        },
        {
          label: mode === 'edit' ? 'Save' : 'Add',
          onClick: submit,
          disabled: isLoading,
        },
      );
    } else {
      data.push({
        label: mode === 'edit' ? 'Save' : 'Add',
        onClick: submit,
        disabled: isLoading,
      });
    }

    return data;
  };

  const onCloseDeleteModal = () => {
    handleCloseModal();
    closeDeleteModal();
  };

  return (
    <>
      <UIModal size="medium" isOpen={isOpen} close={handleCloseModal} title={TITLES[mode]} actions={getActions()}>
        <InputGroup>
          <UIInput label="Name:" placeholder="Name" value={title} onChange={handleTitleChange} error={titleError || saveCategoryError} />
        </InputGroup>
      </UIModal>
      <DeleteGroupCategory isOpen={isDeleteOpen} closeModal={onCloseDeleteModal} category={category} />
    </>
  );
};

export default AddEditGroupCategory;
