export const EditSVG = ({ width = '15', height = '12', ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.43304 8.9107L8.40402 7.93971L7.1317 6.66739L6.16072 7.63837V8.10712H6.96429V8.9107H7.43304ZM11.1161 2.88391C11.0268 2.79463 10.9347 2.79742 10.8398 2.89228L7.91016 5.82197C7.81529 5.91683 7.8125 6.00891 7.90179 6.0982C7.99107 6.18748 8.08315 6.18469 8.17801 6.08983L11.1077 3.16014C11.2026 3.06527 11.2054 2.9732 11.1161 2.88391ZM11.7857 7.85601V9.44641C11.7857 10.1105 11.5499 10.6783 11.0784 11.1498C10.6069 11.6214 10.0391 11.8571 9.375 11.8571H2.41071C1.74665 11.8571 1.17885 11.6214 0.70731 11.1498C0.23577 10.6783 0 10.1105 0 9.44641V2.48213C0 1.81806 0.23577 1.25026 0.70731 0.778721C1.17885 0.307181 1.74665 0.0714111 2.41071 0.0714111H9.375C9.72656 0.0714111 10.053 0.141166 10.3544 0.280675C10.4381 0.319737 10.4883 0.383911 10.505 0.473197C10.5218 0.568063 10.4967 0.648978 10.4297 0.715942L10.0195 1.1261C9.94141 1.20422 9.85212 1.22654 9.75168 1.19306C9.62333 1.15958 9.49777 1.14284 9.375 1.14284H2.41071C2.04241 1.14284 1.72712 1.27398 1.46484 1.53625C1.20257 1.79853 1.07143 2.11382 1.07143 2.48213V9.44641C1.07143 9.81471 1.20257 10.13 1.46484 10.3923C1.72712 10.6546 2.04241 10.7857 2.41071 10.7857H9.375C9.74331 10.7857 10.0586 10.6546 10.3209 10.3923C10.5831 10.13 10.7143 9.81471 10.7143 9.44641V8.39172C10.7143 8.31918 10.7394 8.25779 10.7896 8.20757L11.3253 7.67186C11.409 7.58815 11.5067 7.56862 11.6183 7.61326C11.7299 7.65791 11.7857 7.73882 11.7857 7.85601ZM10.9821 1.67855L13.3929 4.08927L7.76786 9.71427H5.35714V7.30355L10.9821 1.67855ZM14.6987 2.78346L13.9286 3.55355L11.5179 1.14284L12.2879 0.37275C12.4442 0.2165 12.6339 0.138375 12.8571 0.138375C13.0804 0.138375 13.2701 0.2165 13.4263 0.37275L14.6987 1.64507C14.8549 1.80132 14.933 1.99105 14.933 2.21427C14.933 2.43748 14.8549 2.62721 14.6987 2.78346Z"
        fill="#0092FF"
      />
    </svg>
  );
};

export const RemoveSVG = ({ width = '15', height = '15', ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.125 3.73749C11.0438 3.53124 8.95 3.42499 6.8625 3.42499C5.625 3.42499 4.3875 3.48749 3.15 3.61249L1.875 3.73749"
        stroke="#FF4C3F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3125 3.10625L5.45 2.2875C5.55 1.69375 5.625 1.25 6.68125 1.25H8.31875C9.375 1.25 9.45625 1.71875 9.55 2.29375L9.6875 3.10625"
        stroke="#FF4C3F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7812 5.71252L11.375 12.0063C11.3062 12.9875 11.25 13.75 9.50625 13.75H5.49375C3.75 13.75 3.69375 12.9875 3.625 12.0063L3.21875 5.71252"
        stroke="#FF4C3F"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M6.45605 10.3125H8.5373" stroke="#FF4C3F" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.9375 7.8125H9.0625" stroke="#FF4C3F" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
