import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class UserApi {
  private context: any;
  private authClient: any;

  constructor(context, authClient) {
    this.context = context;
    this.authClient = authClient;
  }

  //<----------------------------------Get User Data-------------------------------->
  GetCareUserData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/GetUserData',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Update User Data----------------------------->
  UpdateCareUserData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateUser',
      data: data,
      callback: callback,
    });
  }

  //<----------------------Update User Data Send BroadcastMessage------------------->
  UpdateUserBroadcastMessage(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSendMessageToAllPatients',
      data: data,
      callback: callback,
    });
  }

  UpdateReceiveEscalation(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateReceiveEscalationCallRequest',
      data: data,
      callback: callback,
    });
  }

  SetStaffAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeStaffTab',
      data: data,
      callback: callback,
    });
  }

  SetClientsAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeClientsTab',
      data: data,
      callback: callback,
    });
  }

  SetQueueAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeQueueTab',
      data: data,
      callback: callback,
    });
  }

  SetMessagesAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeMessageCenterTab',
      data: data,
      callback: callback,
    });
  }

  SetAlertsAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeAlertsTab',
      data: data,
      callback: callback,
    });
  }

  SetMemoryBoxAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeMemoryBoxTab',
      data: data,
      callback: callback,
    });
  }

  SetBulletinBoardAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeBulletinBoardTab',
      data: data,
      callback: callback,
    });
  }

  SetDeletePatientsEnabled(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanDeletePatients',
      data: data,
      callback: callback,
    });
  }

  SetDeleteDevicesEnabled(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanDeleteDevices',
      data: data,
      callback: callback,
    });
  }

  SetBargeInCallsEnabled(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateBargeInCallEnabled',
      data: data,
      callback: callback,
    });
  }

  SetDevicesAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateCanSeeDevicesTab',
      data: data,
      callback: callback,
    });
  }

  SetRestartDevicesAccess(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/UpdateRestartDevicesAccess',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Get All Users Data--------------------------->
  getAllUsersData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/GetUsers',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Set User On Duty--------------------------->
  setUserOnDuty(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/SetOnDuty',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Set User Only Urgent Calls--------------------------->
  SetUserOnlyUrgent(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/SetOnlyUrgent',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Set User Is Admin--------------------------->
  setUserIsAdmin(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/SetIsAdmin',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------Set User Can see all Patients----------------------->
  SetSeeAllPatients(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/CanSeeAllPatients',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Create New User--------------------------->
  CreateNewUser(data, callback) {
    let response;
    this.authClient
      .createUser(data)
      .then((rsp) => {
        const { data } = rsp;
        response = {
          ok: true,
          ...data
        };
      })
      .catch(e => {
        const { data } = e.response;
        switch (data.code) {
          case 1:
            response = {
              ok: false,
              emailRegistered: true,
              telephoneRegistered: false,
            };
            break;
          case 2:
            response = {
              ok: false,
              emailRegistered: false,
              telephoneRegistered: true,
            };
            break;
          default:
            response = {
              ok: false,
              error: data.error || 'Unexpected error',
            };
        }
      })
      .finally(() => callback(response));
    /*
		NucleusNetwork.request({
			context : this.context,
			type : "POST",
			url: Config.domain + "CareUser.asmx/AddUser",
			data : data,
			callback : callback,
		});
    */
  }

  //<---------------------------------Delete User--------------------------------->
  DeleteUser(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/DeleteUser',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------Get Possible Patients--------------------------->
  GetPossiblePatients(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/GetPossiblePatients',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------Assign Patient--------------------------->
  AssignPatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/AddPatientUser',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------Assign Patient--------------------------->
  UnassignPatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/DeletePatientUser',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------Get All Patients Data By User------------------------->
  getAllPatientDataForUser(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CarePatient.asmx/GetPatientsForUser',
      data: data,
      callback: callback,
    });
  }

  //<--------------------------------Request Resend Email----------------------------->
  RequestResendEmail(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareUser.asmx/ReSendWelcomeEmail',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Get Daily Calls By Date --------------------------->
  getDailyCallsByDate(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetDailyCallRequests',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------------Get Daily Calls --------------------------->
  getDailyCalls(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetDailyCallRequests',
      data: data,
      callback: callback,
    });
  }

  //<-----------------------------Get Daily Handled Calls ------------------------>
  getDailyHandledCalls(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetCallAnswered',
      data: data,
      callback: callback,
    });
  }

  //<----------------------------- Get Family Calls ------------------------>
  GetFamilyCalls(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetFamilyCallLogs',
      data: data,
      callback: callback,
    });
  }

  GetMediaItemsPerDayReport(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetMemoryBoxItemsPerDay',
      data: data,
      callback: callback,
    });
  }

  GetFamilyMembers(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetFamilyMembers',
      data: data,
      callback: callback,
    });
  }

  GetMessagesReport(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccountReports.asmx/GetMessagesReport',
      data: data,
      callback: callback,
    });
  }

  //<--------------------------------------Account-------------------------------->
  //<---------------------------------Set Show Call Btn--------------------------->
  SetShowCallBtn(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccount.asmx/ShowCallButton',
      data: data,
      callback: callback,
    });
  }

  //<-------------------------------Set Show CheckIn Btn-------------------------->
  SetShowCheckInBtn(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccount.asmx/ShowCheckInButton',
      data: data,
      callback: callback,
    });
  }

  //<------------------------------Set Show Emergency Btn------------------------->
  SetShowEmergencyBtn(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'CareAccount.asmx/ShowEmergencyButton',
      data: data,
      callback: callback,
    });
  }
}

export default UserApi;
