import React, { useState, useEffect } from 'react';

export const MuteSVG = ({ onClick, defaultValue = false }: { onClick: (active: boolean) => void; defaultValue?: boolean; active?: boolean; size?: number }) => {
  const [Active, setActive] = useState(defaultValue != undefined ? defaultValue : false);

  useEffect(() => {
    setActive(defaultValue);
    //alert("Changed to >" + defaultValue);
  }, [defaultValue]);

  if (Active)
    return (
      <MuteSVGActive
        defaultValue={defaultValue}
        onClick={() => {
          setActive(!Active);
          onClick(!Active);
        }}
      />
    );
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setActive(!Active);
        onClick(!Active);
      }}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="23.5" cy="23.5" r="23" fill="black" fill-opacity="0.2" stroke="#F5F9FF" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0588 35.9409H35.9412V11.0586H11.0588V35.9409Z" fill="#33FF41" fill-opacity="0.01" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="11" width="25" height="25">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.0588 35.9409H35.9412V11.0586H11.0588V35.9409Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.5848 35.568L22.0538 28.8332L22.7049 27.5697L27.6468 17.9762L29.9782 13.4504C30.153 13.111 30.0203 12.6942 29.681 12.5201V12.5194C29.3416 12.3424 28.9241 12.4772 28.75 12.8173L27.1264 15.969C26.4165 14.6938 25.0556 13.825 23.4998 13.825C21.2189 13.825 19.3527 15.6912 19.3527 17.9721V23.5015C19.3527 25.0193 20.1814 26.3498 21.4069 27.0721L20.771 28.3065C19.0997 27.3555 17.9704 25.5619 17.9704 23.5015V22.8103C17.9704 22.4288 17.6614 22.1191 17.2792 22.1191C16.8977 22.1191 16.588 22.4288 16.588 22.8103V23.5015C16.588 26.0968 18.0208 28.3556 20.1365 29.5375L17.3566 34.9349C17.1817 35.2743 17.3144 35.6911 17.6538 35.8659C17.7554 35.9185 17.8632 35.9426 17.9697 35.9426C18.2206 35.9426 18.4625 35.8065 18.5848 35.568ZM27.647 23.5015V20.9946L24.2568 27.576C26.179 27.2166 27.647 25.5239 27.647 23.5015ZM21.4265 33.1783H22.8088V30.3874L23.5076 29.0313C26.5578 29.0264 29.0294 26.5534 29.0294 23.5019V22.8107C29.0294 22.4291 29.339 22.1195 29.7206 22.1195C30.1021 22.1195 30.4117 22.4291 30.4117 22.8107V23.5019C30.4117 27.0856 27.6837 30.0321 24.1912 30.3784V33.1783H25.5735C25.9537 33.1783 26.2647 33.4893 26.2647 33.8695C26.2647 34.251 25.955 34.5607 25.5735 34.5607H21.4265C21.1721 34.5607 20.9585 34.4162 20.839 34.2116L21.3643 33.1908C21.3738 33.1898 21.3828 33.1873 21.3917 33.1847C21.403 33.1815 21.4142 33.1783 21.4265 33.1783Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export const MuteSVGActive = ({ onClick, defaultValue }) => {
  const [Active, setActive] = useState(defaultValue != undefined ? defaultValue : false);
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setActive(!Active);
        onClick(!Active);
      }}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.9854 23.606C46.9854 36.3643 36.582 46.7119 23.7427 46.7119C10.9034 46.7119 0.5 36.3643 0.5 23.606C0.5 10.8476 10.9034 0.5 23.7427 0.5C36.582 0.5 46.9854 10.8476 46.9854 23.606Z"
        fill="white"
        stroke="#0092FF"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.8976 23.0162V21.2422L24.9286 26.9725C26.6405 26.4615 27.8976 24.8787 27.8976 23.0162Z" fill="#0092FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.1491 31.8685H22.3917L21.4842 33.6206C21.5423 33.6324 21.6035 33.6389 21.6652 33.6389H25.8202C26.3116 33.6389 26.7105 33.2423 26.7105 32.7537C26.7105 32.2668 26.3099 31.8685 25.8202 31.8685H24.3363V29.4784C27.6638 29.1798 30.2719 26.4025 30.2719 23.0162V22.4261C30.2719 22.1003 30.006 21.8359 29.6784 21.8359C29.3507 21.8359 29.0848 22.1003 29.0848 22.4261V23.0162C29.0848 25.7829 26.9568 28.0532 24.2395 28.3028L23.1491 30.4073V31.8685Z"
        fill="#0092FF"
      />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="17" y="12" width="14" height="23">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2715 12.9844H17.2134V23.6069V34.2294H30.2715V12.9844V12.9844Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.0821 33.7498L22.3573 29.3591L22.9265 28.2602L23.5088 27.136L30.1728 14.2748C30.2292 14.1663 30.2606 14.0512 30.2695 13.9367C30.2761 13.8476 30.2654 13.7596 30.2458 13.6735C30.2405 13.6493 30.2375 13.6239 30.2298 13.5997C30.1603 13.3843 30.0078 13.1949 29.7893 13.0833V13.0827C29.3519 12.8591 28.8153 13.0296 28.5903 13.4646L26.8749 16.7747C26.1116 15.9019 24.9904 15.3448 23.7427 15.3448C21.4574 15.3448 19.5877 17.2038 19.5877 19.4758V23.0167C19.5877 24.5771 20.4692 25.9421 21.7613 26.6444L21.2159 27.6966C19.5402 26.8008 18.4006 25.0415 18.4006 23.0167V22.4266C18.4006 22.1008 18.1347 21.8364 17.807 21.8364C17.4794 21.8364 17.2134 22.1008 17.2134 22.4266V23.0167C17.2134 25.4983 18.6149 27.6529 20.6727 28.7453L18.4997 32.9395C18.2741 33.3738 18.4457 33.9079 18.8831 34.131C19.0137 34.1983 19.1532 34.2296 19.2903 34.2296C19.6132 34.2296 19.9243 34.0555 20.0821 33.7498Z"
          fill="#0092FF"
        />
      </g>
    </svg>
  );
};

export const SmallMuteSVG = ({ onClick, defaultValue }) => {
  const [Active, setActive] = useState(defaultValue != undefined ? defaultValue : false);
  if (Active)
    return (
      <SmallMuteSVGActive
        defaultValue={defaultValue}
        onClick={() => {
          setActive(!Active);
          onClick(!Active);
        }}
      />
    );
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setActive(!Active);
        onClick(!Active);
      }}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7724 13.9761C27.7724 21.3167 21.7863 27.2724 14.3961 27.2724C7.00584 27.2724 1.01981 21.3167 1.01981 13.9761C1.01981 6.63539 7.00584 0.679688 14.3961 0.679688C21.7863 0.679688 27.7724 6.63539 27.7724 13.9761Z"
        fill="white"
        stroke="#0092FF"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8242 13.6266V12.5898L15.089 15.9389C16.0895 15.6402 16.8242 14.7152 16.8242 13.6266Z" fill="#0092FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0492 18.801H13.6066L13.0762 19.825C13.1102 19.8319 13.1459 19.8357 13.182 19.8357H15.6103C15.8976 19.8357 16.1307 19.6039 16.1307 19.3183C16.1307 19.0338 15.8965 18.801 15.6103 18.801H14.743V17.4041C16.6878 17.2296 18.2121 15.6064 18.2121 13.6273V13.2824C18.2121 13.092 18.0567 12.9375 17.8652 12.9375C17.6737 12.9375 17.5183 13.092 17.5183 13.2824V13.6273C17.5183 15.2443 16.2746 16.5711 14.6865 16.717L14.0492 17.947V18.801Z"
        fill="#0092FF"
      />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="7" width="9" height="14">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2116 7.76563H10.5799V13.9739V20.1822H18.2116V7.76562V7.76563Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2565 19.9018L13.5862 17.3357L13.9189 16.6935L14.2592 16.0364L18.1539 8.51983C18.1869 8.45637 18.2052 8.38911 18.2104 8.3222C18.2143 8.27012 18.208 8.21873 18.1966 8.16837C18.1934 8.15423 18.1917 8.1394 18.1872 8.12526C18.1466 7.99936 18.0575 7.88865 17.9298 7.82346V7.82312C17.6741 7.6924 17.3605 7.79207 17.229 8.04627L16.2265 9.98087C15.7804 9.47075 15.1251 9.14515 14.3959 9.14515C13.0603 9.14515 11.9675 10.2316 11.9675 11.5595V13.629C11.9675 14.5409 12.4827 15.3387 13.2379 15.7491L12.9191 16.3641C11.9398 15.8405 11.2737 14.8124 11.2737 13.629V13.2841C11.2737 13.0937 11.1183 12.9392 10.9268 12.9392C10.7353 12.9392 10.5799 13.0937 10.5799 13.2841V13.629C10.5799 15.0793 11.3989 16.3386 12.6017 16.977L11.3316 19.4283C11.1998 19.6821 11.3001 19.9943 11.5557 20.1247C11.632 20.164 11.7136 20.1823 11.7937 20.1823C11.9824 20.1823 12.1642 20.0805 12.2565 19.9018Z"
          fill="#0092FF"
        />
      </g>
    </svg>
  );
};
export const SmallMuteSVGActive = ({ onClick, defaultValue }) => {
  const [Active, setActive] = useState(defaultValue != undefined ? defaultValue : false);
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setActive(!Active);
        onClick(!Active);
      }}
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.7724 13.9761C27.7724 21.3167 21.7863 27.2724 14.3961 27.2724C7.00584 27.2724 1.01981 21.3167 1.01981 13.9761C1.01981 6.63539 7.00584 0.679688 14.3961 0.679688C21.7863 0.679688 27.7724 6.63539 27.7724 13.9761Z"
        fill="white"
        stroke="#0092FF"
      />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.8242 13.6266V12.5898L15.089 15.9389C16.0895 15.6402 16.8242 14.7152 16.8242 13.6266Z" fill="#0092FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0492 18.801H13.6066L13.0762 19.825C13.1102 19.8319 13.1459 19.8357 13.182 19.8357H15.6103C15.8976 19.8357 16.1307 19.6039 16.1307 19.3183C16.1307 19.0338 15.8965 18.801 15.6103 18.801H14.743V17.4041C16.6878 17.2296 18.2121 15.6064 18.2121 13.6273V13.2824C18.2121 13.092 18.0567 12.9375 17.8652 12.9375C17.6737 12.9375 17.5183 13.092 17.5183 13.2824V13.6273C17.5183 15.2443 16.2746 16.5711 14.6865 16.717L14.0492 17.947V18.801Z"
        fill="#0092FF"
      />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="7" width="9" height="14">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2116 7.76563H10.5799V13.9739V20.1822H18.2116V7.76562V7.76563Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.2565 19.9018L13.5862 17.3357L13.9189 16.6935L14.2592 16.0364L18.1539 8.51983C18.1869 8.45637 18.2052 8.38911 18.2104 8.3222C18.2143 8.27012 18.208 8.21873 18.1966 8.16837C18.1934 8.15423 18.1917 8.1394 18.1872 8.12526C18.1466 7.99936 18.0575 7.88865 17.9298 7.82346V7.82312C17.6741 7.6924 17.3605 7.79207 17.229 8.04627L16.2265 9.98087C15.7804 9.47075 15.1251 9.14515 14.3959 9.14515C13.0603 9.14515 11.9675 10.2316 11.9675 11.5595V13.629C11.9675 14.5409 12.4827 15.3387 13.2379 15.7491L12.9191 16.3641C11.9398 15.8405 11.2737 14.8124 11.2737 13.629V13.2841C11.2737 13.0937 11.1183 12.9392 10.9268 12.9392C10.7353 12.9392 10.5799 13.0937 10.5799 13.2841V13.629C10.5799 15.0793 11.3989 16.3386 12.6017 16.977L11.3316 19.4283C11.1998 19.6821 11.3001 19.9943 11.5557 20.1247C11.632 20.164 11.7136 20.1823 11.7937 20.1823C11.9824 20.1823 12.1642 20.0805 12.2565 19.9018Z"
          fill="#0092FF"
        />
      </g>
    </svg>
  );
};

export const NightVisionSVG = ({ onClick, defaultValue }) => {
  const [Active, setActive] = useState(defaultValue != undefined ? defaultValue : false);
  if (Active)
    return (
      <NightVisionSVGActive
        defaultValue={defaultValue}
        onClick={() => {
          setActive(!Active);
          onClick(!Active);
        }}
      />
    );
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setActive(!Active);
        onClick(!Active);
      }}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.5" cx="23.5" cy="23.5" r="23" fill="black" fill-opacity="0.2" stroke="#F5F9FF" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.1105 23.4424C16.1105 19.3926 19.4239 16.0983 23.4971 16.0983C23.9023 16.0983 24.2976 16.1396 24.6853 16.2025C22.3497 17.7923 20.8147 20.4586 20.8147 23.4857C20.8147 26.4681 22.3047 29.1008 24.5826 30.6983C24.2273 30.7501 23.8664 30.7865 23.4971 30.7865C19.4239 30.7865 16.1105 27.4921 16.1105 23.4424ZM23.4971 14C18.2603 14 14 18.2358 14 23.4424C14 28.649 18.2603 32.8848 23.4971 32.8848C28.7338 32.8848 32.9941 28.649 32.9941 23.4424C32.9941 18.2358 28.7338 14 23.4971 14Z"
        fill="white"
      />
    </svg>
  );
};
export const NightVisionSVGActive = ({ onClick, defaultValue }) => {
  const [Active, setActive] = useState(defaultValue != undefined ? defaultValue : false);
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={() => {
        setActive(!Active);
        onClick(!Active);
      }}
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.9854 23.606C46.9854 36.3643 36.582 46.7119 23.7427 46.7119C10.9034 46.7119 0.5 36.3643 0.5 23.606C0.5 10.8476 10.9034 0.5 23.7427 0.5C36.582 0.5 46.9854 10.8476 46.9854 23.606Z"
        fill="white"
        stroke="#0092FF"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3561 23.6064C16.3561 19.5567 19.6695 16.2624 23.7427 16.2624C24.1479 16.2624 24.5432 16.3036 24.9309 16.3666C22.5953 17.9564 21.0603 20.6227 21.0603 23.6498C21.0603 26.6322 22.5503 29.2649 24.8282 30.8624C24.4729 30.9142 24.112 30.9505 23.7427 30.9505C19.6695 30.9505 16.3561 27.6562 16.3561 23.6064ZM23.7427 14.1641C18.5059 14.1641 14.2456 18.3998 14.2456 23.6064C14.2456 28.813 18.5059 33.0488 23.7427 33.0488C28.9794 33.0488 33.2397 28.813 33.2397 23.6064C33.2397 18.3998 28.9794 14.1641 23.7427 14.1641Z"
        fill="#0092FF"
      />
    </svg>
  );
};
export const EndCallSVG = ({ onClick }: { onClick: () => void; defaultValue?: boolean; active?: boolean; size?: number }) => {
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
      width="48"
      height="47"
      viewBox="0 0 48 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M47.0352 23.5C47.0352 36.4785 36.5136 47 23.5352 47C10.5567 47 0.0351562 36.4785 0.0351562 23.5C0.0351562 10.5215 10.5567 0 23.5352 0C36.5136 0 47.0352 10.5215 47.0352 23.5Z"
        fill="#FD3338"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.5621 33.598L26.0926 17.0381L27.5496 14.2105C27.6055 14.1024 27.6366 13.9878 27.6454 13.8738C27.6507 13.8045 27.6448 13.7358 27.6342 13.6676C27.6178 13.5683 27.5878 13.4714 27.5391 13.3833C27.4703 13.2569 27.3663 13.15 27.2383 13.0684C27.2154 13.0537 27.1948 13.0366 27.1701 13.0237C26.7371 12.801 26.206 12.9708 25.9834 13.4038L24.111 17.0381H15.3103C14.6617 17.0381 14.1353 17.5645 14.1353 18.2131V28.7881C14.1353 29.4373 14.6617 29.9631 15.3103 29.9631H17.4529L15.9959 32.7913C15.7726 33.2237 15.9424 33.7554 16.3754 33.9775C16.5046 34.0445 16.6427 34.0756 16.7784 34.0756C17.098 34.0756 17.4059 33.9023 17.5621 33.598Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.6477 17.0352H27.4145L20.7563 29.9602H27.6477C28.2963 29.9602 28.8227 29.4343 28.8227 28.7852V18.2102C28.8227 17.561 28.2963 17.0352 27.6477 17.0352Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.1976 19.7021L30.9951 20.9835C30.3924 21.2243 29.9976 21.8071 29.9976 22.4557V24.5378C29.9976 25.187 30.3924 25.7698 30.9951 26.0107L34.1976 27.292C34.7187 27.5006 35.2851 27.117 35.2851 26.5553V20.4383C35.2851 19.8772 34.7187 19.4936 34.1976 19.7021Z"
        fill="white"
      />
    </svg>
  );
};

export const ReCallSVG = ({ onClick }: { onClick: () => void; defaultValue?: boolean; active?: boolean; size?: number }) => {
  return (
    <svg
      style={{
        cursor: 'pointer',
      }}
      onClick={onClick}
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M48.4854 24.606C48.4854 37.6429 37.8552 48.2119 24.7427 48.2119C11.6302 48.2119 1 37.6429 1 24.606C1 11.569 11.6302 1 24.7427 1C37.8552 1 48.4854 11.569 48.4854 24.606Z"
        fill="white"
        stroke="#0092FF"
      />
      <path
        d="M32.4967 15.849C30.5329 14.0781 27.9321 13 25.0795 13C18.9604 13 14 17.9604 14 24.0795C14 25.229 14.1751 26.3377 14.5 27.3802"
        stroke="#0092FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M34 13.5V17.5H30" stroke="#0092FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path
        d="M17.5033 32.5299C19.4671 34.3008 22.0679 35.3789 24.9205 35.3789C31.0396 35.3789 36 30.4185 36 24.2994C36 23.1499 35.8249 22.0412 35.5 20.9987"
        stroke="#0092FF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path d="M16 34.8789L16 30.8789L20 30.8789" stroke="#0092FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
