//@ts-nocheck
import React from 'react';

import GeneralStore from '../../../stores/GeneralStore';

class CareFamilyMemberItemRow extends React.Component<{
  MemberID: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Telephone: string;

  ProfileCreated: boolean;
  AccountAutoAnswer: boolean;
  AutoAnswer: boolean;
  Admin: boolean;
  UserImageThumb: string;
  Name: string;
}> {
  constructor(props) {
    super(props);
    this.state = {
      MemberID: this.props.MemberID,
    };
    //console.log(this.state.patientID);
    this.handleRowClicked = this.handleRowClicked.bind(this);
    ////this.handleDeleteAssigment = this.handleDeleteAssigment.bind(this);
  }

  handleRowClicked() {
    GeneralStore.requestOpenFamilyMemberModal({
      MemberID: this.props.MemberID,
      MemberFirstName: this.props.FirstName,
      MemberLastName: this.props.LastName,
      MemberEmail: this.props.Email,
      MemberTelephone: this.props.Telephone,

      MemberProfileCreated: this.props.ProfileCreated,
      AccountAutoAnswer: this.props.AccountAutoAnswer,
      MemberAutoAnswer: this.props.AutoAnswer,
      MemberAdmin: this.props.Admin,
    });
  }

  render() {
    //let receiveUpdates = this.props.ReceiveUpdates ? "Y" : "N";
    const profileCompleted = this.props.ProfileCreated ? 'Active' : 'Pending';

    let telephone = this.props.Telephone;
    if (telephone != null && telephone.length > 18) {
      telephone = telephone.substring(0, 18) + ' ...';
    }

    return (
      <tr onClick={this.handleRowClicked}>
        <td className="nucleus-row">
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            {/* FamilyImageRow */}
            <img
              src={this.props.UserImageThumb ? this.props.UserImageThumb : '/img/user_placeholder.png'}
              style={{ width: 50, height: 50, marginRight: 10, borderRadius: 25, objectFit: 'cover' }}
            />
            {this.props.Name}
          </div>
        </td>
        <td className="nucleus-row">{telephone}</td>
        <td className="nucleus-row">{this.props.Email}</td>

        <td className="nucleus-row center-align">{profileCompleted}</td>
        <td className="nucleus-row center-align">{this.props.Admin ? 'Y' : ''}</td>
      </tr>
    );
  }
}

export default CareFamilyMemberItemRow;
