import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getAccountCallsThunk, reportsSliceClean } from '@nucleus-care/nucleuscare-backend-client';
import { RootState } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/store';
import moment from 'moment';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { CSVLink } from 'react-csv';
import {
  useSelector,
  useDispatch,
  //DefaultRootState
} from 'react-redux';

import { SpinnerCircular } from 'spinners-react';

import AuthStore from '../../stores/AuthStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';

import ReportCallRow from './ReportCallsRow';

const ReportCalls = () => {
  const dispatch = useDispatch();

  const reportStore = useSelector((state: RootState) => {
    //console.log("ReportCalls useSelector", state);
    return state.reports.calls;
  });

  const dateParamFormat = 'YYYY-MM-DD HH:mm:ss';
  const [stateControlTimezoneName, setControlTimezoneName] = useState('');
  const [stateControlTimezoneOffset, setControlTimezoneOffset] = useState(0);
  const [stateFromDate, setStateFromDate] = useState(null);
  const [stateToDate, setStateToDate] = useState(null);

  const [stateLoading, setStateLoading] = useState(false);

  const [stateSearchText, setStateSearchText] = useState('');

  const [stateExportFileName, setStateExportFileName] = useState('NucleusCareCallsReport_');

  const [stateSortingStyles, setStateSortingStyles] = useState({
    startDateAsc: ' nucleus-icon-inactive hide ',
    startDateDesc: ' nucleus-icon-active ',

    // accountAsc : " nucleus-icon-inactive ",
    // accountDesc : " nucleus-icon-inactive hide ",

    // patientNameAsc: " nucleus-icon-inactive ",
    // patientNameDesc : " nucleus-icon-inactive hide ",

    // numberAsc: " nucleus-icon-inactive ",
    // numberDesc: " nucleus-icon-inactive hide ",

    // deviceNameAsc: " nucleus-icon-inactive ",
    // deviceNameDesc : " nucleus-icon-inactive hide ",

    // onlineDateAsc : " nucleus-icon-inactive ",
    // onlineDateDesc : " nucleus-icon-inactive hide ",
  });

  const [stateSortingMode, setStateSortingMode] = useState('startDateDesc');

  const handleSortingMode = (tag, tag2) => {
    console.log('handleSortingMode', tag, tag2);

    const object = {
      startDateAsc: ' nucleus-icon-inactive ',
      startDateDesc: ' nucleus-icon-inactive hide ',

      // accountAsc : " nucleus-icon-inactive ",
      // accountDesc : " nucleus-icon-inactive hide ",

      // patientNameAsc: " nucleus-icon-inactive ",
      // patientNameDesc : " nucleus-icon-inactive hide ",

      // numberAsc: " nucleus-icon-inactive ",
      // numberDesc: " nucleus-icon-inactive hide ",

      // deviceNameAsc: " nucleus-icon-inactive ",
      // deviceNameDesc : " nucleus-icon-inactive hide ",

      // onlineDateAsc : " nucleus-icon-inactive ",
      // onlineDateDesc : " nucleus-icon-inactive hide ",
    };
    object[tag] = ' nucleus-icon-active ';
    object[tag2] = ' nucleus-icon-inactive hide ';
    setStateSortingStyles(object);
    setStateSortingMode(tag);
  };

  const callDataToExportRef = useRef([]);
  callDataToExportRef.current = [];

  const csvFileHeadersRef = useRef([]);
  csvFileHeadersRef.current = [
    { label: 'Date & Time', key: 'startTime' },
    { label: 'Call Type', key: 'callType' },
    { label: 'Status', key: 'status' },
    { label: 'Duration', key: 'duration' },
    { label: 'Participants', key: 'participantsText' },
    { label: 'Emergency', key: 'emergencyText' },
  ];

  useEffect(() => {
    console.log('ReportCalls useEffect');

    validateAccessGranted('/reports/calls');

    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 7);
    const currentDate = new Date();

    setControlTimezoneName(new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]);

    const currentTimezone = (currentDate.getTimezoneOffset() / 60) * -1;
    //console.log("TIMEZONE:", currentTimezone);
    //console.log("TIMEZONE FORMAT:", currentDate.toString().match(/([-\+][0-9]+)\s/)[1]);
    setControlTimezoneOffset(currentTimezone);
    //console.log("CONTROL TIMEZONE:", stateControlTimezoneOffset);

    setStateFromDate(fromDate);
    setStateToDate(currentDate);
    //console.log("AccountID", props.AccountID);
    // dispatch(
    // 	getClassSessionConfig({
    // 		AccountID: props.AccountID
    // 	})
    // );
    refreshReport(fromDate, currentDate);
  }, []);

  useEffect(() => {
    console.log('ReportCalls useEffect reportStore', reportStore);

    if (!reportStore.loading) {
      if (reportStore.complete) {
        //Message.show("Report updated");
        setStateLoading(false);
      }
      dispatch(reportsSliceClean({}));
    } else if (reportStore.error) {
      Message.show('Error in Report');
      dispatch(reportsSliceClean({}));
    }
  }, [reportStore]);

  const onSearchTextChange = textInput => {
    console.log('onSearchTextChange', textInput.target.value);
    setStateSearchText(textInput.target.value);

    // if (textInput.target.value != ""){
    //     setStateExportFileName(stateExportFileName + "_" + textInput.target.value);
    // }
    // else{
    //     setStateExportFileName(stateExportFileName);
    // }
  };

  const onFromDateChange = (date, value) => {
    console.log('onFromDateChange', date, value);
    setStateFromDate(value);
    //refreshReport(value, stateToDate);
  };
  const onToDateChange = (date, value) => {
    console.log('onToDateChange', date, value);
    setStateToDate(value);
    //refreshReport(stateFromDate, value);
  };

  const refreshReport = (fromDate, toDate) => {
    console.log('refreshReport', fromDate, toDate);

    const fromDateValue = new Date(fromDate);
    const toDateValue = new Date(toDate);

    fromDateValue.setHours(0);
    fromDateValue.setMinutes(0);
    fromDateValue.setSeconds(0);
    fromDateValue.setHours(fromDateValue.getHours() + stateControlTimezoneOffset * -1);

    toDateValue.setHours(23);
    toDateValue.setMinutes(59);
    toDateValue.setSeconds(0);
    toDateValue.setHours(toDateValue.getHours() + stateControlTimezoneOffset * -1);

    const fromDateParam = moment(fromDateValue).format(dateParamFormat);
    const toDateParam = moment(toDateValue).format(dateParamFormat);

    console.log('PARAM 1', fromDateParam);
    console.log('PARAM 2', toDateParam);

    setStateLoading(true);
    dispatch(
      getAccountCallsThunk({
        accountId: AuthStore.getUserAccountID(),
        startDate: fromDateParam,
        endDate: toDateParam,
      }),
    );
    const fromExportParam = moment(fromDate).format('MM_DD_YYYY');
    const toExportParam = moment(toDate).format('MM_DD_YYYY');

    const exportCSVFileName = 'NucleusCareCallsReport_' + fromExportParam + '_to_' + toExportParam;
    console.log('ExportCSVFileName', exportCSVFileName);
    setStateExportFileName(exportCSVFileName);
  };

  const toHHMMSS = secs => {
    // var sec_num = parseInt(secs, 10)
    // var hours   = Math.floor(sec_num / 3600)
    // var minutes = Math.floor(sec_num / 60) % 60
    // var seconds = sec_num % 60

    // return [hours,minutes,seconds]
    //     .map(v => v < 10 ? "0" + v : v)
    //     .filter((v,i) => v !== "00" || i > 0)
    //     .join(":")
    let seconds = `${parseInt(secs, 10)}`; // don't forget the second param
    let hours = `${Math.floor(Number(seconds) / 3600)}`;
    let minutes = `${Math.floor((Number(seconds) - Number(hours) * 3600) / 60)}`;
    seconds = (Number(seconds) - Number(hours) * 3600 - Number(minutes) * 60).toString();

    if (Number(hours) < 10) {
      hours = '0' + hours;
    }
    if (Number(minutes) < 10) {
      minutes = '0' + minutes;
    }
    if (Number(seconds) < 10) {
      seconds = '0' + seconds;
    }
    const time = hours + ':' + minutes + ':' + seconds;
    return time;
  };

  const getParticipantsString = (accountStrings, callLogData) => {
    // console.log("getParticipantsString");
    // console.log("getParticipantsString", accountStrings[0]);
    // console.log("getParticipantsString", callLogData);
    let patientLabel = 'Patient: ';
    let userLabel = 'User: ';
    const familyLabel = 'Family: ';
    let instructorLabel = 'Instructor: ';
    if (accountStrings[0]) {
      patientLabel = accountStrings[0].ClientRoleName + ': ';
      userLabel = accountStrings[0].UserRoleName + ': ';
      instructorLabel = accountStrings[0].VideoSessionHostName + ': ';
    }

    let participantsList = '';
    if (callLogData.Participants) {
      Object.entries(callLogData.Participants).forEach(([key, participant]: [key: any, particpant: any]) => {
        if (participant.FamilyMember) {
          participantsList += familyLabel + participant.FamilyMember.FirstName + ' ' + participant.FamilyMember.LastName + ', ';
        } else if (participant.User) {
          if (callLogData.VideoSessionID) {
            participantsList += instructorLabel + participant.User.FirstName + ' ' + participant.User.LastName + ', ';
          } else {
            participantsList += userLabel + participant.User.FirstName + ' ' + participant.User.LastName + ', ';
          }
        } else if (participant.Patient) {
          if (callLogData.VideoSessionID) {
            participantsList += participant.Patient.FirstName + ' ' + participant.Patient.LastName + ', ';
          } else {
            participantsList += patientLabel + participant.Patient.FirstName + ' ' + participant.Patient.LastName + ', ';
          }
        }
      });
    }
    if (participantsList != '') {
      participantsList = participantsList.substring(0, participantsList.length - 2);
    }
    return participantsList;
  };

  const getTableRows = () => {
    console.log('render getTableRows>', reportStore);

    //console.log("#props.classSessions> stateReportRendered", stateReportRendered);
    // if (stateReportRendered){
    //     return;
    // }
    //console.log("props.classSessions>", props.classSessions.length);
    const callsRowArray = [];
    //let array = [];
    if (reportStore && reportStore.CallLogs && reportStore.CallLogs.length > 0) {
      let array = [].concat(reportStore.CallLogs);

      if (stateSortingMode == 'startDateDesc') {
        array = array.sort((a, b) => Number(new Date(b.StartTime)) - Number(new Date(a.StartTime)));
      } else if (stateSortingMode == 'startDateAsc') {
        array = array.sort((a, b) => Number(new Date(a.StartTime)) - Number(new Date(b.StartTime)));
      }

      const outputArray = [];
      callDataToExportRef.current = [];

      let reportObject: any = {};
      let seconds = 0;

      const videoSessionLabel =
        reportStore.AccountStrings && reportStore.AccountStrings[0] && reportStore.AccountStrings[0].VideoSessionRoleName
          ? reportStore.AccountStrings[0].VideoSessionRoleName
          : 'Class Session';

      if (stateSearchText.length == 0) {
        array.map((callRecord, key) => {
          reportObject = {};
          reportObject.startTime = moment(callRecord.StartTime).format('MM/DD/YYYY - hh:mm A') + ' - ' + stateControlTimezoneName;
          reportObject.callType = callRecord.VideoSessionID ? videoSessionLabel : 'Regular';
          if (callRecord.CallRequestID) {
            reportObject.callType = 'Requested';
          }
          reportObject.status = callRecord.Status || '';
          reportObject.wasAnswered = callRecord.WasAnswered;
          seconds = callRecord.DurationSeconds || 0;
          reportObject.duration = toHHMMSS(seconds);
          reportObject.isEmergency = callRecord.IsEmergency;
          reportObject.emergencyText = callRecord.IsEmergency ? 'Yes' : '';

          reportObject.participantsText = getParticipantsString(reportStore.AccountStrings, callRecord);

          outputArray.push(reportObject);
          callsRowArray.push(<ReportCallRow key={key} data={reportObject}></ReportCallRow>);
        });
        callDataToExportRef.current = outputArray;
      } else {
        const text = stateSearchText.toLocaleLowerCase();
        array.map((callRecord, key) => {
          reportObject = {};
          reportObject.startTime = moment(callRecord.StartTime).format('MM/DD/YYYY - hh:mm A') + ' - ' + stateControlTimezoneName;
          reportObject.callType = callRecord.VideoSessionID ? videoSessionLabel : 'Regular';
          if (callRecord.CallRequestID) {
            reportObject.callType = 'Requested';
          }
          reportObject.status = callRecord.Status || '';
          reportObject.wasAnswered = callRecord.WasAnswered;
          seconds = callRecord.DurationSeconds || 0;
          reportObject.duration = toHHMMSS(seconds);
          reportObject.isEmergency = callRecord.IsEmergency;
          reportObject.emergencyText = callRecord.IsEmergency ? 'Yes' : '';

          reportObject.participantsText = getParticipantsString(reportStore.AccountStrings, callRecord);

          //console.log("Creating", reportObject);
          //let identifier = "".concat(callLog.Mac, callLog.HexnodeID);
          if (
            reportObject.participantsText.toLowerCase().indexOf(text) >= 0 ||
            reportObject.callType.toLowerCase().indexOf(text) >= 0 ||
            reportObject.status.toLowerCase().indexOf(text) >= 0
            //(identifier.toLowerCase().indexOf(text) >= 0) ||
          ) {
            outputArray.push(reportObject);
            callsRowArray.push(<ReportCallRow key={key} data={reportObject}></ReportCallRow>);
          }
        });
        callDataToExportRef.current = outputArray;
      }
      //console.log("Generated array", outputArray);
      if (callsRowArray && callsRowArray.length > 0) {
        return <tbody>{callsRowArray}</tbody>;
      } else {
        return (
          <tbody className="center-align ">
            <tr style={{ borderBottomStyle: 'none' }}>
              <td colSpan={7} className="center-align ">
                <br />
                <br />
                <br />
                <span className="nucleus-table-header-medium text-gray"> {`There are no call logs for this search text`} </span>
                <br />
                <br />
                <br />
              </td>
            </tr>
          </tbody>
        );
      }
    }
    return (
      <tbody className="center-align ">
        <tr style={{ borderBottomStyle: 'none' }}>
          <td colSpan={7} className="center-align ">
            <br />
            <br />
            <br />
            <span className="nucleus-table-header-medium text-gray"> {`There are no call logs for this date range`} </span>
            <br />
            <br />
            <br />
          </td>
        </tr>
      </tbody>
    );
  };
  return (
    <div className="nucleus-hard-container">
      <div className="nucleus-all-scrollable-page-medium no-margin">
        <div>
          <p className="nucleus-tools-page-title">
            <b>Report: Account Calls</b>
          </p>
        </div>
        <div style={{ marginTop: 0, marginBottom: 0, height: 56 }}>
          <div className="row a-bit-lower left-align">
            <div className="col s9 m7 no-margin">
              <span className="nucleus-tools-page-text-title a-bit-lower">Search &nbsp;&nbsp;&nbsp;</span>
              <input
                type="text"
                className="nucleus-input-form-small-left"
                style={{ width: 500 }}
                onChange={onSearchTextChange}
                placeholder={'Patient / Participant / Call Type / Status'}
              />
            </div>

            <div className="col s3 m5 no-margin right-align">
              {stateLoading && (
                <div style={{ margin: 0, padding: 0, marginTop: 0, marginBottom: 0 }}>
                  <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row left-align">
          <span className="nucleus-tools-page-text-title a-bit-lower" style={{ marginRight: 28 }}>
            From
          </span>
          <div style={{ width: 140, display: 'inline-flex' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                //ref={fromDateTimePickerRef}
                variant="dialog"
                className="browser-default"
                value={stateFromDate}
                onChange={onFromDateChange}
                onError={console.log}
                onClose={() => {}}
                autoOk={true}
                //minDate={new Date("2018-01-01T00:00")}
                format={'MM/DD/YYYY'}
              />
            </MuiPickersUtilsProvider>
          </div>
          <span className="nucleus-tools-page-text-title a-bit-lower" style={{ marginLeft: 44, marginRight: 16 }}>
            To
          </span>
          <div style={{ width: 140, display: 'inline-flex' }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                //ref={toDateTimePickerRef}
                variant="dialog"
                className="browser-default"
                value={stateToDate}
                onChange={onToDateChange}
                onError={console.log}
                autoOk={true}
                //onClose={()=>{console.log("onClose!");window.focus();}}
                //minDate={new Date("2018-01-01T00:00")}
                minDate={new Date(stateFromDate).setDate(new Date(stateFromDate).getDate() + 1)}
                format={'MM/DD/YYYY'}
              />
            </MuiPickersUtilsProvider>
          </div>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <a
            className="txt-white nucleus-font-small nucleus-submit-btn-small"
            onClick={() => {
              refreshReport(stateFromDate, stateToDate);
            }}
          >
            {' '}
            Refresh{' '}
          </a>
        </div>

        <div className="users-table-pending altoImportantContainer">
          <table className="bordered highlight nucleus-table">
            <thead>
              <tr>
                <th className="table-col-22">
                  <span className="nucleus-table-header-medium ">{'Date & Time'}</span>
                  <a className={'nucleus-font-small ' + stateSortingStyles.startDateDesc} onClick={() => handleSortingMode('startDateAsc', 'startDateDesc')}>
                    {' '}
                    &nbsp;▼
                  </a>
                  <a className={'nucleus-font-small ' + stateSortingStyles.startDateAsc} onClick={() => handleSortingMode('startDateDesc', 'startDateAsc')}>
                    &nbsp;▲
                  </a>
                </th>
                <th className="table-col-10 left-align">
                  <span className="nucleus-table-header-medium">{`Call Type`}</span>
                  {/* <a class={ "nucleus-font-small " + stateSortingStyles.nameDesc } onClick={()=>handleSortingMode("nameAsc","nameDesc")}> &nbsp;▼</a>
                                    <a class={ "nucleus-font-small " + stateSortingStyles.nameAsc  } onClick={()=>handleSortingMode("nameDesc","nameAsc")} >&nbsp;▲</a> */}
                </th>
                <th className="table-col-10 left-align">
                  <span className="nucleus-table-header-medium">{'Status'}</span>
                  {/* <a class={ "nucleus-font-small " + stateSortingStyles.numberDesc } onClick={()=>handleSortingMode("numberAsc","numberDesc")}> &nbsp;▼</a>
                                    <a class={ "nucleus-font-small " + stateSortingStyles.numberAsc }  onClick={()=>handleSortingMode("numberDesc","numberAsc")} >&nbsp;▲</a> */}
                </th>
                <th className="table-col-10 left-align">
                  <span className="nucleus-table-header-medium">{'Duration'}</span>
                  {/* <a class={ "nucleus-font-small " + stateSortingStyles.dateDesc }  onClick={()=>handleSortingMode("dateAsc","dateDesc")}> &nbsp;▼</a>
                                    <a class={ "nucleus-font-small " + stateSortingStyles.dateAsc }   onClick={()=>handleSortingMode("dateDesc","dateAsc")} >&nbsp;▲</a> */}
                </th>
                <th className="table-col-38 left-align">
                  <span className="nucleus-table-header-medium">{'Participants'}</span>
                  {/* <a class={ "nucleus-font-small " + this.state.sortSenderDesc } onClick={this.handleSortSenderDesc}> &nbsp;▲</a>
                                    <a class={ "nucleus-font-small " + this.state.sortSenderAsc }  onClick={this.handleSortSenderAsc} >&nbsp;▼</a> */}
                </th>
                <th className="table-col-10 center-align">
                  <span className="nucleus-table-header-medium">{'Emergency'}</span>
                  {/* <a class={ "nucleus-font-small " + this.state.sortSenderDesc } onClick={this.handleSortSenderDesc}> &nbsp;▲</a>
                                    <a class={ "nucleus-font-small " + this.state.sortSenderAsc }  onClick={this.handleSortSenderAsc} >&nbsp;▼</a> */}
                </th>
              </tr>
            </thead>

            {getTableRows()}
          </table>
        </div>
        <div className="fixed-action-btn">
          {callDataToExportRef.current && callDataToExportRef.current.length > 0 && (
            <CSVLink headers={csvFileHeadersRef.current} data={callDataToExportRef.current} filename={stateExportFileName + '.csv'} target="_blank">
              <a
                className={'btn-floating txt-white nucleus-font-small  nucleus-submit-btn-small'}
                style={{ width: 120, height: 35, textAlign: 'center', justifyContent: 'center', padding: 0 }}
              >
                Export to CSV
              </a>
            </CSVLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportCalls;
