import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';

type NotifyCall = {
  userId: string;
  entityId: string;
  requestCallLogId: string;
  callLogId: string;
  callType: 'Video' | 'Audio';
};

export const CallApi = {
  notifyCall: async (callRequest: NotifyCall) => {
    return httpNodeApi.post('v2/call/notify', callRequest);
  },
  cancelCall: async (callRequest: NotifyCall) => {
    return httpNodeApi.post('v2/call/notify-cancel', callRequest);
  },
};
