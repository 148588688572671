import React, { FC, useState, useMemo } from 'react';
import styled from 'styled-components';
import { Patient } from '../../../components/PatientsSelector/usePatientsSelector';
import authStore from '../../../stores/AuthStore';
import SearchImg from '../assets/search-icon.svg';
import GroupContainer from './GroupContainer';
import { Group } from './types';

type Props = {
  group?: Group;
  buttonOnClick: () => void;
  patients?: Patient[];
  removePatientFromGroup?: (patientId: string) => void;
  containerStyle?: React.CSSProperties;
  title: string;
};

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 20px;
  cursor: pointer;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 24px;
`;

const Name = styled.p`
  font-size: 16px;
  color: #0a313f;
  margin: 0;
  margin-left: 10px;
`;

const RemoveText = styled.p`
  color: #ff4c3f;
  font-size: 10px;
  margin: 0;
`;

const Content = styled.div`
  padding: 10px 20px 0px 20px;
  display: flex;
  align-items: center;
`;

const SearchIcon = styled.img`
  width: 22px;
  height: 19px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

const PatientList: FC<Props> = ({ patients, buttonOnClick, removePatientFromGroup, containerStyle, title }) => {
  const [searchText, setSearchText] = useState('');

  const searchEnabled = useMemo(() => {
    if (!patients) return false;
    return patients?.length > 1;
  }, [patients]);

  const handleSearch = e => {
    setSearchText(e.target.value);
  };

  const filteredData = useMemo(
    () => patients?.filter(item => item.firstName?.toLowerCase().includes(searchText?.toLowerCase()) || item.lastName?.toLowerCase().includes(searchText?.toLowerCase())),
    [patients, searchText],
  );

  const getTitle = () => {
    if (patients?.length === 0 || !patients) {
      return '0 ' + authStore.getPatientLabelPlural();
    }
    if (patients?.length === 1) {
      return '1 ' + authStore.getPatientLabel();
    }
    return `${patients?.length} ` + authStore.getPatientLabelPlural();
  };

  const renderSearchInput = () => {
    if (!searchEnabled) return null;

    return (
      <Content>
        <SearchIcon src={SearchImg} width="28" alt="Search" />
        <input value={searchText} type="text" placeholder={`Search by ${title} name`} onChange={handleSearch} />
      </Content>
    );
  };

  return (
    <GroupContainer title={getTitle()} buttonLabel={''} buttonOnClick={buttonOnClick} containerStyle={containerStyle}>
      {renderSearchInput()}
      {filteredData?.map(patient => {
        return (
          <Item key={patient.id}>
            <Left>
              <Avatar src={patient.thumbnailUrl || '/img/user_placeholder.png'} alt={patient.firstName} />
              <Name>
                {patient.firstName} {patient.lastName}
              </Name>
            </Left>
            {removePatientFromGroup && <RemoveText onClick={() => removePatientFromGroup(patient.id)}>remove</RemoveText>}
          </Item>
        );
      })}
    </GroupContainer>
  );
};

export default PatientList;
