import { I18n } from 'i18n-js';
import en from './en.json';

const i18n = new I18n({
  en,
  'en-MX': en,
  'en-US': en,
});
// Set the key-value pairs for the different languages you want to support.

const defaultLocale = 'en';
i18n.defaultLocale = defaultLocale;
i18n.fallbacks = true;
// Set the locale once at the beginning of your app.
i18n.locale = defaultLocale; //Localization.locale;

i18n.getLang = () => {
  return i18n.locale.substring(0, 2);
};
export default i18n;
