import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { connect } from 'react-redux';
import AuthStore from '../../stores/AuthStore';
import Message from '../../utils/Message';

import ResetPasswordForm from './ResetPasswordForm';

class ResetPassword extends React.Component<
  {
    match: any;
    history: any;
    dispatch?: any;
  },
  {
    btnStyle: string;
    errorView1: string;
    errorText: string;
  }
> {
  constructor() {
    super();
    this.state = {
      btnStyle: ' nucleus-submit-btn-disabled',
      errorView1: ' hide',
      errorText: '',
    };

    this.onResetPasswordAction = this.onResetPasswordAction.bind(this);
    //this.validateFields = this.validateFields.bind(this);
    //this.validateFieldsFromKeyboard = this.validateFieldsFromKeyboard.bind(this);
    //this.validateTxtInput = this.validateTxtInput.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    //Register Listener
    AuthStore.on('onResetPasswordAction', this.onResetPasswordAction);

    AuthStore.justLogout(dispatch);
    console.log('URL PARAMS');
    console.log(match.params.email);
    console.log(match.params.token);
  }

  componentWillUnmount() {
    //Remove Listener
    AuthStore.removeListener('onResetPasswordAction', this.onResetPasswordAction);
  }

  resetPassword = newPassword => {
    const { match } = this.props;

    console.log('match.params.email', match.params.email);
    console.log('match.params.token', match.params.token);
    console.log('Password', newPassword);

    AuthStore.resetPassword2({
      Email: match.params.email,
      Token: match.params.token,
      Password: newPassword,
    });
  };

  onResetPasswordAction(response) {
    console.log('onResetPasswordAction');
    console.log(response);

    if (response.ok) {
      Message.show('Password reset complete.\nYou can login now.');
      this.props.history.push('/login');
    } else {
      Message.show('Error on reset your password please, try again.');
      // this.setState({
      //     errorView1 : " ",
      //     errorText : "Error on reset your password please, try again."
      // });
    }
  }



  render() {
    const style = {
      height: window.innerHeight,
      background: '#008FFF',
      overflowY: 'auto',
    };

    return (
      <div className="nucleus-login" style={style}>
        <div className="alto valign-wrapper" style={{ height: 760 }}>
          <div className="row ancho">
            <div className="col s2 m3 l3 border"></div>
            <div className="col s8 m6 l6 border center-align no-padding">
              <img className="responsive-img" src="/img/nucleus_2024_logo.png" />
              <br />
              <br />
              <p className="txt-white nucleus-page-subtitle">Password Reset</p>
              <br />
              <br />
              <ResetPasswordForm
                onSubmitForm={this.resetPassword}
                userData={{
                  firstName: AuthStore.getUserFirstName(),
                  lastName: AuthStore.getUserLastName(),
                  email: AuthStore.getUserEmail(),
                }}
              ></ResetPasswordForm>
            </div>
            <div className="col s2 m3 l3 border"></div>
          </div>
        </div>
      </div>
    );
  }
}

function dispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

const ResetPasswordFC = props => {
  return useReactRouterProps(ResetPassword, props);
};
export default connect(null, dispatchToProps)(ResetPasswordFC);
