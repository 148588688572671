//@ts-nocheck
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { GeneralStore, CareAlertsStore } from '../../stores';
class AlertHandledItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      AlertID: this.props.AlertID,
      patientID: this.props.PatientID,
      patientName: this.props.PatientName,
      cancelIconVisibility: ' nucleus-link',
      //patientName:this.props.FirstName + ' ' + this.props.LastName
    };

    this.handleRowClicked = this.handleRowClicked.bind(this);
    this.handlePatientClicked = this.handlePatientClicked.bind(this);

    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
    //this.dateOptions.timeZone = 'UTC';
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit', timeZone: 'UTC' };
    //this.timeOptions.timeZone = 'UTC';
    this.alertDateAndTime = '';
    this.alertHandledDateAndTime = '';
  }

  componentDidMount() {
    //Register Listener
    //DEPRECATED APRIL 12th, 2018, GET INFO FROM THE PATIENT. Data is now included in the response.
    /*
        console.log("QUEUE ITEM ROW: ", this.state.patientID);
        let patientInfo = CarePatientStore.getPatientInfo(this.props.PatientID);
        //console.log("ITEM QUEUE: ", patientInfo);
        if (patientInfo){
            this.setState({
                patientName : patientInfo.Name,
                patientTelephone: patientInfo.Telephone,
                patientAddress: patientInfo.Address
            });
        }
        */
    //console.log(this.state.patientID);
  }

  componentWillUnmount() {
    //Remove Listener
  }

  handleRowClicked() {
    CareAlertsStore.openHandledAlertDetails({
      AlertID: this.props.AlertID,
      PatientID: this.props.PatientID,
      PatientName: this.props.PatientName,
      AlertOccurredOn: this.alertDateAndTime,
      AlertDescription: this.props.AlertDescription,
      AlertActionsTaken: this.props.AlertHandledActionsTaken,
    });
  }

  handlePatientClicked() {
    GeneralStore.setPageBeforePatient('alerts');
    this.props.history.push('/patientProfile/' + this.props.PatientID + '/null');
  }

  render() {
    /*
        let dateStrings = ''; // (new Date(this.props.RequestTime)).toString();
        //console.log("Date", (new Date(this.props.AlertOccurredOn)).toString());

        //let requestDateString = new Date(this.props.RequestTime).toDateString() + " " + new Date(this.props.RequestTime).toTimeString();
        let alertDateTime = new Date(this.props.AlertOccurredOn); //).toUTCString();
        //alertDateTime.setHours(alertDateTime.getHours() + this.props.AlertPatientTimeZone);
        alertDateTime = alertDateTime.toLocaleDateString('en-US', this.dateOptions) + " - " + alertDateTime.toLocaleTimeString('en-US', this.timeOptions) + " - " + this.props.AlertPatientTimeZone;

        this.alertDateAndTime  = alertDateTime;

        let alertHandledDateTime = new Date(this.props.AlertHandledOn); //).toUTCString();
        //alertDateTime.setHours(alertDateTime.getHours() + this.props.AlertPatientTimeZone);
        alertHandledDateTime = alertHandledDateTime.toLocaleDateString('en-US', this.dateOptions) + " - " + alertHandledDateTime.toLocaleTimeString('en-US', this.timeOptions) + " - " + this.props.AlertPatientTimeZone;

        this.alertHandledDateAndTime  = alertHandledDateTime;
        */

    /*
        let cleanDescription = this.props.AlertDescription;
        let position = cleanDescription.search("This");
        if (position > -1){
            cleanDescription = cleanDescription.substring(position);
        }
        position = cleanDescription.search("<br");
        if (position > -1){
            cleanDescription = cleanDescription.substring(0,position);
        }
        */

    let shortDescription = this.props.AlertDescription;
    if (shortDescription != null && shortDescription.length > 85) {
      shortDescription = shortDescription.substring(0, 85) + ' ...';
    }

    return (
      <tr className="nucleus-row" onClick={this.handleRowClicked}>
        <td className="">{this.props.AlertOccurredOn + ' - ' + this.props.AlertPatientTimeZone}</td>
        <td className="">{this.props.PatientName}</td>
        <td className="">{this.props.AlertHandledOn + ' - ' + this.props.AlertPatientTimeZone}</td>
        <td className="">{this.props.AlertHandledByName}</td>
        <td className="" height="43">
          <span className="nucleus-link-light">{this.props.AlertDescription}</span>
        </td>
      </tr>
    );
  }
}

export default props => useReactRouterProps(AlertHandledItemRow, props);
