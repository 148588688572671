import { startCall } from '@nucleus-care/nucleuscare-backend-client';
import { FullCallEntity, WebRTCCall } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType } from '@nucleus-care/nucleuscare-connect-signaling';
import { CallingMethods } from 'components/WebRTCCallView';
import moment from 'moment-timezone';
import { useEffect, useState, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { NucleusControlledSelect } from '../../../components/NucleusControlledInput';
import { UIModal, UINucleusContainer } from '../../../components/UI';
import { UICard } from '../../../components/UI';
import { UISmallButton } from '../../../components/UI';
import { UINucleusContentContainer } from '../../../components/UI/NucleusContainer/Content';
import AuthStore from '../../../stores/AuthStore';

import Message from '../../../utils/Message';
import { getCallLogDataCatalogs, getCallLogData, updateCallLogData } from '../../../utils/providerApi';
import type { tUpdateCallLog } from '../../../utils/providerApi';
import { getProviderStrings } from '../utils/providerApi';

export type tProviderCallLogData = {
  ID: string;
  CallRequestID?: string;
  StartTime: string;
  EndTime?: string;
  WasAnswered: boolean;
  Notes?: string | null;
  AccountID: string;
  Status: string;
  IsHipaa: boolean;
  UserID?: string;
  UserName?: string;
  Participant?: string | null;
  RequestedByUserID?: string | null;
};

export type tCallLogCatalog = {
  ID: string;
  ProviderID: string;
  Name: string;
};

export type tCallLogCatalogs = {
  providerCallReasonData: tCallLogCatalog[];
  providerCallTypesData: tCallLogCatalog[];
  providerCallOutcomeData: tCallLogCatalog[];
};

interface Props {
  openModal: () => void;
  closeModal: () => void;
  isOpen: boolean;
  callLogId?: string | null;
}

const PostCallDataModal: FC<Props> = ({ openModal, closeModal, isOpen, callLogId }) => {
  const dispatch = useDispatch();

  const [callLogData, setcallLogData] = useState<tProviderCallLogData | null>(null);
  const [callLogParticipants, setcallLogParticipants] = useState<string[]>([]);
  const [callLogCatalogs, setCallLogCatalogs] = useState<tCallLogCatalogs>({ providerCallReasonData: [], providerCallTypesData: [], providerCallOutcomeData: [] });

  const [callLogNotes, setCallLogNotes] = useState<string>('');

  const providerId = AuthStore.getProviderID();
  const [providerRoleName, setProviderRoleName] = useState<string>('');

  const {
    register,
    formState: { errors },
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      ReasonForAppointment: '',
      AppointmentType: null,
      AppointmentOutcome: '',
    },
  });

  useEffect(() => {
    console.log('USE EFFECT =>', callLogCatalogs.providerCallReasonData[0]);
    if (!callLogCatalogs.providerCallReasonData[0] && providerId) {
      fetchCallLogCatalogs(providerId);
    }
    return () => {};
  }, []);

  useEffect(() => {
    console.log('useEffect callLogId', callLogId);
    if (callLogId && providerId) {
      console.log('useEffect got callLogId ==>', callLogId);
      fetchCallLogData(providerId, callLogId);
    }

    if (!callLogCatalogs.providerCallReasonData[0] && providerId) {
      fetchCallLogCatalogs(providerId);
    }
  }, [callLogId]);

  useEffect(() => {
    async function fetchProviderStrings() {
      if (!providerId) return;
      const { UserRoleName } = await getProviderStrings(providerId);
      setProviderRoleName(UserRoleName);
    }
    fetchProviderStrings();
  }, [providerId]);

  const fetchCallLogCatalogs = async (providerId: string) => {
    console.log('fetchCallLogCatalogs', providerId);
    const responseData = await getCallLogDataCatalogs(providerId);
    setCallLogCatalogs({
      providerCallReasonData: responseData?.providerCallReasonData ?? [],
      providerCallTypesData: responseData?.providerCallTypesData ?? [],
      providerCallOutcomeData: responseData?.providerCallOutcomeData ?? [],
    });
  };

  const fetchCallLogData = async (providerId: string, callLogId: string) => {
    console.log('fetchCallLogData providerId callLogId', providerId, callLogId);
    const responseData = await getCallLogData(providerId, callLogId);
    if (responseData?.callLogData?.WasAnswered) {
      openModal();
    } else {
      cleanAndClose();
      return;
    }
    if (responseData?.callLogData) {
      setcallLogData(responseData?.callLogData);
      if (responseData?.callLogData?.ProviderCallReasonID) {
        setValue('ReasonForAppointment', responseData?.callLogData?.ProviderCallReasonID);
      }
    }
    if (responseData?.callLogParticipants) {
      setcallLogParticipants(responseData?.callLogParticipants);
    }
  };

  const updatePostCallLogData = async () => {
    if (!providerId) return;
    if (!callLogId) return;
    console.log('ReasonForAppointment ->', watch('ReasonForAppointment'));
    if (!watch('ReasonForAppointment')) {
      setError('ReasonForAppointment', {
        type: 'required',
        message: 'Please select a reason',
      });
      return;
    }

    console.log('AppointmentType ->', watch('AppointmentType'));

    console.log('AppointmentOutcome ->', watch('AppointmentOutcome'));
    if (!watch('AppointmentOutcome')) {
      setError('AppointmentOutcome', {
        type: 'required',
        message: 'Please select the outcome',
      });
      return;
    }

    console.log('callLogNotes -->', callLogNotes);

    const updateCallLogInfo: tUpdateCallLog = {
      Notes: callLogNotes,
      CareProviderCallReasonID: watch('ReasonForAppointment'),
      CareProviderCallTypeID: watch('AppointmentType'),
      CareProviderCallOutcomeID: watch('AppointmentOutcome'),
    };
    const response = await updateCallLogData(providerId, callLogId, updateCallLogInfo);
    if (response?.callLogId) {
      cleanAndClose();
      Message.show('Call data updated');
    }
  };

  const onRepeatCall = () => {
    console.log('onRepeatCall');
    if (callLogData?.Participant) {
      const callParticipant = JSON.parse(callLogData.Participant);
      const patientId = callParticipant.PatientID.toLowerCase();
      const patientName = callParticipant.FirstName + ' ' + callParticipant.LastName;
      const deviceId = callParticipant.DeviceID.toLowerCase();
      const entityId = (callLogData.RequestedByUserID ? callLogData.RequestedByUserID : patientId).toLowerCase();
      const callType = AuthStore.nucleusAutomaticCallRequestBargeIn ? CallType.BargeIn : CallType.Video;
      const participants: FullCallEntity[] = [
        {
          devicesIds: [deviceId],
          name: patientName,
          entityId,
          host: false,
          status: 'connected',
          type: callType,
        },
      ];

      const callData: WebRTCCall = {
        requestId: callLogData.CallRequestID || '',
        patientId: patientId,
        entityId,
        callingMethod: CallingMethods.Notify,
        participants,
        multiCall: false,
        patientFirstName: callParticipant.FirstName,
        patientLastName: callParticipant.LastName,
        calleeFirstName: callParticipant.FirstName,
        calleeLastName: callParticipant.LastName,
        patientName,
        deviceName: callParticipant.DeviceName || null,
        patientThumb: '',
        callerName: `${providerRoleName || 'Doctor'} is calling you`,
        type: callType,
      };
      console.log('PostCallLogModal callData', callData);
      cleanAndClose();
      dispatch(startCall(callData));
    }
  };

  const cleanAndClose = () => {
    setValue('ReasonForAppointment', '');
    setValue('AppointmentType', null);
    setValue('AppointmentOutcome', '');
    setCallLogNotes('');
    closeModal();
  };

  return (
    <UIModal
      close={closeModal}
      isOpen={isOpen}
      size="mediumLarge"
      title="Post Call Data"
      dismissable={false}
      actions={[
        {
          label: 'Complete',
          onClick: () => updatePostCallLogData(),
          disabled: false,
          buttonVariant: 'primary',
        },
      ]}
    >
      <UINucleusContainer style={{ overflowY: 'auto', maxHeight: '80vh', paddingLeft: 5, paddingRight: 10 }}>
        <UINucleusContentContainer style={{ paddingBottom: 6 }}>
          <UICard
            style={{
              width: '100%',
              backgroundColor: '#FFFFFF',
              marginBottom: 18,
            }}
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  gap: 10,
                }}
              >
                <InfoField label="Date" value={callLogData?.StartTime ? moment(callLogData.StartTime).format('dddd, MMMM D, YYYY') : ''} />
                <InfoField label="Time Started" value={callLogData?.StartTime ? moment(callLogData.StartTime).format('LTS') : ''} />
                <InfoField label="Time ended" value={callLogData?.EndTime ? moment(callLogData.EndTime).format('LTS') : ''} />
                <InfoField label="Call status" value={callLogData?.Status || ''} />
                <InfoField label="Call participants" value={callLogParticipants[0]} />
                <InfoLine label="" value={callLogParticipants[1]} />
              </div>
              <div>{callLogData?.Participant && <UISmallButton onClick={onRepeatCall} text={'Call again'} variant={'secondary'} />}</div>
            </div>
          </UICard>

          <div>
            <InfoLabel>
              Reason for appointment
              <InfoLabelRequired />
            </InfoLabel>
            <NucleusControlledSelect
              id="ReasonForAppointment"
              disabled={false}
              noLabel
              label={'Reason for appointment'}
              register={register('ReasonForAppointment', {
                required: 'Reason is required',
              })}
              error={errors.ReasonForAppointment}
              clearErrors={clearErrors}
              setValue={setValue}
              name={'ReasonForAppointment'}
              options={callLogCatalogs.providerCallReasonData}
              loadingOptions={false}
              value={watch('ReasonForAppointment')}
              valueAccessor={'ID'}
              labelAccessor={'Name'}
              mainContainerStyle={{
                marginTop: 8,
              }}
              selectStyles={{
                minHeight: 44,
              }}
            />
          </div>
          <div>
            <InfoLabel>Appointment Type</InfoLabel>
            <NucleusControlledSelect
              id="AppointmentType"
              disabled={false}
              noLabel
              label={'Appointment Type'}
              placeholder="Select..."
              register={register('AppointmentType', {
                required: 'Appointment Type is required',
              })}
              error={errors.AppointmentType}
              clearErrors={clearErrors}
              setValue={setValue}
              name={'AppointmentType'}
              options={callLogCatalogs.providerCallTypesData}
              loadingOptions={false}
              value={watch('AppointmentType')}
              valueAccessor={'ID'}
              labelAccessor={'Name'}
              mainContainerStyle={{
                marginTop: 8,
              }}
              selectStyles={{
                minHeight: 44,
              }}
            />
          </div>
          <div>
            <InfoLabel>
              Outcome
              <InfoLabelRequired />
            </InfoLabel>
            <NucleusControlledSelect
              id="AppointmentOutcome"
              disabled={false}
              noLabel
              label={'Appointment Outcome'}
              register={register('AppointmentOutcome', {
                required: 'Appointment Outcome is required',
              })}
              error={errors.AppointmentOutcome}
              clearErrors={clearErrors}
              setValue={setValue}
              name={'AppointmentOutcome'}
              options={callLogCatalogs.providerCallOutcomeData}
              loadingOptions={false}
              value={watch('AppointmentOutcome')}
              valueAccessor={'ID'}
              labelAccessor={'Name'}
              mainContainerStyle={{
                marginTop: 8,
              }}
              selectStyles={{
                minHeight: 44,
              }}
            />
          </div>
          <InfoLabel>Notes</InfoLabel>
          <UICard
            style={{
              display: 'flex',
              width: '100%',
              backgroundColor: '#FFFFFF',
              marginTop: 5,
            }}
          >
            <textarea
              style={{
                fontFamily: 'Barlow',
                width: '100%',
                minHeight: 112,
                resize: 'none',
                border: 'none',
                outline: 'none',
                boxSizing: 'border-box',
                boxShadow: 'none',
              }}
              placeholder="Empty"
              value={callLogNotes}
              onChange={e => setCallLogNotes(e.target.value)}
            />
          </UICard>
        </UINucleusContentContainer>
      </UINucleusContainer>
    </UIModal>
  );
};

export type tPostCallData = {};

const InfoLabelContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
`;
const InfoLabel = styled.span`
  display: flex;
  flex: 2;
  font-family: Barlow;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
`;

const InfoValue = styled.span`
  display: flex;
  flex: 4;
`;
const InfoField = ({ label = '', value = '' }: { label: string; value: string }) => {
  return (
    <InfoLabelContainer>
      <InfoLabel>{label}:</InfoLabel>
      <InfoValue>{value}</InfoValue>
    </InfoLabelContainer>
  );
};
const InfoLine = ({ label = '', value = '' }: { label: string; value: string }) => {
  return (
    <InfoLabelContainer>
      <InfoLabel></InfoLabel>
      <InfoValue>{value}</InfoValue>
    </InfoLabelContainer>
  );
};

const InfoLabelRequired = () => {
  return <span style={{ marginLeft: 3, color: 'red', fontSize: 12 }}>*</span>;
};

export default PostCallDataModal;
