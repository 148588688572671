import { setLocalEnv, setDevEnv, setTestEnv, setProdEnv } from '@nucleus-care/nucleuscare-backend-client';
import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';

import api from './httpClient';

const BackendClientLauncher = ({ children }) => {
  const dispatch = useDispatch();
  const [environmentLoaded, setEnvironmentLoaded] = useState<boolean>(false);

  useEffect(() => {
    console.log('BackendClientLauncher useEffect');
    const environment = process.env.REACT_APP_ENVIRONMENT;
    switch (environment) {
      case 'local':
        api.defaults.baseURL = 'https://care-dev.nucleuslife.io/ws';
        dispatch(setLocalEnv());
        break;
      case 'development':
        api.defaults.baseURL = 'https://care-dev.nucleuslife.io/ws';
        dispatch(setDevEnv());
        break;
      case 'test':
        api.defaults.baseURL = 'https://care-test.nucleuslife.io/ws';
        dispatch(setTestEnv());
        break;
      default:
        dispatch(setProdEnv());
        break;
    }
    setEnvironmentLoaded(true);
  }, []);

  if (!environmentLoaded) return null;
  return <>{children}</>;
};

export default BackendClientLauncher;
