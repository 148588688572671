import React from 'react';
import styled from 'styled-components';

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 220px;
`;
const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  margin-right: 10px;
  object-fit: cover;
`;

const Name = styled.span`
  font-family: 'Fira Sans';
  font-weight: 300;
`;

const FullNameCell = ({ cell: { row, value } }) => {
  const rowData = row.original;
  const imgRef = React.useRef(null);

  const handleImageError = () => {
    imgRef.current.src = '/img/user_placeholder.png';
  };

  return (
    <CellContainer>
      <Avatar src={rowData.ThumbnailUrl || '/img/user_placeholder.png'} ref={imgRef} onError={handleImageError} />
      <Name className="truncate">{value}</Name>
    </CellContainer>
  );
};

export default FullNameCell;
