import React from 'react';

import { useNavigate } from 'react-router-dom';
import { UINucleusTitlesNavBar, NucleusTabProps } from '../../../../components/UI';
import { useNucleusProviders } from '../../../../context-api/nucleusProvidersContext/NucleusProvidersContext';
import { ScheduleTabs } from '../../utils/providerConstants';

type ProviderNavTabsProps = {
  handleTabs?: (tab: NucleusTabProps) => void;
  activeTab?: string;
};
const ScheduleNavTabs = ({ handleTabs, activeTab }: ProviderNavTabsProps) => {
  const navigate = useNavigate();
  const {
    state: {
      provider: { data },
    },
  } = useNucleusProviders();
  const defaultHandler = (tab: NucleusTabProps) => {
    if (tab.route) {
      if (tab.route.includes(':providerId') && data?.ID) {
        console.log('ScheduleNavTabs => provider', data?.ID);
        //navigate(tab.route.replace(':providerId', data?.ID))
      } else {
        console.log('ScheduleNavTabs => push', tab.route);
        navigate(tab.route);
      }
    } else {
      console.log("ScheduleNavTabs => navigate.push('/providers');");
      navigate('/providers');
    }
  };
  return (
    <div>
      <UINucleusTitlesNavBar
        key={activeTab}
        tabs={ScheduleTabs}
        activeTab={activeTab}
        onTabChange={tab => {
          if (handleTabs) handleTabs(tab);
          defaultHandler(tab);
        }}
      />
    </div>
  );
};

export default ScheduleNavTabs;
