import React, { useState } from 'react';

type NucleusControlledSelectProps = {
  register: any;
  name: string;
  id: string;
  label: string;
  options: any[];
  error: any;
  clearErrors: any;
  setValue: any;
  valueAccessor?: string;
  labelAccessor?: string;
  loadingOptions?: boolean;
  noLabel?: boolean;
  mainContainerStyle?: any;
  saveHandler?: any;
  saveText?: string;
  saveStyle?: any;
  disabled?: boolean;
  value?: unknown;
  placeholder?: string;
  selectStyles?: React.CSSProperties;
};

const NucleusControlledSelect = ({
  register,
  name,
  id,
  label,
  options,
  error,
  clearErrors,
  setValue,
  valueAccessor = 'value',
  labelAccessor = 'label',
  loadingOptions,
  noLabel = false,
  mainContainerStyle,
  saveHandler,
  saveText,
  saveStyle,
  selectStyles,
  placeholder = 'Select...',
  ...props
}: NucleusControlledSelectProps) => {
  const [selectedValue, setSelectedValue] = useState('');
  const handleSelectChange = e => {
    const value = e.target.value;
    console.log('handleSelectChange', name, value);
    if (error) {
      console.log('handleSelectChange=>error');
      clearErrors(name);
    }
    setValue(name, value);
    setSelectedValue(value);
  };

  // PLEASE DO NOT MODIFY THIS STYLES
  // PLEASE DO NOT MODIFY THIS STYLES
  // PLEASE DO NOT MODIFY THIS STYLES
  // These styles are made to look good depending on the styles of their parent component outside of this component.
  // Try to modify the style of the parent component if you are having errors when making this component visible
  return (
    <React.Fragment>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // minHeight: 70,
          opacity: props?.disabled ? 0.4 : 1,
          backgroundColor: '#fff',
          borderRadius: 8,
          paddingRight: 1,
          ...mainContainerStyle,
        }}
      >
        {!noLabel && (
          <label
            // PLEASE DO NOT MODIFY THIS STYLES
            style={{
              minWidth: 130,
              textAlign: 'start',
              fontSize: 16,
              color: '#0A313F',
            }}
            htmlFor={name}
            id={id}
          >
            {label}
          </label>
        )}
        <select
          style={{
            width: '%100',
            height: '30px',
            display: 'block',
            borderRadius: 8,
            border: error ? '1px solid #FE3824' : 'none',
            color: selectedValue == '' ? '#CBCBCB' : '#000',
            ...selectStyles,
          }}
          name={name}
          id={id}
          {...props}
          {...register}
          onChange={handleSelectChange}
        >
          <option disabled key={'defaultValue'} value={''}>
            {placeholder}
          </option>
          {!loadingOptions &&
            options.map(option => (
              <option key={option[valueAccessor]} value={option[valueAccessor]}>
                {option[labelAccessor]}
              </option>
            ))}
        </select>
        {!!saveHandler && (
          <span
            onClick={saveHandler}
            role="button"
            style={{
              alignSelf: 'center',
              cursor: 'pointer',
              color: '#0092FF',
              textAlign: 'center',
              fontWeight: 'bold',
              // marginLeft: 10,
              paddingLeft: 10,
              paddingRight: 10,
              ...saveStyle,
            }}
          >
            {saveText ? saveText : 'Save'}
          </span>
        )}
      </div>
      {error ? (
        <p
          // PLEASE DO NOT MODIFY THIS STYLES
          style={{
            color: '#FE3824',
            fontWeight: 'bold',
            marginTop: 1,
            textAlign: 'end',
            marginBottom: 4,
          }}
        >
          {error.message}
        </p>
      ) : (
        <p style={{ fontWeight: 'bold', marginTop: 1, marginBottom: 9, height: 9 }} />
      )}
    </React.Fragment>
  );
};

export default NucleusControlledSelect;
