export const ChevronLeftSVG = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.80433 14.7564L21.0457 0.515134C21.7325 -0.171711 22.8461 -0.171711 23.5329 0.515134L25.1939 2.17615C25.8795 2.86182 25.8809 3.9731 25.1968 4.66039L13.9103 16L25.1968 27.3396C25.8809 28.0269 25.8795 29.1382 25.1939 29.8238L23.5329 31.4849C22.846 32.1717 21.7325 32.1717 21.0457 31.4849L6.80433 17.2437C6.11755 16.5568 6.11755 15.4433 6.80433 14.7564Z"
        fill="#0092FF"
      />
    </svg>
  );
};
