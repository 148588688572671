import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { SpinnerCircular } from 'spinners-react';
import styled from 'styled-components';
import { UINucleusContainer, useModal } from '../../../components/UI';
import { UINucleusContentContainer } from '../../../components/UI/NucleusContainer/Content';
import AuthStore from '../../../stores/AuthStore';
import { getProviderAvailabilityDataPaginated } from '../../../utils/providerApi';
import DateFromToSelector from '../components/DateFromToSelector';
import { IProviderAvailability } from '../provider.types';
import type { ProviderAvailabilityResponse, ProviderAvailabilityTableRow } from '../provider.types';
import { AddButtonSVG } from './assets/AddButton';
import DeleteProviderAvailability from './components/DeleteAvailability';
import { ProviderAvailabilityModal } from './components/ProviderAvailabilityModal';

import ScheduleAvailabilityTable from './components/ScheduleAvailabilityTable';
import ScheduleNavTabs from './components/ScheduleNavTabs';
import TablePagination, { Pagination } from './components/TablePagination';

const FloatingButton = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
`;

const ScheduleAvailability = () => {
  const { closeModal, isOpen, openModal } = useModal();
  const { closeModal: closeDeleteAvailabilityModal, isOpen: isOpenDeleteAvailabilityModal, openModal: openDeleteAvailabilityModal } = useModal();

  const [providerAvailabilityData, setProviderAvailabilityData] = React.useState<any[]>([]);
  const [providerAvailabilityToUpdate, setProviderAvailabilityToUpdate] = React.useState<IProviderAvailability | undefined>(undefined);
  const [ProviderAvailabilityToDelete, setProviderAvailabilityToDelete] = React.useState<ProviderAvailabilityTableRow | undefined>(undefined);
  const [lastFetchParams, setLastFetchParams] = useState<{ from: string; to: string }>({ from: '', to: '' });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [pages, setPage] = useState<Pagination>({ currentPage: 1, rowsPerPage: 25, totalRows: 100, sorting: 'asc' });

  const onFetchData = async (from: string, to: string) => {
    if (!AuthStore.getProviderID()) return;
    if (!from || !to) return;
    setIsLoading(true);
    setLastFetchParams({ from: from, to: to });
    //const availabilityData = await getProviderAvailabilityData(String(AuthStore.getProviderID()), from, to);
    const availabilityData = await getProviderAvailabilityDataPaginated(String(AuthStore.getProviderID()), from, to, pages.currentPage, pages.rowsPerPage, pages.sorting);

    setIsLoading(false);
    loadAvailabilityData(availabilityData);
  };

  useEffect(() => {
    onFetchData(lastFetchParams.from, lastFetchParams.to);
  }, [pages.currentPage, pages.totalRows]);

  const loadAvailabilityData = (response: ProviderAvailabilityResponse) => {
    if (response?.totalScheduleAvailabilities != null) {
      setPage({ ...pages, ...{ totalRows: response?.totalScheduleAvailabilities } });
    }

    if (response?.scheduleAvailabilities) {
      const preparedDataRows: ProviderAvailabilityTableRow[] = [];
      for (let index = 0; index < response?.scheduleAvailabilities.length; index++) {
        const row = response?.scheduleAvailabilities[index];
        const dateLabel = moment(row.startTime).format('MMMM D, YYYY');
        const dataRow: ProviderAvailabilityTableRow = {
          id: row.id,
          index: index,
          date: row.startTime,
          dateLabel: dateLabel,
          startTime: row.startTime,
          endTime: row.endTime,
          slotDuration: row.slotDuration,
          availableSlots: row.availableSlots,
          enabled: !moment(row.startTime).isBefore(moment()),
          scheduledAppoiments: row.scheduledAppoiments,
        };
        preparedDataRows.push(dataRow);
      }
      setProviderAvailabilityData(preparedDataRows);
    }
  };

  return (
    <UINucleusContainer>
      <UINucleusContentContainer>
        <ScheduleNavTabs activeTab="schedule-availability"></ScheduleNavTabs>
        <DateFromToSelector initialFromOffsetDays={0} initialToOffsetDays={+15} onSubmit={onFetchData} />

        <UINucleusContentContainer style={{ paddingTop: 30 }}>
          {isLoading ? (
            <UINucleusContentContainer centerContent>
              <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />
            </UINucleusContentContainer>
          ) : (
            <TableContainer className="whiteTable">
              <ScheduleAvailabilityTable
                data={providerAvailabilityData}
                sortMode={pages.sorting}
                onEditClick={data => {
                  console.log('edit clicked', data);
                  setProviderAvailabilityToUpdate(data);
                  openModal();
                }}
                onDeleteClick={data => {
                  console.log('row.original', data);
                  setProviderAvailabilityToDelete(data);
                  openDeleteAvailabilityModal();
                }}
                onSortClick={sort => {
                  console.log('onSortClick', sort);
                  setPage({ ...pages, ...{ sorting: sort } });
                }}
              />
              <TablePagination
                currentPage={pages.currentPage}
                rowsPerPage={pages.rowsPerPage}
                totalData={pages.totalRows}
                onPageSelected={page => setPage({ ...pages, ...{ currentPage: page } })}
              ></TablePagination>
            </TableContainer>
          )}
          <DeleteProviderAvailability
            isOpen={isOpenDeleteAvailabilityModal}
            closeModal={() => {
              closeDeleteAvailabilityModal();
              setProviderAvailabilityToDelete(undefined);
            }}
            availabilityToDeleteData={ProviderAvailabilityToDelete}
            fetchAvailability={() => onFetchData(lastFetchParams.from, lastFetchParams.to)}
          />
          {isOpen && (
            <ProviderAvailabilityModal
              availabilityUpdateData={providerAvailabilityToUpdate}
              isOpen={isOpen}
              closeModal={() => {
                closeModal();
                setProviderAvailabilityToUpdate(undefined);
              }}
              formState={providerAvailabilityToUpdate?.id ? 'update' : 'create'}
              fetchAvailability={() => onFetchData(lastFetchParams.from, lastFetchParams.to)}
            />
          )}
          <FloatingButton
            onClick={() => {
              setProviderAvailabilityToUpdate(undefined);
              openModal();
            }}
          >
            <AddButtonSVG />
          </FloatingButton>
        </UINucleusContentContainer>
      </UINucleusContentContainer>
    </UINucleusContainer>
  );
};

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 90px;
`;

const styles = {
  passedDate: {
    color: '#ACB4BE',
  },
  validDate: {
    color: 'black',
  },
};

export default ScheduleAvailability;
