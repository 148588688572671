import { UserPatientsResponse } from 'apis/v2/interfaces/PatientApi';
import { getUserPatients } from 'apis/v2/PatientApi';
import NucleusLoading from 'components/NucleusLoading/NucleusLoading';
import TablePagination from 'components/NucleusPaginatedTable/TablePagination';
import NucleusSearchInput from 'components/NucleusSearchInput';
import NucleusTable from 'components/NucleusTable';
import debounce from 'lodash/debounce';
import ImportPatientsFromCSV from 'pages/Patients/ImportPatients';
import { PatientData as Patient, PatientData } from 'pages/Patients/patient.types';
import { useConfigAccessSelector } from 'pages/Users/selectors/loggedInUserConfig';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authStore from 'stores/AuthStore';
import { AuthStore, GeneralStore } from 'stores/index';
import styled from 'styled-components';
import VideoCallSvg from 'svg/VideoCallSvg';
import FullNameCell from './components/FullNameCell';
import IsOnlineCell from './components/IsOnlineCell';
import OnlineContactsCell from './components/OnlineContactsCell';
import TotalDevicesCell from './components/TotalDevicesCell';
interface ClientListProps {
  userID: string;
  canImportPatients: boolean;
  salesforceIntegrationEnabled: boolean;
  onImportedPatientsDone: () => void;
  handleSalesforceIntegration: () => void;
  onCallPatient: (patient: PatientData) => void;
  setPatientsShowing: (patients: Patient[]) => void;
}

const ClientList: React.FC<ClientListProps> = ({ userID, setPatientsShowing, onCallPatient, ...props }) => {
  const navigate = useNavigate();
  const handleRowClicked = ({ row: { original } }) => {
    GeneralStore.setClientTab(1);
    GeneralStore.setPageBeforePatient('patients');
    console.log('going->', '/patientProfile/' + original.PatientID + '/null');
    navigate('/patientProfile/' + original.PatientID + '/null');
  };
  type sortObject = { id: string; desc: boolean };
  const [patients, setPatients] = useState<Patient[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalDataCount, setTotalDataCount] = useState<number>(0);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [sortData, setSortData] = useState<undefined | sortObject>(undefined);
  const [lastSearchText, setLastSearchText] = useState<string | null>('');
  const { allow } = useConfigAccessSelector('allowSalesforceImport');
  const SalesforceButton = useCallback(
    ({ onClick }) => {
      if (!allow) return null;
      return (
        <a className="nucleus-font-family-medium nucleus-link profile-header-btn3" style={{ marginLeft: 36 }} onClick={onClick}>
          {'Sync with Salesforce'}
        </a>
      );
    },
    [allow],
  );

  const handleVideoCall = (patient: PatientData) => {
    onCallPatient(patient);
  };

  const patientsDataColumns = useMemo(() => {
    return [
      { accessor: 'FullName', Header: 'Name', canSort: true, Cell: FullNameCell },
      { accessor: 'PatientDevices', Header: 'Call', disableSortBy: true, Cell: ({ cell }) => <CallActionCell cell={cell} handleVideoCall={handleVideoCall} /> },
      { accessor: 'Telephone', Header: 'Telephone', disableSortBy: true },
      { accessor: 'TotalDevices', Header: 'Device Count', disableSortBy: true, Cell: TotalDevicesCell },
      { accessor: 'FamilyMembers', Header: `${AuthStore.getFamilyLabelPlural()}`, disableSortBy: true, Cell: OnlineContactsCell },
      { accessor: 'MemoryBoxItems', Header: 'Photos', disableSortBy: true },
      { accessor: 'OnlineDevices', Header: 'Status', disableSortBy: true, Cell: IsOnlineCell },
    ];
  }, []);

  const fetchPatientsCb = useCallback(
    debounce(async () => {
      if (lastSearchText !== searchText && currentPage !== 1) {
        onPageSelected(1);
        setLastSearchText(searchText);
        return;
      }
      try {
        setLoading(true);
        if (!userID) {
          setError('User ID is required');
          setLoading(false);
          return;
        }
        const response: UserPatientsResponse = await getUserPatients(userID, {
          limit: 100,
          page: currentPage,
          searchText,
          sortBy: sortData?.id,
          sortOrder: sortData?.desc ? (sortData.desc ? 'desc' : 'asc') : undefined,
        });
        setTotalDataCount(response.total || 0);
        setPatients(response.patients);
        setPatientsShowing(response.patients);
      } catch (err) {
        setError(`An error occurred while fetching ${authStore.getPatientLabelPlural()}`);
        setLoading(false);
      } finally {
        setLastSearchText(searchText);
        setLoading(false);
      }
    }, 300),
    [userID, currentPage, searchText, sortData, lastSearchText],
  );

  const onSubmit = async e => {
    e.preventDefault();
    await fetchPatientsCb();
  };

  useEffect(() => {
    const fetchPatients = async () => await fetchPatientsCb();
    fetchPatients();
  }, [userID, currentPage]);
  const onPageSelected = useCallback(
    debounce((pageIndex: number) => {
      if (!pageIndex || loading) return;
      setCurrentPage(pageIndex);
    }, 100),
    [loading],
  );

  const handleSortChange = (column?: sortObject) => {
    console.log('column', column);
    setSortData(column);
  };

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="col s6">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <NucleusSearchInput placeholder="Client name" onChange={e => setSearchText(e.target.value)} value={searchText} buttonType="submit" />
              {loading && <NucleusLoading size={40} />}
            </div>
          </div>
          <div className="col s6 right-align">
            {props.canImportPatients && <ImportPatientsFromCSV onDone={props.onImportedPatientsDone}></ImportPatientsFromCSV>}
            {props.salesforceIntegrationEnabled && <SalesforceButton onClick={props.handleSalesforceIntegration} />}
          </div>
        </div>
        <div
          style={{
            height: 'calc(100vh - 360px)',
            overflow: 'auto',
          }}
        >
          <NucleusTable onRowClick={handleRowClicked} handleSortChange={handleSortChange} stickyThead data={loading ? [] : (patients ?? [])} columns={patientsDataColumns} />
        </div>
        <TablePagination showPaginationInfo={false} key={'patients'} currentPage={currentPage} rowsPerPage={100} totalData={totalDataCount} onPageSelected={onPageSelected} />
        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 10 }} className="valign-wrapper center-align">
          {error && <ErrorSpan>Error: {error}</ErrorSpan>}
          {!loading && patients.length === 0 && <div>No {authStore.getPatientLabelPlural() ?? 'clients'} found</div>}
        </div>
      </form>
    </div>
  );
};
const CallActionCell = ({ handleVideoCall, cell: { row } }) => {
  const rowData = row.original;
  const shouldShowMultiCallButton = rowData.PatientDevices.length && rowData.PatientDevices.every(device => device.multiDeviceCallSupported);
  if (shouldShowMultiCallButton) {
    return (
      <VideoCallSvg
        onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
          e.stopPropagation();
          handleVideoCall(rowData);
        }}
      />
    );
  }
  return <span></span>;
};
const ErrorSpan = styled.span`
  color: red;
  font-size: 1.2rem;
  font-weight: 500;
`;
export default ClientList;
