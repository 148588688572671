import querystring from 'querystring';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import NucleusPaginatedTable from 'components/NucleusPaginatedTable';
import NucleusSearchInput from 'components/NucleusSearchInput';
import { UINucleusContainer, UISmallButton } from 'components/UI';
import { UINucleusColumnsContainer, UINucleusColumnContent } from 'components/UI/Columns';
import { UINucleusContentContainer } from 'components/UI/NucleusContainer/Content';
import { UINucleusPageTitle } from 'components/UI/NucleusPageTitle/Default';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { UseTableRowProps } from 'react-table';
import { SpinnerCircular } from 'spinners-react';
import AuthStore from 'stores/AuthStore';
import styled from 'styled-components';
import Message from 'utils/Message';
import { getProviderCallLogs } from 'utils/providerApi';

export enum CallLogParticipantType {
  Patient = 'Patient',
  User = 'User',
  Family = 'Family',
  ProviderUser = 'ProviderUser',
  ClassHost = 'ClassHost',
}

type ProviderCallLogParticipant = {
  Description: string;
  HasVideoStats: boolean;
  UserType: CallLogParticipantType;
  ID: string;
};
type ProviderCallLogConcerningPatient = {
  ID: string;
  Name: string;
};

type ProviderCallLog = {
  AccountName: string;
  CallReason?: string;
  CallType?: string;
  CallOutcome?: string;
  ClassID?: string;
  DateString: string;
  EndTime?: number;
  Escalated: string;
  HasNotes: boolean;
  ID: string;
  InProgress: boolean;
  Notes?: string;
  NotesUserID?: string;
  Participants: ProviderCallLogParticipant[];
  ConcerningPatient?: ProviderCallLogConcerningPatient;
  PatientID: string;
  RequestID?: string;
  RequestNumber: number;
  RequestTime: string;
  RequestType: string;
  StartTime: number;
  Status?: string;
  TimeString: string;
  WasAnswered: boolean;
  clientRoleName: string;
  counter: number;
  patientTimezone: string;
  v: string;
  AccountState?: string;
};

const ProviderCallLogs = () => {
  const [stateFromDateTimeLogs, setFromDateTimeLogs] = useState<Date | null>();
  const [stateToDateTimeLogs, setToDateTimeLogs] = useState<Date | null>();
  const [datesErrorText, setDatesErrorText] = useState('');
  const [providerCallLogsData, setProviderCallLogsData] = React.useState<ProviderCallLog[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const { handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const controlTimezone = useRef(0);

  const columns = [
    {
      Header: 'Account Name',
      accessor: 'AccountName',
    },
    {
      Header: 'Date & Time',
      accessor: 'DateString',
      Cell: ({ row }) => {
        let timezone = row.original.patientTimezone;
        if (row.original?.patientTimezone && typeof row.original.patientTimezone === 'object') {
          timezone = row.original.patientTimezone.filter(value => value !== null);
        }
        return <span>{row.original.DateString + ' - ' + row.original.TimeString + ' - ' + timezone}</span>;
      },
    },
    {
      Header: 'Duration',
      accessor: 'StartTime',
      Cell: ({ row }: { row: UseTableRowProps<ProviderCallLog> }) => {
        let duration = '';
        if (row.original.WasAnswered) {
          duration = getCallTimeDuration(row.original.StartTime, row.original.EndTime!);
        }
        return <span>{duration}</span>;
      },
    },
    {
      Header: 'Status',
      accessor: 'Status',
      Cell: ({ row }) => {
        if (row.original.WasAnswered) {
          return <span>{row.original.Status}</span>;
        }
        return <span style={{ color: 'red' }}>{row.original.Status}</span>;
      },
    },
    {
      Header: 'Participants',
      accessor: 'Participants',
      disableSortBy: true,
      Cell: ({ row }) => {
        return row.original.Participants.map(peer => (
          <div>
            <span> {peer.Description}</span>
          </div>
        ));
      },
    },
    {
      Header: 'Patient',
      accessor: 'patient',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <div>
            <span> {row.original?.ConcerningPatient?.Name}</span>
          </div>
        );
      },
    },
    {
      Header: 'State',
      accessor: 'AccountState',
      disableSortBy: true,
    },
    {
      Header: 'Reason for Appt.',
      accessor: 'CallReason',
      disableSortBy: true,
    },
    {
      Header: 'Outcome',
      accessor: 'CallOutcome',
      disableSortBy: true,
    },
  ];

  const getCallTimeDuration = (startTime: number, endTime: number) => {
    const pad2 = number => {
      return (number < 10 ? '0' : '') + number;
    };
    let duration = '';
    if (endTime && endTime > 0) {
      //@ts-ignore
      let delta = Math.abs(new Date(endTime) - new Date(startTime)) / 1000;
      const days = Math.floor(delta / 86400);
      delta -= days * 86400;
      const hours = Math.floor(delta / 3600) % 24;
      delta -= hours * 3600;
      const minutes = Math.floor(delta / 60) % 60;
      delta -= minutes * 60;
      const seconds = Math.floor(delta % 60);
      duration = pad2(hours) + ':' + pad2(minutes) + ':' + pad2(seconds);
    }
    return duration;
  };

  useEffect(() => {
    const currentDate = new Date();
    const passedDate = new Date();

    currentDate.setHours(currentDate.getHours(), currentDate.getMinutes() + 30, 0);

    passedDate.setDate(passedDate.getDate() - 7);
    passedDate.setHours(8, 0, 0);

    controlTimezone.current = (currentDate.getTimezoneOffset() / 60) * -1;
    console.log('CONTROL TIMEZONE:', controlTimezone.current);

    setFromDateTimeLogs(passedDate);
    setToDateTimeLogs(currentDate);

    passedDate.setHours(0);
    passedDate.setMinutes(0);
    passedDate.setSeconds(0);
    passedDate.setHours(passedDate.getHours() + controlTimezone.current * -1);

    currentDate.setHours(23);
    currentDate.setMinutes(59);
    currentDate.setSeconds(0);
    currentDate.setHours(currentDate.getHours() + controlTimezone.current * -1);

    console.log('PatientProfileInfo.js', 'fromDate ' + passedDate);
    console.log('PatientProfileInfo.js', 'toDate ' + currentDate);

    const fromParamString =
      passedDate.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }) +
      ' ' +
      passedDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });
    const toParamString =
      currentDate.toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }) +
      ' ' +
      currentDate.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });

    console.log('PatientProfileInfo.js', 'fromParamString ' + fromParamString);
    console.log('PatientProfileInfo.js', 'toParamString ' + toParamString);

    getProviderCallLogs({
      providerId: String(AuthStore.getProviderID()),
      userId: String(AuthStore.getUserID()),
      from: fromParamString,
      to: toParamString,
    })
      .then(responseData => {
        buildProvidersCallLogData(responseData);
      })
      .catch(error => {
        console.log('fetchCallLogsData error', error);
        setIsLoading(false);
        Message.show('Error getting call logs: ' + error?.response?.message);
      });
    setIsLoading(true);

    setDatesErrorText('');
  }, []);

  useEffect(() => {
    if (!AuthStore.getProviderID()) return;
    console.log('ProviderCallLogs AuthStore.getProviderID()', AuthStore.getProviderID());
  }, [AuthStore.getProviderID()]);

  const fetchCallLogsData = async () => {
    console.log('fetchProviderCallLogs ======================>');
    if (!AuthStore.getProviderID()) return;
    if (!AuthStore.getUserID()) return;
    console.log('ProviderCallLogs AuthStore.getProviderID()', AuthStore.getProviderID());
    console.log('ProviderCallLogs AuthStore.getProviderID()', AuthStore.getUserID());

    if (!stateFromDateTimeLogs || !stateToDateTimeLogs) return;
    console.log('fetchData');

    const fromDateTimeLogs = new Date(stateFromDateTimeLogs);
    const toDateTimeLogs = new Date(stateToDateTimeLogs);

    if (toDateTimeLogs < fromDateTimeLogs) {
      setDatesErrorText('The From Date has to be before the To Date.');
      return;
    }

    fromDateTimeLogs.setHours(0);
    fromDateTimeLogs.setMinutes(0);
    fromDateTimeLogs.setSeconds(9);

    fromDateTimeLogs.setHours(fromDateTimeLogs.getHours() + controlTimezone.current * -1);

    toDateTimeLogs.setHours(23);
    toDateTimeLogs.setMinutes(59);
    toDateTimeLogs.setSeconds(0);

    toDateTimeLogs.setHours(toDateTimeLogs.getHours() + controlTimezone.current * -1);

    const fromParam =
      fromDateTimeLogs.toLocaleDateString('en-US') +
      ' ' +
      fromDateTimeLogs.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });

    const toParam =
      toDateTimeLogs.toLocaleDateString('en-US') +
      ' ' +
      toDateTimeLogs.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
      });

    console.log('handleFilterPatientCallLogs', 'fromDate ' + fromParam);
    console.log('handleFilterPatientCallLogs', 'toDate ' + toParam);
    if (fromParam?.includes('Invalid Date') || toParam?.includes('Invalid Date')) {
      Message.show('Please select a valid date');
      return;
    }

    setIsLoading(true);

    try {
      const responseData = await getProviderCallLogs({
        providerId: String(AuthStore.getProviderID()),
        userId: String(AuthStore.getUserID()),
        from: fromParam,
        to: toParam,
      });
      buildProvidersCallLogData(responseData);
    } catch (error) {
      console.log('fetchCallLogsData error', error);

      setIsLoading(false);
      Message.show('Error getting call logs: ' + error?.response?.message);
      return;
    }
  };

  const fetchCallLogDebounce = useCallback(_.debounce(fetchCallLogsData, 500), [stateFromDateTimeLogs, stateToDateTimeLogs, controlTimezone]);

  useEffect(() => {
    if (stateFromDateTimeLogs && stateToDateTimeLogs) {
      fetchCallLogDebounce();
    }
  }, [searchText]);

  const buildProvidersCallLogData = responseData => {
    console.log('onGetProvidersCallLogData', responseData);
    const callLogsData: ProviderCallLog[] = responseData.callLogs || [];
    setProviderCallLogsData(callLogsData);
    setIsLoading(false);
    const filteredData = callLogsData.filter(item => {
      return (
        item.AccountName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.Participants[0]?.Description.toLowerCase().includes(searchText.toLowerCase()) ||
        item.Participants[1]?.Description.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.ConcerningPatient?.Name.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    const dataToExport = filteredData.map(item => {
      let doctorText = '';
      let nurseText = '';
      try {
        const doctor = item.Participants.find(participant => participant.UserType === CallLogParticipantType.ProviderUser);
        const nurse = item.Participants.find(participant => participant.UserType === CallLogParticipantType.User);
        doctorText = doctor?.Description || 'Doctor';
        nurseText = nurse?.Description || 'Nurse';
      } catch (error) {
        console.error('Error getting doctor and nurse', error);
      }
      const timezone = item.patientTimezone;
      if (item?.patientTimezone && typeof item.patientTimezone === 'object') {
        //timezone = item.patientTimezone.filter(value => value !== null);
      }
      return {
        accountName: item.AccountName,
        dateTime: item.DateString + ' - ' + item.TimeString + ' - ' + timezone,
        duration: item.WasAnswered ? getCallTimeDuration(item.StartTime, item.EndTime) : '',
        status: item.Status,
        participants: item.Participants,
        patient: item.ConcerningPatient ? item.ConcerningPatient.Name : '',
        state: item.AccountState,
        doctorText: doctorText,
        nurseText: nurseText,
        callReason: item.CallReason,
        callType: item.CallType,
        callOutcome: item.CallOutcome,
        notes: item.Notes,
      };
    });
    console.warn('Data to export', dataToExport);
  };

  const handleSubmitSearch = data => {
    console.log('handleSubmitSearch', data);
    setSearchText(data.search);
  };

  const onExportReportCSV = async () => {
    const fromDateFormatted = moment.utc(stateFromDateTimeLogs).format('M/D/YYYY hh:mm A');
    const toDateFormatted = moment.utc(stateToDateTimeLogs).format('M/D/YYYY hh:mm A');
    const queryString = querystring.stringify({ fromDate: fromDateFormatted, toDate: toDateFormatted });
    try {
      const response = await httpApi.get(`/v2/providers/${String(AuthStore.getProviderID())}/users/${String(AuthStore.getUserID())}/call-logs/csv?${queryString}`);
      const blob = new Blob([response.data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fromDateFormattedForName = moment(stateFromDateTimeLogs).format('DD_MM_YYYY_hh_mm_A');
      const toDateFormattedForName = moment(stateToDateTimeLogs).format('DD_MM_YYYY_hh_mm_A');
      link.setAttribute('download', `Call_Logs_${fromDateFormattedForName}_${toDateFormattedForName}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const onExportReportPDF = async () => {
    const fromDateFormatted = moment.utc(stateFromDateTimeLogs).format('M/D/YYYY hh:mm A');
    const toDateFormatted = moment.utc(stateToDateTimeLogs).format('M/D/YYYY hh:mm A');
    const queryString = querystring.stringify({ fromDate: fromDateFormatted, toDate: toDateFormatted, limit: 999, page: 1 });
    try {
      const response = await httpApi.get(`/v2/providers/${String(AuthStore.getProviderID())}/users/${String(AuthStore.getUserID())}/call-logs/pdf?${queryString}`, {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      const fromDateFormattedForName = moment(stateFromDateTimeLogs).format('DD_MM_YYYY_hh_mm_A');
      const toDateFormattedForName = moment(stateToDateTimeLogs).format('DD_MM_YYYY_hh_mm_A');
      link.setAttribute('download', `Call_Logs_${fromDateFormattedForName}_${toDateFormattedForName}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <UINucleusContainer>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 2 }}>
          <UINucleusPageTitle>Call Logs</UINucleusPageTitle>
        </div>
        <UINucleusColumnContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: 10,
          }}
        >
          <UISmallButton style={{ width: 244 }} onClick={onExportReportPDF} text={'Export call logs to PDF'} variant={'secondary'} />
          <UISmallButton onClick={onExportReportCSV} text={'Export call logs to CSV'} variant={'secondary'} />
        </UINucleusColumnContent>
      </div>
      <SearchInputContainer>
        <NucleusSearchInput
          value={searchText}
          onChange={e => {
            const value = e.target.value;
            setSearchText(value);
          }}
          inputMinWidth={700}
          placeholder={`Filter by Account Name / Patient / State / Doctor / Nurse / Reason of appointment / Outcome`}
        />
      </SearchInputContainer>
      <UINucleusColumnsContainer style={{ height: 80 }}>
        <UINucleusColumnContent style={{ display: 'flex', flexDirection: 'column' }}>
          <HorizontalContainer>
            <DateSelectorContainer>
              <Label>From</Label>
              <DateSelectorWrapper>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    variant="dialog"
                    className="browser-default"
                    value={stateFromDateTimeLogs}
                    onChange={date => {
                      //@ts-ignore
                      setFromDateTimeLogs(date);
                      setDatesErrorText('');
                    }}
                    autoOk={true}
                    onError={console.log}
                    format={'MM/DD/YYYY'}
                  />
                </MuiPickersUtilsProvider>
              </DateSelectorWrapper>
            </DateSelectorContainer>
            <DateSelectorSeparator> - </DateSelectorSeparator>
            <DateSelectorContainer>
              <Label>To</Label>
              <DateSelectorWrapper>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    variant="dialog"
                    className="browser-default"
                    value={stateToDateTimeLogs}
                    onChange={date => {
                      //@ts-ignore
                      setToDateTimeLogs(date);
                      setDatesErrorText('');
                    }}
                    autoOk={true}
                    onError={console.log}
                    format={'MM/DD/YYYY'}
                  />
                </MuiPickersUtilsProvider>
              </DateSelectorWrapper>
            </DateSelectorContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: 2,
                marginLeft: 12,
              }}
            >
              <UISmallButton onClick={fetchCallLogDebounce} text={'  Filter  '} variant={'black'} style={{ width: 212 }} />
            </div>
          </HorizontalContainer>
          <div style={{ display: 'flex', paddingLeft: 5 }}>
            <ErrorLabel>{datesErrorText}</ErrorLabel>
          </div>
        </UINucleusColumnContent>
      </UINucleusColumnsContainer>

      <UINucleusContentContainer style={{ paddingTop: 30 }}>
        <form onSubmit={handleSubmit(handleSubmitSearch)}></form>
        {isLoading ? (
          <UINucleusContentContainer centerContent>
            <SpinnerCircular color="#2096F3" secondaryColor="rgba(0,0,0,0.16)" size="50" thickness={100} />
          </UINucleusContentContainer>
        ) : (
          <div style={{ marginBottom: 90 }}>
            <NucleusPaginatedTable
              columns={columns}
              data={providerCallLogsData.filter(data => {
                return (
                  data.AccountName.toLowerCase().includes(searchText.toLowerCase()) ||
                  data.Participants[0]?.Description?.toLowerCase().includes(searchText.toLowerCase()) ||
                  data.Participants[1]?.Description?.toLowerCase().includes(searchText.toLowerCase()) ||
                  data.CallOutcome?.toLowerCase().includes(searchText.toLowerCase()) ||
                  data.CallReason?.toLowerCase().includes(searchText.toLowerCase()) ||
                  data.AccountState?.toLowerCase().includes(searchText.toLowerCase()) ||
                  data?.ConcerningPatient?.Name?.toLowerCase().includes(searchText.toLowerCase())
                );
              })}
            />
          </div>
        )}
      </UINucleusContentContainer>
    </UINucleusContainer>
  );
};

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const DateSelectorContainer = styled.div`
  width: 168px;
  padding: 9px 12px;
  height: 68px;
  margin-right: 12px;
  border-radius: 6px;
`;
const DateSelectorSeparator = styled.div`
  margin: 0px;
  padding: 0px;
  padding-top: 24px;
  font-size: 32px;
`;

const DateSelectorWrapper = styled.div`
  width: 140px;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  padding: 5px 5px 0px;
  border-radius: 5px;
  margin-top: 0px;
  width: 158px;
`;

const Label = styled.label`
  color: #0a313f;
  font-size: 15px;
`;
const ErrorLabel = styled.label`
  color: #ff4b30;
  font-size: 15px;
`;
const SearchInputContainer = styled.div`
  display: flex;
  alignitems: center;
  padding-top: 10px;
`;

export default ProviderCallLogs;
