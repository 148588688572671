import { httpApi as httpNodeApi } from '@nucleus-care/nucleuscare-backend-client';
import {
  IProviderAccount,
  INewProviderUser,
  IProviderData,
  IAccountCatalogListItem,
  ProviderStringsResponse,
  ProviderAccountGroupingStringsForm,
} from '../../../../context-api/nucleusProvidersContext/NucleusProvidersContext';

import { IState, IUserState } from '../components/StateSelector/useStateSelector';

export const getProviderByID = async (providerId: string): Promise<IProviderData> => {
  const { data } = await httpNodeApi.get(`/providers/${providerId}`);
  return data;
};
export const updateProviderByID = async (providerId: string, data: FormData): Promise<any> => {
  const response = await httpNodeApi.put(`/providers/${providerId}`, data);
  return response;
};

export const getProviderAccounts = async (providerId: string): Promise<IProviderAccount[]> => {
  const { data } = await httpNodeApi.get(`/providers/${providerId}/accounts`);
  return data.providersAccounts;
};
export const getProviderAccountsToSelect = async (providerId: string): Promise<IAccountCatalogListItem[]> => {
  const { data } = await httpNodeApi.get(`/providers/${providerId}/accounts_catalog`);
  return data.providersAccounts;
};
export const addProviderAccounts = async (providerId: string, accountIds: string[]) => {
  const { data } = await httpNodeApi.post(`/providers/${providerId}/accounts`, {
    accountIds,
  });
  const { accountsAdded } = data;
  return accountsAdded;
};
export const removeProviderAccount = async (providerId: string, accountId: string) => {
  const { data } = await httpNodeApi.delete(`/providers/${providerId}/accounts`, {
    data: {
      accountIds: [accountId],
    },
  });
  const { accountsRemoved } = data;
  return accountsRemoved;
};

export const getProviderStrings = async (providerId: string): Promise<any> => {
  const { data } = await httpNodeApi.get(`/providers/${providerId}/strings`);
  return data as ProviderStringsResponse;
};
export const updateProviderStrings = async ({
  providerId,
  form = { localeTag: 'EN', userRoleName: 'User', userRoleNamePlural: 'Users' },
}: {
  providerId: string;
  form: {
    localeTag?: string;
    userRoleName?: string;
    userRoleNamePlural?: string;
  };
}): Promise<any> => {
  const { data } = await httpNodeApi.put(`/providers/${providerId}/strings`, form);
  return data;
};

export const updateProviderAccountGroupingStrings = async ({ providerId, stringsForm }: { providerId: string; stringsForm: ProviderAccountGroupingStringsForm }): Promise<any> => {
  const { data } = await httpNodeApi.put(`/providers/${providerId}/account_grouping_strings`, stringsForm);
  return data;
};

export const getProviderUsers = async (providerId: string): Promise<any> => {
  const response = await httpNodeApi.get('providers/' + providerId + '/users');
  const { data } = response;
  return data;
};

export const getProviderUserStates = async (providerId: string, providerUserId: string): Promise<IUserState[]> => {
  const { data } = await httpNodeApi.get(`providers/${providerId}/state-license/${providerUserId}`);
  return data;
};

export const addStateToProviderUser = async (providerId: string, providerUserId: string, states: IState[]) => {
  try {
    const buildStates = states.map(state => {
      return {
        UserID: providerUserId,
        StateID: state.ID,
      };
    });
    const { data } = await httpNodeApi.post(`providers/${providerId}/state-license`, buildStates);
    return data.states;
  } catch (e) {
    console.error('Error on addStateToProviderUser:', e);
    return [];
  }
};

export const removeProviderUserStates = async (providerId: string, providerUserId: string, states: IState[]) => {
  try {
    const buildStates = states.map(state => {
      return {
        UserID: providerUserId,
        StateID: state.ID,
      };
    });
    const removedStatesResponse = await httpNodeApi.delete(`providers/${providerId}/state-license`, { data: buildStates });
    return removedStatesResponse;
  } catch (e) {
    console.error('Error on removeProviderUserStates:', e);
    return [];
  }
};
export const addProviderUser = async (providerId: string, newProviderData: INewProviderUser): Promise<any> => {
  const selectedStates = newProviderData.states;
  delete newProviderData.states;
  if (!selectedStates?.length) {
    return {
      ok: false,
      error: 'Please select at least one state',
    };
  }
  try {
    const response = await httpNodeApi.post('providers/' + providerId + '/users', newProviderData);
    const { data } = response;
    await addStateToProviderUser(providerId, data.id, selectedStates ?? []);
    return data;
  } catch (e: any) {
    const { data } = e.response || { error: 'Error on updateProviderUser' };
    console.error('Error on addProviderUsers data:', data);
    throw data;
  }
};

export const getProviderUserConfigData = async (providerId: string, providerUserId: string): Promise<any> => {
  const response = await httpNodeApi.get('providers/' + providerId + '/users/' + providerUserId);
  const { data } = response;
  return data;
};

export const updateProviderUser = async (providerId: string, providerUserId: string, selectedProviderData: INewProviderUser): Promise<any> => {
  try {
    const selectedStates = selectedProviderData.states;
    const statesToRemove = selectedProviderData.statesToRemove;
    delete selectedProviderData.states;
    delete selectedProviderData.statesToRemove;
    const response = await httpNodeApi.put('providers/' + providerId + '/users/' + providerUserId, selectedProviderData);
    const { data } = response;
    if (statesToRemove?.length) {
      await removeProviderUserStates(providerId, providerUserId, statesToRemove);
    }
    await addStateToProviderUser(providerId, providerUserId, selectedStates ?? []);
    return data;
  } catch (e: any) {
    const { data } = e.response || { error: 'Error on updateProviderUser' };
    return {
      ...data,
      ok: false,
    };
  }
};
