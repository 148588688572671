import Config from '../Config';
import NucleusNetwork from '../utils/NucleusNetwork';

class SalesforceApi {
  private context: any;

  constructor(context) {
    this.context = context;
  }

  //<-------------------- Get BulletinBoard Buttons by Account --------------------->
  getSalesforceData(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'Salesforce.asmx/GetPatientsSalesforceData',
      data: data,
      callback: callback,
    });
  }

  IntegrateSalesforcePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'Salesforce.asmx/ImportPatientFromSalesforce',
      data: data,
      callback: callback,
    });
  }

  UpdateSalesforcePatient(data, callback) {
    NucleusNetwork.request({
      context: this.context,
      type: 'POST',
      url: Config.domain + 'Salesforce.asmx/UpdatePatientFromSalesforce',
      data: data,
      callback: callback,
    });
  }
}

export default SalesforceApi;
