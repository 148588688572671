import React from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
interface TelephoneInputUnderlineProps {
  inputId: string;
  fieldId?: string;
  value?: string;
  onChange: (phone: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
}

const TelephoneInputUnderline: React.FC<TelephoneInputUnderlineProps> = ({ inputId, fieldId, value = '', onChange, onBlur, style = {} }) => {
  const combinedStyles = { ...styles.defaultStyle, ...style };
  const id = fieldId || inputId;

  return (
    <PhoneInput
      fieldId={id}
      defaultCountry="us"
      preferredCountries={['us']}
      value={value}
      disableDialCodePrefill={false}
      placeholder="201-555-0123"
      inputProps={{ id: inputId }}
      onChange={onChange}
      onBlur={onBlur}
      style={combinedStyles}
      inputStyle={styles.inputStyle}
      countrySelectorStyleProps={{
        buttonStyle: {
          backgroundColor: 'transparent',
          border: 'none',
          marginTop: -3,
        },
      }}
    />
  );
};

const styles = {
  defaultStyle: {
    height: 36,
    marginBottom: 0,
    paddingBottom: 0,
    marginLeft: -6,
    marginTop: -6,
  },
  inputStyle: {
    paddingLeft: 10,
    fontSize: 16,
  },
};

export default TelephoneInputUnderline;
