import moment from 'moment';

type VideoSessionParams = {
  data: any;
  editClass: (data?: any) => void;
  startClass: (data?: any) => void;
  accountConfig: any;
};

const VideoSessionRow = (props: VideoSessionParams) => {
  const onClickEdit = () => {
    console.log('onClickEdit', props.data);
    props.editClass(props.data);
  };

  const onClickStart = () => {
    console.log('onClickStart', props.data);
    props.startClass(props.data);
  };

  let color1 = props.data.UtcActualStartTime ? 'txt-gray' : 'txt-black';
  const color2 = props.data.UtcActualStartTime ? 'txt-gray' : 'txt-black';
  const timezone = props.data.TimezoneAbbreviation ? ' ' + props.data.TimezoneAbbreviation : '';

  try {
    if (props.accountConfig.MinMinutesToEdit > 0) {
      const now = new Date();
      const minClassDate = new Date(props.data.UtcStartTime);
      minClassDate.setMinutes(minClassDate.getMinutes() + props.accountConfig.MinMinutesToEdit * -1);
      console.log('minClass', minClassDate);
      if (now >= minClassDate) {
        color1 = 'txt-gray';
      }
    }
  } catch (error) {
    console.error('ERror ', error);
  }

  return (
    <tr key={'classRow' + props.data.ID}>
      <td className="nucleus-row">
        {' '}
        <span>{props.data.UserFirstName + ' ' + props.data.UserLastName}</span>
      </td>
      <td className="nucleus-row center-align">
        {' '}
        <span>{props.data.Name}</span>
      </td>
      <td className="nucleus-row center-align">
        {' '}
        <span>{props.data.TotalParticipants}</span>
      </td>
      <td className="nucleus-row center-align">
        {' '}
        <span>{moment(props.data.UtcStartTime).format('MM/DD/YYYY hh:mm A') + timezone}</span>
      </td>
      <td className="nucleus-row center-align">
        {' '}
        <span>{moment(props.data.UtcEndTime).format('MM/DD/YYYY hh:mm A') + timezone}</span>
      </td>
      <td className="nucleus-row center-align" onClick={onClickEdit}>
        <span className={'nucleus-table-icon nucleus-link ' + color1}>
          <i className="Tiny material-icons">edit</i>
        </span>
      </td>

      <td className="nucleus-row center-align" onClick={onClickStart}>
        <span className={'nucleus-table-icon nucleus-link ' + color2}>
          <i className="Tiny material-icons">videocam</i>
        </span>
      </td>
    </tr>
  );
};

export default VideoSessionRow;
