import classNames from 'classnames';
import debounce from 'lodash/debounce';
import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';

type sortModeType = 'asc' | 'desc';

export interface Pagination {
  currentPage: number;
  rowsPerPage: number;
  totalRows: number;
  sorting?: sortModeType;
}

type PageData = {
  label: string;
  value: number;
  disabled?: boolean;
  id?: string;
};

type PaginationProps = {
  showPaginationInfo?: boolean;
  currentPage: number;
  rowsPerPage: number;
  totalData: number;
  onPageSelected?: (page: number) => void;
};

const TablePagination = ({
  showPaginationInfo,
  currentPage,
  rowsPerPage,
  totalData,
  onPageSelected = (page: number) => {
    console.log('page', page);
  },
}: PaginationProps) => {
  const [showing, setShowing] = useState<{ from: number; to: number }>({ from: 0, to: 0 });
  const totalPages = useRef(0);

  useEffect(() => {
    const pageLimit = currentPage * rowsPerPage;
    setShowing({
      from: (currentPage - 1) * rowsPerPage + 1,
      to: pageLimit > totalData ? totalData : pageLimit,
    });
  }, [currentPage, totalData, rowsPerPage]);
  const debouncedOnPageSelected = useCallback(
    debounce(page => onPageSelected(page), 100),
    [onPageSelected],
  );

  const onPageIndexClick = (pageData: PageData) => {
    if (pageData.value !== 0) {
      debouncedOnPageSelected(pageData.value);
    }
  };
  const calculatePages = useMemo(() => {
    const pages = Math.ceil(totalData / rowsPerPage);
    if (totalPages.current !== pages) {
      debouncedOnPageSelected(1);
    }
    totalPages.current = pages;

    const pagesArray: PageData[] = [];
    const disablePreviousButton = currentPage === 1;
    pagesArray.push({ label: '<', value: currentPage - 1, disabled: disablePreviousButton, id: 'previousPageButton' });
    pagesArray.push({ label: '1', value: 1, id: 'firstPage' });

    if (pages > 10) {
      let startPage: number, endPage: number;

      if (currentPage <= 5) {
        startPage = 2;
        endPage = 9;
      } else if (currentPage >= pages - 4) {
        startPage = pages - 8;
        endPage = pages - 1;
      } else {
        startPage = currentPage - 3;
        endPage = currentPage + 3;
      }
      if (currentPage > 5) {
        pagesArray.push({ label: '...', value: 0, disabled: true, id: 'after_1' });
      }

      for (let page = startPage; page <= endPage; page++) {
        pagesArray.push({ label: page.toString(), value: page, id: `pagination-button-${page.toString()}` });
      }

      if (endPage < pages - 1) {
        pagesArray.push({ label: '...', value: 0, disabled: true, id: 'before_last' });
      }
      pagesArray.push({ label: pages.toString(), value: pages, id: `pagination-button-${pages.toString()}` });
    } else {
      for (let page = 2; page <= pages; page++) {
        pagesArray.push({ label: page.toString(), value: page, id: `pagination-button-${page.toString()}` });
      }
    }

    const disableNextButton = currentPage === pages || pages <= 1;
    pagesArray.push({ label: '>', value: currentPage + 1, disabled: disableNextButton, id: 'nextPageButton' });

    return pagesArray;
  }, [currentPage, rowsPerPage, totalData]);

  if (totalData < 1 && showPaginationInfo)
    return (
      <PaginationContainer>
        <PaginationEmptyInfo>
          <span>{`Showing 0 of ${totalData}`}</span>
        </PaginationEmptyInfo>
      </PaginationContainer>
    );

  return (
    <PaginationContainer>
      {showPaginationInfo && (
        <PaginationInfo>
          <span>{`Showing ${showing.from} - ${showing.to} of ${totalData}`}</span>
        </PaginationInfo>
      )}
      <PaginationContent>
        <PaginationPagesWrapper>
          {calculatePages.map(page => (
            <PageIndex id={page?.id} disabled={page.disabled} key={page.label.toString() + '-' + page?.id} pageData={page} currentPage={currentPage} onClick={onPageIndexClick} />
          ))}
        </PaginationPagesWrapper>
      </PaginationContent>
    </PaginationContainer>
  );
};

const PageIndex = ({ pageData, currentPage, onClick, disabled, ...props }) => {
  const onClickProp = disabled ? () => {} : () => onClick(pageData);
  return (
    <div
      data-testid={`container-${props?.id}`}
      className={classNames(pageData.value == currentPage ? 'page-index-selected' : 'page-index', disabled ? 'page-index-disabled' : '')}
      onClick={onClickProp}
    >
      <span data-testid={`${props.id}`}>{pageData.label}</span>
    </div>
  );
};

const PaginationContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-top: 5px;
`;
const PaginationEmptyInfo = styled.div`
  display: flex;
  color: #656d77;
  font-family: 'Fira Sans';
`;
const PaginationInfo = styled.div`
  display: flex;
  margin-left: 9px;
  color: #656d77;
  font-family: 'Fira Sans';
`;
const PaginationContent = styled.div`
  display: flex;
  justify-content: center;
`;

const PaginationPagesWrapper = styled.div`
  display: flex;
  border-color: #ccc;
  border-style: solid;
  border-width: 1px;
  border-radius: 28px;
  padding: 5px 10px;
`;

export default TablePagination;
