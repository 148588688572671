import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useUserMeSelector } from '../selectors/user';
import AuthStore from '../stores/AuthStore';
import Message from '../utils/Message';

import {useReactRouterProps} from 'hooks/useReactRouterProps';

const Login2Factor =() =>{

  const navigate = useNavigate();
  const location = useLocation();
  const user = useUserMeSelector();
  const dispatch = useDispatch();
  const { state } = location;
  const [code, setCode] = useState('');
  const [phoneString, setPhoneString] = useState('');
  const [error, setError] = useState('');

  const handleGoToLogin = () => {
    console.log("handleGoToLogin >>");
    navigate("/login");
  };

  const verifyCode = () => {
    console.log('verifyCode');

    if (code && code.length != 4) {
      setError('Invalid Verification Code. It must be 4 digits');
      return;
    }
    AuthStore.verifyCode(code);
  };

  const handleCodeChange = ({ currentTarget }) => {
    setCode(currentTarget.value);
    setError('');
  };

  const validateFieldsFromKeyboard = e => {
    if (e.charCode == 13 || e.keyCode == 13 || e.key == 13) {
      verifyCode();
    }
  };

  const onVerificationCodeSentAction = response => {
    console.log('onVerificationCodeSentAction', response);

    if (response.ok) {
      let responseMessage = 'We have sent the verification code';
      if (response.Mode == 'Telephone') {
        responseMessage = 'We have sent a verification code to the number  ' + response.Telephone;
      } else if (response.Mode == 'Email') {
        responseMessage = 'We have sent a verification code to the email  ' + response.Email;
      }
      setPhoneString(responseMessage);
    } else if (response.waitToResend) {
      setPhoneString('Several codes have been sent to the user, please wait 30 minutes to try again.');
    } else {
      Message.show('There was a problem sending your verification code, please try again.');
    }
  };

  const onVerifyCode = rsp => {
    if (rsp.ok) {
      AuthStore.getMe(dispatch);
    } else {
      setError('Invalid Verification Code');
    }
  };

  const resendCode = () => {
    setPhoneString('Sending verification code... ');
    AuthStore.send2FactorVerification();
  };

  useEffect(() => {
    //Register Listener
    AuthStore.on('onVerificationCodeSent', onVerificationCodeSentAction);
    AuthStore.on('onVerifyCode', onVerifyCode);
    if (state && state.telephone) {
      setPhoneString('We have sent a verification code to the number  ' + state.telephone + '. ');
    } else if (state && state.email) {
      setPhoneString('We have sent a verification code to the email  ' + state.email + '. ');
    }
    return () => {
      // Remove Listener
      AuthStore.removeListener('onVerificationCodeSent', onVerificationCodeSentAction);
      AuthStore.removeListener('onVerifyCode', onVerifyCode);
    };
  }, [state && state.email]);

  const style = {
    height: window.innerHeight,
    background: '#008FFF',
  };

  const verifyCodeLinkClass = `txt-white nucleus-font-small nucleus-font ${code.length ? 'nucleus-submit-btn' : 'nucleus-submit-btn-disabled'}`;

  if (user !== null) {
    if (user.Created) {
      return <Navigate to="/dashboard" state={{ from: location }} />;
    } else {
      return <Navigate to="/completeProfile" state={{ from: location }} />;
    }
  }

  return (
    <div className="nucleus-login" style={style}>
      <div className="alto valign-wrapper">
        <div className="row ancho">
          <div className="col s2 m3 l3 border"></div>
          <div className="col s8 m6 l6 border center-align">
            <br />
            <img className="responsive-img" src="/img/nucleus_2024_logo.png" />
            <br />
            <br />
            <br />
            <p className="txt-white nucleus-page-subtitle">Verification Code</p>
              <br />
            <p className="login-info txt-white nucleus-font-medium nucleus-font">
              {phoneString}
            </p>
            <p className="login-info login-error nucleus-font-medium nucleus-font ">
              <span className={error ? '' : 'hide'}>{error} &nbsp;</span>
            </p>

            <div className="row center-align no-margin">
              <div className="input-field col s1 m3 l3"></div>
              <div className="input-field col s10 m6 l6">
                <input
                  id={'verification-code'}
                  type="password"
                  name="nucleus-password"
                  className="validate nucleus-input-login nucleus-font"
                  placeholder="Verification Code"
                  onKeyPress={validateFieldsFromKeyboard}
                  value={code}
                  onChange={handleCodeChange}
                />
                <div className="resend-code">
                  <span className="txt-white nucleus-font nucleus-font-small">
                    Didn't receive the code?{' '}
                    <a className="nucleus-font-small resend-code-link" onClick={resendCode}>
                      Resend
                    </a>
                  </span>
                </div>
              </div>
              <div className="input-field col s1 m3 l3"></div>
            </div>
            <br />
            <div className="row center-align">
              <div className="col s12">
                <a id={'verify-btn'} className={verifyCodeLinkClass} onClick={verifyCode}>
                  Verify
                </a>
              </div>
            </div>
            <br />
            <span className="nucleus-font-small nucleus-back-link" onClick={handleGoToLogin}>
              Go back
            </span>

            <br />
            <br />
          </div>
          <div className="col s2 m3 l3 border"></div>
        </div>
      </div>
    </div>
  );
}

export default (props) => useReactRouterProps(Login2Factor, props);