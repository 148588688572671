import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import styled from 'styled-components';
import { CalendarIconSVG } from '../../../assets/CalendarIcon';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  align-items: center;
  background-color: #fff;
  padding-left: 10px;
`;

const ColumnLeftAligned = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Note = styled.p`
  color: #0a313f;
  text-align: center;
  font-size: 16px;
  font-family: Barlow;
`;

export const DayPicker = ({
  scheduleDate,
  disablePast = false,
  onRequestCallFromDateChange,
  checkIfInPast,
}: {
  scheduleDate: Date;
  disablePast?: boolean;
  onRequestCallFromDateChange: (date: MaterialUiPickersDate) => void;
  checkIfInPast: (date: Date) => void;
}) => {
  const dateFormat = 'MM/DD/YYYY';

  return (
    <Row
      style={{
        justifyContent: 'space-between',
        fontFamily: 'Barlow',
      }}
    >
      <ColumnLeftAligned>
        <Note>Select a date to see available appointment slots</Note>
        <InputContainer>
          <CalendarIconSVG />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
              disablePast={disablePast}
              variant="dialog"
              className="browser-default ui-nucleus"
              value={scheduleDate}
              onFocus={() => checkIfInPast(scheduleDate)}
              onBlur={() => checkIfInPast(scheduleDate)}
              onClose={() => checkIfInPast(scheduleDate)}
              defaultValue={Date.now()}
              onChange={onRequestCallFromDateChange}
              onError={console.log}
              style={styles.dateTimePickerInput}
              format={dateFormat}
            />
          </MuiPickersUtilsProvider>
        </InputContainer>
      </ColumnLeftAligned>
    </Row>
  );
};

const styles = {
  dateTimePickerInput: {
    backgroundColor: '#FFF',
    padding: 10,
    width: '100%',
  },
};
