export const EmptyVideoView = ({ index }) => {
  return (
    <div key={index} className={`no-gradient-border`}>
      <div
        style={{
          flex: 1,
          display: 'flex',
          borderRadius: 10,
          padding: 5,
        }}
      ></div>
    </div>
  );
};
