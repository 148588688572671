import { CalleeEntity, FullCallEntity } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/slices/callSlice';
import { CallType } from '@nucleus-care/nucleuscare-connect-signaling';

import { VideoViewData } from './callLayout.types';
import { EmptyVideoView } from './components/EmptyVideoView';
import { VideoView } from './components/VideoView';

export const aspectRatio = 9 / 16;

const buildCallData = ({ data, type }: { data: FullCallEntity; type: CallType }) => {
  const deviceId = data.devicesIds?.[0];
  const nameArray = data.name?.split(' ');
  let intitials = '';
  if (data.firstName && data.lastName) {
    intitials = data.firstName?.charAt(0) + data.lastName?.charAt(0);
  }

  if (nameArray?.length > 1) {
    intitials = nameArray[0]?.charAt(0) + nameArray[1]?.charAt(0);
  }
  const videoCallData: VideoViewData = {
    entityId: data.entityId,
    deviceId: deviceId,
    type,
    host: data.host,
    status: data.status,
    fullName: data.name,
    Initials: intitials,
  };
  return videoCallData;
};

export const OneParticipant = ({ callee, participants }: { callee: CalleeEntity; participants: FullCallEntity[] }) => {
  participants.splice(1, 0, {
    ...callee,
    host: true,
    status: 'connected',
  } as unknown as FullCallEntity);
  const VideoParticipants = participants.map((data, index, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    return <VideoView key={index} data={videoCallData} array={array} index={index} />;
  });
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'column' }}>
      {VideoParticipants}
    </div>
  );
};

export const TwoParticipant = ({ callee, participants }: { callee: CalleeEntity; participants: FullCallEntity[] }) => {
  participants.splice(1, 0, {
    ...callee,
    host: true,
    status: 'connected',
  } as FullCallEntity);
  const VideoParticipants = [];
  participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    if (key == 0) {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} uniqueL={true} uniqueR={true} />);
    } else {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} />);
    }
  });
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const ThreeParticipant = ({ callee, participants }) => {
  participants.splice(2, 0, {
    ...callee,
    host: true,
    status: 'connected',
  });
  const VideoParticipants = participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    return <VideoView key={key} {...{ data: videoCallData, index: key, array }} />;
  });
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const FourParticipant = ({ callee, participants }) => {
  participants.splice(4, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    return <VideoView key={key} {...{ data: videoCallData, index: key, array }} />;
  });
  VideoParticipants.push(<EmptyVideoView {...{ index: VideoParticipants.length }} />);

  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};
export const FiveParticipant = ({ callee, participants }) => {
  participants.splice(3, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = [];
  participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} />);
  });
  return (
    // <div style={{ display: "flex", flexDirection: "column" }}>
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const SixParticipant = ({ callee, participants }) => {
  participants.splice(6, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    return <VideoView key={key} {...{ data: videoCallData, index: key, array }} />;
  });
  VideoParticipants.push(<EmptyVideoView {...{ index: VideoParticipants.length }} />);
  VideoParticipants.push(<EmptyVideoView {...{ index: VideoParticipants.length + 1 }} />);
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const SevenParticipant = ({ callee, participants }) => {
  participants.splice(5, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = [];
  participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    if (key == 0) {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} uniqueL={true} />);
    } else if (key == 1) {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} uniqueR={true} />);
    } else {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} />);
    }
  });
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const EightParticipant = ({ callee, participants }) => {
  participants.splice(6, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = [];
  participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} />);
  });
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const NineParticipant = ({ callee, participants }) => {
  participants.splice(9, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = [];
  participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} />);
  });
  VideoParticipants.push(<EmptyVideoView {...{ index: VideoParticipants.length }} />);
  VideoParticipants.push(<EmptyVideoView {...{ index: VideoParticipants.length + 1 }} />);
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const TenParticipant = ({ callee, participants }) => {
  participants.splice(8, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = [];
  participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    if (key == 0) {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} uniqueL={true} />);
    } else if (key == 1) {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} uniqueR={true} />);
    } else {
      VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} />);
    }
  });
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};

export const ElevenParticipant = ({ callee, participants }) => {
  participants.splice(9, 0, {
    ...callee,
    host: true,
  });
  const VideoParticipants = [];
  participants.map((data, key, array) => {
    const videoCallData = buildCallData({
      data,
      type: callee.type,
    });
    VideoParticipants.push(<VideoView key={key} {...{ data: videoCallData, index: key, array }} />);
  });
  return (
    <div id="gallery" style={{ display: 'flex', flexDirection: 'row' }}>
      {VideoParticipants}
    </div>
  );
};
