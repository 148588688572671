import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { UIAvatar, UICard, UISwitch } from '../../components/UI';
import authStore from '../../stores/AuthStore';
import CareUserStore from '../../stores/CareUserStore';
import message from '../../utils/Message';
import { EditSVG, RemoveSVG } from './assets/UserProfileBannerSVGs';

const InfoLabelStyled = styled.span`
  // font-family: Fira Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 146, 255, 1);
`;
const InfoValueStyled = styled.span`
  //styleName: Typography/Body;
  // font-family: Fira Sans;
  min-height: 30px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0px;
  color: rgba(10, 49, 63, 1);
  background-color: ${props => (props.style?.backgroundColor ? props.style?.backgroundColor : 'transparent')};
`;

const NameTitle = styled.span`
  // font-family: Barlow;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(10, 49, 63, 1);
`;

const InfoLabel = ({ label }) => {
  return <InfoLabelStyled>{label}</InfoLabelStyled>;
};
const InfoValue = ({ value, style = {} }) => {
  return (
    <InfoValueStyled
      style={{
        display: 'flex',
        alignItems: 'end',
        ...style,
      }}
    >
      {value}
    </InfoValueStyled>
  );
};

const InfoSection = ({ children, style = {} }) => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export const UserProfileBanner = ({
  userId,
  fullName,
  imageSrc,
  address,
  phoneNumber,
  isAdmin,
  isOnDuty,
  userProfileCompleted,
  userOnlyUrgent,
  onHandleProfileMode,
  onHandleRemoveUser,
  removeVisibility,
}) => {
  const [userOnlyUrgentState, setUserOnlyUrgentState] = useState(userOnlyUrgent);
  const [userOnDutyState, setUserOnDutyState] = useState(isOnDuty);
  const [listenersCreated, setListenersCreated] = useState(false);
  useEffect(() => {
    setUserOnDutyState(isOnDuty);
    setUserOnlyUrgentState(userOnlyUrgent);
  }, [isOnDuty, userOnlyUrgent]);
  const handleOnDutyProfile = () => {
    const value = !userOnDutyState;
    CareUserStore.setUserOnDuty({
      UserID: userId,
      IsOnDuty: value,
    });
  };
  const handleOnlyUrgent = () => {
    const value = !userOnlyUrgentState;
    CareUserStore.setUserOnlyUrgent({
      UserID: userId,
      OnlyUrgent: value,
    });
  };
  const onSetUserOnDutyAction = result => {
    if (result.userId == userId) {
      if (result.ok) {
        setUserOnDutyState(result.onDuty);
        if (result.onDuty) {
          message.show(fullName + ' is On Duty now!');
        } else {
          message.show(fullName + ' is Off now!');
        }

        if (result.userId == authStore.getUserID()) {
          authStore.setOnDuty(result.onDuty);
        }
      } else {
        message.show('Error updating ' + fullName + ' OnDuty, please try again.');
      }
    }
  };
  const onSetUserOnlyUrgentAction = result => {
    if (result.userId == userId) {
      if (result.ok) {
        setUserOnlyUrgentState(result.onlyUrgent);
        if (result.onlyUrgent) {
          message.show('Urgent calls only enabled');
        } else {
          message.show('Urgent calls only disabled');
        }

        if (result.userId == authStore.getUserID()) {
          authStore.setOnlyUrgent(result.onlyUrgent);
        }
      } else {
        message.show('Error updating Urgent Calls Only, please try again.');
      }
    }
  };
  useEffect(() => {
    if (!userId) return;
    if (!listenersCreated) {
      setListenersCreated(true);
      CareUserStore.on('onSetUserOnDuty', onSetUserOnDutyAction);
      CareUserStore.on('onSetUserOnlyUrgent', onSetUserOnlyUrgentAction);
    }
    return () => {
      CareUserStore.removeListener('onSetUserOnDuty', onSetUserOnDutyAction);
      CareUserStore.removeListener('onSetUserOnlyUrgent', onSetUserOnlyUrgentAction);
    };
  }, [userId]);

  return (
    <UICard
      style={{
        width: '100%',
        height: 152,
        backgroundColor: '#EBF2FE',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <UIAvatar size="large" src={imageSrc || '/img/user_placeholder.png'} />
        </div>
        <div style={{ flex: 1, paddingLeft: 20 }}>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <NameTitle>{fullName}</NameTitle>
              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {/* Edit Button */}
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 30,
                    borderRadius: 5,
                    cursor: 'pointer',
                    marginLeft: 20,
                  }}
                  onClick={() => onHandleProfileMode()}
                >
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: '12px',
                      letterSpacing: 0,
                      textAlign: 'center',
                      color: '#0092FF',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Edit{' '}
                    <span style={{ marginLeft: 5 }}>
                      <EditSVG />
                    </span>
                  </span>
                </div>
                {/* Remove Button */}
                <div
                  style={{
                    display: !removeVisibility ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 30,
                    borderRadius: 5,
                    cursor: 'pointer',
                    marginLeft: 20,
                  }}
                  onClick={() => onHandleRemoveUser()}
                >
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: '12px',
                      letterSpacing: 0,
                      textAlign: 'center',
                      color: '#FF4C3F',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Remove{' '}
                    <span style={{ marginLeft: 5 }}>
                      <RemoveSVG />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flex: 2, flexDirection: 'row' }}>
              <InfoSection>
                <InfoLabel label="Address" />
                <InfoValue value={address} />
              </InfoSection>
              <InfoSection>
                <InfoLabel label="Phone Number" />
                <InfoValue value={phoneNumber} />
              </InfoSection>
              <InfoSection>
                <InfoLabel label="Admin" />
                <InfoValue
                  style={{
                    marginLeft: 18,
                  }}
                  value={isAdmin ? 'Y' : 'N'}
                />
              </InfoSection>
              <InfoSection>
                <InfoLabel label="Receive Calls" />
                <div
                  style={{
                    marginLeft: 24,
                  }}
                >
                  <UISwitch hideCheck disabled={!userProfileCompleted} isEnabled={userOnDutyState} onToggle={handleOnDutyProfile} />
                </div>
              </InfoSection>
              <InfoSection
                style={{
                  alignItems: 'end',
                }}
              >
                <InfoLabel label="Urgent Calls Only" />
                <div
                  style={{
                    marginRight: 40,
                  }}
                >
                  <UISwitch hideCheck disabled={!userProfileCompleted} isEnabled={userOnlyUrgentState} onToggle={handleOnlyUrgent} />
                </div>
              </InfoSection>
            </div>
          </div>
        </div>
      </div>
    </UICard>
  );
};
