import moment from 'moment';
import React from 'react';

type VideoSessionModalInfoParams = {
  key: string;
  accountConfig: any;
  classSessionsData: any;
};

const VideoSessionModalInfo = (props: VideoSessionModalInfoParams) => {
  const startTime = moment(props.classSessionsData.UtcStartTime).format('YYYY-MM-DD hh:mm A');
  const endTime = moment(props.classSessionsData.UtcEndTime).format('YYYY-MM-DD hh:mm A');

  return (
    <div className="row" key={props.key}>
      <div className="col s4">
        <span className="nucleus-labels">
          <b>{'Name'}</b>
        </span>
      </div>
      <div className="col s8">
        <span className="nucleus-labels">{props.classSessionsData.FirstName + ' ' + props.classSessionsData.LastName}</span>
      </div>
      <div className="col s4">
        <span className="nucleus-labels">
          <b>{props.accountConfig.TabNameLabel}</b>
        </span>
      </div>
      <div className="col s8">
        <span className="nucleus-labels">{props.classSessionsData.Name}</span>
      </div>
      <div className="col s4">
        <span className="nucleus-labels">
          <b>{'Start Time'}</b>
        </span>
      </div>
      <div className="col s8">
        <span className="nucleus-labels">{startTime}</span>
      </div>
      <div className="col s4">
        <span className="nucleus-labels">
          <b>{'End Time'}</b>
        </span>
      </div>
      <div className="col s8">
        <span className="nucleus-labels">{endTime}</span>
      </div>
    </div>
  );
};

export default VideoSessionModalInfo;
