import moment from 'moment';
import React, { useState, useEffect } from 'react';

import { SortArrowDown, SortArrowUp } from '../../../../components/NucleusTable/assets/NucleusTableSVGs';

import type { ProviderAvailabilityTableRow } from '../../provider.types';

export type sortModeType = 'asc' | 'desc';

const ScheduleAvailabilityTable = ({
  data,
  sortMode,
  stickyThead = false,
  onEditClick = data => {
    console.log('onEditClick', data);
  },
  onDeleteClick = data => {
    console.log('onEditClick', data);
  },
  onSortClick = (sort: sortModeType) => {
    console.log('onSortClick', sort);
  },
}) => {
  //const [sortMode, setSortMode] = useState<sortModeType>("asc");
  const [tableRows, setTableRows] = useState<React.ReactNode[]>([]);

  useEffect(() => {
    if (data) {
      generateTableRows(sortMode);
    }
  }, [data]);

  useEffect(() => {
    generateTableRows(sortMode);
  }, [sortMode]);

  const renderSortIcon = () => {
    return sortMode == 'asc' ? <SortArrowUp /> : <SortArrowDown />;
  };

  const onSortChange = () => {
    if (sortMode == 'desc') {
      //setSortMode("asc");
      onSortClick('asc');
    } else if (sortMode == 'asc') {
      //setSortMode("desc");
      onSortClick('desc');
    }
  };

  const generateTableRows = (sort: sortModeType) => {
    const tableRows: React.ReactNode[] = [];
    let sortedArray: ProviderAvailabilityTableRow[];
    setTableRows([]);
    if (sort == 'asc') {
      sortedArray = [...data].sort((a, b) => {
        return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
      });
    } else {
      sortedArray = [...data].sort((a, b) => {
        // return (
        //   new Date(b.startTime).getTime() - new Date(a.startTime).getTime()
        // );
        // If sort is "desc", then sort descending by dates (y,m,d) and then
        // in the same day sort ascending by the hours
        const dateA = new Date(a.startTime);
        const dateB = new Date(b.startTime);
        if (dateA.getFullYear() === dateB.getFullYear() && dateA.getMonth() === dateB.getMonth() && dateA.getDate() === dateB.getDate()) {
          return dateA.getTime() - dateB.getTime();
        }
        return dateB.getTime() - dateA.getTime();
      });
    }

    let priorDate: string | null = null;
    let count = 0;
    for (let index = 0; index < sortedArray.length; index++) {
      const row: ProviderAvailabilityTableRow = sortedArray[index];
      let dateLabel = row.dateLabel;
      if (priorDate && priorDate == row.dateLabel) {
        dateLabel = '';
      } else {
        priorDate = row.dateLabel;
        if (index > 0) {
          tableRows.push(<DisabledTableRow count={count} />);
          count++;
        }
      }

      tableRows.push(
        <tr key={count} style={row.enabled ? styles.validDate : styles.passedDate}>
          <td>
            <span style={{ fontFamily: 'Fira Sans', fontWeight: 500 }}>{dateLabel}</span>
          </td>
          <td>
            <span>{row.startTime ? moment(row.startTime).format('h:mm A') : ''}</span>
          </td>
          <td>
            <span>{row.endTime ? moment(row.endTime).format('h:mm A') : ''}</span>
          </td>
          <td className="center-align">
            <span>{row.slotDuration}</span>
          </td>
          <td className="center-align">
            <span>{row.availableSlots}</span>
          </td>
          <td>
            {row.enabled ? (
              <div>
                <img
                  src="/img/edit_icon.png"
                  style={{
                    cursor: row.scheduledAppoiments ? 'not-allowed' : 'pointer',
                    opacity: row.scheduledAppoiments ? 0.5 : 1,
                  }}
                  onClick={e => {
                    e.stopPropagation();
                    if (row.scheduledAppoiments) return;
                    onEditClick(row);
                  }}
                />
                <img
                  src="/img/delete_icon.png"
                  style={{ cursor: 'pointer', marginLeft: 16 }}
                  onClick={e => {
                    e.stopPropagation();
                    onDeleteClick(row);
                  }}
                />
              </div>
            ) : (
              <span></span>
            )}
          </td>
        </tr>,
      );
      count++;
    }
    setTableRows(tableRows);
  };

  return (
    <table className="b-table b-table-colored b-table-hover b-table-active">
      <thead className={stickyThead ? 'nucleus-table-header sticky-thead' : 'nucleus-table-header'}>
        <tr>
          <th className="nucleus-table-header left-align">
            <span style={{ cursor: 'pointer' }} onClick={onSortChange}>
              Start Date
              {renderSortIcon()}
            </span>
          </th>
          <th className="nucleus-table-header left-align">
            <span>From</span>
          </th>
          <th className="nucleus-table-header left-align">
            <span>To</span>
          </th>
          <th className="center-align">
            <span className="nucleus-table-header">Length of Time Slots (minutes)</span>
          </th>
          <th className="center-align">
            <span className="nucleus-table-header">Number of Doctors</span>
          </th>
          <th className="nucleus-table-header left-align">
            <span></span>
          </th>
        </tr>
      </thead>
      <tbody>{tableRows}</tbody>
    </table>
  );
};

const DisabledTableRow = ({ count }) => {
  return (
    <tr key={count} className="disabled-row">
      <td colSpan={6}>
        <span style={{ color: 'transparent' }}>{'>'}</span>
      </td>
    </tr>
  );
};

const styles = {
  passedDate: {
    color: '#ACB4BE',
  },
  validDate: {
    color: 'black',
    fontFamily: 'FiraSans',
  },
};

export default ScheduleAvailabilityTable;
