import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import { UIModal, UIInput } from '../../../../components/UI';
import { ModalAction } from '../../../../components/UI/Modals/Base/ActionButtons';
import authStore from '../../../../stores/AuthStore';
import Message from '../../../../utils/Message';
const InputGroup = styled.div`
  margin-bottom: 20px;
`;
const SubTitle = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: #0a313f;
`;
const SubmitErrorLabel = styled.p`
  font-size: 12px;
  color: red;
  font-weight: 500;
`;
const TITLES = {
  add: 'Add a New Email Address',
  edit: 'Edit Email Address',
};
export const AddEditProviderGroupEmail = ({
  closeModal,
  isOpen,
  receiver,
  group,
  titles,
  tier,
}: {
  closeModal: () => void;
  isOpen: boolean;
  receiver: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  group: {
    id: string;
    name: string;
  } | null;
  titles: {
    ProviderID: string;
    UserRoleName: string;
    UserRoleNamePlural: string;
    Tier1: string;
    Tier1Plural: string;
    Tier2: string;
    Tier2Plural: string;
    Tier3: string;
    Tier3Plural: string;
    LocaleTag: string;
  };
  tier: string;
}) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [mode, setMode] = useState('add');

  useEffect(() => {
    if (receiver) {
      setMode('edit');
      setFirstName(receiver.firstName);
      setLastName(receiver.lastName);
      setEmail(receiver.email);
    }
  }, [receiver]);
  const handleFirstName = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const isValid = /^[a-zA-Z0-9\s]+$/.test(inputValue);
    if (!inputValue.trim()) {
      setFirstNameError('Please enter a value');
    } else if (!isValid) {
      setFirstNameError('Please enter valid characters (alphanumeric and spaces only).');
    } else {
      setFirstNameError('');
    }
    setFirstName(inputValue);
  };
  const handleLastName = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const isValid = /^[a-zA-Z0-9\s]+$/.test(inputValue);
    if (!inputValue.trim()) {
      setLastNameError('Please enter a value');
    } else if (!isValid) {
      setLastNameError('Please enter valid characters (alphanumeric and spaces only).');
    } else {
      setLastNameError('');
    }
    setLastName(inputValue);
  };
  const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue); // Email validation regex

    if (!inputValue.trim()) {
      setEmailError('Please enter an email address.');
    } else if (!isValidEmail) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
    setEmail(inputValue);
  };
  const addReceiver = () => {
    const providerId = authStore.getProviderID();
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/.test(email);
    const isValidFirstName = /^[a-zA-Z0-9\s]+$/.test(firstName);
    const isValidLastName = /^[a-zA-Z0-9\s]+$/.test(lastName);

    if (!firstName.trim() || !lastName.trim()) {
      Message.show('Please enter a value');
      return;
    } else if (!isValidFirstName || !isValidLastName) {
      Message.show('Please enter valid characters (alphanumeric and spaces only).');
      return;
    }
    if (!isValidEmail) {
      Message.show('Please enter a valid email address');
      return;
    }
    if (email.length > 254) {
      Message.show('Email cannot exceed 254 characters.');
      return;
    }
    httpApi
      .post(`/providers/${providerId}/groups/${group?.id}/receivers`, {
        firstName: firstName,
        lastName: lastName,
        email: email,
      })
      .then(response => {
        console.log('response: ', response);
        Message.show('Added new Recipient successfully');
        closeModalHandler();
      })
      .catch(error => {
        Message.show('Error Adding new Recipient');
        setSubmitError(`${error?.response?.data?.message}`);
        setFirstName('');
        setLastName('');
        setEmail('');
      });
  };

  const editReceiver = () => {
    const providerId = authStore.getProviderID();
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/.test(email);
    const isValidFirstName = /^[a-zA-Z0-9\s]+$/.test(firstName);
    const isValidLastName = /^[a-zA-Z0-9\s]+$/.test(lastName);

    if (!firstName.trim() || !lastName.trim()) {
      Message.show('Please enter a value');
      return;
    } else if (!isValidFirstName || !isValidLastName) {
      Message.show('Please enter valid characters (alphanumeric and spaces only).');
      return;
    }
    if (!isValidEmail) {
      Message.show('Please enter a valid email address');
      return;
    }
    if (email.length > 254) {
      Message.show('Email cannot exceed 254 characters.');
      return;
    }
    httpApi
      .put(`/providers/${providerId}/groups/${group?.id}/receivers/${receiver?.id}`, {
        firstName: firstName,
        lastName: lastName,
        email: email,
      })
      .then(response => {
        console.log('response: ', response);
        Message.show('Edited Recipient successfully');
        closeModalHandler();
      })
      .catch(error => {
        console.log('Error editing email: ', error);
        Message.show('Error editing Recipient');
        setSubmitError(`${error}`);
      });
  };

  const submit = () => {
    if (mode === 'add') {
      addReceiver();
    } else {
      editReceiver();
    }
  };
  const closeModalHandler = () => {
    closeModal();
    setFirstName('');
    setLastName('');
    setEmail('');
    setFirstNameError('');
    setLastNameError('');
    setEmailError('');
    setSubmitError('');
    setMode('add');
  };
  const getActions = () => {
    const data: ModalAction[] = [
      {
        label: 'Cancel',
        onClick: closeModalHandler,
        buttonVariant: 'secondary',
      },
      {
        label: mode === 'edit' ? 'Save' : 'Add',
        onClick: submit,
        disabled: firstName === '' || lastName === '' || email === '',
      },
    ];
    return data;
  };
  return (
    <UIModal isOpen={isOpen} close={closeModalHandler} actions={getActions()} title={TITLES[mode]}>
      <SubTitle>{`You are ${mode === 'edit' ? 'editing' : 'adding'} an email address to ${titles?.[tier]}: ${group?.name}`}</SubTitle>
      <InputGroup>
        <UIInput label="First Name:" placeholder="First Name" value={firstName} onChange={handleFirstName} error={firstNameError} />
        <UIInput label="Last Name:" placeholder="Last Name" value={lastName} onChange={handleLastName} error={lastNameError} />
        <UIInput label="Email:" placeholder="Email" value={email} onChange={handleEmail} error={emailError} />
      </InputGroup>
      {<SubmitErrorLabel>{submitError}</SubmitErrorLabel>}
    </UIModal>
  );
};
