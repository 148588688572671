import { debounce } from '@mui/material';
import { onCallEvent } from '@nucleus-care/nucleuscare-backend-client';
import { RootState } from '@nucleus-care/nucleuscare-backend-client/lib/typescript/store';
import { NucleusCommunicationSignaling } from '@nucleus-care/nucleuscare-connect-signaling';
import { ReCallSVG } from 'components/CallSVGs/CallsSVGs';
import CallButton from 'components/WebRTCCallView/CallButton';
import { useIsMultiCallSelector } from 'components/WebRTCCallView/selector';
import { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { aspectRatio } from '../CallLayout';
import { VideoViewData } from '../callLayout.types';
import { AudioView } from './AudioView';
import { VideoCallControls } from './VideoCallControls';
import { VideoRenderer } from './VideRenderer';

export const VideoView = ({ data, index, array, uniqueL, uniqueR }: { data: VideoViewData; index: number; array: any; uniqueL?: boolean; uniqueR?: boolean }) => {
  console.log('VideoView ===> index', index);
  console.log('VideoView ===> data', data);

  const dispatch = useDispatch();

  // const isLoudest = useLoudestPeerSelector(data.deviceId)
  const isMultiCall = useIsMultiCallSelector();

  const accountConfig = useSelector((state: RootState) => {
    //console.log("VideoView useSelector", state);
    return state.accountClassSessionConfig.config;
  });

  const callState = useSelector((state: RootState) => {
    //console.log("SenessCall useSelector 2", state);
    return state.call.callParticipantsStatus ? state.call.callParticipantsStatus : null;
  });

  const callParticipantSizeStore = useSelector((state: RootState) => {
    //console.log("SenessCall useSelector 2", state.call.callParticipantsVideoSizes);
    return state.call.callParticipantsVideoSizes ? state.call.callParticipantsVideoSizes : null;
  });

  const communicationSingleton = NucleusCommunicationSignaling.getInstance();

  const size = array.length;

  const [stateVideoBgColor, setStateVideoBgColor] = useState('transparent');

  let specialMarginL = 10;
  let specialMarginR = 10;
  const videoContainerH = useRef(320);
  const [stateInCall, setStateInCall] = useState(data.status == 'connected');
  const [stateCalling, setStateCalling] = useState(true);
  const [stateDivClass, setStateDivClass] = useState('active-gradient-border');
  const [stateOfflineLabel, setStateOfflineLabel] = useState('left the class');

  useEffect(() => {
    handleResize();
    initializePeerState();
  }, []);

  useEffect(() => {
    console.log('useEffect CallLayout callState', callState);
    updatePeerState();
  }, [callState]);

  useEffect(() => {
    console.log('useEffect CallLayout callState', callState);
    updatePeerVideoSize();
  }, [callParticipantSizeStore]);

  const initializePeerState = () => {
    let defaultState = 'connected';
    if (callState[data.deviceId]) {
      if (callState[data.deviceId].status == 'busy') {
        defaultState = 'busy';
      } else if (callState[data.deviceId].status == 'DND') {
        defaultState = 'DND';
      }
      if (callState[data.deviceId].status) {
        defaultState = callState[data.deviceId].status;
      }
    }
    dispatch(
      onCallEvent({
        peerId: data.deviceId,
        status: defaultState,
      }),
    );
  };

  const handleResize = () => {
    // console.log("resized to: ", window.innerWidth, "x", window.innerHeight);
    const parentView = window.document.getElementById('senessCallView');
    // console.log("parentView",parentView);

    const gallery = document.getElementById('gallery');
    const gallery2 = document.getElementById('gallery2');

    let cols = 1;

    console.log('VideoView ==> size', size);
    let screenCssPixelRatio = (window.outerWidth - 10) / window.innerWidth;
    screenCssPixelRatio = parseFloat(screenCssPixelRatio.toFixed(2));
    console.log('VideoView ==> screenCssPixelRatio', screenCssPixelRatio);
    let zoomLevel = 1;
    if (screenCssPixelRatio >= 0.46 && screenCssPixelRatio <= 0.54) {
      zoomLevel = 1; //"-4";
    } else if (screenCssPixelRatio <= 0.64) {
      zoomLevel = 1; //"-3";
    } else if (screenCssPixelRatio <= 0.76) {
      zoomLevel = 1; //"-2";
    } else if (screenCssPixelRatio <= 0.92) {
      zoomLevel = 1; //"-1";
    } else if (screenCssPixelRatio <= 1.1) {
      zoomLevel = 1; //"0";
    } else if (screenCssPixelRatio <= 1.32) {
      zoomLevel = 1.05; //"1";
    } else if (screenCssPixelRatio <= 1.58) {
      zoomLevel = 1.1; //"2";
    } else if (screenCssPixelRatio <= 1.9) {
      zoomLevel = 1.15; //"3";
    } else if (screenCssPixelRatio <= 2.28) {
      zoomLevel = 1.2; //"4";
    } else if (screenCssPixelRatio <= 2.7) {
      zoomLevel = 1.25; //"5";
    } else {
      zoomLevel = 1.001;
    }
    console.log('VideoView ==> zoomLevel', zoomLevel);

    let _cols = 1;
    switch (size) {
      case 1:
      case 2:
        _cols = 3;
        cols = 1;
        break;
      case 3:
        _cols = 4;
        cols = 6.95 / zoomLevel;
        console.log('VideoView ==> zoomLevel case 3 cols = ', cols);
        break;
      case 4:
        _cols = 4;
        cols = 7.05 / zoomLevel;
        console.log('VideoView ==> zoomLevel case 4 cols = ', cols);
        break;
      case 5:
        _cols = 4;
        cols = 10.65 / zoomLevel;
        console.log('VideoView ==> zoomLevel case 4 cols = ', cols);
        break;
      case 6:
        _cols = 6;
        cols = 8.95 / zoomLevel;
        console.log('VideoView ==> zoomLevel case 6 cols = ', cols);
        break;
      case 7:
      case 8:
      case 9:
        _cols = 6;
        cols = 10.75 / zoomLevel;
        console.log('VideoView ==> zoomLevel case 7,8,9 cols = ', cols);
        break;
      case 10:
      case 11:
      case 12:
        _cols = 6;
        cols = 14.4 / zoomLevel;
        console.log('VideoView ==> zoomLevel case 10,11,12 cols = ', cols);
        break;
      default:
        _cols = 8;
        cols = 14.75 / zoomLevel;
        console.log('VideoView ==> zoomLevel case default cols = ', cols);
        break;
        break;
    }

    const rows = Math.ceil(2 / cols);
    const hScale = parentView.offsetWidth / (cols * aspectRatio);
    const vScale = parentView.offsetHeight / rows;
    let width;
    let height;

    if (hScale <= vScale) {
      console.log('Case 1');
      width = Math.floor(parentView.offsetWidth / cols);
      height = Math.floor(width / aspectRatio);
    } else {
      console.log('Case 2');
      height = Math.floor(parentView.offsetHeight / rows);
      width = Math.floor(height * aspectRatio);
    }

    // console.log("VideoView ==> hScale", hScale);
    // console.log("VideoView ==> vScale", vScale);
    // console.log("VideoView ==> height", height);
    // console.log("VideoView ==> width", width);

    videoContainerH.current = width * 1.5 - 10;
    gallery.style.setProperty('--width', width * 1.5 + 'px');
    gallery.style.setProperty('--height', height * 1.5 + 'px');
    gallery.style.setProperty('--cols', _cols + '');

    if (gallery2) {
      gallery2.style.setProperty('--width', width * 1.5 + 'px');
      gallery2.style.setProperty('--height', height * 1.5 + 'px');
      gallery2.style.setProperty('--cols', _cols + '');
    }
  };

  useEffect(() => {
    const debouncedRecalculateLayout = debounce(handleResize, 50);
    window.addEventListener('resize', debouncedRecalculateLayout);

    return () => {
      window.removeEventListener('resize', debouncedRecalculateLayout);
    };
  }, []);

  const updatePeerState = () => {
    console.log('updatePeerState', data.deviceId, callState[data.deviceId]);
    if (callState[data.deviceId]) {
      console.log('updatePeerState', callState[data.deviceId].status);
      if (callState[data.deviceId].status == 'quitted') {
        setStateInCall(false);
        setStateCalling(false);
        setStateDivClass('offline-gradient-border');
        setStateOfflineLabel('left the ' + accountConfig.TabNameLabel.toLowerCase());
      } else if (callState[data.deviceId].status == 'rejected') {
        setStateInCall(false);
        setStateCalling(false);
        setStateDivClass('offline-gradient-border');
        setStateOfflineLabel('rejected the ' + accountConfig.TabNameLabel.toLowerCase());
      } else if (callState[data.deviceId].status == 'disconnected') {
        setStateInCall(false);
        setStateCalling(false);
        setStateDivClass('offline-gradient-border');
        setStateOfflineLabel('was disconnected');
      } else if (callState[data.deviceId].status == 'timedOut') {
        setStateInCall(false);
        setStateCalling(false);
        setStateDivClass('offline-gradient-border');
        setStateOfflineLabel('call timed out');
      } else if (callState[data.deviceId].status == 'failed') {
        setStateInCall(false);
        setStateCalling(false);
        setStateDivClass('offline-gradient-border');
        setStateOfflineLabel('call failed');
      } else if (callState[data.deviceId].status == 'busy') {
        setStateInCall(false);
        setStateCalling(false);
        setStateDivClass('offline-gradient-border');
        setStateOfflineLabel('busy');
      } else if (callState[data.deviceId].status == 'DND') {
        setStateInCall(false);
        setStateCalling(false);
        setStateDivClass('offline-gradient-border');
        setStateOfflineLabel('is on DND');
      } else if (callState[data.deviceId].status == 'connected') {
        setStateInCall(true);
        setStateCalling(false);
        if (isMultiCall) {
          setStateDivClass('online-gradient-border');
          if (data.host) {
            setStateDivClass('active-gradient-border');
          }
        } else {
          setStateDivClass('active-gradient-border');
        }
        setStateOfflineLabel('connected');
      }
    }
  };

  const updatePeerVideoSize = () => {
    console.log('updatePeerVideoSize', data.deviceId);
    if (callParticipantSizeStore && callParticipantSizeStore[data.deviceId] && callParticipantSizeStore[data.deviceId].ratioFactor == '4:3') {
      setStateVideoBgColor('#7A7A7A');
    }
  };

  const labelFontSize = 20;

  if (uniqueL) {
    specialMarginL = 100;
  }
  if (uniqueR) {
    specialMarginR = 100;
  }

  console.log('CallLayout isPreview', data.type == 'Audio' || !data.host ? false : true);

  return (
    <div key={index} className={`${stateDivClass} gradient-bg video-container-cc`} style={{ marginLeft: specialMarginL, marginRight: specialMarginR }} id={data.deviceId}>
      <VideoRenderer key={index} stateInCall={stateInCall} stateVideoBgColor={stateVideoBgColor} videoContainerH={videoContainerH} data={data} />

      <AudioView data={data} />

      <VideoCallControls {...{ data, labelFontSize, stateInCall, communicationSingleton }} />

      {!stateInCall && (
        <div className="offline-div-container">
          <div className="offline-div" style={{ color: 'white', margin: 0 }}>
            <div className="offlinePeerName" style={{ flex: 1 }}>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <span className="nucleus-font-family-medium " style={{ fontSize: 24 }}>
                  {data.fullName}
                </span>
              </div>
              <div style={{ width: '100%', textAlign: 'center', marginTop: -3, marginBottom: 10 }}>
                <span className="nucleus-font-light " style={{ fontSize: 20, marginBottom: 5 }}>
                  {stateOfflineLabel}
                </span>
              </div>
              <div>
                {!stateCalling && (
                  <CallButton
                    defaultValue={stateInCall}
                    text={'RECALL '}
                    customIcon={
                      <ReCallSVG
                        onClick={() => {
                          console.log('reCall customIcon', data.entityId);
                          communicationSingleton.recallParticipant(data.deviceId);
                          setStateCalling(true);
                          dispatch(
                            onCallEvent({
                              peerId: data.deviceId,
                              status: 'calling',
                            }),
                          );
                        }}
                      />
                    }
                    //activeIcon={"/img/mute-button.png"}
                    onClick={status => {
                      console.log('muteIcon onClick', status);
                      //status ? NucleusCallView.muteAudio() : NucleusCallView.unmuteAudio();
                    }}
                    textClassName="nucleus-font-light"
                    textStyle={{ marginLeft: 5 }}
                  />
                )}
                {stateCalling && (
                  <span className={'nucleus-font-light'} style={{ marginLeft: 5, marginRight: 5 }}>
                    {'CALLING...'}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <style>
        {`
          .offline-div-container {
            flex: 1 1 0%;
            display: flex;
            border-radius: 10px;
            padding: 0px;
            margin: 5px;
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 6px 7px;
          }
          
          .offline-div {
            background-color: black;
            margin: 5px !important;
            flex: 1 1 0%;
            border-radius: 10px;
            padding: 0px;
          }
        `}
      </style>
    </div>
  );
};
