//@ts-nocheck
import React from 'react';

import Spinner from 'react-activity/dist/Spinner';
import { AuthStore, CarePicturesStore } from '../../stores';

import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';

import MemoryBoxGallery from './MemoryBoxGallery';

class Pictures extends React.Component<
  {
    history: any;
  },
  {
    autocompletePatientsList: any;
    imagesArray: any;
    currentImageIndex: number;
    selectedImage: any;
    updateCaptionBtnStyle: string;
    deleteImageBtnStyle: string;
    cancelDeleteImageBtnStyle: string;
    uploadImageBtnStyle: string;
    uploadForAllPatients: boolean;
    uploadPatientsList: any;
    uploadPatientsNames: string;
    selectedUploadPatientsIDs: any;
  }
> {
  private allOptions: { day: string; month: string; year: string; hour12: boolean; hour: string; minute: string };
  private dateOptions: { day: string; month: string; year: string };
  private timeOptions: { hour12?: boolean; hour: string; minute: string; hourCycle?: string };
  private tempArray: any[];
  private uploadingPictureIndex: number;
  private deletingLastPicture: boolean;
  private updatingLastPicture: boolean;
  private controlTimezoneOffset: number;
  private controlTimezone: string;
  private modalDetailsOpen: boolean;
  private modalPatientsOpen: boolean;
  private modalDeleteOpen: boolean;

  constructor(props) {
    super(props);
    validateAccessGranted('/pictures');
    this.state = {
      imagesArray: [], // CarePicturesStore.getPicturesArray(),
      selectedPicturesArray: [],
      currentImageIndex: 0,

      selectedImage: {},
      updateCaptionBtnStyle: ' nucleus-submit-btn-small',
      deleteImageBtnStyle: ' nucleus-submit-btn-small',
      cancelDeleteImageBtnStyle: ' ',
      uploadImageBtnStyle: 'nucleus-submit-btn',

      uploadForAllPatients: true,
      uploadPatientsList: {
        //'CustomUSer' : 'https://st2.depositphotos.com/3101765/8372/v/450/depositphotos_83729944-stock-illustration-border-collie-animal-dog-watercolor.jpg' : '13'
      },
      uploadPatientsNames: '',
      autocompletePatientsList: {},

      selectedUploadPatientsIDs: null,
      selectPatientsVisibility: ' hide',

      selectPatientsEnabled: '',
      selectPatientsError: ' hide',

      totalSelectedPictures: 0,
      uploadingPicture: 0,
      uploadingProgressText: '',

      loading: true,

      //galleryKeyboardAllowed : true,
      noResultsLabel: 'You do not have any photos in your community memory box.Click the + icon to upload the first one',
      ///searchText : "",

      userAvailablePatients: [],
      userAllPatientsAccess: false,
      userPatientsListMode: '',
      userPatientsBtnStyle: ' disabled',
      defaultSelected: false,
      handleAllPatientsAccessModalLabel: 'set',
      handleAllAccessCheckOldValue: false,

      selectedUpdatePatientsIDs: null,
      selectUpdatePatientsVisibility: ' hide',

      selectUpdatePatientsError: ' hide',

      updatePatientsList: {
        //'CustomUSer' : 'https://st2.depositphotos.com/3101765/8372/v/450/depositphotos_83729944-stock-illustration-border-collie-animal-dog-watercolor.jpg' : '13'
      },
      updateImagePatientsBtnStyle: ' nucleus-submit-btn',

      searchText: '',
    };

    this.allOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit' };
    this.dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
    this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };

    this.onGetPicturesByAccountCompleteAction = this.onGetPicturesByAccountCompleteAction.bind(this);
    this.onGetAutocompletePatientsListCompleteAction = this.onGetAutocompletePatientsListCompleteAction.bind(this);

    this.onCurrentImageChange = this.onCurrentImageChange.bind(this);

    this.addNewImage = this.addNewImage.bind(this);
    this.handleCloseUploadModal = this.handleCloseUploadModal.bind(this);
    this.handleToAllPatients = this.handleToAllPatients.bind(this);
    this.selectPhotos = this.selectPhotos.bind(this);
    this.selectedFiles = this.selectedFiles.bind(this);
    this.uploadPicture = this.uploadPicture.bind(this);
    this.onUploadMediaCompleteAction = this.onUploadMediaCompleteAction.bind(this);

    this.openDetails = this.openDetails.bind(this);
    this.handleCloseDetailsModal = this.handleCloseDetailsModal.bind(this);
    this.validateCaption = this.validateCaption.bind(this);

    this.handleUpdateCaption = this.handleUpdateCaption.bind(this);
    this.onUpdateMediaCaptionCompleteAction = this.onUpdateMediaCaptionCompleteAction.bind(this);

    this.openDelete = this.openDelete.bind(this);
    this.handleCancelDelete = this.handleCancelDelete.bind(this);
    this.handleDeleteImage = this.handleDeleteImage.bind(this);
    this.onDeleteMediaCompleteAction = this.onDeleteMediaCompleteAction.bind(this);

    this.onGetPicturePatientsAction = this.onGetPicturePatientsAction.bind(this);

    // Patients Assignation
    this.openPatients = this.openPatients.bind(this);
    this.handleClosePatientsModal = this.handleClosePatientsModal.bind(this);

    this.onAssignPicturePatientAction = this.onAssignPicturePatientAction.bind(this);
    this.onUnAssignPicturePatientAction = this.onUnAssignPicturePatientAction.bind(this);
    this.handleSelectAllClients = this.handleSelectAllClients.bind(this);

    this.handleCancelAssignAllPatients = this.handleCancelAssignAllPatients.bind(this);
    this.handleAssignAllPatients = this.handleAssignAllPatients.bind(this);

    this.onSetPictureForAllPatientAction = this.onSetPictureForAllPatientAction.bind(this);

    this.handleUpdateToAllPatients = this.handleUpdateToAllPatients.bind(this);
    this.handleUpdatePicturePatients = this.handleUpdatePicturePatients.bind(this);

    this.onPicturePatientsUpdatedAction = this.onPicturePatientsUpdatedAction.bind(this);

    this.escFunction = this.escFunction.bind(this);

    this.handleSearchText = this.handleSearchText.bind(this);

    this.tempArray = [];

    this.uploadingPictureIndex = 0;

    this.deletingLastPicture = false;
    this.updatingLastPicture = false;

    this.controlTimezoneOffset = 0;
    this.controlTimezone = 'GMT';

    this.modalDetailsOpen = false;
    this.modalPatientsOpen = false;
    this.modalDeleteOpen = false;

    if (AuthStore.getTime12Hrs().toString() == 'true') {
      //this.datetimeFormat = "m/d/Y h:i A";
      //this.timeFormat = "hh:mm A";
      this.timeOptions = { hour12: true, hour: '2-digit', minute: '2-digit' };
    } else {
      //this.datetimeFormat = "m/d/Y H:i";
      //this.timeFormat = "HH:mm A";
      this.timeOptions = { hourCycle: 'h23', hour: '2-digit', minute: '2-digit' };
    }
  }

  componentDidMount() {
    //Register Listener
    CarePicturesStore.on('onGetPicturesByAccountComplete', this.onGetPicturesByAccountCompleteAction);
    CarePicturesStore.on('onGetAutocompletePatientsListComplete', this.onGetAutocompletePatientsListCompleteAction);
    CarePicturesStore.on('onUploadMediaComplete', this.onUploadMediaCompleteAction);
    CarePicturesStore.on('onUpdateMediaCaptionComplete', this.onUpdateMediaCaptionCompleteAction);
    CarePicturesStore.on('onDeleteMediaComplete', this.onDeleteMediaCompleteAction);
    CarePicturesStore.on('onGetPicturePatients', this.onGetPicturePatientsAction);

    CarePicturesStore.on('onAssignPicturePatient', this.onAssignPicturePatientAction);
    CarePicturesStore.on('onUnAssignPicturePatient', this.onUnAssignPicturePatientAction);

    CarePicturesStore.on('onSetPictureForAllPatient', this.onSetPictureForAllPatientAction);
    CarePicturesStore.on('onPicturePatientsUpdated', this.onPicturePatientsUpdatedAction);

    //Get all the Users Array Data from WS
    CarePicturesStore.getAutocompletePatientsList({
      UserID: AuthStore.getUserID(),
    });

    CarePicturesStore.getPicturesByAccount({
      AccountID: AuthStore.getUserAccountID(),
    });

    $('.tooltipped').tooltip({ delay: 50 });

    $('.modalPictures').modal({
      dismissible: false,
      complete: function () {}, // Callback for Modal close
    });

    this.setState({
      imagesArray: CarePicturesStore.getPicturesArray(),
      loading: CarePicturesStore.getPicturesArray() ? false : true,
    });

    $(document).ready(() => {
      $('.chips').material_chip();

      $('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: this.state.autocompletePatientsList,
          limit: Infinity,
        },
        selectable: false,
      });

      $('.chips').click(() => {
        this.setState({
          selectPatientsError: ' hide',
        });
      });

      // Event for when a Patient Chip is Selected
      $('.chips').on('chip.add', () => {
        //console.log("Selected >"+chip.tag+"<");
        //console.log("Patients available: ", this.state.uploadPatientsList);

        const existingPatients = [];
        const existingData = $('.chips-initial').material_chip('data');
        let patientExist = false;

        for (let i = 0; i < existingData.length; i++) {
          patientExist = this.state.uploadPatientsList[existingData[i].tag];
          //console.log("Esta ", patientExist, existingData[i]);
          if (patientExist) {
            existingPatients.push(existingData[i]);
          }
        }

        $('.chips-initial').material_chip({
          data: existingPatients,
          placeholder: 'Type a name',
          secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: this.state.autocompletePatientsList,
            limit: Infinity,
          },
        });

        //$('#txtPatients input:first').focus();
      });

      /*
            $('.chips').on('chip.delete', (e, chip) =>{ 
            });
            */
      $('.chips').on('chip.select', (e, chip) => {
        console.log("hIrt'", e, chip);
        return false;
      });

      // * * * * * * * * * * * * * * * * * * * * * * * * * * *
      $('.chips2').material_chip();

      $('.chips-initial2').material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: this.state.autocompletePatientsList,
          limit: Infinity,
        },
        selectable: false,
      });

      $('.chips2').click(() => {
        this.setState({
          selectPatientsError: ' hide',
          selectUpdatePatientsError: ' hide',
        });
      });

      // Event for when a Patient Chip is Selected
      $('.chips2').on('chip.add', () => {
        //console.log("Selected >"+chip.tag+"<");
        //console.log("Patients available: ", this.state.updatePatientsList);

        const existingPatients = [];
        const existingData = $('.chips-initial2').material_chip('data');
        let patientExist = false;

        for (let i = 0; i < existingData.length; i++) {
          patientExist = this.state.updatePatientsList[existingData[i].tag];

          if (patientExist) {
            existingPatients.push(existingData[i]);
          }
        }

        $('.chips-initial2').material_chip({
          data: existingPatients,
          placeholder: 'Type a name',
          secondaryPlaceholder: 'Type another name',
          autocompleteOptions: {
            data: this.state.autocompletePatientsList,
            limit: Infinity,
          },
        });

        //$('#txtPatients input:first').focus();
      });

      /*
            $('.chips').on('chip.delete', (e, chip) =>{ 
            });
            */
      $('.chips2').on('chip.select', (e, chip) => {
        console.log("hIrt'", e, chip);
        return false;
      });
    });

    //console.log("Pictures Mount", CarePicturesStore.getPicturesArray());

    try {
      const currentDate = new Date();
      const tzName = currentDate.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
      const currentTimezone = currentDate.getTimezoneOffset() / 60;

      this.controlTimezoneOffset = currentTimezone;
      this.controlTimezone = tzName;

      console.log('CONTROL TIMEZONE:', this.controlTimezoneOffset, this.controlTimezone);
    } catch (ex) {
      console.error('Error getting Timezone', ex);
    }

    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    //Remove Listener
    CarePicturesStore.removeListener('onGetPicturesByAccountComplete', this.onGetPicturesByAccountCompleteAction);
    CarePicturesStore.removeListener('onGetAutocompletePatientsListComplete', this.onGetAutocompletePatientsListCompleteAction);
    CarePicturesStore.removeListener('onUploadMediaComplete', this.onUploadMediaCompleteAction);
    CarePicturesStore.removeListener('onUpdateMediaCaptionComplete', this.onUpdateMediaCaptionCompleteAction);
    CarePicturesStore.removeListener('onDeleteMediaComplete', this.onDeleteMediaCompleteAction);
    CarePicturesStore.removeListener('onGetPicturePatients', this.onGetPicturePatientsAction);

    CarePicturesStore.removeListener('onAssignPicturePatient', this.onAssignPicturePatientAction);
    CarePicturesStore.removeListener('onUnAssignPicturePatient', this.onUnAssignPicturePatientAction);

    CarePicturesStore.removeListener('onSetPictureForAllPatient', this.onSetPictureForAllPatientAction);

    CarePicturesStore.removeListener('onPicturePatientsUpdated', this.onPicturePatientsUpdatedAction);

    $('.tooltipped').tooltip('remove');

    document.removeEventListener('keydown', this.escFunction, false);
  }

  onGetPicturesByAccountCompleteAction(success) {
    if (success) {
      console.log('Pictures: ', CarePicturesStore.getPicturesArray());
      this.setState({
        imagesArray: CarePicturesStore.getPicturesArray(),
        uploadingProgressText: '',
        loading: false,
      });
    } else {
      Message.show('Sorry, There was a problem getting the Pictures. Please try again.');
    }
  }

  onGetPicturePatientsAction(response) {
    console.log('onGetPicturePatientsAction', response);

    if (response.ok) {
      this.setState({
        userAvailablePatients: response.Patients,
      });
    } else {
      Message.show('Error getting available ' + AuthStore.getPatientLabelPlural().toLowerCase() + ' information');
    }
  }

  handleSearchText(e) {
    //console.log("handleSearchText", e.target.value);
    this.setState({
      searchText: e.target.value,
    });
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.handleCloseDetailsModal();
      this.handleClosePatientsModal();
      this.handleCancelDelete();
    }
  }

  onCurrentImageChange(index) {
    console.log('onCurrentImageChange', index);
    if (index === -1 || index === undefined) return;
    this.setState({
      currentImageIndex: index,
    });

    const selectedImageDetails = this.state.imagesArray[index];
    console.log('SELECTED PICTURE ', selectedImageDetails);

    //this.state.currentImageIndex = index;
    if (this.modalDetailsOpen) {
      this.openDetails();
    }
    if (this.modalPatientsOpen) {
      this.openPatients();
    }
    if (this.modalDeleteOpen) {
      this.openDelete();
    }

    CarePicturesStore.getPicturePatients({
      MediaID: selectedImageDetails.MediaID,
      UserID: AuthStore.getUserID(),
    });
  }

  onGetAutocompletePatientsListCompleteAction(response) {
    console.log('onGetAutocompletePatientsListCompleteAction', response);

    if (response.ok) {
      const picturesAutocompletePatients = {};
      const picturesAutocompletePatientIDs = {};
      let patientNameKey = '';

      Object.keys(response.autocompleteOptions.data).forEach(function (key) {
        // key: the name of the object key
        // index: the ordinal position of the key within the object

        patientNameKey = key.trim();
        //console.log("Having >"+patientNameKey+"<");
        picturesAutocompletePatientIDs[patientNameKey] = response.autocompleteOptions.data[key];
        picturesAutocompletePatients[patientNameKey] = null;
      });

      //console.log("picturesAutocompletePatientIDs", picturesAutocompletePatientIDs);
      //console.log("picturesAutocompletePatients", picturesAutocompletePatients);

      this.setState({
        uploadPatientsList: picturesAutocompletePatientIDs,
        updatePatientsList: picturesAutocompletePatientIDs,
        autocompletePatientsList: picturesAutocompletePatients,
      });

      $('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: picturesAutocompletePatients,
          limit: Infinity,
        },
      });

      $('.chips-initial2').material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: picturesAutocompletePatients,
          limit: Infinity,
        },
      });
    }
  }

  // * Upload New Image *
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  addNewImage() {
    this.setState({
      uploadForAllPatients: true,
      selectedUploadPatientsIDs: null,
      selectPatientsVisibility: ' hide',
      uploadingProgressText: '',
    });

    $('.chips-initial').material_chip({
      data: [],
      placeholder: 'Type a name',
      secondaryPlaceholder: 'Type another name',
      autocompleteOptions: {
        data: this.state.autocompletePatientsList,
        limit: Infinity,
      },
    });
    this.refs.checkForAllPatients.checked = true;

    $('#modalUploadImage').modal('open');
  }

  handleCloseUploadModal() {
    $('#modalUploadImage').modal('close');
  }

  handleToAllPatients(e) {
    const setToAllPatients = e.target.checked;
    console.log('handleToAllPatients', setToAllPatients);

    this.setState({
      uploadForAllPatients: setToAllPatients,
    });

    if (setToAllPatients) {
      this.setState({
        selectedUploadPatientsIDs: null,
        selectPatientsVisibility: ' hide',
      });
    } else {
      this.setState({
        selectedUploadPatientsIDs: [],
        selectPatientsVisibility: ' ',
        selectPatientsError: 'hide',
      });
      $('.chips-initial').material_chip({
        data: [],
        placeholder: 'Type a name',
        secondaryPlaceholder: 'Type another name',
        autocompleteOptions: {
          data: this.state.autocompletePatientsList,
          limit: Infinity,
        },
      });
    }
  }

  selectPhotos() {
    console.log('selectPhotos');
    let namesArray = ' All ' + AuthStore.getPatientLabelPlural();

    if (this.state.uploadForAllPatients) {
      this.setState({
        uploadPatientsNames: namesArray,
      });
    } else {
      if (this.state.selectedUploadPatientsIDs != null) {
        const chipsObjectValue = $('.chips-initial').material_chip('data');
        namesArray = '';
        $.each(chipsObjectValue, (key, value) => {
          //console.log("Seleccionado", value);
          //console.log("SeleccionadoDE", this.state.uploadPatientsList);

          if (namesArray.length > 0) {
            namesArray = namesArray + ', ';
          }
          namesArray = namesArray + value.tag;
          //console.log("The PatientID es: ", patientID);
        });
      }

      this.setState({
        uploadPatientsNames: namesArray,
      });

      if (!this.state.uploadForAllPatients && namesArray == '') {
        this.setState({
          selectPatientsError: '',
        });
        console.log('CHSM 1');
        return;
      }

      //console.log("Les noms sont", namesArray);
      let patientsIDsArray = null;
      if (this.state.selectedUploadPatientsIDs != null) {
        const chipsObjectValue = $('.chips-initial').material_chip('data');

        console.log(chipsObjectValue);

        patientsIDsArray = '';
        $.each(chipsObjectValue, (key, value) => {
          let patientID = null;
          patientID = this.state.uploadPatientsList[value.tag];
          if (patientsIDsArray.length > 0) {
            patientsIDsArray = patientsIDsArray + ',';
          }
          patientsIDsArray = patientsIDsArray + patientID;
          //console.log("The PatientID es: ", patientID);
        });
        //console.log("Les patients IDS sont", patientsIDsArray);
        this.setState({
          selectedUploadPatientsIDs: patientsIDsArray,
        });
      }
    }

    document.getElementById('selectFile')?.click();
  }

  selectedFiles(e) {
    const files = Array.from(e.target.files);
    this.setState(
      {
        selectedPicturesArray: files,
        totalSelectedPictures: files.length,
        uploadingPicture: 0,
      },
      () => {
        this.uploadingPictureIndex = 0;
        this.handleCloseUploadModal();
        this.uploadPicture();
      },
    );
  }

  async uploadPicture() {
    const currentPicture = this.state.selectedPicturesArray[this.uploadingPictureIndex];

    this.setState({
      uploadingProgressText: `Uploading image ${this.uploadingPictureIndex + 1} of ${this.state.selectedPicturesArray.length}...`,
      uploadingPicture: this.state.uploadingPicture + 1,
    });

    await CarePicturesStore.UploadMedia({
      UserID: AuthStore.getUserID(),
      Caption: '',
      MediaType: currentPicture.type,
      ForAllPatient: this.state.uploadForAllPatients,
      PatientIDs: this.state.selectedUploadPatientsIDs || '',
      File: currentPicture,
    });

    if (document.getElementById('selectFile')) {
      document.getElementById('selectFile').value = null;
    }

    this.onUploadMediaCompleteAction({ ok: true });
  }

  onUploadMediaCompleteAction(response) {
    if (response.ok) {
      if (this.uploadingPictureIndex + 1 < this.state.selectedPicturesArray.length) {
        this.uploadingPictureIndex += 1;
        this.uploadPicture();
      } else {
        this.setState({
          uploadingProgressText: 'Upload images Complete',
          uploadingPicture: 0,
          loading: true,
        });
        CarePicturesStore.getPicturesByAccount({
          AccountID: AuthStore.getUserAccountID(),
        });
      }
    } else {
      this.setState({
        uploadingProgressText: 'Error Uploading image(s), please try again',
      });
    }
  }

  // * Open Image Details *
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  openDetails() {
    console.log('The selected image is ', this.state.currentImageIndex);
    const selectedImageDetails = this.state.imagesArray[this.state.currentImageIndex];
    console.log('SELECTED PICTURE ', selectedImageDetails);

    const createdDate = new Date(selectedImageDetails.CreatedDate);
    createdDate.setHours(createdDate.getHours() + this.controlTimezoneOffset * -1);
    selectedImageDetails.uploadDate =
      createdDate.toLocaleDateString('en-US', this.dateOptions) + ' ' + createdDate.toLocaleTimeString('en-US', this.timeOptions) + ' - ' + this.controlTimezone;

    this.setState({
      selectedImage: selectedImageDetails,
      updateCaptionBtnStyle: ' nucleus-submit-btn-small-disabled',
      //galleryKeyboardAllowed : false,
      imagesArray: CarePicturesStore.getPicturesArray(),
    });
    //this.state.selectedImage = this.state.imagesArray[this.state.currentImageIndex];
    console.log(this.state.selectedImage);
    this.refs.imageCaption.value = this.state.imagesArray[this.state.currentImageIndex].caption;

    $('#modalImageDetails').modal('open');
    this.modalDetailsOpen = true;
    this.refs.imageCaption.focus();
  }

  handleCloseDetailsModal() {
    $('#modalImageDetails').modal('close');
    // this.setState({
    // 	galleryKeyboardAllowed : true
    // });
    this.modalDetailsOpen = false;
  }

  validateCaption() {
    this.setState({
      updateCaptionBtnStyle: ' nucleus-submit-btn-small',
    });
  }

  handleUpdateCaption() {
    this.setState({
      updateCaptionBtnStyle: ' nucleus-submit-btn-small-disabled',
    });

    CarePicturesStore.UpdateMediaCaption({
      MediaID: this.state.selectedImage.MediaID,
      Caption: this.refs.imageCaption.value,
    });

    this.updatingLastPicture = false;
    if (this.state.currentImageIndex == this.state.imagesArray.length - 1) {
      this.updatingLastPicture = true;
    }

    this.tempArray = this.state.imagesArray;
    this.tempArray[this.state.currentImageIndex].caption = this.refs.imageCaption.value;
    this.tempArray[this.state.currentImageIndex].thumbnailCaption = this.refs.imageCaption.value;
  }

  onUpdateMediaCaptionCompleteAction(response) {
    if (response.ok) {
      this.setState({
        imagesArray: this.tempArray,
        //galleryKeyboardAllowed : true
      });

      // CarePicturesStore.getPicturesByAccount({
      //         AccountID : AuthStore.getUserAccountID()
      //     });

      // this.setState({
      // 	loading : true
      // });

      $('#modalImageDetails').modal('close');
      this.modalDetailsOpen = false;
      Message.show('Image caption updated successfully.');

      if (!this.updatingLastPicture) {
        $("button[title='Next (Right arrow key)']").click();
      }
    } else {
      Message.show('Sorry, There was a problem updating the image caption. Please try again.');
    }
  }

  // * Image Patients Assigned *
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  openPatients() {
    console.log('openPatients');

    console.log('The selected image is ', this.state.currentImageIndex);
    const selectedImageDetails = this.state.imagesArray[this.state.currentImageIndex];
    console.log('SELECTED PICTURE ', selectedImageDetails);

    this.setState({
      selectedImage: selectedImageDetails,
      updateImagePatientsBtnStyle: ' nucleus-submit-btn',
      //updateCaptionBtnStyle : " nucleus-submit-btn-small-disabled",
      //galleryKeyboardAllowed : false,
      //userAvailablePatients: [],
      userAllPatientsAccess: selectedImageDetails.ForAllPatient,
      userPatientsListMode: selectedImageDetails.ForAllPatient ? 'disabled' : '',

      selectUpdatePatientsVisibility: selectedImageDetails.ForAllPatient ? ' hide' : '',
      selectUpdatePatientsError: ' hide',
      //imagesArray : CarePicturesStore.getPicturesArray()
    });

    this.refs.imageForAllPatients.checked = selectedImageDetails.ForAllPatient;
    //this.state.selectedImage = this.state.imagesArray[this.state.currentImageIndex];
    console.log(this.state.selectedImage);

    const existingPatients = [];
    if (!selectedImageDetails.ForAllPatient) {
      for (let i = 0; i < selectedImageDetails.PatientIDs.length; i++) {
        const patientChip = {};
        patientChip['tag'] = selectedImageDetails.PatientIDs[i].FirstName + ' ' + selectedImageDetails.PatientIDs[i].LastName;
        patientChip['Name'] = selectedImageDetails.PatientIDs[i].FirstName + ' ' + selectedImageDetails.PatientIDs[i].LastName;
        patientChip['PatientID'] = selectedImageDetails.PatientIDs[i].ID;
        existingPatients.push(patientChip);
      }
    }

    $('.chips-initial2').material_chip({
      data: existingPatients,
      placeholder: 'Type a name',
      secondaryPlaceholder: 'Type another name',
      autocompleteOptions: {
        data: this.state.autocompletePatientsList,
        limit: 2,
      },
    });

    $('#modalImagePatients').modal('open');
    this.modalPatientsOpen = true;
  }

  handleClosePatientsModal() {
    $('#modalImagePatients').modal('close');
    // this.setState({
    //  galleryKeyboardAllowed : true
    // });
    this.modalPatientsOpen = false;
    if (!this.state.selectedImage.MediaID) return;
    CarePicturesStore.getPicturePatients({
      MediaID: this.state.selectedImage.MediaID,
      UserID: AuthStore.getUserID(),
    });
  }

  onAssignPicturePatientAction(response) {
    console.log('onAssignPicturePatientAction', response);

    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' Assigned');
    } else {
      Message.show('Error assigning ' + AuthStore.getPatientLabel().toLowerCase() + ' to this user.');
    }
  }

  onUnAssignPicturePatientAction(response) {
    if (response.ok) {
      Message.show(AuthStore.getPatientLabel() + ' Unassigned');
    } else {
      Message.show('Sorry,  there was an error unassigning the ' + AuthStore.getPatientLabel().toLowerCase() + ', please try again');
    }
  }

  handleSelectAllClients(checkbox) {
    console.log('This has changed ', checkbox.target.checked);

    if (checkbox.target.checked) {
      this.setState({
        handleAllPatientsAccessModalLabel: 'set',
        handleAllAccessCheckOldValue: false,
      });
      $('#modalAssignAllPatients').modal('open');
    } else {
      this.setState({
        handleAllPatientsAccessModalLabel: 'unset',
        handleAllAccessCheckOldValue: true,
      });
      $('#modalAssignAllPatients').modal('open');
    }
  }

  handleCancelAssignAllPatients() {
    this.refs.checkSelectAll.checked = this.state.handleAllAccessCheckOldValue;
    $('#modalAssignAllPatients').modal('close');
  }

  handleAssignAllPatients() {
    console.log('handleAssignAllPatients', this.state.selectedImage.MediaID, this.refs.checkSelectAll.checked);

    CarePicturesStore.setForAllPatients({
      MediaID: this.state.selectedImage.MediaID,
      Enabled: this.refs.checkSelectAll.checked,
    });
  }

  onSetPictureForAllPatientAction(result) {
    console.log('onSetPictureForAllPatientAction');
    console.log(result);

    if (result.ok) {
      this.setState({
        userAllPatientsAccess: result.Enabled,
        userPatientsListMode: result.Enabled ? 'disabled' : '',
        selectUpdatePatientsVisibility: result.Enabled ? ' hide' : '',
      });
      CarePicturesStore.getPicturesByAccount({
        AccountID: AuthStore.getUserAccountID(),
      });
      //CareUserStore.setUserAllPatientsAccess(result.canSee);
      if (result.Enabled) {
        $('#modalAssignAllPatients').modal('close');
        ////GeneralStore.requestHandleBack();
        //this.history.goBack();
        Message.show('Enabled for all ' + AuthStore.getPatientLabelPlural().toLowerCase());
      } else {
        // CareUserStore.getPossiblePatients({
        //     UserID : this.props.userId
        // });
        $('#modalAssignAllPatients').modal('close');
        Message.show('Disabled for all ' + AuthStore.getPatientLabelPlural().toLowerCase());
      }
    } else {
      Message.show('Error updating, please try again.');
    }
  }

  // Second Way - - - - -
  handleUpdateToAllPatients(e) {
    const setToAllPatients = e.target.checked;
    console.log('handleUpdateToAllPatients', setToAllPatients);

    CarePicturesStore.setForAllPatients({
      MediaID: this.state.selectedImage.MediaID,
      Enabled: setToAllPatients,
    });
    // Listen to "onSetPictureForAllPatientAction"
  }

  handleUpdatePicturePatients() {
    let IDsArray = null;
    const chipsObjectValue = $('.chips-initial2').material_chip('data');
    console.log('chipsObjectValue:', chipsObjectValue);
    IDsArray = '';
    $.each(chipsObjectValue, (key, value) => {
      //console.log("Seleccionado", value);
      //console.log("SeleccionadoDE", this.state.updatePatientsList);
      let patientID = null;
      patientID = this.state.updatePatientsList[value.tag];
      //IDsArray.push(patientID);
      if (IDsArray.length > 0) {
        IDsArray = IDsArray + ',';
      }
      IDsArray = IDsArray + patientID;
      //console.log("The PatientID es: ", patientID);
    });

    if (!this.state.userAllPatientsAccess && IDsArray == '') {
      this.setState({
        selectUpdatePatientsError: ' ',
      });
      return;
    }

    // this.setState({
    //     selectedMessagePatientsIDs : IDsArray
    // });
    this.setState({
      updateImagePatientsBtnStyle: ' nucleus-submit-btn-disabled',
    });

    CarePicturesStore.updateImagePatients({
      MediaID: this.state.selectedImage.MediaID,
      PatientsIDs: IDsArray,
    });
  }

  onPicturePatientsUpdatedAction(response) {
    this.setState({
      updateImagePatientsBtnStyle: ' nucleus-submit-btn',
    });

    if (response.ok) {
      Message.show('Photo ' + AuthStore.getPatientLabelPlural() + ' updated');

      CarePicturesStore.getPicturesByAccount({
        AccountID: AuthStore.getUserAccountID(),
      });
    } else {
      Message.show('Error updating Photo ' + AuthStore.getPatientLabelPlural() + ', please try again');
    }
  }
  // CarePicturesStore.getPicturePatients({
  //         MediaID : selectedImageDetails.MediaID,
  //         UserID : AuthStore.getUserID()
  //     });

  // * Delete Image *
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  openDelete() {
    console.log('The selected image is ', this.state.currentImageIndex);
    console.log('The selected image is ', this.state.imagesArray[this.state.currentImageIndex]);

    this.setState({
      selectedImage: this.state.imagesArray[this.state.currentImageIndex],
      deleteImageBtnStyle: ' nucleus-submit-btn-small',
      cancelDeleteImageBtnStyle: ' ',
      //galleryKeyboardAllowed : false
    });

    $('#modalDeleteImage').modal('open');
    this.modalDeleteOpen = true;
    this.deletingLastPicture = false;

    if (this.state.currentImageIndex == this.state.imagesArray.length - 1) {
      this.deletingLastPicture = true;
    }
  }

  handleCancelDelete() {
    $('#modalDeleteImage').modal('close');
    // this.setState({
    //   		galleryKeyboardAllowed : true
    //   	});
    this.modalDeleteOpen = false;
  }

  handleDeleteImage() {
    this.setState({
      deleteImageBtnStyle: ' nucleus-submit-btn-small-disabled',
      cancelDeleteImageBtnStyle: ' hide',
    });

    CarePicturesStore.DeleteMedia({
      MediaID: this.state.selectedImage.MediaID,
      UserID: AuthStore.getUserID(),
    });
  }

  onDeleteMediaCompleteAction(response) {
    console.log('onDeleteMediaCompleteAction', response);
    if (response.ok) {
      $('#modalDeleteImage').modal('close');
      this.modalDeleteOpen = false;
      // CarePatientStore.getAllPatientsData({
      // 		UserID : AuthStore.getUserID()
      // });
      // setTimeout(function(){
      // 	Message.show(AuthStore.getPatientLabel().capitalize() + " deleted successfully.");
      // }, 700);
      CarePicturesStore.getPicturesByAccount({
        AccountID: AuthStore.getUserAccountID(),
      });
      Message.show('Image deleted successfully.');

      let currentIndex = this.state.currentImageIndex;
      if (this.deletingLastPicture) {
        currentIndex = currentIndex - 1;

        if (currentIndex < 0) {
          $("button[title='Close (Esc)']").click();
        }
      }

      this.setState({
        loading: true,
        currentImageIndex: currentIndex,
        //galleryKeyboardAllowed : true
      });
    } else {
      Message.show('Sorry, There was a problem deleting the image. Please try again.');
    }

    this.setState({
      deleteImageBtnStyle: ' nucleus-submit-btn-small',
      cancelDeleteImageBtnStyle: ' ',
    });
  }

  render() {
    console.log('Pictures Render');
    const picturesList = this.state.imagesArray || [];

    let divResultsView = ' hide';
    let divNoResultsView = ' hide';
    let divLoadingView = ' hide';

    if (this.state.loading) {
      divResultsView = ' hide';
      divNoResultsView = ' hide';
      divLoadingView = '';
    } else {
      if (picturesList.length > 0) {
        divResultsView = ' ';
        divNoResultsView = ' hide';
      } else {
        divResultsView = ' hide';
        divNoResultsView = ' ';
      }
    }

    const imageDetailsPatientsList = [];
    if (this.state.selectedImage.PatientIDs && this.state.selectedImage.PatientIDs.length > 0) {
      console.log('Pictures Render Images 56756235237568244-456-44568789-67679 ');
      let count = 0;
      let separator = '';
      this.state.selectedImage.PatientIDs.map(patient => {
        //let name = (patient.FirstName + " " + patient.LastName).toLowerCase();
        //if (textToSearch.length == 0 || (name.indexOf(textToSearch) != -1) ){
        count++;
        if (count > 1) {
          separator = ', ';
        }

        imageDetailsPatientsList.push(
          <span key={patient.ID} className="nucleus-labels nucleus-font-label-body">
            {' '}
            {separator + patient.FirstName + ' ' + patient.LastName}
          </span>,
        );
        //}
      });
    } else {
      imageDetailsPatientsList.push(
        <span key={new Date()} className="nucleus-labels nucleus-font-label-body">
          {' '}
          &nbsp;All {AuthStore.getPatientLabelPlural()}
        </span>,
      );
    }

    //let textToSearch = this.state.searchText.toLowerCase();
    // let patientsList = [];
    // let textToSearch = this.state.searchText.toLowerCase();
    // if (this.modalPatientsOpen){

    //     if (this.state.userAvailablePatients != null ){
    //         this.state.userAvailablePatients.map((patient, key) => {

    //             let name = (patient.FirstName + " " + patient.LastName).toLowerCase();
    //             //console.log(name.includes(textToSearch));

    //             if (textToSearch.length == 0 || (name.indexOf(textToSearch) != -1) ){

    //                 patientsList.push(
    //                     <CarePicturePatientItemRow
    //                       key={key}
    //                       MediaID={this.state.selectedImage.MediaID}
    //                       PatientID={ patient.PatientID}
    //                       Name={ patient.FirstName + " " + patient.LastName }
    //                       //Telephone={patient.Telephone}
    //                       //DefaultSelected={this.state.defaultSelected}
    //                       Assigned={patient.Assigned}
    //                       PatientsListMode={this.state.userPatientsListMode}
    //                     />
    //                 );
    //             }
    //         });
    //     }

    // }

    //console.log("Rendering : ", this.state.userAllPatientsAccess);

    return (
      <div className="nucleus-hard-container">
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">{AuthStore.getPicturesLabel()}</p>
          <div className="clients-table-pending altoImportantContainer">
            {/*
						<div className="row no-margin a-bit-lower">
                            <div className="col s5 m4 no-margin left-align">
                                <span className="nucleus-labels-height a-bit-lower"> &nbsp;Search&nbsp;&nbsp; </span> 
                                <input ref="txtPatientsSearch" type="text" className="nucleus-input-form-small-left" onChange={this.handleSearchText} placeholder={ "Picture Caption"}/>
                            </div>
                            <div className="col s7 m8 no-margin left-align">
                            </div>
                        </div>
                    	*/}

            <span className="nucleus-font-family-big">
              <b>&nbsp;&nbsp;{this.state.uploadingProgressText}</b>
            </span>

            <div className={'row center-align ' + divNoResultsView}>
              <br />
              <br />
              <br />
              <br />
              <span className="nucleus-table-header-medium text-gray">
                {' '}
                You do not have any photos in your community memory box.
                <br />
                Click the + icon to upload the first one{' '}
              </span>
              <br />
              <br />
              <br />
              <br />
            </div>

            <div className={'pictures-container ' + divResultsView}>
              <MemoryBoxGallery
                currentImageWillChange={this.onCurrentImageChange}
                toolbarButtons={[
                  <button key="openDetails" style={{ marginRight: '5px' }} onClick={this.openDetails} className="waves-effect btn-large">
                    Image Details
                  </button>,
                  <button key="openPatients" style={{ marginRight: '5px' }} onClick={this.openPatients} className="waves-effect btn-large">
                    Image {AuthStore.getPatientLabelPlural()}
                  </button>,
                  <button key="openDelete" style={{ marginRight: '5px' }} onClick={this.openDelete} className="waves-effect btn-large">
                    Delete Image
                  </button>,
                ]}
                images={picturesList}
              />
              {/* <Gallery
			                	ref="gallery"
			                    images={picturesList}
			                    enableLightbox={true}
			                    enableImageSelection={false}
			                    currentImageWillChange={this.onCurrentImageChange}
			                    margin={10}
								backdropClosesModal={false}
								enableKeyboardInput={true}
			                    customControls={[
			                    	<button key="openDetails" onClick={this.openDetails} className="custom-gallery-button1">Image Details</button>,
                                    <button key="openPatients" onClick={this.openPatients} className="custom-gallery-button2">Image {AuthStore.getPatientLabelPlural()}</button>,
			                        <button key="openDelete" onClick={this.openDelete} className="custom-gallery-button3">Delete Image</button>

			                    ]}
			                /> */}
            </div>

            <div className={'row center-align ' + divLoadingView}>
              <br />
              <br />
              <br />
              <Spinner color="#2096F3" size={34} speed={0.6} animating={this.state.loading} style={{ display: 'inline-flex' }} />
              <br />
              <br />
              <br />
            </div>
          </div>

          <div className="fixed-action-btn btn-add-new">
            &nbsp;
            <br />
            <a
              className="btn-floating btn-large waves-effect waves-light orange tooltipped "
              data-position="left"
              data-delay="50"
              data-tooltip={'Photo Upload'}
              onClick={this.addNewImage}
            >
              <i className="material-icons">add</i>
            </a>
            &nbsp;
          </div>

          <div id="modalImageDetails" className="modal modalPictures modalScrollableNotWide">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseDetailsModal} />
              <h3 className="nucleus-page-subtitle">Photo Details</h3>
              <br />
              <br />

              <div className="row ">
                <span className="nucleus-labels nucleus-font-label-title">
                  <b>{'Photo Caption'}</b>
                </span>
                <input
                  id="imageCaption"
                  ref="imageCaption"
                  type="text"
                  className="validate nucleus-input-form"
                  placeholder={'Inser photo caption'}
                  onChange={this.validateCaption}
                />
                <br />

                <div className="row no-margin">
                  <div className="col s12 center-align no-margin">
                    <p></p>
                    <span className=" modalSeparator">
                      {' '}
                      <label> &nbsp;</label>
                    </span>
                    <a className={'txt-white nucleus-font-small ' + this.state.updateCaptionBtnStyle} onClick={this.handleUpdateCaption}>
                      Update Photo Caption
                    </a>
                    <div>&nbsp;</div>
                  </div>
                </div>
              </div>

              <div className="row ">
                <span className="nucleus-labels nucleus-font-label-title">
                  <b>{'Uploaded By: '}</b>
                </span>
                <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.selectedImage.FirstName + ' ' + this.state.selectedImage.LastName}</span>
                <br />
                <span className="nucleus-labels nucleus-font-label-title">
                  <b>{'Uploaded On: '}</b>
                </span>
                <span className="nucleus-labels nucleus-font-label-body"> &nbsp; {this.state.selectedImage.uploadDate}</span>
                <br />
                <span className="nucleus-labels nucleus-font-label-title">
                  <b>{'Uploaded To: '}</b>
                </span>
                &nbsp;
                {imageDetailsPatientsList}
              </div>
            </div>
          </div>

          <div id="modalImagePatients" className="modal modalPictures modalScrollableNotWide">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleClosePatientsModal} />
              <h3 className="nucleus-page-subtitle">Photo {AuthStore.getPatientLabelPlural()}</h3>
              <br />
              <br />

              <div className="row ">
                <div className="row  ">
                  <div className="col s5 no-margin">
                    <span className="nucleus-labels">
                      <b>Send to All {AuthStore.getPatientLabelPlural()}</b>
                    </span>
                  </div>
                  <div className="col s7">
                    <div className="switch nucleus-switch">
                      <label className="no-margin">
                        <input type="checkbox" ref="imageForAllPatients" defaultChecked="checked" onChange={this.handleUpdateToAllPatients} />
                        <span className="lever nucleus-check"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className={'row  ' + this.state.selectUpdatePatientsVisibility}>
                  <span className="nucleus-font nucleus-font-14">
                    Start typing the Patient’s name and we will display all options. Simply select from the list and you will see the Patient's name show in the section below.
                  </span>
                  <div ref="txtUpdatePatients" id="txtUpdatePatients" className="chips2 chips-initial2 no-margin nucleus-chips"></div>
                  <span className={'nucleus-form-error center-align ' + this.state.selectUpdatePatientsError}>Please select at least one {AuthStore.getPatientLabel()}.</span>
                  <div className="col s12 center-align no-margin">
                    <br />
                    <a className={'txt-white nucleus-font-modal-btn ' + this.state.updateImagePatientsBtnStyle} onClick={this.handleUpdatePicturePatients}>
                      Update
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="modalDeleteImage" className="modal modalPictures modalDeletePicture">
            <div className="modal-content modal-content-delete">
              <h3 className="nucleus-page-subtitle">&nbsp;&nbsp;Delete Photo</h3>
              <br /> <br />
              <div className="row">
                <div className="col s12 left-align nucleus-font-medium">
                  Are you sure you want to delete the selected photo?
                  <br />
                </div>
              </div>
              <div className="row ">
                <div className="col s12 right-align ">
                  <p></p>
                  <a className={'txt-white nucleus-font-small nucleus-link ' + this.state.cancelDeleteImageBtnStyle} onClick={this.handleCancelDelete}>
                    Cancel
                  </a>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <a className={'txt-white nucleus-font-small ' + this.state.deleteImageBtnStyle} onClick={this.handleDeleteImage}>
                    Confirm
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="modalUploadImage" className="modal modalPictures modalScrollableNotWide">
            <div className="modal-content nucleus-modal-content txt-special-color">
              <img className="responsive-img img-close-modal" src="/img/close_modal.png" onClick={this.handleCloseUploadModal} />
              <h3 className="nucleus-page-subtitle">Photo Upload</h3>
              <br />
              <br />

              <span className="nucleus-labels nucleus-font-label-title">
                <b>{'STEP 1: Select which ' + AuthStore.getPatientLabel().toLowerCase() + ' to upload the photos'}</b>
              </span>
              <br />
              <br />
              <div className="row  ">
                <div className="col s5 no-margin">
                  <span className="nucleus-labels">
                    <b>Send to All {AuthStore.getPatientLabelPlural()}</b>
                  </span>
                </div>
                <div className="col s7">
                  <div className="switch nucleus-switch">
                    <label className="no-margin">
                      <input type="checkbox" ref="checkForAllPatients" defaultChecked="checked" onChange={this.handleToAllPatients} />
                      <span className="lever nucleus-check"></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className={'row  ' + this.state.selectPatientsVisibility}>
                <span className="nucleus-font nucleus-font-14">
                  Start typing the Patient’s name and we will display all options. Simply select from the list and you will see the Patient's name show in the section below.
                </span>
                <div ref="txtPatients" id="txtPatients" className="chips chips-initial no-margin nucleus-chips" disabled={this.state.selectPatientsEnabled}></div>
                <span className={'nucleus-form-error center-align ' + this.state.selectPatientsError}>Please select at least one {AuthStore.getPatientLabel()}.</span>
              </div>
              <br />
              <span className="nucleus-labels nucleus-font-label-title">
                <b>{'STEP 2: Select photos from your computer'}</b>
              </span>
              <br />
              <br />
              <div className="row no-margin">
                <div className="col s12 center-align no-margin">
                  <p></p>
                  <span className=" modalSeparator">
                    {' '}
                    <label> &nbsp;</label>
                  </span>
                  <input
                    id="selectFile"
                    ref="selectFile"
                    type="file"
                    data-multiple-caption={'Select the images'}
                    multiple={true}
                    onChange={this.selectedFiles}
                    accept={'image/png, image/jpeg'}
                    style={{ display: 'none' }}
                  ></input>
                  <a className={'txt-white nucleus-font-modal-btn ' + this.state.uploadImageBtnStyle} onClick={this.selectPhotos}>
                    Select Photos
                  </a>
                  <div>&nbsp;</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Pictures;
