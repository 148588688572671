import classNames from 'classnames';
import TelephoneInputUnderline from 'components/UI/Inputs/TelephoneInputUnderlined';
import { useReactRouterProps } from 'hooks/useReactRouterProps';
import React from 'react';
import { Link } from 'react-router-dom';
import AuthStore from 'stores/AuthStore';
import styled from 'styled-components';
import CareUserStore from '../../stores/CareUserStore';
import { validateAccessGranted } from '../../utils/accessGrantedValidator';
import Message from '../../utils/Message';
import NewUserPermissions, { UserPermissions, defaultUserPermissions } from './NewUserPermissions';
import {
  UIButton
} from 'components/UI';
import NewUserAssignedPatients, { ISelectedAssignatedPatients} from './NewUserAssignedPatients';
import { saveNewUserAssignedPatients } from './NewUserApi';
class NewUser extends React.Component<
  {
    location: any;
    history: any;
  },
  {
    panel: string;
    newUserEmail: string;
    newUserTelephone: string;
    btnStyle: string;
    errorEmailVisibility: string;
    errorTelephoneVisibility: string;
    errorEmailText: string;
    errorTelephoneText: string;
    assignPatientsModalOpen: boolean;
  }
> {
  private TwoFactorEnabled: boolean;
  private newUserPermissions: UserPermissions = defaultUserPermissions;
  private newUserAssignatedPatients: ISelectedAssignatedPatients[]=[];
  txtNewUserFirstNameRef: React.RefObject<HTMLInputElement>;
  txtNewUserLastNameRef: React.RefObject<HTMLInputElement>;
  txtNewUserEmailRef: React.RefObject<HTMLInputElement>;
  txtNewUserAddressRef: React.RefObject<HTMLInputElement>;
  
  constructor(props) {
    super(props);

    validateAccessGranted('/newUser');

    this.state = {
      panel: 'Form',
      newUserEmail: '',
      errorEmailVisibility: 'hide',
      errorEmailText: 'Invalid Email',
      newUserTelephone: '',
      errorTelephoneVisibility: 'hide',
      errorTelephoneText: 'Telephone already registered',
      btnStyle: ' disabled',
      assignPatientsModalOpen: false,
    };
    this.validateFormValues = this.validateFormValues.bind(this);
    this.submitCreateUserForm = this.submitCreateUserForm.bind(this);
    this.onCreateUserAction = this.onCreateUserAction.bind(this);
    this.TwoFactorEnabled = AuthStore.get2FactorAuthEnabled();
    this.txtNewUserFirstNameRef = React.createRef();
    this.txtNewUserLastNameRef = React.createRef();
    this.txtNewUserEmailRef = React.createRef();
    this.txtNewUserAddressRef = React.createRef();
  }

  componentDidMount() {
    CareUserStore.on('onCreateUserAction', this.onCreateUserAction);
    //$('.tooltipped').tooltip({delay: 50});
    // TODO:2024
  }

  componentWillUnmount() {
    CareUserStore.removeListener('onCreateUserAction', this.onCreateUserAction);
    //$('.tooltipped').tooltip('remove');
    // TODO:2024
  }

  onTelephoneInputChange = phoneNumber => {
    this.state.newUserTelephone = phoneNumber;
    if (phoneNumber.length > 0) {
      this.setState({
        btnStyle: '',
        errorTelephoneVisibility: 'hide',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }
  };

  validateFormValues() {
    let phoneLength = this.state.newUserTelephone?.length;

    if (!this.TwoFactorEnabled && phoneLength < 1) {
      phoneLength = 1;
    }

    if (
      this.txtNewUserFirstNameRef?.current?.value?.length > 0 &&
      this.txtNewUserLastNameRef?.current?.value?.length > 0 &&
      this.txtNewUserEmailRef?.current?.value?.length > 0 &&
      phoneLength > 0
      //&& this.txtNewUserAddressRef.current.value.length > 0
    ) {
      this.setState({
        btnStyle: '',
      });
    } else {
      this.setState({
        btnStyle: 'disabled',
      });
    }
    if (this.txtNewUserEmailRef.current.value.length > 0) {
      this.setState({
        errorEmailVisibility: 'hide',
      });
    }

    if (this.state.newUserTelephone.length > 0) {
      this.setState({
        errorTelephoneVisibility: 'hide',
      });
    }
  }

  submitCreateUserForm() {
    let newUserEmail = this.txtNewUserEmailRef.current.value;
    newUserEmail = newUserEmail.replace(' ', '');

    if (this.txtNewUserFirstNameRef.current.value === '') {
      Message.show('FirstName is required.');
      return;
    }
    if (this.txtNewUserLastNameRef.current.value === '') {
      Message.show('LastName is required.');
      return;
    }

    if (newUserEmail === '') {
      Message.show('Ups! The email is not valid.');
      return;
    }

    // Regex Simple /\S+@\S+\.\S+/
    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,15})+$/.test(newUserEmail)) {
      //Message.show('Ups! The email is not valid.');
      this.setState({
        errorEmailVisibility: '',
        errorTelephoneVisibility: 'hide',
        errorEmailText: 'Invalid Email',
      });
      return;
    }

    let newUserTelephone = this.state.newUserTelephone;

    if (newUserTelephone === '+1') {
      newUserTelephone = '';
    }

    if (this.TwoFactorEnabled && newUserTelephone.length === 0) {
      Message.show('Phone is required if Two Factor Auth is enabled');
      return;
    }

    if (newUserTelephone.length > 0) {
      newUserTelephone = newUserTelephone.replace(/[()-\s]/g, '');
      if (newUserTelephone.length > 0 && newUserTelephone.length < 12) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Telephone must be at least 10 digits',
        });
        return;
      }
      const containsLetter = /[a-zA-Z]/.test(newUserTelephone);
      if (containsLetter) {
        this.setState({
          errorEmailVisibility: 'hide',
          errorTelephoneVisibility: '',
          errorTelephoneText: 'Invalid Telephone.',
        });
        return;
      }
    }

    const newUserData = {
      AccountID: AuthStore.getUserAccountID(),
      FirstName: this.txtNewUserFirstNameRef.current.value,
      LastName: this.txtNewUserLastNameRef.current.value,
      Email: newUserEmail,
      Telephone: newUserTelephone,
      Address: this.txtNewUserAddressRef.current.value,
      CanSendMessageToAllPatients: false, // DEPRECATED - Jan 24th, 2018 - CARE-2305
      ReceiveEscalationCallRequest: false,
      ...this.newUserPermissions
    };
    console.log("CreateNewUser");
    console.log("CreateNewUser newUserData", newUserData); 
    this.setState({
      btnStyle: 'disabled',
    });
    CareUserStore.CreateNewUser(newUserData);
  }

  onCreateUserAction(response) {
    if (response.ok) {
      if (response.ID){
        saveNewUserAssignedPatients({
          userId: response.ID,
          patients: this.newUserAssignatedPatients
        })
      }
      this.props.history.navigate('/users');
      setTimeout(function () {
        Message.show(AuthStore.getUserLabel() + ' created');
      }, 100);
    } else if (response.emailRegistered) {
      this.setState({
        errorEmailVisibility: '',
        errorTelephoneVisibility: 'hide',
        errorEmailText: 'Email already exists.',
      });
    } else if (response.telephoneRegistered) {
      this.setState({
        errorEmailVisibility: 'hide',
        errorTelephoneVisibility: '',
        errorTelephoneText: 'Telephone number already registered.',
      });
    } else {
      if (response.error) {
        Message.show(response.error);
      } else {
        Message.show('Error creating new ' + AuthStore.getUserLabel());
      }
    }

    this.setState({
      btnStyle: '',
    });
  }

  onNewUserPermissionsChanged = (newUserPermissions) =>{
    this.newUserPermissions = newUserPermissions;
  }

  openAssignPatientsModal = ()=>{
    this.setState({
      assignPatientsModalOpen: true
    });
  }

  onNewUserPatientAssignationChanged = (newUserAssignatedPatients)=>{
    this.newUserAssignatedPatients = newUserAssignatedPatients;
  }

  onCloseNewUserPatientAssignationModal = ()=>{
    this.setState({
      assignPatientsModalOpen: false
    });
  }

  render() {
    const formVisibility = classNames({
      hide: this.state.panel != 'Form',
    });
    const messageVisibility = classNames({
      hide: this.state.panel != 'Message',
    });

    return (
      <div className="nucleus-hard-container" style={{backgroundColor:'white'}}>
        <div className="nucleus-all-scrollable-page">
          <p className="nucleus-page-title">New {AuthStore.getUserLabel()}</p>
          <br />

          <div className={'nucleus-user-form-pending ' + formVisibility}>
            <div className="row">
              <div className="col s2 m1 l1"></div>
              <div className="col s7 m7 l6">
                <div className="row">
                  <div className="col s4 valgin-wrapper left-align">
                    <span className="nucleus-labels">First Name </span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <input
                      id={"txtNewUserFirstName"}
                      ref={this.txtNewUserFirstNameRef}
                      type="text"
                      className="validate nucleus-input-form"
                      placeholder="First Name"
                      onChange={this.validateFormValues}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col s4 valgin-wrapper left-align">
                    <span className="nucleus-labels">Last Name</span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <input
                      id={'txtNewUserLastName'}
                      ref={this.txtNewUserLastNameRef}
                      type="text"
                      className="validate nucleus-input-form"
                      placeholder="Last Name"
                      onChange={this.validateFormValues}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col s4 left-align">
                    <span className="nucleus-labels"> Email</span> <span className="nucleus-labels txt-red">*</span>
                  </div>
                  <div className="col s8">
                    <input 
                      id="txtNewUserEmail" 
                      ref={this.txtNewUserEmailRef}
                      type="email" 
                      className="validate nucleus-input-form" 
                      placeholder="Email" 
                      onChange={this.validateFormValues} 
                    />
                    <span className={'nucleus-form-error center-align ' + this.state.errorEmailVisibility}> {this.state.errorEmailText}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 left-align">
                    <span className=" nucleus-labels"> Telephone</span>
                    {this.TwoFactorEnabled && <span className="nucleus-labels txt-red">&nbsp;*</span>}
                  </div>
                  <div className="col s8">
                    <TelephoneInputUnderline 
                      inputId="txtNewUserTelephone" 
                      fieldId="newPhone" 
                      value="" 
                      onChange={this.onTelephoneInputChange} 
                      onBlur={this.validateFormValues} 
                    />
                    <span className={'nucleus-form-error left-align txt-black-forced '} style={{fontSize:13}}>Click the country code icon for more country codes</span>
                    <br />
                    <span className={'nucleus-form-error center-align ' + this.state.errorTelephoneVisibility}> {this.state.errorTelephoneText}</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col s4 left-align">
                    <span className=" nucleus-labels"> Address</span>
                  </div>
                  <div className="col s8">
                    <input
                      id="txtNewUserAddress"
                      ref={this.txtNewUserAddressRef}
                      type="text"
                      className="validate nucleus-input-form"
                      placeholder="1234 Street Lane, New City, NC 00000"
                      onChange={this.validateFormValues}
                    />
                  </div>
                </div>
              </div>
              <div className="col s3 m4 l5" style={{display:'flex', justifyContent:'flex-end'}}>
                <div style={{maxWidth:216}}>
                  <UIButton
                    showChevron
                    onClick={() => {
                      this.openAssignPatientsModal();
                    }}
                    text="Edit Assignments"
                    variant="secondary"
                  />
                </div>
              </div>
            </div>

            <PageRow divClass={'row'}>
              <PageRowColumn divColumnClass={'col s2 m1 l1'}></PageRowColumn>
              <PageRowColumn divColumnClass={'col s8 m8 l8'}>
                <NewUserPermissions onDataChanged={this.onNewUserPermissionsChanged}/>
              </PageRowColumn>
              <PageRowColumn divColumnClass={'col s2 m3 l3'}></PageRowColumn>
            </PageRow>

            <NewUserAssignedPatients 
              modalOpen={this.state.assignPatientsModalOpen} 
              onSavePatientsAssignationData={this.onNewUserPatientAssignationChanged} 
              onCloseModal={this.onCloseNewUserPatientAssignationModal}
            />

            <div className="fixed-action-btn btn-add-new">
              <a
                id="save-user"
                className="btn-floating btn-large waves-effect waves-light light-blue darken-4 tooltipped "
                data-position="left"
                data-delay="50"
                data-tooltip={'Save new ' + AuthStore.getUserLabel().toLowerCase()}
                onClick={this.submitCreateUserForm}
                style={{marginRight:36}}
              >
                <i className="material-icons">save</i>
              </a>
            </div>
          </div>

          <div id="nucleus-new-user-confirmation-pending" className={'nucleus-new-user-form-pending ' + messageVisibility}>
            <div className="row">
              <div className="col s12">
                <p className=" nucleus-labels"> Complete! An account set up email has been sent to {this.state.newUserEmail}</p>
                <Link to="/users">
                  <span className="nucleus-link nucleus-font-medium">Go to {AuthStore.getUserLabelPlural()} List </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default props => useReactRouterProps(NewUser, props);

interface PageRowProps {
  divClass?: string; // Define la propiedad divClass
}

const PageRow =styled.div.attrs<PageRowProps>((props) => ({
  className: props.divClass || 'row',
}))`
  display: flex;
  flex-wrap: wrap;
`;

interface PageRowColumnProps {
  divColumnClass: string; // Define la propiedad divClass
}
const PageRowColumn =styled.div.attrs<PageRowColumnProps>((props) => ({
  className: props.divColumnClass || 'col ',
}))`
  display: flex;
  flex-wrap: wrap;
`;
