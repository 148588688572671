import { httpApi } from '@nucleus-care/nucleuscare-backend-client';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import NucleusTable from '../../../../components/NucleusTable';
import authStore from '../../../../stores/AuthStore';
import EditIcon from '../assets/editIcon.svg';
const SectionTitle = styled.h2`
  color: #0a313f;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 35px;
`;

const RegionLabel = styled.h2`
  color: rgba(0, 128, 0, 1);
  font-family: Barlow;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 25.6px;
  text-align: left;
`;
const ReportsRecipientContainer = styled.div`
  margin-bottom: 2%;
`;
type ReportsRecipients = {
  group: string;
  selectReceiverCallBack: () => {};
  reRenderFlag: boolean;
};
const ReportsRecipients = ({ group, selectReceiverCallBack, reRenderFlag, tierTitles, tier }) => {
  const [receivers, setReceivers] = useState([]);
  const fetchReceivers = async () => {
    try {
      const providerId = authStore.getProviderID();
      const result = await httpApi.get(`/providers/${providerId}/groups/${group?.id}/receivers`);
      console.log('fetchReceivers=result', result);
      setReceivers(result.data.receivers);
    } catch (error) {
      console.error('fetchReceivers=error', error);
      setReceivers([]);
    }
  };
  useEffect(() => {
    if (!group?.id) return;
    fetchReceivers();
  }, [group?.id, reRenderFlag]);
  const columns = useMemo(
    () => [
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Email',
        accessor: 'email',
      },
      {
        Header: 'Last Report Sent On',
        accessor: 'lastReportSentOn',
      },
      {
        Header: 'Delivery Status',
        accessor: 'deliveryStatus',
      },
      {
        disableSortBy: true,
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'row', maxWidth: '70%' }}>
              <p style={{ color: '#FE3824' }} onClick={() => selectReceiverCallBack(row.original, 'remove')}>
                remove
              </p>
              <img src={EditIcon} alt="edit-icon" onClick={() => selectReceiverCallBack(row.original, 'edit')} style={{ marginLeft: '20%' }} />
            </div>
          );
        },
      },
    ],
    [],
  );
  return (
    <ReportsRecipientContainer>
      <SectionTitle>Email list for Call Logs reports for:</SectionTitle>
      <RegionLabel>{`${tierTitles?.[tier] ?? ''} ${tierTitles?.[tier] ? '-' : ''} ${group?.name ?? ''}`}</RegionLabel>
      <NucleusTable columns={columns} data={receivers} />
    </ReportsRecipientContainer>
  );
};

export default ReportsRecipients;
