import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
type NucleusLoadingProps = {
  color?: string;
  size?: number | string;
};
const NucleusLoading = ({ color = '#0092FF', size = 60 }: NucleusLoadingProps) => {
  return <PuffLoader color={color} size={size} />;
};

export default NucleusLoading;
